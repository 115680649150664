import { NbDateFnsDateModule } from '@nebular/date-fns';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbAccordionModule, NbActionsModule, NbBadgeModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbContextMenuModule, NbDatepickerModule, NbIconModule, NbInputModule, NbLayoutModule, NbListModule, NbMenuModule, NbRadioModule, NbSelectModule, NbSidebarModule, NbSpinnerModule, NbStepperModule, NbTabsetModule, NbThemeModule, NbToastrModule, NbToggleModule, NbTreeGridModule, NbUserModule, NbWindowModule } from '@nebular/theme';

import { THEMES } from './theme.consts';

export const NB_MODULES_IMPORTS = [
    NbThemeModule.forRoot({ name: 'corporate' }, THEMES),
    NbToastrModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDateFnsDateModule.forRoot({
        format: 'DD/MM/YYYY',
        parseOptions: { awareOfUnicodeTokens: true },
        formatOptions: { awareOfUnicodeTokens: true }
    }),
    NbMenuModule.forRoot(),
    NbLayoutModule,
    NbCardModule,
    NbUserModule,
    NbActionsModule,
    NbContextMenuModule,
    NbButtonModule,
    NbSelectModule,
    NbIconModule,
    NbEvaIconsModule,
    NbWindowModule,
    NbSpinnerModule,
    NbAccordionModule,
    NbTreeGridModule,
    NbListModule,
    NbInputModule,
    NbRadioModule,
    NbCheckboxModule,
    NbTabsetModule,
    NbBadgeModule,
    NbToggleModule,
    NbStepperModule,
];
export const NB_MODULES_EXPORTS = [
    NbThemeModule,
    NbToastrModule,
    NbSidebarModule,
    NbDatepickerModule,
    NbDateFnsDateModule,
    NbMenuModule,
    NbLayoutModule,
    NbCardModule,
    NbUserModule,
    NbActionsModule,
    NbContextMenuModule,
    NbButtonModule,
    NbSelectModule,
    NbIconModule,
    NbEvaIconsModule,
    NbWindowModule,
    NbSpinnerModule,
    NbAccordionModule,
    NbTreeGridModule,
    NbListModule,
    NbInputModule,
    NbRadioModule,
    NbCheckboxModule,
    NbTabsetModule,
    NbBadgeModule,
    NbToggleModule,
    NbStepperModule,
];
export const NB_MODULES_PROVIDERS = [
    // ...NbThemeModule.forRoot({ name: 'corporate' }, THEMES).providers,
];