import { DAYS_OF_WEEK, MONTHS } from 'app/routes/main/sys/SYS_CONSTS';
import { _calculateNextWorkDate, _calculateWorkEndDate, _workingMsBetweenDates } from './os_calendar_engine';

export class DateUtil {
  readonly timeZone = {
    name: 'America/Fortaleza',
    description: 'GMT-0300 (Hora oficial do Brasil)',
    offsetToUTCInMs: -3 * (60 * 60 * 1000),
  }
  readonly locales = 'pt-BR';
  readonly dateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h23',
    timeZone: this.timeZone.name,
  } as Intl.DateTimeFormatOptions;

  /**
   * Cria uma nova instância de Date.
   * @param {number} valueMs Valor em milissegundos desde meia-noite de 1 de janeiro de 1970 UTC.
   * @returns {Date} Um objeto Date representando a data e hora especificadas ou a data atual se nenhum argumento for passado.
   */
  newDate(valueMs?: number): Date;
  /**
   * Cria uma nova instância de Date.
   * @param {number} year O ano completo.
   * @param {number} monthIndex O índice do mês (0 para Janeiro, 11 para Dezembro).
   * @param {number} date O dia do mês (opcional).
   * @param {number} hours A hora do dia (opcional).
   * @param {number} minutes Os minutos (opcional).
   * @param {number} seconds Os segundos (opcional).
   * @param {number} ms Milissegundos (opcional).
   * @returns {Date} Um objeto Date representando a data e hora especificadas.
   */
  newDate(year: number, monthIndex: number, date?: number, hours?: number, minutes?: number, seconds?: number, ms?: number): Date;
  newDate(valueMsOrYear?: number, monthIndex?: number, date?: number, hours?: number, minutes?: number, seconds?: number, ms?: number): Date {
    let tempDate: Date;

    if (typeof valueMsOrYear === 'number' && typeof monthIndex === 'number')
      // Create date using UTC values
      tempDate = new Date(Date.UTC(valueMsOrYear, monthIndex, date || 1, hours || 0, minutes || 0, seconds || 0, ms || 0));
    else if (typeof valueMsOrYear === 'number')
      tempDate = new Date(valueMsOrYear); // Assume valueMsOrYear is in milliseconds since epoch
    else
      tempDate = new Date(); // Current date and time

    if (!this.dateValid(tempDate))
      return null;

    // Format the date to ensure it reflects the timezone adjustment
    // -- Specifies the desired time zone
    const formatter = new Intl.DateTimeFormat(this.locales, this.dateTimeFormatOptions);
    const parts = formatter.formatToParts(tempDate);
    // -- Extracts the relevant parts of the date and time
    const YYYY = parts.find((part) => part.type === 'year')?.value;
    const MM = parts.find((part) => part.type === 'month')?.value;
    const DD = parts.find((part) => part.type === 'day')?.value;
    const hh = parts.find((part) => part.type === 'hour')?.value;
    const mm = parts.find((part) => part.type === 'minute')?.value;
    const ss = parts.find((part) => part.type === 'second')?.value;

    // Return the date in desired timezone and format
    return new Date(`${YYYY}-${MM}-${DD} ${hh}:${mm}:${ss} ${this.timeZone.description}`);
  }

  /**
   * Retorna a data e hora atuais ajustadas para o fuso horário especificado.
   * @return {Date} Um objeto Date representando a data e hora atuais.
   */
  now(): Date {
    return this.newDate();
  }

  /**
  * Define as horas, minutos, segundos e milissegundos para uma data específica.
  * @param {Date} date Objeto Date a ser modificado.
  * @param {number} hours Horas a serem configuradas.
  * @param {number} min Minutos (opcional).
  * @param {number} sec Segundos (opcional).
  * @param {number} ms Milissegundos (opcional).
  * @return {Date} Um novo objeto Date com a hora ajustada para o fuso horário especificado.
  */
  setHours(date: Date, hours: number, min?: number, sec?: number, ms?: number): Date {
    if (!date || !this.dateValid(date))
      return null;

    min = min !== undefined ? min : 0;
    sec = sec !== undefined ? sec : 0;
    ms = ms !== undefined ? ms : 0;

    // Check the current offset of the date
    const currentOffsetInMs = (date.getTimezoneOffset() * -1) * (60 * 1000); // Offset in milliseconds
    const expectedOffsetInMs = this.timeZone.offsetToUTCInMs;

    // Calculate the difference between the current offset and the desired offset
    const offsetDifferenceInMs = expectedOffsetInMs - currentOffsetInMs;

    const offsetHours = Math.floor(Math.abs(offsetDifferenceInMs) / (60 * 60 * 1000));
    const offsetMinutes = Math.floor((Math.abs(offsetDifferenceInMs) % (60 * 60 * 1000)) / (60 * 1000));
    const offsetSeconds = Math.floor((Math.abs(offsetDifferenceInMs) % (60 * 1000)) / 1000);
    const offsetMilliseconds = Math.floor(Math.abs(offsetDifferenceInMs) % 1000);

    date.setHours(hours + offsetHours, min + offsetMinutes, sec + offsetSeconds, ms + offsetMilliseconds)

    return this.newDate(date.getTime());
  }

  /**
  * Combina uma string de data e uma string de hora em um objeto Date ajustado para o fuso horário especificado.
  * @param {string} dateStr String representando a data no formato 'YYYY-MM-DD'.
  * @param {string} timeStr String representando a hora no formato 'HH:MM'.
  * @param {'00' | '59'} seconds (Opcional) String representando os segundos ('00' ou '59').
  * @return {Date} Um objeto Date ajustado para o fuso horário especificado.
  */
  generateDate(dateStr: string, timeStr: string, seconds: '00' | '59' = '00'): Date {
    return new Date(`${dateStr} ${timeStr}:${seconds} ${this.timeZone.description}`);
  }

  /**
   * Verifica se uma data é válida.
   * @param {Date} date Objeto Date a ser verificado.
   * @return {boolean} True se a data for válida, caso contrário, False.
   */
  dateValid(date: Date): boolean {
    return !isNaN(date.getTime());
  }

  /**	
   * Retorna uma string representando a data no formato 'YYYY-MM-DD'.
   * @param {Date} date Objeto Date a ser convertido.
   * @return {string} String representando a data no formato 'YYYY-MM-DD'.
   */
  getDateStr(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  /**
   * Retorna o ano da data especificada.
   * @param {Date} date Objeto Date do qual o ano será extraído.
   * @return {number} Número representando o ano.
   */
  getDate_Year(date: Date): number {
    const formatter = new Intl.DateTimeFormat(this.locales, this.dateTimeFormatOptions);
    const parts = formatter.formatToParts(date);
    return Number(parts.find((part) => part.type === 'year')?.value);
  }

  /**
   * Retorna o mês da data especificada.
   * @param {Date} date Objeto Date do qual o mês será extraído.
   * @return {number} Número representando o mês.
   */
  getDate_Month(date: Date): number {
    const formatter = new Intl.DateTimeFormat(this.locales, this.dateTimeFormatOptions);
    const parts = formatter.formatToParts(date);
    return Number(parts.find((part) => part.type === 'month')?.value);
  }

  /**
  * Retorna o dia do mês da data especificada.
  * @param {Date} date Objeto Date do qual o dia será extraído.
  * @return {number} Número representando o dia do mês.
  */
  getDate_Day(date: Date): number {
    const formatter = new Intl.DateTimeFormat(this.locales, this.dateTimeFormatOptions);
    const parts = formatter.formatToParts(date);
    return Number(parts.find((part) => part.type === 'day')?.value);
  }

  getDateStrBR(date: Date): string {
    const formatter = new Intl.DateTimeFormat(this.locales, this.dateTimeFormatOptions);
    return formatter.format(date).split(', ')[0];
  }

  /**
   * Use to get the Date Object from a `YYYY-MM-DDThh:mm` string (generally used on <input type='datetime')
   * @param {string} dateTimeStr
   * @return {Date}
   */
  getDateTimeObj(dateTimeStr: string): Date | null {
    if (!dateTimeStr.includes('T'))
      return null;

    const [dateStr, timeStr] = dateTimeStr.split('T');
    const [year, month, day] = dateStr.split('-').map(Number);
    const [hours, minutes] = timeStr.split(':').map(Number);

    return this.newDate(Date.UTC(year, month - 1, day, hours, minutes, 0));
  }

  getDateTimeString(date: Date, showSeconds = false): string {
    if (!date || !this.dateValid(date))
      return '-';

    // Especifica o fuso horário desejado
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: this.timeZone.name,
      hourCycle: 'h23', // Formato de 24 horas
    };

    if (showSeconds)
      options.second = '2-digit';

    const formatter = new Intl.DateTimeFormat(this.locales, options);
    const parts = formatter.formatToParts(date);

    // Extrai as partes relevantes da data e hora
    const YYYY = parts.find((part) => part.type === 'year')?.value;
    const MM = parts.find((part) => part.type === 'month')?.value;
    const DD = parts.find((part) => part.type === 'day')?.value;
    const hh = parts.find((part) => part.type === 'hour')?.value;
    const mm = parts.find((part) => part.type === 'minute')?.value;
    const ss = parts.find((part) => part.type === 'second')?.value || '00';

    // Constrói a string final
    let timeStr = `${YYYY}-${MM}-${DD}T${hh}:${mm}`;
    if (showSeconds)
      timeStr += `:${ss}`;

    return timeStr;
  }

  /**
   * Converte string 'yyyy-mm-dd' para Date()
   * @param {string} dateStr_yyyy_mm_dd
   * @param {'00:00:00' | '23:59:59'} h
   * @return {Date} `Date`
   */
  getDateObj(dateStr_yyyy_mm_dd: string, h: '00:00:00' | '23:59:59' = '00:00:00'): Date {
    if (!dateStr_yyyy_mm_dd)
      return null;
    const [year, month, day] = dateStr_yyyy_mm_dd.split('-').map(Number);
    const [hours, minutes, seconds] = h.split(':').map(Number);

    return this.newDate(Date.UTC(year, month - 1, day, hours, minutes, seconds));
  }

  /**
  * Converte string 'yyyy-mm-dd' para Date() at 00:00:00
  * @param {string} dateStr_yyyy_mm_dd
  * @return {Date}
  */
  getFromDateObj(dateStr_yyyy_mm_dd: string): Date {
    return this.getDateObj(dateStr_yyyy_mm_dd, '00:00:00');
  }

  /**
   * Converte string 'yyyy-mm-dd' para Date() at 23:59:59
   * @param {string} dateStr_yyyy_mm_dd
   * @return {Date}
   */
  getToDateObj(dateStr_yyyy_mm_dd: string): Date {
    return this.getDateObj(dateStr_yyyy_mm_dd, '23:59:59');
  }

  /**
   *
   * @param {string} dateStrFrom 'yyyy-mm-dd' para Date() at 00:00:00
   * @param {string} dateStrTo 'yyyy-mm-dd' para Date() at 23:59:59
   * @return {boolean}
   */
  isDateIntervalValid(dateStrFrom: string, dateStrTo: string): boolean {
    const now = new Date();
    const fromDate = this.getFromDateObj(dateStrFrom);
    const toDate = this.getToDateObj(dateStrTo);

    const validFrom = fromDate ? now.getTime() > fromDate.getTime() : true;
    const validTo = toDate ? now.getTime() < toDate.getTime() : true;

    return validFrom && validTo;
  }

  /**
   * Calcula a diferença em meses entre duas datas.
   * A diferença é sempre positiva, independentemente da ordem das datas.
   * @param {Date} d1 Primeira data para comparação.
   * @param {Date} d2 Segunda data para comparação.
   * @param {boolean} fullMonths (Opcional) Se verdadeiro, considera apenas meses completos. Se a data inicial estiver em um dia maior do que a data final, diminui um mês.
   * @returns {number} A diferença em meses entre as duas datas.
   */
  getMonthDiff(d1: Date, d2: Date, fullMonths = false): number {
    const [startDate, endDate] = d1 < d2 ? [d1, d2] : [d2, d1];
    let months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months += endDate.getMonth() - startDate.getMonth();

    if (fullMonths && startDate.getDate() > endDate.getDate())
      months--;

    return months;
  }

  /**
   * Retorna uma string que representa a data local, considerando o fuso horário e as opções de formato especificadas.
   * @param {Date} date Objeto Date a ser formatado.
   * @returns {string} Uma string representando a data local formatada.
   */
  toLocalDate(date: Date): string {
    if (!date || !this.dateValid(date))
      return '-';

    // Format the date to ensure it reflects the timezone adjustment
    // -- Specifies the desired time zone
    const formatter = new Intl.DateTimeFormat(this.locales, this.dateTimeFormatOptions);
    const parts = formatter.formatToParts(date);
    // -- Extracts the relevant parts of the date and time
    const YYYY = parts.find((part) => part.type === 'year')?.value;
    const MM = parts.find((part) => part.type === 'month')?.value;
    const DD = parts.find((part) => part.type === 'day')?.value;
    const hh = parts.find((part) => part.type === 'hour')?.value;
    const mm = parts.find((part) => part.type === 'minute')?.value;
    const ss = parts.find((part) => part.type === 'second')?.value;

    return `${DD}/${MM}/${YYYY}, ${hh}:${mm}:${ss}`;
  }

  /**
   * Formata uma data para uma string, incluindo a opção de adicionar a hora.
   * Utiliza termos como 'Hoje', 'Ontem', 'Amanhã' e dias da semana se a data estiver na mesma semana.
   * @param {Date} date A data a ser formatada.
   * @param {boolean} [includeTime=true] - Se verdadeiro, inclui a hora na string formatada.
   * @param {boolean} [easy=true] - Se verdadeiro, usa termos amigáveis como 'Hoje', 'Ontem', 'Amanhã'.
   * @returns {string} Uma string representando a data formatada.
   */
  formatToDateString(date: Date, includeTime: boolean = true, easy: boolean = true): string {
    if (!date || !this.dateValid(date))
      return '-';

    const tempDate = this.newDate(date.getTime());
    const now = this.newDate();

    const [nowYear, nowMonth, nowDay, nowDayOfWeek] = [now.getFullYear(), now.getMonth(), now.getDate(), now.getDay()];
    const [year, month, day, dayOfWeek] = [tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), tempDate.getDay()];
    const [hours, minutes] = [tempDate.getHours(), tempDate.getMinutes()];

    let dateString = '';

    // Função para obter o nome do dia da semana
    const getDayOfWeekName = (dayIndex: number): string => {
      return DAYS_OF_WEEK[dayIndex];
    };

    // Verifica se a data está dentro da mesma semana
    const isSameWeek = (d1: Date, d2: Date): boolean => {
      const oneJan = new Date(d1.getFullYear(), 0, 1);
      const numberOfDays1 = Math.floor((d1.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000));
      const numberOfDays2 = Math.floor((d2.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000));
      const weekNumber1 = Math.ceil((numberOfDays1 + oneJan.getDay() + 1) / 7);
      const weekNumber2 = Math.ceil((numberOfDays2 + oneJan.getDay() + 1) / 7);
      return weekNumber1 === weekNumber2;
    };

    // Verifica se é hoje, ontem ou amanhã
    if (easy && year === nowYear && month === nowMonth) {
      if (day === nowDay) {
        dateString = 'Hoje';
      } else if (day === nowDay - 1) {
        dateString = 'Ontem';
      } else if (day === nowDay + 1) {
        dateString = 'Amanhã';
      } else if (isSameWeek(now, tempDate)) {
        dateString = getDayOfWeekName(dayOfWeek);
      } else {
        dateString = `${day} ${MONTHS[month]}`;
      }
    } else if (year !== nowYear) {
      dateString = `${day} ${MONTHS[month]}, ${year}`;
    } else {
      dateString = `${day} ${MONTHS[month]}`;
    }

    if (includeTime)
      dateString += ` ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    return dateString;
  }

  // Métodos utilitários que podem ser importados
  workingMsBetweenDates = _workingMsBetweenDates;
  calculateNextWorkDate = _calculateNextWorkDate;
  calculateWorkEndDate = _calculateWorkEndDate;
}
