import { Location } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';

import { AuthService, SmartTableAuxService, UtilService } from 'app/@core';
import { AuthObject, FirestoreService } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

declare var jQuery: any;

@Component({
  selector: 'app-add-object-to-role-modal',
  templateUrl: './add-object-to-role-modal.component.html',
  styleUrls: ['./add-object-to-role-modal.component.scss']
})
export class AddObjectToRoleModalComponent implements OnInit {

  className = 'AddObjectToRoleModalComponent';

  public loader = true;

  modalId = '';

  objectsIds = [];

  localAuthObjectsIds: string[] = [];
  localAuthObjects: AuthObject[] = [];
  source_AuthObjects: LocalDataSource;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public smartTableAuxService: SmartTableAuxService,
    public uiFeedBackCtrl: UiFeedBackService,
    public db: FirestoreService,
    private authService: AuthService,
    public router: Router,
    private _location: Location,
    public utilCtrl: UtilService,
  ) {
    this.modalId = this.data.modalId;

    (function ($) {
      $(document).ready(function () {
        $('.mat-dialog-container').css("width", "600px");
      });
    })(jQuery);
  }


  ngOnInit() {
    this.loadModalData();
  }

  loadModalData() {
    this.loader = true;
    Promise.all(
      [
        this.loadAuthObjects(),
      ]
    )
      .then(() => {
        this.loader = false
      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }


  loadAuthObjects(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.localAuthObjectsIds = []
      this.localAuthObjects = [];
      this.db.afs.collection(this.db.COLLECTIONS.sys.accessControl.authorizationStructure.authObjects).ref.get({ source: 'server' })
        .then(q => {
          for (let d of q.docs) {
            let tempObj = d.data() as AuthObject;
            this.localAuthObjectsIds.push(tempObj.id)
            this.localAuthObjects.push(tempObj);
          }
          this.source_AuthObjects = new LocalDataSource(this.localAuthObjects);
          resolve();
        })
        .catch(e => reject(e))
    });
  }


  add() {
    this.uiFeedBackCtrl.showTableSelect(this.smartTableAuxService.columns.sys.accessControl.authorizationStructure.authObjects, this.source_AuthObjects)
      .then(d => {
        if (d)
          this.objectsIds.push(d.id)
      })
  }
  removeConfirmation(i) {
    var r = confirm(`delete "${this.objectsIds[i]}"?\n-----------------`);
    if (r == true) {
      this.remove(i);
    } else {
      alert("Action Canceled!");
    }
  }
  remove(i) {
    this.objectsIds.splice(i, 1);
  }



  save() {
    let tempObjects: AuthObject[] = [];
    for (let id of this.objectsIds) {
      tempObjects.push(
        this.localAuthObjects.filter(o => { return o.id == id })[0]
      )
    }
    this.dialog.getDialogById(this.modalId).close({
      objects: tempObjects
    })
  }

}
