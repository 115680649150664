import { ReportingUiColumnPopdownData } from "app/components/@reporting/report.types";

export function columnPopdown_UpdateSelectAll(columnPopdownData: ReportingUiColumnPopdownData) {
    let temp_columnSelectAll = true;
    for (let f of columnPopdownData.searchResult)
        if (!f.show) {
            temp_columnSelectAll = false;
            break
        }
    columnPopdownData.selectAll = temp_columnSelectAll;
}
export function columnPopdown_ToogleAll(columnPopdownData: ReportingUiColumnPopdownData) {
    for (let f of columnPopdownData.searchResult)
        if (columnPopdownData.selectAll)
            f.show = false;
        else
            f.show = true;
    columnPopdownData.selectAll = !columnPopdownData.selectAll;
}
export function columnPopdown__cleancolumn(column_index: number) {
    for (let fi of this._uicolumnIndexes[column_index])
        this.updatecolumnStatus(column_index, fi.value, true);
    this._uicolumnActiveIndex[column_index] = false;
}
export function columnPopdown_Search(columnPopdownData: ReportingUiColumnPopdownData) {
    if (!columnPopdownData.filterIndex) {
        return [];
    }
    if (!columnPopdownData.searchText) {
        return columnPopdownData.filterIndex;
    }
    columnPopdownData.searchText = columnPopdownData.searchText.toLocaleLowerCase();
    let tempSearchResult = columnPopdownData.filterIndex.filter(it => {
        return it.dispValue.toLocaleLowerCase().includes(columnPopdownData.searchText);
    });
    tempSearchResult.forEach(i => {
        i.show = true;
    })
    columnPopdownData.searchResult = tempSearchResult;
}