import { DB_FilterRule, DB_FilterRuleSet } from "../models";
import { DB_FILTER_CONDITION_OPERATIONS } from "./DB_FilterConditionOperations";

function __testData(data: any, filterRules: DB_FilterRule[]): boolean {
    let valid = true;

    for (let filter_index = 0; filter_index < filterRules.length; filter_index++) {
        const filterRule = filterRules[filter_index];
        let testsResults: boolean[] = [];

        for (let condition_index = 0; condition_index < filterRule.conditions.length; condition_index++) {
            const condition = filterRule.conditions[condition_index];
            let operationData = DB_FILTER_CONDITION_OPERATIONS.filter(o => { return o.id == condition.operationId })[0];
            if (operationData) {
                testsResults.push(
                    operationData.test(data, condition.fieldId, condition.value)
                )
            } else {
                // Break the loop if the operationData is not found.
                valid = false;
                break;
                // Break the loop if the operationData is not found.
            }
        }

        // Break the loop if the operationData is not found.
        if (!valid)
            break;
        // Break the loop if the operationData is not found.

        // Analyse the test Results
        if (filterRule.clause == 'OR')
            valid = false;
        for (let tr of testsResults) {
            if (tr == true) {
                valid = true;
            }
            if (tr == false) {
                if (filterRule.clause == 'AND') {
                    valid = false;
                    break;
                }
            }
        }
        // Analyse the test Results


        if (!valid)
            break;
    }

    return valid;
}
export function _filterData(data: any[], filterRuleSet: DB_FilterRuleSet): any[] {
    if (!filterRuleSet || (filterRuleSet && filterRuleSet.length == 0))
        return data;

    let tempData = [];
    data
        .forEach(d => {
            for (let filterRules of filterRuleSet) {
                if (__testData(d, filterRules)) {
                    tempData.push(d);
                    break;
                }
            }
        })
    return tempData;
}

export function _stringifyRuleSet(filterRuleSet: DB_FilterRuleSet): string {
    return JSON.stringify(filterRuleSet);
}
function __parseConditionValue(value: any): any {
    let _value: any = value;

    // Date and time format - ISO 8601
    let dateTimeRegex = /\d{4}(.\d{2}){2}(\s|T)(\d{2}.){2}\d{2}/g;
    if (dateTimeRegex.test(value))
        _value = new Date(value);
    // Date and time format - ISO 8601

    return _value;
}
export function _parseRuleSet(filterRuleSetJson: string): DB_FilterRuleSet {
    let filterRuleSet = [] as DB_FilterRuleSet;
    try {
        filterRuleSet = JSON.parse(filterRuleSetJson);

        filterRuleSet
            .forEach(filterRules => {
                filterRules.forEach(rule => {
                    rule.conditions.forEach(cond => {
                        cond.value = __parseConditionValue(cond.value);
                    })
                })
            })

        return filterRuleSet;
    } catch (error) {
        return filterRuleSet;
    }
}