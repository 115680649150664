import { DB_DataField, DB_DataFieldText, DB_DataSource, FirestoreService } from "app/@firebase";
import { environment } from "environments/environment";

export class DB_DataCoreType {

  min: boolean;

  dataSources: DB_DataSource[];
  dataFields: DB_DataField[];
  dataFieldTexts: DB_DataFieldText[];

  public constructor(init?: Partial<DB_DataCoreType>) {

    this.min = false;

    this.dataSources = [];
    this.dataFields = [];
    this.dataFieldTexts = [];

    if (init)
      Object.assign(this, init);
  }
}
export function loadDataCore(db: FirestoreService): Promise<DB_DataCoreType> {
  return new Promise((resolve, reject) => {
    let tempDataCore = new DB_DataCoreType();

    return db.sys.db.dataSources.getAll()
      .then(data => {
        tempDataCore.dataSources = data;

        return db.sys.db.dataFields.getAll()
          .then(data => {
            tempDataCore.dataFields = data;

            return db.sys.db.dataFieldTexts.getAll()
              .then(data => {
                tempDataCore.dataFieldTexts = data;

                if (!environment.production)
                  console.log(`DB_DataCore -> `, tempDataCore)
                resolve(tempDataCore)

              })
          })
      })
      .catch(e => reject(e))
  });
}

export function loadDataCoreMin(db: FirestoreService, dataSource: DB_DataSource): Promise<DB_DataCoreType> {
  return new Promise((resolve, reject) => {
    let tempDataCore = new DB_DataCoreType();
    tempDataCore.min = true;

    let promises = [];

    if (dataSource.type == 'table') {
      promises.push(
        db.sys.db.dataFields.getDataByIds(dataSource.fieldsIds)
          .then(data => {
            tempDataCore.dataFields = data;
            return Promise.resolve();
          })
          .catch((e) => { return Promise.reject(e) })
      )
      promises.push(
        db.sys.db.dataFieldTexts.getDataByWheres('fieldId', '==', dataSource.fieldsIds)
          .then(data => {
            tempDataCore.dataFieldTexts = data;
            return Promise.resolve();
          })
          .catch((e) => { return Promise.reject(e) })
      )
    } else {
      promises.push(
        db.sys.db.dataSources.getDataByIds(dataSource.tablesIds)
          .then(data => {
            tempDataCore.dataSources = data;
            return Promise.resolve();
          })
          .catch((e) => { return Promise.reject(e) })
      )

      promises.push(
        db.sys.db.dataFields.getAll()
          .then(data => {
            tempDataCore.dataFields = data;
            return Promise.resolve();
          })
          .catch((e) => { return Promise.reject(e) })
      )

      let fieldsIds = [];
      dataSource.viewFields
        .forEach(vf => {
          fieldsIds.push(vf.fieldId);
        })
      promises.push(
        db.sys.db.dataFieldTexts.getDataByWheres('fieldId', '==', fieldsIds)
          .then(data => {
            tempDataCore.dataFieldTexts = data;
            return Promise.resolve();
          })
          .catch((e) => { return Promise.reject(e) })
      )
    }

    Promise.all(promises)
      .then(() => {
        if (!environment.production)
          console.log(`DataCore -> `, tempDataCore)
        resolve(tempDataCore)
      })
      .catch(e => reject(e))
  });
}
