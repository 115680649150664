<div class="control">

  <button class="trigger" (click)="trigger()">
    <span>
      <span class="deemphasizedText">
        <span>{{title}} </span>
      </span>
      <span>{{selected.title}}</span>
    </span>
    <div class="activeIcon">
      <nb-icon *ngIf="!showSelect" icon="chevron-down-outline" pack="eva"></nb-icon>
      <nb-icon *ngIf="showSelect" icon="chevron-up-outline" pack="eva"></nb-icon>
    </div>
  </button>

  <!-- Popdown -->
  <div *ngIf="showSelect" class="popdown">
    <div class="popdownContent" #popdownContent>
      <button class="list-item" *ngFor="let i of list4Selection" [ngClass]="{'selected': i == selected}"
        (click)="select(i)">
        <span>{{i.title}}</span>
      </button>
    </div>
  </div>
  <!-- Popdown -->
</div>
