import { Component, Input } from '@angular/core';

export type userShape = 'round' | 'square';
export interface userIcon {
    pack: string;
    icon: string;
};

@Component({
    selector: 'ic-user',
    templateUrl: './ic-user.component.html',
    styleUrls: ['./ic-user.component.scss']
})
export class IcUserComponent {
    @Input() picture: string | null = null;
    @Input() icon: userIcon | null = null;
    @Input() name: string = '';
    @Input() title: string = '';
    @Input() shape: userShape = 'round';
    @Input() onlyPicture: boolean;
    constructor() { }
    getInitials(): string {
        return this.name ? this.name.split(' ').filter((n) => n.length > 2).map((n) => n.charAt(0)).splice(0, 2).join('').toUpperCase() : '';
    }
    getClassesForIcon(): string[] {
        return [this.icon.pack, this.icon.icon];
    }
}
