import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, ViewChild, ViewEncapsulation } from '@angular/core';
import * as go from 'gojs3';
import { ThemeService } from 'app/@theme/theme.service';

import { magic_org_chart_diagInit } from './magic-org-chart-subrotines';
import { MagicOrgChartNodeData } from './magic-org-chart-types';

@Component({
  selector: 'magic-org-chart',
  templateUrl: './magic-org-chart.component.html',
  styleUrls: ['./magic-org-chart.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class MagicOrgChartComponent implements OnInit, OnChanges, OnDestroy {

  public Diagram: go.Diagram = null;
  // public editorERef = null;
  public ready = false;

  instanceId = this.generateRandom(10);
  // editorClass = 'editor-' + this.instanceId;
  // editorToolbarClass = 'editor__toolbar-' + this.instanceId;

  // @Input() disabled = false;
  // @Input() type: "classic" | "decoupledDocument" = "classic";
  // @Input() mode: "standard" | "restricted" = "standard";
  // @Input() toolbarItems: 'standard' | 'extend' | 'advanced' = "standard";

  @Input() data: MagicOrgChartNodeData[] = [];
  // @Output() onChange = new EventEmitter();
  // @Output() onReady = new EventEmitter<MagicTextEditorComponent>();

  // @Input() value: MagicOrgChartTreeNode[] = [];
  style: any;
  @Input() styleClass: string;
  // @Input() selectionMode: MagicOrgChartSelectionMode = "non";
  preserveSpace: boolean;

  selectionChange: EventEmitter<any>;
  onNodeSelect: EventEmitter<any>;
  onNodeUnselect: EventEmitter<any>;
  onNodeExpand: EventEmitter<any>;
  onNodeCollapse: EventEmitter<any>;


  constructor(
    private theme: ThemeService,
  ) { }
  ngOnInit() { }
  ngOnDestroy() { }
  ngAfterViewInit() {
    setTimeout(() => {
      this.initDiagram();
    }, 100);
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName == 'data') {
        this.loadJson();
      }
      // if (propName == 'disabled') {
      //   this.updateReadOnly();
      // }
    }
  }

  private generateRandom(length: number): string {
    var s = '';
    var r = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    for (var i = 0; i < length; i++) {
      s += r.charAt(Math.floor(Math.random() * r.length));
    }
    return s;
  }

  initDiagram() {
    this.Diagram = magic_org_chart_diagInit(
      "magic-org-chart-diagram",
      false,
      {
        node: {
          font: '10pt ' + this.theme.generalTheme.fontMain,
          textColor: this.theme.generalTheme.fgText,
          fillColor: this.theme.generalTheme.bg,
          strokeWidth: 1,
          strokeColor: this.theme.generalTheme.fgHeading
        },
        link:
        {
          strokeWidth: 1.5,
          strokeColor: this.theme.generalTheme.fgHighlight
        },
      });
    this.ready = true;
    this.loadJson();
  }
  loadJson() {
    if (!this.ready || !this.Diagram)
      return;
    let jsonData = {
      class: "go.TreeModel",
      nodeDataArray: this.data
    };
    let json = JSON.stringify(jsonData);
    this.Diagram.model = go.Model.fromJson(json);
  }
}
