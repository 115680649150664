import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

import { LayoutService } from 'app/@core';
import { DB_DataResult, FirestoreService } from 'app/@firebase';
import { ThemeService } from 'app/@theme/theme.service';
import { MagicTableColumn } from 'app/components/@magic-table';

import { calcTypeDataCubeMaker, customiseOptions, optionsMaker, VIS_TYPES } from '../data';
import { DataVisualization, VisEchartsOptions, VisEngine, VisEngineTypeId, VisMagicTableOptions, VisType } from '../types';

const TEST = false;

@Component({
  selector: 'data-visualization',
  styleUrls: ['./data-visualization.component.scss'],
  templateUrl: './data-visualization.component.html',
})
export class DataVisualizationComponent implements OnInit, OnChanges {

  @Input() id: string = '';
  @Input() title: string = '';

  @Input() vis: DataVisualization = null;

  private vis_types = VIS_TYPES;

  private visType: VisType = null;
  private alive = true;
  private DB_DsDataResult: DB_DataResult = new DB_DataResult();

  private echartsIntance: any;
  public _echartsOptions: any = {};

  public _magicTable_columns: MagicTableColumn[] = [];
  public _magicTable_data: any[] = [];

  public _loader = false;
  public _engine: VisEngineTypeId = null;
  public _engineData: VisEngine = null;
  public _errors = [];

  @Output() onEngineReady = new EventEmitter<{
    engine: VisEngineTypeId,
    data: any[],
    echartsOptions: any,
    magicTable_columns: any[],
    magicTable_data: any[]
  }>();

  // Engines Params
  readonly _ECHART_OPTIONS: VisEchartsOptions = {
    title: {
      text: "",
      subtext: "",
      textStyle: {
        color: this.theme.echartsTheme.textColor,
        fontSize: this.theme.echartsTheme.fontSize,
      }
    },
    backgroundColor: this.theme.echartsTheme.bg,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        label: {
          backgroundColor: '#6a7985',
        },
        shadowStyle: {
          color: 'rgba(0, 0, 0, 0.3)',
        },
      }
    },
    toolbox: {
      show: true,
      feature: {
        dataView: { show: true, readOnly: true },
        magicType: { show: true, type: ['line', 'bar', 'stack'] },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    legend: {
      type: 'scroll',
      top: 20,
      bottom: 20,
      orient: 'horizontal',
      textStyle: {
        color: this.theme.echartsTheme.textColor,
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: [],
      boundaryGap: true,
      axisTick: {
        alignWithLabel: true,
      },
      axisLine: {
        lineStyle: {
          color: this.theme.echartsTheme.axisLineColor,
        },
      },
      axisLabel: {
        color: this.theme.echartsTheme.axisTextColor,
        fontSize: this.theme.echartsTheme.axisFontSize,
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        lineStyle: {
          color: this.theme.echartsTheme.axisLineColor,
        },
      },
      splitLine: {
        lineStyle: {
          color: this.theme.echartsTheme.splitLineColor,
        },
      },
      axisLabel: {
        color: this.theme.echartsTheme.axisTextColor,
        fontSize: this.theme.echartsTheme.axisFontSize,
      },
    },
    series: [],
    _colorMap: new Map<string, string>()
  };
  // Engines Params

  constructor(
    private layoutService: LayoutService,
    private theme: ThemeService,
    public db: FirestoreService,

  ) { }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName == 'vis') {
        setTimeout(() => {
          this.ngOnInit();
        }, 250);
      }
    }
  }
  ngOnInit() {
    this.load();
  }
  ngOnDestroy(): void {
    this.alive = false;
  }

  load() {
    console.log('VIS -> ', this.vis);

    console.time('vis_load_time');
    console.log('VIS -> load');
    this._loader = true;
    this._errors = [];
    this.loadVisType();
    this.db.dataSource.getById(this.vis.source.dbDataSourceId, this.vis.source.limit)
      .then(dsData => {
        this.DB_DsDataResult = dsData;

        console.log('VIS -> DsData ready');
        if (TEST)
          console.log('VIS -> DsData', this.DB_DsDataResult);

        console.log('VIS -> data ready');
        if (TEST)
          console.log('VIS -> data', this.DB_DsDataResult.data);

        this.prepareEngine();

        this._loader = false;
        console.timeEnd('vis_load_time');
      })
      .catch((e) => {
        console.timeEnd('vis_load_time');
        console.error('VIS -> Error', e);
        this._loader = false;
        if (e.message)
          this._errors.push(e.message);
        else
          this._errors.push('Error not have message');
      })
  }
  loadVisType() {
    this.visType = null;
    this._engine = null;
    for (let vt of this.vis_types) {
      if (vt.id == this.vis.typeId) {
        this.visType = vt;
        break;
      }
    }
    if (this.visType)
      this._engine = this.visType['engine']['type'];
  }

  // Engines
  private prepareEngine() {
    if (this._engine == 'echart')
      this.prepareEchart();
    else if (this._engine == 'magic-table')
      this.prepareMagicTable();
    this.onEngineReadyEmitter();
  }

  private engineStart() {
    if (this._engine == 'echart') {
      this.layoutService.onSafeChangeLayoutSize()
        .pipe(
          takeWhile(() => this.alive),
        )
        .subscribe(() => this.resizeChart());
    } else if (this._engine == 'magic-table') {

    }
  }
  private onEngineReadyEmitter() {
    setTimeout(() => {
      this.onEngineReady.emit({
        engine: this._engine,
        data: this.DB_DsDataResult.data,
        echartsOptions: this._echartsOptions,
        magicTable_columns: this._magicTable_columns,
        magicTable_data: this._magicTable_data
      });
    }, 250);
  }
  // Engines

  // Engine Echart
  private prepareEchart() {
    let options = JSON.parse(JSON.stringify(this._ECHART_OPTIONS)) as VisEchartsOptions;

    // Standard Pass-Throughs
    options.title.text = this.title;
    // Standard Pass-Throughs

    this._echartsOptions = {};

    let dataCube = calcTypeDataCubeMaker(this.vis, this.DB_DsDataResult.data);
    console.log('VIS -> dataCube ready');
    if (TEST)
      console.log('VIS -> dataCube', dataCube);

    this._echartsOptions =
      customiseOptions(this.vis,
        optionsMaker(this.vis, this.DB_DsDataResult, options, dataCube, this.theme) as VisEchartsOptions
      );

    console.log('VIS -> _echartsOptions ready');
    if (TEST) {
      console.log('VIS -> _echartsOptions', this._echartsOptions);
      console.log('VIS -> _echartsOptions JSON', JSON.stringify(this._echartsOptions));
    }

    this._updateChartOptions();
  }
  _updateChartOptions() {
    if (this.echartsIntance) {
      this.echartsIntance.setOption(this._echartsOptions);
      this.echartsIntance.resize({ height: 400 });
    }
  }
  _onChartInit(echarts) {
    this.echartsIntance = echarts;
    this.engineStart();
  }
  private resizeChart() {
    if (this.echartsIntance) {
      // Fix recalculation chart size
      // TODO: investigate more deeply
      setTimeout(() => {
        this.echartsIntance.resize();
      }, 0);
    }
  }
  // Engine Echart


  // Engine Magic Table
  private prepareMagicTable() {
    this._magicTable_columns = [];
    this._magicTable_data = [];

    let magicTableOptions = optionsMaker(this.vis, this.DB_DsDataResult, null, null, this.theme) as VisMagicTableOptions;
    this._magicTable_columns = magicTableOptions.columns;
    this._magicTable_data = this.DB_DsDataResult.data;
  }
  // Engine Magic Table

}
