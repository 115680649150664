import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, ViewChild, ViewEncapsulation } from '@angular/core';

import { AuthService, UtilService } from 'app/@core';
import { FirestoreService, Log, ReportPreferences } from 'app/@firebase';
import { FULLSCREEN_URL_PARAM, ThemeService, UiFeedBackService } from 'app/@theme';

import { ReportingPreferencesModeType } from '../../../report.types';
import { _orderByCore } from '../../../report.util';
import { ReportingService } from '../../../services';

@Component({
    selector: 'reporting-preferences',
    templateUrl: './reporting-preferences.component.html',
    styleUrls: ['./reporting-preferences.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class ReportingPreferencesComponent implements OnInit, OnChanges, OnDestroy {

    className = 'ReportingPreferencesComponent';
    localDocPath = this.db.COLLECTIONS.sys.reportPreferences;

    input_title = {
        minlength: 4,
        maxlength: 40
    };
    newReportPref = new ReportPreferences();
    editReportPref = new ReportPreferences();

    constructor(
        private uiFeedBackCtrl: UiFeedBackService,
        private db: FirestoreService,
        public _authService: AuthService,
        public _reportingCtrl: ReportingService,
        public _utilCtrl: UtilService,
        public _themeService: ThemeService,
    ) { }


    ngOnInit() { }
    ngOnDestroy() { }
    ngAfterViewInit() { }
    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }

    selectPref() {
        this._reportingCtrl.selectReportPref(this._reportingCtrl.selectedReportPref);
    }

    input_reportPrefChange() { }

    changeMode(mode: ReportingPreferencesModeType) {
        switch (mode) {
            case "pref-list":
                this._reportingCtrl._editColumns = true;
                break;

            case "pref-selected":
                this._reportingCtrl._editColumns = false;
                this._reportingCtrl.selectReportPref(this._reportingCtrl.selectedReportPref);
                break;

            case "create-pref":
                this._reportingCtrl.loadCleanData()
                    .then(() => {
                        this._reportingCtrl.cleanFilters();
                        this._reportingCtrl.cleanOrd();
                        this._reportingCtrl.updateFiltredData()
                            .then(() => {
                                this.newReportPref = new ReportPreferences();
                                this._reportingCtrl._editColumns = true;
                            });
                    })
                    .catch((e) => {
                        this.uiFeedBackCtrl.presentErrorAlert("", this.className, this._authService.localUser.uName, 'Erro', e)
                    })
                break;

            case "edit-pref":
                this._reportingCtrl.loadCleanData()
                    .then(() => {
                        this.editReportPref = JSON.parse(JSON.stringify(this._reportingCtrl.selectedReportPref));
                        this._reportingCtrl._editColumns = true;
                    })
                    .catch((e) => {
                        this.uiFeedBackCtrl.presentErrorAlert("", this.className, this._authService.localUser.uName, 'Erro', e)
                    })
                break;

            default:
                break;
        }
        this._reportingCtrl._reportingPreferencesMode = mode;
    }
    refreshData() {
        this._reportingCtrl.selectReportPref(this._reportingCtrl.selectedReportPref);
    }
    info() {
        this.uiFeedBackCtrl.presentCustonAlert({
            title: `Detalhes do Relatório`,
            inputs: [
                {
                    disabled: true,
                    type: 'text',
                    label: 'ID',
                    name: 'id',
                    value: this._reportingCtrl.selectedReportPref.id,
                },
                {
                    disabled: true,
                    type: 'text',
                    label: 'Titulo',
                    name: 'title',
                    value: this._reportingCtrl.selectedReportPref.title,
                },
                {
                    disabled: true,
                    type: 'text',
                    label: 'Proprietário',
                    name: 'userFullName',
                    value: this._reportingCtrl.selectedReportPref.userFullName,
                },
                {
                    disabled: true,
                    type: 'text',
                    label: 'É Privado',
                    name: 'isPrivate',
                    value: this._utilCtrl.converters.booleanToStr(this._reportingCtrl.selectedReportPref.isPrivate),
                },

                "spacer",
                {
                    disabled: true,
                    type: 'text',
                    label: 'Criado em',
                    name: 'createdOn',
                    value: this._utilCtrl.timestamp.toLocalDate(this._reportingCtrl.selectedReportPref.createdOn),
                },
                {
                    disabled: true,
                    type: 'text',
                    label: 'Criado por',
                    name: 'createdBy',
                    value: this._reportingCtrl.selectedReportPref.createdBy,
                },
                {
                    disabled: true,
                    type: 'text',
                    label: 'Atualizado em',
                    name: 'updatedOn',
                    value: this._utilCtrl.timestamp.toLocalDate(this._reportingCtrl.selectedReportPref.updatedOn),
                },
                {
                    disabled: true,
                    type: 'text',
                    label: 'Atualizado por',
                    name: 'updatedBy',
                    value: this._reportingCtrl.selectedReportPref.updatedBy,
                },
            ],
        })
    }
    saveReport() {
        this.uiFeedBackCtrl.presentLoader(`Salvando...`)
            .then(() => {

                // newReportPref
                this.newReportPref.id = this.db.createFriendlyId();
                this.newReportPref.className = this._reportingCtrl.reportPageClassName;
                this.newReportPref.uName = this._authService.localUser.uName;
                this.newReportPref.userFullName = this._authService.localUser.fullName;

                this.newReportPref.filterMap = this._reportingCtrl.getFilterMap();
                this.newReportPref.orderMap = this._reportingCtrl.getOrderMap();

                this.newReportPref.createdOn = this._utilCtrl.timestamp.now();
                this.newReportPref.createdBy = this._authService.localUser.uName;
                // newReportPref

                // Log
                let tempLog = new Log();

                tempLog.id = `${this.localDocPath}-${this.db.afs.createId()}`;
                tempLog.className = this.className;
                tempLog.uName = this._authService.localUser.uName;

                tempLog.type = "AUDIT";
                tempLog.category = "CR";

                tempLog.description = `Dados originais do momento da criação: ${JSON.stringify(this.newReportPref, undefined, 2)}`;

                tempLog.docPath = this.localDocPath;
                tempLog.docId = this.newReportPref.id;
                // Log


                return this.db.runTransaction(t => {

                    // Document
                    t.set(
                        this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.sys.reportPreferences).doc(this.newReportPref.id),
                        Object.assign({}, this.newReportPref)
                    );
                    // Document

                    // Log
                    t.set(
                        this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.sys.logs).doc(tempLog.id),
                        Object.assign({}, tempLog)
                    );
                    // Log

                    return Promise.resolve();
                })
                    .then(() => {

                        // update data locally
                        this._reportingCtrl.reportPreferences.push(this.newReportPref);
                        this._reportingCtrl.selectedReportPref = this.newReportPref;

                        // clean create mode
                        this.newReportPref = new ReportPreferences();

                        this.changeMode("pref-selected");
                        this.uiFeedBackCtrl.dismissLoader();
                    })
                    .catch((e) => {
                        this.uiFeedBackCtrl.presentErrorAlert("", this.className, this._authService.localUser.uName, 'Erro', e)
                    })
            });
    }
    updateReport() {
        this.uiFeedBackCtrl.presentLoader(`Salvando...`)
            .then(() => {

                // editReportPref
                if (this.editReportPref.uName == this._authService.localUser.uName)
                    this.editReportPref.userFullName = this._authService.localUser.fullName;

                this.editReportPref.filterMap = this._reportingCtrl.getFilterMap();
                this.editReportPref.orderMap = this._reportingCtrl.getOrderMap();

                this.editReportPref.updatedOn = this._utilCtrl.timestamp.now();
                this.editReportPref.updatedBy = this._authService.localUser.uName;
                // editReportPref

                // Log
                let tempLog = new Log();

                tempLog.id = `${this.localDocPath}-${this.db.afs.createId()}`;
                tempLog.className = this.className;
                tempLog.uName = this._authService.localUser.uName;

                tempLog.type = "AUDIT";
                tempLog.category = "FC";

                tempLog.description = `Dados: ${JSON.stringify(this.editReportPref, undefined, 2)}`;

                tempLog.docPath = this.localDocPath;
                tempLog.docId = this.editReportPref.id;
                // Log


                return this.db.runTransaction(t => {

                    // Document
                    t.update(
                        this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.sys.reportPreferences).doc(this.editReportPref.id),
                        Object.assign({}, this.editReportPref)
                    );
                    // Document

                    // Log
                    t.set(
                        this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.sys.logs).doc(tempLog.id),
                        Object.assign({}, tempLog)
                    );
                    // Log

                    return Promise.resolve();
                })
                    .then(() => {

                        // update data locally
                        for (let index = 0; index < this._reportingCtrl.reportPreferences.length; index++)
                            if (this._reportingCtrl.reportPreferences[index].id == this.editReportPref.id)
                                this._reportingCtrl.reportPreferences[index] = JSON.parse(JSON.stringify(this.editReportPref));
                        this._reportingCtrl.selectedReportPref = JSON.parse(JSON.stringify(this.editReportPref));
                        this._reportingCtrl.reportPreferences.sort((a, b) => _orderByCore(a.title, b.title, "asc"));

                        // clean edit mode
                        this.editReportPref = new ReportPreferences();

                        this.changeMode("pref-selected");
                        this.uiFeedBackCtrl.dismissLoader();
                    })
                    .catch((e) => {
                        this.uiFeedBackCtrl.presentErrorAlert("", this.className, this._authService.localUser.uName, 'Erro', e)
                    })
            })
    }


    deleteConfirmation() {
        const confCode = this._utilCtrl.generators.generateRandom(5);
        this.uiFeedBackCtrl.presentCustonAlert({
            title: 'Apagar Relátorio?',
            message: `⚠️Apagar o Relátorio: ${this._reportingCtrl.selectedReportPref.title}?⚠️ Para proseguir digite o codigo do Projeto abaixo: ${confCode}`,
            inputs: [
                {
                    type: 'text',
                    label: 'Confirmação',
                    name: 'conf',
                    required: true,
                },
            ],
            buttons: [
                {
                    text: 'Cancelar',
                    icon: "close-outline",
                },
                {
                    needFormValid: true,
                    text: 'Deletar',
                    icon: "trash-2-outline",
                    status: "danger",
                    handler: data => {
                        if (data.conf && data.conf.toUpperCase() == confCode.toUpperCase())
                            this._delete();
                        else
                            this.uiFeedBackCtrl.presentAlert(`Deleção cancelada!`, `Verifique o código de confirmação!`, `warning`)
                    }
                }
            ]
        })
    }
    _delete() {
        this.uiFeedBackCtrl.presentLoader('Apagando...')
            .then(() => {

                // Log
                let tempLog = new Log();

                tempLog.id = `${this.localDocPath}-${this.db.afs.createId()}`;
                tempLog.className = this.className;
                tempLog.uName = this._authService.localUser.uName;

                tempLog.type = "AUDIT";
                tempLog.category = "DE";


                tempLog.docPath = this.localDocPath;
                tempLog.docId = this._reportingCtrl.selectedReportPref.id;
                // Log

                this.db.runTransaction(t => {

                    // Document
                    t.delete(
                        this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.sys.reportPreferences).doc(this._reportingCtrl.selectedReportPref.id)
                    );
                    // Document

                    // Log
                    t.set(
                        this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.sys.logs).doc(tempLog.id),
                        Object.assign({}, tempLog)
                    );
                    // Log

                    return Promise.resolve();

                })
                    .then(() => {

                        // update data locally
                        this._reportingCtrl.reportPreferences = this._reportingCtrl.reportPreferences.filter((rp) => { return rp.id != this._reportingCtrl.selectedReportPref.id });
                        this._reportingCtrl.selectedReportPref = null;

                        this.changeMode("pref-list");
                        this.uiFeedBackCtrl.dismissLoader();
                    })
                    .catch((e) => {
                        this.uiFeedBackCtrl.presentErrorAlert("", this.className, this._authService.localUser.uName, 'Erro', e)
                    })
            });
    }

    makePublic() {
        this.uiFeedBackCtrl.presentLoader(`Tornando Público...`)
            .then(() => {

                this.editReportPref = JSON.parse(JSON.stringify(this._reportingCtrl.selectedReportPref));

                // editReportPref
                if (this.editReportPref.uName == this._authService.localUser.uName)
                    this.editReportPref.userFullName = this._authService.localUser.fullName;

                this.editReportPref.isPrivate = false;

                this.editReportPref.updatedOn = this._utilCtrl.timestamp.now();
                this.editReportPref.updatedBy = this._authService.localUser.uName;
                // editReportPref

                // Log
                let tempLog = new Log();

                tempLog.id = `${this.localDocPath}-${this.db.afs.createId()}`;
                tempLog.className = this.className;
                tempLog.uName = this._authService.localUser.uName;

                tempLog.type = "AUDIT";
                tempLog.category = "FC";

                tempLog.description = `Dados: ${JSON.stringify(this.editReportPref, undefined, 2)}`;

                tempLog.docPath = this.localDocPath;
                tempLog.docId = this.editReportPref.id;
                // Log


                return this.db.runTransaction(t => {

                    // Document
                    t.update(
                        this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.sys.reportPreferences).doc(this.editReportPref.id),
                        Object.assign({}, this.editReportPref)
                    );
                    // Document

                    // Log
                    t.set(
                        this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.sys.logs).doc(tempLog.id),
                        Object.assign({}, tempLog)
                    );
                    // Log

                    return Promise.resolve();
                })
                    .then(() => {

                        // update data locally
                        for (let index = 0; index < this._reportingCtrl.reportPreferences.length; index++)
                            if (this._reportingCtrl.reportPreferences[index].id == this.editReportPref.id)
                                this._reportingCtrl.reportPreferences[index] = JSON.parse(JSON.stringify(this.editReportPref));
                        this._reportingCtrl.selectedReportPref = JSON.parse(JSON.stringify(this.editReportPref));
                        this.editReportPref = new ReportPreferences();

                        this.changeMode("pref-selected");
                        this.uiFeedBackCtrl.dismissLoader();
                    })
                    .catch((e) => {
                        this.uiFeedBackCtrl.presentErrorAlert("", this.className, this._authService.localUser.uName, 'Erro', e)
                    })
            })
    }
    makePrivate() {
        this.uiFeedBackCtrl.presentLoader(`Tornando Privado...`)
            .then(() => {

                this.editReportPref = JSON.parse(JSON.stringify(this._reportingCtrl.selectedReportPref));

                // editReportPref
                if (this.editReportPref.uName == this._authService.localUser.uName)
                    this.editReportPref.userFullName = this._authService.localUser.fullName;

                this.editReportPref.isPrivate = true;

                this.editReportPref.updatedOn = this._utilCtrl.timestamp.now();
                this.editReportPref.updatedBy = this._authService.localUser.uName;
                // editReportPref

                // Log
                let tempLog = new Log();

                tempLog.id = `${this.localDocPath}-${this.db.afs.createId()}`;
                tempLog.className = this.className;
                tempLog.uName = this._authService.localUser.uName;

                tempLog.type = "AUDIT";
                tempLog.category = "FC";

                tempLog.description = `Dados: ${JSON.stringify(this.editReportPref, undefined, 2)}`;

                tempLog.docPath = this.localDocPath;
                tempLog.docId = this.editReportPref.id;
                // Log


                return this.db.runTransaction(t => {

                    // Document
                    t.update(
                        this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.sys.reportPreferences).doc(this.editReportPref.id),
                        Object.assign({}, this.editReportPref)
                    );
                    // Document

                    // Log
                    t.set(
                        this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.sys.logs).doc(tempLog.id),
                        Object.assign({}, tempLog)
                    );
                    // Log

                    return Promise.resolve();
                })
                    .then(() => {

                        // update data locally
                        for (let index = 0; index < this._reportingCtrl.reportPreferences.length; index++)
                            if (this._reportingCtrl.reportPreferences[index].id == this.editReportPref.id)
                                this._reportingCtrl.reportPreferences[index] = JSON.parse(JSON.stringify(this.editReportPref));
                        this._reportingCtrl.selectedReportPref = JSON.parse(JSON.stringify(this.editReportPref));

                        // clean edit mode
                        this.editReportPref = new ReportPreferences();

                        this.changeMode("pref-selected");
                        this.uiFeedBackCtrl.dismissLoader();
                    })
                    .catch((e) => {
                        this.uiFeedBackCtrl.presentErrorAlert("", this.className, this._authService.localUser.uName, 'Erro', e)
                    })
            })
    }
    makePreset() {
        this.uiFeedBackCtrl.presentLoader(`Tornando Favorito ❤️...`)
            .then(() => {
                return this.db.runTransaction(t => {

                    // Other docs Document
                    for (const rp of this._reportingCtrl.reportPreferences)
                        t.update(
                            this.db.afs.firestore
                                .collection(this.db.COLLECTIONS.sys.reportPreferences).doc(rp.id),
                            {
                                presetUnames: this.db.fieldValue.arrayRemove(this._authService.localUser.uName) as any,

                                updatedBy: this._authService.localUser.uName,
                                updatedOn: this._utilCtrl.timestamp.now()
                            }
                        );
                    // Other docs Document

                    t.update(
                        this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.sys.reportPreferences).doc(this._reportingCtrl.selectedReportPref.id),
                        {
                            presetUnames: this.db.fieldValue.arrayUnion(this._authService.localUser.uName) as any,

                            updatedBy: this._authService.localUser.uName,
                            updatedOn: this._utilCtrl.timestamp.now()
                        }
                    );

                    return Promise.resolve();
                })
                    .then(() => {

                        // update data locally
                        for (let index = 0; index < this._reportingCtrl.reportPreferences.length; index++) {
                            if (this._reportingCtrl.reportPreferences[index].id == this._reportingCtrl.selectedReportPref.id)
                                this._reportingCtrl.reportPreferences[index].presetUnames.push(this._authService.localUser.uName);
                            else
                                this._reportingCtrl.reportPreferences[index].presetUnames = this._reportingCtrl.reportPreferences[index].presetUnames.filter((uName) => { return uName == this._authService.localUser.uName; });
                        }
                        this._reportingCtrl.selectedReportPref.presetUnames.push(this._authService.localUser.uName);

                        this.uiFeedBackCtrl.dismissLoader();
                    })
                    .catch((e) => {
                        this.uiFeedBackCtrl.presentErrorAlert("", this.className, this._authService.localUser.uName, 'Erro', e)
                    });
            });
    }
    unmakePreset() {
        this.uiFeedBackCtrl.presentLoader(`Removendo Favorito 💔...`)
            .then(() => {
                return this.db.runTransaction(t => {

                    t.update(
                        this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.sys.reportPreferences).doc(this._reportingCtrl.selectedReportPref.id),
                        {
                            presetUnames: this.db.fieldValue.arrayRemove(this._authService.localUser.uName) as any,

                            updatedBy: this._authService.localUser.uName,
                            updatedOn: this._utilCtrl.timestamp.now()
                        }
                    );

                    return Promise.resolve();
                })
                    .then(() => {

                        // update data locally
                        for (let index = 0; index < this._reportingCtrl.reportPreferences.length; index++) {
                            if (this._reportingCtrl.reportPreferences[index].id == this._reportingCtrl.selectedReportPref.id)
                                this._reportingCtrl.reportPreferences[index].presetUnames = this._reportingCtrl.reportPreferences[index].presetUnames.filter((uName) => { return uName != this._authService.localUser.uName; });
                        }
                        this._reportingCtrl.selectedReportPref.presetUnames = this._reportingCtrl.selectedReportPref.presetUnames.filter((uName) => { return uName != this._authService.localUser.uName; });;

                        this.uiFeedBackCtrl.dismissLoader();
                    })
                    .catch((e) => {
                        this.uiFeedBackCtrl.presentErrorAlert("", this.className, this._authService.localUser.uName, 'Erro', e)
                    });
            });
    }
    share() {
        // todo
    }
    copyLink() {
        this.uiFeedBackCtrl.presentCustonAlert({
            title: `Copiar link do Relatório: ${this._reportingCtrl.selectedReportPref.title}`,
            inputs: [
                {
                    type: 'checkbox',
                    label: 'Abrir em tela cheia',
                    name: 'FULLSCREEN',
                    value: false,
                },
            ],
            buttons: [
                {
                    text: 'Cancelar',
                    icon: "close-outline",
                    status: "danger",
                },
                {
                    needFormValid: true,
                    text: 'Copiar',
                    icon: "link-2",
                    status: "primary",
                    handler: data => {
                        let link = this._reportingCtrl.getSelectedReportPrefLink();
                        if (data.FULLSCREEN)
                            link = link + "&" + FULLSCREEN_URL_PARAM + "=true"
                        navigator.clipboard.writeText(link)
                            .then(() => {
                                this.uiFeedBackCtrl.presentToast(`Copiado para Clipboard`, ``, `success`)
                            })
                            .catch((e) => {
                                this.uiFeedBackCtrl.presentErrorAlert('', this.className, this._authService.localUser.uName, 'Erros', e);
                            })
                    }
                }
            ]
        });
    }
}
