export function magicTableNormalizeString(input: string): string {
    // Remover acentos e normalizar a string
    const withoutAccents = input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // Converter para minúsculas
    const lowerCase = withoutAccents.toLowerCase();

    // Remover caracteres especiais
    const withoutSpecialChars = lowerCase.replace(/[^a-z0-9\s]/g, '');

    // Substituir espaços por underscores
    const withUnderscores = withoutSpecialChars.replace(/\s+/g, '_');

    return withUnderscores;
}