<div class="color-select">
  <div class="trigger" (click)="trigger()">
    <div class="colorCircle view" [ngClass]="{'disabled':disabled }" [id]="color.id" [title]="color.name"
      [style.background-color]="color.hex"></div>
  </div>

  <div class="popdown" *ngIf="showSelect">
    <div class="popdownContent" #popdownContent>
      <div class="color-select">
        <div class="color-option" *ngFor="let c of colors">
          <div class="colorCircle" [ngClass]="{'checked': c.hex == color.hex || c.hex == hex}" [id]="c.id"
            [title]="c.name" [style.background-color]="c.hex" (click)="select(c)">
          </div>
        </div>

        <div class="color-option" *ngIf="showClean">
          <div class="colorCircle clean" title="Limpar" (click)="cleanColor()">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>