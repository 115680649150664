import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NbOverlayRef } from '@nebular/theme';

import { OverlayService } from 'app/@theme/services/overlay.service';
import { ThemeStatus } from 'app/@theme/theme.types';

import { AlertStatus } from '../../types';

export interface UiFeedBackAlertModalDialogData {
  title: string;
  message: string;
  lottie: boolean;
  status: AlertStatus;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-ui-feed-back-alert-modal',
  templateUrl: './ui-feed-back-alert-modal.component.html',
  styleUrls: ['./../ui-feed-back-modals.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiFeedBackAlertModalComponent implements OnInit, OnDestroy {

  @Input() id: string = '';
  @Input() overlay: NbOverlayRef;
  readonly className = 'UiFeedBackAlertModalComponent';
  onCloseData = {};

  private INFO_LOTTIE_PATH = 'assets/animations/uiFeedBack.service/information.json';
  private SUCCESS_LOTTIE_PATH = 'assets/animations/uiFeedBack.service/success.json';
  private WARNING_LOTTIE_PATH = 'assets/animations/uiFeedBack.service/warning.json';
  private ERROR_LOTTIE_PATH = 'assets/animations/uiFeedBack.service/error.json';

  @Input() title = '';
  @Input() message = '';
  @Input() status = '';
  @Input() lottie = true;

  public lottieOptions = {
    path: '',
    loop: false
  };
  btn: {
    title: "Informação" | "Sucesso" | "Aviso" | "Erro",
    status: ThemeStatus,
    icon: "info-outline" | "checkmark-circle-2-outline" | "alert-triangle-outline" | "close-circle-outline",
  } = {
      title: "Sucesso",
      status: "success",
      icon: "checkmark-circle-2-outline"
    };

  @ViewChild('AletModalContent', { read: ElementRef, static: false }) aletModalContent: ElementRef;

  constructor(
    public overlayCtrl: OverlayService,
  ) { }
  ngOnInit() {
    switch (this.status) {
      case 'info': {
        this.lottieOptions.path = this.INFO_LOTTIE_PATH;
        this.btn.title = "Informação";
        this.btn.status = "info";
        this.btn.icon = "info-outline";
        break;
      }
      case 'success': {
        this.lottieOptions.path = this.SUCCESS_LOTTIE_PATH;
        this.btn.title = "Sucesso";
        this.btn.status = "success";
        this.btn.icon = "checkmark-circle-2-outline";
        break;
      }
      case 'warning': {
        this.lottieOptions.path = this.WARNING_LOTTIE_PATH;
        this.btn.title = "Aviso";
        this.btn.status = "warning";
        this.btn.icon = "alert-triangle-outline";
        break;
      }
      case 'error': {
        this.lottieOptions.path = this.ERROR_LOTTIE_PATH;
        this.btn.title = "Erro";
        this.btn.status = "danger";
        this.btn.icon = "close-circle-outline";
        break;
      }
    }
  }
  ngAfterViewInit() {
    this._setMessageHTML(this.message);
  }
  ngOnDestroy() { }
  close() {
    this.overlayCtrl.closeOverlay(this.id);
  }

  private _setMessageHTML(message: string) {
    const retryDelayMs = 50;
    let messageElemendId = `alert-message-${this.id}`;
    const element = document.getElementById(messageElemendId);
    if (element)
      document.getElementById(messageElemendId).innerHTML = message;
    else
      setTimeout(() => {
        this._setMessageHTML(message);
      }, retryDelayMs);
  }
}
