export class regexValidators {
  readonly name = /^((\b[A-zÀ-ú']{2,40}\b)\s*){2,}$/;
  readonly email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  readonly number = /^[0-9]*$/;
  readonly text = /^[^0-9]*$/;
  readonly noSpaces = /^[^' ']*$/;
  readonly phone = /\(\d{2,}\) \d{4,}\-\d{4}/g;
  readonly password = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  readonly cpf = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/;
  readonly cnpj = /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}$/;
  readonly cpf_cnpj = /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]g{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/;
  readonly bigger_than_zero = /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/;
  readonly at_lest_zero = /^([0-9]*[0-9]*(\.[0-9]+)?|[0]+\.[0-9]*)$/;
  readonly date = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;
};
