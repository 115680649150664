import { Component, OnDestroy } from '@angular/core';
import { graphic } from 'echarts';
import { takeWhile } from 'rxjs/operators';

import { LayoutService, UtilService } from 'app/@core';
import { DICTIONARY, TM_Project, TM_TimeRecord } from 'app/@firebase';
import { ThemeService } from 'app/@theme';
import { environment } from 'environments/environment';

@Component({
  selector: 'timer-week-hours-bar-chart-detailed',
  styleUrls: ['./charts-common.component.scss'],
  template: `<div class="chart-container"><div echarts [options]="options" class="echart" (chartInit)="onChartInit($event)"></div></div>`,
})
export class TimerWeekHoursBarChartDetailedComponent implements OnDestroy {

  private alive = true;
  private title = 'Horas por Projeto (lado a lado)';

  private echartsIntance: any;

  public chartHeight = 400;
  options: any = {};

  private weekDayNames = [
    'Dom',
    'Seg',
    'Ter',
    'Qua',
    'Qui',
    'Sex',
    'Sáb'
  ];
  private days: {
    date: Date;
    dateWeek: number;
    dateMonth: number;
    dateYear: number;
    dateDay: number;
    dateStr: string;
    weekDay: string;
    h: number;
    m: number;
    val: number
  }[] = [];
  public DICTIONARY = new DICTIONARY();

  constructor(
    private theme: ThemeService,
    private layoutService: LayoutService,
    protected utilCtrl: UtilService
  ) {
    this.layoutService.onSafeChangeLayoutSize()
      .pipe(
        takeWhile(() => this.alive),
      )
      .subscribe(() => this.resizeChart());
  }

  public load(timeRecords: TM_TimeRecord[], datePeriodFrom: Date, datePeriodTo: Date, tmProjects: TM_Project[]) {
    this.days = [];
    let tempTime = datePeriodFrom.getTime();
    let toTime = datePeriodTo.getTime();
    do {
      let tempDate = new Date(tempTime)
      this.days
        .push({
          date: tempDate,
          dateWeek: this.utilCtrl.week.getDate_Week(tempDate),
          dateMonth: this.utilCtrl.date.getDate_Month(tempDate),
          dateYear: this.utilCtrl.date.getDate_Year(tempDate),
          dateDay: this.utilCtrl.date.getDate_Day(tempDate),
          dateStr: this.utilCtrl.date.getDateStrBR(tempDate),
          weekDay: this.weekDayNames[tempDate.getDay()],
          h: 0,
          m: 0,
          val: 0,
        })
      tempTime = tempDate.getTime() + 86400000;
    } while (tempTime <= toTime);

    for (let index = 0; index < timeRecords.length; index++) {
      const tr = timeRecords[index];
      for (let dayIndex = 0; dayIndex < this.days.length; dayIndex++) {

        if (tr.dateDay == this.days[dayIndex].dateDay) {
          this.days[dayIndex].h += tr.durationHours;
          this.days[dayIndex].m += tr.durationMinutes;
          if (this.days[dayIndex].m >= 60) {
            this.days[dayIndex].h++;
            this.days[dayIndex].m = this.days[dayIndex].m - 60;
          }
          this.days[dayIndex].val += tr.duration;
          break;
        }
      }
    }

    let tempLabels = [];

    let tempTotalHoursSerieData = [];
    for (let d of this.days) {
      tempLabels.push(`${d.weekDay} (${d.dateDay})`);
      if (d.val > 0)
        tempTotalHoursSerieData.push(d.val.toFixed(2));
      else
        tempTotalHoursSerieData.push(null)
    }

    let tempSeries = [this.seriesMaker('Total', "#5dcfe3", "#5dcfe3", tempTotalHoursSerieData)];

    let tRIndexByProject = new Map<string, { project: TM_Project, days: { h: number, m: number, val: number }[] }>();

    for (let tr of timeRecords) {
      if (tRIndexByProject.get(tr.projectId)) {
        for (let dayIndex = 0; dayIndex < this.days.length; dayIndex++) {
          if (tr.dateDay == this.days[dayIndex].dateDay) {
            tRIndexByProject.get(tr.projectId).days[dayIndex].h += tr.durationHours;
            tRIndexByProject.get(tr.projectId).days[dayIndex].m += tr.durationMinutes;
            if (tRIndexByProject.get(tr.projectId).days[dayIndex].m >= 60) {
              tRIndexByProject.get(tr.projectId).days[dayIndex].h++;
              tRIndexByProject.get(tr.projectId).days[dayIndex].m = tRIndexByProject.get(tr.projectId).days[dayIndex].m - 60;
            }
            tRIndexByProject.get(tr.projectId).days[dayIndex].val += tr.duration;
            break;
          }
        }
      } else {
        let tempProject = new TM_Project();
        for (let p of tmProjects)
          if (p.id == tr.projectId) {
            tempProject = p;
            break;
          }
        let tempDays: { h: number, m: number, val: number }[] = [];
        for (let dayIndex = 0; dayIndex < this.days.length; dayIndex++) {
          if (tr.dateDay == this.days[dayIndex].dateDay)
            tempDays.push({ h: tr.durationHours, m: tr.durationMinutes, val: tr.duration })
          else
            tempDays.push({ h: 0, m: 0, val: 0 })
        }
        tRIndexByProject.set(tr.projectId, { project: tempProject, days: tempDays });
      }
    }

    tRIndexByProject.forEach((data, projectId) => {
      let tempSerieData = [];
      for (let d of data.days) {
        if (d.val > 0)
          tempSerieData.push(d.val.toFixed(2));
        else
          tempSerieData.push(null)
      }
      let tempColor = this.DICTIONARY.getData(data.project.colorId, this.DICTIONARY.timeManagement.project.color)['hex'];
      if (!tempColor)
        tempColor = '#fff';
      tempSeries.push(
        this.seriesMaker(`${data.project.teamId} - ${data.project.name}`, tempColor, tempColor, tempSerieData)
      );
    });

    this.updateOptions(tempLabels, tempSeries);
  }




  private seriesMaker(name: string, colorGradFrom: string, colorGradTo: string, data: number[]): any {
    return {
      name: name,
      type: 'bar',
      barGap: this.theme.echartsTheme.barGap,
      barWidth: this.theme.echartsTheme.barWidth,
      label: {
        show: true,
        position: 'insideBottom',
        distance: 15,
        align: 'left',
        verticalAlign: 'middle',
        rotate: 90,
        // formatter: '{c}  {name|{a}}',
        formatter: '{c}',
        fontSize: this.theme.echartsTheme.fontSize,
        textStyle: {
          color: this.theme.echartsTheme.textColor,
        },
        rich: {
          name: {
          }
        }
      },
      emphasis: {
        focus: 'series'
      },
      markLine: {
        lineStyle: {
          type: 'dashed',
          color: this.theme.echartsTheme.textColor,
        },
        data: [
          [{ type: 'min' }, { type: 'max' }]
        ]
      },
      itemStyle: {
        normal: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: colorGradFrom,
          }, {
            offset: 1,
            color: colorGradTo,
          }]),
        },
      },
      data: data,
    };
  }
  private updateOptions(labels: string[], series: any[]) {
    this.options = {
      title: {
        text: this.title,
        // subtext: '',
        textStyle: {
          color: this.theme.echartsTheme.textColor,
          fontSize: this.theme.echartsTheme.fontSize,
        },
      },
      backgroundColor: this.theme.echartsTheme.bg,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(0, 0, 0, 0.3)',
          },
        },
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: true },
          saveAsImage: { show: true }
        }
      },
      legend: {
        type: 'scroll',
        top: 20,
        bottom: 20,
        orient: 'horizontal',
        textStyle: {
          color: this.theme.echartsTheme.textColor,
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: labels,
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: this.theme.echartsTheme.axisLineColor,
            },
          },
          axisLabel: {
            color: this.theme.echartsTheme.axisTextColor,
            fontSize: this.theme.echartsTheme.axisFontSize,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: this.theme.echartsTheme.axisLineColor,
            },
          },
          splitLine: {
            lineStyle: {
              color: this.theme.echartsTheme.splitLineColor,
            },
          },
          axisLabel: {
            color: this.theme.echartsTheme.axisTextColor,
            fontSize: this.theme.echartsTheme.axisFontSize,
          },
        },
      ],
      series: series,
    };
    this.resizeChart();
    if (!environment.production) {
      console.log(`timer-week-hours-bar-chart-detailed - Options -> `, this.options);
      // console.log(`JSON ->`, JSON.stringify(this.options, undefined, 2));
    }
  }
  onChartInit(echarts) {
    this.echartsIntance = echarts;
  }
  resizeChart() {
    if (this.echartsIntance) {
      // Fix recalculation chart size
      // TODO: investigate more deeply
      setTimeout(() => {
        this.echartsIntance.resize({ height: this.chartHeight });
      }, 100);
    }
  }
  ngOnDestroy(): void {
    this.alive = false;
  }
}
