export function magicTableFormatVectorWithCommasAndAnd(array: string[]): string {
    if (array.length === 0) {
        return '';
    } else if (array.length === 1) {
        return array[0];
    } else if (array.length === 2) {
        return array.join(' e ') + '.';
    } else {
        const lastItem = array.pop();
        const formattedArray = array.join(', ') + ', e ' + lastItem + '.';
        array.push(lastItem!); // Adicionando o último item de volta ao vetor original
        return formattedArray;
    }
}
