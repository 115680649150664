import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from "firebase/compat/app";

import { COLLECTIONS } from "./COLLECTIONS";
import { LockEntry, Log } from "./models";
import { Validators } from "./Validators";

@Injectable()
export class LockEntries {

  className = 'LockEntries';

  private LOCK_ENTRY_VALID_TIME_IN_MS = 1800000;
  private validators = new Validators;
  private COLLECTIONS = COLLECTIONS;

  constructor(
    public afs: AngularFirestore
  ) { }

  public createLockEntry(docPath: string, docId: string, uName: string, userName: string): Promise<LockEntry> {
    return new Promise((resolve, reject) => {
      let tempLockEntry = new LockEntry();
      tempLockEntry.id = this.afs.createId();
      tempLockEntry.docPath = docPath;
      tempLockEntry.docId = docId;

      tempLockEntry.uName = uName;
      tempLockEntry.userFullName = userName;

      // Log
      let tempLog = new Log();

      tempLog.id = `${docPath}-${this.afs.createId()}`;
      tempLog.className = this.className;
      tempLog.uName = uName;

      tempLog.type = "SYS";
      tempLog.category = "DL";

      tempLog.description = 'Documento Bloqueado';

      tempLog.docPath = docPath;
      tempLog.docId = docId;
      // Log

      this.afs.firestore.runTransaction(t => {

        // LockEntry
        t.set(
          this.afs.firestore
            .collection(this.COLLECTIONS.sys.LOCK_ENTRIES).doc(tempLockEntry.id),
          Object.assign({}, tempLockEntry)
        );
        // LockEntry

        // Log
        t.set(
          this.afs.firestore
            .collection(this.COLLECTIONS.sys.logs).doc(tempLog.id),
          Object.assign({}, tempLog)
        );
        // Log

        return Promise.resolve();
      })
        .then(() => resolve(tempLockEntry))
        .catch(e => reject(e))
    });
  }
  public deleteLockEntry(docPath: string, docId: string, uName: string, lockEntryId: string): Promise<void> {
    return new Promise((resolve, reject) => {

      // Log
      let tempLog = new Log();

      tempLog.id = `${docPath}-${this.afs.createId()}`;
      tempLog.className = this.className;
      tempLog.uName = uName;

      tempLog.type = "SYS";
      tempLog.category = "DU";

      tempLog.description = 'Documento Desbloqueado';

      tempLog.docPath = docPath;
      tempLog.docId = docId;
      // Log

      this.afs.firestore.runTransaction(t => {

        // LockEntry
        t.delete(
          this.afs.firestore
            .collection(this.COLLECTIONS.sys.LOCK_ENTRIES).doc(lockEntryId),
        );
        // LockEntry

        // Log
        t.set(
          this.afs.firestore
            .collection(this.COLLECTIONS.sys.logs).doc(tempLog.id),
          Object.assign({}, tempLog)
        );
        // Log

        return Promise.resolve();
      })
        .then(() => resolve())
        .catch(e => reject(e))
    });
  }
  public getLockEntryStatus(lockEntry: LockEntry): 0 | 1 {
    return firebase.firestore.Timestamp.now().toMillis() - lockEntry.createdOn.toMillis() > this.LOCK_ENTRY_VALID_TIME_IN_MS ? 0 : 1;
  }
  public canEdit(docPath: string, docId: string, ignoreTimeout = false): Promise<{ canEdit: boolean, LE: LockEntry | LockEntry[] }> {
    return new Promise((resolve, reject) => {
      this.afs.collection(this.COLLECTIONS.sys.LOCK_ENTRIES)
        .ref
        .where('docPath', '==', docPath)
        .where('docId', '==', docId)
        .orderBy("createdOn", "asc")
        .get({ source: 'server' })
        .then(querySnapshot => {
          this.validators.validateQuerySnapshot(querySnapshot)
            .then(querySnapshot => {
              if (querySnapshot.empty) {
                resolve({ canEdit: true, LE: null });
              } else {

                let tempLockEntries: LockEntry[] = [];
                let tempCanEditData = { canEdit: true, LE: null };

                for (let doc of querySnapshot.docs) {
                  tempLockEntries.push(doc.data() as LockEntry);
                }

                for (let le of tempLockEntries) {
                  if (ignoreTimeout || this.getLockEntryStatus(le) == 1) {
                    tempCanEditData.canEdit = false;

                    if (tempCanEditData.LE == null)
                      tempCanEditData.LE = le;
                    else if (tempCanEditData.LE instanceof Array) {
                      tempCanEditData.LE.push(le);
                    }
                    else {
                      let tempLockEntryArray: LockEntry[] = [];
                      tempLockEntryArray.push(tempCanEditData.LE);
                      tempLockEntryArray.push(le);
                      tempCanEditData.LE = tempLockEntryArray;
                    }
                  }
                }

                resolve(tempCanEditData);
              }
            })
            .catch(e => reject(e));
        })
        .catch(e => reject(e));
    });
  }

}
