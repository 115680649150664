import { UtilService } from "app/@core";
import { AuditLogChange, DICTIONARY, PM_ToDo, PM_ToDoList } from "app/@firebase";

function stringify_ordArray(ordArray: string[], dataArray: { id: string; name: string; }[]): string {
  const dataMap = dataArray.reduce((acc, data) => {
    acc[data.id] = data.name;
    return acc;
  }, {} as Record<string, string>);
  const orderedNames = ordArray.map((dataId, index) => `${index + 1}º - ${dataMap[dataId]}`);
  return orderedNames.join(', ');
}
export function PM_ToDoList_dataChangGen(oldDoc: PM_ToDoList, newDoc: PM_ToDoList, toDos: PM_ToDo[], utilCtrl: UtilService): AuditLogChange[] {
  let dic = new DICTIONARY();
  let tempChanges: AuditLogChange[] = [];

  if (oldDoc.title != newDoc.title) {
    tempChanges.push({
      fieldName: 'Título',
      oldValue: oldDoc.title,
      newValue: newDoc.title
    })
  }

  // To Do lists
  let toDos_data = toDos.map((t) => { return { id: t.id, name: t.title } });
  let toDos_oldStr = stringify_ordArray(oldDoc.toDosIds, toDos_data);
  let toDos_newStr = stringify_ordArray(newDoc.toDosIds, toDos_data);

  if (toDos_oldStr != toDos_newStr) {
    tempChanges.push({
      fieldName: 'Ordenação de To Dos',
      oldValue: toDos_oldStr,
      newValue: toDos_newStr
    })
  }
  // To Do lists

  return tempChanges;
};
