import firebase from "firebase/compat/app";

export class DB_DataFieldText {

  id: string;
  dataSourceId: string;
  fieldId: string;
  lang: string;
  value: string;
  text: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<DB_DataFieldText>) {

    this.id = '';
    this.dataSourceId = '';
    this.fieldId = '';
    this.lang = '';
    this.value = '';
    this.text = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
