import firebase from "firebase/compat/app";

import { ReportingOrderDirection } from "app/components/@reporting";

export type ReportPreferencesFilterMap = Record<string,
  {
    // todo operator:
    show: boolean;
    value: any;
  }[]>;
export type ReportPreferencesOrderMap = Record<string, ReportingOrderDirection>;


export class ReportPreferences {

  id: string;
  title: string;

  className: string;
  uName: string;
  userFullName: string;

  filterMap: ReportPreferencesFilterMap;
  orderMap: ReportPreferencesOrderMap;

  isPrivate: boolean;
  /** Lista de usuários com os quais a preferência de relatório foi compartilhada */
  sharedWithUnames: string[];
  /** Lista de usuários para os quais a preferência de relatório é definida como predefinida */
  presetUnames: string[];

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<ReportPreferences>) {

    this.id = "";
    this.title = "";
    this.className = "";
    this.uName = "";
    this.userFullName = "";

    this.filterMap = {};
    this.orderMap = {};

    this.isPrivate = true;
    this.sharedWithUnames = [];
    this.presetUnames = [];

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = "";
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
