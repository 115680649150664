<div id="pm-card-modal" class="pm-card-modal">
  <nb-card class="pm-card-modal-nb-card" [nbSpinner]="_loader" [style.box-shadow]="'inset 0 4px 0 ' + cardRoute.hex">
    <nb-card-header>
      <nb-actions size="medium">
        <!-- priority-badge -->
        <nb-action *ngIf="card.priority" class="pm-card-badge priority-badge" [style.color]="cardPriority.color"
          [style.background-color]="cardPriority.background_color">
          <span>
            <b>{{cardPriority.id}}</b>
          </span>
        </nb-action>
        <!-- priority-badge -->

        <!-- card -->
        <nb-action>
          {{card.id}} - {{cardTitleToDisplay}}
        </nb-action>
        <!-- card -->

        <!-- copy-badge -->
        <nb-action *ngIf="card.originalCardId" class="pm-card-badge copya-badge">
          <nb-icon icon="copy-outline" status="success" pack="eva"></nb-icon>
          <span>Cópia</span>
        </nb-action>
        <!-- copy-badge -->

        <!-- template-badge -->
        <nb-action *ngIf="!card.originalCardId && card.template" class="pm-card-badge template-badge">
          <nb-icon icon="copy-outline" status="primary" pack="eva"></nb-icon>
          <span>Modelo</span>
        </nb-action>
        <!-- template-badge -->

        <!-- repeat-badge -->
        <nb-action *ngIf="!card.originalCardId && card.recurrence" class="pm-card-badge repeat-badge">
          <nb-icon icon="repeat-outline" status="primary" pack="eva"></nb-icon>
          <span>Recorrente</span>
        </nb-action>
        <!-- repeat-badge -->

        <!-- archived-badge -->
        <nb-action *ngIf="card.status == 0" class="pm-card-badge archived-badge"
          style="color: red;font-weight: bolder;">
          <nb-icon icon="archive-outline" pack="eva"></nb-icon>
          <span>Arquivado</span>
        </nb-action>
        <!-- archived-badge -->

        <nb-action *ngIf="_viewersUsers.length > 0">
          <div class="pm-card-viewers">
            <div class="pm-card-viewer" *ngFor="let viewer of _viewersUsers; trackBy: _trackByFn"
              [title]="viewer.fullName">
              <nb-user [name]="viewer.fullName" [title]="viewer.jobTitle" [picture]="viewer.picture" onlyPicture>
              </nb-user>
            </div>
          </div>
        </nb-action>
      </nb-actions>
      <div class="close-btn-div">
        <button class="close" mat-button (click)="safeClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <!-- pm-card-modal-inner -->
      <div class="pm-card-modal-inner" [ngClass]="{'archived': card.status == 0}">
        <div id="pm-card-modal-main" class="pm-card-modal-main">
          <div id="pm-card-header2" class="pm-card-header2">
            <div class="title-row">
              <input class="card-title realtime-editible-input" type="text" id="cardTitleInput" name="cardTitleInput"
                placeholder="Título do Cartão" autocomplete="nope" [disabled]="!isBoardActive" [(ngModel)]="card.title"
                (keyup.enter)="_updateCardAfterChange()" (blur)="_updateCardAfterChange()">

              <!-- Subscribers -->
              <button *ngIf="!card.subscribers.includes(authService.localUser.uName)" nbButton size="small"
                appearance="ghost" title="Ativar Notificações" (click)="_toggleSubscribe()">
                <nb-icon icon="bell-off-outline" pack="eva"></nb-icon>
              </button>

              <button *ngIf="card.subscribers.includes(authService.localUser.uName)" nbButton size="small"
                appearance="ghost" title="Desativar Notificações" (click)="_toggleSubscribe()">
                <nb-icon icon="bell" status="warning" pack="eva"></nb-icon>
              </button>
              <!-- Subscribers -->
            </div>

            <textarea class="card-description realtime-editible-input" id="cardDescriptionInput"
              name="cardDescriptionInput" rows="auto" placeholder="Descrição do Cartão" [disabled]="!isBoardActive"
              [(ngModel)]="card.description" (keydown)="_textarea_autosize('cardDescriptionInput')"
              (keyup.enter)="_updateCardAfterChange()" (blur)="_updateCardAfterChange()"></textarea>
          </div>
          <div id="pm-card-modal-main-body" class="pm-card-modal-main-body">
            <!-- related-cards -->
            <related-cards #PMCardModalRelatedCards></related-cards>
            <!-- related-cards -->

            <!-- to-do-lists -->
            <to-do-lists #PMCardModalToDoLists></to-do-lists>
            <!-- to-do-lists -->

            <!-- add-note -->
            <add-note #PMCardModalAddNote></add-note>
            <!-- add-note -->

            <!-- activities -->
            <activities #PMCardModalActivities></activities>
            <!-- activities -->
          </div>
        </div>

        <!-- ------------------------------------------------------------------------------------------------------------- -->

        <div id="pm-card-modal-side" class="pm-card-modal-side">
          <div id="pm-card-modal-side-header" class="pm-card-modal-side-header">
            <div class="mode-selector">
              <div class="ic-button-grp">
                <button *ngFor="let sideMode of PM_CARD_SIDE_MODES; trackBy: _trackByFn" nbButton
                  title="{{sideMode.title}}" status="{{sideMode.status}}" size="small"
                  (click)="_changeSideMode(sideMode.id, sideMode.title)"
                  [ngClass]="{'selected': sideMode.id == selectedSideModeId}">
                  <nb-icon status="{{sideMode.iconStatus}}" icon="{{sideMode.icon}}" pack="eva"></nb-icon>
                </button>

                <button nbButton *ngIf="card.status == 1" [disabled]="!isBoardActive || !isUserAdmin"
                  title="Enviar p/ outro Quadro" status="warning" size="small" (click)="_sendToOtherBoard()">
                  <nb-icon icon="diagonal-arrow-right-up-outline" pack="eva"></nb-icon>
                </button>

                <button nbButton *ngIf="card.status == 1" [disabled]="!isBoardActive || !isUserAdmin"
                  title="Arquivar Cartão" status="warning" size="small" (click)="_archiveConfirmation()">
                  <nb-icon icon="archive-outline" pack="eva"></nb-icon>
                </button>

                <!-- Archived -->
                <button *ngIf="card.status == 0" [disabled]="!isUserAdmin" nbButton title="Ativar Cartão"
                  status="warning" size="small" (click)="_unarchiveCard()">
                  <nb-icon icon="flash" pack="eva"></nb-icon>
                </button>

                <button nbButton *ngIf="card.status == 0" [disabled]="!isBoardActive || !isUserAdmin"
                  title="Apagar Cartão" status="danger" size="small" (click)="_deleteConfirmation()">
                  <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
                </button>
                <!-- Archived -->
              </div>
            </div>
            <div class="mode-title">
              <span class="title">
                {{selectedSideModeTitle}}
              </span>
            </div>
          </div>

          <div id="pm-card-modal-side-body" class="pm-card-modal-side-body">
            <!-- ------------ M O D E S ------------ -->
            <!-- details -->
            <mode-details *ngIf="selectedSideModeId == 'details'" #PMCardModalModeDetails></mode-details>
            <!-- details -->

            <!-- assignees -->
            <mode-assignees *ngIf="selectedSideModeId == 'assignees'" #PMCardModalModeAssignees></mode-assignees>
            <!-- assignees -->

            <!-- labels -->
            <mode-labels *ngIf="selectedSideModeId == 'labels'" #PMCardModalModeLabels></mode-labels>
            <!-- labels -->

            <!-- attachments -->
            <mode-attachments *ngIf="selectedSideModeId == 'attachments'"
              #PMCardModalModeAttachments></mode-attachments>
            <!-- attachments -->

            <!-- time-records -->
            <mode-time-records *ngIf="selectedSideModeId == 'time-records'"
              #PMCardModalModeTimeRecords></mode-time-records>
            <!-- time-records -->
            <!-- ------------ M O D E S ------------ -->
          </div>
        </div>
      </div>
      <!-- pm-card-modal-inner -->
    </nb-card-body>
  </nb-card>
</div>