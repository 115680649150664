import { UtilService } from "app/@core";
import { AuditLogChange, DICTIONARY, PM_ToDo, User } from "app/@firebase";

export function PM_ToDo_dataChangGen(oldDoc: PM_ToDo, newDoc: PM_ToDo, users: User[], utilCtrl: UtilService): AuditLogChange[] {
  let dic = new DICTIONARY();
  let tempChanges: AuditLogChange[] = [];

  if (oldDoc.title != newDoc.title) {
    tempChanges.push({
      fieldName: 'Título',
      oldValue: oldDoc.title,
      newValue: newDoc.title
    })
  }

  const oldUser = users.filter((u) => { return u.uName == oldDoc.assignedTo })[0];
  const newUser = users.filter((u) => { return u.uName == newDoc.assignedTo })[0];
  const oldUserName = oldUser ? oldUser.fullName : "";
  const newUserName = newUser ? newUser.fullName : "";
  if (oldUserName != newUserName) {
    tempChanges.push({
      fieldName: 'Atribuído á',
      oldValue: oldUserName,
      newValue: newUserName
    })
  }

  if (utilCtrl.timestamp.toLocalDate(oldDoc.startDate) != utilCtrl.timestamp.toLocalDate(newDoc.startDate)) {
    tempChanges.push({
      fieldName: 'Prazo: Data de Início',
      oldValue: utilCtrl.timestamp.toLocalDate(oldDoc.startDate),
      newValue: utilCtrl.timestamp.toLocalDate(newDoc.startDate)
    })
  }
  if (utilCtrl.timestamp.toLocalDate(oldDoc.dueDate) != utilCtrl.timestamp.toLocalDate(newDoc.dueDate)) {
    tempChanges.push({
      fieldName: 'Prazo: Data de Entrega',
      oldValue: utilCtrl.timestamp.toLocalDate(oldDoc.dueDate),
      newValue: utilCtrl.timestamp.toLocalDate(newDoc.dueDate)
    })
  }
  if (oldDoc.done != newDoc.done) {
    tempChanges.push({
      fieldName: 'Prazo: Entregue',
      oldValue: utilCtrl.converters.booleanToStr(oldDoc.done),
      newValue: utilCtrl.converters.booleanToStr(newDoc.done)
    })
  }
  if (utilCtrl.timestamp.toLocalDate(oldDoc.doneAt) != utilCtrl.timestamp.toLocalDate(newDoc.doneAt)) {
    tempChanges.push({
      fieldName: 'Prazo: Entregue em',
      oldValue: utilCtrl.timestamp.toLocalDate(oldDoc.doneAt),
      newValue: utilCtrl.timestamp.toLocalDate(newDoc.doneAt)
    })
  }

  return tempChanges;
};
