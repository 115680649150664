import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";

import { FireFunctionsService } from "app/@firebase/fireFunctions.service";
import { MagicTableColumn } from "app/components";

import { CollectionCRUD } from "../CollectionCRUD";
import { COLLECTIONS } from "../COLLECTIONS";
import { DB_DataField, DB_DataFieldText, DB_DataFieldType, DB_DataSource, DB_FilterRuleSet, DB_OrderByRule } from "../models";

const UNIFIER = ".";

export class DB_FieldData {
  id: string;
  dataSourceId: string;
  dataFieldId: string;
  name: string;
  type: DB_DataFieldType;
  public constructor(init?: Partial<DB_FieldData>) {
    this.id = '';
    this.dataSourceId = '';
    this.dataFieldId = '';
    this.name = '';
    this.type = 'string';
    if (init)
      Object.assign(this, init);
  }
}


export class DB_DataResult {
  fields: DB_FieldData[];
  data: any[];
  public constructor(init?: Partial<DB_DataResult>) {
    this.fields = [];
    this.data = [];
    if (init)
      Object.assign(this, init);
  }
}

export class DB_RuleUiData {
  fields: DB_FieldData[];
  dataFieldTexts: DB_DataFieldText[];
  public constructor(init?: Partial<DB_RuleUiData>) {
    this.fields = [];
    this.dataFieldTexts = [];
    if (init)
      Object.assign(this, init);
  }
}

@Injectable()
export class DB_DataSourceService {

  private COLLECTIONS = COLLECTIONS;

  private dataFields: CollectionCRUD<DB_DataField>;
  private dataFieldTexts: CollectionCRUD<DB_DataFieldText>;

  constructor(
    private afs: AngularFirestore,
    private aff: FireFunctionsService
  ) {
    this.dataFields = new CollectionCRUD<DB_DataField>(this.afs, this.COLLECTIONS.sys.db.dataFields, 'id');
    this.dataFieldTexts = new CollectionCRUD<DB_DataFieldText>(this.afs, this.COLLECTIONS.sys.db.dataFieldTexts, 'id');
  }

  private _translateDataFields(dataFields: DB_DataField[], dataSource: DB_DataSource): DB_FieldData[] {
    let fieldsData: DB_FieldData[] = [];

    if (dataSource.type == 'table') {
      dataFields
        .forEach(df => {
          let fd = new DB_FieldData();

          fd.id = df.id;
          fd.dataSourceId = dataSource.id;
          fd.dataFieldId = df.id;
          fd.type = df.type;
          fd.name = df.name;

          fieldsData.push(fd);
        })
    }

    if (dataSource.type == 'view') {
      dataSource.viewFields
        .forEach(vf => {
          let df = dataFields.filter(df => { return df.id == vf.fieldId })[0];
          df = JSON.parse(JSON.stringify(df));
          if (df) {
            let fd = new DB_FieldData();

            fd.id = `${vf.tableId}${UNIFIER}${df.id}`;
            fd.dataSourceId = vf.tableId;
            fd.dataFieldId = df.id;
            fd.type = df.type;

            // name
            fd.name = df.name;
            if (vf.customName != '')
              fd.name = vf.customName;
            // name

            fieldsData.push(fd);
          }
        })
    }

    return fieldsData;
  }
  private _tryParseJson(str: string): any {
    try {
      return JSON.parse(str);
    } catch (error) {
      return null;
    }
  }

  // PUBLIC METHODS
  public decomposeViewFieldId(viewFieldId: string): { dataSourceId: string, fieldId: string } {
    let data = { dataSourceId: '', fieldId: '' };
    let dataArray = viewFieldId.split(UNIFIER);
    if (dataArray.length == 2) {
      data.dataSourceId = dataArray[0];
      data.fieldId = dataArray[1];
    }
    return data;
  }
  public get(dataSource: DB_DataSource, limit: number = 0, filterRuleSet?: DB_FilterRuleSet, orderByRule?: DB_OrderByRule): Promise<DB_DataResult> {
    return new Promise((resolve, reject) => {
      return this.aff.callHttps('DB_DataSourceGet', {
        dataSourceId: dataSource.id,
        limit: limit,
        filterRuleSet: filterRuleSet,
        orderByRule: orderByRule
      })
        .then(data => resolve(data))
        .catch((e) => {
          let error = e;
          if (e.message) {
            let messageData = this._tryParseJson(e.message);
            if (messageData && messageData.details) {
              if (messageData.details == 'limit-of-http-response-exceeded')
                error = { message: 'Limite de dados para processamento em dialogo execido, por gentileza execute essa consulta em backgroud.' }
            }
          }
          reject(error)
        })
    })
  }
  public getById(dataSourceId: string, limit: number = 0, filterRuleSet?: DB_FilterRuleSet, orderByRule?: DB_OrderByRule): Promise<DB_DataResult> {
    return new Promise((resolve, reject) => {
      return this.aff.callHttps('DB_DataSourceGet', {
        dataSourceId: dataSourceId,
        limit: limit,
        filterRuleSet: filterRuleSet,
        orderByRule: orderByRule
      })
        .then(data => resolve(data))
        .catch(e => reject(e))
    })
  }
  public getRuleUiDataById(dataSourceId: string): Promise<DB_RuleUiData> {
    return new Promise((resolve, reject) => {
      this.afs.collection(this.COLLECTIONS.sys.db.dataSources).doc(dataSourceId).ref.get()
        .then(doc => {
          if (doc.exists) {

            const dataSource = new DB_DataSource(doc.data());

            let data = new DB_RuleUiData();

            let fieldsIds = [];
            if (dataSource.type == 'table')
              fieldsIds = dataSource.fieldsIds;
            else
              dataSource.viewFields.forEach(vf => {
                fieldsIds.push(vf.fieldId)
              })

            this.dataFields.getDataByIds(fieldsIds, false)
              .then(dataFields => {
                return this.dataFieldTexts.getDataByWheres('fieldId', '==', fieldsIds, false)
                  .then(dataFieldTexts => {

                    data.fields = this._translateDataFields(dataFields, dataSource);
                    data.dataFieldTexts = dataFieldTexts;
                    resolve(data);

                  })

              })
              .catch(e => reject(e));

          } else
            reject({ message: `dataSourceId: ${dataSourceId} Not Found!` });
        })
        .catch(e => reject(e));
    })
  }
  public convertFieldsDataToTableColumns(fields: DB_FieldData[]): MagicTableColumn[] {
    let tempTable_columns: MagicTableColumn[] = [];
    fields.forEach(f => {
      let col = new MagicTableColumn();
      col.fieldName = f.id;
      col.title = f.name;

      if (f.type == 'timestamp')
        col.type = 'timestamp'
      else if (f.type == 'boolean')
        col.type = 'boolean'
      else
        col.type = 'text';

      col.width = 'auto';
      tempTable_columns.push(col)
    })
    return tempTable_columns;
  }
  // PUBLIC METHODS


}
