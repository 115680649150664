import { Component, ElementRef, HostListener, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MagicTableSettings } from 'app/@firebase';

import { MagicTableColumn } from '../magic-table.types';

@Component({
  selector: 'magic-table-settings-modal',
  styleUrls: ['./magic-table-settings-modal.component.scss'],
  templateUrl: './magic-table-settings-modal.component.html',
})
export class MagicTableSettingsModalComponent implements OnInit {

  @ViewChild('popdownContent', { read: ElementRef, static: false }) popdownContent: ElementRef;

  tableSettings: MagicTableSettings = new MagicTableSettings();

  availableColumns: string[] = [];
  selectedColumns: string[] = [];

  seleted_availableColumn_i = -1;
  seleted_availableColumn = '';

  seleted_selectedColumn_i = -1;
  seleted_selectedColumn = '';

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private mdDialogRef: MatDialogRef<MagicTableSettingsModalComponent>,
  ) { }

  ngOnInit() {
    this.tableSettings = this.data['tableSettings'];
    this.selectedColumns = this.tableSettings.selectedColumns;
    let columns: MagicTableColumn[] = this.data['columns'];
    this.availableColumns = [];
    for (let col of columns) {
      if (this.selectedColumns.indexOf(col.title) == -1)
        this.availableColumns.push(col.title)
    }
  }
  select_availableColumn(index: number, title: string) {
    this.seleted_availableColumn_i = index;
    this.seleted_availableColumn = title;
  }
  select_selectedColumn(index: number, title: string) {
    this.seleted_selectedColumn_i = index;
    this.seleted_selectedColumn = title;
  }
  makeColumSelected() {
    this.availableColumns.splice(this.seleted_availableColumn_i, 1);
    this.selectedColumns.push(this.seleted_availableColumn);
    this.seleted_availableColumn_i = -1;
    this.seleted_availableColumn = '';
  }
  makeColumAvailable() {
    this.selectedColumns.splice(this.seleted_selectedColumn_i, 1);
    this.availableColumns.push(this.seleted_selectedColumn);
    this.seleted_selectedColumn_i = -1;
    this.seleted_selectedColumn = '';
  }
  private moveItem(vet: any[], from: number, to: number): any[] {
    let tempVet = JSON.parse(JSON.stringify(vet));
    // remove `from` item and store it
    var f = tempVet.splice(from, 1)[0];
    // insert stored item into position `to`
    tempVet.splice(to, 0, f);
    return tempVet;
  }
  selectedColumnUp() {
    this.selectedColumns = this.moveItem(this.selectedColumns, this.seleted_selectedColumn_i, (this.seleted_selectedColumn_i - 1));
    this.seleted_selectedColumn_i = this.seleted_selectedColumn_i - 1;
  }
  selectedColumnDown() {
    this.selectedColumns = this.moveItem(this.selectedColumns, this.seleted_selectedColumn_i, (this.seleted_selectedColumn_i + 1));
    this.seleted_selectedColumn_i = this.seleted_selectedColumn_i + 1;
  }
  resetDefaults() {
    let columns: MagicTableColumn[] = this.data['columns'];
    this.selectedColumns = [];
    for (let col of columns) {
      if (col.show) {
        this.selectedColumns.push(col.title);
      }
    }
    this.availableColumns = [];
    for (let col of columns) {
      if (this.selectedColumns.indexOf(col.title) == -1)
        this.availableColumns.push(col.title)
    }
    this.seleted_availableColumn_i = -1;
    this.seleted_availableColumn = '';
    this.seleted_selectedColumn_i = -1;
    this.seleted_selectedColumn = '';
  }
  closeDialog(data?) {
    this.mdDialogRef.close(data)
  }
  save() {
    this.tableSettings.selectedColumns = this.selectedColumns;
    this.closeDialog(this.tableSettings);
  }
  @HostListener('window:keydown.esc', ['$event'])
  onEsc() {
    this.closeDialog();
  }
  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.popdownContent && !this.popdownContent.nativeElement.contains(event.target)) {
      this.closeDialog();
    }
  }

}
