import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { AuthService } from 'app/@core';
import { FirestoreService, OS_BusinessPlace, OS_Company, User } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

declare var jQuery: any;

@Component({
  selector: 'app-caller-user-Info-modal',
  templateUrl: './caller-user-Info-modal.component.html',
  styleUrls: ['./caller-user-Info-modal.component.scss']
})
export class CallerUserInfoModalComponent implements OnInit {

  className = 'CallerUserInfoModalComponent';

  public loader = false;
  public modalId = '';
  public callerUser = new User();
  public userResp = new User();
  public userCompany = new OS_Company();
  public userBusinessPlace = new OS_BusinessPlace();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,

    public uiFeedBackCtrl: UiFeedBackService,
    private authService: AuthService,
    public db: FirestoreService,

  ) {
    this.modalId = this.data.modalId;
    this.callerUser = this.data.callerUser;

    (function ($) {
      $(document).ready(function () {
        $('.mat-dialog-container').css("width", "800px");
      });
    })(jQuery);
  }
  ngOnInit() { this.load(); }
  load() {
    this.loader = true;
    let promises = [];

    if (this.callerUser.respUName != '')
      promises.push(
        this.db.sys.users.getDataByWhere('uName', `==`, this.callerUser.respUName)
          .then(data => {
            this.userResp = data[0];
            return Promise.resolve();
          })
          .catch((e) => { return Promise.reject(e) })
      )


    if (this.callerUser.companyId != '')
      promises.push(
        this.db.organizationalStructure.companies.getDataById(this.callerUser.companyId)
          .then(data => {
            this.userCompany = data;
            return Promise.resolve();
          })
          .catch((e) => { return Promise.reject(e) })
      )


    if (this.callerUser.businessPlaceId != '')
      promises.push(
        this.db.organizationalStructure.businessPlaces.getDataById(this.callerUser.businessPlaceId).then(data => {
          this.userBusinessPlace = data;
          return Promise.resolve();
        })
          .catch((e) => { return Promise.reject(e) })
      )


    return Promise.all(promises)
      .then(() => {
        this.loader = false;
      })
      .catch((e) => {
        this.loader = false;
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }
}
