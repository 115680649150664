import firebase from "firebase/compat/app";

import { PM_CardPriority } from "./PM_CardPriority";
import { PM_CardRecurrence } from "./PM_CardRecurrence";

export function _PM_CardIsUserCard(card: PM_Card): boolean {
  return !card.template && (!card.recurrence || card.originalCardId != '');
}

export class PM_Card {

  id: string;
  externalId: string;
  originalCardId: string;
  boardId: string;
  routeId: string;
  parentKey: string;

  title: string;
  description: string;
  status: 0 | 1;
  priority: PM_CardPriority;
  template: boolean;
  viewers: string[];

  startDate: firebase.firestore.Timestamp | null;
  startDateString: string | null;
  startDateHourString: string | null;

  dueDate: firebase.firestore.Timestamp | null;
  dueDateString: string | null;
  dueDateHourString: string | null;

  done: boolean;
  doneAt: firebase.firestore.Timestamp | null;
  doneAtString: string | null;
  doneAtHourString: string | null;

  assignedTo: string[];
  subscribers: string[];

  labelsIds: string[];
  toDoListsIds: string[];
  relatedCardsIds: string[];

  recurrence: PM_CardRecurrence;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<PM_Card>) {

    this.id = '';
    this.externalId = '';
    this.originalCardId = '';
    this.boardId = '';
    this.routeId = '';
    this.parentKey = '';

    this.title = '';
    this.description = '';
    this.status = 1;
    this.priority = null;
    this.template = false;
    this.viewers = [];

    this.startDate = null;
    this.startDateString = null;
    this.startDateHourString = null;

    this.dueDate = null;
    this.dueDateString = null;
    this.dueDateHourString = null;

    this.done = false;
    this.doneAt = null;
    this.doneAtString = null;
    this.doneAtHourString = null;

    this.assignedTo = [];
    this.subscribers = [];

    this.labelsIds = [];
    this.toDoListsIds = [];
    this.relatedCardsIds = [];

    this.recurrence = null;

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
