import { WS_Role } from './WS_Role';

// Definindo as permissões como constantes (strings)
const PERMISSION_TAREFA_VISUALIZAR = "tarefa.visualizar";
const PERMISSION_TAREFA_ATUALIZAR = "tarefa.atualizar";
const PERMISSION_TAREFA_COMENTAR = "tarefa.comentar";
const PERMISSION_PROJETO_VISUALIZAR = "projeto.visualizar";
const PERMISSION_KANBAN_VISUALIZAR = "kanban.visualizar";
const PERMISSION_DISCUSSAO_PARTICIPAR = "discussao.participar";
const PERMISSION_GAMIFICACAO_VISUALIZAR = "gamificacao.visualizar";

const PERMISSION_TAREFA_ATRIBUIR = "tarefa.atribuir";
const PERMISSION_TAREFA_VISUALIZAR_EQUIPE = "tarefa.visualizar.equipe";
const PERMISSION_TAREFA_MONITORAR = "tarefa.monitorar";
const PERMISSION_RELATORIO_VISUALIZAR_EQUIPE = "relatorio.visualizar.equipe";

const PERMISSION_PROJETO_CRIAR = "projeto.criar";
const PERMISSION_PROJETO_GERENCIAR = "projeto.gerenciar";
const PERMISSION_EQUIPE_CRIAR = "equipe.criar";
const PERMISSION_EQUIPE_GERENCIAR = "equipe.gerenciar";
const PERMISSION_RELATORIO_VISUALIZAR_GERAL = "relatorio.visualizar.geral";
const PERMISSION_GAMIFICACAO_CRIAR = "gamificacao.criar";
const PERMISSION_GAMIFICACAO_CONFIGURAR = "gamificacao.configurar";

const PERMISSION_WORKSPACE_CONFIGURAR = "workspace.configurar";
const PERMISSION_USUARIO_GERENCIAR = "usuario.gerenciar";
const PERMISSION_PERMISSAO_GERENCIAR = "permissao.gerenciar";
const PERMISSION_GAMIFICACAO_CONFIGURAR_AVANCADO = "gamificacao.configurar.avancado";
const PERMISSION_SISTEMA_CONFIGURAR = "sistema.configurar";

const ROLE_MEMBER = {
    id: 'ROLE_MEMBER',
    title: "Membro",
    description: "O Membro é o participante básico do WorkSpace.",
    permissions: [
        PERMISSION_TAREFA_VISUALIZAR,
        PERMISSION_TAREFA_ATUALIZAR,
        PERMISSION_TAREFA_COMENTAR,
        PERMISSION_PROJETO_VISUALIZAR,
        PERMISSION_KANBAN_VISUALIZAR,
        PERMISSION_DISCUSSAO_PARTICIPAR,
        PERMISSION_GAMIFICACAO_VISUALIZAR,
    ]
} as WS_Role;
const ROLE_SUPERVISOR = {
    id: 'ROLE_SUPERVISOR',
    title: "Membro-Supervisor",
    description: "O Membro-Supervisor tem as mesmas permissões que um Membro, mas também pode supervisionar outros membros da equipe.",
    permissions: [
        ...ROLE_MEMBER.permissions, // Herda as permissões de Membro
        PERMISSION_TAREFA_ATRIBUIR,
        PERMISSION_TAREFA_VISUALIZAR_EQUIPE,
        PERMISSION_TAREFA_MONITORAR,
        PERMISSION_RELATORIO_VISUALIZAR_EQUIPE,
    ]
} as WS_Role;
const ROLE_MANAGER = {
    id: 'ROLE_MANAGER',
    title: "Gestor",
    description: "O Gestor tem responsabilidades maiores dentro do WorkSpace.",
    permissions: [
        // Herda as permissões de Membro e Membro-Supervisor
        ...ROLE_SUPERVISOR.permissions,
        PERMISSION_PROJETO_CRIAR,
        PERMISSION_PROJETO_GERENCIAR,
        PERMISSION_EQUIPE_CRIAR,
        PERMISSION_EQUIPE_GERENCIAR,
        PERMISSION_RELATORIO_VISUALIZAR_GERAL,
        PERMISSION_GAMIFICACAO_CRIAR,
        PERMISSION_GAMIFICACAO_CONFIGURAR,
    ]
} as WS_Role;
const ROLE_OWNER = {
    id: 'ROLE_OWNER',
    title: "Dono",
    description: "O Dono tem controle total sobre o WorkSpace.",
    permissions: [
        // Herda as permissões de Gestor
        ...ROLE_MANAGER.permissions,
        PERMISSION_WORKSPACE_CONFIGURAR,
        PERMISSION_USUARIO_GERENCIAR,
        PERMISSION_PERMISSAO_GERENCIAR,
        PERMISSION_GAMIFICACAO_CONFIGURAR_AVANCADO,
        PERMISSION_SISTEMA_CONFIGURAR
    ]
} as WS_Role;

export const WS_CONSTS = {
    standardPermissions: [
        PERMISSION_TAREFA_VISUALIZAR,
        PERMISSION_TAREFA_ATUALIZAR,
        PERMISSION_TAREFA_COMENTAR,
        PERMISSION_PROJETO_VISUALIZAR,
        PERMISSION_KANBAN_VISUALIZAR,
        PERMISSION_DISCUSSAO_PARTICIPAR,
        PERMISSION_GAMIFICACAO_VISUALIZAR,
        PERMISSION_TAREFA_ATRIBUIR,
        PERMISSION_TAREFA_VISUALIZAR_EQUIPE,
        PERMISSION_TAREFA_MONITORAR,
        PERMISSION_RELATORIO_VISUALIZAR_EQUIPE,
        PERMISSION_PROJETO_CRIAR,
        PERMISSION_PROJETO_GERENCIAR,
        PERMISSION_EQUIPE_CRIAR,
        PERMISSION_EQUIPE_GERENCIAR,
        PERMISSION_RELATORIO_VISUALIZAR_GERAL,
        PERMISSION_GAMIFICACAO_CRIAR,
        PERMISSION_GAMIFICACAO_CONFIGURAR,
        PERMISSION_WORKSPACE_CONFIGURAR,
        PERMISSION_USUARIO_GERENCIAR,
        PERMISSION_PERMISSAO_GERENCIAR,
        PERMISSION_GAMIFICACAO_CONFIGURAR_AVANCADO,
        PERMISSION_SISTEMA_CONFIGURAR
    ],
    standardRoles: [
        ROLE_MEMBER,
        ROLE_SUPERVISOR,
        ROLE_MANAGER,
        ROLE_OWNER
    ] as WS_Role[]
};