import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DB_DataResult } from 'app/@firebase';

import { AXIS_CALCULATION_TYPES, AXIS_CATEGORY_TYPES, ENGINE_DATA_REF, VIS_TYPES } from '../../../data';
import { AxisCategoryType, DataVisualization, VisEngineData, VisSource, VisType, VisTypeId } from '../../../types';

@Component({
  selector: 'editor-engine-config',
  styleUrls: ['./editor-engine-config.component.scss'],
  templateUrl: './editor-engine-config.component.html',
})
export class EditorEngineConfigComponent implements OnInit {

  className = "EditorEngineConfigComponent";

  loader = false;

  _old_vis_type: VisType = null;
  _vis_type: VisType = null;

  _vis_types = VIS_TYPES;
  _engineData = ENGINE_DATA_REF;
  _axis_calcTypes = AXIS_CALCULATION_TYPES;
  _axis_catTypes = AXIS_CATEGORY_TYPES;
  _axis_catTypes4sel: AxisCategoryType[] = [];

  _oldVis_engineData: VisEngineData = {};
  _vis_engineData: VisEngineData = {};

  _data_for_selection = {
    fields: [],
  };

  DB_DsDataResult: DB_DataResult = new DB_DataResult();

  @Output() onChange = new EventEmitter();
  @Input() hasChanges = false;

  @Input() edit = false;

  engineDataForm_valid = false;

  constructor() { }

  ngOnInit() { }
  ngOnDestroy(): void { }

  public loadFromVis(vis: DataVisualization, DB_DsDataResult: DB_DataResult) {
    if (!vis.engine || !vis.engine.data)
      vis.engine.data = {};

    this._oldVis_engineData = JSON.parse(JSON.stringify(vis.engine.data));
    this._vis_engineData = JSON.parse(JSON.stringify(vis.engine.data));
    this.hasChanges = false;
    this.engineDataForm_valid = true;

    this.DB_DsDataResult = DB_DsDataResult;

    let vis_type = this._vis_types.filter(vt => { return vt.id == vis.typeId })[0];
    if (vis_type) {
      this._vis_type = vis_type;
      this._old_vis_type = JSON.parse(JSON.stringify(vis_type));
      if (this._vis_type && this._vis_type.id)
        this._axis_catTypes4sel = this._axis_catTypes.filter(ct => { return ct.visTypeId == this._vis_type.id });
    }

    this._data_for_selection.fields = [];
    this.DB_DsDataResult.fields
      .forEach(f => {
        if (vis.source.fields.indexOf(f.id) != -1)
          this._data_for_selection.fields.push(f)
      })
  }
  public loadFromDsDataResult(DB_DsDataResult: DB_DataResult, vis_source: VisSource) {
    this.DB_DsDataResult = DB_DsDataResult;
    this._vis_type = null;
    this._vis_engineData = {};
    this._data_for_selection.fields = [];
    this.engineDataForm_valid = false;
    this.DB_DsDataResult.fields
      .forEach(f => {
        if (vis_source.fields.indexOf(f.id) != -1)
          this._data_for_selection.fields.push(f)
      })
  }
  public getVisTypeId(): VisTypeId {
    let id = null;
    if (this._vis_type && this._vis_type['id'])
      id = this._vis_type['id'];
    return id;
  }
  public getVisEngineData(): VisEngineData {
    return JSON.parse(JSON.stringify(this._vis_engineData));
  }
  _compareFn(o1, o2): boolean {
    let result = false;
    if (o1 === null && o2 === null)
      result = true;
    else if (o1 != null && o2 != null) {
      if (o1 === o2)
        result = true;
      else if (o1.id != null && o2.id != null && o1.id === o2.id)
        result = true;
    }
    return result;
  }
  _trackByFn(index, item) {
    return index;
  }

  _typeChange() {
    if (this._vis_type && this._vis_type.engine && this._vis_type.engine.dataProps) {
      this._vis_engineData = {};
      this._vis_type.engine.dataProps
        .forEach(ed => {
          this._vis_engineData[ed] = {};
        })
    }
    if (this._vis_type && this._vis_type.id)
      this._axis_catTypes4sel = this._axis_catTypes.filter(ct => { return ct.visTypeId == this._vis_type.id });
    console.log('-> _vis_type:', this._vis_type);
    this._vis_engineDataChange(false);
  }
  _vis_engineDataChange(form_valid: boolean) {
    this.engineDataForm_valid = form_valid;

    // yAxis
    if (this._vis_engineData.yAxis !== undefined && this._vis_engineData.yAxis.calcTypeId !== undefined) {
      if (this._vis_engineData.yAxis.calcTypeId == "count" && this._vis_engineData.yAxis.fieldId != "")
        this._vis_engineData.yAxis.fieldId = "";
    }
    // yAxis

    // values
    if (this._vis_engineData.values !== undefined && this._vis_engineData.values.calcTypeId !== undefined) {
      if (this._vis_engineData.values.calcTypeId == "count" && this._vis_engineData.values.fieldId != "")
        this._vis_engineData.values.fieldId = "";
    }
    // values

    // categorizeBy
    if (this._vis_engineData.categorizeBy !== undefined && this._vis_engineData.categorizeBy.catTypeId !== undefined) {
      if (this._vis_engineData.categorizeBy.catTypeId == "" && this._vis_engineData.categorizeBy.fieldId != "")
        this._vis_engineData.categorizeBy.fieldId = "";
    }
    // categorizeBy

    console.log('-> engineDataForm_valid:', this.engineDataForm_valid);
    console.log('-> _vis_engineData:', this._vis_engineData);
    this._change();
  }
  _change() {
    setTimeout(() => {
      this.checkForChanges();
      this.onChange.emit();
    }, 500);
  }
  checkForChanges() {
    const oldData = JSON.stringify(this._old_vis_type);
    const newData = JSON.stringify(this._vis_type);
    const oldData1 = JSON.stringify(this._oldVis_engineData);
    const newData1 = JSON.stringify(this._vis_engineData);
    if (
      oldData != newData ||
      oldData1 != newData1
    )
      this.hasChanges = true
    else
      this.hasChanges = false
  }
}
