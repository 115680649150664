import firebase from "firebase/compat/app";


export class PM_CardAttachment {

  id: string;

  boardId: string;
  cardId: string;
  parentKey: string;

  name: string;
  size: number;
  mimeType: string;
  extension: string;
  url: string;

  createdOn: firebase.firestore.Timestamp;
  createdBy: string;

  public constructor(init?: Partial<PM_CardAttachment>) {

    this.id = '';

    this.boardId = '';
    this.cardId = '';
    this.parentKey = '';

    this.name = '';
    this.size = 0;
    this.mimeType = '';
    this.extension = '';
    this.url = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.createdBy = '';

    if (init)
      Object.assign(this, init);
  }
}
