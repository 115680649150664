<div class="mode mode_details">

    <div *ngIf="_loader" class="loader">
        <p>Carregando...</p>
    </div>

    <div class="doc-fields">
        <!-- ------------------------ -->
        <div class="field">
            <div class="field-label">
                <button nbButton title="Copiar Link" size="tiny" appearance="ghost" (click)="_copyLink()">
                    <nb-icon icon="link-2-outline" pack="eva"></nb-icon>
                </button>
                ID
            </div>
            <div class="field-value">
                <a [href]="'#/main/project-management/kanban/open-board?id=' + pmCardModal.card.boardId + '&cardIdToOpen=' + pmCardModal.card.id"
                    target="_blank" rel="noopener noreferrer">
                    <span *ngIf="pmCardModal.card.priority">
                        [{{pmCardModal.card.priority}}]{{pmCardModal.card.id}}
                    </span>
                    <span *ngIf="!pmCardModal.card.priority">
                        {{pmCardModal.card.id}}
                    </span>
                </a>
            </div>
        </div>
        <!-- ------------------------ -->

        <!-- ------------------------ -->
        <div class="field" *ngIf="pmCardModal.card.externalId">
            <div class="field-label">
                ID Externo
            </div>
            <div class="field-value">
                <div class="field-value">
                    {{pmCardModal.card.externalId}}
                </div>
            </div>
        </div>
        <!-- ------------------------ -->

        <!-- ------------------------ -->
        <div class="field">
            <div class="field-label">
                <button nbButton title="Copiar Referencia" size="tiny" appearance="ghost" (click)="_copyRef()">
                    <nb-icon icon="copy-outline" pack="eva"></nb-icon>
                </button>
                <button *ngIf="pmCardModal.board.integration.tm.active" nbButton title="Apontar Horas No Cartão"
                    size="tiny" appearance="ghost" status="primary" (click)="pmCardModal._tmIntegrationCard()">
                    <nb-icon icon="clock-outline" pack="eva"></nb-icon>
                </button>
                Título
            </div>
            <div class="field-value">
                {{pmCardModal.cardTitleToDisplay}}
                <div *ngIf="pmCardModal.board.integration.tm.active && timeRecordSum4Card.val > 0"
                    title="Somatório de horas apontadas diretamente no cartão" class="total-hours-badge">
                    <nb-icon icon="clock-outline" pack="eva"></nb-icon>
                    <span>{{timeRecordSum4Card.s}}</span>
                </div>
            </div>
        </div>
        <!-- ------------------------ -->

        <!-- ------------------------ -->
        <div class="field">
            <div class="field-label">
                Quadro
            </div>
            <div class="field-value">
                {{pmCardModal.board.name}}
            </div>
        </div>
        <!-- ------------------------ -->

        <!-- ------------------------ -->
        <div class="field">
            <div class="field-label">
                Criado Por
            </div>
            <div class="field-value">
                {{pmCardModal.card.createdBy}}
            </div>
        </div>
        <!-- ------------------------ -->

        <!-- ------------------------ -->
        <div class="field">
            <div class="field-label">
                Criado Em
            </div>
            <div class="field-value">
                {{pmCardModal.utilCtrl.timestamp.toLocalDate(pmCardModal.card.createdOn)}}
            </div>
        </div>
        <!-- ------------------------ -->

        <!-- ------------------------ -->
        <div class="field">
            <div class="field-label">
                Atualizado Por
            </div>
            <div class="field-value">
                {{pmCardModal.card.updatedBy}}
            </div>
        </div>
        <!-- ------------------------ -->

        <!-- ------------------------ -->
        <div class="field">
            <div class="field-label">
                Atualizado Em
            </div>
            <div class="field-value">
                {{pmCardModal.utilCtrl.timestamp.toLocalDate(pmCardModal.card.updatedOn)}}
            </div>
        </div>
        <!-- ------------------------ -->

        <!-- ------------------------ -->
        <div class="field">
            <div class="field-label">
                <button nbButton title="Informação Inscritos" size="tiny" appearance="ghost"
                    (click)="_showSubscribersInfo()">
                    <nb-icon icon="info-outline" pack="eva"></nb-icon>
                </button>
                Inscritos
            </div>
            <div class="field-value">
                {{pmCardModal.card.subscribers.length}}
            </div>
        </div>
        <!-- ------------------------ -->

        <!-- ------------------------ -->
        <div class="field" *ngIf="pmCardModal.card.originalCardId != ''">
            <div class="field-label">
                Cartão Original
            </div>
            <div class="field-value">
                <a [href]="'#/main/project-management/kanban/open-board?id=' + pmCardModal.card.boardId + '&cardIdToOpen=' + pmCardModal.card.originalCardId"
                    target="_blank" rel="noopener noreferrer">
                    {{pmCardModal.card.originalCardId}}
                </a>
            </div>
        </div>
        <!-- ------------------------ -->
    </div>

    <!-- |||||||||||||||||||||||||||||||||||||||||||| -->

    <!-- route -->
    <div class="route">
        <span class="details-subtitle">
            <nb-icon icon="at-outline" pack="eva"></nb-icon>
            <p>Rota</p>
        </span>
        <div class="details-sub-body details-route">
            <div class="form">
                <ng-select id="cardRouteInput" name="cardRouteInput" title="Rotas" [clearable]="false"
                    [disabled]="!pmCardModal.isBoardActive" [(ngModel)]="pmCardModal.card.routeId"
                    [compareWith]="_compareFn" (change)="_routeChange()">
                    <ng-option *ngFor="let r of pmCardModal.boardRoutes" [value]="r.id">
                        <span [style.color]="r.hex">{{r.name}}</span>
                    </ng-option>
                </ng-select>
            </div>
        </div>
    </div>
    <!-- route -->

    <!-- priority -->
    <div class="priority">
        <span class="details-subtitle">
            <span *ngIf="pmCardModal.card.priority" class="indicator"
                title="{{pmCardModal.cardPriority.id}} - {{pmCardModal.cardPriority.name}}"
                [style.background-color]="pmCardModal.cardPriority.background_color"
                [style.border-color]="pmCardModal.cardPriority.background_color">
            </span>
            <nb-icon icon="bar-chart-outline" pack="eva"></nb-icon>
            <p>Prioridade</p>
        </span>
        <div class="details-sub-body">
            <div class="form">
                <ng-select id="cardPriorityInput" name="cardPriorityInput" title="Prioridade"
                    [disabled]="!pmCardModal.isBoardActive || !pmCardModal.isUserAdmin"
                    [(ngModel)]="pmCardModal.card.priority" [compareWith]="_compareFn" (change)="_priorityChange()">ks

                    <ng-option *ngFor="let p of PM_CARD_PRIORITY_DATA_ARRAY_4_SELECTION" [value]="p.id">
                        <span class="cardPriorityInput-ng-option-color" [style.background-color]="p.background_color"
                            [style.border-color]="p.background_color"
                            style="width: 10px; height: 10px;border-radius: 50%; margin-right: 5px;">
                        </span>

                        <span>
                            {{p.id}} - {{p.name}}
                        </span>
                    </ng-option>

                </ng-select>
            </div>

            <p class="priority-description" *ngIf="pmCardModal.cardPriority.description">
                {{pmCardModal.cardPriority.description}}
            </p>
        </div>
    </div>
    <!-- priority -->

    <!-- dueDate -->
    <div class="dueDate">
        <span class="details-subtitle">
            <span *ngIf="pmCardModal.card.dueDate" class="indicator" title="{{_dueDateStatus.name}}"
                [style.background-color]="_dueDateStatus.background_color"
                [style.border-color]="_dueDateStatus.background_color">
            </span>
            <nb-icon icon="calendar-outline" pack="eva"></nb-icon>
            <p>Prazo</p>
        </span>

        <div class="details-sub-body">
            <div class="input-row">
                <button id="card-due-date-popdown-trigger" class="card-due-date" [disabled]="!pmCardModal.isBoardActive"
                    (click)="_showCardDueDatePopdown()" title="{{_dueDateStatus.name}}"
                    style.border-color="{{_dueDateStatus.background_color + ' !important'}}">
                    <nb-icon *ngIf="!pmCardModal.card.startDate && !pmCardModal.card.dueDate" icon="plus-circle-outline"
                        pack="eva"></nb-icon>
                    <span *ngIf="!pmCardModal.card.startDate && !pmCardModal.card.dueDate">Inserir Prazo</span>
                    <span
                        *ngIf="pmCardModal.card.startDate || pmCardModal.card.dueDate">{{_generateDueDateStr(pmCardModal.card)}}</span>
                </button>
                <!-- Card Due Date Popdown -->
                <div id="card-due-date-popdown" class="popdown card-due-date-popdown"
                    [ngClass]="{'hide': !showCardDueDatePopdown}">
                    <div class="popdownContent">
                        <div class="popdown-body">
                            <!-- dates -->
                            <div class="popdown-row">
                                <!-- startDateString -->
                                <div class="popdown-col">
                                    <button [ngClass]="{'hide': pmCardModal.card.startDateString != null}"
                                        [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)"
                                        (click)="_toggleStartDate()">
                                        <nb-icon status="primary" icon="plus-circle-outline" pack="eva"></nb-icon>
                                        <span>
                                            Data de Início
                                        </span>
                                    </button>

                                    <div [ngClass]="{'hide': pmCardModal.card.startDateString == null}"
                                        class="input-row">
                                        <input title="Início" id="cardStartDateStringInput"
                                            name="cardStartDateStringInput"
                                            class="ic-form-control realtime-editible-input" type="date"
                                            [(ngModel)]="pmCardModal.card.startDateString"
                                            (ngModelChange)="_cardStartDateStringInput_onChange()"
                                            [max]="pmCardModal.card.dueDateString"
                                            [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)">

                                        <button
                                            [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)"
                                            (click)="_toggleStartDate()">
                                            <nb-icon size="tiny" status="danger" icon="close-circle-outline"
                                                pack="eva"></nb-icon>
                                        </button>
                                    </div>
                                </div>
                                <!-- startDateString -->

                                <!-- cardDueDateString -->
                                <div class="popdown-col">
                                    <input title="Fim" id="cardDueDateStringInput" name="cardDueDateStringInput"
                                        class="ic-form-control realtime-editible-input" type="date"
                                        [(ngModel)]="pmCardModal.card.dueDateString"
                                        (ngModelChange)="_cardDueDateStringInput_onChange()"
                                        [min]="pmCardModal.card.startDateString"
                                        [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)">
                                </div>
                                <!-- cardDueDateString -->
                            </div>
                            <!-- dates -->

                            <!-- hours -->
                            <div class="popdown-row" [ngClass]="{'hide': pmCardModal.card.dueDateHourString == null}">
                                <!-- startDateHourString -->
                                <div class="popdown-col">
                                    <button
                                        [ngClass]="{'hide': (pmCardModal.card.startDateString == '' || pmCardModal.card.startDateString == null) || pmCardModal.card.startDateHourString != null}"
                                        [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin) || pmCardModal.card.dueDate == null"
                                        (click)="_toggleStartDateHour()">
                                        <nb-icon status="primary" icon="plus-circle-outline" pack="eva"></nb-icon>
                                        <span>
                                            Hora de Início
                                        </span>
                                    </button>

                                    <div [ngClass]="{'hide': pmCardModal.card.startDateHourString == null}"
                                        class="input-row">
                                        <select id="cardStartDateHourStringInput" name="cardStartDateHourStringInput"
                                            title="Prazo" class="ic-form-control realtime-editible-input"
                                            [(ngModel)]="pmCardModal.card.startDateHourString"
                                            (ngModelChange)="_cardStartDateHourStringInput_onChange()"
                                            [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)">
                                            <option *ngFor="let time of TIMES" [ngValue]="time">{{time}}</option>
                                        </select>

                                        <button
                                            [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)"
                                            (click)="_toggleStartDateHour()">
                                            <nb-icon size="tiny" status="danger" icon="close-circle-outline"
                                                pack="eva"></nb-icon>
                                        </button>
                                    </div>
                                </div>
                                <!-- startDateHourString -->

                                <!-- dueDateHourString -->
                                <div class="popdown-col">
                                    <select id="cardDueDateHourStringInput" name="cardDueDateHourStringInput"
                                        title="Prazo" class="ic-form-control realtime-editible-input"
                                        [(ngModel)]="pmCardModal.card.dueDateHourString"
                                        (ngModelChange)="_cardDueDateHourStringInput_onChange()"
                                        [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)">
                                        <option *ngFor="let time of TIMES" [ngValue]="time">{{time}}</option>
                                    </select>
                                </div>
                                <!-- dueDateHourString -->
                            </div>
                            <!-- hours -->
                        </div>
                        <hr>
                        <div class="popdown-footer">
                            <button title="Adicionar Hora"
                                [ngClass]="{'active': pmCardModal.card.dueDateHourString != null}"
                                [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin) || pmCardModal.card.dueDate == null"
                                (click)="_toggleDueDateHour()">
                                <nb-icon icon="clock-outline" pack="eva"></nb-icon>
                            </button>

                            <button title="Limpar"
                                [disabled]="!pmCardModal.isBoardActive || (pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin)"
                                (click)="_clearCardDueDatePopdown()">
                                <span>Limpar</span>
                            </button>
                        </div>
                        <p *ngIf="pmCardModal.board.onlyAdmChangeDueDate && !pmCardModal.isUserAdmin"
                            class="tiny-error">
                            Somente admins podem alterar prazos
                        </p>
                    </div>
                </div>
                <!-- Card Due Date Popdown -->
            </div>

            <!-- cardDone -->
            <div class="cardDone" *ngIf="pmCardModal.card.originalCardId || !pmCardModal.card.recurrence">
                <label for="cardDone">Entregue</label>

                <!-- cardDoneAtInput -->
                <div class="input-row">
                    <button class="done-btn" nbButton [disabled]="!pmCardModal.isBoardActive" appearance="ghost"
                        size="large" title="Marcar/Desmarcar como concluído" (click)="_toggleCardDone()">
                        <nb-icon *ngIf="!pmCardModal.card.done" icon="radio-button-off-outline" pack="eva"></nb-icon>
                        <nb-icon *ngIf="pmCardModal.card.done" icon="checkmark-circle-2" pack="eva"
                            status="success"></nb-icon>
                    </button>

                    <input *ngIf="!pmCardModal.card.dueDateHourString" title="Entregue em" id="doneAtStringInput"
                        name="doneAtStringInput" class="ic-form-control realtime-editible-input" type="date"
                        [(ngModel)]="pmCardModal.card.doneAtString"
                        [disabled]="!pmCardModal.isBoardActive || !pmCardModal.card.done"
                        (ngModelChange)="_cardDoneAtStringInput_onChange()">

                    <input *ngIf="pmCardModal.card.dueDateHourString" title="Entregue em" id="cardDoneAtDateTimeString"
                        name="cardDoneAtDateTimeString" class="ic-form-control realtime-editible-input"
                        type="datetime-local" step="0" [(ngModel)]="cardDoneAtDateTimeString"
                        [disabled]="!pmCardModal.isBoardActive || !pmCardModal.card.done"
                        (ngModelChange)="_cardDoneAtDateTimeStringInput_onChange()">

                    <button *ngIf="pmCardModal.card.doneAt" [disabled]="!pmCardModal.isBoardActive" nbButton
                        status="danger" appearance="ghost" size="tiny" (click)="_cleanDoneAt()">
                        <nb-icon icon="close-circle-outline" pack="eva"></nb-icon>
                    </button>
                </div>
                <!-- cardDoneAtInput -->
                <p *ngIf="pmCardModal.card.done && pmCardModal.card.doneAt == null" class="tiny-error">
                    ⚠️Cartões concluídos e sem data de entrega são considerados entregues fora do prazo!
                </p>
            </div>
            <!-- cardDone -->
        </div>
    </div>
    <!-- dueDate -->

    <!-- assignees -->
    <div class="assignees" *ngIf="cardAssignees.length > 0">
        <span class="details-subtitle">
            <nb-icon icon="people-outline" pack="eva"></nb-icon>
            <p>Atribuído a</p>
        </span>
        <div class="details-sub-body">
            <div class="assignee" *ngFor="let assignee of cardAssignees; trackBy: _trackByFn"
                [title]="assignee.fullName">
                <nb-user [name]="assignee.fullName" [title]="assignee.jobTitle" [picture]="assignee.picture"
                    onlyPicture>
                </nb-user>
            </div>
        </div>
    </div>
    <!-- assignees -->

    <!-- labels -->
    <div class="labels" *ngIf="cardLabels.length > 0">
        <span class="details-subtitle">
            <nb-icon icon="bookmark-outline" pack="eva"></nb-icon>
            <p>Rótulos</p>
        </span>
        <div class="details-sub-body">
            <span class="label" *ngFor="let label of cardLabels; trackBy: _trackByFn" [title]="label.name">
                <span class="label-color" [style.background-color]="label.hex" [style.border-color]="label.hex"></span>
            </span>
        </div>
    </div>
    <!-- labels -->

    <!-- to-do-lists -->
    <div class="to-do-lists" *ngIf="pmCardModal.card.toDoListsIds.length > 0">
        <span class="details-subtitle">
            <nb-icon icon="checkmark-square-2-outline" pack="eva"></nb-icon>
            <p>{{pmCardModal._toDoLists._cardToDosDone}}/{{pmCardModal._toDoLists._cardToDos}}</p>
        </span>
        <div class="details-sub-body">
            <div class="to-do-lists-progress">
                <span class="progress-text">
                    {{pmCardModal._toDoLists._cardDonePerc.toFixed(2)}}%
                </span>
                <div class="progress">
                    <span class="progress-bar" [style.width]="pmCardModal._toDoLists._cardDonePerc + '%'"></span>
                </div>
            </div>
        </div>
    </div>
    <!-- to-do-lists -->

    <!-- recurrence -->
    <div class="recurrence">
        <span class="details-subtitle">
            <nb-icon icon="repeat-outline" pack="eva"></nb-icon>
            <p>Recorrência</p>

            <button nbButton title="Informação Recorrência" size="tiny" appearance="ghost"
                (click)="_showRecurrenceInfo()">
                <nb-icon icon="info-outline" pack="eva"></nb-icon>
            </button>

            <button *ngIf="!pmCardModal.card.originalCardId && !pmCardModal.card.recurrence"
                [disabled]="!pmCardModal.isBoardActive" nbButton appearance="ghost" status="success" size="small"
                (click)="_activeRecurrence()">
                Ativar
            </button>

            <button *ngIf="!pmCardModal.card.originalCardId && pmCardModal.card.recurrence"
                [disabled]="!pmCardModal.isBoardActive" nbButton appearance="ghost" status="danger" size="small"
                (click)="_inactiveRecurrenceConfirmation()">
                Desativar
            </button>
        </span>

        <div class="details-sub-body">
            <!-- copy card -->
            <div *ngIf="pmCardModal.card.originalCardId != ''">
                <p *ngIf="pmCardModal.card.recurrence" class="tiny-text">
                    ℹ️ Verifique a recorrencia no cartão original: <a
                        [href]="'#/main/project-management/kanban/open-board?id=' + pmCardModal.card.boardId + '&cardIdToOpen=' + pmCardModal.card.originalCardId"
                        target="_blank" rel="noopener noreferrer">
                        {{pmCardModal.card.originalCardId}}
                    </a>
                </p>
                <p *ngIf="!pmCardModal.card.recurrence" class="tiny-text">
                    ℹ️ Cartões criados como cópia não podem ser recorrentes!
                </p>
            </div>
            <!-- copy card -->

            <!-- original card -->
            <div *ngIf="pmCardModal.card.originalCardId == '' && pmCardModal.card.recurrence">

                <p *ngIf="pmCardModal.card.recurrence && pmCardModal.card.startDate == null" class="tiny-error">
                    ❌ Preencha a data de início do cartão para que a recorrencia comece a ser aplicada!
                </p>

                <form #recurrenceForm>
                    <div class="row">
                        <!-- frequency -->
                        <div class="col-4">
                            <label for="frequency">Se Repete</label>
                            <select class="ic-form-control realtime-editible-input" id="frequency" name="frequency"
                                [(ngModel)]="pmCardModal.card.recurrence.frequency"
                                (ngModelChange)="pmCardModal._updateCardAfterChange()"
                                [disabled]="!pmCardModal.isBoardActive" required>
                                <option [ngValue]="null" disabled selected>Selecione</option>
                                <option value="daily">Diariamente</option>
                                <option value="weekly">Semanalmente</option>
                                <option value="monthly">Mensalmente</option>
                                <option value="yearly">Anualmente</option>
                            </select>
                        </div>
                        <!-- frequency -->

                        <!-- interval -->
                        <div class="col-3">
                            <label for="interval">A cada</label>
                            <input class="ic-form-control realtime-editible-input" id="interval" type="number"
                                name="interval" [(ngModel)]="pmCardModal.card.recurrence.interval"
                                (ngModelChange)="pmCardModal._updateCardAfterChange()"
                                placeholder="Intervalo de recorrência" [disabled]="!pmCardModal.isBoardActive" min="1"
                                required />
                        </div>
                        <!-- interval -->

                        <!-- Data de Término -->
                        <div class="col-5">
                            <label for="endDate">Término em</label>
                            <input class="ic-form-control realtime-editible-input" id="endDate" type="date"
                                name="endDate" [(ngModel)]="pmCardModal.card.recurrence.endDate"
                                (ngModelChange)="pmCardModal._updateCardAfterChange()"
                                [disabled]="!pmCardModal.isBoardActive" />
                        </div>
                        <!-- Data de Término -->
                    </div>
                    <div class="row">
                        <!-- Dias da Semana -->
                        <div class="col-12" *ngIf="pmCardModal.card.recurrence.frequency === 'weekly'">
                            <label for="daysOfWeek">Dias da Semana:</label>
                            <div class="daysOfWeek">
                                <button *ngFor="let day of daysOfWeek" class="day" (click)="_selectDayOfWeek(day.value)"
                                    [ngClass]="{'selected': pmCardModal.card.recurrence.daysOfWeek.includes(day.value)}">
                                    <span>{{day.label}}</span>
                                </button>
                            </div>
                        </div>
                        <!-- Dias da Semana -->

                        <!-- Mês do Ano -->
                        <div class="col-6" *ngIf="pmCardModal.card.recurrence.frequency === 'yearly'">
                            <label for="monthOfYear">Mês do Ano:</label>
                            <select class="ic-form-control realtime-editible-input" id="monthOfYear" name="monthOfYear"
                                [(ngModel)]="pmCardModal.card.recurrence.monthOfYear"
                                (ngModelChange)="pmCardModal._updateCardAfterChange()"
                                [disabled]="!pmCardModal.isBoardActive">
                                <option *ngFor="let month of monthsOfYear" [value]="month.value">{{ month.label }}
                                </option>
                            </select>
                        </div>
                        <!-- Mês do Ano -->


                        <!-- Dia do Mês -->
                        <div class="col-6"
                            *ngIf="pmCardModal.card.recurrence.frequency === 'monthly' || pmCardModal.card.recurrence.frequency === 'yearly'">
                            <label for="dayOfMonth">Dia do Mês:</label>
                            <input class="ic-form-control realtime-editible-input" id="dayOfMonth" type="number"
                                name="dayOfMonth" [(ngModel)]="pmCardModal.card.recurrence.dayOfMonth"
                                (ngModelChange)="pmCardModal._updateCardAfterChange()"
                                [disabled]="!pmCardModal.isBoardActive" min="1" max="31" />
                        </div>
                        <!-- Dia do Mês -->
                    </div>

                    <br>
                    <div class="row">
                        <p class="tiny-text">
                            Opções de criação dos cartões
                        </p>
                        <!-- earlyCreation -->
                        <div class="col-4">
                            <label for="earlyCreation">Criação antecipada</label>
                            <select class="ic-form-control realtime-editible-input" id="earlyCreation"
                                name="earlyCreation" [(ngModel)]="pmCardModal.card.recurrence.earlyCreation"
                                (ngModelChange)="pmCardModal._updateCardAfterChange()"
                                [disabled]="!pmCardModal.isBoardActive" required>
                                <option [ngValue]="null" selected>Não Adiantar</option>
                                <option value="d">Dias</option>
                                <option value="h">Horas</option>
                            </select>
                        </div>
                        <!-- earlyCreation -->


                        <!-- earlyCreationValue -->
                        <div class="col-3" *ngIf="pmCardModal.card.recurrence.earlyCreation">
                            <label for="earlyCreationValue">Antecip. Em</label>
                            <input class="ic-form-control realtime-editible-input" id="earlyCreationValue" type="number"
                                name="earlyCreationValue" [(ngModel)]="pmCardModal.card.recurrence.earlyCreationValue"
                                (ngModelChange)="pmCardModal._updateCardAfterChange()"
                                [disabled]="!pmCardModal.isBoardActive" min="1" step="0.5" required />
                        </div>
                        <!-- earlyCreationValue -->

                        <!-- creationRouteId -->
                        <div class="col-5">
                            <label for="creationRouteId">Rota</label>
                            <select class="ic-form-control realtime-editible-input" id="creationRouteId"
                                name="creationRouteId" [(ngModel)]="pmCardModal.card.recurrence.creationRouteId"
                                (ngModelChange)="pmCardModal._updateCardAfterChange()"
                                [disabled]="!pmCardModal.isBoardActive" required>
                                <option [ngValue]="null" selected>1ª Rota do Quadro</option>
                                <option *ngFor="let r of pmCardModal.boardRoutes" [value]="r.id" [style.color]="r.hex">
                                    {{r.name}}
                                </option>
                            </select>
                        </div>
                        <!-- creationRouteId -->
                    </div>
                </form>
                <hr>
                <br>

                <p class="tiny-text">
                    <b>ℹ️ Prox. cartão será criado em: </b> <i>{{nextCardCreationDateToCardStr}}</i>
                </p>
                <p class="tiny-text">
                    <b>ℹ️ Início do prox cartão: </b> <i>{{nextStartDateToCardStr}}</i>
                </p>

                <div *ngIf="pmCardModal.card.recurrence.histData.cardsIds.length > 0">
                    <br>
                    <p class="tiny-text"><b>Histórico</b></p>
                    <p class="tiny-text">
                        ℹ️ Cartões criados: {{pmCardModal.card.recurrence.histData.cardsIds.join(', ')}}
                    </p>
                    <p class="tiny-text">
                        ℹ️ Data de início do ultimo criado:
                        {{pmCardModal.utilCtrl.timestamp.toLocalDate(pmCardModal.card.recurrence.histData.lastCardStartDate)}}
                    </p>
                </div>
            </div>
            <!-- original card -->
        </div>
    </div>
    <!-- recurrence -->

    <!-- options -->
    <div class="options">
        <span class="details-subtitle">
            <nb-icon icon="options-2-outline" pack="eva"></nb-icon>
            <p>Opções</p>
        </span>
        <div class="details-sub-body">
            <!-- template -->
            <div class="option-template">
                <!-- copy card -->
                <div *ngIf="pmCardModal.card.originalCardId != ''">
                    <p class="tiny-text">
                        ℹ️ Cartões criados como cópia não podem modelos!
                    </p>
                </div>
                <!-- copy card -->
                <div *ngIf="pmCardModal.card.originalCardId == ''">
                    <input id="cardTemplateInput" name="cardTemplateInput"
                        [disabled]="!pmCardModal.isBoardActive || pmCardModal.card.originalCardId != ''"
                        [(ngModel)]="pmCardModal.card.template" (ngModelChange)="pmCardModal._updateCardAfterChange()"
                        type="checkbox">
                    <label for="cardTemplateInput">Modelo</label>
                </div>
                <!-- original card -->
            </div>
            <!-- template -->

        </div>
    </div>
    <!-- options -->
</div>