import firebase from "firebase/compat/app";

export class WS_Portifolio {

  id: string;
  status: 0 | 1;
  workspaceId: string;
  parentKey: string;
  favoritedUnames: string[];

  title: string;
  description: string;
  color: string;

  members: string[];

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<WS_Portifolio>) {

    this.id = '';
    this.status = 1;
    this.workspaceId = '';
    this.parentKey = '';
    this.favoritedUnames = [];

    this.title = '';
    this.description = '';
    this.color = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
