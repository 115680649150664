import { UtilService } from "app/@core";
import { FirestoreService, TM_TimerSession, User } from "app/@firebase";

let _actualUser: User = null;
let _actualTimerSession: TM_TimerSession = null;
let _actualTimerSessionSub = null;
const TIMEOUT_MS = 6 * 1000;

// C R U D
function _creteTimerSession(db: FirestoreService, util: UtilService, newTarget: { uName: string, dateFromTime: number, dateToTime: number }): Promise<TM_TimerSession> {
  return new Promise((resolve, reject) => {
    let timerSession: TM_TimerSession = new TM_TimerSession();

    timerSession.id = db.createId();
    timerSession.uName = _actualUser.uName;
    timerSession.userFullName = _actualUser.fullName;

    timerSession.target.uName = newTarget.uName;
    timerSession.target.dateFromTime = newTarget.dateFromTime;
    timerSession.target.dateToTime = newTarget.dateToTime;

    timerSession.alive = true;
    timerSession.timeOutedOn = null;

    timerSession.createdBy = _actualUser.uName;

    return db.afs.collection(db.COLLECTIONS.timeManagement.timerSessions).doc(timerSession.id).set(Object.assign({}, timerSession))
      .then(() => {
        return _initActualTimerSessionSub(db, util, timerSession.id)
          .then(() => {
            resolve(timerSession);
          })
      })
      .catch((e) => { reject(e) })
  });
}
function _getTimerSessionsForTargetUname(db: FirestoreService, uName: string): Promise<TM_TimerSession[]> {
  return new Promise((resolve, reject) => {
    return db.afs.collection(db.COLLECTIONS.timeManagement.timerSessions)
      .ref
      .where('target.uName', '==', uName)
      .get({ source: 'server' })
      .then(q => {
        let timerSessions: TM_TimerSession[] = [];
        for (let d of q.docs)
          timerSessions.push(d.data() as TM_TimerSession);
        resolve(timerSessions);
      })
      .catch((e) => { reject(e) })
  });
}
function _getTimerSession(db: FirestoreService, id: string): Promise<TM_TimerSession> {
  return new Promise((resolve, reject) => {
    db.afs.collection(db.COLLECTIONS.timeManagement.timerSessions).doc(id).ref
      .get({ source: 'server' })
      .then(doc => {
        if (doc.exists)
          resolve(doc.data() as TM_TimerSession);
        else
          resolve(null);
      })
      .catch((e) => {
        reject(e)
      })
  })
}
function _updateTimerSession(db: FirestoreService, util: UtilService, id: string, data: Partial<TM_TimerSession>): Promise<void> {
  let newData = data
  newData.updatedBy = _actualUser.uName;
  newData.updatedOn = util.timestamp.now();
  return db.afs.collection(db.COLLECTIONS.timeManagement.timerSessions).doc(id).update(newData);
}
function _deleteTimerSession(db: FirestoreService, id: string): Promise<void> {
  return db.afs.collection(db.COLLECTIONS.timeManagement.timerSessions).doc(id).delete();
}
// C R U D


// REAL TIME
function _sessionDead() {
  alert('session dead!');
  window.location.reload();
}
function _endActualTimerSessionSub() {
  if (_actualTimerSessionSub != null) {
    _actualTimerSessionSub.unsubscribe();
  }
}
function _initActualTimerSessionSub(db: FirestoreService, util: UtilService, timerSessionId: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (_actualTimerSessionSub == null || _actualTimerSessionSub.closed) {
      _actualTimerSessionSub = db.afs.collection(db.COLLECTIONS.timeManagement.timerSessions).doc(timerSessionId).valueChanges()
        .subscribe(docData => {
          if (docData) {

            _actualTimerSession = docData as TM_TimerSession;
            resolve();

            if (!_actualTimerSession.alive) {
              _reviveTimerSession(db, util, _actualTimerSession)
                .then(revive => {
                  if (revive) {
                    console.log('session Revived!')
                  } else {
                    _sessionDead();
                  }
                })
                .catch((e) => {
                  console.error('_reviveTimerSession - (error) =>', e);
                  alert('_reviveTimerSession - (error) =>' + e);
                })
            }


          } else {
            _actualTimerSession = null;
            _endActualTimerSessionSub();
            _sessionDead();
          }
        },
          (error) => {
            reject(error);
            console.error('ActualTimerSessionSub - (error) =>', error);
            alert('ActualTimerSessionSub - (error) =>' + error);
            window.location.reload();
          });
    } else {
      if (_actualTimerSession.id != timerSessionId) {
        _actualTimerSession = null;
        if (_actualTimerSessionSub != null) {
          _actualTimerSessionSub.unsubscribe();
        }
        resolve(_initActualTimerSessionSub(db, util, timerSessionId))
      } else
        resolve();
    }

  })
}
function _killTimerSession(db: FirestoreService, util: UtilService, alreadyOpenedSession: TM_TimerSession): Promise<boolean> {
  return new Promise((resolve, reject) => {
    let timeOut = util.timestamp.fromMillis(new Date().getTime() + TIMEOUT_MS);
    return _updateTimerSession(db, util, alreadyOpenedSession.id, {
      alive: false,
      timeOutedOn: timeOut,
      log: db.fieldValue.arrayUnion({ operation: `kill`, time: util.timestamp.now(), uName: _actualUser.uName, userFullName: _actualUser.fullName }) as any
    })
      .then(() => {
        setTimeout(() => {
          return _getTimerSession(db, alreadyOpenedSession.id)
            .then(data => {
              if (!data || data.alive)
                resolve(false);
              else
                return _deleteTimerSession(db, alreadyOpenedSession.id)
                  .then(() => {
                    resolve(true);
                  })
                  .catch(e => reject(e))
            })
            .catch(e => reject(e))
        }, TIMEOUT_MS);
      })
      .catch((e) => { reject(e) })
  });
}
function _reviveTimerSession(db: FirestoreService, util: UtilService, timerSession: TM_TimerSession): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const time = new Date().getTime();
    const timeout = timerSession.timeOutedOn.toMillis();
    if (time < timeout) {
      return _updateTimerSession(db, util, timerSession.id, {
        alive: true,
        timeOutedOn: null,
        log: db.fieldValue.arrayUnion({ operation: `revive`, time: util.timestamp.now(), uName: _actualUser.uName, userFullName: _actualUser.fullName }) as any
      })
        .then(() => {
          resolve(true);
        })
        .catch((e) => { reject(e) })
    } else
      resolve(false);
  });
}
// REAL TIME

// ----------- U T I L S -----------
export function initSession(db: FirestoreService, util: UtilService, actualUser: User, newTarget: { uName: string, dateFromTime: number, dateToTime: number }): Promise<TM_TimerSession> {
  return new Promise((resolve, reject) => {
    _actualUser = actualUser;

    // Check if the target is avalible
    return _getTimerSessionsForTargetUname(db, newTarget.uName)
      .then(timerSessions => {

        let targetAvalible = true;
        let alreadyOpenedSession: TM_TimerSession = null;
        for (let ts of timerSessions) {
          targetAvalible =
            // target is in the past
            (newTarget.dateFromTime < ts.target.dateFromTime && newTarget.dateToTime < ts.target.dateFromTime) ||
            // target is in the future
            (newTarget.dateFromTime > ts.target.dateToTime && newTarget.dateToTime > ts.target.dateToTime);
          if (!targetAvalible) {
            alreadyOpenedSession = ts;
            break;
          }
        }

        if (!targetAvalible) {
          // Target NOT avalible

          if (_actualTimerSession == null) {

            // User not has session on the window
            return _killTimerSession(db, util, alreadyOpenedSession)
              .then(killed => {
                if (killed) {
                  resolve(_creteTimerSession(db, util, newTarget));
                } else {
                  reject({ code: 423, message: `O usuário: "${alreadyOpenedSession.userFullName}" já esta editando este Período! Favor tentar novamente mais tarde!` });
                }
              })
              .catch((e) => { reject(e) });

          } else {

            // User has session
            if (alreadyOpenedSession.id == _actualTimerSession.id) {
              return _updateTimerSession(db, util, _actualTimerSession.id, { target: newTarget })
                .then(() => {
                  return _initActualTimerSessionSub(db, util, _actualTimerSession.id)
                    .then(() => {
                      resolve(_actualTimerSession);
                    })
                })
                .catch((e) => { reject(e) })
            } else {
              _endActualTimerSessionSub();
              return _deleteTimerSession(db, _actualTimerSession.id)
                .then(() => {
                  _actualTimerSession = null;
                  return _killTimerSession(db, util, alreadyOpenedSession)
                    .then(killed => {
                      if (killed) {
                        resolve(_creteTimerSession(db, util, newTarget));
                      } else {
                        reject({ code: 423, message: `O usuário: "${alreadyOpenedSession.userFullName}" já esta editando este Período! Favor tentar novamente mais tarde!` });
                      }
                    })
                })
                .catch((e) => { reject(e) })
            }
            // User has session

          }

          // Target NOT avalible
        } else
          // Target avalible
          resolve(_creteTimerSession(db, util, newTarget));
        // Target avalible

      })
      .catch((e) => { reject(e) });

    // }
  });
}
export function _endSession(db: FirestoreService, util: UtilService) {
  if (_actualTimerSessionSub != null) {
    _actualTimerSessionSub.unsubscribe();
  }
  if (_actualTimerSession && _actualTimerSession.id != '') {
    _deleteTimerSession(db, _actualTimerSession.id)
      .then(() => {
        _actualTimerSession = null;
        console.log('_endSession -> ok')
      })
      .catch((e) => { console.error('_endSession -> error', e) })
  }
}
export function checkSession(db: FirestoreService): Promise<boolean> {
  return new Promise((resolve, reject) => {
    if (_actualTimerSession && _actualTimerSessionSub != null && !_actualTimerSessionSub.closed) {
      return _getTimerSession(db, _actualTimerSession.id)
        .then(data => {
          if (data && data.alive)
            resolve(true);
          else
            resolve(false);
        })
        .catch(e => reject(e))
    } else
      resolve(false);
  });
}
// ----------- U T I L S -----------
