import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';

import { PM_Board, PM_BoardRoute, PM_Card } from 'app/@firebase';

import { PmCardModal } from '../../../../pm-card-modal';

@Component({
  selector: 'add-related-card-list',
  templateUrl: './add-related-card-list.component.html',
  styleUrls: ['./add-related-card-list.component.scss']
})
export class PMCardModalAddRelatedCardListComponent implements OnInit, OnChanges {

  className = 'PMCardModalAddRelatedCardListComponent';

  @Input() pmCardModal: PmCardModal;

  @Output() cardSelected = new EventEmitter<PM_Card>();

  @Input() board: PM_Board = new PM_Board();
  isUserAdmin = false;
  isBoardActive = false;

  _loader = true;
  _showArchivedCards = false;
  _showOthersBoardsCards = false;
  _searchText = '';
  @ViewChild('SearchInputERef', { static: true }) searchInputERef: ElementRef;


  _cards: PM_Card[] = [];
  _cards4Sel: PM_Card[] = [];

  _routesNamesIndex = {};
  _boardNamesIndex = {};

  _routesPerBoardIndex: Record<string, PM_BoardRoute[]> = {};

  constructor() { }

  ngOnInit() {
    this.load();
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }
  ngOnDestroy() { }

  private load() {
    this._loader = true;

    this._boardNamesIndex = {};
    this._routesNamesIndex = {};

    this.pmCardModal.authService.getUName()
      .then((uName) => {
        return this.pmCardModal.db.projectManagement.kanban.boards
          .getDataByWhere('members', 'array-contains', uName)
          .then(boards => {

            const boardsIds = Array.from(new Set(boards.map((board) => board.id)));
            boards.forEach(b => {
              this._boardNamesIndex[b.id] = b.name;
            })

            return this.pmCardModal.db.projectManagement.kanban.routes
              .getDataByWheres('boardId', '==', boardsIds)
              .then(routes => {
                this.pmCardModal.db.projectManagement.kanban.cards
                  .getDataByWheres('boardId', '==', boardsIds)
                  .then(cards => {

                    let relatedCardsIds = this.pmCardModal.card.relatedCardsIds;
                    this._cards = cards.filter((c) => { return !relatedCardsIds.includes(c.id) && c.id != this.pmCardModal.card.id; });
                    this._updateCards4Sel();

                    this._cards.forEach((card) => {
                      this._routesNamesIndex[card.id] = '';
                      const cardRoute = routes.find((r) => { return r.id == card.routeId; })
                      if (cardRoute)
                        this._routesNamesIndex[card.id] = cardRoute.name;
                    })

                    setTimeout(() => {
                      this.searchInputERef.nativeElement.focus();
                    }, 250);

                    this._loader = false;
                  })
              })
          })
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e)
      })
  }

  _updateCards4Sel() {
    this._searchText = '';
    this._updateSearchResult();
    this.searchInputERef.nativeElement.focus();
  }
  _updateSearchResult() {
    const tempAllCards = this._cards.filter((c) => { return (c.status == 1 || this._showArchivedCards) && (c.boardId == this.pmCardModal.card.boardId || this._showOthersBoardsCards) });
    this._cards4Sel = this._search(tempAllCards, this._searchText);
  }
  _search(cards: PM_Card[], searchText: string): PM_Card[] {
    if (!cards) {
      return [];
    }
    if (!searchText) {
      return cards;
    }
    searchText = searchText.toLocaleLowerCase();
    let tempItems = cards.filter(it => {
      let _searchText = it.id.toLocaleLowerCase() + ' - ' + it.title.toLocaleLowerCase();
      return (it.boardId == this.pmCardModal.card.boardId || this._showOthersBoardsCards) && _searchText.includes(searchText);
    });
    return tempItems;
  }
  _selectCard(card: PM_Card) {
    this.cardSelected.emit(card)
  }
  // Rotinas de controle
  _trackByFn(index, item) {
    return index;
  }
  _showOthersBoardsCardsChange() {
    this._updateCards4Sel();
  }
  _showArchivedCardsChange() {
    this._updateCards4Sel();
  }
}
