import { TM_IntegrationType } from "app/@firebase";

export class TM_IntegrationData {
  referenceType: TM_IntegrationType;

  presetProjectId: string;
  presetDescription: string;

  reference: string;
  reference1: string;
  reference2: string;
  reference3: string;

  public constructor(init?: Partial<TM_IntegrationData>) {
    this.referenceType = null;

    this.presetProjectId = '';
    this.presetDescription = '';

    this.reference = '';
    this.reference1 = '';
    this.reference2 = '';
    this.reference3 = '';

    if (init)
      Object.assign(this, init);
  }
}
