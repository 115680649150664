import { AuthService, UtilService } from "app/@core";
import { FirestoreService, Log, PM_BoardActivity, PM_BoardRoute, PM_Card } from "app/@firebase";

import { PM_Card_dataChangGen } from "../../components/pm-card-modal/PM_Card_dataChangGen";
import { OpenBoardComponent } from "../open-board.component";

export function __checkCardsRelationsToRoutes(_this: OpenBoardComponent) {
    if (_this._cardsRelationsToRoutesChecked)
        return;

    if (_this.routes.length == 0 && _this.cards.length == 0)
        setTimeout(() => {
            __checkCardsRelationsToRoutes(_this);
        }, 1000);

    // Validate cards relations to routes
    let cardIdsOnRoutes = [];
    _this.routes.forEach(r => {
        r.cards.forEach(c_id => {
            cardIdsOnRoutes.push(c_id);
        })
    })

    let lostCardIds = [];
    _this.cards.forEach(c => {
        if (cardIdsOnRoutes.indexOf(c.id) == -1)
            lostCardIds.push(c.id);
    });

    if (lostCardIds.length > 0 && _this.localDoc.routes.length > 0) {
        console.error('__checkCardsRelationsToRoutes -> lostCardIds ', lostCardIds);

        const firstRoute = JSON.parse(JSON.stringify(_this.routes[0])) as PM_BoardRoute;

        // Transaction
        _this.db
            .runTransaction((transaction) => {

                lostCardIds.forEach((card_id) => {
                    // Board Route
                    transaction.update(
                        _this.db.afs.firestore
                            .collection(_this.db.COLLECTIONS.projectManagement.kanban.routes).doc(firstRoute.id),
                        {
                            cards: _this.db.fieldValue.arrayUnion(card_id) as any,
                        }
                    )
                    // Board Route

                    // Card
                    transaction.update(
                        _this.db.afs.firestore
                            .collection(_this.db.COLLECTIONS.projectManagement.kanban.cards).doc(card_id),
                        {
                            routeId: firstRoute.id,
                        }
                    )
                    // Card
                });

                return Promise.resolve();
            })
            // Transaction

            .then(() => {
                _this.uiFeedBackCtrl.dismissLoader();
            })
            .catch((e) => {
                _this.uiFeedBackCtrl.dismissLoader();
                _this.uiFeedBackCtrl.presentErrorAlert(_this.pageDefinition.id, _this.pageDefinition.className, _this.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
            })
    }
    _this._cardsRelationsToRoutesChecked = true;
}
export function _updateCardRoute(utilCtrl: UtilService, db: FirestoreService, authService: AuthService, className: string, oldRouteId: string, newRouteId: string, boardRoutes: PM_BoardRoute[], card: PM_Card): Promise<void> {
    return new Promise((resolve, reject) => {
        const localDocPath = db.COLLECTIONS.projectManagement.kanban.boards;
        const now = utilCtrl.timestamp.now();
        const cardId = card.id;
        const objData = card;

        let newLogs: Log[] = [];
        let newActivities: PM_BoardActivity[] = [];

        let oldRoute = boardRoutes.find((r) => r.id == oldRouteId);
        let newRoute = boardRoutes.find((r) => r.id == newRouteId);

        db.runTransaction((transaction) => {

            // Card
            transaction.update(
                db.afs.firestore
                    .collection(db.COLLECTIONS.projectManagement.kanban.cards).doc(cardId),
                {
                    routeId: newRoute.id,

                    status: 1,
                    updatedBy: authService.localUser.uName,
                    updatedOn: now
                }
            );
            // OldRoute
            transaction.update(
                db.afs.firestore
                    .collection(db.COLLECTIONS.projectManagement.kanban.routes).doc(oldRoute.id),
                {
                    cards: db.fieldValue.arrayRemove(cardId) as any,

                    updatedBy: authService.localUser.uName,
                    updatedOn: now
                }
            );
            // NewRoute
            transaction.update(
                db.afs.firestore
                    .collection(db.COLLECTIONS.projectManagement.kanban.routes).doc(newRoute.id),
                {
                    cards: db.fieldValue.arrayUnion(cardId) as any,

                    updatedBy: authService.localUser.uName,
                    updatedOn: now
                }
            );

            let change = {
                fieldName: 'Rota',
                oldValue: oldRoute.name,
                newValue: newRoute.name
            };

            // Log
            let cardLog = new Log();

            cardLog.id = `${localDocPath}-${db.afs.createId()}`;
            cardLog.className = className;
            cardLog.uName = authService.localUser.uName;

            cardLog.type = "AUDIT";
            cardLog.category = "FC";

            cardLog.docPath = localDocPath;
            cardLog.docId = cardId;
            cardLog.changes = [change];
            cardLog.createdOn = now;
            newLogs.push(cardLog);
            // Log

            // Activity
            let cardActivity = new PM_BoardActivity();

            cardActivity.id = db.afs.createId();
            cardActivity.uName = authService.localUser.uName;
            cardActivity.cardId = cardId;
            cardActivity.boardId = card.boardId;

            cardActivity.type = "FC";

            cardActivity.changes = [change];
            cardActivity.createdOn = now;
            newActivities.push(cardActivity);
            // Activity

            // Log
            newLogs.forEach((log) => {
                transaction.set(
                    db.afs.firestore
                        .collection(db.COLLECTIONS.sys.logs).doc(log.id),
                    Object.assign({}, log)
                );
            });
            // Log

            // Activity
            newActivities.forEach((actv) => {
                transaction.set(
                    db.afs.firestore
                        .collection(db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(actv.id),
                    Object.assign({}, actv)
                );
            });
            // Activity

            return Promise.resolve();
        })
            .then(() => {
                if (newRoute.final)
                    resolve(__markCardAsDone(utilCtrl, db, authService, className, card));
                else
                    resolve();
            })
            .catch((e) => reject(e));
    })
}
export function __markCardAsDone(utilCtrl: UtilService, db: FirestoreService, authService: AuthService, className: string, card: PM_Card): Promise<void> {
    return new Promise((resolve, reject) => {
        const localDocPath = db.COLLECTIONS.projectManagement.kanban.boards;
        const now = utilCtrl.timestamp.now();
        const cardId = card.id;

        const oldCard = new PM_Card(JSON.parse(JSON.stringify(card)));
        let newCard = new PM_Card(JSON.parse(JSON.stringify(card)));

        // Apply changes
        newCard.status = 1;
        let needSaveDoneAt = false;

        newCard.done = true;
        if (!newCard.doneAt) {
            newCard.doneAt = now;
            let doneAtTimeStr = utilCtrl.timestamp.getDateTimeString(now);
            newCard.doneAtString = doneAtTimeStr.split('T')[0];
            newCard.doneAtHourString = doneAtTimeStr.split('T')[1];
            needSaveDoneAt = true;
        }

        // Log
        let tempLog = new Log();

        tempLog.id = `${localDocPath}-${db.afs.createId()}`;
        tempLog.className = className;
        tempLog.uName = authService.localUser.uName;

        tempLog.type = "AUDIT";
        tempLog.category = "FC";

        tempLog.docPath = db.COLLECTIONS.projectManagement.kanban.cards;
        tempLog.docId = cardId;
        // Log

        // Activity
        let tempActivity = new PM_BoardActivity();

        tempActivity.id = db.afs.createId();
        tempActivity.uName = authService.localUser.uName;
        tempActivity.cardId = cardId;
        tempActivity.boardId = oldCard.boardId;

        tempActivity.type = "FC";
        // Activity

        let updateData = null;
        updateData = {
            done: newCard.done,

            status: 1,
            updatedBy: authService.localUser.uName,
            updatedOn: now,
        };

        if (needSaveDoneAt)
            updateData = {
                ...updateData,
                doneAt: newCard.doneAt,
                doneAtString: newCard.doneAtString,
                doneAtHourString: newCard.doneAtHourString,
            };

        tempLog.changes = PM_Card_dataChangGen(oldCard, newCard, [], [], [], utilCtrl);
        tempActivity.changes = PM_Card_dataChangGen(oldCard, newCard, [], [], [], utilCtrl);

        // Transaction
        db.runTransaction((transaction) => {

            // Card
            transaction.update(
                db.afs.firestore
                    .collection(db.COLLECTIONS.projectManagement.kanban.cards).doc(oldCard.id),
                updateData
            );
            // Card

            // Log
            transaction.set(
                db.afs.firestore
                    .collection(db.COLLECTIONS.sys.logs).doc(tempLog.id),
                Object.assign({}, tempLog)
            );
            // Log

            // Activity
            transaction.set(
                db.afs.firestore
                    .collection(db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
                Object.assign({}, tempActivity)
            );
            // Activity

            return Promise.resolve();
        })
            .then(() => resolve())
            .catch((e) => reject(e));
    });
}
