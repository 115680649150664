import firebase from "firebase/compat/app";
import { SM_DocPriority } from "./SM_DocPriority";
import { SM_DocState } from "./types";

export type SM_DocType = 'request' | 'task' | 'incident' | 'problem' | 'change';
export type SM_ContactType = 'email' | 'phone' | 'selfService' | 'Walk-in';

export type SM_DocApprovalStatus = 'A' | 'R' | 'P' | 'NA'

/**
* @1 High
* @2 medium
* @3 low
*/
export type SM_DocImpact = 1 | 2 | 3;
/**
 * @1 High
 * @2 medium
 * @3 low
 */
export type SM_DocUrgency = 1 | 2 | 3;

export class SM_Document {

  id: string;

  parentDocId: string;
  parentDocType: SM_DocType;


  // caller
  callerUname: string;
  callerFullName: string;
  callerPhone: string;
  callerEmail: string;
  callerCompanyId: string;
  callerBusinessPlaceId: string;
  // caller

  categoryId: string;
  subcategoryId: string;
  businessRoleId: string;
  configItemId: string;
  calendarId: string;

  contactType: SM_ContactType;

  // https://www.youtube.com/watch?v=ei0tUyFMlhk
  // https://docs.servicenow.com/bundle/london-it-service-management/page/product/incident-management/concept/c_IncidentManagementStateModel.html
  /**
  * New (verde) - Novo no grupo
  * Work in Progress - Ao ter um analista
  * Awainting Assignment (verde) - ja possuiu um analista mas não possui mais
  * Work Scheduled - Pausa SLA para contar apartir da data preenchida no scheduledDate
  * Pending Problem - Pendente problema (chamado será solucionado automaticamente quando o problema for resolvido) campo problemId deverá ser preenchido
  * Pending Caller - Pendente usuário (commentarios)
  * Pending Vendor - Pendente fornecedor (commentarios)
  * Resolved - Trabalho encerrado solução dada ao solicitante (pode ser reaberto)
  * Closed - Após um determinado tempo como RESOLVED automaticamente o chamado é Closed e não pode ser mais reaberto (Final não pode ser reaberto)
  * Canceled - O chamado foi triado porem foi considerado desnecessario ou duplicado (Final não pode ser reaberto)
  */
  state: SM_DocState;

  active: boolean;

  impact: SM_DocImpact;
  urgency: SM_DocUrgency;
  priority: SM_DocPriority;

  assignedTeamId: string;
  assignedToUName: string;

  title: string;
  text: string;
  html: string;

  type: SM_DocType;
  approvalStatus: SM_DocApprovalStatus;

  dueDate: firebase.firestore.Timestamp;
  scheduledDate: firebase.firestore.Timestamp;

  // Resolution
  userWorkStart: firebase.firestore.Timestamp;
  userWorkEnd: firebase.firestore.Timestamp;
  userWorkTimeDurationMs: number;

  workStart: firebase.firestore.Timestamp;
  workEnd: firebase.firestore.Timestamp;
  workTimeDurationMs: number;

  resolvedBy: string;
  resolvedOn: firebase.firestore.Timestamp;
  resolutionCauseId: string;
  resolutionId: string;
  resolutionNote: string;
  // Resolution

  reopened: boolean;
  lastReopenOn: firebase.firestore.Timestamp;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<SM_Document>) {

    this.id = '';

    this.parentDocId = '';
    this.parentDocType = null;

    // caller
    this.callerUname = '';
    this.callerFullName = '';
    this.callerPhone = '';
    this.callerEmail = '';
    this.callerCompanyId = '';
    this.callerBusinessPlaceId = '';
    // caller

    this.categoryId = '';
    this.subcategoryId = '';
    this.businessRoleId = '';
    this.configItemId = '';
    this.calendarId = '';

    this.contactType = 'selfService';

    this.state = 'new';

    this.active = true;

    this.impact = 1;
    this.urgency = 1;
    this.priority = 'P5';

    this.assignedTeamId = '';
    this.assignedToUName = '';

    this.title = '';
    this.text = '';
    this.html = '';

    this.type = null;
    this.approvalStatus = 'NA';

    this.dueDate = null;
    this.scheduledDate = null;

    // Resolution
    this.userWorkStart = null;
    this.userWorkEnd = null;
    this.userWorkTimeDurationMs = 0;

    this.workStart = null;
    this.workEnd = null;
    this.workTimeDurationMs = 0;

    this.resolvedBy = '';
    this.resolvedOn = null;
    this.resolutionCauseId = '';
    this.resolutionId = '';
    this.resolutionNote = '';
    // Resolution

    this.reopened = false;
    this.lastReopenOn = null;

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
