import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbButtonModule, NbCardModule, NbIconModule } from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { ThemeModule } from 'app/@theme';

import { MagicTableSettingsModalComponent } from './components/modals/magic-table-settings-modal/magic-table-settings-modal.component';
import { MagicTableColOptionsOverlayComponent } from './components/overlays/magic-table-col-options-overlay/magic-table-col-options-overlay.component';
import { MagicTableHighlightDirective } from './directives/magic-table-highlight.directive';
import { MagicControlOptionComponent } from './magic-control/magic-control-option.component';
import { MagicControlComponent } from './magic-control/magic-control.component';
import { MagicTableComponent } from './magic-table.component';

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  ThemeModule,

  Ng2SmartTableModule,
  NbCardModule,
  NbButtonModule,
  NbEvaIconsModule,
  NbIconModule,
  ScrollingModule,
];

const COMPONENTS = [
  MagicTableComponent,
  MagicControlComponent,
  MagicControlOptionComponent,
  MagicTableHighlightDirective,
  MagicTableColOptionsOverlayComponent,
  MagicTableSettingsModalComponent,
];

@NgModule({
  imports: [...BASE_MODULES],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS]
})
export class MagicTableModule { }
