<!-- <nb-layout windowMode> -->
<nb-layout>
    <nb-layout-header id="nb-layout-header" *ngIf="!fullscreen" fixed>
        <ic-header></ic-header>
    </nb-layout-header>

    <nb-sidebar [left]="true" class="menu-sidebar" tag="menu-sidebar" responsive start>
        <ng-content select="nb-menu"></ng-content>
    </nb-sidebar>
    <nb-sidebar [right]="true" [responsive]="false" class="notif-sidebar" tag="notif-sidebar" end>
        <ic-notifications-list></ic-notifications-list>
    </nb-sidebar>

    <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
    </nb-layout-column>

    <nb-layout-footer *ngIf="!fullscreen" fixed>
        <ic-footer></ic-footer>
    </nb-layout-footer>
</nb-layout>