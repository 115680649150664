import firebase from "firebase/compat/app";
import { PM_ToDo } from "./PM_ToDo";

export class PM_TODO_DUEDATE_STATUS_DATA_TYPE {
  id: PM_TODO_DUEDATE_STATUS_TYPE;
  name: string;
  color: string;
  background_color: string;
  public constructor(init?: Partial<PM_TODO_DUEDATE_STATUS_DATA_TYPE>) {
    this.id = "none";
    this.name = "";
    this.color = "";
    this.background_color = "";
  }
}
export type PM_TODO_DUEDATE_STATUS_TYPE = 'none' | 'on-time' | 'expired' | 'done-on-time' | 'done-late';
export const PM_TODO_DUEDATE_STATUS_DATA_ARRAY: PM_TODO_DUEDATE_STATUS_DATA_TYPE[] = [
  {
    id: 'none',
    name: 'Sem Prazo',
    color: '#000000',
    background_color: '#c0c0c0' // cinza claro
  },
  {
    id: 'on-time',
    name: 'No Prazo',
    color: '#000000',
    background_color: '#ffff00' // amarelo
  },
  {
    id: 'expired',
    name: 'Vencido',
    color: '#000000',
    background_color: '#ff0000' // vermelho
  },
  {
    id: 'done-on-time',
    name: 'Entregue (No Prazo)',
    color: '#000000',
    background_color: '#98fb98' // verde pastel
  },
  {
    id: 'done-late',
    name: 'Entregue (Vencido)',
    color: '#000000',
    background_color: '#ffcc99' // laranja pastel
  },
];
export function getPMToDoDueDateStatusData(dueDateStatus: PM_TODO_DUEDATE_STATUS_TYPE): PM_TODO_DUEDATE_STATUS_DATA_TYPE {
  let ddsd = new PM_TODO_DUEDATE_STATUS_DATA_TYPE();
  for (let _ddsd of PM_TODO_DUEDATE_STATUS_DATA_ARRAY)
    if (_ddsd.id == dueDateStatus) {
      ddsd = _ddsd;
      break;
    }
  return ddsd;
}
export function pmToDoDueDateStatusDetermination(toDO: PM_ToDo): PM_TODO_DUEDATE_STATUS_TYPE {
  let tempDueDateStatus: PM_TODO_DUEDATE_STATUS_TYPE = 'none';
  let timeNowMs = new Date().getTime();

  let dueDate_timeMs = 0;
  let doneAt_timeMs = 0;

  if (toDO.dueDate)
    dueDate_timeMs = __timeStampToDate(toDO.dueDate).getTime();
  if (toDO.doneAt)
    doneAt_timeMs = __timeStampToDate(toDO.doneAt).getTime();

  if (toDO.dueDate) {
    // done
    if (toDO.done) {
      if (dueDate_timeMs < doneAt_timeMs || doneAt_timeMs == 0)
        tempDueDateStatus = 'done-late';
      else
        tempDueDateStatus = 'done-on-time';
    }
    // not done
    if (!toDO.done) {
      if (dueDate_timeMs < timeNowMs)
        tempDueDateStatus = 'expired';
      else
        tempDueDateStatus = 'on-time';
    }
  }
  return tempDueDateStatus;
}
function __timeStampToDate(timeStamp: firebase.firestore.Timestamp): Date {
  if (!timeStamp)
    return null;

  if (timeStamp.seconds != null && timeStamp.nanoseconds != null) {
    return new Date((timeStamp.seconds * 1000 + timeStamp.nanoseconds / 1e6));
  }

  let _timeStamp = timeStamp as any;
  if (_timeStamp._seconds != null && _timeStamp._nanoseconds != null) {
    return new Date((_timeStamp._seconds * 1000 + _timeStamp._nanoseconds / 1e6));
  }

  return null;
}