<div class="ic-user">
    <div class="user_container" [ngClass]="{ 'square': shape == 'square', 'round': shape == 'round' }">
        <div class="user" [ngClass]="{ 'picture': picture, 'initials': !picture && !icon, 'icon': !picture && icon}"
            [title]="name">

            <div *ngIf="picture" class="picture" [style]="'background-image: url(' + picture +');'"></div>

            <span *ngIf="!picture && !icon">{{ getInitials() }}</span>

            <i *ngIf="!picture && icon" [ngClass]="getClassesForIcon()"></i>
        </div>
    </div>

    <div *ngIf="!onlyPicture" class="info_container">
        <div class="user_name">
            {{name}}
        </div>
        <div class="user_title">
            {{title}}
        </div>
    </div>
</div>