import firebase from "firebase/compat/app";

export class RoleHist {

  id: string;
  roleId: string;
  userId: string;
  time: firebase.firestore.Timestamp;

  title: string;
  type: 'CREATE' | 'DELETE' | 'UPDATE';

  path: string;
  from: string;
  to: string;



  public constructor(init?: Partial<RoleHist>) {

    this.id = '';
    this.roleId = '';
    this.userId = '';
    this.time = firebase.firestore.Timestamp.now();

    this.title = '';
    this.type = null;

    this.path = '';
    this.from = '';
    this.to = '';

    if (init)
      Object.assign(this, init);
  }
}
