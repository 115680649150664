import firebase from "firebase/compat/app";

export class Role {

  id: string;
  description: string;
  /**
   * C - COMPOSITE ROLE
   * S - SINGLE ROLE
   * D - DERVIED ROLE
   */
  type: 'C' | 'S';

  childRoles: string[];

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<Role>) {

    this.id = '';
    this.description = '';
    this.type = 'S';

    this.childRoles = [];

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
