export class PM_CARD_PRIORITY_DATA_TYPE {
  id: PM_CardPriority;
  name: string;
  color: string;
  background_color: string;
  description: string;
  suggestedUse: string;
  public constructor(init?: Partial<PM_CARD_PRIORITY_DATA_TYPE>) {
    this.id = null;
    this.name = "";
    this.color = "";
    this.background_color = "";
    this.description = "";
    this.suggestedUse = "";
  }
}

export const PM_CARD_PRIORITY_DATA_ARRAY: PM_CARD_PRIORITY_DATA_TYPE[] = [
  {
    id: null,
    name: 'Sem Prioridade',
    color: '#000000',
    background_color: '#c0c0c0', // cinza claro
    description: '',
    suggestedUse: ''
  },
  {
    id: 'P1',
    name: 'Crítico',
    color: '#000000',
    /** Vermelho intenso para destacar a urgência e importância crítica dessas tarefas. */
    background_color: '#FF0000',
    description: 'Tarefas que são de extrema importância e urgência, impactando diretamente o cronograma do projeto.',
    suggestedUse: 'Reservar recursos adicionais ou atenção extra para resolver essas tarefas imediatamente.'
  },
  {
    id: 'P2',
    name: 'Importante',
    color: '#000000',
    /** Laranja para indicar importância, embora não tão crítico quanto vermelho. */
    background_color: '#FFA500',
    description: 'Tarefas essenciais para alcançar os objetivos do projeto, mas não tão urgentes quanto as críticas.',
    suggestedUse: 'Gerenciar de perto para garantir que sejam concluídas a tempo, sem prejudicar o projeto.'
  },
  {
    id: 'P3',
    name: 'Normal',
    color: '#000000',
    /** Amarelo para indicar prioridade moderada, não tão urgente quanto laranja ou vermelho. */
    background_color: '#FFFF00',
    description: 'Tarefas que não são urgentes, mas ainda precisam ser concluídas dentro do prazo estabelecido.',
    suggestedUse: 'Realizar conforme a disponibilidade de recursos e tempo permitir, mantendo o projeto em andamento.'
  },
  {
    id: 'P4',
    name: 'Baixa',
    color: '#000000',
    /** Verde claro para indicar baixa urgência, podem ser tratadas posteriormente. */
    background_color: '#00FF00',
    description: 'Tarefas que podem ser adiadas sem causar um grande impacto no projeto.',
    suggestedUse: 'Tratar quando outras prioridades mais altas forem resolvidas, mantendo o projeto fluindo.'
  },
  {
    id: 'P5',
    name: 'Planejada',
    color: '#000000',
    /** Azul claro para indicar tarefas relacionadas ao planejamento futuro do projeto. */
    background_color: '#ADD8E6',
    description: 'Tarefas relacionadas ao planejamento futuro do projeto, não urgentes no momento.',
    suggestedUse: 'Considerar para o planejamento a longo prazo, garantindo a continuidade e sucesso do projeto.'
  }
];
export function getPMCardPriorityData(priority: PM_CardPriority): PM_CARD_PRIORITY_DATA_TYPE {
  let pd = new PM_CARD_PRIORITY_DATA_TYPE();
  for (let _pd of PM_CARD_PRIORITY_DATA_ARRAY)
    if (_pd.id == priority) {
      pd = _pd;
      break;
    }
  return pd;
}

/**
 * @P1 Critical
 * @P2 Important
 * @P3 Normal
 * @P4 Low
 * @P5 Planning
 */
export type PM_CardPriority = null | 'P1' | 'P2' | 'P3' | 'P4' | 'P5';


// export function calculatePriority(impact: SM_DocImpact, urgency: SM_DocUrgency): SM_DocPriority {
//   let priority: SM_DocPriority = "P5";

//   if (impact == 1) {
//     if (urgency == 1)
//       priority = "P5";
//     else
//       if (urgency == 2)
//         priority = "P4";
//       else
//         if (urgency == 3)
//           priority = "P3";
//   } else

//     if (impact == 2) {
//       if (urgency == 1)
//         priority = "P4";
//       else
//         if (urgency == 2)
//           priority = "P3";
//         else
//           if (urgency == 3)
//             priority = "P2";
//     } else

//       if (impact == 3) {
//         if (urgency == 1)
//           priority = "P3";
//         else
//           if (urgency == 2)
//             priority = "P2";
//           else
//             if (urgency == 3)
//               priority = "P1";
//       }
//   return priority;
// }

