import { MagicTableComponent } from "../magic-table.component";
import { EMPTY_ARRAY_DATA_VALUE, EMPTY_ARRAY_FILTER_VALUE } from "../magic-table.consts";
import { FilterIndexData, MagicTableRowData } from "../magic-table.types";
import { _genDispFilterValue, _getFilterStatus, _updateFiltredRows } from "./magic-table_filter";
import { _orderByCore } from "./magic-table.util";

// Filter Popdown
export function _openFilterPopDown(_this: MagicTableComponent, col_index: number) {
    _this._filterPopdownData.searchText = '';
    _this._filterPopdownData.AddCurSelTofilter = false;

    let tempRows: MagicTableRowData[] = [];
    for (let row of _this.table.rows) {
        let tempRow = row;
        let validRow = true;
        for (let col_index1 = 0; col_index1 < _this.table.cols.length; col_index1++) {
            if (col_index1 != col_index && _this.filterActiveIndex[col_index1]) {
                validRow = _getFilterStatus(_this, col_index1, tempRow.cells[col_index1]);
                if (!validRow)
                    break;
            }
        }
        if (validRow)
            tempRows.push(row);
    }
    let tempColIndex: FilterIndexData[] = [];
    let tempSingleValues = [];

    for (let r of tempRows) {
        const col = _this.table.cols[col_index];
        const fData = r.obj[col.fieldName];
        const fCell = _genDispFilterValue(col, r);

        if (!Array.isArray(fData)) {
            // not array
            if (tempSingleValues.indexOf(fCell) == -1) {
                tempSingleValues.push(fCell)
                let show = _getFilterStatus(_this, col_index, fData);
                tempColIndex.push({ show: show, data: fData, cell: fCell as any })
            }
        } else {
            // array
            for (let i = 0; i < fData.length; i++) {
                const fd = fData[i];
                const fc = fCell[i];
                if (tempSingleValues.indexOf(fc) == -1) {
                    tempSingleValues.push(fc)
                    let show = _getFilterStatus(_this, col_index, fd);
                    tempColIndex.push({ show: show, data: fd, cell: fc as any })
                }
            }
            // empty array
            if (fData.length == 0) {
                if (tempSingleValues.indexOf(EMPTY_ARRAY_FILTER_VALUE) == -1) {
                    tempSingleValues.push(EMPTY_ARRAY_FILTER_VALUE);
                    let show = _getFilterStatus(_this, col_index, EMPTY_ARRAY_DATA_VALUE);
                    tempColIndex.push({ show: show, data: EMPTY_ARRAY_DATA_VALUE, cell: EMPTY_ARRAY_FILTER_VALUE })
                }
            }
        }
    }

    const ord = _this.orderByIndex[col_index] ? _this.orderByIndex[col_index] : "asc";
    tempColIndex.sort((a, b) => _orderByCore(a.cell, b.cell, ord));

    _this._filterPopdownData.filterIndex = JSON.parse(JSON.stringify(tempColIndex));
    _this._filterPopdownData.searchResult = JSON.parse(JSON.stringify(tempColIndex));
    _this._filterPopdownData.selectAll = !_this.filterActiveIndex[col_index];
    _this.showFilterPopdown = col_index;
    setTimeout(() => {
        document.getElementById('FilterPopdownSearchInput_' + col_index).focus();
    }, 200);
}
export function _closeFilterPopDown(_this: MagicTableComponent) {
    _this.showFilterPopdown = -1;
}



export function _toogleSort(_this: MagicTableComponent, col_index: number, ord: "asc" | "desc") {
    _this.orderByIndex[col_index] = _this.orderByIndex[col_index] == ord ? null : ord;

    _this.actualPage = 1;
    _this.input_page = _this.actualPage;
    _updateFiltredRows(_this);
    _this._updateRowsForDisplay();
    _this.closeFilterPopdown();
}
