<div #LoaderModalContent class="ufb-modal">
  <div class="ufb-modal-inner">
    <div class="ufb-card ufb-loader">
      <div class="ufb-card-body">

        <div style="display: flex">
          <div style="width: 40%;">
            <ng-lottie [options]="lottie" width="80px" height="80px" containerClass="ng-lottie-inner">
            </ng-lottie>
          </div>
          <div style="width: 80%;display: flex;align-items: center;">
            {{message}}
          </div>
        </div>

      </div>
    </div>
  </div>
</div>