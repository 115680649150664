<div #AletModalContent class="ufb-modal">
  <div class="ufb-modal-inner">

    <div class="ufb-card ufb-alert">
      <div class="ufb-card-header">
        <span class="ufb-card-title">
          {{title}}
        </span>
        <button class="close" mat-button matDialogClose>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="ufb-card-body">
        <div class="grid" *ngIf="lottie && message.length > 0;">
          <div class="row" justify-content-center>
            <div class="col-4">
              <ng-lottie [options]="lottieOptions" width="100px" containerClass="ng-lottie-inner">
              </ng-lottie>
            </div>
            <div class="col-8">
              <div id="alert-message-{{id}}"></div>
            </div>
          </div>
        </div>

        <div id="alert-message-{{id}}" *ngIf="!lottie && message.length > 0;"></div>

        <div class="grid" *ngIf="lottie && message.length == 0;">
          <div class="row" justify-content-center>
            <div class="col-12">
              <ng-lottie [options]="lottieOptions" width="100px" containerClass="ng-lottie-inner">
              </ng-lottie>
            </div>
          </div>
        </div>
      </div>

      <div class="ufb-card-footer">
        <div class="ic-button-grp">
          <button nbButton matDialogClose status="{{btn.status}}" title="{{btn.title}}">
            <nb-icon *ngIf="btn.icon" icon="{{btn.icon}}" pack="eva"></nb-icon>
            {{btn.title}}
          </button>
        </div>
      </div>
    </div>

  </div>
</div>