import firebase from "firebase/compat/app";

export type TM_IntegrationType = "PM_Card" | "PM_Card-ToDo" | "PM_Card-Activity";

//   referenceType: "PM_Card" | "PM_Card-ToDo" | "PM_Card-Activity"
//   reference - > boardId,
//   reference1 -> cardId,
//   reference2 -> activityId
//   reference3 -> toDoId

export class TM_TimeRecord {

  id: string;
  uName: string;

  teamId: string;
  projectId: string;
  parentKey: string;
  taskId: string;
  tagsIds: string[];

  description: string;

  date: firebase.firestore.Timestamp;
  dateWeek: number;
  dateMonth: number;
  dateYear: number;
  dateDay: number;

  durationHours: number;
  durationMinutes: number;
  duration: number;

  referenceType: TM_IntegrationType;
  reference: string;
  reference1: string;
  reference2: string;
  reference3: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<TM_TimeRecord>) {

    this.id = '';
    this.uName = '';

    this.teamId = '';
    this.projectId = '';
    this.parentKey = '';
    this.taskId = '';
    this.tagsIds = [];

    this.description = '';

    this.date = firebase.firestore.Timestamp.now();
    this.dateWeek = 0;
    this.dateMonth = 0;
    this.dateYear = 0;
    this.dateDay = 0;

    this.referenceType = null;
    this.reference = '';
    this.reference1 = '';
    this.reference2 = '';
    this.reference3 = '';

    this.durationHours = 0;
    this.durationMinutes = 0;
    this.duration = 0;

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;


    if (init)
      Object.assign(this, init);
  }
}
