import { DbTableComponent } from "../db-table.component";
import { DbTableRowData } from "../db-table.types";
import { dbTableFilterValueDetermination } from "./db-table-filter-value-determination";
import { dbTableGetFilterStatus } from "./db-table-get-filter-status";
import { dbTableOrderByCore } from "./db-table-order-by-core";

/**
 * Atualiza as linhas filtradas apartir das linhas da tabela filtros
 */
export function dbTableUpdateFiltredRows(dbTableComponent: DbTableComponent) {
    let tempRows: DbTableRowData[] = [];
    for (let row of dbTableComponent.table.rows) {
        let tempRow = row;
        let validRow = true;
        for (let c_index = 0; c_index < dbTableComponent.table.cols.length; c_index++) {
            if (dbTableComponent.filterActiveIndex[c_index]) {
                const fValue = dbTableFilterValueDetermination(tempRow, c_index, dbTableComponent.table.cols[c_index]);
                validRow = dbTableGetFilterStatus(dbTableComponent, c_index, fValue);
                if (!validRow) break;
            }
        }
        if (validRow)
            tempRows.push(row);
    }

    // -sort rows
    tempRows.sort((rowA, rowB) => {
        let result = 0;
        for (let i = 0; i < dbTableComponent.orderByIndex.length; i++) {
            if (dbTableComponent.orderByIndex[i]) {
                const rowAData = rowA.cellsData[i];
                const rowBData = rowB.cellsData[i];

                // const rowAData = rowA.cellsData[i] == MAGIC_TABLE_CONSTS.EMPTY_ARRAY_DATA_VALUE ? MAGIC_TABLE_CONSTS.EMPTY_CELL_VALUE : rowA.cellsData[i];
                // const rowBData = rowB.cellsData[i] == MAGIC_TABLE_CONSTS.EMPTY_ARRAY_DATA_VALUE ? MAGIC_TABLE_CONSTS.EMPTY_CELL_VALUE : rowB.cellsData[i];
                
                result = dbTableOrderByCore(rowAData, rowBData, dbTableComponent.orderByIndex[i]);
            }

            if (result !== 0)
                break;
        }
        return result;
    });

    dbTableComponent.filtredRows = tempRows;
}