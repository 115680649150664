<div class="timmer">
  <div class="inner">
    <div class="duration">
      <span class="time-format-utils__duration">
        {{data.timeStr}}
      </span>
    </div>

    <div class="buttons-container">

      <button *ngIf="!running" class="play" title="Start timer" (click)="toggle()">
        <nb-icon icon="play-circle-outline" pack="eva"></nb-icon>
      </button>

      <button *ngIf="running" class="stop" title="Stop timer" (click)="toggle()">
        <nb-icon icon="stop-circle-outline" pack="eva"></nb-icon>

      </button>

      <button [disabled]="runnedTimeMs == 0" class="reset" title="Reset timer" (click)="reset()">
        <nb-icon icon="refresh-outline" pack="eva"></nb-icon>
      </button>

    </div>

  </div>

</div>
