<div #SmartTableSelectModalContent class="ufb-modal">
  <div class="ufb-modal-inner">

    <div class="ufb-card smart-table-select">
      <div class="ufb-card-header">
        <span class="ufb-card-title">
          Selecionar
        </span>
        <button class="close" mat-button (click)="closeDialog()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="ufb-card-body">
        <ng2-smart-table *ngIf="showTable" [settings]="nstSettings" [source]="data.source">
        </ng2-smart-table>
      </div>

      <div class="ufb-card-footer">
        <button class="button-to-end" nbButton status="danger" size="small" (click)="closeDialog()">
          <nb-icon icon="close-outline" pack="eva"></nb-icon>
          Cancelar
        </button>
      </div>
    </div>

  </div>
</div>