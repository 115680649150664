import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { NgSelectModule } from '@ng-select/ng-select';
import player from 'lottie-web';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxEchartsModule } from 'ngx-echarts';
import { LottieModule } from 'ngx-lottie';
import { MonacoEditorModule } from 'ngx-monaco-editor';

export function playerFactory() {
    return player;
}

export const NG_MODULES_IMPORTS = [
    Ng2SmartTableModule,
    NgSelectModule,
    NgxEchartsModule.forRoot({
        echarts: () => import('echarts')
    }),
    LottieModule.forRoot({ player: playerFactory }),
    MonacoEditorModule.forRoot(),
    MatDialogModule,
    MatListModule,
    OverlayModule,
    DragDropModule,
    ScrollingModule,
];

export const NG_MODULES_EXPORTS = [
    Ng2SmartTableModule,
    NgSelectModule,
    NgxEchartsModule,
    LottieModule,
    MonacoEditorModule,
    MatDialogModule,
    MatListModule,
    OverlayModule,
    DragDropModule,
    ScrollingModule,
];