import firebase from "firebase/compat/app";
import { ProcessingLog } from "../ProcessingLog";
import { DB_OrderByRule } from "./DB_OrderByRule";
export type DB_ReportStatus = 'waiting' | 'running' | 'done' | 'error';

export class DB_Report {

  id: string;

  uName: string;
  dataSourceId: string;
  pStatus: DB_ReportStatus;
  pLogs: ProcessingLog[];

  limit: number;
  filterRuleSetJson: string;
  orderByRule: DB_OrderByRule;
  queryText: string;

  resultUrl: string;
  resultSize: number;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<DB_Report>) {

    this.id = '';

    this.uName = '';
    this.dataSourceId = '';
    this.pStatus = 'waiting';
    this.pLogs = [];

    this.limit = 0;
    this.filterRuleSetJson = '';
    this.orderByRule = new DB_OrderByRule();
    this.queryText = '';

    this.resultUrl = '';
    this.resultSize = 0;

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
