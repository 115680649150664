import firebase from "firebase/compat/app";

export class RM_RiskEvent {

  id: string;
  description: string;

  risksIds: string[];

  // Avaliação Risco inerente (Utima)
  probability: number;
  impact: number;
  criticality: number;

  // Avaliação Ambiente de Controle (Média Controles)
  controlsEnvironment: number;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<RM_RiskEvent>) {

    this.id = '';
    this.description = '';

    this.probability = 0;
    this.impact = 0;
    this.criticality = 0;

    this.controlsEnvironment = 0;

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
