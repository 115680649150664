import firebase from "firebase/compat/app";

export class ChatMessageFile {
  url: string;
  icon?: string;
  type?: 'image/gif' | 'image/jpeg';
  public constructor(init?: Partial<ChatMessageFile>) {
    this.url = '';
    if (init)
      Object.assign(this, init);
  }
}

export class ChatMessage {

  id: string;

  /**
   * Message text
   * @type {string}
   */
  message: string;
  /**
   * Message sender
   * @type {string}
   */
  sender: string;
  /**
   * Message send date
   * @type {Date}
   */
  date: Date;
  /**
   * Message send date format, default 'shortTime'
   * @type {string}
   */
  dateFormat: string;
  /**
   * Array of files `{ url: 'file url', icon: 'file icon class' }`
   */
  files: ChatMessageFile[];
  /**
   * Quoted message text
   * @type {string}
   */
  quote: string;
  /**
   * Map latitude
   * @type {number}
   */
  latitude: number;
  /**
   * Map longitude
   * @type {number}
   */
  longitude: number;
  /**
   * Message send avatar
   * @type {string}
   */
  avatar: string;
  /**
   * Message type, available options `text|file|map|quote`
   * @type {string}
   */
  type: "text" | "file" | "map" | "quote";;

  uName: string;

  createdOn: firebase.firestore.Timestamp;


  public constructor(init?: Partial<ChatMessage>) {



    if (init)
      Object.assign(this, init);
  }
}
