export class DbTable {
  cols: DbTableColumn[] = [];
  rows: DbTableRowData[] = [];
}
export class DbTableSelectData {
  value: any;
  name: string;
  public constructor(init?: Partial<DbTableSelectData>) {
    this.value = null;
    this.name = "";
    if (init)
      Object.assign(this, init);
  }
}
export class DbTableSelectOptions {
  optionsData: any[];
  optionsFilter?: (cell?: any, row?: any, optionsData?: any[], table?: DbTable, aditionalData?: {}) => any[];
  optionValue: (optionData?: {}) => any;
  optionName: (optionData?: {}) => string;
  public constructor(init?: Partial<DbTableSelectOptions>) {
    this.optionsData = [];
    this.optionsFilter = function (cell?, row?, optionsData?, table?, aditionalData?) { return optionsData.filter(op => { return true }) }
    this.optionValue = function (optionData?) { return optionData['id'] };
    this.optionName = function (optionData?) { return optionData["name"] };
    if (init)
      Object.assign(this, init);
  }
}
export class DbTableInput {
  type?: 'text' | 'number' | 'select' | 'date';
  minlength?: number;
  maxlength?: number;
  min?: number;
  max?: number;
  pattern?: RegExp;
  required?: (cell?: any, row?: any, aditionalData?: {}) => boolean;
  disabled?: (cell?: any, row?: any, aditionalData?: {}) => boolean;
  onChange?: (cell?: any, row?: any, aditionalData?: {}) => void;
  selectOptions?: DbTableSelectOptions;
  public constructor(init?: Partial<DbTableInput>) {
    this.type = 'text';
    this.required = function () { return false };
    this.disabled = function () { return false };
    this.onChange = function () { };
    this.selectOptions = new DbTableSelectOptions();
    if (init)
      Object.assign(this, init);
  }
}
export class DbTableColumn {
  title: string;
  fieldName?: string;
  width?: string = 'auto';
  type?: 'text' | 'html';
  canChange: boolean;
  input?: DbTableInput;
  valuePrepareFunction?: (cell?: any, row?: any, aditionalData?: {}) => string | number;
  public constructor(init?: Partial<DbTableColumn>) {
    this.title = '';
    this.fieldName = '';
    this.width = 'auto';
    this.type = 'text';
    this.canChange = false;
    this.valuePrepareFunction = function (cell) { return cell };
    if (init)
      Object.assign(this, init);
  }
}
export class DbTableRowSelectOption {
  name: string;
  value: string
  public constructor(init?: Partial<DbTableRowSelectOption>) {
    this.name = '';
    this.value = '';
    if (init)
      Object.assign(this, init);
  }
}
export class DbTableRowData {
  id: string;
  status: 'old' | 'new' | 'changed';
  hasError: boolean;
  number: number;
  objData: {};
  cells: any[];
  originalCellsData: any[];
  cellsData: any[];
  selectOptions: DbTableRowSelectOption[][];
  page: number;
  public constructor(init?: Partial<DbTableColumn>) {
    this.id = '';
    this.status = 'new';
    this.hasError = false;
    this.number = 0;
    this.objData = {};
    this.cells = [];
    this.originalCellsData = [];
    this.cellsData = [];
    this.selectOptions = [];
    this.page = 0;
    if (init)
      Object.assign(this, init);
  }
}
export class DbTableDataChanges {
  finalData: any[];
  dataToCreate: any[];
  dataToDelete: any[];
  dataToUpdate: any[];
  public constructor(init?: Partial<DbTableDataChanges>) {
    this.finalData = [];
    this.dataToCreate = [];
    this.dataToDelete = [];
    this.dataToUpdate = [];
    if (init)
      Object.assign(this, init);
  }
}
export class DbTableFilterIndexType {
  show: boolean; value: any
}
