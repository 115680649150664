import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AlertStatus } from '../../types';

export interface UiFeedBackAlertModalDialogData {
  title: string;
  message: string;
  lottie: boolean;
  status: AlertStatus;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-ui-feed-back-alert-modal',
  templateUrl: './ui-feed-back-alert-modal.component.html',
  styleUrls: ['./../ui-feed-back-modals.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiFeedBackAlertModalComponent {

  private SUCCESS_LOTTIE_PATH = 'assets/animations/uiFeedBack.service/success.json';
  private WARNING_LOTTIE_PATH = 'assets/animations/uiFeedBack.service/warning.json';
  private ERROR_LOTTIE_PATH = 'assets/animations/uiFeedBack.service/error.json';

  public id = this.generateRandom(30);
  public title = '';
  public message = '';
  public lottie = true;
  public lottieOptions = {
    path: '',
    loop: false
  };
  btn: {
    title: "Sucesso" | "Aviso" | "Erro",
    status: "success" | "warning" | "danger",
    icon: "checkmark-circle-2-outline" | "alert-triangle-outline" | "close-circle-outline",
  } = {
      title: "Sucesso",
      status: "success",
      icon: "checkmark-circle-2-outline"
    };

  @ViewChild('AletModalContent', { read: ElementRef, static: false }) aletModalContent: ElementRef;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UiFeedBackAlertModalDialogData,
    private mdDialogRef: MatDialogRef<UiFeedBackAlertModalComponent>,
  ) {

    this.title = data.title;
    this.message = data.message;
    this.lottie = data.lottie;

    switch (data.status) {
      case 'success': {
        this.lottieOptions.path = this.SUCCESS_LOTTIE_PATH;
        this.btn.title = "Sucesso";
        this.btn.status = "success";
        this.btn.icon = "checkmark-circle-2-outline";
        break;
      }
      case 'warning': {
        this.lottieOptions.path = this.WARNING_LOTTIE_PATH;
        this.btn.title = "Aviso";
        this.btn.status = "warning";
        this.btn.icon = "alert-triangle-outline";
        break;
      }
      case 'error': {
        this.lottieOptions.path = this.ERROR_LOTTIE_PATH;
        this.btn.title = "Erro";
        this.btn.status = "danger";
        this.btn.icon = "close-circle-outline";
        break;
      }
    }

    this._setMessageHTML(this.message);
  }
  close() {
    this.mdDialogRef.close();
  }
  @HostListener('window:keydown.esc', ['$event'])
  onEsc() {
    this.close();
  }
  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.aletModalContent && !this.aletModalContent.nativeElement.contains(event.target))
      this.close();
  }
  @HostListener('window:keydown.enter', ['$event'])
  onEnter() {
    this.close();
  }
  private generateRandom(length: number): string {
    var s = '';
    var r = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
      s += r.charAt(Math.floor(Math.random() * r.length));
    }
    return s;
  }
  private _setMessageHTML(message: string) {
    const retryDelayMs = 50;
    let messageElemendId = `alert-message-${this.id}`;
    const element = document.getElementById(messageElemendId);
    if (element)
      document.getElementById(messageElemendId).innerHTML = message;
    else
      setTimeout(() => {
        this._setMessageHTML(message);
      }, retryDelayMs);
  }
}
