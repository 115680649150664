import { TIMES } from "app/routes/main/sys/SYS_CONSTS";

export class Gets {
  // constructor() { }
  getRandon(vet: any[]): any {
    return vet[Math.floor(Math.random() * vet.length)];
  }
  getBase64ContentData(base64Content: string): {
    base64Content: string,
    mimeType: string,
    base64Data: string
  } {
    // base64 encoded data doesn't contain commas
    let base64ContentArray = base64Content.split(",")
    // base64 content cannot contain whitespaces but nevertheless skip if there are!
    let mimeType = base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0]
    // base64 encoded data - pure
    let base64Data = base64ContentArray[1]
    return {
      base64Content: base64Content,
      mimeType: mimeType,
      base64Data: base64Data
    }
  }
  /**
   * Returns a numver of bytes for a given object
   * @param object: any
   * @returns number of bytes
   */
  public getSizeOf(object: any): number {
    let bytes = 0;
    function _sizeOf(_object: any): number {
      if (_object !== null && _object !== undefined) {
        switch (typeof _object) {
          case 'number':
            bytes += 8;
            break;
          case 'string':
            bytes += _object.length * 2;
            break;
          case 'boolean':
            bytes += 4;
            break;
          case 'object':
            let objClass = Object.prototype.toString.call(_object).slice(8, -1);
            if (objClass === 'Object' || objClass === 'Array') {
              for (let key in _object) {
                if (!_object.hasOwnProperty(key)) continue;
                _sizeOf(_object[key]);
              }
            } else bytes += _object.toString().length * 2;
            break;
        }
      }
      return bytes;
    }
    return _sizeOf(object);
  }
  getDateAndClosestTime(date: Date): { formattedDate: string, closestTime: string, closestDateTime: Date } {
    if (!date)
      return { formattedDate: '', closestTime: '', closestDateTime: null };

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedDate = `${year}-${month}-${day}`;

    const totalMinutes = hours * 60 + minutes;

    function findClosestTime(): string {
      let closestTime = TIMES[0];
      let closestDifference = Math.abs(totalMinutes - convertTimeToMinutes(closestTime));

      for (let i = 1; i < TIMES.length; i++) {
        const currentTime = TIMES[i];
        const currentDifference = Math.abs(totalMinutes - convertTimeToMinutes(currentTime));

        if (currentDifference < closestDifference) {
          closestTime = currentTime;
          closestDifference = currentDifference;
        }
      }

      return closestTime;
    }

    function convertTimeToMinutes(time: string): number {
      const [hh, mm] = time.split(':').map(Number);
      return hh * 60 + mm;
    }

    const closestTime = findClosestTime();

    // Cria um novo objeto Date com a data original e o horário mais próximo
    const [closestHours, closestMinutes] = closestTime.split(':').map(Number);
    const closestDateTime = new Date(year, Number(month) - 1, Number(day), closestHours, closestMinutes);

    return { formattedDate, closestTime, closestDateTime };
  }
}
