import firebase from "firebase/compat/app";

export class OS_BusinessPlace {

  id: string;
  status: 0 | 1;
  name: string;

  companyId: string;
  calendarId: string;

  city: string;
  state: string;
  address: string;
  phone: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<OS_BusinessPlace>) {

    this.id = '';
    this.status = 0;
    this.name = '';

    this.companyId = "";
    this.calendarId = '';

    this.city = '';
    this.state = '';
    this.address = '';
    this.phone = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
