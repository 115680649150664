import firebase from "firebase/compat/app";

export class OS_Company {

  id: string;
  status: 0 | 1;
  name: string;

  calendarId: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<OS_Company>) {

    this.id = '';
    this.status = 0;
    this.name = '';

    this.calendarId = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
