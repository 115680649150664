import firebase from "firebase/compat/app";

export class SM_DocAdditionalInfo {

  id: string;
  smDocId: string;
  customFieldId: string;

  name: string;
  helpText: string;

  type: 'textarea' | 'text' | 'number' | 'select' | 'multiselect' | 'date';
  ascType: 'Optional' | 'Mandatory';

  minlength: number;
  maxlength: number;
  min: number;
  max: number;

  pattern: string;

  selectOptions: string[];

  value: string | string[];

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<SM_DocAdditionalInfo>) {

    this.id = '';
    this.smDocId = '';
    this.customFieldId = '';

    this.name = '';
    this.helpText = '';

    this.type = 'text';
    this.ascType = 'Optional';

    this.value = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
