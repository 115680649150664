import { MagicTableComponent } from "../magic-table.component";
import { MagicTableColumn, MagicTableRowData, UiFilterIndex } from "../magic-table.types";
import { magicTableTimeStampToLocalDate } from "./magic-table-time-stamp-to-local-date";
import { magicTableFormatToMonetary } from "./magic-table-format-to-monetary";
import { magicTableTranslateBoolean } from "./magic-table-translate-boolean";
import { magicTableProgressBarPercentMaker } from "./magic-table-progress-bar-percent-maker";
import { MAGIC_TABLE_CONSTS } from "./MAGIC_TABLE_CONSTS";
import { magicTableGenFilterValue } from "./magic-table-gen-filter-value";
import { magicTableOrderByCore } from "./magic-table-order-by-core";
import { magicTableCompareFunction } from "./magic-table-compare-function";
import { magicTableCellDataMaker } from "./magic-table-cell-data-maker";

// Filter

/**
* Inicia os indices dos filtros de acordo com as linhas da tabela
*/
export function magicTableInitFilterIndexes(_this: MagicTableComponent) {
    _this.filterIndexes = [];
    _this.filterActiveIndex = [];

    for (let col_index = 0; col_index < _this.table.cols.length; col_index++) {
        let tempColIndex: UiFilterIndex = [];
        let tempSingleValues = [];
        for (let r of _this.table.rows) {
            const col = _this.table.cols[col_index];
            const fData = r.obj[col.fieldName];
            const fCell = magicTableGenDispFilterValue(col, r);

            if (!Array.isArray(fData)) {
                // not array
                if (tempSingleValues.indexOf(fCell) == -1) {
                    tempSingleValues.push(fCell)
                    tempColIndex.push({ show: true, data: fData, cell: fCell as any })
                }
            } else {
                // array
                for (let i = 0; i < fData.length; i++) {
                    const fd = fData[i];
                    const fc = fCell[i];
                    if (tempSingleValues.indexOf(fc) == -1) {
                        tempSingleValues.push(fc)
                        tempColIndex.push({ show: true, data: fd, cell: fc as any })
                    }
                }
                // empty array
                if (fData.length == 0) {
                    if (tempSingleValues.indexOf(MAGIC_TABLE_CONSTS.EMPTY_ARRAY_FILTER_VALUE) == -1) {
                        tempSingleValues.push(MAGIC_TABLE_CONSTS.EMPTY_ARRAY_FILTER_VALUE);
                        tempColIndex.push({ show: true, data: MAGIC_TABLE_CONSTS.EMPTY_ARRAY_DATA_VALUE, cell: MAGIC_TABLE_CONSTS.EMPTY_ARRAY_FILTER_VALUE })
                    }
                }
            }
        }
        tempColIndex.sort((a, b) => magicTableOrderByCore(a, b, "asc"));

        _this.filterIndexes.push(tempColIndex);
        _this.filterActiveIndex.push(false);
        _this.orderByIndex.push(null);
    }
}
/**
 * Atualiza as linhas filtradas apartir das linhas da tabela filtros
 */
export function magicTableUpdateFiltredRows(magicTableComponent: MagicTableComponent) {
    let tempRows: MagicTableRowData[] = [];
    for (let row of magicTableComponent.table.rows) {
        let tempRow = row;
        let validRow = true;
        for (let col_index = 0; col_index < magicTableComponent.table.cols.length; col_index++) {
            if (magicTableComponent.filterActiveIndex[col_index]) {
                validRow = magicTableGetFilterStatus(magicTableComponent, col_index, tempRow.data[col_index]);
                if (!validRow)
                    break;
            }
        }
        if (validRow)
            tempRows.push(row);
    }

    // sort rows
    // -sort array inside lines
    for (let row_index = 0; row_index < tempRows.length; row_index++) {
        for (let col_index = 0; col_index < magicTableComponent.table.cols.length; col_index++)
            if (magicTableComponent.orderByIndex[col_index]) {
                const col = magicTableComponent.table.cols[col_index];
                let fieldData = tempRows[row_index].obj[col.fieldName];
                if (Array.isArray(fieldData)) {
                    fieldData.sort((a, b) => magicTableOrderByCore(a, b, magicTableComponent.orderByIndex[col_index]));
                    tempRows[row_index].cells[col_index] = magicTableCellDataMaker(col, fieldData);
                }
            }
    }
    // -sort rows
    tempRows.sort((rowA, rowB) => {
        let result = 0;
        for (let i = 0; i < magicTableComponent.orderByIndex.length; i++) {
            if (magicTableComponent.orderByIndex[i]) {
                const rowAData = rowA.data[i] == MAGIC_TABLE_CONSTS.EMPTY_ARRAY_DATA_VALUE ? MAGIC_TABLE_CONSTS.EMPTY_CELL_VALUE : rowA.data[i];
                const rowBData = rowB.data[i] == MAGIC_TABLE_CONSTS.EMPTY_ARRAY_DATA_VALUE ? MAGIC_TABLE_CONSTS.EMPTY_CELL_VALUE : rowB.data[i];
                result = magicTableOrderByCore(rowAData, rowBData, magicTableComponent.orderByIndex[i]);
            }

            if (result !== 0)
                break;
        }
        return result;
    });

    magicTableComponent.filtredRows = [...tempRows];
}
function __internalGenDispFilterValue(col: MagicTableColumn, fData: any): string {
    let cell = fData;

    if (col.type == 'timestamp')
        cell = magicTableTimeStampToLocalDate(fData);
    else if (col.type == 'monetary')
        cell = magicTableFormatToMonetary(fData);
    else if (col.type == 'boolean')
        cell = magicTableTranslateBoolean(fData);
    else if (col.type == 'progress-bar')
        cell = magicTableProgressBarPercentMaker(fData);

    return magicTableGenFilterValue(cell);
}
export function magicTableGenDispFilterValue(col: MagicTableColumn, row: MagicTableRowData): string | number | string[] | number[] {
    let fieldData = row.obj[col.fieldName];
    let fieldDispValue = null;

    if (!Array.isArray(fieldData)) {
        // not array
        fieldDispValue = __internalGenDispFilterValue(col, fieldData);
    } else {
        // array
        fieldDispValue = [];
        for (const v of fieldData)
            fieldDispValue.push(
                __internalGenDispFilterValue(col, v)
            );
    }

    return fieldDispValue;
}
// sub routines
export function magicTableGetFilterStatus(_this: MagicTableComponent, col_index: number, filterOrFieldData: any | any[]): boolean {
    let tempShow = true;
    if (!Array.isArray(filterOrFieldData)) {
        // not array
        for (let f of _this.filterIndexes[col_index])
            if (magicTableCompareFunction(f.data, filterOrFieldData)) {
                tempShow = f.show;
                break;
            }
    } else {
        // array
        let showsFieldData = _this.filterIndexes[col_index].filter((f) => f.show).map((f) => f.data);
        tempShow = showsFieldData.some((val: any) => Array.isArray(filterOrFieldData) && filterOrFieldData.includes(val));
    }
    return tempShow;
}
export function magicTableUpdateFilterStatus(_this: MagicTableComponent, col_index: number, filterData: any, status: boolean) {
    for (let f of _this.filterIndexes[col_index])
        if (magicTableCompareFunction(f.data, filterData)) {
            f.show = status;
            break;
        }
}
// sub routines

// Filter