<mat-dialog-content>

  <nb-card [nbSpinner]="loader" nbSpinnerMessage="Loading..." nbSpinnerSize="large" nbSpinnerStatus="basic"
    class="col-12">
    <nb-card-header>
      Auth Object Field Data
      <button class="close" mat-button matDialogClose>
        <span aria-hidden="true">&times;</span>
      </button>
    </nb-card-header>
    <nb-card-body>
      <form #form1="ngForm">
        <div class="row">
          <div class="col-12">
            <label>Id</label>
            <input disabled class="ic-form-control" type="name" id="input_id" name="input_id"
              [(ngModel)]="localAuthObjectField.id" required placeholder="Id"
              [pattern]="utilCtrl.regexValidators.noSpaces">
          </div>
        </div>


        <div class="row">
          <div class="col-12">
            <label class="control-label">Description</label>
            <textarea autofocus class="ic-form-control" id="description" name="description"
              [(ngModel)]="localAuthObjectField.description" required></textarea>
          </div>
        </div>
      </form>

      <div class="padding"></div>

      <nb-card>
        <nb-card-header>
          Values
        </nb-card-header>
        <nb-card-body>


          <form #form2="ngForm">
            <!-- Values From Colection -->
            <div class="row">
              <div class="col-12">
                <nb-toggle (checkedChange)="toggle_valuesFromColection($event)"
                  [(checked)]="localAuthObjectField.valuesFromColection">
                  Values From Colection
                </nb-toggle>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label>Values Colection</label>
                <input [disabled]="!localAuthObjectField.valuesFromColection" class="ic-form-control" type="name"
                  id="input_valuesColection" name="input_valuesColection"
                  [(ngModel)]="localAuthObjectField.valuesColection" required
                  [pattern]="utilCtrl.regexValidators.noSpaces">
              </div>
              <div class="col-6">
                <label>Values Field</label>
                <input [disabled]="!localAuthObjectField.valuesFromColection" class="ic-form-control" type="name"
                  id="input_valuesField" name="input_valuesField" [(ngModel)]="localAuthObjectField.valuesField"
                  required [pattern]="utilCtrl.regexValidators.noSpaces">
              </div>
            </div>
            <!-- Values From Colection -->
          </form>


          <div class="form-group">
            <label class="control-label">Add Value</label>
            <form #form3="ngForm">

              <div class="row">
                <div class="col-4">
                  <input [disabled]="localAuthObjectField.valuesFromColection" class="ic-form-control active" type="text"
                    id="input_newFieldValue_value" name="input_newFieldValue_value" [(ngModel)]="newFieldValue.value"
                    placeholder="value" required [pattern]="utilCtrl.regexValidators.noSpaces">
                </div>

                <div class="col-8">
                  <input [disabled]="localAuthObjectField.valuesFromColection" class="ic-form-control active" type="text"
                    id="input_newFieldValue_description" name="input_newFieldValue_description"
                    [(ngModel)]="newFieldValue.description" placeholder="description" required>
                </div>
              </div>
              <div class="row">

                <div class="col-4">
                  <button nbButton status="primary" size="small"
                    [disabled]="!form3.valid ||localAuthObjectField.valuesFromColection" (click)="addFieldValue()">
                    <nb-icon icon="plus-outline" pack="eva"></nb-icon>
                  </button>
                </div>
              </div>
            </form>

          </div>

          <table *ngIf="localAuthObjectField.values.length>0" class="clean-table">
            <thead>
              <tr>
                <th style="width: 30px;">Nº</th>
                <th>Value</th>
                <th>Description</th>
                <th style="width: 30px;"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let b of localAuthObjectField.values; let i=index">
                <td>{{i+1}}</td>
                <td>
                  <p>{{b.value}}</p>
                </td>
                <td>
                  <p>{{b.description}}</p>
                </td>
                <td>
                  <button nbButton status="danger" size="small" (click)="removeFieldValueConfirmation(i)">
                    <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <br>

        </nb-card-body>
      </nb-card>

    </nb-card-body>
  </nb-card>



  <nb-card>
    <nb-card-body>

      <ng-container *ngIf="form1.valid; else elseTemplate_1">
        <p>✅ Data </p>
      </ng-container>
      <ng-template #elseTemplate_1>
        <p>❌ Data </p>
      </ng-template>


      <div class="row">
        <div class="col-12 padding" style="text-align: center">
          <div class="button-container">
            <button nbButton status="primary" size="small" [disabled]="!form1.valid" (click)="save()">
              <nb-icon icon="save-outline" pack="eva"></nb-icon>
              Salvar
            </button>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>

</mat-dialog-content>
