import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbLayoutDirectionService, NbStatusService } from '@nebular/theme';
import { AuthService } from 'app/@core/services/auth.service';
import { ThemeModule } from 'app/@theme';

import { PmCardUploadModal } from './pm-card-upload-modal';

const Modals = [
    PmCardUploadModal
];

const Components = [];

@NgModule({
    declarations: [
        //Modals
        ...Modals,
        ...Components
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ThemeModule,
    ],
    providers: [
        NbLayoutDirectionService,
        AuthService,
        NbStatusService
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class PmCardUploadModalModule { }
