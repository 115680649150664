import firebase from "firebase/compat/app";
import { PM_Card } from "./PM_Card";
import { PM_ToDoList } from "../PM_ToDoList";
import { PM_ToDo } from "../PM_ToDo";

export interface ModelIcon {
  icon: string;
  pack: string;
};
export interface ModelBoard {
  name: string;
  routes: string[];
  labels: string[];
  color: string;
};
export interface ModelRoute {
  id: string;
  name: string;
  hex: string;
  final: boolean;
  allowCreatingCards: boolean;
  limit: number;
  cards: string[];
};
export interface ModelLabel {
  id: string;
  name: string;
  hex: string;
};

export class PM_BoardModel {

  id: string;
  name: string;
  icon: ModelIcon;
  description: string;
  tags: string[];
  position: number;
  status: 0 | 1;

  board: ModelBoard;
  routes: ModelRoute[];
  labels: ModelLabel[];

  cards: PM_Card[];
  toDoLists: PM_ToDoList[];
  toDos: PM_ToDo[];

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<PM_BoardModel>) {

    this.id = '';
    this.name = '';
    this.icon = null;
    this.description = '';
    this.tags = [];
    this.status = 1;

    this.board = null;
    this.routes = [];
    this.labels = [];

    this.cards = [];
    this.toDoLists = [];
    this.toDos = [];

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
