import firebase from "firebase/compat/app";

const TIMESTAMP_KEYS = ["seconds", "nanoseconds"];
const TIMESTAMP2_KEYS = ["_seconds", "_nanoseconds"];

function __compareObjectKeysArrays(keys1: string[], keys2: string[]): boolean {
    let equals = true;
    for (let k1 of keys1) {
        if (keys2.indexOf(k1) == -1) {
            equals = false;
            break;
        }
    }
    if (equals)
        for (let k2 of keys2) {
            if (keys1.indexOf(k2) == -1) {
                equals = false;
                break;
            }
        }
    return equals;
}
function __newTimestamp(seconds: number, nanoseconds: number): firebase.firestore.Timestamp { return new firebase.firestore.Timestamp(seconds, nanoseconds) }
/**
 * Função para facilitar a comparação entre valores, para valores vazios sempre retornará ''.
 * @param value 
 * @returns 
 */
function _valueStandardizer(value: any): any {

    // null | undefined
    if (!value)
        return '';

    // Array
    if (Array.isArray(value)) {
        if (value.length === 0)
            return '';
        return value.map((item) => _valueStandardizer(item));
    }

    // Object
    if (typeof value === 'object') {
        const value_keys = Object.keys(value);

        // Date or Timestamp
        if (value instanceof Date) return value.getTime();
        if (__compareObjectKeysArrays(value_keys, TIMESTAMP_KEYS))
            return __newTimestamp(value['seconds'], value['nanoseconds']).toDate().getTime();
        if (__compareObjectKeysArrays(value_keys, TIMESTAMP2_KEYS))
            return __newTimestamp(value['_seconds'], value['_nanoseconds']).toDate().getTime();

        return JSON.stringify(value);
    }

    // Date and time format - ISO 8601
    const dateTimeRegex = /\d{4}(.\d{2}){2}(\s|T)(\d{2}.){2}\d{2}/g;
    if (dateTimeRegex.test(value)) return new Date(value).getTime();

    // Number
    const tempNum = Number(value);
    if (!isNaN(tempNum)) return tempNum;

    return value;
}
export function dbTableCompareFunction(v1: any, v2: any) {
    const vA = _valueStandardizer(v1);
    const vB = _valueStandardizer(v2);
    return vA == vB;
}