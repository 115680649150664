import { CkEditorConfigMentionFeedItem } from "./ckeditor/CkEditor";

export interface MagicTextUserToMention extends CkEditorConfigMentionFeedItem { pictureUrl: string };

export function mentionUserFeedGetFeedItems(queryText: string, usersToMention: MagicTextUserToMention[]): Promise<MagicTextUserToMention[]> {
    // As an example of an asynchronous action, return a promise
    // that resolves after a 100ms timeout.
    // This can be a server request or any sort of delayed action.
    return new Promise<MagicTextUserToMention[]>(resolve => {
        setTimeout(() => {
            const itemsToDisplay = usersToMention
                // Filter out the full list of all items to only those matching the query text.
                .filter(isItemMatching)
                // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
                .slice(0, 10);

            resolve(itemsToDisplay);
        }, 100);
    });

    // Filtering function - it uses `name` and `username` properties of an item to find a match.
    function isItemMatching(item) {
        // Make the search case-insensitive.
        const searchString = queryText.toLowerCase();

        // Include an item in the search results if name or username includes the current user input.
        return (
            item.name.toLowerCase().includes(searchString) ||
            item.id.toLowerCase().includes(searchString)
        );
    }
}

export function mentionUserFeedCustomItemRenderer(item: MagicTextUserToMention) {
    const itemElement = document.createElement('span');
    itemElement.classList.add('user-feed-item');
    itemElement.id = `mention-list-item-id-${item.id}`;

    // Elemento da foto ou iniciais do usuário
    const photoElement = document.createElement('span');
    photoElement.classList.add('user-feed-item-photo');

    if (item.pictureUrl) {
        const imgElement = document.createElement('img');
        imgElement.src = item.pictureUrl;
        imgElement.alt = `${item.name}`;
        imgElement.classList.add('user-photo');
        photoElement.appendChild(imgElement);
    } else {
        const names = item.name.split(' ');
        const initials = names.map((n) => n.charAt(0)).splice(0, 2).join('').toUpperCase();
        photoElement.textContent = initials;
        photoElement.classList.add('user-initials');
    }

    // Elemento do nome do usuário
    const usernameElement = document.createElement('span');
    usernameElement.classList.add('user-feed-item-name');
    usernameElement.textContent = item.name;

    // Elemento do ID do usuário
    const idElement = document.createElement('span');
    idElement.classList.add('user-feed-item-id');
    idElement.textContent = item.id;

    // Adiciona os elementos ao item principal
    itemElement.appendChild(photoElement);
    itemElement.appendChild(usernameElement);
    itemElement.appendChild(idElement);

    return itemElement;
}

// Função para coletar todas as menções do conteúdo do editor.
export function collectUsersMentioned(editor): MagicTextUserToMention[] {
    const mentions: MagicTextUserToMention[] = [];
    const rootElement = editor.model.document.getRoot();
    for (const child of rootElement.getChildren()) {
        for (const descendant of child.getChildren()) {
            if (descendant.hasAttribute && descendant.hasAttribute('mention')) {
                const mentionAttribute = descendant.getAttribute('mention');
                mentions.push({
                    id: mentionAttribute['id'],
                    reference: mentionAttribute['reference'],
                    name: mentionAttribute['name'],
                    pictureUrl: mentionAttribute['pictureUrl'] || ''
                });
            }
        }
    }
    return mentions;
};