import { Component, Input, OnChanges, OnInit, SimpleChange } from "@angular/core";
import { NbComponentStatus } from "@nebular/theme";

import { UtilService } from "app/@core";
import { FirestoreService, Log, LogCategory, User } from "app/@firebase";
import { ThemeStatus, UiFeedBackService } from "app/@theme";

export class DataType {
  log: Log;
  user: User;
  catName: string;
  status: ThemeStatus;
  icon: string;
  public constructor(init?: Partial<DataType>) {
    this.log = new Log();
    this.user = new User();
    this.catName = '';
    this.status = 'basic';
    this.icon = '';
    if (init)
      Object.assign(this, init);
  }
}

@Component({
  selector: 'activities-tab',
  templateUrl: './activities-tab.component.html',
  styleUrls: ['./activities-tab.component.scss']
})
export class ActivitiesTabComponent implements OnInit, OnChanges {

  className = 'ActivitiesTabComponent';


  @Input() docId: string = '';
  @Input() docPath: string = '';

  private readonly LogCatsData:
    {
      id: string;
      accent: NbComponentStatus;
      icon: string;
    }[]
    = [
      {
        id: 'GR',
        accent: 'primary',
        icon: 'menu-outline'
      },
      {
        id: 'DE',
        accent: 'danger',
        icon: 'trash-2-outline'
      },
      {
        id: 'CR',
        accent: 'success',
        icon: 'file-text-outline'
      },
      {
        id: 'C',
        accent: 'primary',
        icon: 'message-circle-outline'
      },
      {
        id: 'FC',
        accent: 'warning',
        icon: 'edit-2-outline'
      },
      {
        id: 'PR',
        accent: 'info',
        icon: 'printer-outline'
      },
      {
        id: 'SEC',
        accent: 'success',
        icon: 'shield-outline'
      },

    ];

  loader = true;
  private LogsSub = null;
  private logs: Log[] = [];
  private users: User[] = [];
  data: DataType[] = [];

  constructor(
    public db: FirestoreService,
    public uiFeedBackCtrl: UiFeedBackService,
    public utilCtrl: UtilService,
  ) { }
  ngOnInit() { }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    for (const propName in changes) {
      // if (propName == 'docId') {
      //   this.load();
      // }
    }
  }
  ngOnDestroy() {
    if (this.LogsSub != null) {
      this.LogsSub();
    }
  }

  public load() {
    if (this.LogsSub == null && this.docPath != '') {

      let query = this.db.afs
        .collection(this.db.COLLECTIONS.sys.logs)
        .ref
        .where('type', '==', "AUDIT")
        .where('docPath', '==', this.docPath);

      if (this.docId != '') {
        query = query.where('docId', '==', this.docId);
      }

      this.LogsSub = query
        .orderBy("createdOn", "desc")
        .onSnapshot(
          q => {
            this.logs = [];
            for (let doc of q.docs) {
              const d = doc.data() as Log;
              this.logs.push(d);
            }
            this.loadData();
          },
          (error) => {
            this.uiFeedBackCtrl.presentErrorAlert('', this.className, '', 'Erro', error);
          });

    } else if (this.docPath != '') {
      this.LogsSub();
      this.LogsSub = null;
      this.load();
    }
  }
  private loadData() {
    this.loader = true;

    let tempData: DataType[] = [];
    let tempNewUsersUnames = [];
    let tempOldUsersUnames = [];

    for (let u of this.users)
      tempOldUsersUnames.push(u.uName);

    this.logs
      .forEach(actv => {
        if (tempOldUsersUnames.indexOf(actv.uName) == -1) {
          tempNewUsersUnames.push(actv.uName)
        }
      });

    this.db.sys.users.getDataByWheres('uName', '==', tempNewUsersUnames)
      .then(data => {
        data
          .forEach(u => {
            this.users.push(u)
          })

        this.logs
          .forEach(log => {
            let tempCategoryData = this.getCategoryData(log.category);
            tempData.push({
              log: log,
              user: this.getUser(log.uName),
              catName: tempCategoryData.name,
              status: tempCategoryData.accent,
              icon: tempCategoryData.icon
            })
          });

        this.data = tempData;
        this.loader = false;

      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, '', 'Erro', e);
      })
  }
  private getUser(uName: string): User {
    let u = new User();
    for (let user of this.users)
      if (user.uName == uName) {
        u = user;
        break
      }
    return u;
  }
  private getCategoryData(category: LogCategory): { name: string; accent: NbComponentStatus; icon: string } {
    let tempData: { name: string; accent: NbComponentStatus; icon: string } = { name: this.db.DICTIONARY.translator(category, this.db.DICTIONARY.sys.logs.category), accent: 'basic', icon: '' };
    for (let c of this.LogCatsData) {
      if (c.id == category) {
        tempData.accent = c.accent;
        tempData.icon = c.icon;
        break;
      }
    }
    return tempData;
  }

  _openUserDetail(data: DataType) {

    this.uiFeedBackCtrl.presentCustonAlert({
      title: `Detalhes do usuario do log de ${this.utilCtrl.timestamp.toLocalDate(data.log.createdOn)}`,
      message: data.log.description,
      inputs: [
        {
          disabled: true,
          type: 'text',
          label: 'Nome',
          name: 'Nome',
          value: data.user.fullName,
        },
        {
          disabled: true,
          type: 'text',
          label: 'Email',
          name: 'Email',
          value: data.user.email,
        },

        {
          disabled: true,
          type: 'text',
          label: 'Tel',
          name: 'Tel',
          value: data.user.phone,
        },
        // {
        //   disabled: true,
        //   type: 'text',
        //   label: 'CPF',
        //   name: 'CPF',
        //   value: data.user.cpf,
        // },
      ],
      buttons: [
        {
          text: 'Fechar'
        }
      ]
    })
  }
}
