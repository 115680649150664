import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";

import { KeybindsService } from "app/@core";
import { OverlayService } from "app/@theme";
import { DbTableComponent } from "app/components/@db-table/db-table.component";
import { DbTableRowData } from "app/components/@db-table/db-table.types";

@Component({
    selector: 'db-table-row-options-overlay',
    templateUrl: './db-table-row-options-overlay.component.html',
    styleUrls: ['./db-table-row-options-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DbTableRowOptionsOverlayComponent implements OnInit, OnDestroy {

    @Input() id: string = '';
    className = 'DbTableRowOptionsOverlayComponent';
    loader: boolean = true;
    onCloseData = {};

    @Input() dbTableComponent: DbTableComponent;
    @Input() row_number: number;

    constructor(
        public overlayCtrl: OverlayService,
        public keybindsCtrl: KeybindsService,
    ) { }
    ngOnInit() {
        this.loader = false;
    }
    ngOnDestroy() {
        this.keybindsCtrl.remove(this.className);
    }
    close() {
        this.overlayCtrl.closeOverlay(this.id);
    }

    // Move Item
    public moveItem_rowUp(row_number: number) {
        this.dbTableComponent.moveItem_rowUp(row_number);
        this.close();
    }
    public moveItem_rowDown(row_number: number) {
        this.dbTableComponent.moveItem_rowDown(row_number);
        this.close();
    }
    // Move Item
    public deleteRow(row_number: number) {
        this.dbTableComponent.deleteRow(row_number);
        this.close();
    }
    duplicateRow(row_number: number) {
        this.dbTableComponent.duplicateRow(row_number);
        this.close();
    }
}