import firebase from "firebase/compat/app";

export class TM_Project {

  id: string;
  status: 0 | 1;

  childsKey: string;

  // workspace
  workspaceId: string;
  workspaceTeamId: string;
  workspacePortifolioId: string;
  workspaceClientId: string;
  workspaceTagId: string;
  // workspace

  name: string;
  description: string;

  teamId: string;
  parentKey: string;
  clientId: string;
  colorId: string;
  color: string;

  taskControl: boolean;

  hoursBudget: number;
  conclusion: number;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<TM_Project>) {

    this.id = '';
    this.status = 1;

    this.name = '';
    this.description = '';

    // workspace
    this.workspaceId = '';
    this.workspaceTeamId = '';
    this.workspacePortifolioId = '';
    this.workspaceClientId = '';
    this.workspaceTagId = '';
    // workspace

    this.teamId = '';
    this.parentKey = '';
    this.childsKey = '';
    this.clientId = '';
    this.colorId = '';
    this.color = '';

    this.taskControl = false;

    this.hoursBudget = 0;
    this.conclusion = 0;

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;


    if (init)
      Object.assign(this, init);
  }
}
