import { DbTableComponent } from '../db-table.component';
import { DbTableColumn, DbTableFilterIndexType, DbTableRowData } from '../types';
import { __closeHeaderPopDown } from './db-table_headerPopDown';

// Filter

/**
 * Inicia os indices dos filtros de acordo com as linhas da tabela
 */
export function __initFilterIndexes(_this: DbTableComponent) {
  _this.filterIndexes = [];
  _this.filterActiveIndex = [];

  for (let c_index = 0; c_index < _this.table.cols.length; c_index++) {
    let tempColIndex = [];
    let tempSingleValues = [];

    for (let r of _this.table.rows) {
      const fValue = _filterValueDetermination(r, c_index, _this.table.cols[c_index]);
      if (tempSingleValues.indexOf(fValue) == -1) {
        tempSingleValues.push(fValue);
        tempColIndex.push({ show: true, value: fValue });
      }
    }

    tempColIndex.sort(function (a, b) {
      var valueA = _sort_valueConverter(a.value),
        valueB = _sort_valueConverter(b.value);
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    });

    _this.filterIndexes.push(tempColIndex);
    _this.filterActiveIndex.push(false);
  }
}
/**
 * Atualiza o index de filtro para a coluna alterada
 */
export function __updateFilterIndexes(_this: DbTableComponent, c_index: number) {
  let tempColIndex = [];
  let tempSingleValues = [];

  for (let r of _this.table.rows) {
    const fValue = _filterValueDetermination(r, c_index, _this.table.cols[c_index]);
    if (tempSingleValues.indexOf(fValue) == -1) {
      tempSingleValues.push(fValue);
      tempColIndex.push({ show: true, value: fValue });
    }
  }

  tempColIndex.sort(function (a, b) {
    var valueA = _sort_valueConverter(a.value),
      valueB = _sort_valueConverter(b.value);
    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  });

  // retrives filter show values on old index

  tempColIndex.forEach((fi) => {
    for (let f of _this.filterIndexes[c_index])
      if (f.value == fi.value) {
        fi.show = f.show;
        break;
      }
  });

  _this.filterIndexes[c_index] = tempColIndex;
}

/**
 * Atualiza as linhas filtradas apartir das linhas da tabela filtros
 */
export function __updateFiltredRows(_this: DbTableComponent) {
  let tempRows: DbTableRowData[] = [];
  let pageN = 1;
  let lastPageItens = 0;

  for (let row of _this.table.rows) {
    let tempRow = row;
    let validRow = true;
    tempRow.page = pageN;

    for (let c_index = 0; c_index < _this.table.cols.length; c_index++) {
      if (_this.filterActiveIndex[c_index]) {
        const fValue = _filterValueDetermination(tempRow, c_index, _this.table.cols[c_index]);
        validRow = _getFilterStatus(_this, c_index, fValue);
        if (!validRow) break;
      }
    }
    if (validRow) {
      tempRows.push(row);
      lastPageItens++;
      if (lastPageItens == _this.itensPerPage) {
        pageN++;
        lastPageItens = 0;
      }
    }
  }
  _this.lastPage = Math.ceil(tempRows.length / _this.itensPerPage) > 0 ? Math.ceil(tempRows.length / _this.itensPerPage) : 1;
  _this.input_page = _this.actualPage;
  _this.filtredRows = tempRows;
}


// sub routines
/**
 * Gera o valor para o filtro apartir do valor da celula
 * @param cell
 * @returns string
 */
export function _convertFilterValue(cell: any): string {
  let v = '';
  if (cell != undefined) {
    cell = cell + '';
    v = cell.replace(/<(?:.|\n)*?>/gm, '');
  }
  if (v == '') v = '(Vazias)';
  return v;
}
export function _filterValueDetermination(row: DbTableRowData, c_index: number, col: DbTableColumn): string {
  let fValue = col.canChange ? _convertFilterValue(row.cellsData[c_index]) : _convertFilterValue(row.cells[c_index]);
  if (col.canChange && col.input && col.input.type == 'select') {
    let tempSelValue = '';
    for (let so of col.input.selectOptions.optionsData) {
      if (row.cellsData[c_index] == col.input.selectOptions.optionValue(so)) {
        tempSelValue = col.input.selectOptions.optionName(so);
        break;
      }
    }
    fValue = _convertFilterValue(tempSelValue);
  }
  return fValue;
}
export function _getFilterStatus(_this: DbTableComponent, c_index: number, cell_value: any): boolean {
  let tempShow = !_this.filterActiveIndex[c_index];
  let fValue = _convertFilterValue(cell_value);
  for (let f of _this.filterIndexes[c_index])
    if (f.value == fValue) {
      tempShow = f.show;
      break;
    }
  return tempShow;
}
function _setFilterStatus(_this: DbTableComponent, c_index: number, filterValue: string, status: boolean) {
  let newfs = true;
  for (let f of _this.filterIndexes[c_index]) {
    if (f.value == filterValue) {
      f.show = status;
      newfs = false;
      break;
    }
  }
  if (newfs)
    _this.filterIndexes[c_index].push({ value: filterValue, show: status });
}
export function _sort_valueConverter(s: any) {
  let tempNum = Number(s);
  if (!isNaN(tempNum))
    return tempNum;
  return s;
}
// sub routines






// UI
export function __filter(_this: DbTableComponent, c_index: number) {
  if (_this.filterIndex4Sel.length == 0)
    return;

  if (_this.filterAddCurSelTofilter) {
    // Add Cur Sel To filter
    for (let fi of _this.filterIndex4Sel) {
      _setFilterStatus(_this, c_index, fi.value, fi.show);
    }
    // Add Cur Sel To filter
  } else {
    if (_this.searchText.length > 0)
      for (let fi of _this.filterIndexes[c_index]) {
        let index = _this.filterIndex4Sel.indexOf(fi);
        if (index == -1)
          fi.show = false;
      }

    for (let fi of _this.filterIndex4Sel)
      _setFilterStatus(_this, c_index, fi.value, fi.show);
  }

  // Filter Active
  let tempFilterActive = false;
  for (let fi of _this.filterIndexes[c_index]) {
    if (!fi.show) {
      tempFilterActive = true;
      break;
    }
  }
  _this.filterActiveIndex[c_index] = tempFilterActive;
  // Filter Active

  _this.actualPage = 1;
  _this.input_page = _this.actualPage;
  __updateFiltredRows(_this);
  _this.__updateRowsForDisplay();
  __closeHeaderPopDown(_this);
}
export function __cleanFilter(_this: DbTableComponent, c_index: number) {
  for (let fi of _this.filterIndexes[c_index]) {
    _setFilterStatus(_this, c_index, fi.value, true);
  }
  _this.filterActiveIndex[c_index] = false;
  _this.actualPage = 1;
  _this.input_page = _this.actualPage;
  __updateFiltredRows(_this);
  _this.__updateRowsForDisplay();
  __closeHeaderPopDown(_this);
}
export function __updateSearchResult(_this: DbTableComponent, c_index: number) {
  _this.filterIndex4Sel = _search(_this.filterIndexes[c_index], _this.searchText);
  __updateSelectAllSearchResult(_this);
}
function _search(items: DbTableFilterIndexType[], searchText: string): DbTableFilterIndexType[] {
  if (!items)
    return [];

  let tempItems: DbTableFilterIndexType[] = JSON.parse(JSON.stringify(items));

  if (!searchText)
    return tempItems;

  searchText = searchText.toLocaleLowerCase();
  tempItems = tempItems.filter((it) => {
    return it.value.toLocaleLowerCase().includes(searchText);
  });
  tempItems.forEach((i) => {
    i.show = true;
  });
  return tempItems;
}
export function __updateSelectAll(_this: DbTableComponent, c_index: number) {
  if (_this.filterIndex4Sel.length < _this.filterIndexes[c_index].length) {
    _this.filterSelectAll = false;
    return;
  }

  let temp_filterSelectAll = true;
  for (let f of _this.filterIndex4Sel)
    if (!f.show) {
      temp_filterSelectAll = false;
      break;
    }
  _this.filterSelectAll = temp_filterSelectAll;
}
export function __updateSelectAllSearchResult(_this: DbTableComponent) {
  let temp_filterSelectAllSearchResult = true;
  for (let f of _this.filterIndex4Sel)
    if (!f.show) {
      temp_filterSelectAllSearchResult = false;
      break;
    }
  _this.filterSelectAllSearchResult = temp_filterSelectAllSearchResult;
}
export function __toogleAll(_this: DbTableComponent) {
  for (let f of _this.filterIndex4Sel)
    if (_this.filterSelectAll)
      f.show = false;
    else
      f.show = true;
  _this.filterSelectAll = !_this.filterSelectAll;
}
export function __toogleAllSearchResult(_this: DbTableComponent) {
  for (let f of _this.filterIndex4Sel)
    if (_this.filterSelectAllSearchResult)
      f.show = false;
    else
      f.show = true;
  _this.filterSelectAllSearchResult = !_this.filterSelectAllSearchResult;
}
// UI

// Filter
