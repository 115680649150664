import firebase from "firebase/compat/app";

const TIMESTAMP_KEYS = ["seconds", "nanoseconds"];
const TIMESTAMP2_KEYS = ["_seconds", "_nanoseconds"];

function __compareObjectKeysArrays(keys1: string[], keys2: string[]): boolean {
    let equals = true;
    for (let k1 of keys1) {
        if (keys2.indexOf(k1) == -1) {
            equals = false;
            break;
        }
    }
    if (equals)
        for (let k2 of keys2) {
            if (keys1.indexOf(k2) == -1) {
                equals = false;
                break;
            }
        }
    return equals;
}
function __newTimestamp(seconds: number, nanoseconds: number): firebase.firestore.Timestamp { return new firebase.firestore.Timestamp(seconds, nanoseconds) }


export function _valueStandardizer(value: any): any {

    // null | undefined
    if (!value)
        return null;
    // null | undefined

    // Object
    if (typeof value === 'object') {
        const value_keys = Object.keys(value);

        // Date or Timestamp
        if (value instanceof Date)
            return value.getTime();
        if (__compareObjectKeysArrays(value_keys, TIMESTAMP_KEYS))
            return __newTimestamp(value['seconds'], value['nanoseconds']).toDate().getTime();
        if (__compareObjectKeysArrays(value_keys, TIMESTAMP2_KEYS))
            return __newTimestamp(value['_seconds'], value['_nanoseconds']).toDate().getTime();
        // Date or Timestamp

        return JSON.stringify(value);
    }
    // Object

    // string ""
    if (value == "")
        return null;
    // string ""

    // Date and time format - ISO 8601
    let dateTimeRegex = /\d{4}(.\d{2}){2}(\s|T)(\d{2}.){2}\d{2}/g;
    if (dateTimeRegex.test(value))
        return new Date(value).getTime();
    // Date and time format - ISO 8601

    // Number
    let tempNum = Number(value);
    if (!isNaN(tempNum))
        return tempNum;
    // Number

    return value;
}