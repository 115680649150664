import { Injectable } from "@angular/core";

import { SmartTableColumnFilterType, SmartTableColumnType } from "app/components";

import { AuthObject, AuthObjectClass, AuthObjectField, DB_DataField, DB_DataSource, FirestoreService, H_Doc, HomePageComms, HomePageItem, HomePageTab, OS_Calendar, Role, RoleUser, SM_CustomField, SM_SLADefinition, TM_Project, TM_Team, TM_TimeRecord, User, WF_Group } from "../../@firebase";
import { UtilService } from "./util";

const TEXT_TYPE: SmartTableColumnType = "text";
const HTML_TYPE: SmartTableColumnType = "html";

const CHECKBOX_FILTER_TYPE: SmartTableColumnFilterType = "checkbox";
const COMPLETER_FILTER_TYPE: SmartTableColumnFilterType = "completer";
const LIST_FILTER_TYPE: SmartTableColumnFilterType = "list";

@Injectable()
export class SmartTableAuxService {


  constructor(
    private utilCtrl: UtilService,
    private db: FirestoreService
  ) { }


  private getName(id: string, vet: any[]): string {
    let tempName = '';
    for (let o of vet) {
      if (o.id == id) {
        tempName = o.name;
      }
    }
    return tempName;
  }
  private getObj(id: string, vet: any[]): any {
    let tempName = {};
    for (let o of vet) {
      if (o.id == id) {
        tempName = o;
      }
    }
    return tempName;
  }

  readonly columns = {

    // sys
    sys: {
      db: {
        dataFields: {
          id: {
            title: 'Id',
            type: TEXT_TYPE,
            width: '100px'
          },
          name: {
            title: 'Name',
            type: TEXT_TYPE,
          },
          type_str: {
            title: 'Type',
            type: TEXT_TYPE,
            width: '50px'
          },
          createdBy: {
            title: 'Criado Por',
            type: TEXT_TYPE,
            width: '50px'
          },
          createdOn_str: {
            title: 'Criado em',
            type: TEXT_TYPE,
            width: '50px'
          },
          updatedBy: {
            title: 'Atualizado Por',
            type: TEXT_TYPE,
            width: '50px'
          },
          updatedOn_str: {
            title: 'Atualizado em',
            type: TEXT_TYPE,
            width: '50px'
          },
        },
        dataSources: {
          id: {
            title: 'Id',
            type: TEXT_TYPE,
            width: '100px'
          },
          name: {
            title: 'Name',
            type: TEXT_TYPE,
          },
          type_str: {
            title: 'Type',
            type: TEXT_TYPE,
            width: '50px'
          },
          createdBy: {
            title: 'Criado Por',
            type: TEXT_TYPE,
            width: '50px'
          },
          createdOn_str: {
            title: 'Criado em',
            type: TEXT_TYPE,
            width: '50px'
          },
          updatedBy: {
            title: 'Atualizado Por',
            type: TEXT_TYPE,
            width: '50px'
          },
          updatedOn_str: {
            title: 'Atualizado em',
            type: TEXT_TYPE,
            width: '50px'
          },
        },
      },
      accessControl: {
        authorizationProvisioning: {
          roles: {
            id: {
              title: 'Id',
              type: TEXT_TYPE,
              width: '100px'
            },
            type: {
              title: 'Type',
              type: TEXT_TYPE,
              valuePrepareFunction: (cell, row) => {
                return this.db.DICTIONARY.translator(cell, this.db.DICTIONARY.sys.accessControl.authorizationProvisioning.roles.type);
              }
            },
            description: {
              title: 'Description',
              type: TEXT_TYPE,
            },

            createdBy: {
              title: 'Criado Por',
              type: TEXT_TYPE,
              width: '50px'
            },
            createdOn_str: {
              title: 'Criado em',
              type: TEXT_TYPE,
              width: '50px'
            },
            updatedBy: {
              title: 'Atualizado Por',
              type: TEXT_TYPE,
              width: '50px'
            },
            updatedOn_str: {
              title: 'Atualizado em',
              type: TEXT_TYPE,
              width: '50px'
            },
          },
          // roleAuths: 'RoleAuths',
          // roleUsers: 'RoleUsers'

        },
        authorizationStructure: {
          authObjects: {
            id: {
              title: 'Id',
              type: TEXT_TYPE,
              width: '50px'
            },
            classId: {
              title: 'ClassId',
              type: TEXT_TYPE,
              width: '50px'
            },
            description: {
              title: 'Description',
              type: TEXT_TYPE,
            },

            fields: {
              title: 'Fields',
              type: TEXT_TYPE,
              valuePrepareFunction: (cell, row) => {
                return JSON.stringify(cell);
              }
            },

            createdBy: {
              title: 'Criado Por',
              type: TEXT_TYPE,
              width: '50px'
            },
            createdOn_str: {
              title: 'Criado em',
              type: TEXT_TYPE,
              width: '50px'
            },
            updatedBy: {
              title: 'Atualizado Por',
              type: TEXT_TYPE,
              width: '50px'
            },
            updatedOn_str: {
              title: 'Atualizado em',
              type: TEXT_TYPE,
              width: '50px'
            },
          },
          authObjectClasses: {
            id: {
              title: 'Id',
              type: TEXT_TYPE,
              width: '50px'
            },
            description: {
              title: 'Description',
              type: TEXT_TYPE,
            },

            createdBy: {
              title: 'Criado Por',
              type: TEXT_TYPE,
              width: '50px'
            },
            createdOn_str: {
              title: 'Criado em',
              type: TEXT_TYPE,
              width: '50px'
            },
            updatedBy: {
              title: 'Atualizado Por',
              type: TEXT_TYPE,
              width: '50px'
            },
            updatedOn_str: {
              title: 'Atualizado em',
              type: TEXT_TYPE,
              width: '50px'
            },
          },
          authObjectFields: {
            id: {
              title: 'Id',
              type: TEXT_TYPE,
              width: '50px'
            },
            description: {
              title: 'Description',
              type: TEXT_TYPE,
            },

            values: {
              title: 'Values',
              type: HTML_TYPE,
              valuePrepareFunction: (cell, row) => {
                let tempHtml = '';
                for (let v of cell) {
                  tempHtml = tempHtml + `<p><b>${v.value}</b> ${v.description}</p>`;
                }
                return tempHtml;
              },
              filterFunction(cell?: any, search?: string, row?: any): boolean {
                // console.log('cell', cell);
                // console.log('row', row);
                // console.dir('search', search);
                if (search === '') {
                  return true;
                }
                let tempSearch = search.toLocaleUpperCase();
                let match = false;
                for (let v of cell) {
                  if (v.value.toLocaleUpperCase().indexOf(tempSearch) != -1 || v.description.toLocaleUpperCase().indexOf(tempSearch) != -1) {
                    match = true;
                    break;
                  }
                }
                if (match) {
                  return true;
                } else {
                  return false;
                }
              }
            },

            valuesFromColection: {
              title: 'ValuesFromColection',
              type: TEXT_TYPE,
              filter: {
                type: CHECKBOX_FILTER_TYPE,
                config: {
                  true: 'true',
                  false: 'false',
                  resetText: 'limpar',
                },
              }
            },
            valuesColection: {
              title: 'ValuesColection',
              type: TEXT_TYPE,
            },
            valuesField: {
              title: 'ValuesField',
              type: TEXT_TYPE,
            },

            createdBy: {
              title: 'Criado Por',
              type: TEXT_TYPE,
              width: '50px'
            },
            createdOn_str: {
              title: 'Criado em',
              type: TEXT_TYPE,
              width: '50px'
            },
            updatedBy: {
              title: 'Atualizado Por',
              type: TEXT_TYPE,
              width: '50px'
            },
            updatedOn_str: {
              title: 'Atualizado em',
              type: TEXT_TYPE,
              width: '50px'
            },
          },


        },
        // sysPages: 'sysPages',
      },
      users: {
        uName: {
          title: 'User Name',
          type: TEXT_TYPE,
        },
        fullName: {
          title: 'Nome',
          type: TEXT_TYPE,
        },
        email: {
          title: 'Email',
          type: TEXT_TYPE,
        },
        phone: {
          title: 'Telefone',
          type: TEXT_TYPE,
        },

        passwordStatus: {
          title: 'Status da senha',
          type: TEXT_TYPE,
        },
        passwordDate_str: {
          title: 'Data da senha',
          type: TEXT_TYPE,
          width: '50px'
        },

        validFrom: {
          title: 'Valido desde',
          type: TEXT_TYPE,
          valuePrepareFunction: (cell, row) => {
            let v = '-';
            if (cell != '')
              v = this.utilCtrl.date.getDateStrBR(new Date(cell))
            return v;
          }
        },
        validTo: {
          title: 'Valido até',
          type: TEXT_TYPE,
          valuePrepareFunction: (cell, row) => {
            let v = '-';
            if (cell != '')
              v = this.utilCtrl.date.getDateStrBR(new Date(cell))
            return v;
          }
        },
        createdBy: {
          title: 'Criado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        createdOn_str: {
          title: 'Criado em',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedBy: {
          title: 'Atualizado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedOn_str: {
          title: 'Atualizado em',
          type: TEXT_TYPE,
          width: '50px'
        },
      },
      // USERS_REGISTERS: 'UsersRegisters',
      // NOTIFICATIONS: 'Notifications',
      // ACTIVITIES: 'Activities',
      // ACCESS_GROUPS: 'AccessGroups',
      // USER_ASSIGNMENTS: 'UserAssignments',
      // LGPD: 'LGPD',
      // ADDRESS: 'Address',
      // CREDIT_CARDS: 'CreditCards',
      // LOCK_ENTRIES: 'LockEntries'
    },

    // Home
    home: {
      items: {
        title: {
          title: 'Título',
          type: TEXT_TYPE,
          width: '200px'
        },

        status_str: {
          title: 'Status',
          type: TEXT_TYPE,
          // width: '20px'
        },

        createdBy: {
          title: 'Criado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        createdOn_str: {
          title: 'Criado em',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedBy: {
          title: 'Atualizado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedOn_str: {
          title: 'Atualizado em',
          type: TEXT_TYPE,
          width: '50px'
        },
      },

      tabs: {
        position: {
          title: 'Posição',
          type: TEXT_TYPE,
          width: '50px'
        },

        title: {
          title: 'Título',
          type: TEXT_TYPE,
          width: '200px'
        },

        status_str: {
          title: 'Status',
          type: TEXT_TYPE,
          // width: '20px'
        },

        createdBy: {
          title: 'Criado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        createdOn_str: {
          title: 'Criado em',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedBy: {
          title: 'Atualizado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedOn_str: {
          title: 'Atualizado em',
          type: TEXT_TYPE,
          width: '50px'
        },
      },
      comms: {
        position: {
          title: 'Posição',
          type: TEXT_TYPE,
          width: '50px'
        },

        title: {
          title: 'Título',
          type: TEXT_TYPE,
          width: '100px'
        },

        text: {
          title: 'Texto',
          type: TEXT_TYPE,
          width: '200px',
          valuePrepareFunction: (cell, row) => {
            return `${cell.substr(0, 100)}...`;
          }
        },

        validFrom: {
          title: 'Valido desde',
          type: TEXT_TYPE,
          width: '50px',
          valuePrepareFunction: (cell, row) => {
            let v = '-';
            if (cell != '')
              v = this.utilCtrl.date.getDateStrBR(new Date(cell))
            return v;
          }
        },
        validTo: {
          title: 'Valido até',
          type: TEXT_TYPE,
          width: '50px',
          valuePrepareFunction: (cell, row) => {
            let v = '-';
            if (cell != '')
              v = this.utilCtrl.date.getDateStrBR(new Date(cell))
            return v;
          }
        },

        status_str: {
          title: 'Status',
          type: TEXT_TYPE,
          width: '50px',
        },

        createdBy: {
          title: 'Criado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        createdOn_str: {
          title: 'Criado em',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedBy: {
          title: 'Atualizado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedOn_str: {
          title: 'Atualizado em',
          type: TEXT_TYPE,
          width: '50px'
        },
      },
    },
    // Home

    // Help
    help: {
      hDocs: {

        title: {
          title: 'Título',
          type: TEXT_TYPE,
          width: '200px'
        },

        status_str: {
          title: 'Status',
          type: TEXT_TYPE,
          // width: '20px'
        },

        path: {
          title: 'Local',
          type: TEXT_TYPE,
          width: '150px'
        },

        text: {
          title: 'Texto',
          type: TEXT_TYPE,
          valuePrepareFunction: (cell, row) => {
            return `${cell.substr(0, 100)}...`;
          }
        },

        createdBy: {
          title: 'Criado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        createdOn_str: {
          title: 'Criado em',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedBy: {
          title: 'Atualizado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedOn_str: {
          title: 'Atualizado em',
          type: TEXT_TYPE,
          width: '50px'
        },
      },
      hFaqs: {

        title: {
          title: 'Título',
          type: TEXT_TYPE,
          width: '200px'
        },

        status_str: {
          title: 'Status',
          type: TEXT_TYPE,
          // width: '20px'
        },

        path: {
          title: 'Local',
          type: TEXT_TYPE,
          width: '150px'
        },

        text: {
          title: 'Texto',
          type: TEXT_TYPE,
          valuePrepareFunction: (cell, row) => {
            return `${cell.substr(0, 100)}...`;
          }
        },

        createdBy: {
          title: 'Criado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        createdOn_str: {
          title: 'Criado em',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedBy: {
          title: 'Atualizado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedOn_str: {
          title: 'Atualizado em',
          type: TEXT_TYPE,
          width: '50px'
        },
      },
    },
    // Help

    // Time Management
    timeManagement: {
      timeRecords: {
        id: {
          title: 'Id',
          type: TEXT_TYPE,
          width: '50px'
        },

        createdBy: {
          title: 'Criado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        createdOn_str: {
          title: 'Criado em',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedBy: {
          title: 'Atualizado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedOn_str: {
          title: 'Atualizado em',
          type: TEXT_TYPE,
          width: '50px'
        },
      },
      teams: {
        id: {
          title: 'Id',
          type: TEXT_TYPE,
          width: '50px'
        },

        name: {
          title: 'Nome',
          type: TEXT_TYPE,
        },

        createdBy: {
          title: 'Criado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        createdOn_str: {
          title: 'Criado em',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedBy: {
          title: 'Atualizado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedOn_str: {
          title: 'Atualizado em',
          type: TEXT_TYPE,
          width: '50px'
        },
      },
      projects: {
        id: {
          title: 'Id',
          type: HTML_TYPE,
          width: '150px',
          valuePrepareFunction: (cell, row) => {
            let colorHex = this.db.DICTIONARY.getData(row.colorId, this.db.DICTIONARY.timeManagement.project.color)['hex'];
            return this.utilCtrl.trustHtml(`<b style="color:${colorHex}">${cell}</b>`);
          }
        },

        status_str: {
          title: 'Status',
          type: TEXT_TYPE,
          // width: '20px'
        },

        teamId: {
          title: 'Equipe',
          type: TEXT_TYPE,
          width: '50px'
        },

        name: {
          title: 'Nome',
          type: TEXT_TYPE,
        },

        hoursBudget: {
          title: 'Horas Orç.',
          type: TEXT_TYPE,
          valuePrepareFunction: (cell, row) => {
            return `${cell.toFixed(2)}`;
          }
        },


        taskControl: {
          title: 'Controle de tarefas',
          type: TEXT_TYPE,
          valuePrepareFunction: (cell, row) => {
            if (cell == true)
              return `Sim`;
            return `Não`;
          }
        },
        conclusion: {
          title: '% Conclusão',
          type: TEXT_TYPE,
          valuePrepareFunction: (cell, row) => {
            return `${cell.toFixed(2)} %`;
          }
        },

        createdBy: {
          title: 'Criado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        createdOn_str: {
          title: 'Criado em',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedBy: {
          title: 'Atualizado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedOn_str: {
          title: 'Atualizado em',
          type: TEXT_TYPE,
          width: '50px'
        },
      },
    },
    // Time Management

    // Workflow
    workflow: {
      groups: {
        id: {
          title: 'Id',
          type: TEXT_TYPE,
          width: '50px'
        },

        approvalType_str: {
          title: 'tp aprovação',
          type: TEXT_TYPE,
          width: '50px'
        },

        name: {
          title: 'Nome',
          type: TEXT_TYPE,
        },

        createdBy: {
          title: 'Criado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        createdOn_str: {
          title: 'Criado em',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedBy: {
          title: 'Atualizado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedOn_str: {
          title: 'Atualizado em',
          type: TEXT_TYPE,
          width: '50px'
        },
      },
    },
    // Workflow

    // Service Management
    serviceManagement: {
      teams: {
        id: {
          title: 'Id',
          type: TEXT_TYPE,
          width: '50px'
        },

        name: {
          title: 'Nome',
          type: TEXT_TYPE,
        },

        createdBy: {
          title: 'Criado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        createdOn_str: {
          title: 'Criado em',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedBy: {
          title: 'Atualizado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedOn_str: {
          title: 'Atualizado em',
          type: TEXT_TYPE,
          width: '50px'
        },
      },
      serviceCatalog: {
        customFields: {
          id: {
            title: 'Id',
            type: TEXT_TYPE,
            width: '50px'
          },

          status_str: {
            title: 'status',
            type: TEXT_TYPE,
            width: '100px'
          },

          type_str: {
            title: 'Tipo',
            type: TEXT_TYPE,
            width: '100px'
          },

          name: {
            title: 'Nome',
            type: TEXT_TYPE,
          },

          createdBy: {
            title: 'Criado Por',
            type: TEXT_TYPE,
            width: '50px'
          },
          createdOn_str: {
            title: 'Criado em',
            type: TEXT_TYPE,
            width: '50px'
          },
          updatedBy: {
            title: 'Atualizado Por',
            type: TEXT_TYPE,
            width: '50px'
          },
          updatedOn_str: {
            title: 'Atualizado em',
            type: TEXT_TYPE,
            width: '50px'
          },
        },
        slaDefinitions: {
          id: {
            title: 'Id',
            type: TEXT_TYPE,
            width: '50px'
          },

          status_str: {
            title: 'status',
            type: TEXT_TYPE,
            width: '100px'
          },

          type_str: {
            title: 'Tipo',
            type: TEXT_TYPE,
            width: '100px'
          },

          target_str: {
            title: 'Alvo',
            type: TEXT_TYPE,
            width: '100px'
          },

          name: {
            title: 'Nome',
            type: TEXT_TYPE,
          },

          createdBy: {
            title: 'Criado Por',
            type: TEXT_TYPE,
            width: '50px'
          },
          createdOn_str: {
            title: 'Criado em',
            type: TEXT_TYPE,
            width: '50px'
          },
          updatedBy: {
            title: 'Atualizado Por',
            type: TEXT_TYPE,
            width: '50px'
          },
          updatedOn_str: {
            title: 'Atualizado em',
            type: TEXT_TYPE,
            width: '50px'
          },
        },
      }
    },
    // Service Management

    // Organizational Structure
    organizationalStructure: {
      calendars: {
        id: {
          title: 'Id',
          type: TEXT_TYPE,
          width: '50px'
        },

        name: {
          title: 'Nome',
          type: TEXT_TYPE,
        },

        createdBy: {
          title: 'Criado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        createdOn_str: {
          title: 'Criado em',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedBy: {
          title: 'Atualizado Por',
          type: TEXT_TYPE,
          width: '50px'
        },
        updatedOn_str: {
          title: 'Atualizado em',
          type: TEXT_TYPE,
          width: '50px'
        },
      },
    },
    // Organizational Structure

  };

  readonly dataSourceGenerators = {

    // sys
    sys: {
      db: {
        dataFields: (data: DB_DataField[]): any[] => {
          let tempVet = []
          for (let obj of data) {
            let tempObj = obj;
            tempObj['type_str'] = this.db.DICTIONARY.translator(obj.type, this.db.DICTIONARY.sys.db.dataField.type);
            tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
            tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
            tempVet.push(tempObj)
          }
          return tempVet;
        },
        dataSources: (data: DB_DataSource[]): any[] => {
          let tempVet = []
          for (let obj of data) {
            let tempObj = obj;
            tempObj['type_str'] = this.db.DICTIONARY.translator(obj.type, this.db.DICTIONARY.sys.db.dataSource.type);
            tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
            tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
            tempVet.push(tempObj)
          }
          return tempVet;
        }
      },
      accessControl: {
        authorizationProvisioning: {
          roles: (roles: Role[]): any[] => {
            let tempVet = []
            for (let obj of roles) {
              let tempObj = obj;
              tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
              tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
              tempVet.push(tempObj)
            }
            return tempVet;
          },
          // roleAuths: 'RoleAuths',
          roleUsers: (roleUsers: RoleUser[]): any[] => {
            let tempVet = []
            for (let obj of roleUsers) {
              let tempObj = obj;
              tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
              tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
              tempVet.push(tempObj)
            }
            return tempVet;
          },
        },
        authorizationStructure: {
          authObjects: (authObjects: AuthObject[]): any[] => {
            let tempVet = []
            for (let obj of authObjects) {
              let tempObj = obj;
              tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
              tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
              tempVet.push(tempObj)
            }
            return tempVet;
          },
          authObjectClasses: (authObjectClasses: AuthObjectClass[]): any[] => {
            let tempVet = []
            for (let obj of authObjectClasses) {
              let tempObj = obj;
              tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
              tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
              tempVet.push(tempObj)
            }
            return tempVet;
          },
          authObjectFields: (authObjectFields: AuthObjectField[]): any[] => {
            let tempVet = []
            for (let obj of authObjectFields) {
              let tempObj = obj;
              tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
              tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
              tempVet.push(tempObj)
            }
            return tempVet;
          },
        },
        // sysPages: 'sysPages',
      },
      users: (users: User[]): any[] => {
        let tempVet = []
        for (let obj of users) {
          let tempObj = obj;
          tempObj['passwordDate_str'] = this.utilCtrl.timestamp.toLocalDate(obj.passwordDate);
          tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
          tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
          tempVet.push(tempObj)
        }
        return tempVet;
      },
      // USERS_REGISTERS: 'UsersRegisters',
      // NOTIFICATIONS: 'Notifications',
      // ACTIVITIES: 'Activities',
      // ACCESS_GROUPS: 'AccessGroups',
      // USER_ASSIGNMENTS: 'UserAssignments',
      // LGPD: 'LGPD',
      // ADDRESS: 'Address',
      // CREDIT_CARDS: 'CreditCards',
      // LOCK_ENTRIES: 'LockEntries'
    },

    // Home
    home: {
      items: (data: HomePageItem[]): any[] => {
        let tempVet = []
        for (let obj of data) {
          let tempObj = obj;
          tempObj['status_str'] = this.db.DICTIONARY.translator(obj.status, this.db.DICTIONARY.general.status);
          tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
          tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
          tempVet.push(tempObj)
        }
        return tempVet;
      },
      tabs: (data: HomePageTab[]): any[] => {
        let tempVet = []
        for (let obj of data) {
          let tempObj = obj;
          tempObj['status_str'] = this.db.DICTIONARY.translator(obj.status, this.db.DICTIONARY.general.status);
          tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
          tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
          tempVet.push(tempObj)
        }
        return tempVet;
      },
      comms: (data: HomePageComms[]): any[] => {
        let tempVet = []
        for (let obj of data) {
          let tempObj = obj;
          tempObj['status_str'] = this.db.DICTIONARY.translator(obj.status, this.db.DICTIONARY.general.status);
          tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
          tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
          tempVet.push(tempObj)
        }
        return tempVet;
      },
    },
    // Home

    // Help
    help: {
      hDocs: (data: H_Doc[]): any[] => {
        let tempVet = []
        for (let obj of data) {
          let tempObj = obj;
          tempObj['status_str'] = this.db.DICTIONARY.translator(obj.status, this.db.DICTIONARY.general.status);
          tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
          tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
          tempVet.push(tempObj)
        }
        return tempVet;
      },
      hFaqs: (data: H_Doc[]): any[] => {
        let tempVet = []
        for (let obj of data) {
          let tempObj = obj;
          tempObj['status_str'] = this.db.DICTIONARY.translator(obj.status, this.db.DICTIONARY.general.status);
          tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
          tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
          tempVet.push(tempObj)
        }
        return tempVet;
      },
    },
    // Help

    // Time Management
    timeManagement: {
      timeRecords: (data: TM_TimeRecord[]): any[] => {
        let tempVet = []
        for (let obj of data) {
          let tempObj = obj;
          tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
          tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
          tempVet.push(tempObj)
        }
        return tempVet;
      },
      teams: (data: TM_Team[]): any[] => {
        let tempVet = []
        for (let obj of data) {
          let tempObj = obj;
          tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
          tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
          tempVet.push(tempObj)
        }
        return tempVet;
      },
      projects: (data: TM_Project[]): any[] => {
        let tempVet = []
        for (let obj of data) {
          let tempObj = obj;
          tempObj['status_str'] = this.db.DICTIONARY.translator(obj.status, this.db.DICTIONARY.general.status);

          tempObj['project_name'] = `${obj.teamId} - ${obj.name}`;
          tempObj['project_color'] = this.db.DICTIONARY.getData(obj.colorId, this.db.DICTIONARY.timeManagement.project.color)['hex'];

          tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
          tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
          tempVet.push(tempObj)
        }
        return tempVet;
      },
    },
    // Time Management

    // Workflow
    workflow: {
      groups: (data: WF_Group[]): any[] => {
        let tempVet = []
        for (let obj of data) {
          let tempObj = obj;
          tempObj['approvalType_str'] = this.db.DICTIONARY.translator(obj.approvalType, this.db.DICTIONARY.workflow.group.approvalType);
          tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
          tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
          tempVet.push(tempObj)
        }
        return tempVet;
      },
    },
    // Workflow

    // Service Management
    serviceManagement: {
      teams: (data: TM_Team[]): any[] => {
        let tempVet = []
        for (let obj of data) {
          let tempObj = obj;
          tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
          tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
          tempVet.push(tempObj)
        }
        return tempVet;
      },
      serviceCatalog: {
        customFields: (data: SM_CustomField[]): any[] => {
          let tempVet = []
          for (let obj of data) {
            let tempObj = obj;

            tempObj['status_str'] = this.db.DICTIONARY.translator(obj.status, this.db.DICTIONARY.general.status);
            tempObj['type_str'] = this.db.DICTIONARY.translator(obj.type, this.db.DICTIONARY.serviceManagement.serviceCatalog.customField.type);

            tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
            tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
            tempVet.push(tempObj)
          }
          return tempVet;
        },
        slaDefinitions: (data: SM_SLADefinition[]): any[] => {
          let tempVet = []
          for (let obj of data) {
            let tempObj = obj;

            tempObj['status_str'] = this.db.DICTIONARY.translator(obj.status, this.db.DICTIONARY.general.status);
            tempObj['type_str'] = this.db.DICTIONARY.translator(obj.type, this.db.DICTIONARY.serviceManagement.serviceCatalog.slaDefinition.type);
            tempObj['target_str'] = this.db.DICTIONARY.translator(obj.target, this.db.DICTIONARY.serviceManagement.serviceCatalog.slaDefinition.target);

            tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
            tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
            tempVet.push(tempObj)
          }
          return tempVet;
        },
      },
    },
    // Service Management

    // Organizational Structure
    organizationalStructure: {
      calendars: (data: OS_Calendar[]): any[] => {
        let tempVet = []
        for (let obj of data) {
          let tempObj = obj;
          tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.createdOn);
          tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(obj.updatedOn);
          tempVet.push(tempObj)
        }
        return tempVet;
      },
    },
    // Organizational Structure

  };


}
