import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbLayoutDirectionService, NbStatusService } from '@nebular/theme';

import { AuthService } from 'app/@core';
import { ThemeModule } from 'app/@theme';
import { MagicTextEditorModule } from 'app/components';

import { PmCardModule } from '../pm-card/pm-card.module';
import { PMCardModalActivitiesComponent } from './components/main/activities/activities.component';
import { PMCardModalAddNoteComponent } from './components/main/add-note/add-note.component';
import { PMCardModalAddRelatedCardListComponent } from './components/main/related-cards/add-related-card-list/add-related-card-list.component';
import { PMCardListHighlightDirective } from './components/main/related-cards/add-related-card-list/db-table-highlight.directive';
import { PMCardModalRelatedCardsComponent } from './components/main/related-cards/related-cards.component';
import { PMCardModalToDoListsComponent } from './components/main/to-do-lists/to-do-lists.component';
import { PMCardModalModeAssigneesComponent } from './components/side/mode-assignees/mode-assignees.component';
import { PMCardModalModeAttachmentsComponent } from './components/side/mode-attachments/mode-attachments.component';
import { PMCardModalModeDetailsComponent } from './components/side/mode-details/mode-details.component';
import { PMCardModalModeLabelsComponent } from './components/side/mode-labels/mode-labels.component';
import { MODE_TIME_RECORDS_CHARTS_COMPONENTS } from './components/side/mode-time-records/charts/mode-time-records-charts-components';
import { PMCardModalModeTimeRecordsComponent } from './components/side/mode-time-records/mode-time-records.component';
import { PmCardModal } from './pm-card-modal';

const Modals = [
    PmCardModal
];

const Components = [

    // Main
    PMCardModalRelatedCardsComponent,
    PMCardModalAddRelatedCardListComponent,
    PMCardListHighlightDirective,
    PMCardModalToDoListsComponent,
    PMCardModalAddNoteComponent,
    PMCardModalActivitiesComponent,

    // Side
    PMCardModalModeDetailsComponent,
    PMCardModalModeAssigneesComponent,
    PMCardModalModeLabelsComponent,
    PMCardModalModeAttachmentsComponent,
    PMCardModalModeTimeRecordsComponent,
    ...MODE_TIME_RECORDS_CHARTS_COMPONENTS,
];
const Modules = [
    PmCardModule
]

@NgModule({
    declarations: [
        //Modals
        ...Modals,
        ...Components
    ],
    imports: [
        ...Modules,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ThemeModule,

        MagicTextEditorModule,
    ],
    providers: [
        NbLayoutDirectionService,
        AuthService,
        NbStatusService
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class PmCardModalModule { }
