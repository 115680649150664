import * as go from 'gojs3';

var $ = go.GraphObject.make;  // for conciseness

interface DiagramTheme {
    node: {
        font: string,
        textColor: string,
        fillColor: string,
        strokeWidth: number,
        strokeColor: string
    },
    link: {
        strokeWidth: number,
        strokeColor: string
    }
}

// This function provides a common style for most of the TextBlocks.
// Some of these values may be overridden in a particular TextBlock.
function textStyle(theme: DiagramTheme) {
    return { font: theme.node.font, stroke: theme.node.textColor };
}
// this is used to determine feedback during drags
function mayWorkFor(node1, node2) {
    if (!(node1 instanceof go.Node)) return false;  // must be a Node
    if (node1 === node2) return false;  // cannot work for yourself
    if (node2.isInTreeOf(node1)) return false;  // cannot work for someone who works for you
    return true;
}

// This converter is used by the Picture.
function findHeadShot(pic) {
    if (!pic) return "images/HSnopic.png"; // There are only 16 images on the server
    return pic;
}

export function magic_org_chart_diagInit(
    div: string,
    edit: boolean,
    theme: DiagramTheme
) {
    let myDiagram =
        new go.Diagram(div,  // create a Diagram for the HTML Div element
            {
                allowCopy: false,
                allowDelete: false,
                //initialAutoScale: go.Diagram.Uniform,
                maxSelectionCount: 1, // users can select only one part at a time
                validCycle: go.Diagram.CycleDestinationTree, // make sure users can only create trees

                layout:
                    $(go.TreeLayout,
                        {
                            treeStyle: go.TreeLayout.StyleLastParents,
                            arrangement: go.TreeLayout.ArrangementHorizontal,
                            // properties for most of the tree:
                            angle: 90,
                            layerSpacing: 35,
                            // properties for the "last parents":
                            alternateAngle: 90,
                            alternateLayerSpacing: 35,
                            alternateAlignment: go.TreeLayout.AlignmentBus,
                            alternateNodeSpacing: 20
                        }),
                "undoManager.isEnabled": true // enable undo & redo
            });

    // define the Node template
    myDiagram.nodeTemplate =
        $(go.Node, "Spot",
            {
                selectionObjectName: "BODY",
                // mouseEnter: (e, node) => node.findObject("BUTTON").opacity = node.findObject("BUTTONX").opacity = 1,
                // mouseLeave: (e, node) => node.findObject("BUTTON").opacity = node.findObject("BUTTONX").opacity = 0,
                // handle dragging a Node onto a Node to (maybe) change the reporting relationship
                mouseDragEnter: (e, node, prev) => {

                    const diagram = node.diagram;
                    const selnode = diagram.selection.first();
                    if (!mayWorkFor(selnode, node)) return;
                    console.log(node)
                    // const shape = node.findObject("SHAPE");
                    // if (shape) {
                    //     shape._prevFill = shape.fill;  // remember the original brush
                    //     shape.fill = "darkred";
                    // }
                },
                mouseDragLeave: (e, node, next) => {
                    // const shape = node.findObject("SHAPE");
                    // if (shape && shape._prevFill) {
                    //     shape.fill = shape._prevFill;  // restore the original brush
                    // }
                },
                mouseDrop: (e, node) => {
                    // const diagram = node.diagram;
                    // const selnode = diagram.selection.first();  // assume just one Node in selection
                    // if (mayWorkFor(selnode, node)) {
                    //     // find any existing link into the selected node
                    //     const link = selnode.findTreeParentLink();
                    //     if (link !== null) {  // reconnect any existing link
                    //         link.fromNode = node;
                    //     } else {  // else create a new link
                    //         diagram.toolManager.linkingTool.insertLink(node, node.port, selnode, selnode.port);
                    //     }
                    // }
                }
            },
            // for sorting, have the Node.text be the data.name
            new go.Binding("text", "name"),
            // bind the Part.layerName to control the Node's layer depending on whether it isSelected
            new go.Binding("layerName", "isSelected", sel => sel ? "Foreground" : "").ofObject(),
            $(go.Panel, "Auto",
                { name: "BODY" },
                // define the node's outer shape
                $(go.Shape, "Rectangle",
                    { name: "SHAPE", fill: theme.node.fillColor, stroke: theme.node.strokeColor, strokeWidth: theme.node.strokeWidth, portId: "" }),
                $(go.Panel, "Horizontal",
                    $(go.Picture,
                        {
                            name: "Picture",
                            desiredSize: new go.Size(70, 70),
                            margin: 1,
                            source: "images/HSnopic.png"  // the default image
                        },
                        new go.Binding("source", "pic", findHeadShot)),
                    // define the panel where the text will appear
                    $(go.Panel, "Table",
                        {
                            minSize: new go.Size(130, NaN),
                            maxSize: new go.Size(150, NaN),
                            margin: new go.Margin(6, 10, 0, 6),
                            defaultAlignment: go.Spot.Left
                        },
                        $(go.RowColumnDefinition, { column: 2, width: 4 }),


                        // the name
                        $(go.TextBlock, textStyle(theme),
                            {
                                name: "NAMETB",
                                row: 0, column: 0, columnSpan: 5,
                                font: "bolder 14pt " + theme.node.font,
                                editable: edit, isMultiline: false,
                                minSize: new go.Size(50, 16)
                            },
                            new go.Binding("text", "name").makeTwoWay()
                        ),


                        // Title
                        // $(go.TextBlock, "Title: ", textStyle(theme),
                        //     {
                        //         row: 1, column: 0,
                        //         font: "bolder 14pt " + theme.node.font,
                        //         minSize: new go.Size(20, 16)
                        //     }),
                        $(go.TextBlock, textStyle(theme),
                            {
                                row: 1, column: 0,
                                font: "italic 10pt" + theme.node.font,
                                editable: edit, isMultiline: false,
                                minSize: new go.Size(50, 16),
                                margin: new go.Margin(4, 0, 0, 0)
                            },
                            new go.Binding("text", "title").makeTwoWay()),


                        // ID
                        $(go.TextBlock, textStyle(theme),
                            {
                                row: 2, column: 0,
                                margin: new go.Margin(4, 0, 0, 0)
                            },
                            new go.Binding("text", "key", v => "ID: " + v)),


                        // $(go.TextBlock, textStyle(theme),  // the comments
                        //     {
                        //         row: 3, column: 0, columnSpan: 5,
                        //         font: "italic 10pt" + theme.node.font,
                        //         wrap: go.TextBlock.WrapFit,
                        //         editable: edit,  // by default newlines are allowed
                        //         minSize: new go.Size(100, 14)
                        //     },
                        //     new go.Binding("text", "comments").makeTwoWay())
                    ) // end Table Panel
                ) // end Horizontal Panel
            ), // end Auto Panel
        );  // end Node, a Spot Panel

    // define the Link template
    myDiagram.linkTemplate =
        $(go.Link, go.Link.Orthogonal,
            { layerName: "Background", corner: 5 },
            $(go.Shape, { strokeWidth: theme.link.strokeWidth, stroke: theme.link.strokeColor }));  // the link shape

    return myDiagram;
}