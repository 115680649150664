import { MagicTableColumn } from "../magic-table.types";
import { MAGIC_TABLE_CONSTS } from "./MAGIC_TABLE_CONSTS";
import { magicTableFormatToMonetary } from "./magic-table-format-to-monetary";
import { magicTableFormatVectorWithCommasAndAnd } from "./magic-table-format-vector-with-commas-and-and";
import { magicTableGenCellValue } from "./magic-table-gen-cell-value";
import { magicTableProgressBarPercentMaker } from "./magic-table-progress-bar-percent-maker";
import { magicTableTimeStampToLocalDate } from "./magic-table-time-stamp-to-local-date";
import { magicTableTranslateBoolean } from "./magic-table-translate-boolean";

function __internalCellDataMaker(col: MagicTableColumn, fieldData: any): string {
    let cell = fieldData;

    if (col.type == 'timestamp')
        cell = magicTableTimeStampToLocalDate(fieldData);
    else if (col.type == 'monetary')
        cell = magicTableFormatToMonetary(fieldData);
    else if (col.type == 'boolean')
        cell = magicTableTranslateBoolean(fieldData);
    else if (col.type == 'progress-bar')
        cell = magicTableProgressBarPercentMaker(fieldData);

    return magicTableGenCellValue(cell);
}
export function magicTableCellDataMaker(col: MagicTableColumn, fieldData: any): string {
    let cell = null;
    if (!Array.isArray(fieldData)) {
        // not array
        cell = __internalCellDataMaker(col, fieldData);
    } else {
        // array
        cell = [];
        for (let fValue_index = 0; fValue_index < fieldData.length; fValue_index++)
            cell.push(__internalCellDataMaker(col, fieldData[fValue_index]));

        cell = magicTableFormatVectorWithCommasAndAnd(cell);

        // empty array
        if (fieldData.length == 0)
            cell = MAGIC_TABLE_CONSTS.EMPTY_CELL_VALUE;
    }
    return cell;
}