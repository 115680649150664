import { DbTableComponent } from "../db-table.component";
import { dbTableConvertFilterValue } from "./db-table-convert-filter-value";

export function dbTableGetFilterStatus(_this: DbTableComponent, c_index: number, cell_value: any): boolean {
    let tempShow = !_this.filterActiveIndex[c_index];
    let fValue = dbTableConvertFilterValue(cell_value);
    for (let f of _this.filterIndexes[c_index])
        if (f.value == fValue) {
            tempShow = f.show;
            break;
        }
    return tempShow;
}