<div class="editor-fields-inner">

  <p>Campos a Exibir ({{_index.length}}):</p>

  <input [disabled]="!edit" (click)="_toogleAll()" type="checkbox" id="input_unselectAll_editor-fields"
    name="input_unselectAll_editor-fields" [(ngModel)]="_selectAll">
  <label for="input_unselectAll_editor-fields">(Selecionar Tudo)</label>

  <div class="fields-list">
    <div class="field" *ngFor="let field of _index; trackBy: _trackByFn; let i=index">
      <input [disabled]="!edit" type="checkbox" [id]="'input_field_'+i" [name]="'input_field_'+i"
        [(ngModel)]="field.active" (ngModelChange)="_change()">
      <label [for]="'input_field_'+i">{{field.name}}</label>


      <div class="rowUp" *ngIf="i>0">
        <button [disabled]="!edit" (click)="_rowUp(i)">
          <nb-icon icon="arrow-upward" pack="eva"></nb-icon>
        </button>
      </div>

      <div class="rowDown" *ngIf="i<_index.length-1">
        <button [disabled]="!edit" (click)="_rowDown(i)">
          <nb-icon icon="arrow-downward" pack="eva"></nb-icon>
        </button>
      </div>

    </div>
  </div>

</div>
