import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';

import { AuthService, UtilService } from 'app/@core';
import { DB_DataFieldText, DB_FieldData, DB_FILTER_CONDITION_OPERATIONS, DB_FilterCondition, DB_FilterConditionOperation, DB_FilterRule, DB_FilterRuleClause, DB_FilterRuleSet, DB_RuleUiData, FirestoreService } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

@Component({
  selector: 'db-filter-rule-ui',
  templateUrl: './db-filter-rule-ui.component.html',
  styleUrls: ['./db-filter-rule-ui.component.scss']

})
export class DbFilterRuleUiComponent implements OnInit, OnChanges {

  readonly className = 'DbFilterRuleUiComponent';

  readonly OR_CLAUSE: DB_FilterRuleClause = 'OR';
  readonly AND_CLAUSE: DB_FilterRuleClause = 'AND';

  @Input() disabled = false;
  @Output() onChange = new EventEmitter();
  hasChanges = false;
  hasError = false;

  @Input() edit = false;

  dataSourceId: string = '';
  oldruleSet: DB_FilterRuleSet;

  ruleSet: DB_FilterRuleSet;
  @Output() ruleSetChange = new EventEmitter<DB_FilterRule[]>();

  _dataSourceData: DB_RuleUiData = new DB_RuleUiData();

  private conditionsOperations = DB_FILTER_CONDITION_OPERATIONS;
  _operations4Sel: DB_FilterConditionOperation[][][][] = [];
  _fieldTexts4Sel: DB_DataFieldText[][][][] = [];

  _fieldDataIndex: DB_FieldData[][][] = [];
  _operationDataIndex: DB_FilterConditionOperation[][][] = [];

  _loader = false;
  _showConstructor = false;
  _queryStr = '';

  constructor(
    private authService: AuthService,
    public db: FirestoreService,
    public uiFeedBackCtrl: UiFeedBackService,
    public utilCtrl: UtilService,
  ) { }

  ngOnInit() { }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }

  private __ruleSetStandardizer(ruleSet: DB_FilterRuleSet): DB_FilterRuleSet {
    let _ruleSet = ruleSet;
    _ruleSet
      .forEach(filterRules => {
        filterRules.forEach(rule => {
          rule.conditions.forEach(cond => {
            cond.value = cond.value;
            // Date
            if (typeof cond.value.toISOString === 'function')
              cond.value = this.utilCtrl.date.getDateTimeString(cond.value);
            // Date
          })
        })
      })
    return JSON.parse(JSON.stringify(_ruleSet));
  }
  public load(dataSourceId: string, ruleSet: DB_FilterRuleSet) {
    this._loader = true;

    this.dataSourceId = dataSourceId;
    this.ruleSet = this.__ruleSetStandardizer(ruleSet);
    this.oldruleSet = JSON.parse(JSON.stringify(this.ruleSet));

    this.db.dataSource.getRuleUiDataById(this.dataSourceId)
      .then(data => {
        this._dataSourceData = data;
        this._change();
        this._loader = false;
      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }
  public setInternalData(dataSourceId: string, ruleSet: DB_FilterRuleSet, dataSourceData: DB_RuleUiData) {
    this.dataSourceId = dataSourceId;

    this.ruleSet = this.__ruleSetStandardizer(ruleSet);
    this.oldruleSet = JSON.parse(JSON.stringify(this.ruleSet));

    this._dataSourceData = dataSourceData;
    this._change();
  }
  getData(): DB_FilterRuleSet {
    let tempData = JSON.parse(JSON.stringify(this.ruleSet)) as DB_FilterRuleSet;

    for (let fr_index = 0; fr_index < tempData.length; fr_index++) {
      const filterRules = tempData[fr_index];

      for (let r_index = 0; r_index < filterRules.length; r_index++) {
        const rule = filterRules[r_index];

        for (let condition_index = 0; condition_index < rule.conditions.length; condition_index++) {
          if (rule.conditions[condition_index].value) {
            let fieldData = this._dataSourceData.fields.filter(field => { return field.id == rule.conditions[condition_index].fieldId })[0];

            // timestamp
            if (fieldData && fieldData.type == 'timestamp')
              rule.conditions[condition_index].value = new Date(rule.conditions[condition_index].value);
            // timestamp

          }
        }

      }

    }

    return tempData;
  }
  getQueryText(): string {
    return this._queryStr;
  }
  // Rotinas de controle
  _updateQueryStr() {
    let tempQueryStr = '';

    for (let fr_index = 0; fr_index < this.ruleSet.length; fr_index++) {
      const filterRules = this.ruleSet[fr_index];
      let filterRulesStr = '';

      for (let r_index = 0; r_index < filterRules.length; r_index++) {
        const rule = filterRules[r_index];

        filterRulesStr += ` ${this.db.DICTIONARY.translator(rule.clause, this.db.DICTIONARY.sys.db.filterRule.clause)}(`;

        for (let condition_index = 0; condition_index < rule.conditions.length; condition_index++) {
          const condition = rule.conditions[condition_index];

          // fieldStr
          let fieldStr = '';
          let fieldData = this._dataSourceData.fields.filter(f => { return f.id == condition.fieldId })[0];
          if (fieldData)
            fieldStr = fieldData.name;
          // fieldStr

          // operationStr
          let operationStr = '';
          if (condition.operationId)
            operationStr = this.db.DICTIONARY.translator(condition.operationId, this.db.DICTIONARY.sys.db.filterRule.conditions.operation);
          // operationStr

          // valueStr
          let valueStr = '';
          if (condition.value !== null && fieldData) {
            let fieldText = this._dataSourceData.dataFieldTexts.filter(ft => { return ft.fieldId == fieldData.dataFieldId && ft.value == condition.value; })[0];
            if (fieldText)
              valueStr = fieldText.text;
            else {
              if (fieldData && fieldData.type == 'timestamp')
                valueStr = this.utilCtrl.date.toLocalDate(new Date(condition.value));
              else if (fieldData && fieldData.type == 'boolean')
                valueStr = this.utilCtrl.converters.booleanToStr(condition.value);
              else
                valueStr = condition.value;
            }
          } else if (condition.value)
            valueStr = condition.value;
          // valueStr

          filterRulesStr += `"${fieldStr} ${operationStr} ${valueStr}"`;

          if (condition_index < rule.conditions.length - 1)
            filterRulesStr += `, `;
        }

        filterRulesStr += `)`;
      }

      tempQueryStr += `> {${filterRulesStr} }`;
      if (fr_index < this.ruleSet.length - 1)
        tempQueryStr += ` `;
    }

    this._queryStr = tempQueryStr;
  }
  _fieldId_input_change(fr_index: number, r_index: number, condition_index: number) {
    this.ruleSet[fr_index][r_index].conditions[condition_index].operationId = null;
    this.ruleSet[fr_index][r_index].conditions[condition_index].value = null;
    this._operation_input_change(fr_index, r_index, condition_index);
  }
  _operation_input_change(fr_index: number, r_index: number, condition_index: number) {
    this.ruleSet[fr_index][r_index].conditions[condition_index].value = null;
    this._change();
  }
  _getOperationData(operationId: string) {
    if (!operationId)
      return null;
    return this.conditionsOperations.filter(op => { return op.id == operationId })[0];
  }
  _checkForChanges() {
    if (
      JSON.stringify(this.oldruleSet) != JSON.stringify(this.ruleSet)
    )
      this.hasChanges = true
    else
      this.hasChanges = false
  }
  _checkForError() {
    let hasError = false;

    for (let fr_index = 0; fr_index < this.ruleSet.length; fr_index++) {
      const filterRules = this.ruleSet[fr_index];


      for (let r_index = 0; r_index < filterRules.length; r_index++) {
        const rule = filterRules[r_index];

        for (let condition_index = 0; condition_index < rule.conditions.length; condition_index++) {
          const condition = rule.conditions[condition_index];

          if (!condition.fieldId)
            hasError = true;

          if (!condition.operationId)
            hasError = true;
          else {
            let tempCO = this.conditionsOperations.filter(op => { return op.id == condition.operationId })[0];

            if (tempCO.inputType != 'none' && (condition.value === null || condition.value === undefined))
              hasError = true;
          }

          if (hasError)
            break;
        }
        if (hasError)
          break;

      }

    }

    this.hasError = hasError;
  }
  _change() {
    setTimeout(() => {
      this._updateFieldDataIndex();
      this._updateOperations4Sel();
      this._updateOperationDataIndex();
      this._updateFieldTexts4Sel();
      this._updateQueryStr();
      this._checkForChanges();
      this._checkForError();
      this.onChange.emit();
    }, 250);
  }
  _updateFieldDataIndex() {
    let tempFieldDataIndex: DB_FieldData[][][] = [];

    for (let fr_index = 0; fr_index < this.ruleSet.length; fr_index++) {
      const filterRules = this.ruleSet[fr_index];
      tempFieldDataIndex[fr_index] = [];

      for (let r_index = 0; r_index < filterRules.length; r_index++) {
        const rule = filterRules[r_index];
        tempFieldDataIndex[fr_index][r_index] = [];

        for (let condition_index = 0; condition_index < rule.conditions.length; condition_index++) {
          const condition = rule.conditions[condition_index];

          let tempFieldData = this._dataSourceData.fields.filter(field => { return field.id == condition.fieldId })[0];
          tempFieldDataIndex[fr_index][r_index][condition_index] = tempFieldData;
        }

      }

    }

    this._fieldDataIndex = tempFieldDataIndex;
  }
  _updateOperationDataIndex() {
    let tempOperationDataIndex: DB_FilterConditionOperation[][][] = [];

    for (let fr_index = 0; fr_index < this.ruleSet.length; fr_index++) {
      const filterRules = this.ruleSet[fr_index];
      tempOperationDataIndex[fr_index] = [];

      for (let r_index = 0; r_index < filterRules.length; r_index++) {
        const rule = filterRules[r_index];
        tempOperationDataIndex[fr_index][r_index] = [];

        for (let condition_index = 0; condition_index < rule.conditions.length; condition_index++) {
          const condition = rule.conditions[condition_index];

          let tempCO = this.conditionsOperations.filter(op => { return op.id == condition.operationId })[0];
          tempOperationDataIndex[fr_index][r_index][condition_index] = tempCO;
        }

      }

    }

    this._operationDataIndex = tempOperationDataIndex;
  }
  _updateOperations4Sel() {
    let tempOperations4Sel: DB_FilterConditionOperation[][][][] = [];

    for (let fr_index = 0; fr_index < this.ruleSet.length; fr_index++) {
      const filterRules = this.ruleSet[fr_index];
      tempOperations4Sel[fr_index] = [];

      for (let r_index = 0; r_index < filterRules.length; r_index++) {
        const rule = filterRules[r_index];
        tempOperations4Sel[fr_index][r_index] = [];

        for (let condition_index = 0; condition_index < rule.conditions.length; condition_index++) {
          const condition = rule.conditions[condition_index];
          tempOperations4Sel[fr_index][r_index][condition_index] = [];

          let selectedField: DB_FieldData = this._dataSourceData.fields.filter(dsf => { return dsf.id == condition.fieldId })[0];
          this.conditionsOperations
            .forEach(co => {
              if (
                selectedField &&
                ((co.fieldTypes.indexOf(selectedField.type) != -1) || (co.fieldTypes.indexOf('*') != -1))
              ) {
                let tempCO = co as any;
                tempCO.name = this.db.DICTIONARY.translator(co.id, this.db.DICTIONARY.sys.db.filterRule.conditions.operation);
                tempOperations4Sel[fr_index][r_index][condition_index].push(tempCO)
              }
            })
        }

      }

    }

    this._operations4Sel = tempOperations4Sel;
  }
  _updateFieldTexts4Sel() {
    let tempFieldTexts4Sel: DB_DataFieldText[][][][] = [];

    for (let fr_index = 0; fr_index < this.ruleSet.length; fr_index++) {
      const filterRules = this.ruleSet[fr_index];
      tempFieldTexts4Sel[fr_index] = [];

      for (let r_index = 0; r_index < filterRules.length; r_index++) {
        const rule = filterRules[r_index];
        tempFieldTexts4Sel[fr_index][r_index] = [];

        for (let condition_index = 0; condition_index < rule.conditions.length; condition_index++) {
          const condition = rule.conditions[condition_index];
          tempFieldTexts4Sel[fr_index][r_index][condition_index] = [];

          let selectedField: DB_FieldData = this._dataSourceData.fields.filter(dsf => { return dsf.id == condition.fieldId })[0];
          this._dataSourceData.dataFieldTexts
            .forEach(dft => {
              if (
                selectedField &&
                ((dft.dataSourceId == null || dft.dataSourceId == selectedField.dataSourceId) && dft.fieldId == selectedField.dataFieldId)) {
                tempFieldTexts4Sel[fr_index][r_index][condition_index].push(dft)
              }
            })
        }

      }

    }

    this._fieldTexts4Sel = tempFieldTexts4Sel;
  }
  _toggleShowConstructor() {
    this._showConstructor = !this._showConstructor;
  }
  _addSet() {
    this.ruleSet.push([]);
    this._addRule(this.ruleSet.length - 1)
    this._change();
  }
  _addRule(fr_index: number, clause: DB_FilterRuleClause = 'AND') {
    let tempRule = new DB_FilterRule();
    tempRule.clause = clause;
    tempRule.conditions.push(new DB_FilterCondition());
    this.ruleSet[fr_index].push(tempRule);
    this._change();
  }
  _remCondition(fr_index: number, r_index: number, condition_index: number) {
    if (this.ruleSet[fr_index].length == 1 && this.ruleSet[fr_index][0].conditions.length == 1) {
      this.ruleSet.splice(fr_index, 1);
    } else {
      if (this.ruleSet[fr_index][r_index].conditions.length > 1)
        this.ruleSet[fr_index][r_index].conditions.splice(condition_index, 1);
      else
        this.ruleSet[fr_index].splice(r_index, 1);
    }
    this._change();
  }
  _addCondition(fr_index: number, r_index: number, condition_index: number, clause: DB_FilterRuleClause = 'AND') {
    if (clause == 'AND') {
      if (this.ruleSet[fr_index][r_index].clause == clause) {
        this.ruleSet[fr_index][r_index].conditions.push(new DB_FilterCondition());
        this._change();
      } else {
        if (this.ruleSet[fr_index][this.ruleSet[fr_index].length - 1].clause == 'AND') {
          this.ruleSet[fr_index][this.ruleSet[fr_index].length - 1].conditions.push(new DB_FilterCondition());
          this._change();
        } else
          this._addRule(fr_index, clause);
      }
    }

    if (clause == 'OR') {
      let oldCondition = this.ruleSet[fr_index][r_index].conditions[condition_index];

      if (this.ruleSet[fr_index][r_index].clause == clause || this.ruleSet[fr_index][r_index].conditions.length == 1) {
        this.ruleSet[fr_index][r_index].clause = 'OR';
        this.ruleSet[fr_index][r_index].conditions.push(JSON.parse(JSON.stringify(oldCondition)));
        this._change();
      } else {
        this.ruleSet[fr_index][r_index].conditions.splice(condition_index, 1);
        let tempRule = new DB_FilterRule();
        tempRule.clause = clause;
        tempRule.conditions.push(JSON.parse(JSON.stringify(oldCondition)));
        tempRule.conditions.push(JSON.parse(JSON.stringify(oldCondition)));
        this.ruleSet[fr_index].push(tempRule);
        this._change();
      }
    }
  }
  _trackByFn(index, item) {
    return index;
  }
  _compareFn(o1, o2): boolean {
    let result = false;
    if (o1 === null && o2 === null)
      result = true;
    else if (o1 != null && o2 != null) {
      if (o1 === o2)
        result = true;
      else if (o1.id != null && o2.id != null && o1.id === o2.id)
        result = true;
    }
    return result;
  }
  // Rotinas de controle
}
