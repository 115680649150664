import firebase from "firebase/compat/app";

export type SM_DocActivityType =
  /** Geral */
  "GR" |
  /** Criação */
  "CR" |
  /** Comentário */
  "C" |
  /** Alteraçõees */
  "FC";

export type SM_DocActivityVisibility =
  /** Geral */
  "G" |
  /** Internal */
  "I";

export class SM_DocActivity {

  id: string;
  uName: string;
  smDocId: string;

  text: string;
  html: string;

  /**
   * @GR Geral
   * @CR Criação
   * @C Comentário
   * @PR Impressão
   */
  type: SM_DocActivityType;

  /**
   * @G Geral
   * @I Internal
   */
  visibility: SM_DocActivityVisibility;

  createdOn: firebase.firestore.Timestamp;

  public constructor(init?: Partial<SM_DocActivity>) {

    this.id = '';
    this.uName = '';
    this.smDocId = '';

    this.text = '';
    this.html = '';

    this.type = null;
    this.visibility = null;

    this.createdOn = firebase.firestore.Timestamp.now();

    if (init)
      Object.assign(this, init);
  }
}
