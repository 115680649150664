import { EMPTY_CELL_FILTER_VALUE, EMPTY_CELL_VALUE } from "../magic-table.consts";
import { MagicTableColumn, MagicTableRowData, OrderDirection, SortField } from "../magic-table.types";
import { _formatToMonetary, _formatVectorWithCommasAndAnd, _progressBarPercentMaker, _timeStampToLocalDate, _translateBoolean } from "./magic-table_formatters";
import firebase from "firebase/compat/app";

const TIMESTAMP_KEYS = ["seconds", "nanoseconds"];
const TIMESTAMP2_KEYS = ["_seconds", "_nanoseconds"];

function __compareObjectKeysArrays(keys1: string[], keys2: string[]): boolean {
    let equals = true;
    for (let k1 of keys1) {
        if (keys2.indexOf(k1) == -1) {
            equals = false;
            break;
        }
    }
    if (equals)
        for (let k2 of keys2) {
            if (keys1.indexOf(k2) == -1) {
                equals = false;
                break;
            }
        }
    return equals;
}
function __newTimestamp(seconds: number, nanoseconds: number): firebase.firestore.Timestamp { return new firebase.firestore.Timestamp(seconds, nanoseconds) }
/**
 * Função para facilitar a comparação entre valores, para valores vazios sempre retornará "".
 * @param value 
 * @returns 
 */
function _valueStandardizer(value: any): any {

    // null | undefined
    if (!value) return "";

    // Array
    if (Array.isArray(value)) {
        if (value.length === 0) return "";
        return value.map((item) => _valueStandardizer(item));
    }

    // Object
    if (typeof value === 'object') {
        const value_keys = Object.keys(value);

        // Date or Timestamp
        if (value instanceof Date) return value.getTime();
        if (__compareObjectKeysArrays(value_keys, TIMESTAMP_KEYS))
            return __newTimestamp(value['seconds'], value['nanoseconds']).toDate().getTime();
        if (__compareObjectKeysArrays(value_keys, TIMESTAMP2_KEYS))
            return __newTimestamp(value['_seconds'], value['_nanoseconds']).toDate().getTime();

        return JSON.stringify(value);
    }

    // Date and time format - ISO 8601
    const dateTimeRegex = /\d{4}(.\d{2}){2}(\s|T)(\d{2}.){2}\d{2}/g;
    if (dateTimeRegex.test(value)) return new Date(value).getTime();

    // Number
    const tempNum = Number(value);
    if (!isNaN(tempNum)) return tempNum;

    return value;
}
export function _compareFunction(v1: any, v2: any) {
    const vA = _valueStandardizer(v1);
    const vB = _valueStandardizer(v2);
    return vA == vB;
}
export function _isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
function __internalCellDataMaker(col: MagicTableColumn, fieldData: any): string {
    let cell = fieldData;

    if (col.type == 'timestamp')
        cell = _timeStampToLocalDate(fieldData);
    else if (col.type == 'monetary')
        cell = _formatToMonetary(fieldData);
    else if (col.type == 'boolean')
        cell = _translateBoolean(fieldData);
    else if (col.type == 'progress-bar')
        cell = _progressBarPercentMaker(fieldData);

    return _genCellValue(cell);
}
export function _cellDataMaker(col: MagicTableColumn, fieldData: any): string {
    let cell = null;
    if (!Array.isArray(fieldData)) {
        // not array
        cell = __internalCellDataMaker(col, fieldData);
    } else {
        // array
        cell = [];
        for (let fValue_index = 0; fValue_index < fieldData.length; fValue_index++)
            cell.push(__internalCellDataMaker(col, fieldData[fValue_index]));

        cell = _formatVectorWithCommasAndAnd(cell);

        // empty array
        if (fieldData.length == 0)
            cell = EMPTY_CELL_VALUE;
    }
    return cell;
}

function __cleanValue(value: any, type: 'cell' | 'filter'): string {
    let empty_value = "";
    if (type == "cell")
        empty_value = EMPTY_CELL_VALUE;
    else if (type == "filter")
        empty_value = EMPTY_CELL_FILTER_VALUE;
    let v = '';
    // Tratar valores nulos e indefinidos
    if (value === null || value === undefined) {
        return empty_value;
    }

    // Tratar valores booleanos
    if (typeof value === 'boolean') {
        v = _translateBoolean(value);
    }
    // Tratar valores numéricos
    else if (typeof value === 'number') {
        v = value.toLocaleString('pt-BR');
    }
    // Tratar valores string (remover HTML)
    else if (typeof value === 'string' && type != "cell") {
        v = value.replace(/<(?:.|\n)*?>/gm, ' ');
    }
    // Tratar outros tipos de dados
    else {
        v = value + "";
    }

    // Caso o valor seja vazio após a remoção de HTML
    if (v.trim() === '') {
        v = empty_value;
    }
    return v;
}

export function _genFilterValue(value: any): string {
    return __cleanValue(value, "filter");
}
export function _genCellValue(value: any): string {
    return __cleanValue(value, "cell");
}
export function _normalizeString(input: string): string {
    // Remover acentos e normalizar a string
    const withoutAccents = input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // Converter para minúsculas
    const lowerCase = withoutAccents.toLowerCase();

    // Remover caracteres especiais
    const withoutSpecialChars = lowerCase.replace(/[^a-z0-9\s]/g, '');

    // Substituir espaços por underscores
    const withUnderscores = withoutSpecialChars.replace(/\s+/g, '_');

    return withUnderscores;
}
export function _orderByCore(a: any, b: any, direction: OrderDirection): number {
    let aValue = _valueStandardizer(a);
    let bValue = _valueStandardizer(b);
    if (Array.isArray(aValue)) {
        aValue = aValue.sort((a, b) => _orderByCore(a, b, direction));
        aValue = aValue[0];
    }
    if (Array.isArray(bValue)) {
        bValue = bValue.sort((a, b) => _orderByCore(a, b, direction));
        bValue = bValue[0];
    }
    if (aValue < bValue) return direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return direction === 'asc' ? 1 : -1;
    return 0;
};
/**
 * 
 * @param data any[]
 * @param sortFields Array of objects containing fieldName and direction
 * @returns 
 */
export function _localSort(data: any[], sortFields: SortField[] = []): any[] {
    return data.sort((a, b) => {
        let result = 0;
        for (let i = 0; i < sortFields.length; i++) {
            const { fieldName, direction } = sortFields[i];
            result = _orderByCore(a[fieldName], b[fieldName], direction);

            if (result !== 0)
                break;
        }
        return result;
    });
}