import { Component, Input } from '@angular/core';

@Component({
  selector: 'ic-main-layout',
  styleUrls: ['./main.layout.scss'],
  templateUrl: './main.layout.html',
})
export class MainLayoutComponent {
  @Input() fullscreen = false;
}
