import firebase from "firebase/compat/app";

export class WS_TeamAssociation {

  id: string;
  teamId: string;
  parentKey: string;
  uName: string;
  role: 'Admin' | 'Member';
  status: 0 | 1;

  /** Jornada de trabalho */
  workingHours: number;

  /** Valor para cobrança */
  billableCost: number;
  /** Valor da mão de obra */
  laborCost: number;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<WS_TeamAssociation>) {

    this.id = '';
    this.teamId = '';
    this.parentKey = '';
    this.uName = '';
    this.role = 'Member';
    this.status = 1;

    this.workingHours = 0;
    this.billableCost = 0;
    this.laborCost = 0;

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;


    if (init)
      Object.assign(this, init);
  }
}
