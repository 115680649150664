import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';

import { AuthService, KeybindsService, UtilService } from 'app/@core';
import { FirestoreService, TM_Project, TM_ProjectAsc, TM_ProjectTask, TM_Tag, TM_TeamAsc, TM_TimeRecord, TM_TimerUserConfig } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

import { checkSession } from '../timer-session-utils';
import { TimerModeType } from '../timer.component';
import { TimerListTabUserConfigModalComponent } from './timer-list-tab-user-config-modal/timer-list-tab-user-config-modal.component';
import { DAY_TESTS, INPUT_HOUR, INPUT_MIN, LINE_TESTS } from './timer-list-tab.consts';
import { DataCoreType, TimerListLine, TimerListLineDay, weekDayNames } from './timer-list-tab.types';
import { TM_IntegrationData } from './TM_IntegrationData';

const USER_CONFIG_PARAM_ID = "TM_TimerUserConfig";

@Component({
  selector: 'timer-list-tab',
  templateUrl: './timer-list-tab.component.html',
  styleUrls: ['./timer-list-tab.component.scss']
})
export class TimerListTabComponent implements OnInit, OnChanges {

  className = 'TimerListTabComponent';

  userConfig: TM_TimerUserConfig = new TM_TimerUserConfig();
  uName: string = '';
  todayDateStr: string = this.utilCtrl.date.getDateStrBR(this.utilCtrl.date.newDate());
  editionMode: 'user' | 'manager' = 'user';

  // Ascs
  tmTeamAscs: TM_TeamAsc[] = [];
  tmProjectAsc: TM_ProjectAsc[] = [];
  // Ascs

  dataCore: DataCoreType = new DataCoreType();
  selectionData: DataCoreType = new DataCoreType();

  integrationPresetProject: TM_Project = new TM_Project();
  integrationData: TM_IntegrationData = new TM_IntegrationData();
  integrationActivate = false;

  @Input() datePeriodFrom: Date = new Date();
  @Input() datePeriodTo: Date = new Date();

  @Input() mode: TimerModeType;
  @Input() edit = false;

  @Output() onChange = new EventEmitter();
  @Input() hasChanges = false;
  @Output() hasChangesChange = new EventEmitter();
  @Input() hasError = false;
  @Output() hasErrorChange = new EventEmitter();

  timeRecordsIdsToDelete: string[] = [];
  timeRecordsIds: string[] = [];

  @Input() timeRecords: TM_TimeRecord[] = [];
  @Output() timeRecordsChange = new EventEmitter<TM_TimeRecord[]>();

  lines: TimerListLine[] = [];
  weekendDays = [0, 6];
  days: {
    date: Date;
    dateWeek: number;
    dateMonth: number;
    dateYear: number;
    dateDay: number;
    dateStr: string;
    weekDay: string;
    isWeekendDay: boolean;
    time: string,
    h: number;
    m: number;
    val: number;
  }[] = [];
  weeklyGoal_status = {
    status: 'not-set',
    dif: 0,
    difH: 0,
    difM: 0,
    difPerc: 0
  }
  weeklyGoal_days: {
    status: 'not-set' | 'ok' | 'up' | 'down';
    dif: number;
    difH: number;
    difM: number;
    difPerc: number;
  }[] = [];
  totalHours = 0;

  loader = false;

  showTagPopdown = -1;
  showOptionsPopdown = -1;

  input_hour = INPUT_HOUR;
  input_min = INPUT_MIN;
  lineTests = LINE_TESTS;
  dayTests = DAY_TESTS;
  errors: string[] = [];
  dayIndexesWithErros: number[] = [];
  confettiFired: number[] = [];

  @ViewChild('TagPopdownContentERef', { read: ElementRef, static: false }) tagPopdownContentERef: ElementRef;
  @ViewChild('OptionPopdownContentERef', { read: ElementRef, static: false }) optionPopdownContentERef: ElementRef;

  selectedLineIndex = -1;

  constructor(
    public db: FirestoreService,
    private authService: AuthService,
    private uiFeedBackCtrl: UiFeedBackService,
    protected utilCtrl: UtilService,
    private keybindsCtrl: KeybindsService
  ) { }
  @HostListener('window:keydown.esc', ['$event'])
  onEsc() {
    this.selectedLineIndex = -1;
    this._closeTagPopdown();
    this._closeOptionsPopdown();
  }
  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.tagPopdownContentERef && !this.tagPopdownContentERef.nativeElement.contains(event.target)) {
      this._closeTagPopdown();
    }
    if (this.optionPopdownContentERef && !this.optionPopdownContentERef.nativeElement.contains(event.target)) {
      this._closeOptionsPopdown();
    }
  }

  ngOnInit() {
    this.keybindsCtrl.add(this.className, {
      keyCode: 'KeyN',
      altKey: true,
      function: () => {
        if (!this.edit)
          return;
        this.addLine();
      }
    });
    this.keybindsCtrl.add(this.className, {
      keyCode: 'KeyD',
      altKey: true,
      function: () => {
        if (!this.edit)
          return;
        if (this.selectedLineIndex == -1) {
          this.uiFeedBackCtrl.presentAlert('Nehuma linha selecioanda!', 'Selecione uma linha para que ela seja duplicada!', 'warning');
          return;
        }
        this._dupLine(this.selectedLineIndex);
      }
    });
    this.keybindsCtrl.add(this.className, {
      keyCode: 'Delete',
      altKey: true,
      function: () => {
        if (!this.edit)
          return;
        if (this.selectedLineIndex == -1) {
          this.uiFeedBackCtrl.presentAlert('Nehuma linha selecioanda!', 'Selecione uma linha para que ela seja apagada!', 'warning');
          return;
        }
        this._remLine(this.selectedLineIndex);
      }
    });
    this.keybindsCtrl.add(this.className, {
      keyCode: 'KeyS',
      altKey: true,
      function: () => {
        if (!this.hasChanges) {
          this.uiFeedBackCtrl.presentAlert('Nehuma alteração a ser Salva!', 'Não existem alterações que necessitam salvar!', 'success');
          return;
        }
        if (this.errors.length > 0) {
          this.uiFeedBackCtrl.presentAlert('Erros de Preenchimento identificados!', 'Verifique e corrija os erros de preenchimento antes de salvar!', 'warning');
          return;
        }
        if (this.edit)
          this.trySave();
      }
    });
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }
  ngOnDestroy() {
    this.keybindsCtrl.remove(this.className);
  }
  // ------------------------------------------------- U S E R _ C O N F I G
  showUserConfigModal() {
    if (this.hasChanges) {
      this.uiFeedBackCtrl.presentAlert('Conclua suas edições primeiro', 'Para alterar configurações desta pagina, salve os dados antes e tente novamente.', 'warning', true);
      return;
    }

    let tempData = JSON.parse(JSON.stringify(this.userConfig));
    let active_TimerListTabUserConfigModalRef = this.uiFeedBackCtrl.dialog_opener(TimerListTabUserConfigModalComponent, {
      id: 'TimerListTabUserConfigModalRef_' + new Date().getTime(),
      data: tempData
    });
    active_TimerListTabUserConfigModalRef.afterClosed()
      .subscribe(data => {
        if (data)
          this.saveUserConfigModal(data);
      });
  }
  saveUserConfigModal(userConfig: TM_TimerUserConfig) {
    this.uiFeedBackCtrl.presentLoader()
      .then(() => {
        this.authService.set_parameter(USER_CONFIG_PARAM_ID, userConfig)
          .then(() => {
            this.uiFeedBackCtrl.dismissLoader();
            this.load(this.uName);
          })
          .catch((e) => {
            this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
          })
      })
  }
  // ------------------------------------------------- U S E R _ C O N F I G

  // ------------------------------------------------- L O A D
  private loadDataForSelection(): Promise<void> {
    return new Promise((resolve, reject) => {

      this.selectionData = new DataCoreType();

      this.tmTeamAscs = [];
      this.tmProjectAsc = [];

      return this.db.timeManagement.projectAscs.getDataByWhere('uName', '==', this.uName)
        .then(user_TmProjectAscs => {
          this.tmProjectAsc = user_TmProjectAscs.filter(pa => { return pa.status == 1 });
          return this.db.timeManagement.teamAsc.getDataByWhere('uName', '==', this.uName)
            .then(user_TmTeamAsc => {
              this.tmTeamAscs = user_TmTeamAsc.filter(ta => { return ta.status == 1 });

              let userTeamsIds = [];
              this.tmTeamAscs.forEach(ta => {
                userTeamsIds.push(ta.teamId)
              })

              let userProjectsIds = [];
              this.tmProjectAsc.forEach(pa => {
                userProjectsIds.push(pa.projectId);
              })

              return this.db.timeManagement.projects.getDataByIds(userProjectsIds)
                .then(data => {
                  let tempProjects = data.filter(p => { return p.status == 1 && userTeamsIds.indexOf(p.teamId) != -1 })
                  this.selectionData.projects = tempProjects;

                  return this.db.timeManagement.projectTasks.getDataByWheres('projectId', '==', userProjectsIds)
                    .then(data => {
                      this.selectionData.projectTasks = data;
                      return this.db.timeManagement.tags.getDataByWheres('teamId', '==', userTeamsIds)
                        .then(data => {
                          this.selectionData.tags = data;
                          this.dataCore = JSON.parse(JSON.stringify(this.selectionData));
                          this.updatePresetProjectData();
                          resolve();
                        })
                    })
                })
            })
        })
        .catch(e => reject(e));
    });
  }
  private updateDataCore(): Promise<void> {
    return new Promise((resolve, reject) => {

      let old_projectsIds = [];
      let old_projectTasksIds = [];
      let old_tagsIds = [];
      this.dataCore.projects.forEach(p => {
        old_projectsIds.push(p.id);
      })
      this.dataCore.projectTasks.forEach(t => {
        old_projectTasksIds.push(t.id);
      })
      this.dataCore.tags.forEach(t => {
        old_tagsIds.push(t.id);
      })

      let new_projectsIds = [];
      let new_projectTasksIds = [];
      let new_tagsIds = [];
      this.timeRecords
        .forEach(tr => {
          if (old_projectsIds.indexOf(tr.projectId) == -1)
            new_projectsIds.push(tr.projectId)

          if (tr.taskId != '' && old_projectTasksIds.indexOf(tr.taskId) == -1)
            new_projectTasksIds.push(tr.taskId)

          tr.tagsIds.forEach(tId => {
            if (old_tagsIds.indexOf(tId) == -1)
              new_tagsIds.push(tId)
          })
        });

      return this.db.timeManagement.projects.getDataByIds(new_projectsIds)
        .then(data => {
          data.forEach(d => {
            this.dataCore.projects.push(d)
          })

          return this.db.timeManagement.projectTasks.getDataByIds(new_projectTasksIds)
            .then(data => {
              data.forEach(d => {
                this.dataCore.projectTasks.push(d)
              })

              return this.db.timeManagement.tags.getDataByIds(new_tagsIds)
                .then(data => {
                  data.forEach(d => {
                    this.dataCore.tags.push(d)
                  })

                  resolve();
                });
            });
        })
        .catch(e => reject(e));

    });
  }
  private _getFirstValuedDayIndexForLine(line: TimerListLine): number {
    let temp_firstValuedDayIndex = -1;
    for (let index = 0; index < line.days.length; index++) {
      const d = line.days[index];
      if (d.val > 0) {
        temp_firstValuedDayIndex = index;
        break;
      }
    }
    return temp_firstValuedDayIndex;
  }
  private _sortLines(lines: TimerListLine[]): TimerListLine[] {
    let ord = "asc";
    let tempRows = lines;
    tempRows.sort(function (a, b) {

      let valueA = a.firstValuedDayIndex + "-" + a.project.id + "-" + a.task.id + "-" + a.value;
      let valueB = b.firstValuedDayIndex + "-" + b.project.id + "-" + b.task.id + "-" + b.value;

      if (ord == "asc") {
        if (valueA < valueB)
          return -1;
        if (valueA > valueB)
          return 1;
      } else {
        if (valueA > valueB)
          return -1;
        if (valueA < valueB)
          return 1;
      }
      return 0;
    });
    return tempRows;
  }
  private _genMergeKeyForTimeRec(timeRecord: TM_TimeRecord): string {
    let tagsIds = '';
    for (let index = 0; index < timeRecord.tagsIds.length; index++) {
      tagsIds = tagsIds + timeRecord.tagsIds[index];
      if (index < timeRecord.tagsIds.length - 1)
        tagsIds = tagsIds + '-';
    }
    return `${timeRecord.projectId}-${timeRecord.taskId}-${tagsIds}-${timeRecord.description}`;
  }
  private _genLines(timeRecords: TM_TimeRecord[]): TimerListLine[] {
    let tempLines: TimerListLine[] = [];
    let userProjectsIds = [];
    this.tmProjectAsc.forEach(pa => { userProjectsIds.push(pa.projectId) });

    // Create TR Index
    let timeRecordsWMergeKey: { mergeKey: string; timeRecord: TM_TimeRecord; }[] = [];
    for (let tr of timeRecords) {
      timeRecordsWMergeKey.push(
        {
          mergeKey: this._genMergeKeyForTimeRec(tr),
          timeRecord: tr
        }
      )
    }
    let recordsIndex = new Map<string, TM_TimeRecord[]>();
    for (let trwmk of timeRecordsWMergeKey) {
      if (recordsIndex.get(trwmk.mergeKey))
        recordsIndex.get(trwmk.mergeKey).push(trwmk.timeRecord);
      else
        recordsIndex.set(trwmk.mergeKey, [trwmk.timeRecord]);
    }

    // Create Lines
    recordsIndex.forEach((timeRecords, mergeKey) => {
      let tempLine = new TimerListLine();
      tempLine.status = `old`;

      let timeRecord = timeRecords[0];
      tempLine.oldData.projectId = timeRecord.projectId;
      tempLine.oldData.taskId = timeRecord.taskId;
      tempLine.oldData.tagsIds = timeRecord.tagsIds;
      tempLine.oldData.description = timeRecord.description;

      // integration
      tempLine.presetTeamId = timeRecord.presetTeamId;
      tempLine.presetProjectId = timeRecord.presetProjectId;
      tempLine.presetDescription = timeRecord.presetDescription;

      tempLine.referenceType = timeRecord.referenceType;
      tempLine.reference = timeRecord.reference;
      tempLine.reference1 = timeRecord.reference1;
      tempLine.reference2 = timeRecord.reference2;
      tempLine.reference3 = timeRecord.reference3;
      // integration

      for (let p of this.dataCore.projects) {
        if (timeRecord.projectId == p.id) {
          tempLine.project = p;
          break;
        }
      }

      // Test Auth
      tempLine.auth = userProjectsIds.indexOf(timeRecord.projectId) != -1;
      // Test Auth

      if (tempLine.project.taskControl) {
        tempLine.selectionData.projectTasks = this.dataCore.projectTasks.filter(pt => { return pt.projectId == tempLine.project.id });
        for (let t of tempLine.selectionData.projectTasks) {
          if (timeRecord.taskId == t.id) {
            tempLine.task = t;
            break;
          }
        }
      }
      else
        tempLine.selectionData.projectTasks = [];

      tempLine.selectionData.projectTags = this.dataCore.tags.filter(t => { return t.teamId == tempLine.project.teamId });
      for (let t of tempLine.selectionData.projectTags) {
        if (timeRecord.tagsIds.indexOf(t.id) != -1) {
          tempLine.tags.push(t);
        }
      }

      tempLine.description = timeRecord.description;

      tempLine.mergeKey = mergeKey;

      // populate line vectors
      for (let d of this.days) {
        // Days
        let tempDay = new TimerListLineDay();
        tempDay.timeRecordId = `${this.uName}-${this.db.createId()}`;
        tempDay.createdBy = this.authService.localUser.uName;
        tempLine.days.push(tempDay);
        // Days

      }
      // populate line vectors


      for (let tr of timeRecords) {
        // Days
        for (let dayIndex = 0; dayIndex < this.days.length; dayIndex++) {
          if (this.days[dayIndex].dateDay == tr.dateDay) {
            // Vals
            tempLine.days[dayIndex].timeRecordId = tr.id;
            tempLine.days[dayIndex].h = tr.durationHours;

            tempLine.days[dayIndex].m = tr.durationMinutes;
            if (tempLine.days[dayIndex].m >= 60) {
              tempLine.days[dayIndex].h++;
              tempLine.days[dayIndex].m = tempLine.days[dayIndex].m - 60;
            }

            tempLine.days[dayIndex].val = tr.duration;
            tempLine.days[dayIndex].time = this.__getTime(tempLine.days[dayIndex].h, tempLine.days[dayIndex].m);

            tempLine.value += tempLine.days[dayIndex].val;
            // Vals

            tempLine.days[dayIndex].createdBy = tr.createdBy;
            tempLine.days[dayIndex].updatedBy = tr.updatedBy;
            tempLine.days[dayIndex].managed = (tr.createdBy != this.uName && tr.updatedBy != this.uName) || (tr.updatedBy != null && tr.updatedBy != this.uName);

          }
        }
        // Days
      }

      tempLine.oldData.days = JSON.parse(JSON.stringify(tempLine.days));

      tempLine.firstValuedDayIndex = this._getFirstValuedDayIndexForLine(tempLine);

      // managed determination by days
      tempLine.managed = false;
      for (let d of tempLine.days) {
        if (d.managed) {
          tempLine.managed = true;
          break;
        }
      }
      // managed determination by days

      if (tempLine.value > 0)
        tempLines.push(tempLine)
    });
    return this._sortLines(tempLines);
  }
  private _isWeekendDay(date: Date): boolean {
    return this.weekendDays.indexOf(date.getDay()) != -1;
  }
  load(uName: string) {
    this.loader = true;

    this.uName = uName;
    this.editionMode = this.uName == this.authService.localUser.uName ? 'user' : 'manager';

    this.hasError = false;
    this.hasChanges = false;
    this.errors = [];

    this.loadDataForSelection()
      .then(() => {
        this.updateDataCore()
          .then(() => {

            return this.authService.get_parameter(USER_CONFIG_PARAM_ID)
              .then(userParameter_userConfig => {

                if (userParameter_userConfig) {
                  this.userConfig = new TM_TimerUserConfig(userParameter_userConfig as TM_TimerUserConfig);
                }

                console.log('U S E R _ C O N F I G', this.userConfig);

                this.days = [];
                let tempTime = this.datePeriodFrom.getTime();
                let toTime = this.datePeriodTo.getTime();
                do {
                  let tempDate = new Date(tempTime)
                  this.days
                    .push({
                      date: tempDate,
                      dateWeek: this.utilCtrl.week.getDate_Week(tempDate),
                      dateMonth: this.utilCtrl.date.getDate_Month(tempDate),
                      dateYear: this.utilCtrl.date.getDate_Year(tempDate),
                      dateDay: this.utilCtrl.date.getDate_Day(tempDate),
                      dateStr: this.utilCtrl.date.getDateStrBR(tempDate),
                      weekDay: weekDayNames[tempDate.getDay()],
                      isWeekendDay: this._isWeekendDay(tempDate),
                      time: '00:00:00',
                      h: 0,
                      m: 0,
                      val: 0,
                    });
                  tempTime = tempDate.getTime() + 86400000;
                } while (tempTime <= toTime);

                this.days = this.days.filter(d => { return this.userConfig.showWeekend || !d.isWeekendDay; })

                this.weeklyGoal_days = [];
                for (let d of this.days) {
                  const dayGoal = this.userConfig.weeklyGoal.days[d.date.getDay()];
                  this.weeklyGoal_days.push({
                    status: dayGoal.val == 0 ? 'not-set' : null,
                    dif: 0,
                    difH: 0,
                    difM: 0,
                    difPerc: 0,
                  });
                }

                this.timeRecordsIds = [];
                for (let tr of this.timeRecords)
                  this.timeRecordsIds.push(tr.id);

                this.lines = [];
                this.lines = [...this._genLines(this.timeRecords)];

                for (let lineIndex = 0; lineIndex < this.lines.length; lineIndex++)
                  for (let dayIndex = 0; dayIndex < this.days.length; dayIndex++)
                    if (this.lines[lineIndex].days[dayIndex].val > 0)
                      this._updateDay(lineIndex, dayIndex)


                this.loader = false;

              })
          })
      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e)
      })
  }
  // ------------------------------------------------- L O A D




  // ------------------------------------------------- G E N E R A L
  public selectLine(lineIndex: number) {
    this.selectedLineIndex = lineIndex;
  }
  public activateIntegration(data: TM_IntegrationData) {
    this.integrationActivate = true;
    this.integrationData = JSON.parse(JSON.stringify(data));
    this.updatePresetProjectData();
  }
  public deactivateIntegration() {
    this.integrationActivate = false;
    this.integrationData = JSON.parse(JSON.stringify(new TM_IntegrationData()));
  }
  public updatePresetProjectData() {
    if (!this.integrationData.presetProjectId) {
      this.integrationPresetProject = new TM_Project();
      return;
    }

    let tempProject = new TM_Project();
    let p = this.selectionData.projects.filter(p => { return p.id == this.integrationData.presetProjectId })[0];
    if (p)
      tempProject = p;
    this.integrationPresetProject = tempProject;
  }
  public _compareFn(o1, o2): boolean {
    let result = false;
    if (o1 === null && o2 === null)
      result = true;
    else if (o1 != null && o2 != null) {
      if (o1 === o2)
        result = true;
      else if (o1.id != null && o2.id != null && o1.id === o2.id)
        result = true;
    }
    return result;
  }
  updateList() {
    this.lines = [...this.lines];
  }
  isToday(day): boolean {
    return day[`dateStr`] == this.todayDateStr;
  }
  addLineFromIntegrationData() {
    this.addLine(this.integrationData);
  }

  addLine(integrationData?: TM_IntegrationData) {
    let newLine = new TimerListLine();

    // integration
    if (integrationData) {
      newLine.presetTeamId = integrationData.presetTeamId;
      newLine.presetProjectId = integrationData.presetProjectId;
      newLine.presetDescription = integrationData.presetDescription;

      if (integrationData.presetProjectId) {
        let tempProject = this.selectionData.projects.find((p) => p.id == integrationData.presetProjectId);
        if (tempProject)
          newLine.project = tempProject;
      }

      newLine.referenceType = integrationData.referenceType;
      newLine.reference = integrationData.reference;
      newLine.reference1 = integrationData.reference1;
      newLine.reference2 = integrationData.reference2;
      newLine.reference3 = integrationData.reference3;
    }
    // integration


    newLine.status = 'new';
    newLine.managed = this.editionMode == 'manager';
    newLine.hasError = true;
    newLine.auth = true;
    for (let d of this.days) {
      let tempDay = new TimerListLineDay();
      tempDay.timeRecordId = `${this.authService.localUser.uName}-${this.db.createId()}`;
      tempDay.createdBy = this.authService.localUser.uName;
      tempDay.managed = this.editionMode == 'manager';
      newLine.days.push(tempDay)
    }
    newLine.oldData.days = JSON.parse(JSON.stringify(newLine.days));
    this.lines.push(newLine);
    this.updateList();

    setTimeout(() => {
      document.getElementById('input_project_' + (this.lines.length - 1).toString()).focus();
    }, 200);
    this._lineChange(this.lines.length - 1);
    if (newLine.project.id)
      this._selectProject(this.lines.length - 1);
  }
  private _genDaysStr(days: TimerListLineDay[]) {
    let str = '';
    for (let index = 0; index < days.length; index++) {
      const d = days[index];
      str = str + `${d.h}:${d.m}`
      if (index < days.length - 1)
        str = str + '|';
    }
    return str;
  }
  private _genDayStr(d: TimerListLineDay) {
    let str = '';
    str = str + `${d.h}:${d.m}`
    return str;
  }
  _lineChange(lineIndex: number) {
    setTimeout(() => {

      if (this.lines[lineIndex].status != 'new') {

        // Sync Days
        for (let dayIndex = 0; dayIndex < this.lines[lineIndex].days.length; dayIndex++) {
          let dayStr = this._genDayStr(this.lines[lineIndex].days[dayIndex]);
          let olddayStr = this._genDayStr(this.lines[lineIndex].oldData.days[dayIndex]);

          if (dayStr != olddayStr)
            this.lines[lineIndex].days[dayIndex].updatedBy = this.authService.localUser.uName;
          else
            this.lines[lineIndex].days[dayIndex].updatedBy = this.lines[lineIndex].oldData.days[dayIndex].updatedBy;

          this.lines[lineIndex].days[dayIndex].managed =
            (this.lines[lineIndex].days[dayIndex].createdBy != this.uName && this.lines[lineIndex].days[dayIndex].updatedBy != this.uName) ||
            (this.lines[lineIndex].days[dayIndex].updatedBy != null && this.lines[lineIndex].days[dayIndex].updatedBy != this.uName);
        }
        // Sync Days

        let lineHasChanges = false;
        let changeType: 'data' | 'days' = null;

        // Check Changes on data
        let tempTagsIds = [];
        this.lines[lineIndex].tags.forEach(t => { tempTagsIds.push(t.id) });
        if (
          this.lines[lineIndex].project.id != this.lines[lineIndex].oldData.projectId ||
          this.lines[lineIndex].task.id != this.lines[lineIndex].oldData.taskId ||
          this.lines[lineIndex].description != this.lines[lineIndex].oldData.description ||
          tempTagsIds.toString() != this.lines[lineIndex].oldData.tagsIds.toString()
        ) {
          lineHasChanges = true;
          changeType = 'data';
        }
        else
          lineHasChanges = false;
        // Check Changes on data

        if (!lineHasChanges) {
          // Check Changes on days
          let dayStr = this._genDaysStr(this.lines[lineIndex].days);
          let olddayStr = this._genDaysStr(this.lines[lineIndex].oldData.days);
          if (
            dayStr != olddayStr
          ) {
            lineHasChanges = true;
            changeType = 'days';
          }
          else
            lineHasChanges = false;
          // Check Changes on data
        }

        this.lines[lineIndex].firstValuedDayIndex = this._getFirstValuedDayIndexForLine(this.lines[lineIndex]);
        this.lines[lineIndex].status = lineHasChanges ? 'changed' : 'old';

        if (lineHasChanges && changeType == 'data') {
          this.lines[lineIndex].managed = this.editionMode == 'manager' ? true : false;
          for (let dayIndex = 0; dayIndex < this.lines[lineIndex].days.length; dayIndex++) {
            this.lines[lineIndex].days[dayIndex].updatedBy = this.authService.localUser.uName;
            this.lines[lineIndex].days[dayIndex].managed = this.editionMode == 'manager' ? true : false;
          }
        }

      }

      // managed determination
      this.lines[lineIndex].managed = false;
      for (let d of this.lines[lineIndex].days)
        if (d.managed) {
          this.lines[lineIndex].managed = true;
          break;
        }
      // managed determination

      this._checkErrors();
      this._updateHasError();
      this._updateHasChanges();
      this._onChangeEmitter();

      this.updateList();
    }, 250);
  }
  private _checkErrors() {
    let tempErrors = [];
    let tempDayIndexesWithErros = [];

    for (let i = 0; i < this.lines.length; i++) {
      let tempLineHasError = false;
      this.lineTests
        .forEach(lt => {
          if (!lt.test(this, this.lines[i])) {
            tempErrors.push(`linha: ${i + 1} - ${lt.failText}`);
            tempLineHasError = true;
          }
        });
      this.lines[i].hasError = tempLineHasError;
    }

    for (let i = 0; i < this.days.length; i++)
      this.dayTests.forEach((dt) => {
        if (!dt.test(this, i)) {
          tempDayIndexesWithErros.push(i);
          tempErrors.push(`Dia: ${this.days[i].dateStr} (${this.days[i].weekDay}) - ${dt.failText}`);
        }
      });

    this.dayIndexesWithErros = tempDayIndexesWithErros;
    this.errors = tempErrors;
  }
  private _onChangeEmitter() {
    this.onChange.emit(this.hasChanges);
  }
  private _updateHasError() {
    let hasError = false;
    if (this.errors.length > 0)
      hasError = true;
    if (this.hasError != hasError)
      this.hasErrorChange.emit(hasError);
    this.hasError = hasError;
  }
  private _updateHasChanges() {
    let hasChanges = false;
    if (this.timeRecordsIdsToDelete.length > 0)
      hasChanges = true;
    else
      for (let r of this.lines)
        if (r.status == 'changed' || r.status == 'new') {
          hasChanges = true;
          break;
        }

    if (this.hasChanges != hasChanges)
      this.hasChangesChange.emit(hasChanges);
    this.hasChanges = hasChanges;
  }
  _genLineMergeKey(line: TimerListLine): string {
    let tagsIds = '';
    for (let index = 0; index < line.tags.length; index++) {
      tagsIds = tagsIds + line.tags[index].id;
      if (index < line.tags.length - 1)
        tagsIds = tagsIds + '-';
    }
    return `${line.project.id}-${line.task.id}-${tagsIds}-${line.description}`;
  }
  _selectProject(index: number) {
    //Tasks
    if (this.lines[index].project.taskControl)
      this.lines[index].selectionData.projectTasks = this.selectionData.projectTasks.filter(pt => { return pt.projectId == this.lines[index].project.id });
    else
      this.lines[index].selectionData.projectTasks = [];
    this.lines[index].task = new TM_ProjectTask();

    if (this.lines[index].project.taskControl && this.lines[index].selectionData.projectTasks.length == 0)
      this.uiFeedBackCtrl.presentAlert('Dado mestre incompleto!', `O projeto ${this.lines[index].project.name} não possui nehuma tarefa associada ainda, favor consultar o responsavel pelo projeto!`, 'warning');
    //Tasks

    //Tags
    this.lines[index].selectionData.projectTags = this.selectionData.tags.filter((t) => { return t.teamId == this.lines[index].project.teamId });
    this.lines[index].tags = [];
    this.showTagPopdown = -1;
    //Tags

    this.lines[index].mergeKey = this._genLineMergeKey(this.lines[index]);

    if (this.lines[index].selectionData.projectTasks.length == 1) {
      this.lines[index].task = this.lines[index].selectionData.projectTasks[0];
      this._selectTask(index);
    } else
      this._lineChange(index);
  }
  _selectTask(index: number) {
    this.lines[index].tags = [];
    if (this.lines[index].task.tagId != null && this.lines[index].task.tagId != "") {
      let tempTaskTag = new TM_Tag();
      for (let t of this.lines[index].selectionData.projectTags)
        if (t.id == this.lines[index].task.tagId) {
          tempTaskTag = t;
          break;
        }
      this.lines[index].tags.push(tempTaskTag)
    }
    this.lines[index].mergeKey = this._genLineMergeKey(this.lines[index]);

    this._lineChange(index);
  }
  // Tag
  _remTag(tag: TM_Tag, tmTag_index: number, line_index: number) {
    if (this.lines[line_index].task.tagId == tag.id) {
      this.uiFeedBackCtrl.presentAlert('Não é possivel remover a Tag config. no projeto', 'Essa tag foi configurada diretamente no projeto de apontamento de horas, por gentileza, para remove-la entre em contato com o administrador do projeto.', `warning`);
      return;
    }

    this.lines[line_index].tags.splice(tmTag_index, 1);
    this.lines[line_index].mergeKey = this._genLineMergeKey(this.lines[line_index]);

    this._lineChange(line_index);
  }
  _openTagPopdown(index: number) {
    this.showTagPopdown = index;
  }
  _closeTagPopdown() {
    this.showTagPopdown = -1;
  }
  _getTagsForShow(index: number): TM_Tag[] {
    return this.lines[index].selectionData.projectTags.filter(t => {
      let show = true;
      for (let st of this.lines[index].tags) {
        if (st.id == t.id) {
          show = false;
          break
        }
      }
      return show;
    });
  }
  _selectTag(tmTag: TM_Tag, index: number) {
    this.lines[index].tags.push(tmTag);
    this.lines[index].mergeKey = this._genLineMergeKey(this.lines[index]);
    this.showTagPopdown = -1;

    this._lineChange(index);
  }
  _updateDescription(index: number) {
    this.lines[index].mergeKey = this._genLineMergeKey(this.lines[index]);

    this._lineChange(index);
  }
  // Tag

  // Day
  _updateGlobalDay(dayIndex: number) {
    let h = 0;
    let m = 0;
    let val = 0;
    for (let l of this.lines) {
      h += l.days[dayIndex].h;
      m += l.days[dayIndex].m;
      if (m >= 60) {
        h++;
        m = m - 60;
      }
      val += l.days[dayIndex].val;
    }
    this.days[dayIndex].h = h;
    this.days[dayIndex].m = m;
    this.days[dayIndex].val = Number(val.toFixed(6)); // val fixed to 6 decimal digits to avoid problems in parseInt bellow
    this.days[dayIndex].time = this.__getTime(this.days[dayIndex].h, this.days[dayIndex].m);

    this.totalHours = 0;
    for (let d of this.days)
      this.totalHours += d.val;

    if (this.userConfig.weeklyGoal.active) {

      // days
      const userConfig_weeklyGoal_days_index = this.days[dayIndex].date.getDay();
      this.weeklyGoal_days[dayIndex].dif = this.days[dayIndex].val - this.userConfig.weeklyGoal.days[userConfig_weeklyGoal_days_index].val;
      this.weeklyGoal_days[dayIndex].difPerc = this.days[dayIndex].val / this.userConfig.weeklyGoal.days[userConfig_weeklyGoal_days_index].val;
      this.weeklyGoal_days[dayIndex].difH = parseInt(this.weeklyGoal_days[dayIndex].dif.toString());
      this.weeklyGoal_days[dayIndex].difM = parseInt(Number(Math.abs(this.weeklyGoal_days[dayIndex].dif - parseInt(this.weeklyGoal_days[dayIndex].dif.toString())) * 60).toFixed(0));
      if (this.weeklyGoal_days[dayIndex].difM >= 60) {
        this.weeklyGoal_days[dayIndex].difH++;
        this.weeklyGoal_days[dayIndex].difM = this.weeklyGoal_days[dayIndex].difM - 60;
      }

      if (this.weeklyGoal_days[dayIndex].status != 'not-set')
        if (this.weeklyGoal_days[dayIndex].dif > 0)
          this.weeklyGoal_days[dayIndex].status = 'up';
        else if (this.weeklyGoal_days[dayIndex].dif < 0)
          this.weeklyGoal_days[dayIndex].status = 'down';
        else if (this.weeklyGoal_days[dayIndex].dif == 0)
          this.weeklyGoal_days[dayIndex].status = 'ok';

      if (['ok', 'up'].includes(this.weeklyGoal_days[dayIndex].status) && !this.confettiFired.includes(dayIndex)) {
        this.confettiFired.push(dayIndex);
        const elem = document.getElementById('weekly-goal-day_' + dayIndex);
        if (!this.loader)
          this.uiFeedBackCtrl.confetti.realisticLook(elem);
      }

      // week
      this.weeklyGoal_status.dif = this.totalHours - this.userConfig.weeklyGoal.value;
      this.weeklyGoal_status.difPerc = this.totalHours / this.userConfig.weeklyGoal.value;
      this.weeklyGoal_status.difH = parseInt(this.weeklyGoal_status.dif.toString());
      this.weeklyGoal_status.difM = parseInt(Number(Math.abs(this.weeklyGoal_status.dif - parseInt(this.weeklyGoal_status.dif.toString())) * 60).toFixed(0));
      if (this.weeklyGoal_status.difM >= 60) {
        this.weeklyGoal_status.difH++;
        this.weeklyGoal_status.difM = this.weeklyGoal_status.difM - 60;
      }

      if (this.weeklyGoal_status.dif > 0)
        this.weeklyGoal_status.status = 'up';
      else if (this.weeklyGoal_status.dif < 0)
        this.weeklyGoal_status.status = 'down';
      else if (this.weeklyGoal_status.dif == 0)
        this.weeklyGoal_status.status = 'ok';

      if (['ok', 'up'].includes(this.weeklyGoal_status.status) && !this.confettiFired.includes(-1)) {
        this.confettiFired.push(-1);
        if (!this.loader)
          this.uiFeedBackCtrl.confetti.fireworks(5);
      }
    }

  }
  _dayInputModelChange(lineIndex: number, dayIndex: number) {
    setTimeout(() => {
      this._updateDay(lineIndex, dayIndex)
    }, 200);
  }
  _dayInputBlur(lineIndex: number, dayIndex: number) {
    setTimeout(() => {
      if (!this.lines[lineIndex].days[dayIndex].time)
        this.lines[lineIndex].days[dayIndex].time = '00:00:00';
      this._updateDay(lineIndex, dayIndex)
    }, 200);
  }
  private __getHourFromHHMMSS(HHMMSS: string): number {
    return Number(HHMMSS.split(':')[0]);
  }
  private __getMinFromHHMMSS(HHMMSS: string): number {
    return Number(HHMMSS.split(':')[1]);
  }
  private __getTime(h: number, m: number): string {
    return `${this.utilCtrl.formatters.numberPadding(h, 2)}:${this.utilCtrl.formatters.numberPadding(m, 2)}:00`;
  }
  _updateDay(lineIndex: number, dayIndex: number) {
    if (!this.lines[lineIndex].days[dayIndex].time)
      return;

    this.lines[lineIndex].days[dayIndex].h = this.__getHourFromHHMMSS(this.lines[lineIndex].days[dayIndex].time);
    this.lines[lineIndex].days[dayIndex].m = this.__getMinFromHHMMSS(this.lines[lineIndex].days[dayIndex].time);

    this.lines[lineIndex].days[dayIndex].val = this.lines[lineIndex].days[dayIndex].h + this.lines[lineIndex].days[dayIndex].m / 60;

    this.lines[lineIndex].value = 0;
    for (let i = 0; i < this.days.length; i++)
      this.lines[lineIndex].value += this.lines[lineIndex].days[i].val;

    this._lineChange(lineIndex);
    this._updateGlobalDay(dayIndex);
  }
  _dayHasError(index: number): boolean {
    return this.dayIndexesWithErros.indexOf(index) != -1;
  }

  // Options
  _openOptionsPopdown(index: number) {
    this.showOptionsPopdown = index;
  }
  _closeOptionsPopdown() {
    this.showOptionsPopdown = -1;
  }
  _remLine(index: number) {
    this.lines[index].days.forEach(d => {
      let timeRecordsIds_index = this.timeRecordsIds.indexOf(d.timeRecordId);
      if (timeRecordsIds_index != -1) {
        this.timeRecordsIdsToDelete.push(d.timeRecordId)
        this.timeRecordsIds.splice(timeRecordsIds_index, 1);
      }
    })
    this.lines.splice(index, 1);

    for (let i = 0; i < this.days.length; i++)
      this._updateGlobalDay(i)

    this._checkErrors();
    this._updateHasError();
    this._updateHasChanges();
    this._onChangeEmitter();

    this.updateList();

    if (this.selectedLineIndex == index)
      this.selectedLineIndex = -1;

    this.showOptionsPopdown = -1;
  }
  _dupLine(index: number) {

    let tempLine = JSON.parse(JSON.stringify(this.lines[index])) as TimerListLine;
    tempLine.status = 'new';
    tempLine.managed = this.editionMode == 'manager';

    // reset integration
    tempLine.referenceType = null;
    tempLine.reference = '';
    tempLine.reference1 = '';
    tempLine.reference2 = '';
    tempLine.reference3 = '';
    // reset integration

    for (let index = 0; index < tempLine.days.length; index++) {
      tempLine.days[index].timeRecordId = `${this.authService.localUser.uName}-${this.db.createId()}`;
      tempLine.days[index].createdBy = this.authService.localUser.uName;
      tempLine.days[index].updatedBy = null;
      tempLine.days[index].managed = this.editionMode == 'manager';
    }

    this.lines.push(tempLine);

    setTimeout(() => {
      document.getElementById('input_project_' + (this.lines.length - 1).toString()).focus();
    }, 200);

    for (let i = 0; i < this.days.length; i++)
      this._updateGlobalDay(i)

    this._checkErrors();
    this._updateHasError();
    this._updateHasChanges();
    this._onChangeEmitter();

    this.updateList();

    this.showOptionsPopdown = -1;
  }
  _openIntegration(index: number) {
    let line = JSON.parse(JSON.stringify(this.lines[index])) as TimerListLine;
    switch (line.referenceType) {
      case 'PM_Card':
        this.__openPMCard(line.reference, line.reference1);
        break;
    }
  }
  __openPMCard(boardId: string, cardId: string) {
    let url = window.location.origin + '/#/main/project-management/kanban/open-board?id=' + boardId + '&cardIdToOpen=' + cardId;
    window.open(url, '_blank').focus();
  }
  // ------------------------------------------------- G E N E R A L


  // ------------------------------------------------- S A V E
  private _mergeLines(lines: TimerListLine[]): TimerListLine[] {
    let tempLines: TimerListLine[] = [];
    let tempLinesIndexByMergeKey = new Map<string, TimerListLine[]>();

    for (let l of lines)
      if (tempLinesIndexByMergeKey.get(l.mergeKey))
        tempLinesIndexByMergeKey.get(l.mergeKey).push(l);
      else
        tempLinesIndexByMergeKey.set(l.mergeKey, [l]);

    tempLinesIndexByMergeKey.forEach((lines, mergeKey) => {
      let tempLine = lines[0];

      for (let lineIndex = 1; lineIndex < lines.length; lineIndex++) {
        const line = lines[lineIndex];

        // Merge Lines Days
        for (let dayIndex = 0; dayIndex < this.days.length; dayIndex++) {
          if (line.days[dayIndex].val > 0) {
            // Merge Value
            tempLine.days[dayIndex].h += line.days[dayIndex].h;
            tempLine.days[dayIndex].m += line.days[dayIndex].m;
            if (tempLine.days[dayIndex].m >= 60) {
              tempLine.days[dayIndex].h++;
              tempLine.days[dayIndex].m = tempLine.days[dayIndex].m - 60;
            }
            tempLine.days[dayIndex].val += line.days[dayIndex].val;

            tempLine.days[dayIndex].time = this.__getTime(tempLine.days[dayIndex].h, tempLine.days[dayIndex].m);
            // Merge Value


            // Mark Tr for Deletion
            if (this.timeRecordsIds.indexOf(line.days[dayIndex].timeRecordId) != -1)
              this.timeRecordsIdsToDelete.push(line.days[dayIndex].timeRecordId)
            // Mark Tr for Deletion

            // Merge other Data
            tempLine.days[dayIndex].updatedBy = this.authService.localUser.uName;
            tempLine.days[dayIndex].managed =
              (tempLine.days[dayIndex].createdBy != this.uName && tempLine.days[dayIndex].updatedBy != this.uName) ||
              (tempLine.days[dayIndex].updatedBy != null && tempLine.days[dayIndex].updatedBy != this.uName);
            // Merge other Data
          }
        }
        // Merge Lines Days

        // Mertge Lines Props
        if (!line.auth)
          tempLine.auth = false;

        if (line.managed)
          tempLine.managed = true;
        // Mertge Lines Props

      }

      tempLines.push(tempLine);
    });

    return tempLines;
  }
  private _getTimeRecord(id: string): TM_TimeRecord {
    let tr = new TM_TimeRecord();
    for (let ltr of this.timeRecords) {
      if (ltr.id == id) {
        tr = ltr;
        break
      }
    }
    return JSON.parse(JSON.stringify(tr));
  }
  /**
   *
   * @param timeRecord1
   * @param timeRecord2
   * @returns `true` for equal || `false` for diferent
   */
  private _compareTimeRecords(timeRecord1: TM_TimeRecord, timeRecord2: TM_TimeRecord): boolean {
    if (
      timeRecord1.teamId != timeRecord2.teamId ||
      timeRecord1.projectId != timeRecord2.projectId ||
      timeRecord1.taskId != timeRecord2.taskId ||
      timeRecord1.tagsIds.toString() != timeRecord2.tagsIds.toString() ||
      timeRecord1.description != timeRecord2.description ||
      timeRecord1.duration != timeRecord2.duration ||
      timeRecord1.durationHours != timeRecord2.durationHours ||
      timeRecord1.durationMinutes != timeRecord2.durationMinutes
    )
      return false
    else
      return true
  }
  private _set_LocalTimeRecord(
    id: string,
    data: TM_TimeRecord
  ) {
    let updated = false;
    for (let index = 0; index < this.timeRecords.length; index++) {
      if (this.timeRecords[index].id == id) {
        this.timeRecords[index].teamId = data.teamId;
        this.timeRecords[index].projectId = data.projectId;
        this.timeRecords[index].parentKey = data.parentKey;
        this.timeRecords[index].taskId = data.taskId;
        this.timeRecords[index].tagsIds = data.tagsIds;
        this.timeRecords[index].description = data.description;
        this.timeRecords[index].duration = data.duration;
        this.timeRecords[index].durationHours = data.durationHours;
        this.timeRecords[index].durationMinutes = data.durationMinutes;
        updated = true;
        break;
      }
    }
    if (!updated) {
      this.timeRecordsIds.push(id);
      this.timeRecords.push(data);
    }
    this.timeRecordsChange.emit(this.timeRecords);
  }
  private _delete_LocalTimeRecord(id: string) {
    for (let i = 0; i < this.timeRecords.length; i++) {
      if (this.timeRecords[i].id == id) {
        this.timeRecords.splice(i, 1);
        break;
      }
    }
    for (let i = 0; i < this.timeRecordsIds.length; i++) {
      if (this.timeRecordsIds[i] == id) {
        this.timeRecordsIds.splice(i, 1);
        break;
      }
    }
    this.timeRecordsChange.emit(this.timeRecords);
  }

  trySave() {
    this.uiFeedBackCtrl.presentLoader('Validando sessão...')
      .then(() => {
        checkSession(this.db)
          .then(valid => {
            if (valid) {
              this.uiFeedBackCtrl.dismissLoader();
              this.save();
            } else {
              this.uiFeedBackCtrl.dismissLoader();
              this.uiFeedBackCtrl.presentAlert('Sessão expirada!', 'Erro ao tentar salvar, sua sessão foi expirada!', 'error');
            }
          })
          .catch((e) => {
            this.uiFeedBackCtrl.dismissLoader();
            this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro ao salvar horas no Banco de dados!', e)
          })
      });
  }

  save() {
    this.uiFeedBackCtrl.presentLoader('Salvando...')
      .then(() => {

        let tempTimeRecordsToCreate: TM_TimeRecord[] = [];
        let tempTimeRecordsToUpdate: TM_TimeRecord[] = [];

        this.lines = this._mergeLines(this.lines);

        for (let l of this.lines) {
          for (let dayIndex = 0; dayIndex < l.days.length; dayIndex++) {
            let tempLineDay = l.days[dayIndex];
            if (tempLineDay.val > 0) {
              let tempTR = new TM_TimeRecord();
              tempTR.id = tempLineDay.timeRecordId;
              tempTR.teamId = l.project.teamId;
              tempTR.projectId = l.project.id;
              tempTR.parentKey = l.project.parentKey;
              tempTR.taskId = l.task.id;
              l.tags.forEach(t => {
                tempTR.tagsIds.push(t.id)
              });
              tempTR.description = l.description;

              tempTR.presetTeamId = l.presetTeamId;
              tempTR.presetProjectId = l.presetProjectId;
              tempTR.presetDescription = l.presetDescription;

              tempTR.referenceType = l.referenceType;
              tempTR.reference = l.reference;
              tempTR.reference1 = l.reference1;
              tempTR.reference2 = l.reference2;
              tempTR.reference3 = l.reference3;

              tempTR.uName = this.uName;
              tempTR.createdBy = this.authService.localUser.uName;
              tempTR.date = this.utilCtrl.timestamp.fromDate(this.days[dayIndex].date);
              tempTR.dateWeek = this.days[dayIndex].dateWeek;
              tempTR.dateMonth = this.days[dayIndex].dateMonth;
              tempTR.dateYear = this.days[dayIndex].dateYear;
              tempTR.dateDay = this.days[dayIndex].dateDay;
              tempTR.durationHours = tempLineDay.h;
              tempTR.durationMinutes = tempLineDay.m;
              tempTR.duration = tempLineDay.val;

              if (this.timeRecordsIds.indexOf(tempTR.id) != -1) {
                let localTR = this._getTimeRecord(tempTR.id);
                tempTR.createdBy = localTR.createdBy;
                tempTR.createdOn = localTR.createdOn;
                if (this._compareTimeRecords(tempTR, localTR) == false) {
                  tempTR.updatedBy = this.authService.localUser.uName;
                  tempTR.updatedOn = this.utilCtrl.timestamp.now();
                  tempTimeRecordsToUpdate.push(tempTR)
                }
              } else
                tempTimeRecordsToCreate.push(tempTR)

            } else if (tempLineDay.val == 0 && this.timeRecordsIds.indexOf(tempLineDay.timeRecordId) != -1)
              this.timeRecordsIdsToDelete.push(tempLineDay.timeRecordId);
          }
        }

        this.db.runTransaction(t => {

          // Creates
          for (let tr of tempTimeRecordsToCreate) {

            if (tr.durationHours == null)
              tr.durationHours = 0
            if (tr.durationMinutes == null)
              tr.durationMinutes = 0

            t.set(
              this.db.afs.firestore
                .collection(this.db.COLLECTIONS.timeManagement.timeRecords).doc(tr.id),
              Object.assign({}, tr)
            );
          }
          // Creates

          // Updates
          for (let tr of tempTimeRecordsToUpdate) {

            if (tr.durationHours == null)
              tr.durationHours = 0
            if (tr.durationMinutes == null)
              tr.durationMinutes = 0

            t.update(
              this.db.afs.firestore
                .collection(this.db.COLLECTIONS.timeManagement.timeRecords).doc(tr.id),
              Object.assign({}, tr)
            );
          }
          // Updates

          // Deletes
          this.timeRecordsIdsToDelete.forEach(timeRecordIdToDelete => {
            t.delete(
              this.db.afs.firestore
                .collection(this.db.COLLECTIONS.timeManagement.timeRecords).doc(timeRecordIdToDelete)
            );
          })
          // Deletes

          return Promise.resolve();
        })
          .then(() => {

            for (let tr of tempTimeRecordsToCreate)
              this._set_LocalTimeRecord(tr.id, tr);

            for (let tr of tempTimeRecordsToUpdate)
              this._set_LocalTimeRecord(tr.id, tr);

            for (let tr of this.timeRecordsIdsToDelete)
              this._delete_LocalTimeRecord(tr);

            this.timeRecordsIdsToDelete = [];

            for (let l of this.lines) {
              l.oldData.projectId = l.project.id;
              l.oldData.taskId = l.task.id;
              l.oldData.tagsIds = [];
              l.tags.forEach((t) => { l.oldData.tagsIds.push(t.id) })
              l.oldData.description = l.description;
              l.oldData.days = JSON.parse(JSON.stringify(l.days));

              l.status = 'old';
            }

            this._updateHasChanges();

            this.updateList();

            this.confettiFired = [];

            if (this.userConfig.showSaveDoneAlert)
              this.uiFeedBackCtrl.presentAlert(`Horas salvas com sucesso!`, ``, `success`);

            this.uiFeedBackCtrl.dismissLoader();
          })
          .catch((e) => {
            this.uiFeedBackCtrl.dismissLoader();
            this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro ao salvar horas no Banco de dados!', e)
          })

      })
  }
  // ------------------------------------------------- S A V E

}
