import firebase from "firebase/compat/app";

export class WS_Bookmark {

  id: string;
  workspaceId: string;
  parentKey: string;

  title: string;
  url: string;
  iconUrl: string;
  description: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<WS_Bookmark>) {

    this.id = "";
    this.workspaceId = "";
    this.parentKey = "";

    this.title = "";
    this.url = "";
    this.iconUrl = "";
    this.description = "";

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = "";
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
