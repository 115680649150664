import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChange } from '@angular/core';

import { getPMToDoDueDateStatusData, Log, PM_BoardActivity, PM_ToDo, PM_TODO_DUEDATE_STATUS_DATA_TYPE, PM_ToDoList, pmToDoDueDateStatusDetermination, User } from 'app/@firebase';
import { genNotif_todoAssing } from 'app/routes/main/project-management/PM_NOTIFICATIONS';
import { PM_ToDo_dataChangGen } from 'app/routes/main/project-management/toDoList/PM_ToDo_dataChangGen';
import { PM_ToDoList_dataChangGen } from 'app/routes/main/project-management/toDoList/PM_ToDoList_dataChangGen';
import { _decodeMultipleToDoListsFromString, _decodeToDosFromString, _encodeMultipleToDoListsToString, _encodeToDosToString } from 'app/routes/main/project-management/toDoList/toDoList-utils';
import { TIMES } from 'app/routes/main/sys/SYS_CONSTS';

import { PM_Card_dataChangGen } from '../../../PM_Card_dataChangGen';
import { PmCardModal } from '../../../pm-card-modal';

@Component({
  selector: 'to-do-lists',
  templateUrl: './to-do-lists.component.html',
  styleUrls: ['./to-do-lists.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PMCardModalToDoListsComponent implements OnInit, AfterViewInit, OnChanges {

  className = 'PMCardModalToDoListsComponent';

  private toDoListsSub = null;
  private toDosSub = null;

  _loader = false;

  cardToDoLists: PM_ToDoList[] = [];
  cardToDoListsMap: Record<string, PM_ToDoList> = {};
  oldCardToDoListsMap: Record<string, PM_ToDoList> = {};

  toDoListsIds: string[] = [];
  dragingToDo = false;
  cardToDos: PM_ToDo[] = [];
  cardToDosMap: Record<string, PM_ToDo> = {};
  oldCardToDosMap: Record<string, PM_ToDo> = {};
  cardToDosDueDateDisplayStrMap: Record<string, string> = {};
  cardToDosDueDateStatusMap: Record<string, PM_TODO_DUEDATE_STATUS_DATA_TYPE> = {};
  cardToDosAssigneesMap: Record<string, User> = {};
  timeRecordMap: Record<string, string> = {};

  TIMES = TIMES;

  _toDoListsDonePercMap = {};
  _cardToDos = 0;
  _cardToDosDone = 0;
  _cardDonePerc = 0;

  showToDoDueDatePopdownId = "";
  showToDoAssigneePopdownId = "";

  constructor(
    public pmCardModal: PmCardModal,
    public changeDetector: ChangeDetectorRef,
  ) { }
  ngOnInit() {
    this.openToDoListsSubs();
  }
  ngAfterViewInit() { }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }
  ngOnDestroy() {
    this.closeToDoListsSubs();
  }
  public updateUi() {
    this.changeDetector.detectChanges();
  }
  public _trackByFn(index: number, item: string) {
    return item;
  }

  private initOldToDoList(toDoList: PM_ToDoList) {
    this.oldCardToDoListsMap[toDoList.id] = JSON.parse(JSON.stringify(toDoList));
  }
  private toDoListNeedSave(toDoListId: string): boolean {
    return this.oldCardToDoListsMap[toDoListId].title != this.cardToDoListsMap[toDoListId].title ||
      JSON.stringify(this.oldCardToDoListsMap[toDoListId].toDosIds) != JSON.stringify(this.cardToDoListsMap[toDoListId].toDosIds);
  }
  private initOldToDo(toDo: PM_ToDo) {
    this.oldCardToDosMap[toDo.id] = JSON.parse(JSON.stringify(toDo));
  }
  private toDoNeedSave(toDoId: string): boolean {
    return this.oldCardToDosMap[toDoId].title != this.cardToDosMap[toDoId].title ||
      this.oldCardToDosMap[toDoId].done != this.cardToDosMap[toDoId].done ||
      this.oldCardToDosMap[toDoId].assignedTo != this.cardToDosMap[toDoId].assignedTo ||

      this.oldCardToDosMap[toDoId].startDate != this.cardToDosMap[toDoId].startDate ||
      this.oldCardToDosMap[toDoId].startDateString != this.cardToDosMap[toDoId].startDateString ||
      this.oldCardToDosMap[toDoId].startDateHourString != this.cardToDosMap[toDoId].startDateHourString ||

      this.oldCardToDosMap[toDoId].dueDate != this.cardToDosMap[toDoId].dueDate ||
      this.oldCardToDosMap[toDoId].dueDateString != this.cardToDosMap[toDoId].dueDateString ||
      this.oldCardToDosMap[toDoId].dueDateHourString != this.cardToDosMap[toDoId].dueDateHourString ||

      this.oldCardToDosMap[toDoId].doneAt != this.cardToDosMap[toDoId].doneAt ||
      this.oldCardToDosMap[toDoId].dueDateString != this.cardToDosMap[toDoId].dueDateString ||
      this.oldCardToDosMap[toDoId].dueDateHourString != this.cardToDosMap[toDoId].dueDateHourString;
  }
  private openToDoListsSubs() {
    if (!this.pmCardModal.card || this.pmCardModal.card.id == '')
      return;

    // toDoLists
    if (!this.toDoListsSub)
      this.toDoListsSub = this.pmCardModal.db.projectManagement.toDoLists.ref
        .where("cardId", "==", this.pmCardModal.card.id)
        .onSnapshot(
          (toDoListsQuerySnapshot) => {
            let toDoListsDocChanges = toDoListsQuerySnapshot.docChanges();
            if (toDoListsDocChanges.length > 0) {
              // update only if has changes
              toDoListsDocChanges.forEach((change) => {
                const newToDoList = new PM_ToDoList(change.doc.data());
                const existingIndex = this.cardToDoLists.findIndex((toDoList) => toDoList.id == newToDoList.id);
                if (change.type === "added") {
                  if (existingIndex === -1) {
                    this.cardToDoLists.push(newToDoList);
                    this.toDoListsIds.push(newToDoList.id);
                  }
                } else if (change.type === "modified") {
                  if (existingIndex > -1) {
                    this.cardToDoLists[existingIndex] = newToDoList;
                    this.toDoListsIds[existingIndex] = newToDoList.id;
                  }
                } else if (change.type === "removed") {
                  if (existingIndex > -1) {
                    this.cardToDoLists.splice(existingIndex, 1);
                    this.toDoListsIds.splice(existingIndex, 1);
                  }
                }

                // update the internal maps
                if (change.type === "removed") {
                  delete this.cardToDoListsMap[newToDoList.id];
                  delete this.oldCardToDoListsMap[newToDoList.id];
                } else {
                  this.cardToDoListsMap[newToDoList.id] = JSON.parse(JSON.stringify(newToDoList));
                  this.oldCardToDoListsMap[newToDoList.id] = JSON.parse(JSON.stringify(newToDoList));
                }
              });

              this._loadToDoLists();
            }
          },
          (error) => {
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', error);
          }
        );

    // toDos
    if (!this.toDosSub)
      this.toDosSub = this.pmCardModal.db.projectManagement.toDos.ref
        .where("cardId", "==", this.pmCardModal.card.id)
        .onSnapshot(
          (toDosQuerySnapshot) => {
            let toDosDocChanges = toDosQuerySnapshot.docChanges();
            if (toDosDocChanges.length > 0) {
              // update only if has changes
              toDosDocChanges.forEach((change) => {
                const newToDo = new PM_ToDo(change.doc.data());
                const existingIndex = this.cardToDos.findIndex((toDo) => toDo.id == newToDo.id);
                if (change.type === "added") {
                  if (existingIndex === -1)
                    this.cardToDos.push(newToDo);
                } else if (change.type === "modified") {
                  if (existingIndex > -1)
                    this.cardToDos[existingIndex] = newToDo;
                } else if (change.type === "removed") {
                  if (existingIndex > -1)
                    this.cardToDos.splice(existingIndex, 1);
                }

                // update the internal maps
                if (change.type === "removed") {
                  delete this.cardToDosMap[newToDo.id];
                  delete this.oldCardToDosMap[newToDo.id];

                  delete this.cardToDosDueDateDisplayStrMap[newToDo.id];

                  delete this.cardToDosDueDateStatusMap[newToDo.id];

                  delete this.cardToDosAssigneesMap[newToDo.id];
                } else {
                  this.cardToDosMap[newToDo.id] = JSON.parse(JSON.stringify(newToDo));
                  this.oldCardToDosMap[newToDo.id] = JSON.parse(JSON.stringify(newToDo));

                  this.cardToDosDueDateDisplayStrMap[newToDo.id] = this._generateDateString(newToDo);

                  let tempDueDateStatus = pmToDoDueDateStatusDetermination(newToDo);
                  this.cardToDosDueDateStatusMap[newToDo.id] = getPMToDoDueDateStatusData(tempDueDateStatus);

                  this.cardToDosAssigneesMap[newToDo.id] = new User();
                  const a = this.pmCardModal.boardMembers.find((u) => u.uName == newToDo.assignedTo);
                  if (a)
                    this.cardToDosAssigneesMap[newToDo.id] = a;
                }
              });

              if (this.pmCardModal.toDoIdToFocus != "")
                this.pmCardModal.focusElementById(this.pmCardModal.toDoIdToFocus, () => { this.pmCardModal.toDoIdToFocus = ""; });

              this._loadToDoLists();
            }
          },
          (error) => {
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', error);
          }
        );
  }
  private closeToDoListsSubs() {
    if (this.toDoListsSub != null)
      this.toDoListsSub();
    if (this.toDosSub != null)
      this.toDosSub();
  }
  __updateTimeRecordMap() {
    if (!this.pmCardModal.board.integration.tm.active)
      return;

    let tempMap = {};
    this.cardToDos
      .forEach((toDo) => {
        const sum = this.pmCardModal.getHoursSum4ToDo(toDo.id);
        if (sum.val > 0)
          tempMap[toDo.id] = sum.s;
      });
    this.timeRecordMap = tempMap;
    this.updateUi();
  }
  private _loadToDoLists() {
    this._cardToDos = 0;
    this._cardToDosDone = 0;
    this._cardDonePerc = 0;
    this._toDoListsDonePercMap = {};

    this.cardToDoLists
      .forEach((toDoList) => {
        let toDoListDoneToDos = 0;
        this.cardToDos
          .filter((todo) => todo.listId == toDoList.id)
          .forEach((todo) => {
            this._cardToDos++;
            if (todo.done) {
              this._cardToDosDone++;
              toDoListDoneToDos++;
            }
          });
        this._toDoListsDonePercMap[toDoList.id] = ((toDoListDoneToDos / toDoList.toDosIds.length) * 100);
      })

    if (this._cardToDos > 0)
      this._cardDonePerc = (this._cardToDosDone / this._cardToDos) * 100;

    this.updateUi();
    this.__updateTimeRecordMap();
  }

  // to-do-lists
  _toDolistsOnDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer == event.container) {
      this._loader = true;

      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      )

      this.pmCardModal.card.toDoListsIds = event.container.data;
      this.pmCardModal._updateCard()
        .then(() => {
          this._loader = false;
          this.updateUi();
        });
    }
  }
  _addToDoList() {
    this._loader = true;

    let newToDoList = new PM_ToDoList();
    newToDoList.id = this.pmCardModal.db.createId();
    newToDoList.cardId = this.pmCardModal.card.id;
    newToDoList.boardId = this.pmCardModal.card.boardId;
    newToDoList.parentKey = this.pmCardModal.card.parentKey;
    newToDoList.createdBy = this.pmCardModal.authService.localUser.uName;

    newToDoList.title = `To Do List #${this.pmCardModal.card.toDoListsIds.length + 1}`;

    this.pmCardModal.card.toDoListsIds.push(newToDoList.id);
    this.pmCardModal.card.status = 1;
    this.pmCardModal.card.updatedBy = this.pmCardModal.authService.localUser.uName;
    this.pmCardModal.card.updatedOn = this.pmCardModal.utilCtrl.timestamp.now();

    // Transaction
    this.pmCardModal.db
      .runTransaction((transaction) => {

        // Log
        let tempLog = new Log();

        tempLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
        tempLog.className = this.pmCardModal.className;
        tempLog.uName = this.pmCardModal.authService.localUser.uName;

        tempLog.type = "AUDIT";
        tempLog.category = "FC";

        tempLog.docPath = this.pmCardModal.localDocPath;
        tempLog.docId = this.pmCardModal.card.id;
        tempLog.changes = PM_Card_dataChangGen(this.pmCardModal.oldCard, this.pmCardModal.card, this.pmCardModal.boardMembers, this.pmCardModal.boardLabels, [...this.cardToDoLists, newToDoList], this.pmCardModal.utilCtrl);
        // Log

        // Activity
        let tempActivity = new PM_BoardActivity();

        tempActivity.id = this.pmCardModal.db.afs.createId();
        tempActivity.uName = this.pmCardModal.authService.localUser.uName;
        tempActivity.cardId = this.pmCardModal.card.id;
        tempActivity.boardId = this.pmCardModal.card.boardId;

        tempActivity.type = "FC";

        tempActivity.changes = PM_Card_dataChangGen(this.pmCardModal.oldCard, this.pmCardModal.card, this.pmCardModal.boardMembers, this.pmCardModal.boardLabels, [...this.cardToDoLists, newToDoList], this.pmCardModal.utilCtrl);
        // Activity

        // ToDoList
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDoLists).doc(newToDoList.id),
          Object.assign({}, newToDoList)
        );
        // ToDoList

        // Card
        transaction.update(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.localDocPath).doc(this.pmCardModal.card.id),
          {
            toDoListsIds: this.pmCardModal.db.fieldValue.arrayUnion(newToDoList.id) as any,
            status: this.pmCardModal.card.status,
            updatedBy: this.pmCardModal.card.updatedBy,
            updatedOn: this.pmCardModal.card.updatedOn,
          }
        );
        // Card

        // Log
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
          Object.assign({}, tempLog)
        );
        // Log

        // Activity
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
          Object.assign({}, tempActivity)
        );
        // Activity

        return Promise.resolve();

      })
      .then(() => {
        this.pmCardModal._initOldCard();
        this._loader = false;
        this.updateUi();
      })
      .catch((e) => {
        this._loader = false;
        this.updateUi();
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e)
      })
    // Transaction
  }
  _updateToDoListAfterChange(toDoList: PM_ToDoList) {
    if (!this.toDoListNeedSave(toDoList.id))
      return;

    // Transaction
    this.pmCardModal.db
      .runTransaction((transaction) => {

        // Log
        let tempLog = new Log();

        tempLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
        tempLog.className = this.pmCardModal.className;
        tempLog.uName = this.pmCardModal.authService.localUser.uName;

        tempLog.type = "AUDIT";
        tempLog.category = "FC";

        tempLog.docPath = this.pmCardModal.localDocPath;
        tempLog.docId = this.pmCardModal.card.id;
        tempLog.changes = PM_ToDoList_dataChangGen(this.oldCardToDoListsMap[toDoList.id], this.cardToDoListsMap[toDoList.id], this.cardToDos, this.pmCardModal.utilCtrl);
        // Log

        // Activity
        let tempActivity = new PM_BoardActivity();

        tempActivity.id = this.pmCardModal.db.afs.createId();
        tempActivity.uName = this.pmCardModal.authService.localUser.uName;
        tempActivity.cardId = this.pmCardModal.card.id;
        tempActivity.boardId = this.pmCardModal.card.boardId;

        tempActivity.type = "FC";

        tempActivity.html = `<span>To Do List Alterado: ${toDoList.title}</span>`;
        tempActivity.text = "To Do List Alterado: " + toDoList.title;
        tempActivity.changes = PM_ToDoList_dataChangGen(this.oldCardToDoListsMap[toDoList.id], this.cardToDoListsMap[toDoList.id], this.cardToDos, this.pmCardModal.utilCtrl);
        // Activity

        // toDoList
        transaction.update(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDoLists).doc(toDoList.id),
          {
            title: toDoList.title,
            toDosIds: toDoList.toDosIds,

            updatedBy: this.pmCardModal.authService.localUser.uName,
            updatedOn: this.pmCardModal.utilCtrl.timestamp.now(),
          } as PM_ToDoList
        );
        // toDoList
        // Log
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
          Object.assign({}, tempLog)
        );
        // Log

        // Activity
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
          Object.assign({}, tempActivity)
        );
        // Activity

        return Promise.resolve();

      })
      .then(() => {
        this.initOldToDoList(toDoList);
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erros', e)
      })
    // Transaction
  }
  _copyToDoListsToClipboard() {
    let toDoLists = this.cardToDoLists.sort((a, b) => this.pmCardModal.card.toDoListsIds.indexOf(a.id) - this.pmCardModal.card.toDoListsIds.indexOf(b.id));
    navigator.clipboard.writeText(
      _encodeMultipleToDoListsToString(this.pmCardModal.utilCtrl, toDoLists, this.cardToDos)
    )
      .then(() => {
        this.pmCardModal.uiFeedBackCtrl.presentToast(`Copiado para Clipboard`, ``, `success`)
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erros', e)
      })
  }
  _useClipboardToCreateToDoLists() {
    this.pmCardModal.uiFeedBackCtrl.presentLoader()
      .then(() => {
        navigator.clipboard.readText()
          .then((text) => {
            if (text && text.length > 0) {

              let newToDoLists: PM_ToDoList[] = [];
              let newToDos: PM_ToDo[] = [];
              let membersToAddToCard: string[] = [];

              let data = _decodeMultipleToDoListsFromString(this.pmCardModal.utilCtrl, text);

              data.toDoLists
                .forEach((toDoList) => {
                  toDoList.cardId = this.pmCardModal.card.id;
                  toDoList.boardId = this.pmCardModal.card.boardId;
                  toDoList.parentKey = this.pmCardModal.card.parentKey;
                  toDoList.createdBy = this.pmCardModal.authService.localUser.uName;
                  newToDoLists.push(toDoList);
                });

              data.toDos
                .forEach((toDo) => {
                  toDo.cardId = this.pmCardModal.card.id;
                  toDo.boardId = this.pmCardModal.card.boardId;
                  toDo.parentKey = this.pmCardModal.card.parentKey;
                  toDo.createdBy = this.pmCardModal.authService.localUser.uName;

                  // autoSetStartDate
                  if (!toDo.startDate && this.pmCardModal.board.autoSetStartDate) {
                    let startDateData = this.pmCardModal.utilCtrl.gets.getDateAndClosestTime(this.pmCardModal.utilCtrl.timestamp.toDate(toDo.createdOn));
                    toDo.startDate = this.pmCardModal.utilCtrl.timestamp.fromDate(startDateData.closestDateTime);
                    toDo.startDateString = startDateData.formattedDate;
                    toDo.startDateHourString = startDateData.closestTime;
                  }

                  // assignedTo validation
                  const validMember = this.pmCardModal.boardMembers.find((m) => m.uName == toDo.assignedTo) ? true : false;
                  if (validMember && !this.pmCardModal.card.assignedTo.includes(toDo.assignedTo) && !membersToAddToCard.includes(toDo.assignedTo))
                    membersToAddToCard.push(toDo.assignedTo);
                  if (!validMember)
                    toDo.assignedTo = '';

                  newToDos.push(toDo);
                });

              // Transaction
              this.pmCardModal.db
                .runTransaction((transaction) => {

                  // Log
                  let tempLog = new Log();

                  tempLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
                  tempLog.className = this.pmCardModal.className;
                  tempLog.uName = this.pmCardModal.authService.localUser.uName;

                  tempLog.type = "AUDIT";
                  tempLog.category = "FC";

                  tempLog.docPath = this.pmCardModal.localDocPath;
                  tempLog.docId = this.pmCardModal.card.id;
                  tempLog.changes = PM_Card_dataChangGen(this.pmCardModal.oldCard, this.pmCardModal.card, this.pmCardModal.boardMembers, this.pmCardModal.boardLabels, [...this.cardToDoLists, ...newToDoLists], this.pmCardModal.utilCtrl);
                  // Log

                  // Activity
                  let tempActivity = new PM_BoardActivity();

                  tempActivity.id = this.pmCardModal.db.afs.createId();
                  tempActivity.uName = this.pmCardModal.authService.localUser.uName;
                  tempActivity.cardId = this.pmCardModal.card.id;
                  tempActivity.boardId = this.pmCardModal.card.boardId;

                  tempActivity.type = "FC";

                  tempActivity.changes = PM_Card_dataChangGen(this.pmCardModal.oldCard, this.pmCardModal.card, this.pmCardModal.boardMembers, this.pmCardModal.boardLabels, [...this.cardToDoLists, ...newToDoLists], this.pmCardModal.utilCtrl);
                  // Activity

                  // ToDos
                  newToDos.forEach((newToDo) => {
                    transaction.set(
                      this.pmCardModal.db.afs.firestore
                        .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDos).doc(newToDo.id),
                      Object.assign({}, newToDo)
                    );
                  });
                  // ToDos

                  // toDoList
                  newToDoLists.forEach((newToDoList) => {
                    transaction.set(
                      this.pmCardModal.db.afs.firestore
                        .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDoLists).doc(newToDoList.id),
                      Object.assign({}, newToDoList)
                    );
                  });
                  // toDoList

                  // Cards
                  const newToDoListsIds = newToDoLists.map((newToDoList) => newToDoList.id);
                  transaction.update(
                    this.pmCardModal.db.afs.firestore
                      .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.cards).doc(this.pmCardModal.card.id),
                    {
                      toDoListsIds: this.pmCardModal.db.fieldValue.arrayUnion(...newToDoListsIds) as any,
                      updatedBy: this.pmCardModal.authService.localUser.uName,
                      updatedOn: this.pmCardModal.utilCtrl.timestamp.now()
                    }
                  );
                  // Cards

                  // Log
                  transaction.set(
                    this.pmCardModal.db.afs.firestore
                      .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
                    Object.assign({}, tempLog)
                  );
                  // Log

                  // Activity
                  transaction.set(
                    this.pmCardModal.db.afs.firestore
                      .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
                    Object.assign({}, tempActivity)
                  );
                  // Activity

                  return Promise.resolve();

                })
                .then(() => {
                  // Add Members To Card
                  membersToAddToCard
                    .forEach((uName) => this.pmCardModal.forceAddMembersToCard(uName));

                  this.pmCardModal.uiFeedBackCtrl.dismissLoader();
                })
                .catch((e) => {
                  this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e)
                })
              // Transaction

            } else {
              this.pmCardModal.uiFeedBackCtrl.dismissLoader();
              this.pmCardModal.uiFeedBackCtrl.presentAlert('Texto inválido', 'Verifique o texto usado na clipboard. O separador do texto deve ser: ";" Ponto e virgula! Para fazer upload adicione pelomenos 2 itens.', 'error')
            }
          })
          .catch((e) => {
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e)
          })
      })
  }
  _copyToDoListToClipboard(toDoList: PM_ToDoList) {
    const toDos = toDoList.toDosIds
      .map((toDoId) => this.cardToDos.find((todo) => todo.id == toDoId))
      .filter((toDo) => toDo !== undefined);
    navigator.clipboard.writeText(
      _encodeToDosToString(this.pmCardModal.utilCtrl, toDos)
    )
      .then(() => {
        this.pmCardModal.uiFeedBackCtrl.presentToast(`Copiado para Clipboard`, ``, `success`)
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erros', e)
      })
  }
  _useClipboardToCreateToDoList(toDoList: PM_ToDoList) {
    this.pmCardModal.uiFeedBackCtrl.presentLoader()
      .then(() => {
        navigator.clipboard.readText()
          .then((text) => {
            if (text && text.length > 0) {

              let newToDos: PM_ToDo[] = [];
              let membersToAddToCard: string[] = [];

              _decodeToDosFromString(this.pmCardModal.utilCtrl, text)
                .forEach((toDo) => {
                  toDo.listId = toDoList.id;
                  toDo.cardId = this.pmCardModal.card.id;
                  toDo.boardId = this.pmCardModal.card.boardId;
                  toDo.parentKey = this.pmCardModal.card.parentKey;
                  toDo.createdBy = this.pmCardModal.authService.localUser.uName;

                  // autoSetStartDate
                  if (!toDo.startDate && this.pmCardModal.board.autoSetStartDate) {
                    let startDateData = this.pmCardModal.utilCtrl.gets.getDateAndClosestTime(this.pmCardModal.utilCtrl.timestamp.toDate(toDo.createdOn));
                    toDo.startDate = this.pmCardModal.utilCtrl.timestamp.fromDate(startDateData.closestDateTime);
                    toDo.startDateString = startDateData.formattedDate;
                    toDo.startDateHourString = startDateData.closestTime;
                  }

                  // assignedTo validation
                  const validMember = this.pmCardModal.boardMembers.find((m) => m.uName == toDo.assignedTo) ? true : false;
                  if (validMember && !this.pmCardModal.card.assignedTo.includes(toDo.assignedTo) && !membersToAddToCard.includes(toDo.assignedTo))
                    membersToAddToCard.push(toDo.assignedTo);
                  if (!validMember)
                    toDo.assignedTo = '';

                  newToDos.push(toDo);
                  toDoList.toDosIds.push(toDo.id);
                });

              toDoList.updatedBy = this.pmCardModal.authService.localUser.uName;
              toDoList.updatedOn = this.pmCardModal.utilCtrl.timestamp.now();

              // Transaction
              this.pmCardModal.db
                .runTransaction((transaction) => {

                  // Log
                  let tempLog = new Log();

                  tempLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
                  tempLog.className = this.pmCardModal.className;
                  tempLog.uName = this.pmCardModal.authService.localUser.uName;

                  tempLog.type = "AUDIT";
                  tempLog.category = "FC";

                  tempLog.docPath = this.pmCardModal.localDocPath;
                  tempLog.docId = this.pmCardModal.card.id;
                  tempLog.changes = PM_ToDoList_dataChangGen(this.oldCardToDoListsMap[toDoList.id], toDoList, [...this.cardToDos, ...newToDos], this.pmCardModal.utilCtrl);
                  // Log

                  // Activity
                  let tempActivity = new PM_BoardActivity();

                  tempActivity.id = this.pmCardModal.db.afs.createId();
                  tempActivity.uName = this.pmCardModal.authService.localUser.uName;
                  tempActivity.cardId = this.pmCardModal.card.id;
                  tempActivity.boardId = this.pmCardModal.card.boardId;

                  tempActivity.type = "FC";

                  tempActivity.changes = PM_ToDoList_dataChangGen(this.oldCardToDoListsMap[toDoList.id], toDoList, [...this.cardToDos, ...newToDos], this.pmCardModal.utilCtrl);

                  // ToDo
                  newToDos.forEach((newToDo) => {
                    transaction.set(
                      this.pmCardModal.db.afs.firestore
                        .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDos).doc(newToDo.id),
                      Object.assign({}, newToDo)
                    );
                  });
                  // ToDo

                  // toDoList
                  const newToDosIds = newToDos.map((newToDo) => newToDo.id);
                  transaction.update(
                    this.pmCardModal.db.afs.firestore
                      .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDoLists).doc(toDoList.id),
                    {
                      toDosIds: this.pmCardModal.db.fieldValue.arrayUnion(...newToDosIds) as any,
                      updatedBy: toDoList.updatedBy,
                      updatedOn: toDoList.updatedOn,
                    }
                  );
                  // toDoList

                  // Log
                  transaction.set(
                    this.pmCardModal.db.afs.firestore
                      .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
                    Object.assign({}, tempLog)
                  );
                  // Log

                  // Activity
                  transaction.set(
                    this.pmCardModal.db.afs.firestore
                      .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
                    Object.assign({}, tempActivity)
                  );
                  // Activity

                  return Promise.resolve();

                })
                .then(() => {
                  this.initOldToDoList(toDoList);

                  // Add Members To Card
                  membersToAddToCard
                    .forEach((uName) => this.pmCardModal.forceAddMembersToCard(uName));

                  this.pmCardModal.uiFeedBackCtrl.dismissLoader();
                })
                .catch((e) => {
                  this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e)
                })
              // Transaction

            } else {
              this.pmCardModal.uiFeedBackCtrl.dismissLoader();
              this.pmCardModal.uiFeedBackCtrl.presentAlert('Texto inválido', 'Verifique o texto usado na clipboard. O separador do texto deve ser: ";" Ponto e virgula! Para fazer upload adicione pelomenos 2 itens.', 'error')
            }
          })
          .catch((e) => {
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e)
          })
      })
  }
  _remToDoList(toDoListToDelete: PM_ToDoList) {
    this._loader = true;

    this.pmCardModal.card.toDoListsIds = this.pmCardModal.card.toDoListsIds.filter((toDoListId) => { return toDoListId != toDoListToDelete.id });
    this.pmCardModal.card.status = 1;
    this.pmCardModal.card.updatedBy = this.pmCardModal.authService.localUser.uName;
    this.pmCardModal.card.updatedOn = this.pmCardModal.utilCtrl.timestamp.now();

    // Transaction
    this.pmCardModal.db
      .runTransaction((transaction) => {

        // Log
        let tempLog = new Log();

        tempLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
        tempLog.className = this.pmCardModal.className;
        tempLog.uName = this.pmCardModal.authService.localUser.uName;

        tempLog.type = "AUDIT";
        tempLog.category = "FC";

        tempLog.docPath = this.pmCardModal.localDocPath;
        tempLog.docId = this.pmCardModal.card.id;
        tempLog.changes = PM_Card_dataChangGen(this.pmCardModal.oldCard, this.pmCardModal.card, this.pmCardModal.boardMembers, this.pmCardModal.boardLabels, this.cardToDoLists, this.pmCardModal.utilCtrl);
        // Log

        // Activity
        let tempActivity = new PM_BoardActivity();

        tempActivity.id = this.pmCardModal.db.afs.createId();
        tempActivity.uName = this.pmCardModal.authService.localUser.uName;
        tempActivity.cardId = this.pmCardModal.card.id;
        tempActivity.boardId = this.pmCardModal.card.boardId;

        tempActivity.type = "FC";

        tempActivity.html = `<span>To Do List Apagado: ${toDoListToDelete.title}</span>`;
        tempActivity.text = "To Do List Apagado: " + toDoListToDelete.title;
        tempActivity.changes = PM_Card_dataChangGen(this.pmCardModal.oldCard, this.pmCardModal.card, this.pmCardModal.boardMembers, this.pmCardModal.boardLabels, this.cardToDoLists, this.pmCardModal.utilCtrl);
        // Activity

        // ToDoList
        transaction.delete(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDoLists).doc(toDoListToDelete.id)
        );
        // ToDoList

        // ToDos
        toDoListToDelete.toDosIds
          .forEach((toDoId) => {
            transaction.delete(
              this.pmCardModal.db.afs.firestore
                .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDos).doc(toDoId)
            );
          });
        // ToDos

        // Card
        transaction.update(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.localDocPath).doc(this.pmCardModal.card.id),
          {
            toDoListsIds: this.pmCardModal.db.fieldValue.arrayRemove(toDoListToDelete.id) as any,
            status: this.pmCardModal.card.status,
            updatedBy: this.pmCardModal.card.updatedBy,
            updatedOn: this.pmCardModal.card.updatedOn,
          }
        );
        // Card

        // Log
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
          Object.assign({}, tempLog)
        );
        // Log

        // Activity
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
          Object.assign({}, tempActivity)
        );
        // Activity

        return Promise.resolve();

      })
      .then(() => {
        this.pmCardModal._initOldCard();
        this._loader = false;
      })
      .catch((e) => {
        this._loader = false;
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e)
      })
    // Transaction
  }
  // -- to-do
  _todolistOnDrop(event: CdkDragDrop<string[]>) {
    this._loader = true;

    const now = this.pmCardModal.utilCtrl.timestamp.now();
    const toDoId = event.item.data;
    const oldToDoListId = event.previousContainer.id;
    const newtoDoListId = event.container.id;

    let newLogs: Log[] = [];
    let newActivities: PM_BoardActivity[] = [];

    let toDo = this.cardToDos.find((toDo) => { return toDo.id == toDoId });
    let oldToDoList = this.cardToDoLists.find((toDoList) => { return toDoList.id == oldToDoListId });
    let newToDoList = this.cardToDoLists.find((toDoList) => { return toDoList.id == newtoDoListId });

    let previousIndex = -1;

    // Transaction
    this.pmCardModal.db.runTransaction((transaction) => {

      if (event.previousContainer == event.container) {
        //-- on the same List
        previousIndex = event.container.data.indexOf(event.item.data);
        moveItemInArray(
          event.container.data,
          // event.previousIndex,
          previousIndex,
          event.currentIndex
        )

        // no change in index
        if (previousIndex == event.currentIndex)
          return Promise.resolve();

        let tempToDosIds = event.container.data;

        this.cardToDoListsMap[oldToDoList.id].toDosIds = tempToDosIds;
        transaction.update(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDoLists).doc(oldToDoList.id),
          {
            toDosIds: tempToDosIds,

            updatedBy: this.pmCardModal.authService.localUser.uName,
            updatedOn: now
          }
        );

        // Log
        let listLog = new Log();

        listLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
        listLog.className = this.pmCardModal.className;
        listLog.uName = this.pmCardModal.authService.localUser.uName;

        listLog.type = "AUDIT";
        listLog.category = "FC";

        listLog.docPath = this.pmCardModal.localDocPath;
        listLog.docId = this.pmCardModal.card.id;
        listLog.changes = PM_ToDoList_dataChangGen(this.oldCardToDoListsMap[oldToDoList.id], this.cardToDoListsMap[oldToDoList.id], this.cardToDos, this.pmCardModal.utilCtrl);

        listLog.createdOn = now;
        newLogs.push(listLog);
        // Log

        // Activity
        let listActivity = new PM_BoardActivity();

        listActivity.id = this.pmCardModal.db.afs.createId();
        listActivity.uName = this.pmCardModal.authService.localUser.uName;
        listActivity.cardId = this.pmCardModal.card.id;
        listActivity.boardId = this.pmCardModal.card.boardId;

        listActivity.type = "FC";
        listActivity.changes = PM_ToDoList_dataChangGen(this.oldCardToDoListsMap[oldToDoList.id], this.cardToDoListsMap[oldToDoList.id], this.cardToDos, this.pmCardModal.utilCtrl);

        listActivity.createdOn = now;
        newActivities.push(listActivity);
        // Activity

        //-- on the same List
      } else {
        //-- in between Lists
        previousIndex = event.previousContainer.data.indexOf(event.item.data);

        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          // event.previousIndex,
          previousIndex,
          event.currentIndex
        )

        let tempOldListToDosIds = event.previousContainer.data;
        let tempNewListToDosIds = event.container.data;

        transaction.update(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDos).doc(toDoId),
          {
            listId: newtoDoListId,

            updatedBy: this.pmCardModal.authService.localUser.uName,
            updatedOn: now
          } as PM_ToDo
        );
        transaction.update(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDoLists).doc(oldToDoListId),
          {
            toDosIds: tempOldListToDosIds,

            updatedBy: this.pmCardModal.authService.localUser.uName,
            updatedOn: now
          } as PM_ToDoList
        );
        transaction.update(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDoLists).doc(newtoDoListId),
          {
            toDosIds: tempNewListToDosIds,

            updatedBy: this.pmCardModal.authService.localUser.uName,
            updatedOn: now
          } as PM_ToDoList
        );

        let change = {
          fieldName: 'To Do alterado de lista: ' + toDo.title,
          oldValue: oldToDoList.title,
          newValue: newToDoList.title
        };

        // Log
        let toDoLog = new Log();

        toDoLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
        toDoLog.className = this.pmCardModal.className;
        toDoLog.uName = this.pmCardModal.authService.localUser.uName;

        toDoLog.type = "AUDIT";
        toDoLog.category = "FC";

        toDoLog.docPath = this.pmCardModal.localDocPath;
        toDoLog.docId = this.pmCardModal.card.id;

        toDoLog.changes = [change];
        toDoLog.createdOn = now;
        newLogs.push(toDoLog);
        // Log

        // Activity
        let toDoActivity = new PM_BoardActivity();

        toDoActivity.id = this.pmCardModal.db.afs.createId();
        toDoActivity.uName = this.pmCardModal.authService.localUser.uName;
        toDoActivity.cardId = this.pmCardModal.card.id;
        toDoActivity.boardId = this.pmCardModal.card.boardId;

        toDoActivity.type = "FC";

        toDoActivity.changes = [change];
        toDoActivity.createdOn = now;
        newActivities.push(toDoActivity);
        // Activity

        //-- in between Lists
      }

      // Log
      newLogs.forEach((log) => {
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(log.id),
          Object.assign({}, log)
        );
      });
      // Log

      // Activity
      newActivities.forEach((actv) => {
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(actv.id),
          Object.assign({}, actv)
        );
      });
      // Activity

      return Promise.resolve();
    })
      .then(() => {
        this._loader = false;
      })
      .catch((e) => {
        this._loader = false;
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e)
      })
  }
  _addToDo(toDoList: PM_ToDoList) {
    this._loader = true;

    let newToDo = new PM_ToDo();
    newToDo.id = this.pmCardModal.db.createId();
    newToDo.listId = toDoList.id;
    newToDo.cardId = this.pmCardModal.card.id;
    newToDo.boardId = this.pmCardModal.card.boardId;
    newToDo.parentKey = this.pmCardModal.card.parentKey;
    newToDo.createdBy = this.pmCardModal.authService.localUser.uName;
    if (this.pmCardModal.board.autoSetStartDate) {
      let startDateData = this.pmCardModal.utilCtrl.gets.getDateAndClosestTime(this.pmCardModal.utilCtrl.timestamp.toDate(newToDo.createdOn))
      newToDo.startDate = this.pmCardModal.utilCtrl.timestamp.fromDate(startDateData.closestDateTime);
      newToDo.startDateString = startDateData.formattedDate;
      newToDo.startDateHourString = startDateData.closestTime;
    }

    newToDo.title = `To Do #${toDoList.toDosIds.length + 1}`;

    toDoList.toDosIds.push(newToDo.id);
    toDoList.updatedBy = this.pmCardModal.authService.localUser.uName;
    toDoList.updatedOn = this.pmCardModal.utilCtrl.timestamp.now();

    // Transaction
    this.pmCardModal.db
      .runTransaction((transaction) => {

        // Log
        let tempLog = new Log();

        tempLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
        tempLog.className = this.pmCardModal.className;
        tempLog.uName = this.pmCardModal.authService.localUser.uName;

        tempLog.type = "AUDIT";
        tempLog.category = "FC";

        tempLog.docPath = this.pmCardModal.localDocPath;
        tempLog.docId = this.pmCardModal.card.id;
        tempLog.changes = PM_ToDoList_dataChangGen(this.oldCardToDoListsMap[toDoList.id], toDoList, [...this.cardToDos, newToDo], this.pmCardModal.utilCtrl);
        // Log

        // Activity
        let tempActivity = new PM_BoardActivity();

        tempActivity.id = this.pmCardModal.db.afs.createId();
        tempActivity.uName = this.pmCardModal.authService.localUser.uName;
        tempActivity.cardId = this.pmCardModal.card.id;
        tempActivity.boardId = this.pmCardModal.card.boardId;

        tempActivity.type = "FC";

        tempActivity.changes = PM_ToDoList_dataChangGen(this.oldCardToDoListsMap[toDoList.id], toDoList, [...this.cardToDos, newToDo], this.pmCardModal.utilCtrl);

        // ToDo
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDos).doc(newToDo.id),
          Object.assign({}, newToDo)
        );
        // ToDo

        // toDoList
        transaction.update(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDoLists).doc(toDoList.id),
          {
            toDosIds: this.pmCardModal.db.fieldValue.arrayUnion(newToDo.id) as any,
            updatedBy: toDoList.updatedBy,
            updatedOn: toDoList.updatedOn,
          }
        );
        // toDoList

        // Log
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
          Object.assign({}, tempLog)
        );
        // Log

        // Activity
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
          Object.assign({}, tempActivity)
        );
        // Activity

        return Promise.resolve();

      })
      .then(() => {
        this.initOldToDoList(toDoList);
        this._loader = false;
      })
      .catch((e) => {
        this._loader = false;
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e)
      })
    // Transaction
  }
  _updateToDoAfterChange(toDo: PM_ToDo) {
    this.cardToDosMap[toDo.id] = JSON.parse(JSON.stringify(toDo));

    if (
      this.cardToDosMap[toDo.id].assignedTo &&
      this.cardToDosMap[toDo.id].assignedTo != this.oldCardToDosMap[toDo.id].assignedTo &&
      !this.pmCardModal.card.assignedTo.includes(this.cardToDosMap[toDo.id].assignedTo)
    )
      // Add Member To the Card
      this.pmCardModal.forceAddMembersToCard(this.cardToDosMap[toDo.id].assignedTo);

    if (!this.toDoNeedSave(toDo.id))
      return;

    // Transaction
    this.pmCardModal.db
      .runTransaction((transaction) => {

        // Log
        let tempLog = new Log();

        tempLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
        tempLog.className = this.pmCardModal.className;
        tempLog.uName = this.pmCardModal.authService.localUser.uName;

        tempLog.type = "AUDIT";
        tempLog.category = "FC";

        tempLog.docPath = this.pmCardModal.localDocPath;
        tempLog.docId = this.pmCardModal.card.id;
        tempLog.changes = PM_ToDo_dataChangGen(this.oldCardToDosMap[toDo.id], this.cardToDosMap[toDo.id], this.pmCardModal.boardMembers, this.pmCardModal.utilCtrl);
        // Log

        // Activity
        let tempActivity = new PM_BoardActivity();

        tempActivity.id = this.pmCardModal.db.afs.createId();
        tempActivity.uName = this.pmCardModal.authService.localUser.uName;
        tempActivity.cardId = this.pmCardModal.card.id;
        tempActivity.boardId = this.pmCardModal.card.boardId;

        tempActivity.type = "FC";

        tempActivity.html = `<span>To Do Alterado: ${this.oldCardToDosMap[toDo.id].title}</span>`;
        tempActivity.text = "To Do Alterado: " + this.oldCardToDosMap[toDo.id].title;
        tempActivity.changes = PM_ToDo_dataChangGen(this.oldCardToDosMap[toDo.id], this.cardToDosMap[toDo.id], this.pmCardModal.boardMembers, this.pmCardModal.utilCtrl);
        // Activity

        // toDo
        transaction.update(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDos).doc(toDo.id),
          {
            title: toDo.title,
            done: toDo.done,
            assignedTo: toDo.assignedTo,

            startDate: toDo.startDate,
            startDateString: toDo.startDateString,
            startDateHourString: toDo.startDateHourString,

            dueDate: toDo.dueDate,
            dueDateString: toDo.dueDateString,
            dueDateHourString: toDo.dueDateHourString,

            doneAt: toDo.doneAt,
            doneAtString: toDo.doneAtString,
            doneAtHourString: toDo.doneAtHourString,

            updatedBy: this.pmCardModal.authService.localUser.uName,
            updatedOn: this.pmCardModal.utilCtrl.timestamp.now(),
          } as PM_ToDo
        );
        // toDo

        // Log
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
          Object.assign({}, tempLog)
        );
        // Log

        // Activity
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
          Object.assign({}, tempActivity)
        );
        // Activity

        // Notification
        if ((this.cardToDosMap[toDo.id].assignedTo !== this.oldCardToDosMap[toDo.id].assignedTo) && this.cardToDosMap[toDo.id].assignedTo != this.pmCardModal.authService.localUser.uName) {
          let notification = genNotif_todoAssing(this.pmCardModal.card, this.cardToDosMap[toDo.id], this.pmCardModal.authService.localUser);
          transaction.set(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.sys.notifications).doc(notification.id),
            Object.assign({}, notification)
          );
        }
        // Notification

        return Promise.resolve();

      })
      .then(() => {
        this.initOldToDo(toDo);
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.pmCardModal.className, this.pmCardModal.authService.localUser.uName, 'Erros', e)
      })
    // Transaction
  }
  _remToDo(toDoList: PM_ToDoList, toDoToDelete: PM_ToDo) {
    this._loader = true;

    toDoList.toDosIds = toDoList.toDosIds.filter((toDoId) => { return toDoId != toDoToDelete.id });
    toDoList.updatedBy = this.pmCardModal.authService.localUser.uName;
    toDoList.updatedOn = this.pmCardModal.utilCtrl.timestamp.now();

    // Transaction
    this.pmCardModal.db
      .runTransaction((transaction) => {

        // Log
        let tempLog = new Log();

        tempLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
        tempLog.className = this.pmCardModal.className;
        tempLog.uName = this.pmCardModal.authService.localUser.uName;

        tempLog.type = "AUDIT";
        tempLog.category = "FC";

        tempLog.docPath = this.pmCardModal.localDocPath;
        tempLog.docId = this.pmCardModal.card.id;
        tempLog.changes = PM_ToDoList_dataChangGen(this.oldCardToDoListsMap[toDoList.id], toDoList, this.cardToDos, this.pmCardModal.utilCtrl);
        // Log

        // Activity
        let tempActivity = new PM_BoardActivity();

        tempActivity.id = this.pmCardModal.db.afs.createId();
        tempActivity.uName = this.pmCardModal.authService.localUser.uName;
        tempActivity.cardId = this.pmCardModal.card.id;
        tempActivity.boardId = this.pmCardModal.card.boardId;

        tempActivity.type = "FC";
        tempActivity.html = `<span>To Do Apagado: ${toDoToDelete.title}</span>`;
        tempActivity.text = "To Do Apagado: " + toDoToDelete.title;
        tempActivity.changes = PM_ToDoList_dataChangGen(this.oldCardToDoListsMap[toDoList.id], toDoList, this.cardToDos, this.pmCardModal.utilCtrl);

        // ToDos
        transaction.delete(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDos).doc(toDoToDelete.id)
        );
        // ToDos

        // ToDoList
        transaction.update(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.toDoLists).doc(toDoList.id),
          {
            toDosIds: this.pmCardModal.db.fieldValue.arrayRemove(toDoToDelete.id) as any,

            updatedBy: toDoList.updatedBy,
            updatedOn: toDoList.updatedOn,
          }
        );
        // ToDoList

        // Log
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
          Object.assign({}, tempLog)
        );
        // Log

        // Activity
        transaction.set(
          this.pmCardModal.db.afs.firestore
            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
          Object.assign({}, tempActivity)
        );
        // Activity

        return Promise.resolve();

      })
      .then(() => {
        this.initOldToDoList(toDoList);
        this._loader = false;
      })
      .catch((e) => {
        this._loader = false;
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e)
      })
    // Transaction
  }

  _toggleToDoDone(toDo: PM_ToDo) {
    toDo.done = !toDo.done;
    if (toDo.done) {
      toDo.doneAt = this.pmCardModal.utilCtrl.timestamp.now();
      const doneAtDateTimeStr = this.pmCardModal.utilCtrl.timestamp.getDateTimeString(toDo.doneAt);
      toDo.doneAtString = doneAtDateTimeStr.split('T')[0];
      toDo.doneAtHourString = doneAtDateTimeStr.split('T')[1];
    } else {
      toDo.doneAt = null;
      toDo.doneAtString = null;
      toDo.doneAtHourString = null;
    }
    this._updateToDoAfterChange(toDo);
  }
  _onDragToDoStarted(event) {
    this.dragingToDo = true;
  }
  _onDragToDoEnded(event) {
    this.dragingToDo = false;
  }

  _generateDateString(toDo: PM_ToDo): string {
    let result = '';
    if (toDo.startDate)
      result += `${this.pmCardModal.utilCtrl.timestamp.formatToDateString(toDo.startDate, toDo.dueDateHourString != null)} - `;
    if (toDo.dueDate)
      result += `${this.pmCardModal.utilCtrl.timestamp.formatToDateString(toDo.dueDate, toDo.dueDateHourString != null)}`;
    return result;
  }
  // toDo dueDate Popdown
  _showToDoDueDatePopdown(toDoId: string) {
    this.showToDoDueDatePopdownId = toDoId;
    this.pmCardModal.__showPopdownInMain("to-do-due-date-popdown-trigger-" + toDoId, "to-do-due-date-popdown-" + toDoId, () => { this._hideToDoDueDatePopdown(); });
  }
  _hideToDoDueDatePopdown() {
    const _this = this;
    _this.showToDoDueDatePopdownId = "";
    this.updateUi();
  }
  _toDoDueDatePopdown_clear(toDo: PM_ToDo) {
    toDo.startDate = null;
    toDo.startDateString = null;
    toDo.startDateHourString = null;

    toDo.dueDate = null;
    toDo.dueDateString = null;
    toDo.dueDateHourString = null;

    // toDo.dueDateDone = false;
    // toDo.doneAt = null;
    // toDo.doneAtString = null;
    // toDo.doneAtHourString = null;

    this._updateToDoAfterChange(toDo);
    this._hideToDoDueDatePopdown();
  }
  _toDoDueDatePopdown_toggleStartDate(toDo: PM_ToDo) {
    if (toDo.startDateString != null) {
      toDo.startDate = null;
      toDo.startDateString = null;
      toDo.startDateHourString = null;
      this.__toDoDueDatePopdown_saveStartDate(toDo);
    } else
      toDo.startDateString = '';
  }
  _toDoDueDatePopdown_toggleStartDateHour(toDo: PM_ToDo) {
    if (toDo.startDateHourString != null)
      toDo.startDateHourString = null;
    else
      toDo.startDateHourString = this.TIMES[0];
    this.__toDoDueDatePopdown_saveStartDate(toDo);
  }
  _toDoDueDatePopdown_toggleDueDateHour(toDo: PM_ToDo) {
    if (toDo.dueDateHourString != null)
      toDo.dueDateHourString = null;
    else
      toDo.dueDateHourString = this.TIMES[this.TIMES.length - 1];
    this.__toDoDueDatePopdown_saveDueDate(toDo);
  }
  _toDoDueDatePopdown_startDateStringInputOnChange(toDo: PM_ToDo) {
    this.__toDoDueDatePopdown_saveStartDate(toDo);
  }
  _toDoDueDatePopdown_startDateHourStringInputOnChange(toDo: PM_ToDo) {
    this.__toDoDueDatePopdown_saveStartDate(toDo);
  }
  _toDoDueDatePopdown_dueDateStringInputOnChange(toDo: PM_ToDo) {
    this.__toDoDueDatePopdown_saveDueDate(toDo);
  }
  _toDoDueDatePopdown_dueDateHourStringInputOnChange(toDo: PM_ToDo) {
    this.__toDoDueDatePopdown_saveDueDate(toDo);
  }
  private __toDoDueDatePopdown_saveStartDate(toDo: PM_ToDo) {
    if (!toDo.startDateString || typeof toDo.startDateString !== 'string') {
      // missing startDateString
      toDo.startDate = null;
      toDo.startDateString = null;
      toDo.startDateHourString = null;
    } else {
      let dateParts = toDo.startDateString.split('-');
      if (dateParts.length !== 3)
        throw new Error('Invalid date format. Expected format: YYYY-MM-DD');

      let year = parseInt(dateParts[0], 10);
      let month = parseInt(dateParts[1], 10) - 1; // Months are zero-based in JavaScript Date
      let day = parseInt(dateParts[2], 10);

      let hours = 0;
      let minutes = 0;

      if (toDo.startDateHourString && typeof toDo.startDateHourString === 'string' && toDo.startDateHourString.trim() !== '') {
        let timeParts = toDo.startDateHourString.split(':');
        if (timeParts.length !== 2)
          throw new Error('Invalid time format. Expected format: HH:MM');

        hours = parseInt(timeParts[0], 10);
        minutes = parseInt(timeParts[1], 10);
      } else {
        // missing startDateHourString
        toDo.startDateHourString = null;
      }

      let date = new Date(year, month, day, hours, minutes, 0, 0);
      if (isNaN(date.getTime()))
        throw new Error('Invalid date or time');

      toDo.startDate = this.pmCardModal.utilCtrl.timestamp.fromDate(date);
    }
    this._updateToDoAfterChange(toDo);
  }
  private __toDoDueDatePopdown_saveDueDate(toDo: PM_ToDo) {
    if (!toDo.dueDateString || typeof toDo.dueDateString !== 'string') {
      // missing startDateString
      toDo.startDate = null;
      toDo.startDateString = null;
      toDo.startDateHourString = null;
    } else {
      let dateParts = toDo.dueDateString.split('-');
      if (dateParts.length !== 3)
        throw new Error('Invalid date format. Expected format: YYYY-MM-DD');

      let year = parseInt(dateParts[0], 10);
      let month = parseInt(dateParts[1], 10) - 1; // Months are zero-based in JavaScript Date
      let day = parseInt(dateParts[2], 10);

      let hours = 23;
      let minutes = 59;

      if (toDo.dueDateHourString && typeof toDo.dueDateHourString === 'string' && toDo.dueDateHourString.trim() !== '') {
        let timeParts = toDo.dueDateHourString.split(':');
        if (timeParts.length !== 2)
          throw new Error('Invalid time format. Expected format: HH:MM');

        hours = parseInt(timeParts[0], 10);
        minutes = parseInt(timeParts[1], 10);
      } else {
        // missing dueDateHourString
        toDo.dueDateHourString = null;
      }

      let date = new Date(year, month, day, hours, minutes, 59, 999);
      if (isNaN(date.getTime()))
        throw new Error('Invalid date or time');

      toDo.dueDate = this.pmCardModal.utilCtrl.timestamp.fromDate(date);
    }
    this._updateToDoAfterChange(toDo);
  }
  // toDo dueDate Popdown


  // toDo Assignee Popdown
  _showToDoAssigneePopdown(toDoId: string) {
    this.showToDoAssigneePopdownId = toDoId;
    this.pmCardModal.__showPopdownInMain("to-do-assignee-popdown-trigger-" + toDoId, "to-do-assignee-popdown-" + toDoId, () => { this._hideToDoAssigneePopdown(); });
  }
  _hideToDoAssigneePopdown() {
    const _this = this;
    _this.showToDoAssigneePopdownId = "";
    this.updateUi();
  }
  _updateToDoAssigneeAfterChange(toDo: PM_ToDo, user: User) {
    toDo.assignedTo = "";
    if (user)
      toDo.assignedTo = user.uName;
    this._updateToDoAfterChange(toDo);
    this._hideToDoAssigneePopdown();
  }
  _cleanToDoAssignee(toDo: PM_ToDo) {
    toDo.assignedTo = "";
    this._updateToDoAfterChange(toDo);
    this._hideToDoAssigneePopdown();
  }
  // toDo Assignee Popdown

  _copyToDoLink(toDo: PM_ToDo) {
    let linkUrl = window.location.origin + '/#/main/project-management/kanban/open-board?id=' + this.pmCardModal.card.boardId + '&cardIdToOpen=' + this.pmCardModal.card.id + '&toDoIdToFocus=' + toDo.id;
    navigator.clipboard.writeText(linkUrl)
      .then(() => {
        this.pmCardModal.uiFeedBackCtrl.presentToast(`Copiado para Clipboard`, ``, `success`);
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erros', e)
      })
  }
  // -- to-do
  // to-do-lists

}
