export const EDITOR_ITEMS = [
  'fontSize',
  'removeFormat',
  '|',
  'bold',
  'italic',
  'underline',
  'highlight',
  '|',
  'link',
  'blockQuote',
  'imageInsert',
  'insertTable',
  'mediaEmbed',
  '|',
  'bulletedList',
  'numberedList',
  // 'todoList',
  '|',
  'undo',
  'redo',
  'findAndReplace'
];
export const EDITOR_ITEMS_EXTEND = [
  'heading',
  'fontFamily',
  'fontSize',
  'removeFormat',
  '|',
  'bold',
  'italic',
  'underline',
  'highlight',
  'strikethrough',
  'link',
  'bulletedList',
  'numberedList',
  // 'todoList',
  '-',
  'outdent',
  'indent',
  '|',
  'imageInsert',
  'blockQuote',
  'insertTable',
  'mediaEmbed',
  '|',
  'horizontalLine',
  'pageBreak',
  'restrictedEditingException',
  '|',
  'undo',
  'redo',
  'findAndReplace',
  '|'
];
export const EDITOR_ITEMS_ADVANCED = [
  'heading',
  'fontFamily',
  'fontSize',
  'removeFormat',
  '|',
  'bulletedList',
  'numberedList',
  // 'todoList',
  '|',
  'outdent',
  'indent',
  '|',
  'imageInsert',
  'mediaEmbed',
  '|',
  'horizontalLine',
  'pageBreak',
  'restrictedEditingException',
  '|',
  '-',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'subscript',
  'superscript',
  '|',
  'highlight',
  'fontBackgroundColor',
  'fontColor',
  'alignment',
  '|',
  'link',
  'blockQuote',
  'insertTable',
  'specialCharacters',
  '|',
  'undo',
  'redo',
  'findAndReplace',
  '|'
];

export const EditorConfig = {
  toolbar: {
    items: [
      // 'heading',
      // 'fontFamily',
      // 'fontSize',
      // 'removeFormat',
      // '|',
      // 'bulletedList',
      // 'numberedList',
      // // 'todoList',
      // '|',
      // 'outdent',
      // 'indent',
      // '|',
      // 'imageInsert',
      // 'mediaEmbed',
      // '|',
      // 'horizontalLine',
      // 'pageBreak',
      // 'restrictedEditingException',
      // '|',
      // '-',
      // 'bold',
      // 'italic',
      // 'underline',
      // 'strikethrough',
      // 'subscript',
      // 'superscript',
      // '|',
      // 'highlight',
      // 'fontBackgroundColor',
      // 'fontColor',
      // 'alignment',
      // '|',
      // 'link',
      // 'blockQuote',
      // 'insertTable',
      // 'specialCharacters',
      // '|',
      // 'undo',
      // 'redo',
      // 'findAndReplace',
      // '|'
    ],
    shouldNotGroupWhenFull: true
  },
};
export const EditorConfgRestricted = {
  toolbar: {
    items: [
      'heading',
      '|',
      'fontSize',
      'fontFamily',
      '|',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'alignment',
      '|',
      'numberedList',
      'bulletedList',
      '|',
      'outdent',
      'indent',
      '|',
      'todoList',
      'link',
      'blockQuote',
      'imageUpload',
      'insertTable',
      'mediaEmbed',
      '|',
      'undo',
      'redo'
    ]
  },
};
