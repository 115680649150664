import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export class ExecelSheet {
    sheet_name: string;
    sheet_data: any[];
    public constructor(init?: Partial<ExecelSheet>) {
        this.sheet_name = '';
        this.sheet_data = [];
        if (init) {
            Object.assign(this, init);
        }
    }
}
export class ExecelFile {
    sheets: ExecelSheet[];
    name: string;
    public constructor(init?: Partial<ExecelFile>) {
        this.name = '';
        this.sheets = [];
        if (init) {
            Object.assign(this, init);
        }
    }
}

@Injectable()
export class ExcelService {
    constructor() { }
    public exportAsExcelFile(data: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }
    public exportAsExcelFileWithSheets(execelFile: ExecelFile): void {
        const workbook: XLSX.WorkBook = { Sheets: {}, SheetNames: [] };
        for (let sheet of execelFile.sheets) {
            workbook.Sheets[sheet.sheet_name] = XLSX.utils.json_to_sheet(sheet.sheet_data);
            workbook.SheetNames.push(sheet.sheet_name);
        }
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, execelFile.name);
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().toISOString() + EXCEL_EXTENSION);
    }
}

// src => https://medium.com/@madhavmahesh/exporting-an-excel-file-in-angular-927756ac9857

// use exemple:

// HTML
// <p>Exporting an excel file of type .xslx !!!</p>
// <p>Export as XLSX by clicking the below button</p>
// <button (click)="exportAsXLSX()"><i class="fa fa-file-excel-o" style="font-size:48px;color:blue"></i></button>
// <table>
//  <tr>
//    <td>Eid</td>
//    <td>Ename</td>
//    <td>Esal</td>
//  </tr>
//  <tr *ngFor="let item of data">
//    <td>{{item.eid}}</td>
//    <td>{{item.ename}}</td>
//    <td>{{item.esal}}</td>
//  </tr>
// </table>

// TS
// import { Component } from '@angular/core';
// import { ExcelService } from './services/excel.service';
// @Component({
//     selector: 'my-app',
//     templateUrl: './app.component.html',
//     styleUrls: ['./app.component.css']
// })
// export class AppComponent {
//     data: any = [{
//         eid: 'e101',
//         ename: 'ravi',
//         esal: 1000
//     }, {
//         eid: 'e102',
//         ename: 'ram',
//         esal: 2000
//     }, {
//         eid: 'e103',
//         ename: 'rajesh',
//         esal: 3000
//     }];
//     constructor(private excelService: ExcelService) {
//     }
//     exportAsXLSX(): void {
//         this.excelService.exportAsExcelFile(this.data, 'sample');
//     }
// }
