import { Inject, Injectable } from "@angular/core";
import { Action, AngularFirestore, CollectionReference, DocumentSnapshot, FieldPath, Query } from '@angular/fire/compat/firestore';
import { OrderByDirection, WhereFilterOp } from "@firebase/firestore-types";
import firebase from "firebase/compat/app";
import { Observable } from "rxjs";

import { _localSort } from "app/@core/services";

export class DataChanges<T> {
  docs: T[];
  added: T[];
  modified: T[];
  removed: T[];
  public constructor(init?: Partial<DataChanges<T>>) {
    this.docs = [];
    this.added = [];
    this.modified = [];
    this.removed = [];
    if (init)
      Object.assign(this, init);
  }
}

// export class Query<T> {
//   constructor(
//     private afs: AngularFirestore,
//     @Inject('COLLECTION') private COLLECTION: string,
//     @Inject(`orderByFieldPath`) private orderByFieldPath: string | string[],
//     @Inject(`orderByDirectionStr`) private orderByDirectionStr: OrderByDirection = 'asc'
//   ) { }
//   public where(
//     fieldPath: string | FieldPath,
//     opStr: WhereFilterOp,
//     value: any
//   ): Query<T> {
//     return this.afs.collection(this.COLLECTION).ref
//       .where(fieldPath, opStr, value)
//
//   }
// }

@Injectable()
export class CollectionCRUD<T> {

  private SERIAL_COLLECTION = 'Serials';
  private lastSetSerialsByExternalTransaction = {};
  private observers = [];
  public ref: CollectionReference<T> | Query<T> = this.afs.collection<T>(this.COLLECTION).ref;

  /**
   * Coleção generica
   * @param afs referencia ao AngularFirestore
   * @param COLLECTION Coleção
   * @param orderByFieldPath Propriedade(s) que sera utilizada para ordernar os objetos coleção
   * @param orderByDirectionStr Opcional direção para organizar ('asc' or 'desc'). If not specified, order will be ascending.
   */
  constructor(
    private afs: AngularFirestore,
    @Inject('COLLECTION') private COLLECTION: string,
    @Inject(`orderByFieldPath`) private orderByFieldPath: string | string[],
    @Inject(`orderByDirectionStr`) private orderByDirectionStr: OrderByDirection = 'asc'
  ) { }

  public compareTimeStamps(
    timeStampA: { nanoseconds: number; seconds: number } | null | undefined,
    timeStampB: { nanoseconds: number; seconds: number } | null | undefined
  ): boolean {
    if (!timeStampA && !timeStampB)
      return false; // Ambos são nulos ou indefinidos, sem alteração

    if (!timeStampA || !timeStampB)
      return true; // Um é nulo ou indefinido e o otro não, houve alteração

    // Compara os valores de `seconds` e `nanoseconds`
    return (
      timeStampA.seconds !== timeStampB.seconds ||
      timeStampA.nanoseconds !== timeStampB.nanoseconds
    );
  }
  public advancedProcessQuerySnapshot(oldData: T[], querySnapshot: firebase.firestore.QuerySnapshot<T>, sort: boolean = true, onlyEmitOnUpdatedChange: boolean = true): { hasChange: boolean, data: T[] } {
    let docChanges = querySnapshot.docChanges();
    let newData: T[] = [...oldData];
    let hasChange = false;

    if (docChanges.length > 0) {
      // Processa mudanças
      docChanges.forEach((change) => {

        const docData = change.doc.data();
        const existingIndex = oldData.findIndex((oldDoc) => oldDoc['id'] === docData['id']);

        if (change.type === "added") {
          if (existingIndex === -1) {
            newData.push(docData);
            hasChange = true;
          }
        } else if (change.type === "modified") {
          if (existingIndex > -1) {
            const oldDocData = oldData[existingIndex];
            // Verifica se o campo updatedOn mudou
            if (
              !onlyEmitOnUpdatedChange ||
              this.compareTimeStamps(oldDocData['updatedOn'], docData['updatedOn'])
            ) {
              newData[existingIndex] = docData;
              hasChange = true;
            }
          }
        } else if (change.type === "removed") {
          if (existingIndex > -1) {
            newData.splice(existingIndex, 1);
            hasChange = true;
          }
        }

      });
    }

    if (hasChange && sort)
      return { hasChange, data: _localSort(newData, this.orderByFieldPath, this.orderByDirectionStr) };
    return { hasChange, data: newData };
  }

  public processQuerySnapshot(querySnapshot: firebase.firestore.QuerySnapshot<T>, sort: boolean = true): T[] {
    let tempData: T[] = [];
    for (let doc of querySnapshot.docs) {
      tempData.push(doc.data() as T);
    }
    if (sort)
      return _localSort(tempData, this.orderByFieldPath, this.orderByDirectionStr);
    return tempData;
  }

  // public where(
  //   fieldPath: string | FieldPath,
  //   opStr: WhereFilterOp,
  //   value: any
  // ): Query<T> {
  // }

  public getAll(sort: boolean = true): Promise<T[]> {
    return new Promise((resolve, reject) => {
      let orderByFieldPath = "";
      if (Array.isArray(this.orderByFieldPath))
        orderByFieldPath = this.orderByFieldPath[0];
      else
        orderByFieldPath = this.orderByFieldPath;
      this.afs.collection<T>(this.COLLECTION)
        .ref
        .orderBy(orderByFieldPath, this.orderByDirectionStr)
        .get({ source: 'server' })
        .then(querySnapshot =>
          resolve(this.processQuerySnapshot(querySnapshot, sort))
        )
        .catch((e) => {
          reject(e)
        })
    })
  }

  /**
   * Returns the documents for the additional filter
   *
   * @param fieldPath The path to compare
   * @param opStr The operation string (e.g "<", "<=", "==", ">", ">=").
   * @param value The value for comparison
   * @return The created Query.
   */
  public getDataByWhere(
    fieldPath: string | FieldPath,
    opStr: WhereFilterOp,
    value: any,
    sort: boolean = true
  ): Promise<T[]> {
    return new Promise((resolve, reject) => {
      this.afs.collection<T>(this.COLLECTION).ref
        .where(fieldPath, opStr, value)
        .get({ source: 'server' })
        .then(querySnapshot =>
          resolve(this.processQuerySnapshot(querySnapshot, sort))
        )
        .catch((e) => {
          reject(e)
        })
    })
  }
  public getDataByWheres(
    fieldPath: string | FieldPath,
    opStr: WhereFilterOp,
    values: any[],
    sort: boolean = true
  ): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const uniqueValues = values.filter((elem, index, self) => {
        return index == self.indexOf(elem);
      });
      let tempPromises = [];
      uniqueValues.forEach(value => {
        tempPromises.push(
          new Promise((resolve2, reject2) => {
            return this.afs.collection<T>(this.COLLECTION).ref
              .where(fieldPath, opStr, value)
              .get({ source: 'server' })
              .then(querySnapshot =>
                resolve2(this.processQuerySnapshot(querySnapshot, sort))
              )
              .catch(e => reject2(e))
          })
        )
      })
      Promise.all(tempPromises)
        .then(data => {
          let tempData: T[] = [];
          data.forEach(d => {
            tempData.push(...d)
          })
          resolve(tempData);
        })
        .catch(e => reject(e))
    })
  }

  public getAllActive(sort: boolean = true): Promise<T[]> {
    return new Promise((resolve, reject) => {
      this.afs.collection<T>(this.COLLECTION).ref
        .where('status', '==', 1)
        .get({ source: 'server' })
        .then(querySnapshot =>
          resolve(this.processQuerySnapshot(querySnapshot, sort))
        )
        .catch((e) => {
          reject(e)
        })
    })
  }

  /**
   * Listen to snapshot updates from the document.
   */
  public snapshotChanges(id: string): Observable<Action<DocumentSnapshot<T>>> {
    return this.afs.collection(this.COLLECTION).doc<T>(id).snapshotChanges();
  }

  // Value Changes
  /**
   * Listen to unwrapped snapshot updates from the document.
   *
   * If the `idField` option is provided, document IDs are included and mapped to the
   * provided `idField` property name.
   */
  public valueChanges(id: string): Observable<T | undefined> {
    return this.afs.collection(this.COLLECTION).doc<T>(id).valueChanges();
  }
  public valueChangesByWhere(
    fieldPath: string | FieldPath,
    opStr: WhereFilterOp,
    value: any,
    sort: boolean = true): Observable<DataChanges<T>> {
    return new Observable<DataChanges<T>>(subscriber => {
      // Keep track of the Documents Changes
      const firebaseObservableUnsubscriber = this.afs
        .collection<T>(this.COLLECTION).ref
        .where(fieldPath, opStr, value)
        .onSnapshot(
          querySnapshot => {
            let dataChanges = new DataChanges<T>();

            querySnapshot.docs
              .forEach(doc => {
                dataChanges.docs.push(doc.data() as T)
              });

            querySnapshot.docChanges()
              .forEach(change => {
                if (change.type === 'added') {
                  dataChanges.added.push(change.doc.data() as T)
                }
                if (change.type === 'modified') {
                  dataChanges.modified.push(change.doc.data() as T)
                }
                if (change.type === 'removed') {
                  dataChanges.removed.push(change.doc.data() as T)
                }
              });

            if (sort) {
              dataChanges.docs = _localSort(dataChanges.docs, this.orderByFieldPath, this.orderByDirectionStr);
              dataChanges.added = _localSort(dataChanges.added, this.orderByFieldPath, this.orderByDirectionStr);
              dataChanges.modified = _localSort(dataChanges.modified, this.orderByFieldPath, this.orderByDirectionStr);
              dataChanges.removed = _localSort(dataChanges.removed, this.orderByFieldPath, this.orderByDirectionStr);
            }

            subscriber.next(dataChanges);
          },
          (error) => {
            subscriber.error(error);
          }
        );

      // Provide a way of canceling and disposing the interval resource
      return function unsubscribe() {
        firebaseObservableUnsubscriber();
      };
    });
  }
  // Value Changes

  public get(id: string): Promise<DocumentSnapshot<T>> {
    return new Promise((resolve, reject) => {
      this.afs.collection(this.COLLECTION).doc(id).ref
        .get({ source: 'server' })
        .then((doc) => resolve(doc as DocumentSnapshot<T>))
        .catch((e) => {
          reject(e)
        })
    })
  }
  public getDataById(id: string): Promise<T> {
    return new Promise((resolve, reject) => {
      this.afs.collection(this.COLLECTION).doc(id).ref
        .get({ source: 'server' })
        .then(doc => {
          if (doc.exists)
            resolve(doc.data() as T);
          else
            reject({ message: `error at getDataById, doc: ${this.COLLECTION}/${id} not found!` })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
  public getDataByIds(ids: string[], sort: boolean = true): Promise<T[]> {
    return new Promise((resolve, reject) => {
      let uniqueIds = ids.filter((elem, index, self) => {
        return index == self.indexOf(elem);
      })
      uniqueIds = uniqueIds.filter(id => { return id != '' });
      let tempPromises = [];
      uniqueIds.forEach(id => {
        tempPromises.push(
          new Promise((resolve, reject) => {
            return this.afs.collection(this.COLLECTION).doc(id).ref.get({ source: 'server' })
              .then(d => resolve(d))
              .catch(e => reject(e))
          })
        )
      })
      Promise.all(tempPromises)
        .then(docs => {
          let tempData: T[] = [];
          for (let doc of docs) {
            if (doc.exists)
              tempData.push(doc.data() as T);
          }
          let data = tempData;
          if (sort)
            data = _localSort(tempData, this.orderByFieldPath, this.orderByDirectionStr);
          resolve(data);
        })
        .catch(e => reject(e))
    })
  }

  public create(id: string, data: T): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afs.collection(this.COLLECTION).doc(id).ref.get()
        .then(doc => {
          if (!doc.exists) {
            resolve(this.afs.collection(this.COLLECTION).doc(id).set(Object.assign({}, data)));
          } else {
            reject({ message: `Id: ${this.COLLECTION}/${id} not available!` });
          }
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
  public set(id: string, data: T): Promise<void> {
    return this.afs.collection(this.COLLECTION).doc(id).set(Object.assign({}, data));
  }
  private registerNextSerial(transactionId: string, collection: string, nextSerial: number): number {
    if (this.lastSetSerialsByExternalTransaction[transactionId] && this.lastSetSerialsByExternalTransaction[transactionId][collection]) {
      this.lastSetSerialsByExternalTransaction[transactionId][collection] = this.lastSetSerialsByExternalTransaction[transactionId][collection] + 1;
    } else {
      if (!this.lastSetSerialsByExternalTransaction[transactionId])
        this.lastSetSerialsByExternalTransaction[transactionId] = {};
      this.lastSetSerialsByExternalTransaction[transactionId][collection] = nextSerial;
    }
    return this.lastSetSerialsByExternalTransaction[transactionId][collection];
  }
  public createWithSerial(data: T, transaction?: firebase.firestore.Transaction, transactionId?: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (transaction && !transactionId)
        reject({ message: 'transactionId Required' })
      if (!transaction && transactionId)
        reject({ message: 'transaction Required' })

      const serialRef = this.afs.firestore.collection(this.SERIAL_COLLECTION).doc(this.COLLECTION);

      if (transaction) {
        return transaction.get(serialRef)
          .then((serialDoc) => {
            let nextSerial = 1;
            if (serialDoc.exists)
              nextSerial = this.registerNextSerial(transactionId, this.COLLECTION, serialDoc.data().lastSerial + 1);
            else
              nextSerial = this.registerNextSerial(transactionId, this.COLLECTION, 1);
            const newId = nextSerial.toString();
            const newDocRef = this.afs.firestore.collection(this.COLLECTION).doc(newId);
            data['id'] = newId;
            transaction.set(newDocRef, Object.assign({}, data));
            transaction.set(serialRef, { lastSerial: nextSerial });
            resolve(newId);
          });

      } else {
        this.afs.firestore.runTransaction((t) => {
          return t.get(serialRef)
            .then((serialDoc) => {
              let nextSerial = 1;
              if (serialDoc.exists)
                nextSerial = serialDoc.data().lastSerial + 1;
              const newId = nextSerial.toString();
              const newDocRef = this.afs.firestore.collection(this.COLLECTION).doc(newId);
              data['id'] = newId;
              t.set(newDocRef, Object.assign({}, data));
              t.set(serialRef, { lastSerial: nextSerial });
              return Promise.resolve(newId);
            });
        })
          .then(newId => {
            resolve(newId);
          })
          .catch((e) => {
            reject(e);
          });
      }
    })
  }
  /**
  * Reserva um novo ID serial para uma coleção específica no Firestore.
  *
  * @returns Uma Promise que resolve para o novo ID serial (string).
  * @throws Error Se o documento de controle serial não existir ou se ocorrer outro erro.
  */
  public async reserveSerial(): Promise<string> {
    const serialRef = this.afs.firestore.collection(this.SERIAL_COLLECTION).doc(this.COLLECTION); // Caminho mais descritivo

    try {
      const newId = await this.afs.firestore.runTransaction(
        async (transaction) => {
          const serialDoc = await transaction.get(serialRef);

          let nextSerial = 1;
          if (serialDoc.exists)
            nextSerial = serialDoc.data().lastSerial + 1;

          transaction.set(serialRef, { lastSerial: nextSerial });
          return nextSerial.toString(); // Retorna o ID serial formatado
        }
      );

      return newId; // Retorna o ID serial fora da transação

    } catch (error) {
      throw new Error(`Erro ao reservar serial para ${this.SERIAL_COLLECTION}/${this.COLLECTION}: ${error.message}`);
    }
  }
  /**
   * Reserva múltiplos IDs seriais para uma coleção específica no Firestore.  
   * @param count O número de IDs seriais a serem reservados.
   * @returns Uma Promise que resolve para um array de IDs seriais (strings).
   * @throws Error Se o documento de controle serial não existir ou se ocorrer outro erro.
   */
  async reserveSerials(count: number): Promise<string[]> {
    if (count == 0)
      return [];

    const serialRef = this.afs.firestore.collection(this.SERIAL_COLLECTION).doc(this.COLLECTION); // Caminho mais descritivo

    try {
      const serials = await this.afs.firestore.runTransaction(
        async (transaction) => {
          const serialDoc = await transaction.get(serialRef);

          let startSerial = 1;
          if (serialDoc.exists)
            startSerial = serialDoc.data().lastSerial + 1;

          const endSerial = startSerial + count - 1; // Calcula o último serial
          transaction.set(serialRef, { lastSerial: endSerial }); // Atualiza com o último

          const serialsArray: string[] = [];
          for (let i = startSerial; i <= endSerial; i++) {
            serialsArray.push(i.toString()); // Formata cada ID
          }
          return serialsArray;
        }
      );

      return serials; // Retorna os IDs serial fora da transação

    } catch (error) {
      throw new Error(`Erro ao reservar seriais para ${this.SERIAL_COLLECTION}/${this.COLLECTION}: ${error.message}`);
    }
  }


  public delete(id: string): Promise<void> {
    return this.afs.collection(this.COLLECTION).doc(id).delete();
  }

  public update(id: string, data: Partial<T>, transaction?: firebase.firestore.Transaction, transactionId?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (transaction && !transactionId)
        reject({ message: 'transactionId Required' })
      if (!transaction && transactionId)
        reject({ message: 'transaction Required' })

      if (transaction) {
        transaction.update(this.afs.firestore.collection(this.COLLECTION).doc(id), data)
        resolve();
      } else {
        resolve(this.afs.collection(this.COLLECTION).doc(id).update(data));
      }
    })
  }

  public add(data: T[]): Promise<void> {
    return new Promise((resolve, reject) => {
      let promises = [];
      data.forEach(d => {
        promises.push(
          this.afs.collection(this.COLLECTION).add(Object.assign({}, d))
        )
      })
      Promise.all(promises)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
}
