import { DB_DataResult, DB_FieldData } from "app/@firebase";
import { ThemeService } from "app/@theme/theme.service";

import { MagicTableColumn } from "../@magic-table";
import { AxisCalculationType, AxisCategoryType, DataVisualization, VisAxisData, VisDataCube, VisEchartsOptions, VisMagicTableOptions, VisType } from "./types";
import { genFilterValue, stringToColourHex } from "./util";

// ICONS from ->  https://icons8.com

// ------ Egine Data ------

// ------ AXIS_CALCULATION_TYPES
export const AXIS_CALCULATION_TYPES: AxisCalculationType[] = [
  {
    id: 'count',
    name: 'Contagem',
    dataCubeMaker: (data: any[], labelsFieldId: string, valueFieldId: string, categoriesFieldId = null): VisDataCube => {
      let result = new VisDataCube();
      let index = new Map<string, number>();
      let categoriesDataCubeIndex = {};

      for (let d of data) {

        let label = d[labelsFieldId];
        let value = Number(d[valueFieldId]);
        let category = '';
        if (categoriesFieldId != null) {
          category = d[categoriesFieldId];
          category = genFilterValue(category);
        }

        if (label !== undefined && value !== undefined) {
          label = genFilterValue(label);
          if (index.get(label))
            index.set(label, index.get(label) + 1);
          else
            index.set(label, 1);
        }

        if (categoriesFieldId != null) {
          if (!categoriesDataCubeIndex[label])
            categoriesDataCubeIndex[label] = new Map<string, number>();

          if (categoriesDataCubeIndex[label].get(category))
            categoriesDataCubeIndex[label].set(category, categoriesDataCubeIndex[label].get(category) + 1);
          else {
            categoriesDataCubeIndex[label].set(category, 1);
            result.categoriesDataCube.set(category, []);
          }
        }

      }

      index
        .forEach((value, key) => {
          result.labels.push(key);
          result.values.push(value);
        })

      if (Object.entries(categoriesDataCubeIndex).length > 0) {
        result.categoriesDataCube
          .forEach((value, key) => {
            result.labels
              .forEach(main_label => {
                let hasValue = false;
                categoriesDataCubeIndex[main_label]
                  .forEach((value2, key2) => {
                    if (key == key2) {
                      value.push(value2);
                      hasValue = true;
                    }
                  });
                if (!hasValue)
                  value.push(0);
              });
          });
      }

      return result;
    }
  },
  {
    id: 'distinct_count',
    name: 'Contagem Distinta',
    dataCubeMaker: (data: any[], labelsFieldId: string, valueFieldId: string, categoriesFieldId = null): VisDataCube => {
      let result = new VisDataCube();
      let index = new Map<string, number>();
      let categoriesDataCubeIndex = {};

      let index_values: string[] = [];
      let categoriesIndex_values = {};

      for (let d of data) {

        let label = d[labelsFieldId];
        let value = d[valueFieldId] + '';
        let category = '';
        if (categoriesFieldId != null) {
          category = d[categoriesFieldId];
          category = genFilterValue(category);
        }

        if (label !== undefined && value !== undefined) {
          label = genFilterValue(label);
          if (index.get(label)) {
            if (index_values.indexOf(value) == -1) {
              index.set(label, index.get(label) + 1);
              index_values.push(value);
            }
          } else {
            index.set(label, 1);
            index_values.push(value);
          }
        }

        if (categoriesFieldId != null) {
          if (!categoriesDataCubeIndex[label]) {
            categoriesDataCubeIndex[label] = new Map<string, number>();
            categoriesIndex_values[label] = [];
          }

          if (categoriesDataCubeIndex[label].get(category)) {
            if (categoriesIndex_values[label].indexOf(value) == -1) {
              categoriesDataCubeIndex[label].set(category, categoriesDataCubeIndex[label].get(category) + 1);
              categoriesIndex_values[label].push(value);
            }
          } else {
            categoriesDataCubeIndex[label].set(category, 1);
            categoriesIndex_values[label].push(value);
            result.categoriesDataCube.set(category, []);
          }
        }

      }

      index
        .forEach((value, key) => {
          result.labels.push(key);
          result.values.push(value);
        })

      if (Object.entries(categoriesDataCubeIndex).length > 0) {
        result.categoriesDataCube
          .forEach((value, key) => {
            result.labels
              .forEach(main_label => {
                let hasValue = false;
                categoriesDataCubeIndex[main_label]
                  .forEach((value2, key2) => {
                    if (key == key2) {
                      value.push(value2);
                      hasValue = true;
                    }
                  });
                if (!hasValue)
                  value.push(0);
              });
          });
      }

      return result;
    }
  },
  {
    id: 'average',
    name: 'Média',
    dataCubeMaker: (data: any[], labelsFieldId: string, valueFieldId: string, categoriesFieldId = null) => {
      let result = new VisDataCube();

      let count_index = new Map<string, number>();
      let count_categoriesDataCubeIndex = {};
      let sum_index = new Map<string, number>();
      let sum_categoriesDataCubeIndex = {};

      let index = new Map<string, number>();
      let categoriesDataCubeIndex = {};

      for (let d of data) {

        let label = d[labelsFieldId];
        let value = Number(d[valueFieldId]);
        let category = '';
        if (categoriesFieldId != null) {
          category = d[categoriesFieldId];
          category = genFilterValue(category);
        }

        if (label !== undefined && value !== undefined) {
          label = genFilterValue(label);
          // count
          if (count_index.get(label))
            count_index.set(label, count_index.get(label) + 1);
          else
            count_index.set(label, 1);
          // count

          // sum
          if (sum_index.get(label))
            sum_index.set(label, sum_index.get(label) + value);
          else
            sum_index.set(label, value);
          // sum
        }

        if (categoriesFieldId != null) {
          // count
          if (!count_categoriesDataCubeIndex[label])
            count_categoriesDataCubeIndex[label] = new Map<string, number>();

          if (count_categoriesDataCubeIndex[label].get(category))
            count_categoriesDataCubeIndex[label].set(category, count_categoriesDataCubeIndex[label].get(category) + 1);
          else {
            count_categoriesDataCubeIndex[label].set(category, 1);
            result.categoriesDataCube.set(category, []);
          }
          // count

          // sum
          if (!sum_categoriesDataCubeIndex[label])
            sum_categoriesDataCubeIndex[label] = new Map<string, number>();

          if (sum_categoriesDataCubeIndex[label].get(category))
            sum_categoriesDataCubeIndex[label].set(category, sum_categoriesDataCubeIndex[label].get(category) + value);
          else
            sum_categoriesDataCubeIndex[label].set(category, value);
          // sum
        }

      }


      // average
      count_index
        .forEach((value, key) => {
          index.set(key, (sum_index.get(key) / value))
        })

      for (var [key, value] of Object.entries(count_categoriesDataCubeIndex)) {
        categoriesDataCubeIndex[key] = new Map<string, number>();
        let v = value as any;
        v
          .forEach((value2, key2) => {
            categoriesDataCubeIndex[key].set(key2, (sum_categoriesDataCubeIndex[key].get(key2) / value2))
          })
      }
      // average


      index
        .forEach((value, key) => {
          result.labels.push(key);
          result.values.push(value);
        })

      if (Object.entries(categoriesDataCubeIndex).length > 0) {
        result.categoriesDataCube
          .forEach((value, key) => {
            result.labels
              .forEach(main_label => {
                let hasValue = false;
                categoriesDataCubeIndex[main_label]
                  .forEach((value2, key2) => {
                    if (key == key2) {
                      value.push(value2);
                      hasValue = true;
                    }
                  });
                if (!hasValue)
                  value.push(0);
              });
          });
      }

      return result;
    }
  },
  {
    id: 'sum',
    name: 'Soma',
    dataCubeMaker: (data: any[], labelsFieldId: string, valueFieldId: string, categoriesFieldId = null) => {
      let result = new VisDataCube();
      let index = new Map<string, number>();
      let categoriesDataCubeIndex = {};

      for (let d of data) {

        let label = d[labelsFieldId];
        let value = Number(d[valueFieldId]);
        let category = '';
        if (categoriesFieldId != null) {
          category = d[categoriesFieldId];
          category = genFilterValue(category);
        }

        if (label !== undefined && value !== undefined) {
          label = genFilterValue(label);
          if (index.get(label))
            index.set(label, index.get(label) + value);
          else
            index.set(label, value);
        }

        if (categoriesFieldId != null) {
          if (!categoriesDataCubeIndex[label])
            categoriesDataCubeIndex[label] = new Map<string, number>();

          if (categoriesDataCubeIndex[label].get(category))
            categoriesDataCubeIndex[label].set(category, categoriesDataCubeIndex[label].get(category) + value);
          else {
            categoriesDataCubeIndex[label].set(category, value);
            result.categoriesDataCube.set(category, []);
          }
        }

      }

      index
        .forEach((value, key) => {
          result.labels.push(key);
          result.values.push(value);
        })

      if (Object.entries(categoriesDataCubeIndex).length > 0) {
        result.categoriesDataCube
          .forEach((value, key) => {
            result.labels
              .forEach(main_label => {
                let hasValue = false;
                categoriesDataCubeIndex[main_label]
                  .forEach((value2, key2) => {
                    if (key == key2) {
                      value.push(value2);
                      hasValue = true;
                    }
                  });
                if (!hasValue)
                  value.push(0);
              });
          });
      }

      return result;
    }
  },
  {
    id: 'min',
    name: 'Min.',
    dataCubeMaker: (data: any[], labelsFieldId: string, valueFieldId: string, categoriesFieldId = null) => {
      let result = new VisDataCube();
      let index = new Map<string, number>();
      let categoriesDataCubeIndex = {};

      for (let d of data) {

        let label = d[labelsFieldId];
        let value = Number(d[valueFieldId]);
        let category = '';
        if (categoriesFieldId != null) {
          category = d[categoriesFieldId];
          category = genFilterValue(category);
        }

        if (label !== undefined && value !== undefined) {
          label = genFilterValue(label);
          if (index.get(label)) {
            if (index.get(label) > value)
              index.set(label, value);
          } else
            index.set(label, value);
        }

        if (categoriesFieldId != null) {
          if (!categoriesDataCubeIndex[label])
            categoriesDataCubeIndex[label] = new Map<string, number>();

          if (categoriesDataCubeIndex[label].get(category)) {
            if (categoriesDataCubeIndex[label].get(category) > value)
              categoriesDataCubeIndex[label].set(category, value);
          } else {
            categoriesDataCubeIndex[label].set(category, value);
            result.categoriesDataCube.set(category, []);
          }
        }

      }

      index
        .forEach((value, key) => {
          result.labels.push(key);
          result.values.push(value);
        })

      if (Object.entries(categoriesDataCubeIndex).length > 0) {
        result.categoriesDataCube
          .forEach((value, key) => {
            result.labels
              .forEach(main_label => {
                let hasValue = false;
                categoriesDataCubeIndex[main_label]
                  .forEach((value2, key2) => {
                    if (key == key2) {
                      value.push(value2);
                      hasValue = true;
                    }
                  });
                if (!hasValue)
                  value.push(0);
              });
          });
      }

      return result;
    }
  },
  {
    id: 'max',
    name: 'Máx.',
    dataCubeMaker: (data: any[], labelsFieldId: string, valueFieldId: string, categoriesFieldId = null) => {
      let result = new VisDataCube();
      let index = new Map<string, number>();
      let categoriesDataCubeIndex = {};

      for (let d of data) {

        let label = d[labelsFieldId];
        let value = Number(d[valueFieldId]);
        let category = '';
        if (categoriesFieldId != null) {
          category = d[categoriesFieldId];
          category = genFilterValue(category);
        }

        if (label !== undefined && value !== undefined) {
          label = genFilterValue(label);
          if (index.get(label)) {
            if (index.get(label) < value)
              index.set(label, value);
          } else
            index.set(label, value);
        }

        if (categoriesFieldId != null) {
          if (!categoriesDataCubeIndex[label]) {
            categoriesDataCubeIndex[label] = new Map<string, number>();
          }
          if (categoriesDataCubeIndex[label].get(category)) {
            if (categoriesDataCubeIndex[label].get(category) < value)
              categoriesDataCubeIndex[label].set(category, value);
          } else {
            categoriesDataCubeIndex[label].set(category, value);
            result.categoriesDataCube.set(category, []);
          }
        }

      }

      index
        .forEach((value, key) => {
          result.labels.push(key);
          result.values.push(value);
        })

      if (Object.entries(categoriesDataCubeIndex).length > 0) {
        result.categoriesDataCube
          .forEach((value, key) => {
            result.labels
              .forEach(main_label => {
                let hasValue = false;
                categoriesDataCubeIndex[main_label]
                  .forEach((value2, key2) => {
                    if (key == key2) {
                      value.push(value2);
                      hasValue = true;
                    }
                  });
                if (!hasValue)
                  value.push(0);
              });
          });
      }

      return result;
    }
  },
];
function get_AXIS_CALCULATION_TYPE(id: string): AxisCalculationType {
  let data = null;
  let tempData = AXIS_CALCULATION_TYPES.filter(ct => { return ct.id == id; })[0];
  if (tempData)
    data = tempData;
  return data;
}
function get_AXIS_CALCULATION_TYPE_fromVis(vis: DataVisualization): AxisCalculationType {
  let calcTypeId = "";
  for (var [key, value] of Object.entries(vis.engine.data)) {
    for (var [key2, value2] of Object.entries(value)) {
      if (key2 == 'calcTypeId') {
        calcTypeId = value2 as any;
        break;
      }
    }
    if (calcTypeId != "")
      break;
  }
  return get_AXIS_CALCULATION_TYPE(calcTypeId);
}
// ------ AXIS_CALCULATION_TYPES

// ------ AXIS_CATEGORY_TYPES
export const AXIS_CATEGORY_TYPES: AxisCategoryType[] = [
  // bar
  {
    id: "stacked",
    visTypeId: "bar",
    name: "Stacked Bar Chart",
    description: "Uma única coluna de segunda dimensão é dividida por valores de terceira dimensão.",
    optionsMaker: (vis: DataVisualization, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService): VisEchartsOptions => {
      let newOptions = { ...options };
      const seriesType = 'bar';
      newOptions.series = [];
      newOptions._colorMap = new Map<string, string>();
      dataCube.categoriesDataCube
        .forEach((value, key) => {
          newOptions.series.push({
            name: key,
            data: value,
            type: seriesType,
            stack: 'stacked_bar'
          });
          newOptions._colorMap.set(key, stringToColourHex(key));
        });
      return newOptions;
    }
  },
  {
    id: "grouped",
    visTypeId: "bar",
    name: "Grouped Bar Chart",
    description: "Os valores da terceira dimensão são exibidos como colunas adjacentes no valor da segunda dimensão.",
    optionsMaker: (vis: DataVisualization, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService): VisEchartsOptions => {
      let newOptions = { ...options };
      const seriesType = 'bar';
      newOptions.series = [];
      newOptions._colorMap = new Map<string, string>();
      dataCube.categoriesDataCube
        .forEach((value, key) => {
          newOptions.series.push({
            name: key,
            data: value,
            type: seriesType,
          });
          newOptions._colorMap.set(key, stringToColourHex(key));
        });
      return newOptions;
    }
  },
  {
    id: "stacked_100",
    visTypeId: "bar",
    name: "100% Stacked Bar Chart",
    description: "Uma coluna de dimensão única é dividida pela porcentagem que cada valor de terceira dimensão contribui para o total.",
    optionsMaker: (vis: DataVisualization, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService): VisEchartsOptions => {
      let newOptions = { ...options };
      const seriesType = 'bar';
      newOptions.yAxis.maxInterval = 10;
      newOptions.yAxis.max = 100;
      newOptions.series = [];
      newOptions._colorMap = new Map<string, string>();
      dataCube.categoriesDataCube
        .forEach((value, key) => {
          let serieValues: number[] = [];
          for (let index = 0; index < value.length; index++) {
            const v = value[index];
            const totalValue = dataCube.values[index];
            serieValues.push((v / totalValue) * 100);
          }
          newOptions.series.push({
            name: key,
            data: serieValues,
            type: seriesType,
            stack: 'stacked_bar'
          });
          newOptions._colorMap.set(key, stringToColourHex(key));
        });
      return newOptions;

    }
  },
  // bar

  // line
  {
    id: "standard_line",
    visTypeId: 'line',
    name: "Standard Line Chart",
    description: "Mostra uma progressão quantitativa ao longo do tempo.",
    optionsMaker: (vis: DataVisualization, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService): VisEchartsOptions => {
      let newOptions = { ...options };
      const seriesType = 'line';
      newOptions.series = [];
      newOptions._colorMap = new Map<string, string>();
      dataCube.categoriesDataCube
        .forEach((value, key) => {
          newOptions.series.push({
            name: key,
            data: value,
            type: seriesType,
          });
          newOptions._colorMap.set(key, stringToColourHex(key));
        });
      return newOptions;
    }
  },
  {
    id: "stacked_line",
    visTypeId: "line",
    name: "Stacked Line Chart",
    description: "Uma única Linha de segunda dimensão é dividida por valores de terceira dimensão.",
    optionsMaker: (vis: DataVisualization, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService): VisEchartsOptions => {
      let newOptions = { ...options };
      const seriesType = 'line';
      newOptions.series = [];
      newOptions._colorMap = new Map<string, string>();
      dataCube.categoriesDataCube
        .forEach((value, key) => {
          newOptions.series.push({
            name: key,
            data: value,
            type: seriesType,
            stack: 'stacked_line'
          });
          newOptions._colorMap.set(key, stringToColourHex(key));
        });
      return newOptions;
    }
  },
  // line

  // area
  {
    id: "standard_area",
    visTypeId: 'area',
    name: "Standard Area Chart",
    description: "Mostra uma progressão quantitativa ao longo do tempo.",
    optionsMaker: (vis: DataVisualization, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService): VisEchartsOptions => {
      let newOptions = { ...options };
      const seriesType = 'line';
      newOptions.series = [];
      newOptions._colorMap = new Map<string, string>();
      dataCube.categoriesDataCube
        .forEach((value, key) => {
          newOptions.series.push({
            name: key,
            data: value,
            type: seriesType,
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
          });
          newOptions._colorMap.set(key, stringToColourHex(key));
        });
      return newOptions;
    }
  },
  {
    id: "stacked_area",
    visTypeId: 'area',
    name: "Stacked Area Chart",
    description: "Mostra a proporção do total que cada categoria representa em um determinado momento.",
    optionsMaker: (vis: DataVisualization, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService): VisEchartsOptions => {
      let newOptions = { ...options };
      const seriesType = 'line';
      newOptions.series = [];
      newOptions._colorMap = new Map<string, string>();
      dataCube.categoriesDataCube
        .forEach((value, key) => {
          newOptions.series.push({
            name: key,
            data: value,
            type: seriesType,
            stack: 'stacked_area',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
          });
          newOptions._colorMap.set(key, stringToColourHex(key));
        });
      return newOptions;
    }
  },
  {
    id: "stacked_area_100",
    visTypeId: 'area',
    name: "100% Stacked Area Chart",
    description: "Mostra como as partes constituintes de um todo mudaram ao longo do tempo. O eixo y se expande para 100% e cada área colorida representa uma parte do todo, com cada parte empilhada verticalmente. A altura de cada pilha colorida representa a proporção percentual de essa categoria em um determinado momento.",
    optionsMaker: (vis: DataVisualization, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService): VisEchartsOptions => {
      let newOptions = { ...options };
      const seriesType = 'line';
      newOptions.yAxis.maxInterval = 10;
      newOptions.yAxis.max = 100;
      newOptions.series = [];
      newOptions._colorMap = new Map<string, string>();
      dataCube.categoriesDataCube
        .forEach((value, key) => {
          let serieValues: number[] = [];
          for (let index = 0; index < value.length; index++) {
            const v = value[index];
            const totalValue = dataCube.values[index];
            serieValues.push((v / totalValue) * 100);
          }
          newOptions.series.push({
            name: key,
            data: serieValues,
            type: seriesType,
            stack: 'stacked_area',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
          });
          newOptions._colorMap.set(key, stringToColourHex(key));
        });
      return newOptions;
    }
  },
  // area
];
function get_AXIS_CATEGORY_TYPE(id: string): AxisCategoryType {
  let data = null;
  let tempData = AXIS_CATEGORY_TYPES.filter(ct => { return ct.id == id; })[0];
  if (tempData)
    data = tempData;
  return data;
}
function get_AXIS_CATEGORY_TYPE_fromVis(vis: DataVisualization): AxisCategoryType {
  let categorizeBy_catTypeId = "";
  if (vis.engine.data.categorizeBy && vis.engine.data.categorizeBy.catTypeId)
    categorizeBy_catTypeId = vis.engine.data.categorizeBy.catTypeId;
  return get_AXIS_CATEGORY_TYPE(categorizeBy_catTypeId);
}
// ------ AXIS_CATEGORY_TYPES

// ------ ENGINE_DATA_REF
export const ENGINE_DATA_REF = {
  xAxis: {
    id: 'xAxis',
    name: 'Eixo X',
    properties: [
      'fieldId',
    ]
  },
  yAxis: {
    id: 'yAxis',
    name: 'Eixo Y',
    properties: [
      'calcTypeId',
      'fieldId',
    ]
  },
  categorizeBy: {
    id: 'categorizeBy',
    name: 'Categorizar Por',
    properties: [
      'catTypeId',
      'fieldId',
    ]
  },
  categories: {
    id: 'categories',
    name: 'Categorias',
    properties: [
      'fieldId',
    ]
  },
  values: {
    id: 'values',
    name: 'Valores',
    properties: [
      'calcTypeId',
      'fieldId',
    ]
  },
};
// ------ ENGINE_DATA_REF
// ------ Egine Data ------

// ------ Egine Customize ------
// ------ ENGINE_CUSTOMIZE_REF
export const ENGINE_CUSTOMIZE_REF = {
  invertAxes: {
    id: 'invertAxes',
    name: 'Inverter Eixos'
  },
  hideLegend: {
    id: 'hideLegend',
    name: 'Ocultar Legenda'
  },
  colors: {
    id: 'colors',
    name: 'Cores'
  }
};
// ------ ENGINE_CUSTOMIZE_REF
// ------ Egine Customize ------



// ------ V I S ------
export function calcTypeDataCubeMaker(vis: DataVisualization, data: any[]): VisDataCube {
  let ct = get_AXIS_CALCULATION_TYPE_fromVis(vis);
  if (ct)
    return ct.dataCubeMaker(
      data,
      get_AXIS_DATA(vis).xAxis_fieldId,
      get_AXIS_DATA(vis).yAxis_fieldId,
      get_AXIS_DATA(vis).categorizeBy_fieldId
    );
  else
    return null;
}
export function optionsMaker(vis: DataVisualization, DB_DsDataResult: DB_DataResult, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService): VisEchartsOptions | VisMagicTableOptions {
  let vt = get_VIS_TYPE(vis.typeId);
  if (vt.id != '' && vt.engine.optionsMaker)
    return vt.engine.optionsMaker(vis, DB_DsDataResult, options, dataCube, theme);
  else
    return options;
}
export function customiseOptions(vis: DataVisualization, options: VisEchartsOptions): VisEchartsOptions {
  let vt = get_VIS_TYPE(vis.typeId);
  if (vt.id != '' && vt.engine.customiseOptions)
    return vt.engine.customiseOptions(vis, options);
  else
    return options;
}
// export function colorMapMaker(labels: string[]): Map<string, string> {
//   let colors: Map<string, string>;
//   labels.forEach(l => {
//     colors.set(l, stringToColourHex(l));
//   })
//   return colors;
// }
function colorMaker(colorMap: Map<string, string>): string[] {
  let color: string[] = [];
  colorMap.forEach((value, key) => {
    color.push(value);
  });
  return color;
}
export function get_AXIS_DATA(vis: DataVisualization): VisAxisData {
  let data = new VisAxisData();

  if (vis.engine.data.xAxis && vis.engine.data.xAxis.fieldId)
    data.xAxis_fieldId = vis.engine.data.xAxis.fieldId;
  if (vis.engine.data.categories && vis.engine.data.categories.fieldId)
    data.xAxis_fieldId = vis.engine.data.categories.fieldId;

  if (vis.engine.data.yAxis && vis.engine.data.yAxis.fieldId)
    data.yAxis_fieldId = vis.engine.data.yAxis.fieldId;
  if (vis.engine.data.values && vis.engine.data.values.fieldId)
    data.yAxis_fieldId = vis.engine.data.values.fieldId;

  if (vis.engine.data.categorizeBy && vis.engine.data.categorizeBy.fieldId)
    data.categorizeBy_fieldId = vis.engine.data.categorizeBy.fieldId;

  return data;
}
export const VIS_TYPES: VisType[] = [
  // Bar Chart
  {
    id: "bar",
    name: "Bar Chart",
    description: "Compara duas ou três variáveis usando um único valor. Um gráfico de barras é um gráfico com barras retangulares e o comprimento de cada barra é proporcional ao valor que ela representa.",
    base64Img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAABpklEQVR4nO2bu0oDQRRAT3zgo9GUCoKvUkTEyg/w8Rda2GqhtoKlla2I4BeIPyAI/oLYCIKdpYIgaqEWSSUx2Wx292zwHggsZGY4OWSykEwgYAY4A/psEYsN4BuYtEUMemwBmwhgC9hEAFvAJs9b3xCwD8wlHP8F3AAn9evCyOs2eFpft93HdsYeTclzC6yknLeaqUUL8gwwUPC8VPz7D8EIYAvYRABbwCYC2AI2EcAWsOmG7wHXgD1gNOH4Z+AIuEoyuOwBJoBLYLDNecvALPDUamDZt8AS7b94gGFgMcnAsgfo5B3an2RQ2QPkTgSwBWz+2mMHwCYwknCdR2AXuM5CqkgaBVgHDttcpwpcAGPAR6dSRdJoCyykXKsKTHXgotAoQG/G65WarhPOmghgC9hEAFvAJgLYAjYRwBawiQC2gE0EsAVsIoAtYBMBbAGbCGAL2EQAW8AmAtgCNhHAFrCJALaATQSwBWwq1P4zdA7cAp/UfuMfT7neHfBev54n4UGlX7wC9/XrKjCd0uUBeGny/BuwVaF2nu6YdMfRupl3YMeW0PkBDPAuugQq5NQAAAAASUVORK5CYII=',
    engine: {
      dataProps: [
        'xAxis',
        'yAxis',
        'categorizeBy',
      ],
      customizationProps: [
        'hideLegend',
        'invertAxes',
        'colors',
      ],
      type: 'echart',
      optionsMaker: (vis: DataVisualization, DB_DsDataResult: DB_DataResult, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService): VisEchartsOptions => {
        // Options
        let newOptions = { ...options };
        // Options

        // Maker
        const seriesType = 'bar';
        const calcType = get_AXIS_CALCULATION_TYPE_fromVis(vis);
        const catType = get_AXIS_CATEGORY_TYPE_fromVis(vis);

        if (dataCube != null) {
          newOptions.xAxis.data = [];
          newOptions.xAxis.data = dataCube.labels;
          if (dataCube.categoriesDataCube.size == 0 || catType == null) {
            newOptions.series = [];
            newOptions._colorMap = new Map<string, string>();
            let serie_name = calcType.name;
            newOptions.series.push({
              name: serie_name,
              data: dataCube.values,
              type: seriesType
            });
            newOptions._colorMap.set(serie_name, stringToColourHex(serie_name));
          } else {
            newOptions.title.subtext = `${calcType.name}`;
            newOptions = catType.optionsMaker(vis, newOptions, dataCube, theme);
          }
          newOptions.color = colorMaker(newOptions._colorMap);
        }
        // Maker

        return newOptions;
      },
      customiseOptions: (vis: DataVisualization, options: VisEchartsOptions): VisEchartsOptions => {
        // Options
        let newOptions = { ...options };
        // Options

        if (vis.engine && vis.engine.customize) {

          // hideLegend
          if (vis.engine.customize.hideLegend && vis.engine.customize.hideLegend == true) {
            newOptions.legend = null;
          }
          // hideLegend

          // invertAxes
          if (vis.engine.customize.invertAxes && vis.engine.customize.invertAxes == true) {
            let x = { ...newOptions.xAxis };
            let y = { ...newOptions.yAxis };
            newOptions.xAxis = y as any;
            newOptions.yAxis = x as any;
          }
          // invertAxes

          // colors
          if (vis.engine.customize.colors && Object.entries(vis.engine.customize.colors).length > 0 && newOptions._colorMap.size > 0) {
            newOptions.color = [];
            newOptions._colorMap
              .forEach((value, key) => {
                if (vis.engine.customize.colors[key])
                  newOptions.color.push(vis.engine.customize.colors[key] + "");
                else
                  newOptions.color.push(value + "");
              });
          }
          // colors

        }

        return newOptions;
      }
    }
  },
  // Bar Chart

  // Line Chart
  {
    id: "line",
    name: "Line Chart",
    description: "Mostra tendências ou mudanças ao longo do tempo exibindo uma série de pontos de dados conectados por segmentos de linha reta. Você pode exibir uma ou mais séries em um gráfico de linhas.",
    base64Img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAD+klEQVR4nO3ZS2hdVRTG8V+TNKQa3yWgIkYFNbU+EDFExYGdaMGBKBYUQYwDXwMHagciDjrrRGyd1Re+sIMqgnUgYhGpFVSkWBSq4kChvotNo8a0jYN1YtN7z0nuPnmc6+35wx3cc/dee+3v7L322utSU1NTU3N8M4Kv0F+1I1XQhYtwMVZW7EsldFXtQNXUAlTtQNXUAlTtQNXUAlTtQNXUAlTtQNV0sgA9GMaQWebZqQJcga/xMb7ELpxT1PhuTGFwSVxbfLrxjZjTzM+7eY3bcQX0oXce/S/DBTnP18i58baTAGfibfyJcbyEk0rYmSh4fgiTeT+0yxbYqXnZvlrCzjKx/xttvVjUoR0EGNLs8JR4Yycm2roFR0QcOIK/8LyC1dRTzt8FZ0XB8x4sT7AziOfwCa7L+k4qWPrTtMMKWI4/NK+ATxNt7MJ+nJcyeDsI8ETmw6+OTv6gCGhDLdrYmPW7NXXwVAF68SBex2ZRT5wPN+KwiPq9okh7FQbwG95rwcZasd+fKeNAigDLsN2xy/SgcLgMg2KSn8uPA/dlY6ybxcbZ+CWz0VfGiRQBbpAfrbeXGHcFPsPvOL+gTbeIAz/IL9t34wMcwIUlfECaAA/IF+DbEuO+IJb+2jnaDWftNub8tiEb/84S4/9HigDXyxfgrcQxp5f2ky2234J/sGrGszVCmGcTx24iNQhudezk9+PShPGG8bdIe1tNxVeKrbIbm0Sysw97cELC2LmkCtCFj8TED2CH1icygO/Fljktwcd1IpefKfw4LkmwUUiqAGeIN/gURrO+j7XQrwfvC8cvT/CvCz9p3nbjOCXBTiGpAjyctV+dfd8q9ufVc/SbTlTuSvTvLPlxZwpXJtrKJVWAL0TKOc2p+E5cPk4u6HOb8olKj9j/jZOfwOkl7DWRIsBw1vbehucj4sLxSk6fIRErdipf6LhfswAbStpqIkWALRiTf7V8PLMzikfFKtkhAt6PImObDzeJ+sA2s2eGybQqQL94k0XnbpcIcnl7dfOCeLpItCrAPVm7kVnarJYvwJj51fkWjZSa4KijJeYiiqo3/YoDZKW0KsAqXGPutHOPKGw0slfc9duOVgUYFWf9y3O0GxcXppklqDHNp0bbMFtN8HZxfvfhWnHhaeUtviausDeLjPENkbe3LXlB8BHNgezppXdtaWgUoFccd40CHLJAmVc7kRcDBuQnOt04d3HdWXryBNgnMrdGxsQ/Lh1FngCH8ZBY8tNMYb0ogHYURafANlHpvUP84fAmPlwqp5aS2Y7B3dmno2mnv8croRagageq5rgXYGYQXC//JtepTGBTj0hufrbAZab/AZN4p2onampqamqq5F+5hR1oZTbUogAAAABJRU5ErkJggg==',
    engine: {
      dataProps: [
        'xAxis',
        'yAxis',
        'categorizeBy',
      ],
      customizationProps: [
        'hideLegend',
        'invertAxes',
        'colors',
      ],
      type: 'echart',
      optionsMaker: (vis: DataVisualization, DB_DsDataResult: DB_DataResult, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService): VisEchartsOptions => {
        // Options
        let newOptions = { ...options };
        newOptions.xAxis.boundaryGap = false;
        newOptions.tooltip.axisPointer.type = 'cross';
        // Options

        // Maker
        const seriesType = 'line';
        const calcType = get_AXIS_CALCULATION_TYPE_fromVis(vis);
        const catType = get_AXIS_CATEGORY_TYPE_fromVis(vis);

        if (dataCube != null) {
          newOptions.xAxis.data = [];
          newOptions.xAxis.data = dataCube.labels;
          if (dataCube.categoriesDataCube.size == 0 || catType == null) {
            newOptions.series = [];
            newOptions._colorMap = new Map<string, string>();
            let serie_name = calcType.name;
            newOptions.series.push({
              name: serie_name,
              data: dataCube.values,
              type: seriesType
            });
            newOptions._colorMap.set(serie_name, stringToColourHex(serie_name));
          } else {
            newOptions.title.subtext = `${calcType.name}`;
            newOptions = catType.optionsMaker(vis, newOptions, dataCube, theme);
          }
          newOptions.color = colorMaker(newOptions._colorMap);
        }
        // Maker

        return newOptions;
      },
      customiseOptions: (vis: DataVisualization, options: VisEchartsOptions): VisEchartsOptions => {
        // Options
        let newOptions = { ...options };
        // Options

        if (vis.engine && vis.engine.customize) {

          // hideLegend
          if (vis.engine.customize.hideLegend && vis.engine.customize.hideLegend == true) {
            newOptions.legend = null;
          }
          // hideLegend

          // invertAxes
          if (vis.engine.customize.invertAxes && vis.engine.customize.invertAxes == true) {
            let x = { ...newOptions.xAxis };
            let y = { ...newOptions.yAxis };
            newOptions.xAxis = y as any;
            newOptions.yAxis = x as any;
          }
          // invertAxes

          // colors
          if (vis.engine.customize.colors && Object.entries(vis.engine.customize.colors).length > 0 && newOptions._colorMap.size > 0) {
            newOptions.color = [];
            newOptions._colorMap
              .forEach((value, key) => {
                if (vis.engine.customize.colors[key])
                  newOptions.color.push(vis.engine.customize.colors[key] + "");
                else
                  newOptions.color.push(value + "");
              });
          }
          // colors

        }

        return newOptions;
      }
    }
  },
  // Line Chart

  // Area Chart
  {
    id: "area",
    name: "Area Chart",
    description: "Compara tendências históricas ou mudanças mostrando a proporção do total que cada categoria representa em um determinado momento. Os gráficos de área comunicam tendências gerais em vez de valores individuais ao comparar várias séries de dados.",
    base64Img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACE0lEQVRoge3Xva8MURjH8c9yJUKCSkJDbkVEIhEaSgkNBZWClu5GQ+tfUBAvnUaiFYWrUSg1EjoK1eWKTtgEdxQnmx1zZ2dm531kv8lJptg583xzZn7PsyxY0AQnuy6gDg7gc9dFVGUX3iLqupAqLGFVkBi0yD1TicGK3PSvxCBFLuKPgYscx3ebJQYlMonZNInBiMRjdrAiyZhtXWRbTfs8lC/RmMgR4VX4gqe4ij0l9kmL2VZERljBOOVBv/EGt4X0GeXsdUl6zDYushfP53hw1mllxWyjIuexPueD4+sXXuEWzsiO2UZEtuMONipI1LVKM/mguxYoLZL1QXcqcmgOiX140YOiU0Ui0wRZMTsaz2KtBwVniiTXGp7gGo4Kf2L68EEn18s8kSbWGFdwH99q2O+RMAa1LnLDlK1Cv3hs/sa3IUwHE1oVWTV7JNktdPdnQmPM2ucnLifub03kq5B2RdgvBM7rGfucTrmnNZELBSWSHBZeoQ94h4MzfteKyN2SEnFGwig0i8ZF3mNHDSJ5NCoyxrEWJBoXiUdt0zQmkhW1TbBJ5LowllSZp9YVj9q62CQSZ1loUg/wSXGRslFbhUyRJEXE6ojaMkSIRqYS87zXy0KXPYVzwrx0Aj/qrLAgUfwi70Ty2Fnx/ipUOpE+EcGWrquoi4VI31iI9I2FSN/4b0SWYtcfO6uiBuKdfdD8BYfs7lzivVHOAAAAAElFTkSuQmCC',
    engine: {
      dataProps: [
        'xAxis',
        'yAxis',
        'categorizeBy',
      ],
      customizationProps: [
        'hideLegend',
        'invertAxes',
        'colors',
      ],
      type: 'echart',
      optionsMaker: (vis: DataVisualization, DB_DsDataResult: DB_DataResult, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService): VisEchartsOptions => {
        // Options
        let newOptions = { ...options };
        newOptions.xAxis.boundaryGap = false;
        newOptions.tooltip.axisPointer.type = 'cross';
        // Options

        // Maker
        const seriesType = 'line';
        const calcType = get_AXIS_CALCULATION_TYPE_fromVis(vis);
        const catType = get_AXIS_CATEGORY_TYPE_fromVis(vis);

        if (dataCube != null) {
          newOptions.xAxis.data = [];
          newOptions.xAxis.data = dataCube.labels;
          if (dataCube.categoriesDataCube.size == 0 || catType == null) {
            newOptions.series = [];
            newOptions._colorMap = new Map<string, string>();
            let serie_name = calcType.name;
            newOptions.series.push({
              name: serie_name,
              data: dataCube.values,
              type: seriesType,
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
            });
            newOptions._colorMap.set(serie_name, stringToColourHex(serie_name));
          } else {
            newOptions.title.subtext = `${calcType.name}`;
            newOptions = catType.optionsMaker(vis, newOptions, dataCube, theme)
          }
          newOptions.color = colorMaker(newOptions._colorMap);
        }
        // Maker

        return newOptions;
      },
      customiseOptions: (vis: DataVisualization, options: VisEchartsOptions): VisEchartsOptions => {
        // Options
        let newOptions = { ...options };
        // Options

        if (vis.engine && vis.engine.customize) {

          // hideLegend
          if (vis.engine.customize.hideLegend && vis.engine.customize.hideLegend == true) {
            newOptions.legend = null;
          }
          // hideLegend

          // invertAxes
          if (vis.engine.customize.invertAxes && vis.engine.customize.invertAxes == true) {
            let x = { ...newOptions.xAxis };
            let y = { ...newOptions.yAxis };
            newOptions.xAxis = y as any;
            newOptions.yAxis = x as any;
          }
          // invertAxes

          // colors
          if (vis.engine.customize.colors && Object.entries(vis.engine.customize.colors).length > 0 && newOptions._colorMap.size > 0) {
            newOptions.color = [];
            newOptions._colorMap
              .forEach((value, key) => {
                if (vis.engine.customize.colors[key])
                  newOptions.color.push(vis.engine.customize.colors[key] + "");
                else
                  newOptions.color.push(value + "");
              });
          }
          // colors

        }

        return newOptions;
      }
    }
  },
  // Area Chart

  // Pie Chart
  {
    id: "pie",
    name: "Pie Chart",
    description: "Mostra as categorias como uma proporção ou porcentagem do todo. Use gráficos de pizza para mostrar a composição de dados categóricos com cada segmento proporcional à quantidade que representa.",
    base64Img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAEEklEQVRYhbWXXWgcVRTHf2cmlPYhtb7UzaJCtyL4UMvOzAbT0LJV6weURmsjrW/Sx1iKqVbtk1ioVNMiWIW+qCBFJIiN9aUoEuy3O3f2pauVZjVVYYO+uGyhm2xnjg87WdY0SbOb5P80e/ec+//Nmbn3nhGWQK7r/gBsBH5W1YKInOns7Dw7Ojp6+265skQAOsvwP8DJarV6tFAo3FwUQH9/v10sFjeJSB/QC6SAe4ErxpjNTQCbVXWLiOwE3HisJCIHfN//omWAnp6eVbVabZ+qHgDWzhZjjJFpAGNMYz7HcR4TkXeBbDx0PJVKHRweHg6b8625zD3P2zU1NfWrqh6Nza+r6tvA9jAM180HDhAEwWVjzOMisheoAoPFYvHLmZ4ds+RanucdVtW3qFfopyiKXs/n8+eAxrN2XXeW1Dukvu9/kk6nf7Es6xsRecFxnKEgCAYbZjPNHcc5paqHgClgwBjTk8/nf2w2b1X5fP5SFEU7gKqIvOp53u5ZATzPOywiu4GbqtpnjPkYiNo1ngkhIgMAqnqst7e3838AnuftisteE5FngiA4uxTGzfJ9/1NgFEjeunXrjQZANptdqarHqT/zA77vX1hq81ga3yQisjebzXZYAJVKZT/wgKrmjDEnlskcqK8OIAASlUrlaau/v98GBgFs2z7EIl62hUpVvwIQkWetYrG4ifo6L+Ryue+X2zzWuRjk0Q4ReS4ePLPYWec4E+bTBgvYBBBF0WLu/nybedc6gPUAInKjXXdjzOZ2czuAewAmJycn2pmgjbIDnJ+GbmxEa9asabU3aLfsAJnpiw7gX2BtrVbrAioLnaGdsnd3d68Lw/C32BMAS0SKAKr6YKsTtqooijbEl2MNAFW9CBCG4bblBlDVDICIXGwAAKfjwZ3L7C/AHoAwDL9uAKRSqUvA38BD6XT6ieVydxxnK/UlP5HP5680AIaHh0NVHQKwLOsY87Rpi5AV94cA79PUY1gAq1ev/hD4A9jouu7AUru7rrsP6AbGy+XyR83/2QDj4+O3u7q6bojIi8BTiUTi4sTExO9LYe553jbgM+pVePnq1auFOwAASqXStWQyaQNbRWRHIpHwFwuRTqe3iMgIsEpE3vF9/+TMGLv5R6lUGk0mkw8DGRF5KZlMlkulUo7WewRxXXdQRD4HVgGnjDH7Zw2cI/kgcIT6O1JQ1TeDIPh2Ic6ZTObJMAyPiEgmBn/PGHOIOZrbOfd/x3GeF5EPgOkd8rqInAa+U9U/q9XqX7Zt64oVK+6PY7ap6nbgkTj+hojs931/ZD7geQ+gbDa7slKpvAK8Btw3X2yTJoChcrl8YmxsbPJuwQs9AS3P83qAviiKekVkPfWPU6V+sIyp6gXbtkdyudxlWviW+A+nhZ01lAS4vQAAAABJRU5ErkJggg==',
    engine: {
      dataProps: [
        'categories',
        'values',
      ],
      customizationProps: [
        'hideLegend',
        'colors',
      ],
      type: 'echart',
      optionsMaker: (vis: DataVisualization, DB_DsDataResult: DB_DataResult, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService): VisEchartsOptions => {
        // Options
        let newOptions = { ...options };
        newOptions.toolbox = {
          show: true,
          feature: {
            dataView: { show: true, readOnly: true },
            saveAsImage: { show: true }
          }
        };
        newOptions.xAxis = undefined;
        newOptions.yAxis = undefined;
        newOptions.tooltip = {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        };
        // Options

        // Maker
        const seriesType = 'pie';
        const calcType = get_AXIS_CALCULATION_TYPE_fromVis(vis);
        const catType = get_AXIS_CATEGORY_TYPE_fromVis(vis);

        if (dataCube != null) {
          newOptions.title.subtext = `${calcType.name}`;
          if (dataCube.categoriesDataCube.size == 0 || catType == null) {
            newOptions._colorMap = new Map<string, string>();
            let tempSerieData = [];
            for (let index = 0; index < dataCube.labels.length; index++) {
              const label = dataCube.labels[index];
              const value = dataCube.values[index];
              tempSerieData
                .push({ name: label, value: value },
                )
              newOptions._colorMap.set(label, stringToColourHex(label));
            }
            newOptions.series = [];
            newOptions.series.push({
              name: calcType.name,
              type: seriesType,
              radius: ['0%', '70%'],
              avoidLabelOverlap: false,
              data: tempSerieData,
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: theme.echartsTheme.itemHoverShadowColor,
                },
              },
              label: { show: true }
            });
          } else {
            newOptions = catType.optionsMaker(vis, newOptions, dataCube, theme);
          }
          newOptions.color = colorMaker(newOptions._colorMap);
        }
        // Maker

        return newOptions;
      },
      customiseOptions: (vis: DataVisualization, options: VisEchartsOptions): VisEchartsOptions => {
        // Options
        let newOptions = { ...options };
        // Options

        if (vis.engine && vis.engine.customize) {

          // hideLegend
          if (vis.engine.customize.hideLegend && vis.engine.customize.hideLegend == true) {
            newOptions.legend = null;
          }
          // hideLegend

          // colors
          if (vis.engine.customize.colors && Object.entries(vis.engine.customize.colors).length > 0 && newOptions._colorMap.size > 0) {
            newOptions.color = [];
            newOptions._colorMap
              .forEach((value, key) => {
                if (vis.engine.customize.colors[key])
                  newOptions.color.push(vis.engine.customize.colors[key] + "");
                else
                  newOptions.color.push(value + "");
              });
          }
          // colors

        }

        return newOptions;
      }
    }
  },
  // Pie Chart


  // {
  //   id: "bubble",
  //   name: "Bubble Chart",
  //   description: "Comunica a contagem bruta, frequência ou proporção de uma variável. O tamanho da bolha reflete a quantidade, a cor da bolha reflete a categoria e os eixos X e Y exibem valores independentes."
  // },
  // {
  //   id: "heat_map",
  //   name: "Heat map Chart",
  //   description: "Exibe valores individuais em uma matriz e representa o valor como cores. Os pontos de dados são definidos por uma interseção dos eixos X e Y e um terceiro valor que determina a cor do ponto de dados."
  // },
  // {
  //   id: "summary_table",
  //   name: "Summary table",
  //   description: "Agrupa registros em valores exclusivos em um ou mais campos-chave e, em seguida, executa uma contagem para o número de registros correspondentes."
  // },
  {
    id: "table",
    name: "Table",
    description: "Apresenta os dados de forma integra",
    base64Img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABjUlEQVRIieWWvUuCURTGf6YEQtJafjQUSFtTIunUWtDSX9EfELSGSDToVpuzRkuQa3PQ1BYNDX2vURAEWYPn4qNc08DPOnC5hx/PfR/Ovfc97wv/LQIeFgNmeuzzDDz8JMgDNeCrx6MG5NqZxvpkquZRZzbRYuy2PmR5AKgYKwpLyLqE8KKxirCQsQAQ9xkPNIZmHGrDy9TPBSBt8xqNrQqL9hB4t3zJ5hXgyHLfm9MUKfp3sdxIdap4XyreABaBC+DMWATYsvwAeLV8FVgGroATqXi724qDwt22F4TFRRsXXjBWFhbEU/HIXa554NPyKZunjUNzS50DJkXj1jit7p43Bnq5Rq6BJIEFG1VjJWEZ0WaEl4xVhSV9Bu3O+IbGGb/Z/GIc4EO0t8C9aNwap/We8cjd6jyNBuLaYBbYszwi2h0aDSQra5x2PFrmsVScpv7NvQYujYWBdctPaf5IJIE74Fwq3uy24r/bModmrDHQn73WyPXJvAbsqpHvHYsCs52255fxBDz2+JljFt97Ju6gVX16wgAAAABJRU5ErkJggg==',
    engine: {
      dataProps: [],
      customizationProps: [],
      type: 'magic-table',
      optionsMaker: (vis: DataVisualization, DB_DsDataResult: DB_DataResult, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService): VisMagicTableOptions => {
        let magicTableOptions: VisMagicTableOptions = new VisMagicTableOptions();

        // Maker
        let fields: DB_FieldData[] = [];
        vis.source.fields
          .forEach(fieldId => {
            let fieldData = DB_DsDataResult.fields.filter(f => { return f.id == fieldId })[0];
            if (fieldData)
              fields.push(fieldData)
          })

        let tempTable_columns: MagicTableColumn[] = [];
        fields.forEach(f => {
          let col = new MagicTableColumn();
          col.fieldName = f.id;
          col.title = f.name;

          if (f.type == 'timestamp')
            col.type = 'timestamp'
          else
            col.type = 'text';

          col.width = 'auto';
          tempTable_columns.push(col)
        })
        magicTableOptions.columns = tempTable_columns;
        // Maker

        return magicTableOptions;
      }
    }
  },
  // {
  //   id: "treemap",
  //   name: "Treemap Chart",
  //   description: "Exibe dados hierárquicos estruturados em árvore como um conjunto de retângulos aninhados."
  // },
  // {
  //   id: "statistics",
  //   name: "Statistics",
  //   description: "Mostra informações estatísticas em um formato visualmente atraente para um ou mais campos em uma tabela."
  // }
];
function get_VIS_TYPE(id: string): VisType {
  let data = new VisType();
  let tempData = VIS_TYPES.filter(vt => { return vt.id == id; })[0];
  if (tempData)
    data = tempData;
  return data;
}
// ------ V I S ------
