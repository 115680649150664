import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnChanges, OnInit, SimpleChange } from '@angular/core';

import { User } from 'app/@firebase';
import { genNotif_cardAddAssignee, genNotif_cardRemAssignee } from 'app/routes/main/project-management/PM_NOTIFICATIONS';

import { PmCardModal } from '../../../pm-card-modal';

@Component({
  selector: 'mode-assignees',
  templateUrl: './mode-assignees.component.html',
  styleUrls: ['./mode-assignees.component.scss']
})
export class PMCardModalModeAssigneesComponent implements OnInit, OnChanges {

  className = 'PMCardModalModeAssigneesComponent';

  _loader = true;

  cardAssignees: User[] = [];
  boardMembers4Sel: User[] = [];
  newCardAssignee = new User();

  constructor(
    public pmCardModal: PmCardModal
  ) { }

  ngOnInit() {
    this.load();
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }
  ngOnDestroy() { }
  _trackByFn(index, item) {
    return index;
  }

  load() {
    this._loader = true;
    let tempCardAssignees = [];
    this.pmCardModal.card.assignedTo
      .forEach((ca) => {
        const tempUser = this.pmCardModal.boardMembers.find((u) => u.uName == ca);
        if (tempUser)
          tempCardAssignees.push(tempUser);
      });
    this.cardAssignees = tempCardAssignees;
    this.boardMembers4Sel = this.pmCardModal.boardMembers.filter((bm) => !this.pmCardModal.card.assignedTo.includes(bm.uName));
    this._loader = false;
  }
  _assigneesOnDrop(event: CdkDragDrop<User[]>) {
    if (event.previousContainer == event.container) {
      this._loader = true;

      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      )

      let tempAssigneesUnames = [];
      event.container.data.forEach((m) => {
        tempAssigneesUnames.push(m.uName);
      });

      this.pmCardModal.card.assignedTo = tempAssigneesUnames;

      this.pmCardModal._updateCard()
        .then(() => {
          this.load();
        })
        .catch((e) => {
          this._loader = false;
          this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
        })
    }
  }
  _addAssignee() {
    this._loader = true;

    this.pmCardModal.card.assignedTo.push(this.newCardAssignee.uName);

    this.pmCardModal._updateCard(
      {
        assignedTo: this.pmCardModal.db.fieldValue.arrayUnion(this.newCardAssignee.uName) as any,
        subscribers: this.pmCardModal.db.fieldValue.arrayUnion(this.newCardAssignee.uName) as any
      }
    )
      .then(() => {

        let promises = [];
        if (this.newCardAssignee.uName != this.pmCardModal.authService.localUser.uName) {
          let notification = genNotif_cardAddAssignee(this.pmCardModal.card, this.pmCardModal.authService.localUser, this.newCardAssignee.uName);
          promises.push(
            this.pmCardModal.db.sys.notifications.create(notification.id, notification)
          )
        }

        return Promise.all(promises)
          .then(() => {
            this.newCardAssignee = new User();
            this.load();
          });
      })
      .catch((e) => {
        this._loader = false;
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
      })
  }
  _remAssignee(uName: string) {
    this._loader = true;

    this.pmCardModal.card.assignedTo = this.pmCardModal.card.assignedTo.filter((assignee) => assignee != uName);

    this.pmCardModal._updateCard(
      {
        assignedTo: this.pmCardModal.db.fieldValue.arrayRemove(uName) as any,
      }
    )
      .then(() => {

        let promises = [];
        if (uName != this.pmCardModal.authService.localUser.uName) {
          let notification = genNotif_cardRemAssignee(this.pmCardModal.card, this.pmCardModal.authService.localUser, uName);
          promises.push(
            this.pmCardModal.db.sys.notifications.create(notification.id, notification)
          )
        }

        return Promise.all(promises)
          .then(() => {
            this.pmCardModal._toDoLists.cardToDos.filter((toDo) => toDo.assignedTo == uName)
              .forEach((toDo) => {
                toDo.assignedTo = "";
                this.pmCardModal._toDoLists._updateToDoAfterChange(toDo);
              })
            this.load();
          });
      })
      .catch((e) => {
        this._loader = false;
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
      })
  }
}
