import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbGlobalLogicalPosition, NbToastrConfig, NbToastRef, NbToastrService } from '@nebular/theme';

import { AuthService, UtilService } from 'app/@core';
import { FireMessagingService, FirestoreService, NOTIF_STATUS_ICON_TYPE, NOTIF_STATUS_ICONS, Notification, NotificationAction } from 'app/@firebase';

import { ThemeService } from '../../theme.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

type FilterMode = "all" | "readed" | "unReaded";

@Component({
  selector: 'ic-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {

  className = 'NotificationsListComponent';
  @ViewChild('notifSidebarContent', { read: ElementRef, static: false }) notifSidebarContent: ElementRef;
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  filterModes = [
    { id: 'unReaded' as FilterMode, name: () => 'Não Lidas (' + this.numOfNotifMap.unReaded + ')' },
    { id: 'readed' as FilterMode, name: () => 'Lidas (' + this.numOfNotifMap.readed + ')' },
    { id: 'all' as FilterMode, name: () => 'Todas (' + this.numOfNotifMap.all + ')' },
  ];
  filterMode: FilterMode = "unReaded";

  _loader = true;
  private _notifSidebarOpenSub: any = null;
  private _notifSidebarCloseSub: any = null;
  _notifReaded: string[] = [];

  notifications: Notification[] = [];


  iconMap = {};
  elapsedTimeStrMap = {};
  createdOnStrMap = {};
  dateStringMap = {};
  numOfNotifMap = { all: 0, readed: 0, unReaded: 0 };

  loaderLottie = {
    loop: true,
    autoplay: true,
    path: 'assets/animations/notification.json'
  };
  noNotifLottie = {
    loop: true,
    autoplay: true,
    path: 'assets/animations/no_notifications.json'
  };

  private logicalPositions = NbGlobalLogicalPosition;

  constructor(
    public db: FirestoreService,
    public utilCtrl: UtilService,
    public authService: AuthService,
    public themeService: ThemeService,
    public router: Router,
    private toastrService: NbToastrService,
    private fm: FireMessagingService,
    private elRef: ElementRef, private renderer: Renderer2
  ) { }

  ngOnInit() {
    this._notifSidebarOpenSub = this.themeService.onNotifSidebarOpen()
      .subscribe(
        (next) => {
          this.load();
        },
        (error) => {
          this.themeService.uiFeedBack.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', error);
        }
      )
    this._notifSidebarCloseSub = this.themeService.onNotifSidebarClose()
      .subscribe(
        (next) => {
          this._notifReaded = [];
        },
        (error) => {
          this.themeService.uiFeedBack.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', error);
        }
      )

    this.fm.onMessage()
      .subscribe(
        payload => {
          this.load();
          let toastrConfig: Partial<NbToastrConfig> = {
            position: this.logicalPositions.TOP_START,
            duration: 8000,
            status: 'basic',
          };

          if (payload.data && payload.data.status) {
            if (NOTIF_STATUS_ICONS[payload.data.status]) {
              toastrConfig.icon = NOTIF_STATUS_ICONS[payload.data.status];
              toastrConfig.icon.status = payload.data.status;
            } else {
              toastrConfig.status = payload.data.status;
              toastrConfig.hasIcon = false;
            }
          }

          const toastRef: NbToastRef = this.toastrService.show(payload.data.body, payload.data.title, toastrConfig);
          toastRef.onClick().subscribe(() => { this._onToastClick(payload.data as any) });
        });
  }
  _onToastClick(payloadData: { id: string; action: NotificationAction; actionBtnText: string; actionData: string; }) {
    console.log("_onToastClick payloadData: ", payloadData);
    if (!payloadData)
      return;

    if (payloadData.id)
      this.db.sys.notifications.update(payloadData.id, { readed: true, readedOn: this.utilCtrl.timestamp.now() })
        .then(() => {
          console.log('notification readed: ', payloadData.id);
        })
        .catch((e) => {
          this.themeService.uiFeedBack.presentErrorAlert('Falha ao ler Notificação!', this.className, this.authService.localUser.uName, 'Erro', e);
        });

    // action
    if (payloadData.action == 'url' && payloadData.actionData)
      this.router.navigateByUrl(payloadData.actionData);
  }
  ngAfterViewInit(): void {
    this._adjustNotifCardsListHeight();
    window.addEventListener('resize', this._adjustNotifCardsListHeight.bind(this)); // Reajusta ao redimensionar
  }
  ngOnDestroy(): void {
    if (this._notifSidebarOpenSub != null)
      this._notifSidebarOpenSub.unsubscribe();
    if (this._notifSidebarCloseSub != null)
      this._notifSidebarCloseSub.unsubscribe();
    window.removeEventListener('resize', this._adjustNotifCardsListHeight.bind(this));
  }


  // UI
  _adjustNotifCardsListHeight(): void {
    // Obtém os elementos do cabeçalho
    const layoutHeader = document.querySelector('#nb-layout-header');
    const notificationsHeader = this.elRef.nativeElement.querySelector('#notifications-list-header');

    const notifCardsList = this.elRef.nativeElement.querySelector('.notif-cards-list');

    // Obtém as alturas dos cabeçalhos
    const layoutHeaderHeight = layoutHeader ? layoutHeader.clientHeight : 0;
    const notificationsHeaderHeight = notificationsHeader ? notificationsHeader.offsetHeight : 0;

    // Calcula a altura disponível
    const availableHeight = window.innerHeight - layoutHeaderHeight - notificationsHeaderHeight;

    // Define a altura calculada no .notif-cards-list
    this.renderer.setStyle(notifCardsList, 'height', `${availableHeight}px`);
  }
  _trackByFn(index, item) {
    return index;
  }
  _selectMode(modeId: FilterMode) {
    this.filterMode = modeId;
    this.updateFilterMode();
  }
  _resetScrollPosition(): void {
    if (this.viewport) {
      this.viewport.scrollToIndex(0); // Rola para o índice 0 (início da lista)
    }
  }
  // UI

  // L O A D
  private _loadNotifFromDB(): Promise<void> {
    return new Promise((resolve, reject) => {
      let q = this.db.sys.notifications.ref
        .where('uName', '==', this.authService.localUser.uName)
        .orderBy('createdOn', 'desc')

      if (this.filterMode == "readed")
        q = q.where('readed', '==', true);

      if (this.filterMode == "unReaded")
        q = q.where('readed', '==', false);

      return q.get({ source: "server" })
        .then((snapshot) => {
          this.numOfNotifMap[this.filterMode] = snapshot.size;
          let tempNotifications: Notification[] = [];
          this.notifications = [];
          for (let doc of snapshot.docs)
            tempNotifications.push(new Notification(doc.data()));
          this.notifications = [...tempNotifications];
          resolve();
        })
        .catch(e => reject(e));
    })
  }
  updateFilterMode() {
    this._loader = true;
    return this._loadNotifFromDB()
      .then(() => {
        this._updateUiList();
        this._resetScrollPosition();
        this._loader = false;
      })
      .catch(e => this.themeService.uiFeedBack.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e));
  }
  private _updateUiList() {
    let timeNowMs = new Date().getTime();

    let tempIconMap = {} as Record<string, NOTIF_STATUS_ICON_TYPE>;
    let tempElapsedTimeStrMap = {};
    let tempCreatedOnStrMap = {};
    let tempDateStringMap = {};

    this.notifications
      .forEach(n => {
        let _createdOn = this.utilCtrl.timestamp.toDate(n.createdOn);

        tempIconMap[n.id] = null;
        if (NOTIF_STATUS_ICONS[n.status])
          tempIconMap[n.id] = NOTIF_STATUS_ICONS[n.status];

        tempElapsedTimeStrMap[n.id] = this.utilCtrl.converters.millisecondsToHuman(timeNowMs - _createdOn.getTime());
        tempCreatedOnStrMap[n.id] = this.utilCtrl.timestamp.toLocalDate(n.createdOn);
        tempDateStringMap[n.id] = this.utilCtrl.date.getDateStrBR(_createdOn);

        this.iconMap = tempIconMap;
        this.elapsedTimeStrMap = tempElapsedTimeStrMap;
        this.createdOnStrMap = tempCreatedOnStrMap;
        this.dateStringMap = tempDateStringMap;
      })
  }
  load() {
    this._loader = true;

    this.notifications = [];
    this.numOfNotifMap = { all: 0, readed: 0, unReaded: 0 };

    // mode determination
    if (this.authService.numOfUnreadNotif > 0)
      this.filterMode = "unReaded";

    return this.db.sys.notifications.ref
      .where('uName', '==', this.authService.localUser.uName)
      .get({ source: "server" })
      .then(snapshot => {
        if (!snapshot.empty) {
          let tempDocs: Notification[] = [];
          snapshot.forEach(doc => {
            tempDocs.push(doc.data());
          });
          this.numOfNotifMap.all = tempDocs.length;
          this.numOfNotifMap.readed = tempDocs.filter((n) => { return n.readed; }).length;
          this.numOfNotifMap.unReaded = tempDocs.filter((n) => { return !n.readed; }).length;
        }

        return this._loadNotifFromDB()
          .then(() => {
            this._updateUiList();
            this._loader = false;
          })
      })
      .catch(e => this.themeService.uiFeedBack.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e));
  }
  // L O A D

  _uiIsNotifReaded(notif: Notification) {
    return notif.readed || this._notifReaded.includes(notif.id);
  }
  _openNotif(notif: Notification, event?: MouseEvent) {
    if (event.button === 0 || event.button === 1) {
      this._markNotifAsReaded(notif);

      if (event.button === 0) {
        this.themeService.closeAllSidebars();

        // logic of notif.action
        if (notif.action == 'url')
          this.__openUrl(notif.actionData, false);
      }
    }
  }
  __openUrl(url: string, newTab: boolean) {
    if (!newTab) {
      this.router.navigateByUrl(url);
    } else {
      let _url = window.location.origin + '/#/' + url;
      window.open(_url, '_blank').focus();
    }
  }
  _markNotifAsReaded(notif: Notification) {
    if (!notif.readed && !this._notifReaded.includes(notif.id))
      this.db.sys.notifications.update(notif.id, { readed: true, readedOn: this.utilCtrl.timestamp.now() })
        .then(() => {
          this._notifReaded.push(notif.id);
        })
        .catch((e) => {
          this.themeService.uiFeedBack.presentErrorAlert('Falha ao ler Notificações!', this.className, this.authService.localUser.uName, 'Erro', e);
        });
  }
  _markAllAsReaded() {
    this.themeService.uiFeedBack.presentLoader('lendo Notificações...')
      .then(() => {
        this.db.sys.notifications.ref
          .where('uName', '==', this.authService.localUser.uName)
          .orderBy('createdOn', 'desc')
          .where('readed', '==', false)
          .get({ source: "server" })
          .then(snapshot => {
            let readedOn = this.utilCtrl.timestamp.now();
            let promises = snapshot.docs.map(doc => {
              return doc.ref.update({ readed: true, readedOn: readedOn })
                .then(() => Promise.resolve())
                .catch((e) => { return Promise.reject(e) })
            });
            return Promise.all(promises)
              .then(() => {
                this.load();
                this.themeService.uiFeedBack.dismissLoader();
              })
          })
          .catch((e) => {
            this.themeService.uiFeedBack.presentErrorAlert('Falha ao ler Notificações!', this.className, this.authService.localUser.uName, 'Erro', e);
          });
      });
  }
  _deleteReadedNotif() {
    this.themeService.uiFeedBack.presentLoader('Apagando Notificações...')
      .then(() => {
        this.db.sys.notifications.ref
          .where('uName', '==', this.authService.localUser.uName)
          .orderBy('createdOn', 'desc')
          .where('readed', '==', true)
          .get({ source: "server" })
          .then(snapshot => {
            let promises = snapshot.docs.map(doc => {
              return doc.ref.delete()
                .then(() => Promise.resolve())
                .catch((e) => { return Promise.reject(e) })
            });
            return Promise.all(promises)
              .then(() => {
                this.load();
                this.themeService.uiFeedBack.dismissLoader();
              })
          })
          .catch((e) => {
            this.themeService.uiFeedBack.presentErrorAlert('Falha ao apagar Notificações!', this.className, this.authService.localUser.uName, 'Erro', e);
          });
      });
  }
}
