import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NbButtonModule, NbIconModule } from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';

import { DbFilterRuleUiComponent } from './db-filter-rule-ui/db-filter-rule-ui.component';
import { DbOrderByRuleUiComponent } from './db-order-by-rule-ui/db-order-by-rule-ui.component';
import { DbRulesUiComponent } from './db-rules-ui.component';

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  NgSelectModule,
  NbButtonModule,
  NbIconModule
];
const COMPONENTS = [
  DbFilterRuleUiComponent,
  DbOrderByRuleUiComponent,
  DbRulesUiComponent
];


@NgModule({
  imports: [...BASE_MODULES],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class DbRuleUiModule { }
