import { Location } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AuthService, UtilService } from 'app/@core';
import { AuthObject, AuthObjectField, FirestoreService, RoleAuth } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

declare var jQuery: any;

@Component({
  selector: 'app-edit-auth-values-modal',
  templateUrl: './edit-auth-values-modal.component.html',
  styleUrls: ['./edit-auth-values-modal.component.scss']
})
export class EditAuthValuesModalComponent implements OnInit {

  className = 'EditAuthValuesModalComponent';

  public loader = true;

  modalId = '';
  localRoleAuth = new RoleAuth();
  localObject = new AuthObject();
  localObjectField = new AuthObjectField();

  roleAuth_AP_field_index = -1;

  manual_newValue = '';
  listed_newValue = '';
  valuesFromColection_newValue = '';
  valuesFromColection_values: string[] = [];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,

    public uiFeedBackCtrl: UiFeedBackService,
    public db: FirestoreService,
    private authService: AuthService,
    public router: Router,
    private _location: Location,
    public utilCtrl: UtilService,
  ) {
    this.modalId = this.data.modalId;

    this.localRoleAuth = this.data.ra != undefined ? JSON.parse(JSON.stringify(this.data.ra)) : new RoleAuth();
    this.localObject = this.data.object != undefined ? JSON.parse(JSON.stringify(this.data.object)) : new AuthObject();
    this.localObjectField = this.data.field != undefined ? JSON.parse(JSON.stringify(this.data.field)) : new AuthObjectField();

    (function ($) {
      $(document).ready(function () {
        $('.mat-dialog-container').css("width", "600px");
      });
    })(jQuery);
  }


  ngOnInit() {
    this.loadModalData();
    this.loader = false;
  }

  getObjFieldValueDescriptonData(value: string): string {
    let tempData = this.localObjectField.values.filter(of => { return of.value == value })[0];
    if (tempData)
      return tempData.description;
    else
      return 'Manual entry';
  }

  loadModalData() {
    this.loader = true;

    for (let i = 0; i < this.localRoleAuth.authPairs.length; i++) {
      if (this.localRoleAuth.authPairs[i].field == this.localObjectField.id) {
        this.roleAuth_AP_field_index = i;
        break;
      }
    }

    if (!this.localObjectField.valuesFromColection)
      this.loader = false;
    else
      this.valuesFromColection_loadValues()
        .then(() => {
          this.loader = false;
        })
        .catch((e) => {
          this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
        })
  }

  valuesFromColection_loadValues(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.valuesFromColection_values = [];
      this.db.afs.collection(this.localObjectField.valuesColection)
        .ref
        .get({ source: 'server' })
        .then(q => {
          for (let d of q.docs) {
            let tempData = d.data();
            this.valuesFromColection_values.push(tempData[this.localObjectField.valuesField]);
          }
          resolve();
        })
        .catch(e => reject(e))
    });
  }

  removeConfirmation(i) {
    var r = confirm(`delete "${this.localRoleAuth.authPairs[this.roleAuth_AP_field_index].values[i]}"?\n-----------------`);
    if (r == true) {
      this.remove(i);
    } else {
      alert("Action Canceled!");
    }
  }
  remove(i) {
    this.localRoleAuth.authPairs[this.roleAuth_AP_field_index].values.splice(i, 1);
  }


  cleanInputs() {
    this.manual_newValue = '';
    this.listed_newValue = '';
    this.valuesFromColection_newValue = '';
  }
  addManual() {
    this.localRoleAuth.authPairs[this.roleAuth_AP_field_index].values.push(this.manual_newValue.toLocaleUpperCase());
    this.cleanInputs();
  }
  addListed() {
    this.localRoleAuth.authPairs[this.roleAuth_AP_field_index].values.push(this.listed_newValue.toLocaleUpperCase());
    this.cleanInputs();
  }
  addValuesFromColection() {
    this.localRoleAuth.authPairs[this.roleAuth_AP_field_index].values.push(this.valuesFromColection_newValue.toLocaleUpperCase());
    this.cleanInputs();
  }
  save() {
    this.dialog.getDialogById(this.modalId).close({
      ra: this.localRoleAuth
    })
  }

}
