export class Booleans {
  // constructor() { }
  dataHas18yPlus(dateStr_dd_mm_yyyy: string): boolean {
    let dd = dateStr_dd_mm_yyyy.split("/")[0];
    let mm = dateStr_dd_mm_yyyy.split("/")[1];
    let yyyy = dateStr_dd_mm_yyyy.split("/")[2];
    let dateObj = new Date(`${mm}/${dd}/${yyyy} 00:00:00 GMT-0300 (Hora oficial do Brasil)`);
    return (new Date().getTime() - dateObj.getTime()) > 568024668000;
  }
}
