import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';

import { AuthService, UtilService } from 'app/@core';
import { FirestoreService, SM_Document } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

declare var jQuery: any;

@Component({
  selector: 'app-other-sm-doc-modal',
  templateUrl: './other-sm-doc-modal.component.html',
  styleUrls: ['./other-sm-doc-modal.component.scss']
})
export class OtherSMDocModalComponent implements OnInit {

  className = 'OtherSMDocModalComponent';

  public loader = false;
  public modalId = '';
  public sMDocId = '';
  public sMDoc = new SM_Document();

  public sMDocIdStatus: 'found' | 'not-found' | 'non' = 'non';

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,

    public uiFeedBackCtrl: UiFeedBackService,
    private authService: AuthService,
    public db: FirestoreService,
    public utilCtrl: UtilService,
    public router: Router,
  ) {
    this.modalId = this.data.modalId;

    (function ($) {
      $(document).ready(function () {
        $('.mat-dialog-container').css("width", "400px");
      });
    })(jQuery);
  }
  ngOnInit() { }

  search() {
    this.loader = true;
    this.sMDoc = new SM_Document();
    this.db.serviceManagement.documents.documents.getDataById(this.sMDocId)
      .then(data => {
        if (data) {
          this.sMDocIdStatus = 'found';
          this.sMDoc = data;
        } else {
          this.sMDocIdStatus = 'not-found';
        }
        this.loader = false;
      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert(``, this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }
  goToSmDocDetail(smDocId: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        'id': smDocId,
      }
    };
    this.router.navigate(['/main/service-management/sm-documents/detail'], navigationExtras);
    this.dialog.closeAll();
  }
}
