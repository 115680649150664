import { ColorSelectComponent } from "./color-select";
import { SvgInlineComponent } from "./svg-inline";
import { WeekInputComponent } from "./week-input";

export const SHARED_COMPONENTS_COMPONENTS = [
    ColorSelectComponent,
    WeekInputComponent,
    SvgInlineComponent,
];

export const SHARED_COMPONENTS_MODULES = [
];