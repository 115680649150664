import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'custom-open-btn',
  styles: [`
    a {
        cursor: pointer !important;
        display: block !important;
        width: 100% !important;
        text-align: center !important;
        color: #a4abb3 !important;
    }
    a:hover {
        color: #5dcfe3 !important;
    }
    nb-icon{
        font-size: 2rem !important;
    }
      `],
  template: `
    <a (click)="click.emit(rowData)"><nb-icon icon="plus-circle" pack="eva"></nb-icon></a>
    `,
})
export class CustomOpenBtnRendererComponent implements ViewCell, OnInit {
  @Input() value: string | number;
  @Input() rowData: any = {};
  @Output() click: EventEmitter<any> = new EventEmitter(this.rowData);
  ngOnInit() { }
}
