import { Component, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

import { LayoutService, UtilService } from 'app/@core';
import { _PM_CardIsUserCard, TM_TimeRecord, User } from 'app/@firebase';
import { ThemeService } from 'app/@theme';
import { environment } from 'environments/environment';
import { sumTimeRecords } from '../../../../pm-card-modal';

@Component({
  selector: 'mode-time-records-record-type-pie-chart',
  styleUrls: ['./charts-common.component.scss'],
  template: `<div class="chart-container" [style.--chart-width]="chartWidth" [style.--chart-height]="chartHeight"><div echarts [options]="options" class="echart" (chartInit)="onChartInit($event)"></div></div>`,
})
export class ModeTimeRecordsRecordTypePieChartComponent implements OnDestroy {

  private alive = true;
  private title = '% Cartões no Prazo';

  private echartsIntance: any;
  public chartWidth = '400px';
  public chartHeight = '400px';
  options: any = {};

  trTipes = [
    { id: 'card', name: 'Cartão', test: (tr: TM_TimeRecord): boolean => { return tr.referenceType == 'PM_Card' } },
    { id: 'activity', name: 'Atividades', test: (tr: TM_TimeRecord): boolean => { return tr.referenceType == 'PM_Card-Activity' } },
    { id: 'toDo', name: 'To-Dos', test: (tr: TM_TimeRecord): boolean => { return tr.referenceType == 'PM_Card-ToDo' } },
  ];

  constructor(
    private layoutService: LayoutService,
    private theme: ThemeService,
    protected utilCtrl: UtilService
  ) {
    this.layoutService.onSafeChangeLayoutSize()
      .pipe(
        takeWhile(() => this.alive),
      )
      .subscribe(() => this.resizeChart());
  }

  public load(trs: TM_TimeRecord[], users: User[]) {

    const stringToColourHex = (str: string): string => {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var color = '#';
      for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return color;
    };

    // picsMap
    let picsMap = {};
    users.forEach((u) => {
      picsMap[`PIC_${u.uName}`] = {
        backgroundColor: {
          image: u.picture,
        },
        height: 25,
        width: 25,
      };
    });

    // uNames
    const uNames = [...new Set(trs.map((tr) => tr.uName))];

    this.options = {
      title: {
        show: false,
        text: this.title,
        // subtext: '',
        textStyle: {
          color: this.theme.echartsTheme.textColor,
          fontSize: this.theme.echartsTheme.fontSize,
        },
      },
      backgroundColor: this.theme.echartsTheme.bg,
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          if (params.seriesIndex === 0) {
            return `${params.marker}<b>${params.name}</b><br/>
            ${params.data.detail.value} (${params.percent}%)`;
          }
          if (params.seriesIndex === 1) {
            let tooltipData = `${params.marker}<b>${params.name}</b><br/>
            ${params.data.detail.value} (${params.percent}%)<br/>
            <br/>`;
            if (params.value > 0) {
              tooltipData += `
              Detalhamento<br/>
              <div style="margin-left: 15px;">`;
              if (params.data.detail.card)
                tooltipData += `- Cartão: ${params.data.detail.card}</br>`;
              if (params.data.detail.activity)
                tooltipData += `- Atividades: ${params.data.detail.activity}</br>`;
              if (params.data.detail.toDo)
                tooltipData += `- To-Dos: ${params.data.detail.toDo}</br>`;
              tooltipData += `</div>`;
            }
            return tooltipData;
          }
          return `${params.seriesName} <br/>${params.marker}${params.name}: ${params.value} (${params.percent}%)`;
        },
        backgroundColor: this.theme.echartsTheme.bg,
        borderColor: '#aaa',
        borderWidth: 1,
        textStyle: {
          color: this.theme.echartsTheme.textColor,
          fontSize: this.theme.echartsTheme.fontSize,
        },
        shadowStyle: {
          color: 'rgba(0, 0, 0, 0.3)',
        },
      },
      series: [
        {
          name: 'Tipo de Apontamento',
          type: 'pie',
          selectedMode: 'single',
          radius: [0, '45%'],
          label: {
            position: 'inner',
            fontSize: this.theme.echartsTheme.fontSize,
          },
          labelLine: {
            show: false,
          },
          data: this.trTipes
            .map((trt) => {
              const name = trt.name;
              const sum = sumTimeRecords(trs.filter((tr) => trt.test(tr)));
              return { value: sum.val, name: name, itemStyle: { color: stringToColourHex(name) }, detail: { value: sum.s } }
            })
            .filter((data) => data.value > 0),
        },
        {
          name: 'Horas por Usuário',
          type: 'pie',
          radius: ["50%", "60%"],
          labelLine: {
            length: 5,
          },
          label: {
            formatter: function (params) {
              if (params.data && params.data.detail && params.data.detail.hasPic)
                return `{PIC_${params.data.detail.uName}|}`;
              return ` {b|${params.name}} `;
            },
            backgroundColor: '#F6F8FC',
            borderColor: '#8C8D8E',
            borderWidth: 1,
            borderRadius: 4,
            rich: {
              b: {
                color: '#4C5058',
                fontSize: 10,
                fontWeight: 'bold',
                lineHeight: 33
              },
              ...picsMap,
            },
          },
          data: uNames.map((uName) => {
            const userData = users.find((u) => u.uName == uName);
            const userSum = sumTimeRecords(trs.filter((tr) => tr.uName == uName));
            const name = (userData?.firstName ?? uName);

            let tempDetail = {
              uName: uName,
              hasPic: userData && userData.picture != '' ? true : false,
              value: userSum.s,
            };

            this.trTipes.forEach((trt) => {
              const sum = sumTimeRecords(trs.filter((tr) => tr.uName == uName && trt.test(tr)));
              if (sum.val > 0)
                tempDetail[trt.id] = sum.s;
            })

            return {
              value: userSum.val,
              name: name,
              itemStyle: { color: stringToColourHex(name) },
              detail: tempDetail,
            };
          }),
        },
      ],
    };


    // this.resizeChart();
    if (!environment.production) {
      console.log(`mode-time-records-record-type-pie-chart - Options -> `, this.options);
      // console.log(`mode-time-records-record-type-pie-chart - Options -> `, JSON.stringify(this.options, undefined, 2));
    }
  }

  onChartInit(echarts) {
    this.echartsIntance = echarts;
  }
  resizeChart() {
    if (this.echartsIntance) {
      // Fix recalculation chart size
      // TODO: investigate more deeply
      setTimeout(() => {
        this.echartsIntance.resize({ height: this.chartHeight, width: this.chartWidth });
      }, 500);
    }
  }
  ngOnDestroy(): void {
    this.alive = false;
  }
}
