<div class="db-order-by-rule-ui-inner" [ngClass]="{'has-error': hasError}">
    <div class="header">
        <button *ngIf="!_showConstructor" icButton size="small" title="Mostrar Construtor de Query"
            (click)="_toggleShowConstructor()">
            <nb-icon icon="arrow-downward-outline" pack="eva"></nb-icon>
        </button>

        <button *ngIf="_showConstructor" icButton size="small" title="Ocultar Construtor de Query"
            (click)="_toggleShowConstructor()">
            <nb-icon icon="arrow-downward" pack="eva"></nb-icon>
        </button>

        <div class="order-by-text">
            {{_orderByStr}}
        </div>
    </div>


    <div class="constructor" *ngIf="_showConstructor">
        <span class="title">Ordenação</span>
        <div class="selector">

            <ng-select [disabled]="!edit" id="input_field" name="input_field" labelForId="input_field"
                [(ngModel)]="orderBy.fieldId" (change)="_change()">
                <ng-option value="">
                    Não Ordenar
                </ng-option>
                <ng-option *ngFor="let field of _dataSourceData.fields" [value]="field.id">
                    {{field.name}}
                </ng-option>
            </ng-select>

            <button [disabled]="!edit||orderBy.fieldId == ''" class="order-by-button"
                [ngClass]="{'selected':orderBy.direction=='asc' }" (click)="_sort('asc')">
                <div class="inner">
                    <span style="color: blue;">A</span>
                    <span>Z</span>
                </div>
            </button>
            <button [disabled]="!edit||orderBy.fieldId == ''" class="order-by-button"
                [ngClass]="{'selected':orderBy.direction=='desc' }" (click)="_sort('desc')">
                <div class="inner">
                    <span>Z</span>
                    <span style="color: blue;">A</span>
                </div>
            </button>

        </div>

        <p *ngIf="dataSourceId ==''" class="tiny-error">
            Selecione um Data Source para começar
        </p>
    </div>
</div>