import firebase from "firebase/compat/app";

export class WS_Role {

  id: string;
  workspaceId: string;
  parentKey: string;

  title: string;
  description: string;
  permissions: string[];

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<WS_Role>) {

    this.id = '';
    this.workspaceId = '';
    this.parentKey = '';

    this.title = '';
    this.description = '';
    this.permissions = [];

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
