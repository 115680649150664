import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NbMediaBreakpointsService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from 'app/@core/services/auth.service';
import { TmTimerModalComponent } from 'app/routes/main/time-management/timer-modal/timer-modal.component';

import { environment } from '../../../../environments/environment';
import { ThemeService } from '../../theme.service';
import { UiFeedBackService } from 'app/@theme/services';

@Component({
  selector: 'ic-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  className = "HeaderComponent";
  public dev = !environment.production;
  private destroy$: Subject<void> = new Subject<void>();

  userPictureOnly: boolean = false;
  userMenu = [
    { title: 'Perfil', icon: 'person-outline' },
    { title: 'Sair', link: '/auth/logout', icon: 'log-out-outline' },
  ];

  constructor(
    public themeService: ThemeService,
    public authService: AuthService,
    public uiFeedBackService: UiFeedBackService,
    private breakpointService: NbMediaBreakpointsService
  ) { }

  ngOnInit() {
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.nbTheme.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  menubuttonClick() {
    this.themeService.toggleMenuSidebar();
    return false;
  }
  _openNotif() {
    this.themeService.toggleNotifSidebar();
    return false;
  }

  // Buttons
  _openTmTimerModal() {
    let active_TmTimerModalRef = this.themeService.uiFeedBack.dialog_opener(TmTimerModalComponent, {
      id: 'TmTimerModalRef_' + new Date().getTime(),
    });
  }
  // Buttons


}
