<div class="timer-dashboard-tab">
  <div class="timer-dashboard-tab-inner">

    <div *ngIf="loader" class="loader">
      <p><b>Carregando...</b></p>
    </div>


    <div class="container">
      <div class="row">
        <div class="col-12">
          <nb-tabset>
            <nb-tab tabTitle="Resumo">
              <timer-week-hours-bar-chart-summary #TimerWeekHoursBarChartSummary_REF>
              </timer-week-hours-bar-chart-summary>
            </nb-tab>
            <nb-tab tabTitle="Detalhes">
              <timer-week-hours-bar-chart-detailed #TimerWeekHoursBarChartDetailed_REF>
              </timer-week-hours-bar-chart-detailed>
            </nb-tab>
          </nb-tabset>
        </div>
      </div>

      <div class="padding"></div>

      <div class="row">

        <div class="col-7">
          <magic-table id="timer-dashboard-HoursTable" title="Apontamentos" [data]="timeRecordsTable_data"
            [columns]="timeRecordsTable_columns">
          </magic-table>
        </div>

        <div class="col-5">
          <div class="total-container">
            <div class="label">
              Horas apontadas
            </div>
            <div class="value">
              {{totalHours}}h {{totalMin}}m
            </div>

          </div>
          <timer-week-hours-pie-doughnut-chart #TimerWeekHoursPieDoughnutChart_REF>
          </timer-week-hours-pie-doughnut-chart>
        </div>

      </div>
    </div>
  </div>
</div>
