import { DICTIONARY } from "app/@firebase/firestore/DICTIONARY";

import { SM_DocType } from "./SM_Document";

export type SM_UserAuthority =
  /** Common user */
  "user" |
  /** Requester */
  "caller" |
  /** User assigned to at least one service team */
  "analyst" |
  /** User to which the ticket is assigned */
  "proprietary-analyst";

export const SM_USER_AUTHORITY_TYPES = [
  {
    id: "user",
    icon: "bulb-outline",
    color: "",
    name: "Usuário comum",
    description: "Pessoa autorizada a exibir chamados"
  },
  {
    id: "caller",
    icon: "bulb",
    color: "orange",
    name: "Solicitante",
    description: "Usuario a qual a solicitação atenderá"
  },
  {
    id: "analyst",
    icon: "flash-outline",
    color: "",
    name: "Analista",
    description: "Usuário atribuído a pelo menos uma equipe de serviço"
  },
  {
    id: "proprietary-analyst",
    icon: "flash",
    color: "red",
    name: "Analista Proprietário",
    description: "Usuário ao qual o ticket é atribuído"
  },
];

export type SM_DocState =
  'new' |
  'work_in_progress' |
  'awainting_assignment' |
  'awainting_approval' |
  'work_scheduled' |
  'pending_caller' |
  'pending_vendor' |
  'pending_problem' |
  'pending_change' |
  'resolved' |
  'closed' |
  'canceled' |
  'assess' |
  'authorize' |
  'scheduled' |
  'implement' |
  'review';

export type SM_DocStateField = 'assignedTeamId' | 'assignedToUName' | 'note';

export class SM_DocStateData {
  id: SM_DocState;
  name: string;
  docTypes: SM_DocType[] | '*';
  prevStates: SM_DocState[];
  userColor: string;
  sysColor: string;
  final: boolean;
  requiredFields: SM_DocStateField[];
  public constructor(init?: Partial<SM_DocStateData>) {
    this.id = null;
    this.name = '';
    this.docTypes = [];
    this.prevStates = [];
    this.userColor = '';
    this.sysColor = '';
    this.final = false;
    this.requiredFields = [];
    if (init)
      Object.assign(this, init);
  }
}

const STATES: SM_DocStateData[] = [
  {
    id: 'new',
    name: '',
    docTypes: '*',
    prevStates: [],
    userColor: 'gren',
    sysColor: 'yellow',
    final: false,
    requiredFields: []
  },
  {
    id: 'work_in_progress',
    name: '',
    docTypes: ['incident', 'problem', 'request', 'task'],
    prevStates: ['new', 'awainting_assignment', 'work_scheduled', 'pending_caller', 'pending_vendor', 'pending_change', 'pending_problem'],
    userColor: 'gren',
    sysColor: 'gren',
    final: false,
    requiredFields: ['assignedTeamId', 'assignedToUName']
  },
  {
    id: 'awainting_assignment',
    name: '',
    docTypes: ['incident', 'problem', 'request', 'task'],
    prevStates: ['work_in_progress'],
    userColor: 'gren',
    sysColor: 'yellow',
    final: false,
    requiredFields: ['assignedTeamId']
  },
  {
    id: 'work_scheduled',
    name: '',
    docTypes: ['incident', 'problem', 'request', 'task'],
    prevStates: ['work_in_progress'],
    userColor: 'gren',
    sysColor: 'gren',
    final: false,
    requiredFields: ['assignedTeamId', 'assignedToUName', 'note']
  },
  {
    id: 'pending_caller',
    name: '',
    docTypes: '*',
    prevStates: ['work_in_progress'],
    userColor: 'gren',
    sysColor: 'gren',
    final: false,
    requiredFields: ['assignedTeamId', 'assignedToUName', 'note']
  },
  {
    id: 'pending_vendor',
    name: '',
    docTypes: '*',
    prevStates: ['work_in_progress'],
    userColor: 'gren',
    sysColor: 'gren',
    final: false,
    requiredFields: ['assignedTeamId', 'assignedToUName', 'note']
  },
  {
    id: 'pending_change',
    name: '',
    docTypes: '*',
    prevStates: ['work_in_progress'],
    userColor: 'gren',
    sysColor: 'gren',
    final: false,
    requiredFields: ['assignedTeamId', 'assignedToUName', 'note']
  },
  {
    id: 'pending_problem',
    name: '',
    docTypes: '*',
    prevStates: ['work_in_progress'],
    userColor: 'gren',
    sysColor: 'gren',
    final: false,
    requiredFields: ['assignedTeamId', 'assignedToUName', 'note']
  },
  {
    id: 'resolved',
    name: '',
    docTypes: '*',
    prevStates: ['work_in_progress'],
    userColor: 'gren',
    sysColor: 'gren',
    final: true,
    requiredFields: ['assignedTeamId', 'assignedToUName']
  },
  {
    id: 'review',
    name: '',
    docTypes: '*',
    prevStates: ['authorize', 'resolved'],
    userColor: 'gren',
    sysColor: 'gren',
    final: false,
    requiredFields: ['assignedTeamId', 'assignedToUName']
  },
  {
    id: 'closed',
    name: '',
    docTypes: '*',
    prevStates: ['resolved', 'review'],
    userColor: 'gren',
    sysColor: 'gren',
    final: true,
    requiredFields: ['assignedTeamId', 'assignedToUName']
  },
  {
    id: 'canceled',
    name: '',
    docTypes: '*',
    prevStates: ['new', 'authorize', 'implement', 'awainting_assignment', 'work_in_progress'],
    userColor: 'gren',
    sysColor: 'gren',
    final: true,
    requiredFields: ['assignedTeamId', 'assignedToUName']
  },
  {
    id: 'assess',
    name: '',
    docTypes: ['change'],
    prevStates: ['new'],
    userColor: 'gren',
    sysColor: 'gren',
    final: false,
    requiredFields: ['assignedTeamId', 'assignedToUName']
  },
  {
    id: 'authorize',
    name: '',
    docTypes: ['change'],
    prevStates: ['assess'],
    userColor: 'gren',
    sysColor: 'gren',
    final: false,
    requiredFields: []
  },
  {
    id: 'scheduled',
    name: '',
    docTypes: ['change'],
    prevStates: ['authorize'],
    userColor: 'gren',
    sysColor: 'gren',
    final: false,
    requiredFields: ['assignedTeamId', 'assignedToUName', 'note']
  },
  {
    id: 'implement',
    name: '',
    docTypes: ['change'],
    prevStates: ['authorize'],
    userColor: 'gren',
    sysColor: 'gren',
    final: true,
    requiredFields: ['assignedTeamId', 'assignedToUName']
  },
];


export function getStates(): SM_DocStateData[] {
  let dic = new DICTIONARY();
  let states = [];
  STATES.forEach(s => {
    s.name = dic.translator(s.id, dic.serviceManagement.documents.state);
    states.push(s);
  })
  return states;
}
export function getStateById(id: string): SM_DocStateData {
  let state = null;
  for (let s of getStates()) {
    if (s.id == id) {
      state = s;
      break;
    }
  }
  return state;
}
export function get_DocNextStates(docType: SM_DocType, actualState: SM_DocState): SM_DocStateData[] {
  return getStates().filter(s => {
    return ((s.docTypes == '*' || s.docTypes.indexOf(docType) != -1) && s.prevStates.indexOf(actualState) != -1);
  });
}
// export function get_fieldStatus(docType: SM_DocType, docState: SM_DocState, field: SM_DocStateField): { required: boolean, noChangeble: boolean } {
//   let status = { close: false, required: false, noChangeble: false };
//   for (let s of STATES) {
//     if ((s.docTypes == '*' || s.docTypes.indexOf(docType) != -1) && s.id == docState) {
//       status.required = s.requiredFields.indexOf(field) != -1;
//       // status.noChangeble = s.noChangebleFields.indexOf(field) != -1;
//       break
//     }
//   }
//   return status;
// }

