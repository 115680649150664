import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { __saveAsExcel } from './db-table-subroutines/db-table_excel';
import { __cleanFilter, __filter, __initFilterIndexes, __toogleAll, __toogleAllSearchResult, __updateFilterIndexes, __updateFiltredRows, __updateSearchResult, __updateSelectAll, __updateSelectAllSearchResult, _getFilterStatus } from './db-table-subroutines/db-table_filter';
import { __closeHeaderPopDown, __openHeaderPopDown, __sort } from './db-table-subroutines/db-table_headerPopDown';
import { DbTable, DbTableColumn, DbTableDataChanges, DbTableFilterIndexType, DbTableInput, DbTableRowData, DbTableRowSelectOption, DbTableSelectData, DbTableSelectOptions } from './types';

@Component({
  selector: 'db-table',
  templateUrl: './db-table.component.html',
  styleUrls: ['./db-table.component.scss'],
})
export class DbTableComponent implements OnInit, OnChanges {
  id: string = 'tid-' + this._generateRandom(6);
  data: any[] = [];
  dataToDelete: any[] = [];
  aditionalData: any = {};

  @Input() itensPerPage: number = 10;
  @Input() columns: DbTableColumn[] = [];

  table: DbTable = new DbTable();
  rowsNumIndex: {};
  filtredRows: DbTableRowData[] = [];
  rowsForDisplay: DbTableRowData[] = [];

  actualPage: number = 0;
  lastPage: number = 0;
  input_page: number = 0;

  @Input() edit = false;
  @Input() canCreate = true;
  @Input() canDelete = true;
  @Input() canDuplicate = false;
  @Input() canMoveItems = false;
  @Input() loader = false;

  @Output() onChange = new EventEmitter();
  @Input() hasChanges = false;
  @Output() hasChangesChange = new EventEmitter();
  @Input() hasError = false;
  @Output() hasErrorChange = new EventEmitter();

  showOptionsPopDown = -1;
  @ViewChild('OptionPopDownContentERef', { read: ElementRef, static: false })
  optionPopDownContentERef: ElementRef;

  // Header PopDown
  showHeaderPopDown = -1;
  @ViewChild('HeaderPopDownContentERef', { read: ElementRef, static: false })
  headerPopDownContentERef: ElementRef;
  searchText = '';
  filterActiveIndex: boolean[] = [];
  filterIndexes: DbTableFilterIndexType[][] = [];
  filterIndex4Sel: DbTableFilterIndexType[] = [];
  filterSelectAll = true;
  filterSelectAllSearchResult = true;
  filterAddCurSelTofilter = false;
  // Header PopDown

  queue_workingOnPromise = false;
  queue_promises = [];

  constructor(private _sanitizer: DomSanitizer) { }
  @HostListener('window:keydown.esc', ['$event'])
  on_keydown_esc() {
    this._closeOptionsPopDown();
    this.__closeHeaderPopDown();
  }
  @HostListener('window:keydown.l', ['$event'])
  on_keydown_l() {
    if (
      this.showHeaderPopDown != -1 &&
      this.filterActiveIndex[this.showHeaderPopDown]
    )
      this._cleanFilter(this.showHeaderPopDown);
  }
  @HostListener('window:keydown.enter', ['$event'])
  on_keydown_enter() {
    if (this.showHeaderPopDown != -1 && this.filterIndex4Sel.length > 0)
      this._filter(this.showHeaderPopDown);
  }
  @HostListener('document:mousedown', ['$event'])
  on_mousedown(event: MouseEvent): void {
    if (
      this.optionPopDownContentERef &&
      !this.optionPopDownContentERef.nativeElement.contains(event.target)
    ) {
      this._closeOptionsPopDown();
    }
    if (
      this.headerPopDownContentERef &&
      !this.headerPopDownContentERef.nativeElement.contains(event.target)
    ) {
      this.__closeHeaderPopDown();
    }
  }
  _trustHtml(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }
  _trackByFn(index, item) {
    return index;
  }
  private _generateRandom(length: number): string {
    var s = '';
    var r = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (var i = 0; i < length; i++) {
      s += r.charAt(Math.floor(Math.random() * r.length));
    }
    return s;
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    // for (const propName in changes) {
    //   if (propName == 'data') {
    //     this.initTableData();
    //   }
    // }
  }
  execQueue() {
    if (this.queue_promises.length > 0 && !this.queue_workingOnPromise) {
      this.queue_workingOnPromise = true;
      this.loader = true;
      let promise = this.queue_promises.shift() as any;
      promise()
        .then(() => {
          this.queue_workingOnPromise = false;
          this.loader = false;
          if (this.queue_promises.length > 0) this.execQueue();
        })
        .catch((err) => {
          this.queue_workingOnPromise = false;
          this.loader = false;
          console.error('execQueue ERROR', err);
        });
    } else {
      setTimeout(() => {
        this.execQueue();
      }, 200);
    }
  }
  ngOnInit() { }

  // ------------------------------------------------- L O A D
  load(data: any[], aditionalData?: any) {
    this.dataToDelete = [];
    this.actualPage = 1;
    this.input_page = this.actualPage;
    this.data = JSON.parse(JSON.stringify(data));
    if (aditionalData) this.aditionalData = aditionalData;
    this.__initCols();
    this.__initTableRows();
    __initFilterIndexes(this);
    __updateFiltredRows(this);
    this.__updateRowsForDisplay();
    this.hasChanges = false;
    this.hasChangesChange.emit(this.hasChanges);
    this.hasError = false;
    this.hasErrorChange.emit(this.hasError);
  }
  /**
   * Inicia as colunas
   */
  private __initCols() {
    let tempCols: DbTableColumn[] = [];
    for (let col of this.columns) {
      let tempCol = new DbTableColumn();
      tempCol.title = col.title ? col.title : tempCol.title;
      tempCol.fieldName = col.fieldName ? col.fieldName : tempCol.fieldName;
      tempCol.width = col.width ? col.width : tempCol.width;
      tempCol.type = col.type ? col.type : tempCol.type;
      tempCol.canChange =
        col.canChange != undefined ? col.canChange : tempCol.canChange;
      // -- Input
      if (col.input) {
        tempCol.input = new DbTableInput();
        tempCol.input.type = col.input.type
          ? col.input.type
          : tempCol.input.type;

        tempCol.input.minlength = col.input.minlength;
        tempCol.input.maxlength = col.input.maxlength;
        tempCol.input.min = col.input.min;
        tempCol.input.max = col.input.max;

        tempCol.input.pattern = col.input.pattern
          ? col.input.pattern
          : tempCol.input.pattern;
        tempCol.input.required = col.input.required
          ? col.input.required
          : tempCol.input.required;
        tempCol.input.disabled = col.input.disabled
          ? col.input.disabled
          : tempCol.input.disabled;
        tempCol.input.onChange = col.input.onChange
          ? col.input.onChange
          : tempCol.input.onChange;

        // ---- selectOptions
        if (col.input.selectOptions) {
          tempCol.input.selectOptions.optionsData = col.input.selectOptions
            .optionsData
            ? col.input.selectOptions.optionsData
            : tempCol.input.selectOptions.optionsData;

          tempCol.input.selectOptions.optionsFilter = col.input.selectOptions
            .optionsFilter
            ? col.input.selectOptions.optionsFilter
            : tempCol.input.selectOptions.optionsFilter;

          tempCol.input.selectOptions.optionValue = col.input.selectOptions
            .optionValue
            ? col.input.selectOptions.optionValue
            : tempCol.input.selectOptions.optionValue;
          tempCol.input.selectOptions.optionName = col.input.selectOptions
            .optionName
            ? col.input.selectOptions.optionName
            : tempCol.input.selectOptions.optionName;
        }
        // ---- selectOptions
      }
      // -- Input
      tempCol.valuePrepareFunction = col.valuePrepareFunction
        ? col.valuePrepareFunction
        : tempCol.valuePrepareFunction;
      tempCols.push(tempCol);
    }
    this.table.cols = tempCols;
  }
  /**
   * Inicia as linhas sem levar em consideração os filtros
   */
  private __initTableRows() {
    let tempRows: DbTableRowData[] = [];
    let tempRowsNumIndex = {};
    let pageN = 1;
    let lastPageItens = 0;
    for (let d of this.data) {
      let row = new DbTableRowData();
      row.id = `${this.id}_${this._generateRandom(10)}`;
      row.page = pageN;
      row.status = 'old';
      row.objData = d;
      row.number = tempRows.length + 1;
      for (let c_index = 0; c_index < this.table.cols.length; c_index++) {
        const col = this.table.cols[c_index];
        let value = row.objData[col.fieldName];

        if (value == undefined) value = null;

        row.originalCellsData.push(value);
        row.cellsData.push(value);

        let cell = col.valuePrepareFunction(
          value,
          row.objData,
          this.aditionalData
        );
        row.cells.push(cell);

        row.selectOptions.push([]);
      }
      tempRows.push(row);
      tempRowsNumIndex[row.id] = row.number;
      lastPageItens++;
      if (lastPageItens == this.itensPerPage) {
        pageN++;
        lastPageItens = 0;
      }
    }
    this.lastPage =
      Math.ceil(tempRows.length / this.itensPerPage) > 0
        ? Math.ceil(tempRows.length / this.itensPerPage)
        : 1;

    // init selectOptions
    for (let row_index = 0; row_index < tempRows.length; row_index++) {
      for (let c_index = 0; c_index < this.table.cols.length; c_index++) {
        tempRows[row_index].selectOptions[c_index] = this._rowSelectOptionsGen(
          this.table.cols[c_index].input,
          tempRows[row_index].cellsData[c_index],
          tempRows[row_index].objData,
          { rows: tempRows, cols: this.table.cols },
          this.aditionalData
        );
      }
    }
    // init selectOptions

    this.table.rows = tempRows;
    this.rowsNumIndex = tempRowsNumIndex;
  }

  /**
   * Atualiza as linhas que são mostradas no HTML de acordo com a pagina selecionada e apartir das linhas filtradas
   */
  __updateRowsForDisplay() {
    let tempRows: DbTableRowData[] = [];
    for (let r of this.filtredRows)
      if (r.page == this.actualPage) tempRows.push(r);
    this.rowsForDisplay = tempRows;
  }
  // ------------------------------------------------- L O A D

  // ------------------------------------------------- G E N E R A L
  public _compareFn(o1, o2): boolean {
    let result = false;
    if (o1 === null && o2 === null) result = true;
    else if (o1 != null && o2 != null) {
      if (o1 === o2) result = true;
      else if (o1.id != null && o2.id != null && o1.id === o2.id) result = true;
    }
    return result;
  }
  public _rowSelectOptionsGen(input: DbTableInput, cellData?: any, row?: any, table?: DbTable, aditionalData?: {}): DbTableRowSelectOption[] {
    if (!input || input.type != 'select' || !input.selectOptions) return [];

    let selOpt = new DbTableSelectOptions(input.selectOptions);
    let tempData = selOpt.optionsFilter(cellData, row, selOpt.optionsData, table, aditionalData);
    let tempOptions4Sel: DbTableRowSelectOption[] = [];
    tempData.forEach((data) => {
      tempOptions4Sel.push({
        name: selOpt.optionName(data),
        value: selOpt.optionValue(data),
      });
    });
    return tempOptions4Sel;
  }

  public _validateSelectOption(selectedOption: string, options: DbTableRowSelectOption[]): boolean {
    if (!selectedOption || selectedOption.length == 0 || options.length == 0)
      return true;

    let valid = false;
    for (let o of options) {
      if (this._compareFn(selectedOption, o.value)) {
        valid = true;
        break;
      }
    }
    return valid;
  }
  public createLine(data: any) {
    this.loader = true;
    this.queue_promises.push(() => this._createLine(data));
    this.execQueue();
  }
  private _createLine(data: any): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        let row = new DbTableRowData();
        row.status = 'new';
        row.hasError = true;
        row.number = this.table.rows.length + 1;
        let lastPageItens = this.table.rows.filter((r) => {
          return r.page == this.lastPage;
        });
        if (lastPageItens.length + 1 > this.itensPerPage) this.lastPage++;
        row.page = this.lastPage;
        let virtualObject = {};

        for (let c_index = 0; c_index < this.table.cols.length; c_index++) {
          const col = this.table.cols[c_index];

          let value = data[col.fieldName];

          if (value == undefined) value = null;

          if (col.fieldName != '') {
            if (col.input) {
              if (col.input.type == 'text' && value == null) value = '';
              else if (col.input.type == 'number' && value == null) value = 0;
            }
            virtualObject[col.fieldName] = value;
          }

          row.originalCellsData.push(value);
          row.cellsData.push(value);

          let cell = col.valuePrepareFunction(
            value,
            virtualObject,
            this.aditionalData
          );
          row.cells.push(cell);

          if (col.canChange)
            row.selectOptions[c_index] = this._rowSelectOptionsGen(this.table.cols[c_index].input, row.cellsData[c_index], row.objData, this.table, this.aditionalData);
        }
        row.objData = virtualObject;
        this.table.rows.push(row);

        this.__setPage(this.lastPage);
        __updateFiltredRows(this);
        this.__updateRowsForDisplay();

        this._syncFormValidWithHtm(row.number)
          .then(() => {
            this.__updateHasError();
            this.__updateHasChanges();
            this.__onChangeEmitter();

            resolve();
          })
          .catch((e) => reject(e));
      } catch (e) {
        reject(e);
      }
    });
  }
  private _syncFormValidWithHtm(row_number: number): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        // sync form valid with html

        let row = this.table.rows[row_number - 1];
        let formId = `${this.id}_rowForm_${row_number}`;
        let formElem = document.querySelector('.' + formId);

        if (formElem) {
          let formValid = formElem.classList.contains('ng-valid');
          row.hasError = !formValid;
          resolve();
        } else
          setTimeout(() => {
            resolve(this._syncFormValidWithHtm(row_number));
          }, 250);

        // sync form valid with html
      } catch (err) {
        reject(err);
      }
    });
  }

  _addLine() {
    let row = new DbTableRowData();
    row.status = 'new';
    row.hasError = true;
    row.number = this.table.rows.length + 1;
    let lastPageItens = this.table.rows.filter((r) => {
      return r.page == this.lastPage;
    });
    if (lastPageItens.length + 1 > this.itensPerPage) this.lastPage++;
    row.page = this.lastPage;
    let virtualObject = {};
    for (let c_index = 0; c_index < this.table.cols.length; c_index++) {
      const col = this.table.cols[c_index];
      let value = null;
      if (col.fieldName != '') {
        if (col.input) {
          if (col.input.type == 'text') value = '';
          else if (col.input.type == 'number') value = 0;
        }
        virtualObject[col.fieldName] = value;
      }

      row.originalCellsData.push(value);
      row.cellsData.push(value);

      let cell = col.valuePrepareFunction(
        value,
        virtualObject,
        this.aditionalData
      );
      row.cells.push(cell);

      row.selectOptions[c_index] = this._rowSelectOptionsGen(
        this.table.cols[c_index].input,
        row.cellsData[c_index],
        row.objData,
        this.table,
        this.aditionalData
      );
    }
    row.objData = virtualObject;
    this.table.rows.push(row);
    this.__updateHasError();
    this.__updateHasChanges();
    this.__onChangeEmitter();
    this.__setPage(this.lastPage);
    __updateFiltredRows(this);
    this.__updateRowsForDisplay();
  }

  _rowChange(dr_index: number, c_index: number, line_form_valid: boolean) {
    this.rowsForDisplay[dr_index].hasError = !line_form_valid;

    const col = this.table.cols[c_index];

    this.rowsForDisplay[dr_index].objData[col.fieldName] =
      this.rowsForDisplay[dr_index].cellsData[c_index];

    if (
      this.rowsForDisplay[dr_index].status == 'old' ||
      this.rowsForDisplay[dr_index].status == 'changed'
    )
      this.rowsForDisplay[dr_index].status =
        this.rowsForDisplay[dr_index].cellsData[c_index] !=
          this.rowsForDisplay[dr_index].originalCellsData[c_index]
          ? 'changed'
          : 'old';

    for (let _c_index = 0; _c_index < this.table.cols.length; _c_index++) {
      const col = this.table.cols[_c_index];
      let value = this.rowsForDisplay[dr_index].objData[col.fieldName];
      if (!col.input) {
        let cell = col.valuePrepareFunction(
          value,
          this.rowsForDisplay[dr_index].objData,
          this.aditionalData
        );
        this.rowsForDisplay[dr_index].cells[_c_index] = cell;
      }
      if (col.input !== undefined && _c_index == c_index) {
        col.input.onChange(
          value,
          this.rowsForDisplay[dr_index],
          this.aditionalData
        );
      }
      this.rowsForDisplay[dr_index].selectOptions[_c_index] =
        this._rowSelectOptionsGen(
          this.table.cols[_c_index].input,
          this.rowsForDisplay[dr_index].cellsData[_c_index],
          this.rowsForDisplay[dr_index].objData,
          this.table,
          this.aditionalData
        );
    }

    this.__updateHasError();
    this.__updateHasChanges();

    __updateFilterIndexes(this, c_index);

    this.__onChangeEmitter();
  }
  private __onChangeEmitter() {
    this.onChange.emit(this.getDataChanges());
  }
  private __updateHasError() {
    let hasError = false;
    for (let r of this.table.rows) {
      if (r.hasError) {
        hasError = true;
        break;
      }
    }
    if (this.hasError != hasError) this.hasErrorChange.emit(hasError);
    this.hasError = hasError;
  }
  private __updateHasChanges() {
    let hasChanges = false;
    if (this.dataToDelete.length > 0) hasChanges = true;
    else {
      for (let r of this.table.rows) {
        if (r.status == 'changed' || r.status == 'new') {
          hasChanges = true;
          break;
        }
      }
    }
    if (this.hasChanges != hasChanges) this.hasChangesChange.emit(hasChanges);
    this.hasChanges = hasChanges;
  }

  // -> Options
  _openOptionsPopDown(r_index: number) {
    this.showOptionsPopDown = r_index;
  }
  _closeOptionsPopDown() {
    this.showOptionsPopDown = -1;
  }
  private _syncRowsNumbers() {
    for (let i = 0; i < this.table.rows.length; i++)
      this.table.rows[i].number = i + 1;
  }
  // Move Item
  private _moveItem(vet: any[], from: number, to: number): any[] {
    let tempVet = JSON.parse(JSON.stringify(vet));
    // remove `from` item and store it
    var f = tempVet.splice(from, 1)[0];
    // insert stored item into position `to`
    tempVet.splice(to, 0, f);
    return tempVet;
  }
  private _rowNumberChange(r_id: string) {
    let row = new DbTableRowData();
    let r_index = 0;

    for (r_index = 0; r_index < this.table.rows.length; r_index++) {
      if (this.table.rows[r_index].id == r_id) {
        row = this.table.rows[r_index];
        break;
      }
    }

    if (row.status == 'old' || row.status == 'changed') {
      let rowOldNumber = this.rowsNumIndex[row.id];
      if (rowOldNumber != row.number) row.status = 'changed';
      else
        for (let c_index = 0; c_index < this.table.cols.length; c_index++) {
          const col = this.table.cols[c_index];
          row.objData[col.fieldName] = row.cellsData[c_index];

          row.status =
            row.cellsData[c_index] != row.originalCellsData[c_index]
              ? 'changed'
              : 'old';
          if (row.status == 'changed') break;
        }
    }

    this.table.rows[r_index].status = row.status;
  }
  _moveItem_rowUp(r_number: number) {
    let fromIndex = r_number - 1;
    let toIndex = fromIndex - 1;
    let fromId = this.table.rows[fromIndex].id;
    let toId = this.table.rows[toIndex].id;

    this.table.rows = this._moveItem(this.table.rows, fromIndex, toIndex);

    this._syncRowsNumbers();

    this._rowNumberChange(fromId);
    this._rowNumberChange(toId);

    this.__updateHasChanges();
    this.__onChangeEmitter();
    __updateFiltredRows(this);
    this.__updateRowsForDisplay();
    this.showOptionsPopDown = -1;
  }
  _moveItem_rowDown(r_number: number) {
    let fromIndex = r_number - 1;
    let toIndex = fromIndex + 1;
    let fromId = this.table.rows[fromIndex].id;
    let toId = this.table.rows[toIndex].id;

    this.table.rows = this._moveItem(this.table.rows, fromIndex, toIndex);

    this._syncRowsNumbers();

    this._rowNumberChange(fromId);
    this._rowNumberChange(toId);

    this.__updateHasChanges();
    this.__onChangeEmitter();
    __updateFiltredRows(this);
    this.__updateRowsForDisplay();
    this.showOptionsPopDown = -1;
  }
  // Move Item
  _tryDelete(r_number: number) {
    let r = null;
    let tableData = [];
    for (let tr of this.table.rows) {
      if (tr.number == r_number) r = tr.objData;
      tableData.push(tr.objData);
    }
    this._deleteRow(r_number);
  }
  _deleteRow(r_number: number) {
    let tempRows: DbTableRowData[] = [];
    let pageN = 1;
    for (let i = 0; i < this.table.rows.length; i++) {
      let tempRow = JSON.parse(
        JSON.stringify(this.table.rows[i])
      ) as DbTableRowData;
      if (this.table.rows[i].number != r_number) {
        tempRow.page = pageN;
        tempRow.number = tempRows.length + 1;
        tempRows.push(tempRow);
        if (tempRows.length == this.itensPerPage) pageN++;
      } else {
        if (tempRow.status == 'old' || tempRow.status == 'changed')
          this.dataToDelete.push(tempRow.objData);
      }
    }
    this.lastPage =
      Math.ceil(tempRows.length / this.itensPerPage) > 0
        ? Math.ceil(tempRows.length / this.itensPerPage)
        : 1;
    this.table.rows = tempRows;
    if (this.actualPage < this.lastPage) this.__setPage(this.actualPage);
    else this.__setPage(this.lastPage);
    this.__updateHasError();
    this.__updateHasChanges();
    this.__onChangeEmitter();
    __updateFiltredRows(this);
    this.__updateRowsForDisplay();
    this.showOptionsPopDown = -1;
  }
  _duplicateRow(r_number: number) {
    let tempRows: DbTableRowData[] = [];
    let pageN = 1;
    let lastPageItens = 0;
    let duplicateRownPageN = 1;
    for (let i = 0; i < this.table.rows.length; i++) {
      let tempRow = JSON.parse(
        JSON.stringify(this.table.rows[i])
      ) as DbTableRowData;
      tempRow.page = pageN;
      tempRow.number = tempRows.length + 1;
      tempRows.push(JSON.parse(JSON.stringify(tempRow)));
      if (tempRows.length == this.itensPerPage) pageN++;
      if (this.table.rows[i].number == r_number) {
        tempRow.page = pageN;
        duplicateRownPageN = tempRow.page;
        tempRow.status = 'new';
        tempRow.number = tempRows.length + 1;
        tempRows.push(JSON.parse(JSON.stringify(tempRow)));
        lastPageItens++;
        if (lastPageItens == this.itensPerPage) {
          pageN++;
          lastPageItens = 0;
        }
      }
    }
    this.lastPage = Math.ceil(tempRows.length / this.itensPerPage) > 0 ? Math.ceil(tempRows.length / this.itensPerPage) : 1;
    this.table.rows = tempRows;
    this.__setPage(duplicateRownPageN);
    __updateFiltredRows(this);
    this.__updateRowsForDisplay();
    this.showOptionsPopDown = -1;
  }
  // -> Options
  public getDataChanges(): DbTableDataChanges {
    let finalData = [];
    let dataToCreate = [];
    let dataToUpdate = [];
    for (let r of this.table.rows) {
      finalData.push(r.objData);
      if (r.status == 'new') dataToCreate.push(r.objData);
      else if (r.status == 'changed') dataToUpdate.push(r.objData);
    }
    return {
      finalData: finalData,
      dataToCreate: dataToCreate,
      dataToUpdate: dataToUpdate,
      dataToDelete: this.dataToDelete,
    };
  }
  showObligatoryStamp(input_required: boolean, cellsData) {
    return (
      this.edit && input_required && (cellsData == null || cellsData === '')
    );
  }

  // ------------------------------------------------- G E N E R A L

  // ---------------------- PAGE CONTROLS
  _pageDown() {
    this.actualPage--;
    this.__setPage(this.actualPage);
  }
  _pageUp() {
    this.actualPage++;
    this.__setPage(this.actualPage);
  }
  __setPage(page: number) {
    this.actualPage = page;
    this.input_page = this.actualPage;
    this.__updateRowsForDisplay();
  }
  _update_input_page() {
    if (
      this.input_page &&
      this.input_page > 0 &&
      this.input_page <= this.lastPage
    )
      this.__setPage(this.input_page);
    else this.input_page = this.actualPage;
  }
  // ---------------------- PAGE CONTROLS

  // Header PopDown
  _openHeaderPopDown(c_index: number) { __openHeaderPopDown(this, c_index); }
  __closeHeaderPopDown() { __closeHeaderPopDown(this); }
  _sort(c_index: number, ord: 'asc' | 'desc') { __sort(this, c_index, ord); }

  //  Filter
  _filter(c_index: number) { __filter(this, c_index); }
  _cleanFilter(c_index: number) { __cleanFilter(this, c_index); }
  _updateSearchResult(c_index: number) { __updateSearchResult(this, c_index); }
  _filterIndex4SelShowChange(c_index: number) {
    __updateSelectAll(this, c_index);
    __updateSelectAllSearchResult(this);
  }
  _toogleAll() { __toogleAll(this); }
  _toogleAllSearchResult() { __toogleAllSearchResult(this); }
  // Filter
  // Header PopDown

  // -- Excel
  public saveAsExcel() { __saveAsExcel(this); }
  // -- Excel
}
