import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, NgZone, OnInit, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';

import { AuthService, LayoutService, UtilService } from 'app/@core';
import { FireStorageService, FirestoreService, getPMCardPriorityData, Log, PM_Board, PM_BoardActivity, PM_BoardLabel, PM_BoardRoute, PM_Card, PM_CARD_PRIORITY_DATA_TYPE, PM_ToDo, TM_TimeRecord, User } from 'app/@firebase';
import { ThemeService, UiFeedBackService } from 'app/@theme';
import { MagicTextUserToMention } from 'app/components';
import { TmTimerModalComponent } from 'app/routes/main/time-management/timer-modal/timer-modal.component';
import { TM_IntegrationData } from 'app/routes/main/time-management/timer/timer-list-tab/TM_IntegrationData';

import { PMCardModalActivitiesComponent } from './components/main/activities/activities.component';
import { PMCardModalAddNoteComponent } from './components/main/add-note/add-note.component';
import { PMCardModalRelatedCardsComponent } from './components/main/related-cards/related-cards.component';
import { PMCardModalToDoListsComponent } from './components/main/to-do-lists/to-do-lists.component';
import { PMCardModalModeAssigneesComponent } from './components/side/mode-assignees/mode-assignees.component';
import { PMCardModalModeAttachmentsComponent } from './components/side/mode-attachments/mode-attachments.component';
import { PMCardModalModeDetailsComponent } from './components/side/mode-details/mode-details.component';
import { PMCardModalModeLabelsComponent } from './components/side/mode-labels/mode-labels.component';
import { PMCardModalModeTimeRecordsComponent } from './components/side/mode-time-records/mode-time-records.component';
import { PM_Card_dataChangGen, stringify_relations } from './PM_Card_dataChangGen';
import { PM_CARD_SIDE_MODES, PmCardModalSideMode } from './pm-card-side-modes';
import { boards4Sel_columns, boards4SelDataSourceMaker } from './pm-card-utils';

export type TM_TimeRecordsHoursSum = {
  h: number;
  m: number;
  val: number;
  s: string
};

export function sumTimeRecords(timeRecords: TM_TimeRecord[]): TM_TimeRecordsHoursSum {
  const formatNumber = (num: number) => {
    if (Number.isInteger(num))
      return num.toLocaleString('pt-BR', { maximumFractionDigits: 0 });
    return num.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  let sum: TM_TimeRecordsHoursSum = { h: 0, m: 0, val: 0, s: '' };
  for (let tr of timeRecords) {
    sum.h += tr.durationHours;
    sum.m += tr.durationMinutes;
    if (sum.m >= 60) {
      sum.h++;
      sum.m = sum.m - 60;
    }
    sum.val += tr.duration;
  }
  if (sum.h == 0 && sum.m == 0)
    sum.s = 'Ø';
  if (sum.h > 0)
    sum.s = `${formatNumber(sum.h)}h`;
  if (sum.m > 0)
    sum.s += ` ${formatNumber(sum.m)}m`;
  return sum;
}

@Component({
  selector: 'app-pm-card-modal',
  templateUrl: './pm-card-modal.html',
  styleUrls: ['./pm-card-modal.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PmCardModal implements OnInit, AfterViewInit {
  readonly className = 'PmCardModal';

  toDoIdToFocus: string;
  activityIdToFocus: string;
  localDocPath = this.db.COLLECTIONS.projectManagement.kanban.cards;
  _cardSub = null;
  _timeRecordsSub = null;
  _activeModal = false;
  __deleteCardOnNgDestroy = false;
  __openCardOnNgDestroyFunction = null;

  // main data
  _loader = false;

  public pMCardMainIntersectionObserver!: IntersectionObserver;
  public pMCardMainObserversUnsubscribeFunctions: { [key: string]: () => void } = {};
  public pMCardMainObserversOnShowFunctions: { [key: string]: () => void } = {};

  card = new PM_Card();
  cardTitleToDisplay: string = "";
  cardPriority: PM_CARD_PRIORITY_DATA_TYPE = new PM_CARD_PRIORITY_DATA_TYPE();

  oldCard = new PM_Card();
  cardRoute = new PM_BoardRoute();
  _viewersUsers: User[] = [];

  board = new PM_Board();
  boardRoutes: PM_BoardRoute[] = [];
  boardLabels: PM_BoardLabel[] = [];
  boardMembers: User[] = [];
  magicTextEditorUsersToMention: MagicTextUserToMention[] = [];
  timeRecords: TM_TimeRecord[] = [];

  isUserAdmin = false;
  isBoardActive = false;

  @ViewChild('PMCardModalRelatedCards') _relatedCards: PMCardModalRelatedCardsComponent = new PMCardModalRelatedCardsComponent(this);
  @ViewChild('PMCardModalToDoLists') _toDoLists: PMCardModalToDoListsComponent = new PMCardModalToDoListsComponent(this, this.changeDetector);
  @ViewChild('PMCardModalAddNote') _addNote: PMCardModalAddNoteComponent = new PMCardModalAddNoteComponent(this);
  @ViewChild('PMCardModalActivities') _activities: PMCardModalActivitiesComponent = new PMCardModalActivitiesComponent(this);
  // main data

  // side data
  readonly PM_CARD_SIDE_MODES = PM_CARD_SIDE_MODES;
  selectedSideModeId: PmCardModalSideMode;
  selectedSideModeTitle = "";

  _boards4Sel_source: LocalDataSource = null;
  _boards4Sel_columns = boards4Sel_columns;

  @ViewChild('PMCardModalModeDetails') _modeDetails: PMCardModalModeDetailsComponent = new PMCardModalModeDetailsComponent(this);
  @ViewChild('PMCardModalModeAssignees') _modeAssignees: PMCardModalModeAssigneesComponent = new PMCardModalModeAssigneesComponent(this);
  @ViewChild('PMCardModalModeLabels') _modeLabels: PMCardModalModeLabelsComponent = new PMCardModalModeLabelsComponent(this);
  @ViewChild('PMCardModalModeAttachments') _modeAttachments: PMCardModalModeAttachmentsComponent = new PMCardModalModeAttachmentsComponent(this);
  @ViewChild('PMCardModalModeTimeRecords') _modeTimeRecords: PMCardModalModeTimeRecordsComponent = new PMCardModalModeTimeRecordsComponent(this);
  // side data

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {},
    public mdDialogRef: MatDialogRef<PmCardModal>,

    public changeDetector: ChangeDetectorRef,
    public layoutService: LayoutService,
    public theme: ThemeService,
    public db: FirestoreService,
    public afStorage: AngularFireStorage,
    public router: Router,
    public storage: FireStorageService,
    public authService: AuthService,
    public utilCtrl: UtilService,
    public uiFeedBackCtrl: UiFeedBackService,
  ) {
    if (data) {
      if (data['card'])
        this.card = new PM_Card(data['card']);

      if (data['toDoIdToFocus'])
        this.toDoIdToFocus = data['toDoIdToFocus'];

      if (data['activityIdToFocus'])
        this.activityIdToFocus = data['activityIdToFocus'];

      if (data['board']) {
        this.board = new PM_Board(data['board']);
        this.isUserAdmin = this.board.admins.includes(this.authService.localUser.uName);
        this.isBoardActive = this.board.status == 1;
      }

      if (data['boardRoutes']) {
        this.boardRoutes = this.board.routes.map((routeId) => { return data['boardRoutes'].find((r) => { return r.id == routeId }) });
        const cardRoute = this.boardRoutes.find((r) => { return r.id == this.card.routeId });
        this.cardRoute = new PM_BoardRoute(cardRoute);
      }

      if (data['boardLabels'])
        this.boardLabels = data['boardLabels'];

      if (data['boardMembers']) {
        this.boardMembers = data['boardMembers'];
        this.magicTextEditorUsersToMention = this.boardMembers.map((user) => { return { id: `@${user.uName}`, reference: user.uName, name: user.fullName, pictureUrl: user.picture } as MagicTextUserToMention })
      }
    }
  }
  @HostListener('window:keydown.esc', ['$event'])
  onEsc() {
    if (!this._activeModal)
      this.safeClose();
  }

  public closePMCardMainObservers() {
    Object.values(this.pMCardMainObserversUnsubscribeFunctions).forEach((func) => func());

    if (this.pMCardMainIntersectionObserver) {
      this.pMCardMainIntersectionObserver.disconnect();
      this.pMCardMainIntersectionObserver = undefined;
    }
  }
  ngOnInit() {
    if (this.board.privateCards && !this.isUserAdmin && !this.card.assignedTo.includes(this.authService.localUser.uName)) {
      this._close();
      this.uiFeedBackCtrl.presentAlert("Acesso Negado", "Você não tem permissão para acessar este cartão privado, pois não faz parte dos membros dele. Por favor, entre em contato com o administrador do quadro para mais informações.", 'warning', true);
      return;
    }
    this.openCardSub();
    this._changeSideMode(this.PM_CARD_SIDE_MODES[0].id as any, this.PM_CARD_SIDE_MODES[0].title);
    this.addViewer();
  }
  ngAfterViewInit() {
    this._textarea_autosize('cardDescriptionInput');
  }
  ngOnDestroy() {
    this.closePMCardMainObservers();
    this.closeCardSub();
    this.closetimeRecordsSub();
    this.removeViewer();
    if (this.__deleteCardOnNgDestroy)
      this._delete();
    if (this.__openCardOnNgDestroyFunction)
      this.__openCardOnNgDestroyFunction();
  }
  private openCardSub() {
    if (!this.card || this.card.id == '')
      return;

    // CardSub
    this._cardSub = this.db.projectManagement.kanban.cards.valueChanges(this.card.id)
      .subscribe(docData => {

        if (docData == undefined && !this.__deleteCardOnNgDestroy) {
          this.uiFeedBackCtrl.presentAlert('Erro', `O ${this.card.id} não Existe!`, 'error', true);
          return;
        }

        this.card = new PM_Card(docData);

        // related Card List Load
        let oldCard_relationsStr = stringify_relations(this.oldCard.relatedCardsIds, this.utilCtrl);
        let card_relationsStr = stringify_relations(this.card.relatedCardsIds, this.utilCtrl);
        if (oldCard_relationsStr != card_relationsStr)
          this._relatedCards.load();

        this._initOldCard();

        this.load();
      },
        (e) => {
          this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
        }
      );
    // CardSub
  }
  private closeCardSub() {
    if (this._cardSub != null)
      this._cardSub.unsubscribe();
  }

  private openTimeRecordsSub(): Promise<void> {
    return new Promise((resolve) => {
      // TimeRecordsSub
      this._timeRecordsSub = this.db.timeManagement.timeRecords.ref
        .where('reference', '==', this.card.boardId)
        .where('reference1', '==', this.card.id)
        .onSnapshot(
          (querySnapshot) => {
            this.timeRecords = querySnapshot.docs.map((doc) => new TM_TimeRecord(doc.data()));
            if (this._modeTimeRecords)
              this._modeTimeRecords.load();
            if (this._modeDetails)
              this._modeDetails.__updateTimeRecordSum4Card();
            if (this._toDoLists)
              this._toDoLists.__updateTimeRecordMap();
            if (this._activities)
              this._activities.__updateTimeRecordMap();
          },
          (e) => {
            this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
          }
        );
      resolve();
      // TimeRecordsSub
    })
  }
  private closetimeRecordsSub() {
    if (this._timeRecordsSub != null)
      this._timeRecordsSub();
  }

  // viewers
  addViewer() {
    this.db.projectManagement.kanban.cards.update(this.card.id, { viewers: this.db.fieldValue.arrayUnion(this.authService.localUser.uName) as any })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro ao salvar view no Banco de dados!', e)
      })
  }
  removeViewer() {
    if (this.__deleteCardOnNgDestroy)
      return;

    this.db.projectManagement.kanban.cards.update(this.card.id, { viewers: this.db.fieldValue.arrayRemove(this.authService.localUser.uName) as any })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro ao salvar view no Banco de dados!', e)
      })
  }
  // viewers

  // subscribe
  _toggleSubscribe() {
    this._loader = true;

    const uName = this.authService.localUser.uName;
    const subscriber = this.card.subscribers.includes(uName);

    // Transaction
    this.db
      .runTransaction((transaction) => {

        if (!subscriber)
          transaction.update(
            this.db.afs.firestore.collection(this.db.COLLECTIONS.projectManagement.kanban.cards).doc(this.card.id),
            { subscribers: this.db.fieldValue.arrayUnion(uName) as any }
          );
        else
          transaction.update(
            this.db.afs.firestore.collection(this.db.COLLECTIONS.projectManagement.kanban.cards).doc(this.card.id),
            { subscribers: this.db.fieldValue.arrayRemove(uName) as any }
          );

        return Promise.resolve();
      })
      .then(() => {
        // local
        if (!subscriber)
          this.card.subscribers.push(uName);
        else
          this.card.subscribers = this.card.subscribers.filter((s) => { return s != uName });

        this._loader = false;
      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro ao salvar subscrição no Banco de dados!', e);
      })
    // Transaction
  }
  // subscribe

  load() {
    this.cardTitleToDisplay = this.card.title.length > 30 ? this.card.title.substring(0, 25) + '[...]' : this.card.title;

    // Priority
    this.cardPriority = new PM_CARD_PRIORITY_DATA_TYPE();
    if (this.card.priority)
      this.cardPriority = getPMCardPriorityData(this.card.priority);
    // Priority

    // viewersUsers
    let tempViewersUsers = [];
    this.card.viewers
      .forEach((viewerUname) => {
        let tempUser = this.boardMembers.find(u => u.uName == viewerUname);
        if (tempUser)
          tempViewersUsers.push(tempUser);
      })

    this._viewersUsers = tempViewersUsers;
    // viewersUsers

    let promises = [];
    promises.push(
      this.__syncBoardAndCardMembers()
    );
    promises.push(
      this.__syncBoardAndCardLabels()
    );
    if (this.board.integration.tm.active)
      promises.push(
        this.openTimeRecordsSub()
      );

    Promise.all(promises)
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }
  private __syncBoardAndCardMembers(): Promise<void> {
    return new Promise((resolve, reject) => {
      let oldData = JSON.stringify(this.card.assignedTo);

      let boardMembersUnames = [];
      this.boardMembers
        .forEach(boardMember => {
          boardMembersUnames.push(boardMember.uName);
        })

      this.card.assignedTo = this.card.assignedTo.filter(assigneeUname => { return boardMembersUnames.indexOf(assigneeUname) != -1 });

      if (oldData != JSON.stringify(this.card.assignedTo)) {
        this.db.projectManagement.kanban.cards
          .update(this.card.id,
            {
              assignedTo: this.card.assignedTo
            }
          )
          .then(() => resolve())
          .catch(e => reject(e))
      } else {
        resolve();
      }
    });
  }
  private __syncBoardAndCardLabels(): Promise<void> {
    return new Promise((resolve, reject) => {
      let oldData = JSON.stringify(this.card.labelsIds);

      this.card.labelsIds = this.card.labelsIds.filter((labelId) => { return this.board.labels.includes(labelId); });

      if (oldData != JSON.stringify(this.card.labelsIds)) {
        this.db.projectManagement.kanban.cards
          .update(this.card.id,
            {
              labelsIds: this.card.labelsIds
            }
          )
          .then(() => resolve())
          .catch(e => reject(e))
      } else {
        resolve();
      }
    })
  }
  safeClose(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this._addNote._noteText.length > 0) {
        this.uiFeedBackCtrl.presentCustonAlert({
          title: 'Sair Sem Salvar?',
          message: `Atenção: Voce possui mudanças não salvas. Presione Cancelar para voltar e salvar estas mudanças, ou OK para sair sem salvar.`,
          buttons: [
            {
              text: 'Cancelar',
              icon: "close-outline",
              status: "primary",
              handler: () => resolve(false)
            },
            {
              needFormValid: true,
              text: 'Sair',
              icon: "log-out",
              status: "danger",
              handler: () => resolve(this._close())
            }
          ],
          closeButtonHandler: () => resolve(false)
        })
      } else
        resolve(this._close());
    });
  }
  _close(data?): Promise<boolean> {
    return new Promise((resolve) => {
      this.mdDialogRef.close(data);
      resolve(true);
    });
  }
  public _initOldCard() {
    this.oldCard = JSON.parse(JSON.stringify(this.card));
  }
  private _needSave(): boolean {
    return this.oldCard.title != this.card.title ||
      this.oldCard.description != this.card.description ||
      this.oldCard.status != this.card.status ||

      this.oldCard.priority != this.card.priority ||

      this.utilCtrl.timestamp.toLocalDate(this.oldCard.startDate) != this.utilCtrl.timestamp.toLocalDate(this.card.startDate) ||
      this.oldCard.startDateString != this.card.startDateString ||
      this.oldCard.startDateHourString != this.card.startDateHourString ||

      this.utilCtrl.timestamp.toLocalDate(this.oldCard.dueDate) != this.utilCtrl.timestamp.toLocalDate(this.card.dueDate) ||
      this.oldCard.dueDateString != this.card.dueDateString ||
      this.oldCard.dueDateHourString != this.card.dueDateHourString ||

      this.oldCard.done != this.card.done ||
      this.utilCtrl.timestamp.toLocalDate(this.oldCard.doneAt) != this.utilCtrl.timestamp.toLocalDate(this.card.doneAt) ||

      JSON.stringify(this.oldCard.assignedTo) != JSON.stringify(this.card.assignedTo) ||

      JSON.stringify(this.oldCard.labelsIds) != JSON.stringify(this.card.labelsIds) ||
      JSON.stringify(this.oldCard.toDoListsIds) != JSON.stringify(this.card.toDoListsIds) ||
      JSON.stringify(this.oldCard.relatedCardsIds) != JSON.stringify(this.card.relatedCardsIds) ||
      JSON.stringify(this.oldCard.recurrence) != JSON.stringify(this.card.recurrence) ||

      this.oldCard.template != this.card.template;
  }

  _updateCardAfterChange(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(this._updateCard()), 250);
    });
  }
  _updateCard(custonUpdateData: Partial<PM_Card> = null, archiveCard: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {

      // check duedate inversion
      if (this.card.startDate && this.card.dueDate) {
        const statDate = this.utilCtrl.timestamp.toDate(this.card.startDate);
        const endDate = this.utilCtrl.timestamp.toDate(this.card.dueDate);
        if (statDate.getTime() >= endDate.getTime()) {
          this.card.startDate = null;
          this.card.startDateString = null;
          this.card.startDateHourString = null;
        }
      }

      // done
      if (this.oldCard.done != this.card.done) {
        if (this.card.done) {
          const now = new Date();
          this.card.doneAt = this.utilCtrl.timestamp.fromDate(now);
          let doneAtTimeStr = this.utilCtrl.date.getDateTimeString(now);
          this.card.doneAtString = doneAtTimeStr.split('T')[0];
          this.card.doneAtHourString = doneAtTimeStr.split('T')[1];
        } else {
          this.card.doneAt = null;
          this.card.doneAtString = null;
          this.card.doneAtHourString = null;
        }
      }

      if (!this._needSave()) {
        resolve();
        return;
      }

      if (!archiveCard)
        this.card.status = 1;

      // Transaction
      this.db
        .runTransaction(transaction => {

          // Log
          let tempLog = new Log();

          tempLog.id = `${this.localDocPath}-${this.db.afs.createId()}`;
          tempLog.className = this.className;
          tempLog.uName = this.authService.localUser.uName;

          tempLog.type = "AUDIT";
          tempLog.category = "FC";

          tempLog.docPath = this.localDocPath;
          tempLog.docId = this.card.id;
          tempLog.changes = PM_Card_dataChangGen(this.oldCard, this.card, this.boardMembers, this.boardLabels, this._toDoLists.cardToDoLists, this.utilCtrl);
          // Log

          // Activity
          let tempActivity = new PM_BoardActivity();

          tempActivity.id = this.db.afs.createId();
          tempActivity.uName = this.authService.localUser.uName;
          tempActivity.cardId = this.card.id;
          tempActivity.boardId = this.card.boardId;

          tempActivity.type = "FC";

          tempActivity.changes = PM_Card_dataChangGen(this.oldCard, this.card, this.boardMembers, this.boardLabels, this._toDoLists.cardToDoLists, this.utilCtrl);
          // Activity

          // Card
          let updateData: Partial<PM_Card> = {};

          if (custonUpdateData)
            updateData = custonUpdateData;
          else
            updateData = {
              title: this.card.title,
              description: this.card.description,

              priority: this.card.priority,

              startDate: this.card.startDate,
              startDateString: this.card.startDateString,
              startDateHourString: this.card.startDateHourString,

              dueDate: this.card.dueDate,
              dueDateString: this.card.dueDateString,
              dueDateHourString: this.card.dueDateHourString,

              done: this.card.done,
              doneAt: this.card.doneAt,
              doneAtString: this.card.doneAtString,
              doneAtHourString: this.card.doneAtHourString,

              assignedTo: this.card.assignedTo,
              labelsIds: this.card.labelsIds,
              toDoListsIds: this.card.toDoListsIds,
              recurrence: this.card.recurrence ? Object.assign({}, this.card.recurrence) : null,

              template: this.card.template,
            };

          updateData.id = this.card.id;
          updateData.status = this.card.status;
          updateData.updatedBy = this.authService.localUser.uName;
          updateData.updatedOn = this.utilCtrl.timestamp.now();

          transaction.update(
            this.db.afs.firestore
              .collection(this.localDocPath).doc(this.card.id),
            updateData
          );
          // Card

          // Log
          transaction.set(
            this.db.afs.firestore
              .collection(this.db.COLLECTIONS.sys.logs).doc(tempLog.id),
            Object.assign({}, tempLog)
          );
          // Log

          // Activity
          transaction.set(
            this.db.afs.firestore
              .collection(this.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
            Object.assign({}, tempActivity)
          );
          // Activity

          return Promise.resolve();

        })
        .then(() => {
          this._initOldCard();
          resolve();
        })
        .catch(e => reject(e))
      // Transaction

    });
  }


  // --------------- Ui Rotines
  _trackByFn(index, item) {
    return index;
  }
  _compareFn(o1, o2): boolean {
    let result = false;
    if (o1 === null && o2 === null)
      result = true;
    else if (o1 != null && o2 != null) {
      if (o1 === o2)
        result = true;
      else if (o1.id != null && o2.id != null && o1.id === o2.id)
        result = true;
    }
    return result;
  }
  _textarea_autosize(id: string) {
    var el = document.getElementById(id);
    setTimeout(() => {
      el.style.cssText = 'height:auto; padding:0';
      // for box-sizing other than "content-box" use:
      // el.style.cssText = '-moz-box-sizing:content-box';
      el.style.cssText = 'height:' + el.scrollHeight + 'px';
    }, 200);
  }
  _changeSideMode(mode: PmCardModalSideMode, modeTitle: string) {
    this.selectedSideModeId = mode;
    this.selectedSideModeTitle = modeTitle;

    this.load();
  }
  public forceAddMembersToCard(uName: string) {
    if (!uName)
      return;

    if (this.card.assignedTo.includes(uName)) {
      console.log(`${uName} Already is a member of the card!`);
      return;
    }

    // check if is a valid member
    let newMember = this.boardMembers.find((m) => m.uName == uName);
    if (newMember) {

      // change to assignees side mode
      const assigneesModeId = 'assignees';
      if (this.selectedSideModeId != assigneesModeId) {
        const assigneesMode = this.PM_CARD_SIDE_MODES.find((m) => m.id == assigneesModeId);
        this._changeSideMode(assigneesMode.id, assigneesMode.title);
      }
      if (!this._modeAssignees) {
        setTimeout(() => this.forceAddMembersToCard(uName), 250);
        return;
      }

      // add member
      this._modeAssignees.newCardAssignee = newMember;
      this._modeAssignees._addAssignee();
      this.uiFeedBackCtrl.presentToast(`${newMember.firstName} foi adicionado ao cartão!`, 'Ao atribuir uma tarefa a um membro do quadro que não está associado ao cartão, o sistema fará a associação automaticamente.', 'info');
    } else
      this.uiFeedBackCtrl.presentToast(`${uName} não Encontrado no Quadro!`, 'Verifique se o usuario existe e adicone ele manualmente ao Quadro e posteriormente ao Cartão e ao To Do.', 'warning');
  }
  public focusElementById(elementId: string, callback: () => void) {
    const tryFocus = () => {
      const modal = document.getElementById('pm-card-modal');
      const modalMain = document.getElementById('pm-card-modal-main');
      const element = document.getElementById(elementId);
      if (modal && modalMain && element) {
        const rect = element.getBoundingClientRect();
        const modalRect = modalMain.getBoundingClientRect();
        const scrollPosition = (modalMain.scrollTop + (rect.top - modalRect.top)); // Margin;
        modalMain.scrollTo({ top: scrollPosition, behavior: 'smooth' });

        if (element.offsetParent !== null) {
          element.classList.add('focus');
          callback();
        } else {
          setTimeout(tryFocus, 250);
        }
      } else {
        setTimeout(tryFocus, 250);
      }
    };

    tryFocus();
  }
  __showPopdownInMain(triggerElementId: string, popdownElementId: string, hideFunction: () => void) {
    return this.___showPopdown(triggerElementId, popdownElementId, 'main', hideFunction);
  }
  __showPopdownInSide(triggerElementId: string, popdownElementId: string, hideFunction: () => void) {
    return this.___showPopdown(triggerElementId, popdownElementId, 'side', hideFunction);
  }
  private ___showPopdown(triggerElementId: string, popdownElementId: string, scrollArea: 'main' | 'side', hideFunction: () => void) {
    let triggerElement = document.getElementById(triggerElementId);
    let popdownElement = document.getElementById(popdownElementId);
    let pmCardModalElement = document.getElementById("pm-card-modal");

    if (!triggerElement || !popdownElement || !pmCardModalElement)
      return;

    const updatePopdownPosition = () => {
      const triggerRect = triggerElement.getBoundingClientRect();
      const pmCardModalRect = pmCardModalElement.getBoundingClientRect();

      // Calcular a posição desejada
      const top = (triggerRect.bottom - pmCardModalRect.top) + pmCardModalElement.scrollTop;
      const left = (triggerRect.left - pmCardModalRect.left) + pmCardModalElement.scrollLeft;

      // Ajustar a posição usando translate3d
      popdownElement.style.transform = `translate3d(${left}px, ${top}px, 0)`;
      popdownElement.style.top = '0'; // Resetar a propriedade top
      popdownElement.style.left = '0'; // Resetar a propriedade left
    };

    // Atualizar a posição inicialmente
    updatePopdownPosition();

    // Adicionar evento de rolagem ao contêiner específico
    const scrowElementsMap = { main: "pm-card-modal-main", side: "pm-card-modal-side-body" };
    const pmCardModalScrollElement = document.getElementById(scrowElementsMap[scrollArea]);
    if (pmCardModalScrollElement)
      pmCardModalScrollElement.addEventListener('scroll', updatePopdownPosition);

    // Função para detectar clique fora do popdown
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popdownElement &&
        !popdownElement.contains(event.target as Node) &&
        triggerElement &&
        !triggerElement.contains(event.target as Node)
      ) {
        hideFunction();
        document.removeEventListener('click', handleClickOutside);
      }
    };

    // Adicionar evento de clique ao documento para detectar cliques fora do popdown
    document.addEventListener('click', handleClickOutside);
  }
  // --------------- Ui Rotines

  // Integrations
  //   referenceType: PM
  //   reference - > boardId,
  //   reference1 -> cardId,
  //   reference2 -> activityId
  //   reference3 -> toDoId
  _tmIntegrationCard() {
    let integration = new TM_IntegrationData({
      referenceType: 'PM_Card',

      presetProjectId: this.board.integration.tm.projectId,

      presetDescription: `${this.card.id} - ${this.card.title}`,
      reference: this.card.boardId,
      reference1: this.card.id
    });
    this._activeModal = true;
    let active_TmTimerModalRef = this.uiFeedBackCtrl.dialog_opener(TmTimerModalComponent, {
      id: 'TmTimerModalRef_' + new Date().getTime(),
      data: {
        integrationActivate: true,
        integrationData: JSON.parse(JSON.stringify(integration))
      }
    });
    active_TmTimerModalRef.afterClosed()
      .subscribe((data) => {
        this._activeModal = false;

        // if (data)
      });
  }
  getTotalHoursSum(): TM_TimeRecordsHoursSum {
    return sumTimeRecords(this.timeRecords);
  }
  getHoursSum4Card(): TM_TimeRecordsHoursSum {
    return sumTimeRecords(this.timeRecords.filter((tr) => tr.reference2 == '' && tr.reference3 == ''));
  }
  getHoursSum4ToDo(toDoId: string): TM_TimeRecordsHoursSum {
    return sumTimeRecords(this.timeRecords.filter((tr) => tr.reference3 == toDoId));
  }
  getHoursSum4Activity(activityId: string): TM_TimeRecordsHoursSum {
    return sumTimeRecords(this.timeRecords.filter((tr) => tr.reference2 == activityId));
  }
  _tmIntegrationToDo(toDo: PM_ToDo) {
    let integration = new TM_IntegrationData({
      referenceType: 'PM_Card-ToDo',

      presetProjectId: this.board.integration.tm.projectId,

      presetDescription: `${this.card.id} - ${this.card.title} | ${toDo.title}`,
      reference: toDo.boardId,
      reference1: toDo.cardId,

      reference3: toDo.id,
    });
    this._activeModal = true;
    let active_TmTimerModalRef = this.uiFeedBackCtrl.dialog_opener(TmTimerModalComponent, {
      id: 'TmTimerModalRef_' + new Date().getTime(),
      data: {
        integrationActivate: true,
        integrationData: JSON.parse(JSON.stringify(integration))
      }
    });
    active_TmTimerModalRef.afterClosed()
      .subscribe((data) => {
        this._activeModal = false;

        // if (data)
      });
  }
  _tmIntegrationActivity(activity: PM_BoardActivity) {
    let integration = new TM_IntegrationData({
      referenceType: 'PM_Card-Activity',

      presetProjectId: this.board.integration.tm.projectId,

      presetDescription: `${this.card.id} - ${this.card.title} | ${activity.text}`,
      reference: activity.boardId,
      reference1: activity.cardId,

      reference2: activity.id,
    });
    this._activeModal = true;
    let active_TmTimerModalRef = this.uiFeedBackCtrl.dialog_opener(TmTimerModalComponent, {
      id: 'TmTimerModalRef_' + new Date().getTime(),
      data: {
        integrationActivate: true,
        integrationData: JSON.parse(JSON.stringify(integration))
      }
    });
    active_TmTimerModalRef.afterClosed()
      .subscribe((data) => {
        this._activeModal = false;

        // if (data)
      });
  }
  // Integrations

  // sent to other board
  _loadBoards4Sel(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.authService.getUName()
        .then((uName) => {
          return this.db.projectManagement.kanban.boards
            .getDataByWhere('members', 'array-contains', uName)
            .then(data => {
              this._boards4Sel_source =
                new LocalDataSource(
                  boards4SelDataSourceMaker(
                    data.filter(b => { return b.id != this.card.boardId })
                  )
                );
              resolve();
            })
        })
        .catch(e => reject(e));
    })
  }
  _sendToOtherBoard() {
    this.uiFeedBackCtrl.presentLoader()
      .then(() => {
        this._loadBoards4Sel()
          .then(() => {
            this.uiFeedBackCtrl.dismissLoader();
            this._activeModal = true;
            return this.uiFeedBackCtrl.showTableSelect(this._boards4Sel_columns, this._boards4Sel_source)
              .then((newBoard) => {
                if (newBoard)
                  this.__sendToOtherBoard_save(newBoard);
                this._activeModal = false;
              })
          })
          .catch((e) => {
            this.uiFeedBackCtrl.dismissLoader();
            this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e);
          });
      })
  }
  __sendToOtherBoard_save(newBoard: PM_Board) {
    this.uiFeedBackCtrl.presentLoader('Enviando...')
      .then(() => {

        const now = this.utilCtrl.timestamp.now();
        let tempLogs: Log[] = [];
        let tempActivities: PM_BoardActivity[] = [];

        const tempCard = new PM_Card(JSON.parse(JSON.stringify(this.card)));

        tempCard.boardId = newBoard.id;
        tempCard.parentKey = newBoard.childsKey;
        tempCard.routeId = newBoard.routes[0];

        // card Log
        let cardLog = new Log();

        cardLog.id = `${this.localDocPath}-${this.db.afs.createId()}`;
        cardLog.className = this.className;
        cardLog.uName = this.authService.localUser.uName;

        cardLog.type = "AUDIT";
        cardLog.category = "FC";

        cardLog.docPath = this.localDocPath;
        cardLog.changes.push({
          fieldName: `Quadro alterado`,
          oldValue: `${this.board.id} - ${this.board.name}`,
          newValue: `${newBoard.id} - ${newBoard.name}`
        });

        cardLog.createdOn = now;

        let newBoardCard_log = new Log(JSON.parse(JSON.stringify(cardLog)))
        newBoardCard_log.docId = this.card.boardId;
        tempLogs.push(newBoardCard_log);

        let oldBoardCard_log = new Log(JSON.parse(JSON.stringify(cardLog)))
        oldBoardCard_log.docId = newBoard.id;
        tempLogs.push(oldBoardCard_log);
        // card Log

        // Card Activity
        let cardActivity = new PM_BoardActivity();

        cardActivity.id = this.db.afs.createId();
        cardActivity.uName = this.authService.localUser.uName;
        cardActivity.cardId = this.card.id;

        cardActivity.type = "FC";

        cardActivity.changes.push({
          fieldName: `Quadro alterado`,
          oldValue: `${this.board.id} - ${this.board.name}`,
          newValue: `${newBoard.id} - ${newBoard.name}`
        });

        cardActivity.createdOn = now;

        let newBoardCard_actv = new PM_BoardActivity(JSON.parse(JSON.stringify(cardActivity)))
        newBoardCard_actv.boardId = this.card.boardId;
        tempActivities.push(newBoardCard_actv);

        let oldBoardCard_actv = new PM_BoardActivity(JSON.parse(JSON.stringify(cardActivity)))
        oldBoardCard_actv.boardId = newBoard.id;
        tempActivities.push(oldBoardCard_actv);
        // Card Activity

        // transaction
        this.db
          .runTransaction(t => {

            // Card
            t.update(
              this.db.afs.firestore
                .collection(this.localDocPath).doc(this.card.id),
              {
                boardId: tempCard.boardId,
                parentKey: tempCard.parentKey,
                routeId: tempCard.routeId,
              }
            );
            // Card

            // ToDoLists
            this._toDoLists.cardToDoLists
              .forEach((toDoList) => {
                t.update(
                  this.db.afs.firestore
                    .collection(this.db.COLLECTIONS.projectManagement.toDoLists).doc(toDoList.id),
                  {
                    boardId: tempCard.boardId,
                    parentKey: tempCard.parentKey
                  }
                );
              });
            // ToDoLists


            // ToDos
            this._toDoLists.cardToDos
              .forEach((toDos) => {
                t.update(
                  this.db.afs.firestore
                    .collection(this.db.COLLECTIONS.projectManagement.toDos).doc(toDos.id),
                  {
                    boardId: tempCard.boardId,
                    parentKey: tempCard.parentKey
                  }
                );
              });
            // ToDos

            // Old Board Route
            t.update(
              this.db.afs.firestore
                .collection(this.db.COLLECTIONS.projectManagement.kanban.routes).doc(this.cardRoute.id),
              {
                cards: this.db.fieldValue.arrayRemove(this.card.id) as any,

                updatedBy: this.authService.localUser.uName,
                updatedOn: now,
              }
            );
            // Old Board Route

            // New Board Route
            t.update(
              this.db.afs.firestore
                .collection(this.db.COLLECTIONS.projectManagement.kanban.routes).doc(tempCard.routeId),
              {
                cards: this.db.fieldValue.arrayUnion(this.card.id) as any,

                updatedBy: this.authService.localUser.uName,
                updatedOn: now,
              }
            );
            // New Board Route

            // Activity
            tempActivities
              .forEach(actv => {
                t.set(
                  this.db.afs.firestore
                    .collection(this.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(actv.id),
                  Object.assign({}, actv)
                );
              })
            // Activity

            // Log
            tempLogs.forEach(log => {
              t.set(
                this.db.afs.firestore
                  .collection(this.db.COLLECTIONS.sys.logs).doc(log.id),
                Object.assign({}, log)
              );
            })
            // Log

            return Promise.resolve();

          })
          .then(() => {
            this._close();
            this.uiFeedBackCtrl.dismissLoader();

            const navigationExtras: NavigationExtras = {
              queryParams: {
                'id': tempCard.boardId,
                'cardIdToOpen': tempCard.id
              }
            };
            this.router.navigate(['/main/project-management/kanban/open-board'], navigationExtras);
          })
          .catch((e) => {
            this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
          })
        // transaction
      })
  }
  // sent to other board

  _archiveConfirmation() {
    this._activeModal = true;
    this.uiFeedBackCtrl.presentCustonAlert({
      title: 'Arquivar Cartão?',
      message: `Ao aquivar o Cartão: "${this.card.title}" ele não irá mais aparecer no quadro. ⚠️ Para desarquivar o cartão basta realizar alguma alteração nele.`,
      buttons: [
        {
          text: 'Cancelar',
          icon: "close-outline",
          handler: () => {
            this._activeModal = false;
          }
        },
        {
          needFormValid: true,
          text: 'Arquivar',
          icon: "archive-outline",
          status: "warning",
          handler: () => {
            this._activeModal = false;
            this._archive();
          }
        }
      ]
    })
  }
  _archive() {
    this.uiFeedBackCtrl.presentLoader('Arquivando...')
      .then(() => {

        this.card.status = 0;

        this._updateCard(null, true)
          .then(() => {
            this.uiFeedBackCtrl.dismissLoader();
          })
          .catch((e) => {
            this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
          })
      });
  }
  _unarchiveCard() {
    this.uiFeedBackCtrl.presentLoader('Desarquivando...')
      .then(() => {

        this.card.status = 1;

        this._updateCard(null, true)
          .then(() => {
            this.uiFeedBackCtrl.dismissLoader();
          })
          .catch((e) => {
            this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
          })
      });
  }
  _deleteConfirmation() {
    const confCode = this.utilCtrl.generators.generateRandom(5);
    this._activeModal = true;
    this.uiFeedBackCtrl.presentCustonAlert({
      title: 'Apagar Cartão?',
      message: `⚠️Apagar o Cartão: "${this.card.title}" irá apagar todos os documentos relacionados a ele!⚠️ Para proseguir digite o código de confirmação abaixo: ${confCode}`,
      inputs: [
        {
          type: 'text',
          label: 'Confirmação',
          name: 'conf',
          required: true,
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          icon: "close-outline",
          handler: () => {
            this._activeModal = false;
          }
        },
        {
          needFormValid: true,
          text: 'Deletar',
          icon: "trash-2-outline",
          status: "danger",
          handler: data => {
            this._activeModal = false;
            if (data.conf && data.conf.toUpperCase() == confCode.toUpperCase()) {
              this.__deleteCardOnNgDestroy = true;
              this._close();
            } else
              this.uiFeedBackCtrl.presentAlert(`Deleção cancelada!`, `Verifique o código de confirmação!`, `warning`)
          }
        }
      ]
    })
  }
  _delete() {
    this.uiFeedBackCtrl.presentLoader('Apagando...')
      .then(() => {

        const now = this.utilCtrl.timestamp.now();

        // Log
        let tempLog = new Log();

        tempLog.id = `${this.localDocPath}-${this.db.afs.createId()}`;
        tempLog.className = this.className;
        tempLog.uName = this.authService.localUser.uName;

        tempLog.type = "AUDIT";
        tempLog.category = "DE";

        tempLog.docPath = this.localDocPath;
        tempLog.docId = this.card.id;

        tempLog.createdOn = now;
        // Log

        // Board Route
        const boardRouteUpdateData = {
          id: this.card.routeId,
          cards: this.db.fieldValue.arrayRemove(this.card.id) as any,

          updatedBy: this.authService.localUser.uName,
          updatedOn: now,
        }
        // Board Route

        this.db.runTransaction(t => {

          // Card
          t.delete(
            this.db.afs.firestore
              .collection(this.localDocPath).doc(this.card.id)
          );
          // Card

          // ToDoLists
          this._toDoLists.cardToDoLists
            .forEach((toDoList) => {
              t.delete(
                this.db.afs.firestore
                  .collection(this.db.COLLECTIONS.projectManagement.toDoLists).doc(toDoList.id)
              );
            });
          // ToDoLists

          // ToDos
          this._toDoLists.cardToDos
            .forEach((toDos) => {
              t.delete(
                this.db.afs.firestore
                  .collection(this.db.COLLECTIONS.projectManagement.toDos).doc(toDos.id)
              );
            });
          // ToDos

          // Board Route
          t.update(
            this.db.afs.firestore
              .collection(this.db.COLLECTIONS.projectManagement.kanban.routes).doc(this.card.routeId),
            boardRouteUpdateData
          );
          // Board Route

          // Log
          t.set(
            this.db.afs.firestore
              .collection(this.db.COLLECTIONS.sys.logs).doc(tempLog.id),
            Object.assign({}, tempLog)
          );
          // Log

          return Promise.resolve();

        })
          .then(() => {
            this.uiFeedBackCtrl.dismissLoader();
          })
          .catch((e) => {
            this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
          })
      });
  }
}
