import firebase from "firebase/compat/app";

export class LockEntry {

  id: string;
  docPath: string;
  docId: string;

  uName: string;
  userFullName: string;

  createdOn: firebase.firestore.Timestamp;

  public constructor(init?: Partial<LockEntry>) {

    this.id = '';
    this.docPath = '';
    this.docId = '';

    this.uName = '';
    this.userFullName = '';

    this.createdOn = firebase.firestore.Timestamp.now();

    if (init)
      Object.assign(this, init);
  }
}
