import firebase from "firebase/compat/app";

export class SM_CustomFieldAsc {

  id: string;
  customFieldId: string;

  categoryId: string;
  subcategoryId: string;
  businessRoleId: string;

  type: 'Optional' | 'Mandatory';
  status: 0 | 1;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<SM_CustomFieldAsc>) {

    this.id = '';
    this.customFieldId = '';

    this.categoryId = "";
    this.subcategoryId = "";
    this.businessRoleId = '';

    this.type = 'Optional';
    this.status = 1;

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;


    if (init)
      Object.assign(this, init);
  }
}
