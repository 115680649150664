<div class="timer-list-tab">
  <div class="timer-list-tab-inner">

    <div *ngIf="loader" class="loader">
      <p><b>Carregando...</b></p>
    </div>

    <!-- table -->
    <nb-card>
      <nb-card-body class="table-card">
        <table *ngIf="!loader" class="timer-table">
          <thead>
            <tr>

              <th style="width: 15px;">
                Nº
              </th>

              <th style="width: 150px;">
                Projeto
              </th>

              <th style="width: 100px;">
                Tarefa
              </th>

              <th style="width: 100px;">
                Tags
              </th>

              <th style="width: 180px;">
                Descrição
              </th>

              <th style="width: 50px;" class="th-day" *ngFor="let d of days; let th_day_i=index"
                [ngClass]="{'today': isToday(d),'isWeekendDay': d.isWeekendDay}">
                <nb-icon *ngIf="_dayHasError(th_day_i)" icon="alert-triangle-outline" pack="eva" style="color: red;">
                </nb-icon>
                <p>{{d.weekDay}}</p>
                <p>{{d.dateStr}}</p>
              </th>

              <th *ngIf="edit" style="width: 30px;">
                <button title="Nova Linha" nbButton status="primary" size="small" (click)="addLine()">
                  <nb-icon icon="file-add-outline" pack="eva"></nb-icon>
                </button>
              </th>

            </tr>
          </thead>

          <tbody>
            <!-- Lines -->
            <tr [id]="'row_'+line_i" *ngFor="let line of lines; let line_i=index" class="line"
              [ngClass]="{'project_inactive': line.project.status==0,'no_auth': !line.auth, 'managed': line.managed,'create_by_integration': line.referenceType, 'old': line.status=='old',' new': line.status=='new', 'changed': line.status=='changed'}">

              <!-- Nº -->
              <td>
                <div class="line-num-container">
                  <nb-icon *ngIf="line.hasError" icon="alert-triangle-outline" pack="eva" style="color: red;"></nb-icon>
                  <p *ngIf="line.status=='old'">{{line_i+1}}</p>
                  <p *ngIf="line.status=='new'||line.status=='changed'"><b>{{line_i+1}}*</b></p>

                  <!-- tooltip-managed -->
                  <div class="tooltip-box">
                    <span class="tooltip-div tooltip-managed">
                      <nb-icon icon="alert-triangle-outline" pack="eva" style="color: red;"></nb-icon>
                      Linha criada / alterada via gerenciamento de horas
                    </span>
                  </div>
                  <!-- tooltip-managed -->
                </div>

                <!-- tooltip-project_inactive -->
                <div class="tooltip-box">
                  <span class="tooltip-div tooltip-project_inactive">
                    <nb-icon icon="alert-triangle-outline" pack="eva" style="color: red;"></nb-icon>
                    Projeto: "{{line.project.teamId}} - {{line.project.name}}" inativo!
                  </span>
                </div>
                <!-- tooltip-project_inactive -->

                <!-- tooltip-no_auth -->
                <div class="tooltip-box">
                  <span class="tooltip-div tooltip-no_auth">
                    <nb-icon icon="alert-triangle-outline" pack="eva" style="color: red;"></nb-icon>
                    Sem autorização ao projeto: "{{line.project.teamId}} - {{line.project.name}}"!
                  </span>
                </div>
                <!-- tooltip-no_auth -->
              </td>

              <!-- Projeto -->
              <td
                [style.background-color]="db.DICTIONARY.getData(line.project.colorId,db.DICTIONARY.timeManagement.project.color)['hex']">

                <div *ngIf="line.project.status==0 || !line.auth ">
                  <select style="min-width: 150px;" disabled [id]="'input_project_'+line_i"
                    [name]="'input_project_'+line_i" [(ngModel)]="line.project" [compareWith]="_compareFn">
                    <option *ngFor="let p of dataCore.projects" [ngValue]="p"
                      [style.color]="db.DICTIONARY.getData(p.colorId,db.DICTIONARY.timeManagement.project.color)['hex']">
                      {{p.teamId}} - {{p.name}}
                    </option>
                  </select>
                </div>

                <div *ngIf="line.project.status==1 && line.auth">
                  <select style="min-width: 150px;" [disabled]="!edit" [id]="'input_project_'+line_i"
                    [name]="'input_project_'+line_i" [(ngModel)]="line.project" [compareWith]="_compareFn"
                    (ngModelChange)="_selectProject(line_i)">
                    <option *ngFor="let p of selectionData.projects" [ngValue]="p"
                      [style.color]="db.DICTIONARY.getData(p.colorId,db.DICTIONARY.timeManagement.project.color)['hex']">
                      {{p.teamId}} - {{p.name}}
                    </option>
                  </select>
                </div>
              </td>

              <!-- Tarefa -->
              <td
                [style.background-color]="db.DICTIONARY.getData(line.project.colorId,db.DICTIONARY.timeManagement.project.color)['hex']">
                <div *ngIf="!line.project.taskControl" class="project-no_task_control">N/A</div>

                <select *ngIf="line.project.taskControl" style="min-width: 150px;"
                  [disabled]="!edit || line.project.status==0 || !line.auth  || !line.project.taskControl"
                  [id]="'input_project_'+line_i" [name]="'input_project_'+line_i" [(ngModel)]="line.task"
                  [compareWith]="_compareFn" (ngModelChange)="_selectTask(line_i)">
                  <option *ngFor="let t of line.selectionData.projectTasks" [ngValue]="t">
                    {{t.name}}
                  </option>
                </select>
              </td>

              <!-- Tags -->
              <td>
                <div class="tags-container">
                  <div class="inner">
                    <div class="tag" [ngClass]="{'project_task_tag': line.task.tagId == tag.id}"
                      *ngFor="let tag of line.tags; let tag_i=index">
                      <button
                        [disabled]="!edit || line.project.status==0 || !line.auth  || line.task.tagId == tag.id || (line.project.taskControl && line.task.id == '')"
                        [title]="tag.name" (click)="_remTag(tag_i, line_i)">
                        <nb-icon style="color:red" icon="close-circle-outline" pack="eva"></nb-icon>
                        {{tag.name}}
                      </button>

                      <!-- tooltip-box -->
                      <div class="tooltip-box">
                        <span class="tooltip-div tooltip-project_task_tag">
                          <nb-icon icon="alert-triangle-outline" pack="eva" style="color: red;"></nb-icon>
                          Não é possivel remover a Tag config. no projeto
                        </span>
                      </div>
                      <!-- tooltip-box -->
                    </div>

                    <button class="add-tag"
                      [disabled]="!edit || line.project.status==0 || !line.auth  || showTagPopdown == line_i || line.selectionData.projectTags.length == 0 || line.tags.length == line.selectionData.projectTags.length || (line.project.taskControl && line.task.id == '')"
                      (click)="_openTagPopdown(line_i)">
                      <nb-icon icon="plus-outline" pack="eva"></nb-icon>
                    </button>
                  </div>

                  <!-- popdown -->
                  <div class="popdown" *ngIf="showTagPopdown == line_i">
                    <div class="popdownContent" #TagPopdownContentERef>
                      <ul>
                        <li *ngFor="let tag of _getTagsForShow(line_i)">
                          <button [title]="tag.name" (click)="_selectTag(tag, line_i)">
                            <nb-icon style="color:blue" icon="plus-circle-outline" pack="eva"></nb-icon>
                            {{tag.name}}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- popdown -->

                </div>
              </td>

              <!-- Descrição -->
              <td>
                <input class="input-description" [disabled]="!edit || line.project.status==0 || !line.auth " type="text"
                  id="{{'input_description_'+line_i}}" name="{{'input_description_'+line_i}}"
                  [(ngModel)]="line.description" autocomplete="nope" Placeholder="Descrição"
                  (ngModelChange)="_updateDescription(line_i)">
              </td>

              <!-- Time -->
              <td *ngFor="let d of days; let days_i=index" class="input-time-container"
                [ngClass]="{'nil': line.days[days_i].val == 0,'managed':line.days[days_i].managed && line.days[days_i].val > 0,'today': isToday(d)}">
                <input [disabled]="!edit || line.project.status==0 || !line.auth "
                  (ngModelChange)="_dayInputModelChange(line_i, days_i)" (blur)="_dayInputBlur(line_i, days_i)"
                  [(ngModel)]="line.days[days_i].time" id="{{'input_time_'+line_i+':'+days_i}}"
                  name="{{'input_time_'+line_i+':'+days_i}}" type="time" step="0" max="24:00" />
              </td>

              <td *ngIf="edit">
                <div class="options-container">
                  <div class="inner">
                    <button title="Opções" [disabled]="line.project.status==0 || !line.auth "
                      (click)="_openOptionsPopdown(line_i)">
                      <nb-icon icon="more-vertical-outline" pack="eva"></nb-icon>
                    </button>
                  </div>

                  <!-- popdown -->
                  <div class="popdown" *ngIf="showOptionsPopdown==line_i">
                    <div class="popdownContent" #OptionPopdownContentERef>
                      <ul>
                        <li *ngIf="line.referenceType">
                          <button title="Abrir Link" (click)="_openIntegration(line_i)">
                            <nb-icon status="primary" icon="external-link-outline" pack="eva"></nb-icon>
                            Abrir Link
                          </button>
                        </li>
                        <li>
                          <button title="Duplicar" (click)="_dupLine(line_i)">
                            <nb-icon status="primary" icon="copy-outline" pack="eva"></nb-icon>
                            Duplicar
                          </button>
                        </li>
                        <li>
                          <button title="Deletar" (click)="_remLine(line_i)">
                            <nb-icon status="danger" icon="trash-2-outline" pack="eva"></nb-icon>
                            Deletar
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- popdown -->
                </div>
              </td>
            </tr>
            <!-- Lines -->

            <!-- integration -->
            <tr *ngIf="integrationActivate" class="line integration-line">
              <th>
                <button title="Nova Linha (Integração)" *ngIf="edit" nbButton status="warning" size="small"
                  (click)="addLineFromIntegrationData()">
                  <nb-icon icon="star-outline" pack="eva"></nb-icon>
                </button>
              </th>

              <!-- Projeto -->
              <th *ngIf="!integrationData.presetProjectId"></th>

              <td *ngIf="integrationData.presetProjectId"
                [style.background-color]="db.DICTIONARY.getData(integrationPresetProject.colorId, db.DICTIONARY.timeManagement.project.color)['hex']">
                <select style="min-width: 150px;" disabled [(ngModel)]="integrationData.presetProjectId">
                  <option *ngFor="let p of dataCore.projects" [ngValue]="p.id">
                    {{p.teamId}} - {{p.name}}
                  </option>
                </select>

              </td>
              <!-- Projeto -->

              <!-- Tarefa -->
              <th *ngIf="!integrationData.presetProjectId"></th>

              <td *ngIf="integrationData.presetProjectId"
                [style.background-color]="db.DICTIONARY.getData(integrationPresetProject.colorId,db.DICTIONARY.timeManagement.project.color)['hex']">
                <div *ngIf="!integrationPresetProject.taskControl" class="project-no_task_control">N/A</div>
              </td>
              <!-- Tarefa -->

              <th></th>
              <td>
                <input disabled class="input-description" [value]="integrationData.presetDescription">
              </td>
              <td class="input-time-container" *ngFor="let d of days; let days_i=index"
                [ngClass]="{'nil': d.val == 0,'today': isToday(d)}">
              </td>
              <th *ngIf="edit"></th>
            </tr>
            <!-- integration -->
          </tbody>

          <tfoot>
            <!-- Totals -->
            <tr>
              <th>
                <button title="Nova Linha" *ngIf="edit" nbButton status="primary" size="small" (click)="addLine()">
                  <nb-icon icon="file-add-outline" pack="eva"></nb-icon>
                </button>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <td>
                Totais:
              </td>
              <td class="input-time-container" *ngFor="let d of days; let days_i=index"
                [ngClass]="{'nil': d.val == 0,'today': isToday(d)}">
                <input disabled class="input_time_total" [(ngModel)]="d.time" id="{{'input_time_'+days_i}}"
                  id="input_time_total" name="input_time_total" name="{{'input_time_'+days_i}}" type="time" step="0" />
              </td>
              <th *ngIf="edit"></th>
            </tr>
            <!-- Totals -->

            <!-- weeklyGoal -->
            <tr *ngIf="userConfig.weeklyGoal.active" class="weeklyGoal">
              <th class="null"></th>
              <th class="null"></th>
              <th class="null"></th>
              <th class="null"></th>
              <td>
                <div *ngIf="mode=='week'">
                  <p *ngIf="weeklyGoal_status.status=='not-set'">
                    N/A
                  </p>

                  <p *ngIf="weeklyGoal_status.status=='up'">
                    <span *ngIf="weeklyGoal_status.difH!=0">{{weeklyGoal_status.difH}}h</span>
                    <span *ngIf="weeklyGoal_status.difM!=0">{{weeklyGoal_status.difM}}m</span>
                    <span>⬆️</span>
                  </p>
                  <p *ngIf="weeklyGoal_status.status=='down'" style="color:red">
                    <span *ngIf="weeklyGoal_status.difH!=0">{{weeklyGoal_status.difH}}h</span>
                    <span *ngIf="weeklyGoal_status.difM!=0">{{weeklyGoal_status.difM}}m</span>
                    <span>⬇️</span>
                  </p>
                  <p *ngIf="weeklyGoal_status.status=='ok'">
                    <span>❇️</span>
                  </p>
                </div>
              </td>
              <td class="input-time-container" *ngFor="let d of days; let days_i=index"
                [ngClass]="{'nil': weeklyGoal_days[days_i].status == 'not-set'}">
                <div class="inner">

                  <p *ngIf="weeklyGoal_days[days_i].status=='not-set'">
                    N/A
                  </p>

                  <p *ngIf="weeklyGoal_days[days_i].status=='up'">
                    <span *ngIf="weeklyGoal_days[days_i].difH!=0">{{weeklyGoal_days[days_i].difH}}h</span>
                    <span *ngIf="weeklyGoal_days[days_i].difM!=0">{{weeklyGoal_days[days_i].difM}}m</span>
                    <span>⬆️</span>
                  </p>
                  <p *ngIf="weeklyGoal_days[days_i].status=='down'" style="color:red">
                    <span *ngIf="weeklyGoal_days[days_i].difH!=0">{{weeklyGoal_days[days_i].difH}}h</span>
                    <span *ngIf="weeklyGoal_days[days_i].difM!=0">{{weeklyGoal_days[days_i].difM}}m</span>
                    <span>⬇️</span>
                  </p>
                  <p *ngIf="weeklyGoal_days[days_i].status=='ok'">
                    <span>❇️</span>
                  </p>

                </div>
              </td>
              <th *ngIf="edit" class="null"></th>
            </tr>
            <!-- weeklyGoal -->
          </tfoot>
        </table>
      </nb-card-body>
    </nb-card>
    <!-- table -->

    <!-- timer -->
    <timer-list-tab-timer *ngIf="!loader && userConfig.showTimer"></timer-list-tab-timer>
    <!-- timer -->

    <hr>

    <!-- Assistente de Edição -->
    <nb-card *ngIf="edit" class="editor-wizard">
      <nb-card-header>
        <p>Assistente de Edição</p>
        <button (click)="showUserConfigModal()">
          <nb-icon icon="settings-outline" pack="eva"></nb-icon>
        </button>
      </nb-card-header>
      <nb-card-body>

        <div class="row">
          <div class="col-12 padding">
            <p *ngIf="!hasChanges">💾✔️ Não existem alterações a serem salvas</p>
            <p *ngIf="hasChanges && errors.length==0">✅ Não existem erros de Preenchimento.</p>
            <div *ngIf="errors.length>0">
              <p>❌ Erros de Preenchimento:</p>
              <div *ngFor="let e of errors; let i=index">
                <p class="error"><b>{{i+1}})</b> {{e}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 padding" style="text-align: center">
            <div class="button-container">
              <button nbButton status="primary" size="small" [disabled]="!hasChanges ||  errors.length > 0"
                (click)="trySave()">
                <nb-icon icon="save-outline" pack="eva"></nb-icon>
                Salvar
              </button>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
    <!-- Assistente de Edição -->

    <!-- legenda -->
    <nb-card>
      <nb-card-body class="table-card">
        <table class="timer-table subtitles-table">
          <thead>
            <tr>
              <th>
                Legenda p/ contornos de linha
              </th>
            </tr>
          </thead>

          <tbody>
            <tr class="line no_auth">
              <td>
                Sem autorização
              </td>
            </tr>

            <tr class="line project_inactive">
              <td>
                Projeto Inativo
              </td>
            </tr>

            <tr class="line create_by_integration">
              <td>
                Criada por integração
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              -
            </tr>
          </tfoot>
        </table>
      </nb-card-body>
    </nb-card>
    <!-- legenda -->

  </div>
</div>