import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, ViewChild, ViewEncapsulation } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import { CkEditor } from './ckeditor/CkEditor';
import { collectUsersMentioned, MagicTextUserToMention, mentionUserFeedCustomItemRenderer, mentionUserFeedGetFeedItems } from './magic-text-editor.mention.usersFeed';
import { UploadAdapter } from './magic-text-editor.uploadAdapter';

@Component({
  selector: 'magic-text-editor',
  templateUrl: './magic-text-editor.component.html',
  styleUrls: ['./magic-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class MagicTextEditorComponent implements OnInit, OnChanges, OnDestroy {

  public Editor: CkEditor;
  public editorERef = null;
  public ready = false;
  public _loader = true;

  instanceId = this.generateRandom(30);
  editorClass = 'editor-' + this.instanceId;
  editorToolbarClass = 'editor__toolbar-' + this.instanceId;

  @Input() disabled = false;
  @Input() type: "classic" | "decoupledDocument" = "classic";
  @Input() mode: "standard" | "restricted" = "standard";
  @Input() toolbarItems: 'standard' | 'extend' | 'advanced' = "standard";

  @Input() data: string = '';
  @Output() onChange = new EventEmitter<string>();
  @Output() onUsersMentionedChange = new EventEmitter<MagicTextUserToMention[]>();
  @Output() onReady = new EventEmitter<MagicTextEditorComponent>();

  @Input() usersToMention: MagicTextUserToMention[] = [];
  public usersMentioned: MagicTextUserToMention[] = [];

  constructor(
    private afStorage: AngularFireStorage
  ) { }
  private log(message?: any, ...optionalParams: any[]) {
    console.log(`[magic-text-editor] ${message}`, ...optionalParams)
  }
  private logError(message?: any, ...optionalParams: any[]) {
    console.error(`[magic-text-editor] ${message}`, ...optionalParams)
  }
  ngOnInit() { }
  ngOnDestroy() {
    if (this.editorERef)
      this.editorERef.destroy()
        .then(() => {
          this.log('Editor was destructed ->instanceId: ', this.instanceId);
        })
        .catch((e) => {
          this.logError('Error on Editor destruction ->instanceId: ', this.instanceId, e);
        })
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.initEditor();
    }, 100);
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName == 'disabled') {
        this.updateReadOnly();
      }
    }
  }

  private generateRandom(length: number): string {
    var s = '';
    var r = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    for (var i = 0; i < length; i++) {
      s += r.charAt(Math.floor(Math.random() * r.length));
    }
    return s;
  }
  getEditorRef(): Promise<Element> {
    return new Promise((resolve) => {
      let editorRef = document.querySelector('.' + this.editorClass);
      if (editorRef)
        resolve(editorRef);
      else
        setTimeout(() => {
          resolve(this.getEditorRef());
        }, 50);
    });
  }
  private _initPlugin_Mention(editor) {
    // The upcast converter will convert <a class="mention" href="" data-user-id="">
    // elements to the model 'mention' attribute.
    editor.conversion.for('upcast').elementToAttribute({
      view: {
        name: 'span',
        key: 'data-mention',
        classes: 'mention',
        attributes: {
          title: true,
          'data-id': true,
          'data-reference': true,
        }
      },
      model: {
        key: 'mention',
        value: viewItem => {
          // The mention feature expects that the mention attribute value
          // in the model is a plain object with a set of additional attributes.
          // In order to create a proper object, use the toMentionAttribute helper method:
          const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
            // Add any other properties that you need.
            id: viewItem.getAttribute('data-id'),
            reference: viewItem.getAttribute('data-reference'),
            name: viewItem.getAttribute('title'),
          });

          return mentionAttribute;
        }
      },
      converterPriority: 'high'
    });

    // Downcast the model 'mention' text attribute to a view <a> element.
    editor.conversion.for('downcast').attributeToElement({
      model: 'mention',
      view: (modelAttributeValue, { writer }) => {
        // Do not convert empty attributes (lack of value means no mention).
        if (!modelAttributeValue) {
          return;
        }

        return writer.createAttributeElement('span', {
          class: 'mention',
          'data-id': modelAttributeValue.id,
          'data-reference': modelAttributeValue.reference,
          'title': modelAttributeValue.name
        }, {
          // Make mention attribute to be wrapped by other attribute elements.
          priority: 20,
          // Prevent merging mentions together.
          id: modelAttributeValue.uid
        });
      },
      converterPriority: 'high'
    });
  }
  private __initPlugin_FileRepository(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new UploadAdapter(loader, this.afStorage);
    };
  }
  initEditor() {
    this._loader = true;
    this.Editor = new CkEditor(this.type, this.mode, this.toolbarItems);

    // mention user feed initialization
    this.Editor.config.mention.feeds.push({
      marker: '@',
      feed: (queryText) => mentionUserFeedGetFeedItems(queryText, this.usersToMention),
      itemRenderer: mentionUserFeedCustomItemRenderer,
      minimumCharacters: 0
    });

    this.getEditorRef()
      .then(editorRef => {
        return this.Editor.js
          .create(editorRef, this.Editor.config)
          .then(editor => {

            // Set a custom container for the toolbar.
            // if (this.type == 'decoupledDocument') {
            document.querySelector('.' + this.editorToolbarClass).appendChild(editor.ui.view.toolbar.element);
            document.querySelector('.ck-toolbar').classList.add('ck-reset_all');
            // }

            if (this.data)
              this.setData(this.data);

            editor.model.document.on('change:data', () => {
              this.onChangeEmitter();
            });

            // Plugins
            this._initPlugin_Mention(editor);
            this.__initPlugin_FileRepository(editor);

            this.editorERef = editor;
            this.ready = true;
            this._loader = false;
            this.onReadyEmitter();
            this.log('Editor was initialized ->instanceId: ', this.instanceId);
          })
      })
      .catch((e) => {
        this.logError('Error on Editor initialization ->instanceId:', this.instanceId, e);
      })
  }

  private onReadyEmitter() {
    this.onReady.emit(this)
  }
  private onChangeEmitter() {
    if (!this.editorERef)
      return
    const data = this.editorERef.getData();
    this.data = data;
    this.onChange.emit(this.data);

    this.usersMentioned = collectUsersMentioned(this.editorERef);
    this.onUsersMentionedChange.emit(this.usersMentioned);
  }
  private updateReadOnly() {
    if (this.editorERef)
      this.editorERef.isReadOnly = this.disabled;
    else
      setTimeout(() => {
        this.updateReadOnly();
      }, 250);
  }
  public setData(data: string) {
    this._loader = true;
    if (this.editorERef) {
      this.data = data;
      this.editorERef.setData(this.data);

      const currentData = this.editorERef.getData();
      if (currentData != data)
        setTimeout(() => { this.setData(data); }, 250);
      else
        this._loader = false;
    } else
      setTimeout(() => { this.setData(data); }, 250);
  }
}
