import firebase from "firebase/compat/app";

export type HomePageItemAscAppType = 'hpi' | 'note' | 'comms';
export class HomePageItemAsc {
  type: HomePageItemAscAppType;
  hpiId: string;
  width: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  public constructor(init?: Partial<HomePageItemAsc>) {
    this.type = 'hpi';
    this.hpiId = '';
    this.width = 6;
    if (init)
      Object.assign(this, init);
  }
}

export class HomePageTab {
  id: string;
  status: 0 | 1;

  title: string;
  position: number;

  itemAscs: HomePageItemAsc[];

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<HomePageTab>) {
    this.id = "";
    this.status = 0;

    this.title = "";
    this.position = 0;

    this.itemAscs = [];

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
