import firebase from "firebase/compat/app";
import { SM_DocImpact, SM_DocType, SM_DocUrgency } from '../SM_Document/SM_Document';

export class SM_BusinessRole {

  id: string;
  status: 0 | 1;
  name: string;
  type: SM_DocType;

  categoryId: string;
  subcategoryId: string;
  designedTeamId: string;
  calendarId: string;
  workflowGroupId: string;

  impact: SM_DocImpact;
  urgency: SM_DocUrgency;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<SM_BusinessRole>) {

    this.id = '';
    this.status = 0;
    this.name = '';
    this.type = null;

    this.categoryId = "";
    this.subcategoryId = "";
    this.designedTeamId = "";
    this.calendarId = '';
    this.workflowGroupId = "";

    this.impact = null;
    this.urgency = null;

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
