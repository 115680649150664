import * as ckeditorJs from 'assets/js/magic-text-editor/ckeditor.js';

import { EDITOR_ITEMS, EDITOR_ITEMS_ADVANCED, EDITOR_ITEMS_EXTEND, EditorConfgRestricted, EditorConfig } from './EditorConfig';

export interface CkEditorConfigMentionFeedItem {
  /** tag ex: '@Yure' */
  id: string;
  reference: string;
  name: string;
};

export class CkEditorConfig {
  toolbar: {
    items: string[],
    shouldNotGroupWhenFull?: boolean
  };
  language: string;
  image: {
    toolbar: string[]
  };
  table: {
    contentToolbar: string[]
  };
  mention: {
    dropdownLimit?: number,
    feeds: {
      marker: string;
      feed?: CkEditorConfigMentionFeedItem[] | ((queryText: string) => Promise<CkEditorConfigMentionFeedItem[]>);
      itemRenderer?: (item: CkEditorConfigMentionFeedItem) => any;
      minimumCharacters: number
    }[];
  }
  licenseKey: string;
  public constructor(init?: Partial<CkEditorConfig>) {
    this.toolbar = {
      items: []
    };
    this.language = 'pt-br';
    this.image = {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        'linkImage'
      ]
    };
    this.table = {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties'
      ]
    };
    this.licenseKey = '';
    this.mention = {
      feeds: []
    };
    if (init)
      Object.assign(this, init);
  }
}

export class CkEditor {
  js: any;
  config: CkEditorConfig = new CkEditorConfig();
  public constructor(type: 'classic' | 'decoupledDocument' = "classic", mode: 'standard' | 'restricted' = "standard", toolbarItems: 'standard' | 'extend' | 'advanced' = "standard") {
    if (type == 'classic')
      if (mode == 'standard')
        this.js = ckeditorJs.Classic;
      else
        this.js = ckeditorJs.ClassicRestricted;
    else
      if (mode == 'standard')
        this.js = ckeditorJs.DecoupledDocument;
      else
        this.js = ckeditorJs.DecoupledDocumentRestricted;

    if (mode == 'standard') {
      this.config = new CkEditorConfig(EditorConfig);
      switch (toolbarItems) {
        case "standard":
          this.config.toolbar.items = EDITOR_ITEMS;
          break;
        case "extend":
          this.config.toolbar.items = EDITOR_ITEMS_EXTEND;
          break;
        case "advanced":
          this.config.toolbar.items = EDITOR_ITEMS_ADVANCED;
          break;

        default:
          this.config.toolbar.items = EDITOR_ITEMS;
          break;
      }
    } else
      this.config = new CkEditorConfig(EditorConfgRestricted);
  }
};
