import firebase from "firebase/compat/app";

export class WS_Client {

  id: string;
  workspaceId: string;
  parentKey: string;
  favoritedUnames: string[];

  name: string;
  picture: string;
  thumbnail: string;
  email: string;
  phone: string;
  address: string;

  contactPerson: string;
  companyName: string;
  documentNumber: string;
  notes: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<WS_Client>) {

    this.id = "";
    this.workspaceId = "";
    this.parentKey = "";
    this.favoritedUnames = [];

    this.name = "";
    this.picture = '';
    this.thumbnail = '';
    this.email = "";
    this.phone = "";
    this.address = "";

    this.contactPerson = "";
    this.companyName = "";
    this.documentNumber = "";
    this.notes = "";

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = "";
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
