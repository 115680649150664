import firebase from "firebase/compat/app";

export class TM_ProjectAsc {

  id: string;
  projectId: string;
  parentKey: string;
  uName: string;
  role: 'Admin' | 'Member';
  status: 0 | 1;

  /** Valor para cobrança */
  billableCost: number;
  /** Valor da mão de obra */
  laborCost: number;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<TM_ProjectAsc>) {

    this.id = '';
    this.projectId = '';
    this.parentKey = '';
    this.uName = '';
    this.role = 'Member';
    this.status = 1;

    this.billableCost = 0;
    this.laborCost = 0;

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;


    if (init)
      Object.assign(this, init);
  }
}
