import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

import { LayoutService, UtilService } from 'app/@core';
import { DICTIONARY, TM_Project, TM_TimeRecord } from 'app/@firebase';
import { ThemeService } from 'app/@theme';
import { environment } from 'environments/environment';

@Component({
  selector: 'timer-week-hours-pie-doughnut-chart',
  styleUrls: ['./charts-common.component.scss'],
  template: `<div class="chart-container"><div echarts [options]="options" class="echart" (chartInit)="onChartInit($event)"></div></div>`,
})
export class TimerWeekHoursPieDoughnutChartComponent implements AfterViewInit, OnDestroy {

  private alive = true;
  private title = 'Horas por Projeto';

  private echartsIntance: any;

  public chartHeight = 400;
  options: any = {};

  public DICTIONARY = new DICTIONARY();

  constructor(
    private theme: ThemeService,
    private layoutService: LayoutService,
    protected utilCtrl: UtilService
  ) {
    this.layoutService.onSafeChangeLayoutSize()
      .pipe(
        takeWhile(() => this.alive),
      )
      .subscribe(() => this.resizeChart());
  }
  ngAfterViewInit() { }
  ngOnDestroy(): void {
    this.alive = false;
  }
  public load(timeRecords: TM_TimeRecord[], tmProjects: TM_Project[]) {
    let tRIndexByProject = new Map<string, { project: TM_Project, val: number }>();

    for (let tr of timeRecords) {
      if (tRIndexByProject.get(tr.projectId)) {
        tRIndexByProject.get(tr.projectId).val += tr.duration;
      } else {
        let tempProject = new TM_Project();
        for (let p of tmProjects)
          if (p.id == tr.projectId) {
            tempProject = p;
            break;
          }
        tRIndexByProject.set(tr.projectId, { project: tempProject, val: tr.duration });
      }
    }

    let tempSerieColors: string[] = [];
    let tempSerieData: { value: any, name: string }[] = [];

    tRIndexByProject.forEach((data, projectId) => {
      tempSerieData.push({ name: `${data.project.teamId} - ${data.project.name}`, value: data.val.toFixed(2) });
      let tempColor = this.DICTIONARY.getData(data.project.colorId, this.DICTIONARY.timeManagement.project.color)['hex'];
      if (!tempColor)
        tempColor = '#fff';
      tempSerieColors.push(tempColor);
    });

    this.updateOptions(this.seriesMaker('Total', tempSerieColors, tempSerieData));
  }
  private seriesMaker(name: string, colorsArray: string[], data: { value: any, name: string }[]): any {
    return {
      name: name,
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: this.theme.echartsTheme.itemHoverShadowColor,
        },
      },
      label: {
        show: false,
      },
      // emphasis: {
      //   label: {
      //     show: true,
      //     fontSize: this.theme.echartsTheme.fontSize,
      //     fontWeight: this.theme.echartsTheme.fontWeight,
      //   }
      // },
      // label: {
      //   show: true,
      //   normal: {
      //     textStyle: {
      //       color: this.theme.echartsTheme.textColor,
      //     },
      //   },
      // },
      // labelLine: {
      //   normal: {
      //     lineStyle: {
      //       color: this.theme.echartsTheme.axisLineColor,
      //     },
      //   },
      // },
      color: colorsArray,
      data: data
    };
  }
  private updateOptions(series: any[]) {
    this.options = {
      title: {
        text: this.title,
        // subtext: '',
        textStyle: {
          color: this.theme.echartsTheme.textColor,
          fontSize: this.theme.echartsTheme.fontSize,
        },
      },
      backgroundColor: this.theme.echartsTheme.bg,
      responsive: true,
      maintainAspectRatio: false,
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        type: 'scroll',
        top: 20,
        bottom: 20,
        orient: 'horizontal',
        textStyle: {
          color: this.theme.echartsTheme.textColor,
        },
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: true },
          saveAsImage: { show: true }
        }
      },
      series: series,
    };
    this.resizeChart();
    if (!environment.production) {
      console.log(`timer-week-hours-pie-doughnut-chart - Options -> `, this.options);
      // console.log(`JSON ->`, JSON.stringify(this.options, undefined, 2));
    }
  }
  onChartInit(echarts) {
    this.echartsIntance = echarts;
  }
  resizeChart() {
    if (this.echartsIntance) {
      // Fix recalculation chart size
      // TODO: investigate more deeply
      setTimeout(() => {
        this.echartsIntance.resize({ height: this.chartHeight });
      }, 100);
    }
  }
}
