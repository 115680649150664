import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';

import { DIRECTORIES } from './DIRECTORIES';

@Injectable()
export class FireStorageService {

  public DIRECTORIES = DIRECTORIES;

  constructor(
    private afStorage: AngularFireStorage
  ) { }

  /**
   * Faz o upload
   * @param {string} path
   * @param {string} dataUrlFile base64 content
   * @param {string} fileName sem o .jpeg
   * @return {Promise<string>} `downloadURL` in a Promise
   */
  public uploadFileAsDataUrl(path: string, fileName: string, dataUrlFile: string): Promise<string> {
    console.log('Fazendo Upload...')
    return new Promise((resolve, reject) => {

      const tempDocRef = this.afStorage.ref(path + "/" + fileName);
      var task = tempDocRef.putString(dataUrlFile, 'data_url') as AngularFireUploadTask;

      task.snapshotChanges().subscribe(snapshot => {
        var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Fazendo Upload... " + Number(percentage).toFixed(2) + " %");
      });

      task.then(() => {
        tempDocRef.getDownloadURL().toPromise()
          .then(url => {
            console.log('Upload concluido: ' + url)
            resolve(url);
          })
          .catch((e) => {
            console.log({ log: 'erro ao recuperar Url', error: JSON.stringify(e) })
            reject({ log: 'erro ao recuperar Url', error: JSON.stringify(e) });
          });
      });

      task.catch((e) => {
        console.log({ log: 'erro ao fazer upload', error: JSON.stringify(e) });
        reject({ log: 'erro ao fazer upload', error: JSON.stringify(e) });
      });

    });
  }
  /**
   * Faz o upload
   * @param {string} path
   * @param {string} dataUrlFile base64 content
   * @param {string} file
   * @return {Promise<string>} `downloadURL` in a Promise
   */
  public uploadFile(path: string, fileName: string, file: File): Promise<string> {
    console.log('Fazendo Upload...')
    return new Promise((resolve, reject) => {
      const tempDocRef = this.afStorage.ref(path + "/" + fileName);
      var task = tempDocRef.put(file) as AngularFireUploadTask;
      task.snapshotChanges().subscribe(snapshot => {
        var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Fazendo Upload... " + Number(percentage).toFixed(2) + " %");
      });
      task.then(() => {
        tempDocRef.getDownloadURL().toPromise()
          .then(url => {
            console.log('Upload concluido: ' + url)
            resolve(url);
          })
          .catch((e) => {
            console.log({ log: 'erro ao recuperar Url', error: JSON.stringify(e) })
            reject({ log: 'erro ao recuperar Url', error: JSON.stringify(e) });
          });
      });
      task.catch((e) => {
        console.log({ log: 'erro ao fazer upload', error: JSON.stringify(e) });
        reject({ log: 'erro ao fazer upload', error: JSON.stringify(e) });
      });

    });
  }
  public deleteFile(path: string, fileName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const tempDocRef = this.afStorage.ref(path + "/" + fileName);
      tempDocRef.delete().toPromise()
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject({ log: 'erro ao deletar arquivo', error: JSON.stringify(e) });
        });
    });
  }

  /**
   * Redimensiona imagem
   * @param {string} base64Img original base64JpegFile
   * @param {number} MAX_WIDTH the final img width
   * @param {number} MAX_HEIGHT the final img height
   * @param {quality} quality the final img quality 0 to 1 as percentage of the original image
   * @return {Promise<string>} base64JpegFile as `Promise<string>`
   */
  public reziseImage(base64Img: string, MAX_WIDTH: number = 700, MAX_HEIGHT: number = 700, quality: number = 1): Promise<string> {
    return new Promise((resolve) => {

      var canvas: any = document.createElement("canvas");
      var image = new Image();

      image.onload = () => {
        var width = image.width;
        var height = image.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");

        ctx.drawImage(image, 0, 0, width, height);

        // IMPORTANT: 'jpeg' NOT 'jpg'
        var dataUrl = canvas.toDataURL('image/jpeg', quality);

        resolve(dataUrl)
      }

      image.src = base64Img;
    });
  }
}
