import { AngularFirestore } from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";

import { Condition, DB_MagicQuery, FilterGroup, OrderByDirection } from "./DB_MagicQuery.types";

const TIMESTAMP_KEYS = ['seconds', 'nanoseconds'];
const TIMESTAMP2_KEYS = ['_seconds', '_nanoseconds'];

function __compareObjectKeysArrays(keys1: string[], keys2: string[]): boolean {
    let equals = true;
    for (const k1 of keys1)
        if (keys2.indexOf(k1) == -1) {
            equals = false;
            break;
        }

    if (equals)
        for (const k2 of keys2)
            if (keys1.indexOf(k2) == -1) {
                equals = false;
                break;
            }

    return equals;
}
function __newTimestamp(seconds: number, nanoseconds: number): firebase.firestore.Timestamp {
    return new firebase.firestore.Timestamp(seconds, nanoseconds);
}
function _valueStandardizer(value: any): any {
    // null | undefined
    if (!value) return null;

    // Array
    if (Array.isArray(value)) {
        if (value.length === 0) return null;
        return value.map((item) => _valueStandardizer(item));
    }

    // Object
    if (typeof value === 'object') {
        const value_keys = Object.keys(value);

        // Date or Timestamp
        if (value instanceof Date) return value.getTime();
        if (__compareObjectKeysArrays(value_keys, TIMESTAMP_KEYS))
            return __newTimestamp(value['seconds'], value['nanoseconds']).toDate().getTime();
        if (__compareObjectKeysArrays(value_keys, TIMESTAMP2_KEYS))
            return __newTimestamp(value['_seconds'], value['_nanoseconds']).toDate().getTime();

        return JSON.stringify(value);
    }

    // string ""
    if (value == '') return null;

    // Date and time format - ISO 8601
    const dateTimeRegex = /\d{4}(.\d{2}){2}(\s|T)(\d{2}.){2}\d{2}/g;
    if (dateTimeRegex.test(value)) return new Date(value).getTime();

    // Number
    const tempNum = Number(value);
    if (!isNaN(tempNum)) return tempNum;

    return value;
}

export async function executeQueryLocally(firestore: AngularFirestore, query: DB_MagicQuery): Promise<any[]> {
    console.error("executing Query Locally");

    let result: any[] = [];
    const collectionsData: Record<string, any[]> = {};

    try {
        // Recupera todos os documentos da coleção principal
        const mainCollectionSnapshot = await firestore.collection(query.mainCollection).ref.get({ source: 'server' });
        collectionsData[query.mainCollection] = [];
        mainCollectionSnapshot.forEach((doc) => {
            const docData = doc.data();
            collectionsData[query.mainCollection].push(docData);
            result.push({ [query.mainCollection]: docData });
        });


        // Aplica joins
        // 1- Recupera todos os documentos das coleções dos Joins
        const uniqueNewCollections = Array.from(new Set(query.joins.map((join) => join.newCollection)));
        await Promise.all(uniqueNewCollections.map(async (newCollection) => {
            const newCollectionSnapshot = await firestore.collection(newCollection).ref.get({ source: 'server' });
            collectionsData[newCollection] = newCollectionSnapshot.docs.map((doc) => doc.data());
        }));

        // Listar coleções e tamanhos
        let collectionsreadStr = 'Coleções lidas:\n';
        Object.entries(collectionsData).forEach(([collection, data]) => {
            collectionsreadStr += `${collection}: ${data.length}\n`;
        });
        console.log(collectionsreadStr);

        // 2- Realiza o join localmente
        result = result.map((rawData) => {
            const newData = { ...rawData };
            for (const join of query.joins) {
                const localCollection = join.localCollection ? join.localCollection : query.mainCollection;
                const localValue = _valueStandardizer(rawData[localCollection][join.localField]);
                const matchingDoc = collectionsData[join.newCollection].find((j) => _valueStandardizer(j[join.foreignField]) === localValue);
                newData[join.newCollection] = matchingDoc || null;
            }
            return newData;
        });

        // Aplica outputFields
        if (query.outputFields.length > 0)
            result = result.map((item) => {
                const newItem: any = {};
                query.outputFields.forEach((fieldMapping) => {
                    if (typeof fieldMapping === 'string') {
                        // Quando fieldMapping é uma string
                        const originalField = fieldMapping;
                        newItem[originalField] = item[query.mainCollection][originalField];
                    } else {
                        // Quando fieldMapping é um objeto
                        const { originalCollection, originalField, outputField } = fieldMapping;
                        if (item[originalCollection])
                            newItem[outputField] = item[originalCollection][originalField];
                    }
                });
                return newItem;
            });

        // Aplica condições
        if (query.conditions.length > 0) {
            const applyFilterGroup = (group: FilterGroup, item: any): boolean => {
                const groupResult = group.filters.map((filter) => {
                    const fieldValue = _valueStandardizer(item[filter.outputField]);
                    const filterValue = _valueStandardizer(filter.value);
                    switch (filter.operator) {
                        case '==': return fieldValue === filterValue;
                        case '!=': return fieldValue !== filterValue;
                        case '<': return fieldValue < filterValue;
                        case '<=': return fieldValue <= filterValue;
                        case '>': return fieldValue > filterValue;
                        case '>=': return fieldValue >= filterValue;
                        case 'array-contains': return Array.isArray(fieldValue) && fieldValue.includes(filterValue);
                        case 'in': return Array.isArray(filterValue) && filterValue.includes(fieldValue);
                        case 'array-contains-any': return Array.isArray(fieldValue) && Array.isArray(filterValue) && filterValue.some((val) => fieldValue.includes(val));
                        case 'not-in': return Array.isArray(filterValue) && !filterValue.includes(fieldValue);
                        default: return false;
                    }
                });
                const result = group.operator === 'AND' ? groupResult.every(Boolean) : groupResult.some(Boolean);
                return group.negate ? !result : result;
            };

            const applyCondition = (condition: Condition, item: any): boolean => {
                const conditionResult = condition.filterGroups.map((group) => applyFilterGroup(group, item));
                return condition.groupOperator === 'AND' ? conditionResult.every(Boolean) : conditionResult.some(Boolean);
            };

            result = result.filter((item) => query.conditions.some((condition) => applyCondition(condition, item)));
        }

        // Aplica orderBy
        if (query.orderBy) {
            const { outputField, direction } = typeof query.orderBy === 'string' ? { outputField: query.orderBy, direction: 'asc' as OrderByDirection } : query.orderBy;

            const orderByCore = (a: any, b: any, direction: OrderByDirection): number => {
                const aValue = _valueStandardizer(a);
                const bValue = _valueStandardizer(b);
                if (aValue < bValue) return direction === 'asc' ? -1 : 1;
                if (aValue > bValue) return direction === 'asc' ? 1 : -1;
                return 0;
            };

            // Ordenação por vetor dentro dos documentos
            result.forEach((item) => {
                if (Array.isArray(item[outputField]))
                    item[outputField].sort((a, b) => orderByCore(a, b, direction));
            });

            // Ordenação dos próprios resultados
            result.sort((a, b) => orderByCore(a[outputField], b[outputField], direction));
        }

        // Aplica limite
        if (query.limit && query.limit > 0)
            result = result.slice(0, query.limit);


    } catch (error) {
        console.error('Erro ao executar a consulta:', error);
    }
    return result;
}