export class DB_FilterCondition {
  fieldId: string;
  operationId: string;
  value: any;
  public constructor(init?: Partial<DB_FilterCondition>) {
    this.fieldId = '';
    this.operationId = '';
    this.value = null;
    if (init)
      Object.assign(this, init);
  }
}

export type DB_FilterRuleClause = 'AND' | 'OR';

export class DB_FilterRule {
  conditions: DB_FilterCondition[];
  clause: DB_FilterRuleClause;
  public constructor(init?: Partial<DB_FilterCondition>) {
    this.conditions = [];
    this.clause = 'AND';
    if (init)
      Object.assign(this, init);
  }
}

/** 
 * RuleSet -> FilterRules[] -> FilterRule 
 */
export type DB_FilterRuleSet = DB_FilterRule[][];