import { Injectable } from '@angular/core';
import { id } from 'date-fns/locale';

@Injectable()
export class SmartTableSelectService {

  private db: { instName: string; data: { varName: string; valueJsonStr: string }[] }[] = [];

  constructor() { }

  getValue(instName: string, varName: string): any {
    let tempValue = null;
    for (let db of this.db) {
      if (db.instName == instName) {
        for (let data of db.data) {
          if (data.varName == varName) {
            tempValue = JSON.parse(data.valueJsonStr);
            break;
          }
        }
        break;
      }
    }
    return tempValue;
  }
  setValue(instName: string, varName: string, value: any) {
    let varExists = false;
    let indexOfInstance = -1;
    for (let i = 0; i < this.db.length; i++) {
      if (this.db[i].instName == instName) {
        indexOfInstance = i;
        for (let x = 0; x < this.db[i].data.length; x++) {
          if (this.db[i].data[x].varName == varName) {
            varExists = true;
            this.db[i].data[x].valueJsonStr = JSON.stringify(value);
            break;
          }
        }
        break;
      }
    }
    if (!varExists) {
      if (indexOfInstance != -1) {
        this.db[indexOfInstance].data.push({
          varName: varName,
          valueJsonStr: JSON.stringify(value)
        })
      } else {
        this.db.push({
          instName: instName,
          data: [{
            varName: varName,
            valueJsonStr: JSON.stringify(value)
          }]
        })
      }
    }
  }
}
