<div class="ic-gantt-main-container">

    <!-- <button (click)="toggleGroups()">{{groupModeStr}}</button> -->

    <!-- <div class="ic-gantt-actions-container">
        <div class="ic-gantt-actions">
            <div class="ic-gantt-actions__row">
                <div class="ic-gantt-actions__col">
                    <nb-toggle class="ic-gantt-action" (checkedChange)="_toggleCollapseRows($event)">
                        Recolher linhas
                    </nb-toggle>
                </div>

                <div class="ic-gantt-actions__col">
                    <button class="ic-gantt-action" nbButton [disabled]="!undoBtnActive" title="Desfazer" size="tiny"
                        (click)="_undo()">
                        <nb-icon icon="undo-outline" pack="eva"></nb-icon>
                    </button>
                </div>

                <div class="ic-gantt-actions__col">
                    <button class="ic-gantt-action" nbButton [disabled]="!redoBtnActive" title="Refazer" size="tiny"
                        (click)="_redo()">
                        <nb-icon style="transform: scaleX(-1);" icon="undo-outline" pack="eva"></nb-icon>
                    </button>
                </div>

                <div class="ic-gantt-actions__col">
                    <nb-toggle class="ic-gantt-action" (checkedChange)="_toggleAutoScheduling($event)" checked="true">
                        Agendamento Automático
                    </nb-toggle>
                </div>

                <div class="ic-gantt-actions__col">
                    <nb-toggle class="ic-gantt-action" (checkedChange)="_toggleCriticalPath($event)" checked="true">
                        Caminho crítico
                    </nb-toggle>
                </div>

                <div class="ic-gantt-actions__col">
                    <nb-toggle class="ic-gantt-action" (checkedChange)="_toggleZoomToFit($event)" checked="true">
                        Zoom para caber
                    </nb-toggle>
                </div>

                <div class="ic-gantt-actions__col">
                    <div class="scale-container">
                        <select id="scale_select" name="scale_select" class="ic-form-control" [(ngModel)]="scale"
                            (ngModelChange)="_changeScale()">
                            <option *ngFor="let s of scales4Sel" [ngValue]="s">
                                {{s.name}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="ic-gantt-actions__row">
                <button class="ic-gantt-btn" nbButton size="small" (click)="_exportToPDF()">
                    <nb-icon icon="download-outline" pack="eva"></nb-icon>
                    Export to PDF
                </button>

                <button class="ic-gantt-btn" nbButton size="small" (click)="_exportToPNG()">
                    <nb-icon icon="download-outline" pack="eva"></nb-icon>
                    Export to PNG
                </button>

                <button class="ic-gantt-btn" nbButton size="small" (click)="_exportToExcel()">
                    <nb-icon icon="download-outline" pack="eva"></nb-icon>
                    Export to Excel
                </button>

                <button class="ic-gantt-btn" nbButton size="small" (click)="_exportToMSProject()">
                    <nb-icon icon="download-outline" pack="eva"></nb-icon>
                    Export to MS Project
                </button>

                <button class="ic-gantt-btn" nbButton size="small" (click)="_exportToMSProject()">
                    <nb-icon icon="download-outline" pack="eva"></nb-icon>
                    Export to MS Project
                </button>
            </div>

            <div class="ic-gantt-actions__last-elem">
                <button class="ic-gantt-btn" nbButton size="small" (click)="_fullscreen()">
                    <nb-icon icon="download-outline" pack="eva"></nb-icon>
                    Fullscreen
                </button>
            </div>
        </div>
    </div> -->

    <div class="ic-gantt-main-content">
        <div #gantt_here class="gantt-chart"></div>
    </div>
</div>