import { THEMES } from "./theme.consts";

export function isValidThemeName(themeName): boolean {
    let valid = false;
    for (let t of THEMES)
        if (t.name === themeName) {
            valid = true;
            break;
        }
    return valid;
};
