import { DbTableComponent } from "../db-table.component";
import { dbTableFilterValueDetermination } from "./db-table-filter-value-determination";
import { dbTableOrderByCore } from "./db-table-order-by-core";

/**
 * Atualiza o index de filtro para a coluna alterada
 */
export function dbTableUpdateFilterIndexes(_this: DbTableComponent, c_index: number) {
    let tempColIndex = [];
    let tempSingleValues = [];

    for (let r of _this.table.rows) {
        const fValue = dbTableFilterValueDetermination(r, c_index, _this.table.cols[c_index]);
        if (tempSingleValues.indexOf(fValue) == -1) {
            tempSingleValues.push(fValue);
            tempColIndex.push({ show: true, value: fValue });
        }
    }

    tempColIndex.sort((a, b) => dbTableOrderByCore(a, b, "asc"));

    // retrives filter show values on old index

    tempColIndex.forEach((fi) => {
        for (let f of _this.filterIndexes[c_index])
            if (f.value == fi.value) {
                fi.show = f.show;
                break;
            }
    });

    _this.filterIndexes[c_index] = tempColIndex;
}