<mat-dialog-content>

  <nb-card [nbSpinner]="loader" nbSpinnerMessage="Loading..." nbSpinnerSize="large" nbSpinnerStatus="basic"
    class="col-12">
    <nb-card-header>
      Add Object To Role

      <button class="close" mat-button matDialogClose>
        <span aria-hidden="true">&times;</span>
      </button>
    </nb-card-header>
    <nb-card-body>

      <div class="form-group">
        <div class="row">
          <div class="col-4">
            <button nbButton status="primary" size="small" (click)="add()">
              <nb-icon icon="plus-outline" pack="eva"></nb-icon>
            </button>
          </div>
        </div>
      </div>

      <table *ngIf="objectsIds.length>0" class="clean-table">
        <thead>
          <tr>
            <th style="width: 30px;">Nº</th>
            <th>Object Id</th>
            <th style="width: 30px;"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let on of objectsIds; let i=index">
            <td>{{i+1}}</td>
            <td>
              <p>{{on}}</p>
            </td>
            <td>
              <button nbButton status="danger" size="small" (click)="removeConfirmation(i)">
                <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="padding"></div>


      <nb-card>
        <nb-card-body>

          <div class="row">
            <div class="col-12 padding" style="text-align: center">
              <div class="button-container">
                <button nbButton status="primary" size="small" [disabled]="objectsIds.length==0" (click)="save()">
                  <nb-icon icon="save-outline" pack="eva"></nb-icon>
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </nb-card-body>
      </nb-card>

    </nb-card-body>
  </nb-card>

</mat-dialog-content>
