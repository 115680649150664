import { UtilService } from "app/@core";
import { AuditLogChange, DICTIONARY, PM_BoardLabel, PM_Card, PM_ToDoList, User } from "app/@firebase";

function stringify_members(members: string[], users: User[]): string {
  const memberNames = members.map(member => {
    const user = users.find(u => u.uName === member);
    return user ? user.fullName : null;
  }).filter(name => name !== null);

  return memberNames.length ? memberNames.join(', ') + '.' : '';
}
function stringify_ordArray(ordArray: string[], dataArray: { id: string; name: string; }[]): string {
  const dataMap = dataArray.reduce((acc, data) => {
    acc[data.id] = data.name;
    return acc;
  }, {} as Record<string, string>);
  const orderedNames = ordArray.map((dataId, index) => `${index + 1}º - ${dataMap[dataId]}`);
  return orderedNames.join(', ');
}
// function stringify_toDoList(toDoList: PM_CardToDo[], utilCtrl: UtilService): string {
//   let str = "";
//   for (let index = 0; index < toDoList.length; index++) {
//     const toDo = toDoList[index];
//     const toDoStr = `${index + 1}º - ${toDo.title} | Concluido: ${utilCtrl.converters.booleanToStr(toDo.done)}`;
//     if (index == 0)
//       str = `${toDoStr}`;
//     else if (index < (toDoList.length - 1))
//       str = `${str}, ${toDoStr}`;
//     else
//       str = `${str}, ${toDoStr}.`;
//   }
//   return str;
// }
// export function stringify_toDoLists(toDoLists: PM_CardToDoList[], utilCtrl: UtilService): string {
//   let str = "";
//   for (let index = 0; index < toDoLists.length; index++) {
//     const toDoList = toDoLists[index];
//     const toDoListStr = `${index + 1}º - ${toDoList.title} -> [${stringify_toDoList(toDoList.list, utilCtrl)}]`;
//     if (index == 0)
//       str = `${toDoListStr}`;
//     else if (index < (toDoLists.length - 1))
//       str = `${str}, ${toDoListStr}`;
//     else
//       str = `${str}, ${toDoListStr}.`;
//   }
//   return str;
// }
export function stringify_relations(relatedCardsIds: string[], utilCtrl: UtilService): string {
  let str = "";
  let _relations = utilCtrl.arrays.sort(relatedCardsIds);
  _relations.forEach(cId => {
    str += cId;
  })
  return str;
}
function formatDayOfMonth(day: number): string {
  return `${day}º`;
}
function formatDayOfWeek(dayOfWeek: number): string {
  const daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
  return daysOfWeek[dayOfWeek];
}
function formatMonthOfYear(month: number): string {
  const months = [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ];
  return months[month];
}
function formatFrequency(frequency: string): string {
  switch (frequency) {
    case "daily":
      return "Diária";
    case "weekly":
      return "Semanal";
    case "monthly":
      return "Mensal";
    case "yearly":
      return "Anual";
    default:
      return frequency; // Retorna o valor original se não for reconhecido
  }
}
function formatEarlyCreation(earlyCreation: string): string {
  switch (earlyCreation) {
    case "d":
      return "Dias";
    case "h":
      return "Horas";
    case null:
      return "Não Adiantar";
    default:
      return earlyCreation;
  }
}
export function PM_Card_dataChangGen(oldDoc: PM_Card, newDoc: PM_Card, users: User[], labels: PM_BoardLabel[], toDoLists: PM_ToDoList[], utilCtrl: UtilService): AuditLogChange[] {
  let dic = new DICTIONARY();
  let tempChanges: AuditLogChange[] = [];
  if (oldDoc.title != newDoc.title) {
    tempChanges.push({
      fieldName: 'Título',
      oldValue: oldDoc.title,
      newValue: newDoc.title
    })
  }
  if (oldDoc.description != newDoc.description) {
    tempChanges.push({
      fieldName: 'Descrição',
      oldValue: oldDoc.description,
      newValue: newDoc.description
    })
  }
  if (oldDoc.status != newDoc.status) {
    tempChanges.push({
      fieldName: 'Status',
      oldValue: dic.translator(oldDoc.status, dic.general.status),
      newValue: dic.translator(newDoc.status, dic.general.status)
    })
  }

  if (oldDoc.priority != newDoc.priority) {
    tempChanges.push({
      fieldName: 'Prioridade ',
      oldValue: `${oldDoc.priority} - ${dic.translator(oldDoc.priority, dic.projectManagement.boards.cards.priority)}`,
      newValue: `${newDoc.priority} - ${dic.translator(newDoc.priority, dic.projectManagement.boards.cards.priority)}`
    })
  }

  if (utilCtrl.timestamp.toLocalDate(oldDoc.startDate) != utilCtrl.timestamp.toLocalDate(newDoc.startDate)) {
    tempChanges.push({
      fieldName: 'Prazo: Data de Início',
      oldValue: utilCtrl.timestamp.toLocalDate(oldDoc.startDate),
      newValue: utilCtrl.timestamp.toLocalDate(newDoc.startDate)
    })
  }
  if (utilCtrl.timestamp.toLocalDate(oldDoc.dueDate) != utilCtrl.timestamp.toLocalDate(newDoc.dueDate)) {
    tempChanges.push({
      fieldName: 'Prazo: Data de Entrega',
      oldValue: utilCtrl.timestamp.toLocalDate(oldDoc.dueDate),
      newValue: utilCtrl.timestamp.toLocalDate(newDoc.dueDate)
    })
  }
  
  if (oldDoc.done != newDoc.done) {
    tempChanges.push({
      fieldName: 'Entregue',
      oldValue: utilCtrl.converters.booleanToStr(oldDoc.done),
      newValue: utilCtrl.converters.booleanToStr(newDoc.done)
    })
  }
  if (utilCtrl.timestamp.toLocalDate(oldDoc.doneAt) != utilCtrl.timestamp.toLocalDate(newDoc.doneAt)) {
    tempChanges.push({
      fieldName: 'Entregue em',
      oldValue: utilCtrl.timestamp.toLocalDate(oldDoc.doneAt),
      newValue: utilCtrl.timestamp.toLocalDate(newDoc.doneAt)
    })
  }

  // Designados
  let assignedTo_oldStr = stringify_members(oldDoc.assignedTo, users);
  let assignedTo_newStr = stringify_members(newDoc.assignedTo, users);

  if (assignedTo_oldStr != assignedTo_newStr) {
    tempChanges.push({
      fieldName: 'Designados',
      oldValue: assignedTo_oldStr,
      newValue: assignedTo_newStr
    })
  }
  // Designados

  // Rótulos
  let labelsIds_oldStr = stringify_ordArray(oldDoc.labelsIds, labels);
  let labelsIds_newStr = stringify_ordArray(newDoc.labelsIds, labels);

  if (labelsIds_oldStr != labelsIds_newStr) {
    tempChanges.push({
      fieldName: 'Rótulos',
      oldValue: labelsIds_oldStr,
      newValue: labelsIds_newStr
    })
  }
  // Rótulos

  // Cartões Relacionados
  let relations_oldStr = stringify_relations(oldDoc.relatedCardsIds, utilCtrl);
  let relations_newStr = stringify_relations(newDoc.relatedCardsIds, utilCtrl);

  if (relations_oldStr != relations_newStr) {
    tempChanges.push({
      fieldName: 'Cartões Relacionados',
      oldValue: relations_oldStr,
      newValue: relations_newStr
    })
  }
  // Cartões Relacionados

  // To Do lists
  let toDoLists_data = toDoLists.map((t) => { return { id: t.id, name: t.title } });
  let toDoList_oldStr = stringify_ordArray(oldDoc.toDoListsIds, toDoLists_data);
  let toDoList_newStr = stringify_ordArray(newDoc.toDoListsIds, toDoLists_data);

  if (toDoList_oldStr != toDoList_newStr) {
    tempChanges.push({
      fieldName: 'Ordenação de To Do lists',
      oldValue: toDoList_oldStr,
      newValue: toDoList_newStr
    })
  }
  // To Do lists

  // Recorrência
  if (oldDoc.recurrence && newDoc.recurrence) {
    // Lógica de comparação
    if (oldDoc.recurrence.frequency !== newDoc.recurrence.frequency) {
      tempChanges.push({
        fieldName: 'Recorrência: Frequência',
        oldValue: formatFrequency(oldDoc.recurrence.frequency) || 'Nenhum',
        newValue: formatFrequency(newDoc.recurrence.frequency) || 'Nenhum'
      });
    }

    if (oldDoc.recurrence.interval !== newDoc.recurrence.interval) {
      tempChanges.push({
        fieldName: 'Recorrência: Intervalo',
        oldValue: oldDoc.recurrence.interval.toString(),
        newValue: newDoc.recurrence.interval.toString()
      });
    }

    if (oldDoc.recurrence.endDate !== newDoc.recurrence.endDate) {
      tempChanges.push({
        fieldName: 'Recorrência: Data de Término',
        oldValue: utilCtrl.date.toLocalDate(utilCtrl.date.getDateObj(oldDoc.recurrence.endDate, '23:59:59')),
        newValue: utilCtrl.date.toLocalDate(utilCtrl.date.getDateObj(newDoc.recurrence.endDate, '23:59:59'))
      });
    }

    if (oldDoc.recurrence.daysOfWeek.join(',') !== newDoc.recurrence.daysOfWeek.join(',')) {
      tempChanges.push({
        fieldName: 'Recorrência: Dias da Semana',
        oldValue: oldDoc.recurrence.daysOfWeek?.length > 0 ? oldDoc.recurrence.daysOfWeek.map((d) => formatDayOfWeek(d)).join(', ') : 'Nenhum',
        newValue: newDoc.recurrence.daysOfWeek?.length > 0 ? newDoc.recurrence.daysOfWeek.map((d) => formatDayOfWeek(d)).join(', ') : 'Nenhum'
      });
    }

    if (oldDoc.recurrence.dayOfMonth !== newDoc.recurrence.dayOfMonth) {
      tempChanges.push({
        fieldName: 'Recorrência: Dia do Mês',
        oldValue: oldDoc.recurrence.dayOfMonth !== null ? formatDayOfMonth(oldDoc.recurrence.dayOfMonth) : 'Nenhum',
        newValue: newDoc.recurrence.dayOfMonth !== null ? formatDayOfMonth(newDoc.recurrence.dayOfMonth) : 'Nenhum'
      });
    }

    if (oldDoc.recurrence.monthOfYear !== newDoc.recurrence.monthOfYear) {
      tempChanges.push({
        fieldName: 'Recorrência: Mês do Ano',
        oldValue: oldDoc.recurrence.monthOfYear !== null ? formatMonthOfYear(oldDoc.recurrence.monthOfYear) : 'Nenhum',
        newValue: newDoc.recurrence.monthOfYear !== null ? formatMonthOfYear(newDoc.recurrence.monthOfYear) : 'Nenhum'
      });
    }

    if (oldDoc.recurrence.earlyCreation !== newDoc.recurrence.earlyCreation) {
      tempChanges.push({
        fieldName: 'Recorrência: Criação antecipada',
        oldValue: formatEarlyCreation(oldDoc.recurrence.earlyCreation),
        newValue: formatEarlyCreation(newDoc.recurrence.earlyCreation)
      });
    }

    if (oldDoc.recurrence.earlyCreationValue !== newDoc.recurrence.earlyCreationValue) {
      tempChanges.push({
        fieldName: 'Recorrência: Antecip. Em',
        oldValue: oldDoc.recurrence.earlyCreationValue.toString(),
        newValue: newDoc.recurrence.earlyCreationValue.toString()
      });
    }

    if (oldDoc.recurrence.creationRouteId !== newDoc.recurrence.creationRouteId) {
      tempChanges.push({
        fieldName: 'Recorrência: Rota',
        oldValue: oldDoc.recurrence.creationRouteId,
        newValue: newDoc.recurrence.creationRouteId
      });
    }

  } else {
    if (!oldDoc.recurrence && newDoc.recurrence) {
      tempChanges.push({
        fieldName: 'Recorrência',
        oldValue: 'Desativada',
        newValue: 'Ativada'
      });
    }

    if (oldDoc.recurrence && !newDoc.recurrence) {
      tempChanges.push({
        fieldName: 'Recorrência',
        oldValue: 'Ativada',
        newValue: 'Desativada'
      });
    }
  }
  // Recorrência


  if (oldDoc.template != newDoc.template) {
    tempChanges.push({
      fieldName: 'Modelo',
      oldValue: utilCtrl.converters.booleanToStr(oldDoc.template),
      newValue: utilCtrl.converters.booleanToStr(newDoc.template)
    })
  }

  return tempChanges;
};
