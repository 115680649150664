import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './@core';

const routes: Routes = [
  { path: 'init-db', loadChildren: () => import('./routes/init-db/init-db.module').then(m => m.InitDBModule) },

  { path: 'auth', loadChildren: () => import('./routes/auth/auth.module').then(m => m.AuthModule) },

  { path: 'main', loadChildren: () => import('./routes/main/main.module').then(m => m.MainModule), canActivate: [AuthGuard] },

  { path: 'print', loadChildren: () => import('./routes/print/print.module').then(m => m.PrintModule), canActivate: [AuthGuard] },

  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth/login' },
];

const config: ExtraOptions = {
  useHash: true,
  enableTracing: false,
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
