import { DbTableOrderDirection } from "../db-table.types";
import { dbTableValueStandardizer } from "./db-table-value-standardizer";

export function dbTableOrderByCore(a: any, b: any, direction: DbTableOrderDirection): number {
    let aValue = dbTableValueStandardizer(a);
    let bValue = dbTableValueStandardizer(b);
    if (Array.isArray(aValue)) {
        aValue = aValue.sort((a, b) => dbTableOrderByCore(a, b, direction));
        aValue = aValue[0];
    }
    if (Array.isArray(bValue)) {
        bValue = bValue.sort((a, b) => dbTableOrderByCore(a, b, direction));
        bValue = bValue[0];
    }
    if (aValue < bValue) return direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return direction === 'asc' ? 1 : -1;
    return 0;
};