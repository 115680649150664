import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NbGlobalLogicalPosition, NbToastrConfig, NbToastRef, NbToastrService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';

import { environment } from 'environments/environment';
import { SmartTableSelectModalComponent, UiFeedBackAlertModalComponent, UiFeedBackAlertModalDialogData, UiFeedBackCustonAlertModal, UiFeedBackLoaderModalComponent } from './components';
import { AlertStatus, CustonAlertButton, CustonAlertInputOptions, CustonAlertOptions, ToastStatus } from './types';

const TOASTR_STATUS_ICONS = {
  'basic': { icon: 'bulb', pack: 'eva' },
  'info': { icon: 'info', pack: 'eva' },
  'success': { icon: 'checkmark-circle', pack: 'eva' },
  'warning': { icon: 'alert-triangle', pack: 'eva' },
  'danger': { icon: 'close-circle', pack: 'eva' },
} as Record<string, { icon: string; pack?: string; }>;

@Injectable()
export class UiFeedBackService {

  private hasAlertActive = false;
  private hasCustonAlertActive = false;
  private hasLoaderActive = false;

  private active_loaderDialogRef = null;
  private selectViabackEndPromiseResolve = null;
  private active_SelectionDialogRef = null;

  private logicalPositions = NbGlobalLogicalPosition;

  constructor(
    private dialog: MatDialog,
    private toastrService: NbToastrService,
  ) { }

  private log(message?: any, ...optionalParams: any[]) {
    if (!environment.production)
      console.log(`[UiFeedBackService] ${message}`, ...optionalParams)
  }
  private logError(message?: any, ...optionalParams: any[]) {
    console.error(`[UiFeedBackService] ${message}`, ...optionalParams)
  }

  /**
   * Apresenta aleta para o usuário usando JavaScript puro
   * @param {string} title Título do alerta
   * @param {string} message Mensagem do alerta
   * @param {string} status Tipo de haptic feedback / lottie do alerta (`success`, `warning` e `danger`)
   */
  presentJsAlert(title: string, message: string, status: AlertStatus) {
    this.log('presentJsAlert: ', { title: title, message: message, status: status });

    // Função auxiliar para determinar a cor de fundo do botão
    const getBackgroundColor = (status: "success" | "warning" | "error") => {
      switch (status) {
        case 'success':
          return 'green';
        case 'warning':
          return 'orange';
        case 'error':
          return 'red';
        default:
          return 'gray';
      }
    };

    // Criação dos elementos do modal
    const alertModal = document.createElement('div');
    const modalInner = document.createElement('div');
    const alertCard = document.createElement('div');
    const alertHeader = document.createElement('div');
    const alertTitle = document.createElement('span');
    const closeButton = document.createElement('button');
    const alertBody = document.createElement('div');
    const alertMessage = document.createElement('p');
    const alertFooter = document.createElement('div');
    const okButton = document.createElement('button');

    // Configurações do modal
    alertModal.classList.add('ufb-js-modal');
    modalInner.classList.add('ufb-modal-inner');
    alertCard.classList.add('ufb-card', 'ufb-alert');
    alertHeader.classList.add('ufb-card-header');
    alertBody.classList.add('ufb-card-body');
    alertFooter.classList.add('ufb-card-footer');

    // Definir o título e mensagem
    alertTitle.textContent = title;
    alertMessage.textContent = message;
    closeButton.textContent = '×';
    okButton.textContent = 'OK';

    // Aplicar ícone e estilos baseados no status
    switch (status) {
      case 'success':
        okButton.style.backgroundColor = getBackgroundColor('success');
        okButton.textContent = 'Sucesso';
        break;
      case 'warning':
        okButton.style.backgroundColor = getBackgroundColor('warning');
        okButton.textContent = 'Aviso';
        break;
      case 'error':
        okButton.style.backgroundColor = getBackgroundColor('error');
        okButton.textContent = 'Erro';
        break;
    }

    // Estilizar título
    alertTitle.style.width = '100%';
    alertTitle.style.fontSize = '1rem';
    alertTitle.style.fontWeight = 'bold';

    // Estilizar botão de fechar
    closeButton.classList.add('close');
    closeButton.style.cursor = 'pointer';
    closeButton.style.background = 'none';
    closeButton.style.border = 'none';
    closeButton.style.float = 'right';
    closeButton.style.fontSize = '1.5rem';
    closeButton.style.fontWeight = '700';
    closeButton.style.lineHeight = '1';
    closeButton.style.color = '#000';
    closeButton.style.textShadow = '0 1px 0 #fff';
    closeButton.style.opacity = '.5';

    // Estilizar okButton
    okButton.style.fontSize = '0.875rem';
    okButton.style.fontWeight = '700';
    okButton.style.lineHeight = '1rem';
    okButton.style.borderRadius = '0.25rem';
    okButton.style.padding = '0.5rem 0.75rem';

    // Função para fechar o modal
    closeButton.onclick = () => document.body.removeChild(alertModal);
    okButton.onclick = () => document.body.removeChild(alertModal);

    // Montar o cabeçalho
    alertHeader.appendChild(alertTitle);
    alertHeader.appendChild(closeButton);

    // Montar o corpo
    alertBody.appendChild(alertMessage);

    // Montar o rodapé com botão de OK
    alertFooter.appendChild(okButton);

    // Montar o card do alerta
    alertCard.appendChild(alertHeader);
    alertCard.appendChild(alertBody);
    alertCard.appendChild(alertFooter);

    // Montar o modal
    modalInner.appendChild(alertCard);
    alertModal.appendChild(modalInner);

    // Adicionar estilos customizados ao modal
    alertModal.style.position = 'fixed';
    alertModal.style.top = '0';
    alertModal.style.left = '0';
    alertModal.style.width = '100%';
    alertModal.style.height = '100%';
    alertModal.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    alertModal.style.display = 'flex';
    alertModal.style.alignItems = 'center';
    alertModal.style.justifyContent = 'center';
    alertModal.style.zIndex = '1000';

    // Estilos do card
    alertCard.style.backgroundColor = '#fff';
    alertCard.style.borderRadius = '6px';
    alertCard.style.minWidth = '500px';
    alertCard.style.maxWidth = '40vw';
    alertCard.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.1)';

    // Estilos do Header
    alertHeader.style.display = 'flex';
    alertHeader.style.alignItems = 'center';
    alertHeader.style.minHeight = '30px';
    alertHeader.style.marginBottom = '10px';
    alertHeader.style.padding = '0 5px 0 5px';

    // Estilos do Body
    alertBody.style.display = 'block';
    alertBody.style.maxHeight = '40vh';
    alertBody.style.overflowX = 'hidden';
    alertBody.style.overflowY = 'auto';
    alertBody.style.padding = '0 10px 0 10px';

    // Estilos do Footer
    alertFooter.style.display = 'flex';
    alertFooter.style.alignItems = 'center';
    alertFooter.style.minHeight = '40px';
    alertFooter.style.marginTop = '10px';
    alertFooter.style.padding = '0 5px 0 5px';

    // Exibir o modal
    document.body.appendChild(alertModal);
  }

  /**
   * Apresenta aleta para o usuário
   * @param {string} title Título do alerta
   * @param {string} message Mensagem do alerta
   * @param {string} status Tipo de haptic feedback / lottie do alerta (`success`, `warning` e `danger`)
   * @param {boolean} lottie `opcional` Se deverá ser exibido o lottie para o tipo de alerta
   */
  public presentAlert(title: string, message: string, status: AlertStatus, lottie?: boolean): MatDialogRef<UiFeedBackAlertModalComponent, any> {
    this.log('presentAlert: ', { title: title, message: message, status: status, lottie: lottie });

    this.dismissLoader();

    if (!this.hasAlertActive) {
      const dialogRef = this.dialog.open(
        UiFeedBackAlertModalComponent,
        {
          data: {
            title: title,
            message: message,
            lottie: lottie,
            status: status
          } as UiFeedBackAlertModalDialogData,
          panelClass: 'ufb-panel',
          ariaLabel: 'ufb-aria'
        }
      );
      dialogRef.afterClosed().subscribe(() => {
        this.hasAlertActive = false;
      });
      this.hasAlertActive = true;
      return dialogRef;

    } else {
      console.log('Already showing Alert waiting dismiss current alert for show next alert...')
      setTimeout(() => {
        return this.presentAlert(title, message, status, lottie);
      }, 250);
    }
  }
  private cleanCustonAlertOpts(opts: CustonAlertOptions): CustonAlertOptions {

    //clean the father object
    let tempOpts: CustonAlertOptions = {
      title: opts.title ? opts.title : '',
      message: opts.message ? opts.message : '',
      inputs: opts.inputs ? opts.inputs : [],
      buttons: opts.buttons ? opts.buttons : [],
      closeButtonHandler: opts.closeButtonHandler ? opts.closeButtonHandler : () => { console.log(`close Button clicked`) },
    };

    //clean inputs
    for (let i = 0; i < tempOpts.inputs.length; i++) {
      if (tempOpts.inputs[i] !== "spacer")
        opts.inputs[i] = new CustonAlertInputOptions(tempOpts.inputs[i] as any);
      else
        opts.inputs[i] = tempOpts.inputs[i];
    }

    //clean btns
    for (let btn of tempOpts.buttons)
      btn = new CustonAlertButton(btn);

    return tempOpts;
  }

  /**
   * Display an alert with a title, menssage, and buttons
   * @param {AlertOptions} opts Alert. See the table below
   */
  public presentCustonAlert(opts: CustonAlertOptions): void {
    this.log('presentCustonAlert: ', opts);

    this.dismissLoader();

    if (!this.hasCustonAlertActive) {

      let tempId = 'UiFeedBackCustonAlertModal_' + new Date().getTime();
      let cleanData = this.cleanCustonAlertOpts(opts);

      const dialogRef = this.dialog.open(
        UiFeedBackCustonAlertModal,
        {
          id: tempId,
          data: cleanData as CustonAlertOptions,
          panelClass: 'ufb-panel',
          ariaLabel: 'ufb-aria'
        }
      );
      dialogRef.afterClosed()
        .subscribe(() => {
          this.hasCustonAlertActive = false;
        });
      this.hasCustonAlertActive = true;

    } else {
      this.log('Already showing Custon Alert waiting dismiss current Custon alert for show next Custon alert...')
      setTimeout(() => {
        this.presentCustonAlert(opts);
      }, 250);
    }
  }




  /**
  * Apresenta loader para o usuário
  * @param {string} message Mensagem do alerta
  */
  public presentLoader(message?: string): Promise<void> {
    return new Promise((resolve) => {
      this.log('presentLoader: ', { message: message });

      if (!this.hasLoaderActive) {
        this.active_loaderDialogRef = this.dialog.open(
          UiFeedBackLoaderModalComponent,
          {
            hasBackdrop: true,
            disableClose: true,
            autoFocus: false,
            data: {
              message: message ? message : 'Carregando...',
            },
            panelClass: 'ufb-panel'
          }
        );
        this.active_loaderDialogRef.afterClosed().subscribe(() => {
          this.hasLoaderActive = false;
        });
        this.hasLoaderActive = true;
        resolve();
      } else {
        console.log('Already showing loader waiting dismiss current loader for show next alert...')
        setTimeout(() => {
          resolve(this.presentLoader(message));
        }, 250);
      }
    });
  }

  // /**
  //  * Atualiza a mensagem do loader ativo
  //  * @param {string} newMessage Nova mensagem
  //  */
  // public updateLoaderMessage(newMessage: string) {
  //   if (this.hasLoaderActive)
  //     this.loaderMsg = newMessage;
  // }

  /**
    * Remove o loader ativo
    */
  public dismissLoader(): Promise<void> {
    return new Promise((resolve) => {
      if (this.hasLoaderActive) {
        this.active_loaderDialogRef.close();
        resolve();
      } else {
        resolve();
      }
    });
  }

  /**
   * Apresenta Toast para o usuário
   * @param {string} title
   * @param {string} message
   * @param {ToastStatus} status
   */
  public presentToast(title: string, message: string, status: ToastStatus): NbToastRef {
    this.log('presentToast: ', { title: title, message: message, status: status });

    let toastrConfig: Partial<NbToastrConfig> = {
      position: this.logicalPositions.TOP_START,
      duration: 5000,
      status: 'basic',
    };

    if (TOASTR_STATUS_ICONS[status]) {
      toastrConfig.icon = TOASTR_STATUS_ICONS[status];
      toastrConfig.icon.status = status;
    } else {
      toastrConfig.status = status;
      toastrConfig.hasIcon = false;
    }

    return this.toastrService.show(message, title, toastrConfig);
  }

  /**
 * Apresenta Toast Persistente para o usuário
 * @param {string} title
 * @param {string} message
 * @param {ToastStatus} status
 */
  public presentPersistentToast(title: string, message: string, status: ToastStatus): NbToastRef {

    let toastrConfig: Partial<NbToastrConfig> = {
      position: this.logicalPositions.TOP_START,
      destroyByClick: true,
      status: 'basic',
    };

    if (TOASTR_STATUS_ICONS[status]) {
      toastrConfig.icon = TOASTR_STATUS_ICONS[status];
      toastrConfig.icon.status = status;
    } else {
      toastrConfig.status = status;
      toastrConfig.hasIcon = false;
    }

    return this.toastrService.show(message, title, toastrConfig);
  }

  public presentOfflineAlert(): void {
    this.presentToast(`Voce esta offline!`, `Tente novamente mais tarde!`, 'danger');
  }

  /**
 * Apresenta aleta de erro para o usuário
 * @param {string} title Título do erro
 * @param {string} error Erro
 */
  public presentErrorAlert(pageId: string, className: string, uName: string, title: string, error: any): void {
    this.logError(`ERROR: ${title}`);
    this.logError(JSON.stringify(error, undefined, 2));
    this.logError('presentErrorAlert:', { title: title, error: error })

    let errorMsg: string = error.message;
    var isInternetError = false;

    if (error['code'] === 'unavailable') {
      isInternetError = true;
    }

    if (errorMsg != undefined && !isInternetError) {
      const tempVet = errorMsg.split(' ');
      for (let word of tempVet) {
        let tempWord = word.toLowerCase().replace(/[^a-zA-Z0-9_-]/g, '');
        if (tempWord == 'offline') {
          isInternetError = true;
          break;
        }
      }
    }

    if (isInternetError) {
      this.presentOfflineAlert();
    } else {
      this.presentAlert(`${title}`, error.message, 'error');

      // todo Salvar log de erro automatico
      // Log
      // let tempLog = new Log();

      // tempLog.id = `ER-${this.db.afs.createId()}`;
      // tempLog.pageId = pageId;
      // tempLog.className = className;
      // tempLog.uName = uName;

      // tempLog.type = "SYS";
      // tempLog.category = "ER";

      // tempLog.description =
      //   `Title: ${title}
      //   Error JSON: ${JSON.stringify(error, undefined, 2)}`;

      // this.db.sys.logs.create(tempLog.id, tempLog)
      // Log
    }
  }






  // Smart Table Select
  private onSelection(data) {
    if (this.selectViabackEndPromiseResolve) {
      this.selectViabackEndPromiseResolve(data)
    }
  }

  /**
   * Apresenta table de seleção para o usuário
   * @param {any} columns columns
   * @param {LocalDataSource} source source
   * @param {string} lastSelected_id lastSelected_id
   * @param {string} lastSelected_Field lastSelected_Field
   */
  showTableSelect(columns: any, source: LocalDataSource, lastSelected_id: string = '', lastSelected_Field: string = 'id'): Promise<any> {
    return new Promise((resolve, reject) => {
      this.log('showTableSelect: ', { columns: columns, source: source, lastSelected_id: lastSelected_id, lastSelected_Field: lastSelected_Field });

      if (this.selectViabackEndPromiseResolve == null) {
        this.selectViabackEndPromiseResolve = resolve;

        this.active_SelectionDialogRef = this.dialog.open(
          SmartTableSelectModalComponent,
          {
            id: 'SelectionDialogRef_' + new Date().getTime(),
            data: {
              columns: columns,
              source: source,
              lastSelected_id: lastSelected_id,
              lastSelected_Field: lastSelected_Field
            },
            panelClass: 'ufb-panel'
          }
        );

        this.active_SelectionDialogRef.afterClosed()
          .subscribe(data => {
            this.onSelection(data);
            this.selectViabackEndPromiseResolve = null;
          });


      } else {
        reject({ message: 'Já Existe uma seleção em andamento!' })
      }
    })
  }

  public dialog_opener<T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>): MatDialogRef<T, R> {
    config.panelClass = 'ufb-modal';
    this.log('dialog_opener: ', { componentOrTemplateRef: componentOrTemplateRef, config: config });
    return this.dialog.open(componentOrTemplateRef, config);
  }

}
