import { getLocaleFirstDayOfWeek } from "@angular/common";
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, LOCALE_ID, OnChanges, OnInit, Output, PLATFORM_ID, SimpleChange, ViewChild } from '@angular/core';

export class WeekForSelection {
  year: number;
  number: number;
  month: number;
  weekStartDate: Date;
  weekEndDate: Date;
  days: { day: number; month: number; dayOfTheWeek: number }[];
  public constructor(init?: Partial<WeekForSelection>) {
    this.year = 0;
    this.number = 0;
    this.month = 0;
    this.weekStartDate = new Date();
    this.weekEndDate = new Date();
    this.days = [];
    if (init)
      Object.assign(this, init);
  }
}


@Component({
  selector: 'week-input',
  templateUrl: './week-input.component.html',
  styleUrls: ['./week-input.component.scss']

})
export class WeekInputComponent {

  @ViewChild('popdownContent', { read: ElementRef, static: false }) popdownContent: ElementRef;

  @Input() disabled = false;
  touched = false;

  showSelect = false;

  @Input() year: number = 0;
  yearsWeeks: WeekForSelection[] = [];
  @Input() month: number = 0;
  @Input() week: number = 0;
  @Input() weekStartDate: Date = null;
  @Input() weekEndDate: Date = null;

  @Output() yearChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() monthChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() weekChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() weekStartDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() weekEndDateChange: EventEmitter<Date> = new EventEmitter<Date>();

  internalWeeksCacheByYear: Record<number, WeekForSelection[]> = {};

  today = new Date();
  todayObj = {
    day: this.getDate_day(this.today),
    month: this.getDate_month(this.today),
    year: this.getDate_year(this.today)
  };
  months = [
    { id: 1, name: 'Janeiro' },
    { id: 2, name: 'Fevereiro' },
    { id: 3, name: 'Março' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Maio' },
    { id: 6, name: 'Junho' },
    { id: 7, name: 'Julho' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Setembro' },
    { id: 10, name: 'Outubro' },
    { id: 11, name: 'Novembro' },
    { id: 12, name: 'Dezembro' },
  ];
  weekDays = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];

  weeksForSelection: WeekForSelection[] = [];

  constructor(
    @Inject(LOCALE_ID) public locale,
    @Inject(PLATFORM_ID) public platformId
  ) {
    let date = new Date();
    this.year = this.getDate_year(date);
    this.month = this.getDate_month(date);
    this.week = this.getWeekNumber(date);

    if (this.yearsWeeks.length == 0)
      this.updateYearsWeeks();
  }
  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.popdownContent && !this.popdownContent.nativeElement.contains(event.target)) {
      this.closeSelect();
    }
  }
  private getFirstDayOfWeek() {
    return getLocaleFirstDayOfWeek(this.locale);
  }
  private getDate_year(date: Date): number {
    return Number(date.toISOString().split('T')[0].split('-')[0]);
  }
  private getDate_month(date: Date): number {
    return Number(date.toISOString().split('T')[0].split('-')[1]);
  }
  private getDate_day(date: Date): number {
    return Number(date.toISOString().split('T')[0].split('-')[2]);
  }
  private numberPadding(number: number, size: number): string {
    let s = number.toString();
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }
  /**
   * Get week number in the year.
   * @param  {Date} date
   * @param  {Integer} [weekStart=0]  First day of the week. 0-based. 0 for Sunday, 6 for Saturday.
   * @return {Integer}                1-based number of week.
   */
  private getWeekNumber(date: Date): number {
    let tempWeekNumber = -1;

    const dateYear = this.getDate_year(date);
    const dateMonth = this.getDate_month(date);
    const dateDayNumber = this.getDate_day(date);

    // Definir anos relevantes (ano anterior, atual e próximo)
    const possibleYears = [dateYear - 1, dateYear, dateYear + 1];
    const possibleYearWeeks: WeekForSelection[] = [];
    possibleYears.forEach((year) => {
      possibleYearWeeks.push(...this.generateYearsWeeks(year, 0));
    });

    // Definir meses relevantes (anterior, atual e próximo)
    const previousMonth = dateMonth === 1 ? 12 : dateMonth - 1;
    const nextMonth = dateMonth === 12 ? 1 : dateMonth + 1;
    const previousMonthYear = dateMonth === 1 ? dateYear - 1 : dateYear;
    const nextMonthYear = dateMonth === 12 ? dateYear + 1 : dateYear;

    // Filtrar semanas dos meses relevante (independente do ano)
    const possibleMonthsWeeks = possibleYearWeeks.filter((w) => {
      return (
        (w.month === previousMonth && w.year === previousMonthYear) ||
        (w.month === dateMonth && w.year === dateYear) ||
        (w.month === nextMonth && w.year === nextMonthYear)
      );
    });

    // Encontrar a semana que contém o dia desejado
    for (let week of possibleMonthsWeeks) {
      for (let day of week.days) {
        if (day.month == dateMonth && day.day == dateDayNumber) {
          tempWeekNumber = week.number;
          break;
        }
      }
      if (tempWeekNumber != -1)
        break
    }

    return tempWeekNumber;
  }
  /**
 * Generates the weeks of a given year, starting from a specified day of the week.
 *
 * @param year - The year for which the weeks should be generated. Must be greater than 1500.
 * @param startOfWeek - The starting day of the week (0 for Sunday, 1 for Monday, etc.). Defaults to 1 (Monday).
 * @returns An array of `InternalWeek` objects representing the weeks of the specified year.
 * 
 * @throws Will throw an error if the provided year is less than 1501.
 */
  private generateYearsWeeks(year: number, startOfWeek: number = 1): WeekForSelection[] {
    if (year < 1501)
      throw new Error("Wrong argument. Year must be greater than 1500.");

    if (this.internalWeeksCacheByYear[year])
      return this.internalWeeksCacheByYear[year];

    const daysInMillis = 86400000;
    const januaryFirst = new Date(year, 0, 1);

    // Calcula o deslocamento para o primeiro dia da semana com base no startOfWeek
    const dayShift = (januaryFirst.getDay() - startOfWeek + 7) % 7;
    let firstDayOfFirstWeek = new Date(januaryFirst.getTime() - dayShift * daysInMillis);

    // Ajusta o dia de corte (quinta-feira por padrão)
    const cutDay = (startOfWeek + 3) % 7; // A "quinta-feira" relativa ao startOfWeek
    let firstCutDay = new Date(firstDayOfFirstWeek.getTime() + cutDay * daysInMillis);

    // Checa se a semana que começa no firstDayOfFirstWeek é do ano anterior se for ajusta o firstDayOfFirstWeek
    if (this.getDate_year(firstCutDay) < year)
      firstDayOfFirstWeek = new Date(firstDayOfFirstWeek.getTime() + 7 * daysInMillis);

    let lastWeekYear = this.getDate_year(firstDayOfFirstWeek);
    const tempYearsWeeks: WeekForSelection[] = [];

    while (lastWeekYear <= year) {
      const tempWeek = new WeekForSelection();

      for (let count = 0; count <= 6; count++) {
        const lastDayOfLastWeek = tempYearsWeeks.length == 0
          ? new Date(firstDayOfFirstWeek.getTime() - daysInMillis)
          : tempYearsWeeks[tempYearsWeeks.length - 1].weekEndDate;

        const firstDayOfWeek = new Date(lastDayOfLastWeek.getTime() + daysInMillis);
        const tempDay = new Date(firstDayOfWeek.getTime() + count * daysInMillis);
        const tempDayOfTheWeek = tempDay.getDay();

        // determina o mes da semana
        if (tempDayOfTheWeek == cutDay) {
          tempWeek.number = tempYearsWeeks.length + 1;
          tempWeek.year = this.getDate_year(tempDay);
          tempWeek.month = this.getDate_month(tempDay);
        }

        tempWeek.days.push({
          day: this.getDate_day(tempDay),
          month: this.getDate_month(tempDay),
          dayOfTheWeek: tempDayOfTheWeek,
        });


        if (count == 0)
          tempWeek.weekStartDate = tempDay;
        if (count == 6)
          tempWeek.weekEndDate = tempDay;
      }

      lastWeekYear = this.getDate_year(tempWeek.weekEndDate);

      // Adiciona a semana somente se estiver dentro do ano ou até uma semana do próximo ano
      if (tempWeek.year == year)
        tempYearsWeeks.push(tempWeek);
    }

    this.internalWeeksCacheByYear[year] = tempYearsWeeks;
    return tempYearsWeeks;
  };
  private updateYearsWeeks() {
    if (this.year > 1501)
      this.yearsWeeks = this.generateYearsWeeks(this.year, 0);
  };
  private getWeeksForMonth(yearsWeeks: WeekForSelection[], month: number, year: number): WeekForSelection[] {
    return yearsWeeks.filter((w) => w.month == month && w.year == year);
  }
  private getWeeksForShow(month: number, year: number): WeekForSelection[] {
    if (month < 1 || month > 12)
      throw new Error('Wrong argument. Month must be an integer between 1 and 12.');

    let tempWeeks = this.getWeeksForMonth(this.yearsWeeks, month, year);

    // Past Month
    let pastMonth = (month - 1) > 0 ? month - 1 : 12;
    let lastWeek_pastMonth = new WeekForSelection();
    let tempWeeks_pastMonth = [];

    if (pastMonth == 12) {
      tempWeeks_pastMonth = this.getWeeksForMonth(this.generateYearsWeeks(year - 1, 0), pastMonth, year - 1);
    } else
      tempWeeks_pastMonth = this.getWeeksForMonth(this.yearsWeeks, pastMonth, year);

    if (tempWeeks_pastMonth.length > 0)
      lastWeek_pastMonth = tempWeeks_pastMonth[tempWeeks_pastMonth.length - 1]

    // Next Month
    let nextMonth = (month + 1) < 13 ? month + 1 : 1;
    let firstWeek_nextMonth = new WeekForSelection();
    let tempWeeks_nextMonth = [];

    if (nextMonth == 1) {
      tempWeeks_nextMonth = this.getWeeksForMonth(this.generateYearsWeeks(year + 1), nextMonth, year + 1);
    } else
      tempWeeks_nextMonth = this.getWeeksForMonth(this.yearsWeeks, nextMonth, year);

    if (tempWeeks_nextMonth.length > 0)
      firstWeek_nextMonth = tempWeeks_nextMonth[0]

    tempWeeks = [
      lastWeek_pastMonth,
      ...tempWeeks,
      firstWeek_nextMonth
    ];
    return tempWeeks;
  }
  isToday(day: number, month: number, year: number): boolean {
    return this.todayObj.day === day && this.todayObj.month === month && this.todayObj.year === year;
  }
  showWeek(): string {
    if (this.week > 0)
      return this.numberPadding(this.week, 2)
    else
      return `--`
  }
  showYear(): string {
    if (this.year > 0)
      return this.numberPadding(this.year, 4)
    else
      return `----`
  }
  trigger() {
    if (!this.disabled && !this.showSelect) {
      this.showSelect = true;
      this.openSelect();
    }
  }
  closeSelect() {
    this.showSelect = false;
  }
  openSelect() {
    if (this.yearsWeeks.length == 0)
      this.updateYearsWeeks();
    this.weeksForSelection = [];
    this.weeksForSelection = this.getWeeksForShow(this.month, this.year);
    this.showSelect = true;
  }
  useActualWeek() {
    let actualDate = new Date();

    this.year = this.getDate_year(actualDate);
    this.updateYearsWeeks();

    let actualWeekNo = this.getWeekNumber(actualDate);
    let actualWeek = this.yearsWeeks.find((w) => w.year == this.year && w.number == actualWeekNo);

    this.month = actualWeek.month;
    this.week = actualWeek.number;
    this.weekStartDate = actualWeek.weekStartDate;
    this.weekEndDate = actualWeek.weekEndDate;

    this.yearChange.emit(this.year);
    this.monthChange.emit(this.month);
    this.weekStartDateChange.emit(this.weekStartDate);
    this.weekEndDateChange.emit(this.weekEndDate);

    this.weekChange.emit(this.week);
  }


  // popdown
  popdown_useActualWeek() {
    this.useActualWeek();
    this.showSelect = false;
  }
  popdown_updateMonth() {
    this.weeksForSelection = [];
    this.weeksForSelection = this.getWeeksForShow(this.month, this.year);
  }
  popdown_updateYear() {
    this.updateYearsWeeks();
    this.popdown_updateMonth();
  }
  popdown_monthBack() {
    if (this.month - 1 >= 1) {
      this.month--;
      this.popdown_updateMonth();
    } else {
      this.month = 12;
      this.year--;
      this.popdown_updateYear();
    }
  }
  popdown_monthAdvance() {
    if (this.month + 1 <= 12) {
      this.month++;
      this.popdown_updateMonth();
    } else {
      this.month = 1;
      this.year++;
      this.popdown_updateYear();
    }
  }
  popdown_select(week4Sel: WeekForSelection) {

    if (week4Sel.year != this.year) {
      this.year = week4Sel.year;
      this.updateYearsWeeks();
    }

    this.year = week4Sel.year;
    this.month = week4Sel.month;
    this.week = week4Sel.number;
    this.weekStartDate = week4Sel.weekStartDate;
    this.weekEndDate = week4Sel.weekEndDate;

    this.yearChange.emit(this.year);
    this.monthChange.emit(this.month);
    this.weekStartDateChange.emit(this.weekStartDate);
    this.weekEndDateChange.emit(this.weekEndDate);

    this.weekChange.emit(this.week);

    this.showSelect = false;
  }
  // popdown


}
