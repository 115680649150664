import firebase from "firebase/compat/app";

export class WF_GroupAsc {

  id: string;
  groupId: string;

  uName: string;
  uNameAlt: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<WF_GroupAsc>) {

    this.id = '';
    this.groupId = '';

    this.uName = '';
    this.uNameAlt = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;


    if (init)
      Object.assign(this, init);
  }
}
