import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';

export class ColorSelectData {
  id: string;
  name: string;
  hex: string;
  public constructor(init?: Partial<ColorSelectData>) {
    this.id = '';
    this.name = '';
    this.hex = '';
    if (init)
      Object.assign(this, init);
  }
}

export const STANDARD_COLORS: ColorSelectData[] = [
  {
    id: 'c1',
    name: 'Lochmara',
    hex: '#0B83D9'
  },
  {
    id: 'c2',
    name: 'Christi',
    hex: '#2DA608'
  },
  {
    id: 'c3',
    name: 'Saara',
    hex: '#C7AF14'
  },
  {
    id: 'c4',
    name: 'Roxo Médio',
    hex: '#9E5BD9'
  },
  {
    id: 'c5',
    name: 'Niágara',
    hex: '#06A893'
  },
  {
    id: 'c6',
    name: 'Saratoga',
    hex: '#596614'
  },
  {
    id: 'c7',
    name: 'Cabaré',
    hex: '#D94182'
  },
  {
    id: 'c8',
    name: 'Bronze antigo',
    hex: '#C9806B'
  },
  {
    id: 'c9',
    name: 'Soco',
    hex: '#D92B2B'
  },
  {
    id: 'c10',
    name: 'Clementine',
    hex: '#E36A00'
  },
  {
    id: 'c11',
    name: 'San Marino',
    hex: '#465BB3'
  },
  {
    id: 'c12',
    name: 'Scarpa Flow',
    hex: '#525266'
  },
  {
    id: 'c13',
    name: 'Indochina',
    hex: '#BF7000'
  },
  {
    id: 'c14',
    name: 'Flerte',
    hex: '#990099'
  },
  {
    id: 'c15',
    name: 'Sangria',
    hex: '#991102'
  },
];

@Component({
  selector: 'color-select',
  templateUrl: './color-select.component.html',
  styleUrls: ['./color-select.component.scss']

})
export class ColorSelectComponent implements OnInit {

  @ViewChild('popdownContent', { read: ElementRef, static: false }) popdownContent: ElementRef;

  @Input() required = false;
  @Input() disabled = false;
  @Input() showClean = false;

  @Input() colors: ColorSelectData[] = STANDARD_COLORS;
  showSelect = false;

  @Input() color: ColorSelectData = new ColorSelectData();
  @Output() colorChange = new EventEmitter();

  @Input() hex = '';
  @Output() hexChange = new EventEmitter();

  constructor() { }
  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.popdownContent && !this.popdownContent.nativeElement.contains(event.target)) {
      this.trigger();
    }
  }

  ngOnInit() { }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName == 'hex') {
        const c = this.colors.filter((c) => c.hex == this.hex).pop();
        if (c)
          this.color = c;
      }
    }
  }
  trigger() {
    if (!this.disabled)
      this.showSelect = !this.showSelect;
  }
  select(c: ColorSelectData) {
    this.showSelect = false;
    this.color = c;
    this.hex = c.hex;
    this.colorChange.emit(this.color);
    this.hexChange.emit(this.hex);
  }
  cleanColor() {
    this.select(new ColorSelectData())
  }

}
