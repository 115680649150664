<div class="data-visualization-editor">
  <nb-card [nbSpinner]="loader">
    <nb-card-header>
      Data Visualization Editor
    </nb-card-header>
    <nb-card-body>

      <nb-tabset>

        <!-- S O U R C E  -->
        <nb-tab tabTitle="Source" class="container data-container">
          <div class="row no-gutters">
            <!-- M A I N  -->
            <div class="col-9" style="padding-right: 10px;overflow-x: scroll">
              <div class="row no-gutters">
                <div class="row no-gutters col-5">
                  <label class="col-12">Data Source</label>
                  <div class="col-10">
                    <h6 class="ic-form-control">{{_tabSource_selectedDataSource.id}}</h6>
                  </div>
                  <div class="col-2">
                    <button [disabled]="!edit" nbButton status="primary" size="small" (click)="_tabSource_selectDS()">
                      <nb-icon icon="list-outline" pack="eva"></nb-icon>
                    </button>
                  </div>
                </div>

                <div class="col-4">
                  <label>Name / Type</label>
                  <h6 class="ic-form-control">{{_tabSource_selectedDataSource.name}} /
                    {{_tabSource_selectedDataSource.type}}
                  </h6>
                </div>

                <div class="col-2">
                  <label>Limit</label>
                  <input [disabled]="!edit" class="ic-form-control" type="number" id="input_max_n_of_hits"
                    name="input_max_n_of_hits" [max]="10000" [(ngModel)]="_tabSource_input_max_n_of_hits"
                    (ngModelChange)="_tabSource_change()">
                  <p style="color:red;font-size: 0.8rem;margin: unset;">0 for no limit</p>
                </div>

                <div class="col-1">
                  <button style="margin-top: 25px;"
                    [disabled]="!edit||_tabSource_selectedDataSource.id==''||DbRulesUiERef.hasError" nbButton
                    status="primary" size="small" (click)="_tabSource_run()">
                    <nb-icon icon="flash-outline" pack="eva"></nb-icon>
                    Run
                  </button>
                </div>
              </div>

              <db-rules-ui #DbRulesUiERef [edit]="edit" (onChange)="_dbRulesChange()"></db-rules-ui>

              <hr>

              <magic-table [id]="'data-visualization-editor_'+_tabSource_selectedDataSource.id"
                [title]="_tabSource_selectedDataSource.name" [showHeader]="false" [loader]="loader"
                [data]="_tabSource_table_data" [columns]="_tabSource_table_columns">
              </magic-table>
            </div>
            <!-- M A I N  -->


            <!-- A U X -->
            <div class="col-3">
              <editor-fields #EditorFieldsERef [edit]="edit" (onChange)="_fieldsChange()"></editor-fields>
            </div>
            <!-- A U X -->
          </div>
        </nb-tab>
        <!-- S O U R C E  -->

        <!-- Visualization -->
        <nb-tab tabTitle="Visualization" class="container data-container">
          <div class="row no-gutters">
            <!-- M A I N  -->
            <div class="col-9" style="padding-right: 10px">
              <data-visualization #DataVisualizationERef [id]="id" [title]="title" [vis]="vis"
                (onEngineReady)="_dataVisEnigneReady($event)">
              </data-visualization>
            </div>
            <!-- M A I N  -->

            <!-- A U X -->
            <div class="col-3">
              <nb-tabset>
                <nb-tab tabTitle="Config">
                  <editor-engine-config #EditorEngineConfigERef [edit]="edit" (onChange)="_engineConfigChange()">
                  </editor-engine-config>
                </nb-tab>

                <nb-tab tabTitle="Exibir">
                  <editor-engine-display #EditorEngineDisplayERef [edit]="edit" (onChange)="_engineDisplayChange()">
                  </editor-engine-display>
                </nb-tab>
              </nb-tabset>
            </div>
            <!-- A U X -->
          </div>
        </nb-tab>
        <!-- Visualization -->
      </nb-tabset>

    </nb-card-body>
  </nb-card>
</div>