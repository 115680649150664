import firebase from "firebase/compat/app";

export const STANDARD_PM_BOARD_ROUTES_COLOR = "#4a86e8";

export class PM_BoardRoute {

    id: string;
    boardId: string;
    parentKey: string;

    name: string;
    hex: string;
    hide: boolean;
    final: boolean;
    allowCreatingCards: boolean;
    limit: number;
    cards: string[];

    createdOn: firebase.firestore.Timestamp;
    updatedOn: firebase.firestore.Timestamp;
    createdBy: string;
    updatedBy: string;

    public constructor(init?: Partial<PM_BoardRoute>) {

        this.id = '';
        this.boardId = '';
        this.parentKey = '';

        this.name = '';
        this.hex = STANDARD_PM_BOARD_ROUTES_COLOR;
        this.hide = false;
        this.final = false;
        this.allowCreatingCards = false;
        this.limit = 0;
        this.cards = [];

        this.createdOn = firebase.firestore.Timestamp.now();
        this.updatedOn = null;
        this.createdBy = '';
        this.updatedBy = null;

        if (init)
            Object.assign(this, init);
    }
}