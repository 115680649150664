import firebase from "firebase/compat/app";

export class DB_DataSource {

  id: string;
  name: string;
  type: 'table' | 'view';

  fieldsIds: string[];

  tablesIds: string[];
  joinConditions: JoinCondition[];
  viewFields: ViewField[];

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<DB_DataSource>) {

    this.id = '';
    this.name = '';
    this.type = 'table';

    this.fieldsIds = [];

    this.tablesIds = [];
    this.joinConditions = [];
    this.viewFields = [];

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}

export class JoinCondition {
  tableA_id: string;
  tableA_fieldId: string;
  tableB_id: string;
  tableB_fieldId: string;
  public constructor(init?: Partial<JoinCondition>) {
    this.tableA_id = '';
    this.tableA_fieldId = '';
    this.tableB_id = '';
    this.tableB_fieldId = '';
    if (init)
      Object.assign(this, init);
  }
}
export class ViewField {
  tableId: string;
  fieldId: string;
  customName: string;
  public constructor(init?: Partial<ViewField>) {
    this.tableId = '';
    this.fieldId = '';
    this.customName = '';
    if (init)
      Object.assign(this, init);
  }
}

