import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { fromEventPattern, Subject, takeUntil } from 'rxjs';

export interface KeyBind {
    keyCode: string;
    altKey?: boolean;
    shiftKey?: boolean;
    function: () => void;
}
interface InternalKeyBind extends KeyBind {
    className: string;
}

@Injectable({
    providedIn: 'root'
})
export class KeybindsService {
    private _destroy$ = new Subject<void>();
    private renderer: Renderer2;

    private keyBinds: InternalKeyBind[] = [];
    private keysPressed: string[] = [];

    constructor(private rendererFactory2: RendererFactory2) {
        this.renderer = this.rendererFactory2.createRenderer(null, null);
        this.createKeyEventsObservables();
    }
    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private createKeyEventsObservables() {
        let removeKeyDownEventListener: () => void;
        let removeKeyUpEventListener: () => void;

        const createKeyDownEventListener = (handler: (e: KeyboardEvent) => boolean | void) => {
            removeKeyDownEventListener = this.renderer.listen('document', 'keydown', handler);
        };

        const createKeyUpEventListener = (handler: (e: KeyboardEvent) => boolean | void) => {
            removeKeyUpEventListener = this.renderer.listen('document', 'keyup', handler);
        };

        fromEventPattern<KeyboardEvent>(createKeyDownEventListener, () => removeKeyDownEventListener())
            .pipe(takeUntil(this._destroy$))
            .subscribe(event => this.handleKeyDown(event));

        fromEventPattern<KeyboardEvent>(createKeyUpEventListener, () => removeKeyUpEventListener())
            .pipe(takeUntil(this._destroy$))
            .subscribe(event => this.handleKeyUp(event));
    }
    private handleKeyDown(event: KeyboardEvent) {
        this.keyBinds.forEach((keyBind) => {
            if (
                event.code === keyBind.keyCode &&
                (keyBind.altKey === undefined || event.altKey === keyBind.altKey) &&
                (keyBind.shiftKey === undefined || event.shiftKey === keyBind.shiftKey) &&
                !this.keysPressed.includes(event.code)
            ) {
                this.keysPressed.push(event.code);
                keyBind.function();
            }
        });
    }
    private handleKeyUp(event: KeyboardEvent) {
        this.keysPressed = this.keysPressed.filter((key) => key !== event.code);
    }

    public add(className: string, keyBind: KeyBind) {
        this.keyBinds.push({ ...keyBind, className });
    }
    public remove(className: string) {
        this.keyBinds = this.keyBinds.filter((keyBind) => keyBind.className !== className);
    }
}