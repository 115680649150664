<div class="db-table-container">

  <div *ngIf="loader" class="db-table-loader">
    <p><b>Carregando...</b></p>
  </div>

  <!-- db-table -->
  <div class="db-table">

    <!-- db-table-thead -->
    <div class="db-table-thead">
      <!-- Nº -->
      <div class="db-table-th">
        <div class="inner" style="width: 30px;">
          <div class="wrapper">
            <span class="col-title">
              Nº
            </span>
          </div>
        </div>
      </div>
      <!-- Nº -->

      <!-- custom cols -->
      <div class="db-table-th" *ngFor="let col of table.cols; trackBy: _trackByFn; let col_i=index">

        <div class="inner" [style.width]="col.width">
          <div class="wrapper">
            <span class="col-title">
              {{col.title}}
            </span>

            <!-- filter -->
            <button class="col-btn" (click)="_openHeaderPopDown(col_i)">
              <nb-icon *ngIf="!filterActiveIndex[col_i]" icon="chevron-down-outline" pack="eva"></nb-icon>
              <nb-icon *ngIf="filterActiveIndex[col_i]" style="color: blue;" icon="funnel-outline" pack="eva"></nb-icon>
            </button>

            <!-- Header PopDown -->
            <div class="popDown" *ngIf="showHeaderPopDown==col_i">
              <div class="popDownContent" #HeaderPopDownContentERef>

                <button class="list-button" (click)="_sort(col_i,'asc')">
                  <span class="ltext">
                    <span style="color: blue;">A</span>
                    <span>Z</span>
                  </span>
                  <nb-icon icon="arrow-downward-outline" pack="eva"></nb-icon>
                  Classificar de A a Z
                </button>
                <button class="list-button" (click)="_sort(col_i,'desc')">
                  <span class="ltext">
                    <span>Z</span>
                    <span style="color: blue;">A</span>
                    <nb-icon icon="arrow-downward-outline" pack="eva"></nb-icon>
                  </span>
                  Classificar de Z a A
                </button>
                <hr>
                <button [disabled]="!filterActiveIndex[col_i]" class="list-button" (click)="_cleanFilter(col_i)">
                  <nb-icon style="color: red;" icon="funnel-outline" pack="eva"></nb-icon>
                  Limpar Filtro
                </button>

                <!-- SearchInput -->
                <input #HeaderPopDownSearchInputERef class="list-input" type="text"
                  [id]="'headerPopDownSearchInput_'+col_i" [name]="'headerPopDownSearchInput_'+col_i"
                  aria-describedby="search-text" placeholder="Pesquisar" autocomplete="nope" [(ngModel)]="searchText"
                  autofocus (ngModelChange)="_updateSearchResult(col_i)" (keyup.enter)="_filter(col_i)">
                <!-- SearchInput -->

                <div class="search-list-group search-list-group-flush">

                  <!-- (Selecionar Tudo) -->
                  <div class="search-list-group-item" *ngIf="searchText.length==0">
                    <input [disabled]="filterIndex4Sel.length==0" (click)="_toogleAll()" type="checkbox"
                      id="{{'input_unselectAll-'+id}}" name="{{'input_unselectAll-'+id}}" [(ngModel)]="filterSelectAll">
                    <label for="{{'input_unselectAll-'+id}}">
                      (Selecionar Tudo)
                    </label>
                  </div>
                  <!-- (Selecionar Tudo) -->

                  <!-- (Selecionar Todos os Resultados da Pesquisa) -->
                  <div class="search-list-group-item" *ngIf="searchText.length>0 && filterIndex4Sel.length>0">
                    <input (click)="_toogleAllSearchResult()" type="checkbox"
                      id="{{'input_filterSelectAllSearchResult-'+id}}"
                      name="{{'input_filterSelectAllSearchResult-'+id}}" [(ngModel)]="filterSelectAllSearchResult">
                    <label for="{{'input_filterSelectAllSearchResult-'+id}}">
                      (Selecionar Todos os Resultados da Pesquisa)
                    </label>
                  </div>
                  <!-- (Selecionar Todos os Resultados da Pesquisa) -->

                  <!-- (Adicionar seleção atual ao filtro) -->
                  <div class="search-list-group-item" *ngIf="searchText.length>0 && filterIndex4Sel.length>0">
                    <input type="checkbox" id="{{'input_addCurSelTofilter-'+id}}"
                      name="{{'input_addCurSelTofilter-'+id}}" [(ngModel)]="filterAddCurSelTofilter">
                    <label for="{{'input_addCurSelTofilter-'+id}}">
                      (Adicionar seleção atual ao filtro)
                    </label>
                  </div>
                  <!-- (Adicionar seleção atual ao filtro) -->

                  <div class="search-list-group-item" *ngFor="let c of filterIndex4Sel; let input_filter_check_i=index">
                    <input type="checkbox" [id]="'input_filter_check_'+input_filter_check_i"
                      [name]="'input_filter_check_'+input_filter_check_i" [(ngModel)]="c.show"
                      (ngModelChange)="_filterIndex4SelShowChange(col_i)">
                    <label [for]="'input_filter_check_'+input_filter_check_i" dbTableHighlight
                      [searchedWord]="searchText" [content]="c.value" [classToApply]="'highlight'"
                      [setTitle]="true">{{c.value}}</label>
                  </div>
                </div>

                <button [disabled]="filterIndex4Sel.length==0" class="list-button" (click)="_filter(col_i)">
                  Filtrar
                </button>

              </div>
            </div>
            <!-- Header PopDown -->
            <!-- filter -->

          </div>
        </div>

      </div>
      <!-- custom cols -->

      <!-- options -->
      <div class="db-table-th" *ngIf="edit">
        <div class="inner" style="width: 50px;">
          <div class="wrapper">
            <button [disabled]="!canCreate" nbButton status="primary" size="small" (click)="_addLine()">
              <nb-icon icon="file-add-outline" pack="eva"></nb-icon>
            </button>
          </div>
        </div>
      </div>
      <!-- options -->
    </div>
    <!-- db-table-thead -->

    <!-- db-table-tbody -->
    <form class="db-table-tr" class="{{id+'_rowForm_'+row.number}}" id="{{id+'_rowForm_'+row.number}}"
      name="{{id+'_rowForm_'+row.number}}" #rowForm="ngForm"
      *ngFor="let row of rowsForDisplay; trackBy: _trackByFn; let rows_i=index">

      <!-- Nº -->
      <div class="db-table-td">
        <div class="inner" style="width: 30px;">
          <div class="wrapper">
            <div class="line-num-container">
              <nb-icon *ngIf="row.hasError" icon="alert-triangle-outline" pack="eva" style="color: red;"></nb-icon>
              <p>{{row.number}}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Nº -->

      <!-- custom table data -->
      <div class="db-table-td" *ngFor="let cel of row.cells; trackBy: _trackByFn; let cells_i=index">
        <div class="inner" [style.width]="table.cols[cells_i].width">
          <div class="wrapper">
            <!-- Old row -->
            <div *ngIf="row.status== 'old' || row.status== 'changed'">

              <!-- input data -->
              <div *ngIf="table.cols[cells_i].input">

                <!-- changeable cells -->
                <div *ngIf="table.cols[cells_i].canChange">
                  <!-- text -->
                  <input form="rowForm" class="ic-form-control" *ngIf="table.cols[cells_i].input.type == 'text'"
                    id="{{id+'_input_L'+row.number+'C'+cells_i}}" name="{{id+'_input_L'+row.number+'C'+cells_i}}"
                    type="text" minlength="{{table.cols[cells_i].input.minlength}}"
                    maxlength="{{table.cols[cells_i].input.maxlength}}"
                    [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i],row,aditionalData)"
                    [pattern]="table.cols[cells_i].input.pattern"
                    [required]="table.cols[cells_i].input.required(row.cellsData[cells_i],row,aditionalData)"
                    [(ngModel)]="row.cellsData[cells_i]" (ngModelChange)="_rowChange(rows_i, cells_i, rowForm.valid)"
                    autofocus>
                  <!-- text -->

                  <!-- number -->
                  <input form="rowForm" class="ic-form-control" *ngIf="table.cols[cells_i].input.type == 'number'"
                    id="{{id+'_input_L'+row.number+'C'+cells_i}}" name="{{id+'_input_L'+row.number+'C'+cells_i}}"
                    type="number" min="{{table.cols[cells_i].input.min}}" max="{{table.cols[cells_i].input.max}}"
                    [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i],row,aditionalData)"
                    [pattern]="table.cols[cells_i].input.pattern"
                    [required]="table.cols[cells_i].input.required(row.cellsData[cells_i],row,aditionalData)"
                    [(ngModel)]="row.cellsData[cells_i]" (ngModelChange)="_rowChange(rows_i, cells_i, rowForm.valid)"
                    autofocus>
                  <!-- number -->

                  <!-- date -->
                  <input form="rowForm" class="ic-form-control" *ngIf="table.cols[cells_i].input.type == 'date'"
                    id="{{id+'_input_L'+row.number+'C'+cells_i}}" name="{{id+'_input_L'+row.number+'C'+cells_i}}"
                    type="date" min="{{table.cols[cells_i].input.min}}" max="{{table.cols[cells_i].input.max}}"
                    [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i],row,aditionalData)"
                    [pattern]="table.cols[cells_i].input.pattern"
                    [required]="table.cols[cells_i].input.required(row.cellsData[cells_i],row,aditionalData)"
                    [(ngModel)]="row.cellsData[cells_i]" (ngModelChange)="_rowChange(rows_i, cells_i, rowForm.valid)"
                    autofocus>
                  <!-- date -->

                  <!-- select -->
                  <ng-select form="rowForm" *ngIf="table.cols[cells_i].input.type == 'select'"
                    id="{{id+'_input_L'+row.number+'C'+cells_i}}" name="{{id+'_input_L'+row.number+'C'+cells_i}}"
                    [compareWith]="_compareFn"
                    [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i],row,aditionalData)"
                    [required]="table.cols[cells_i].input.required(row.cellsData[cells_i],row,aditionalData)"
                    [pattern]="table.cols[cells_i].input.pattern" [(ngModel)]="row.cellsData[cells_i]"
                    (change)="_rowChange(rows_i, cells_i, rowForm.valid)" autofocus>
                    <ng-option *ngFor="let o of row.selectOptions[cells_i]" [value]="o.value">
                      {{o.name}}
                    </ng-option>
                  </ng-select>

                  <p class="tiny-error"
                    *ngIf="table.cols[cells_i].input.type == 'select' && table.cols[cells_i].input.required(row.cellsData[cells_i],row,aditionalData) && !_validateSelectOption(row.cellsData[cells_i], row.selectOptions[cells_i])">
                    invalid value: "{{row.cellsData[cells_i]}}"
                  </p>
                  <!-- select -->

                  <p class="tiny-error"
                    *ngIf="showObligatoryStamp(table.cols[cells_i].input.required(row.cellsData[cells_i],row.objData,aditionalData),row.cellsData[cells_i])">
                    obrigatório
                  </p>
                </div>
                <!-- changeable cells -->

                <!-- unchangeable cells -->
                <div *ngIf="!table.cols[cells_i].canChange">
                  <!-- text -->
                  <span *ngIf="table.cols[cells_i].input.type == 'text'">
                    {{cel}}
                  </span>
                  <!-- text -->

                  <!-- number -->
                  <span *ngIf="table.cols[cells_i].input.type == 'number'">
                    {{cel}}
                  </span>
                  <!-- number -->

                  <!-- date -->
                  <input form="rowForm" class="ic-form-control" *ngIf="table.cols[cells_i].input.type == 'date'"
                    id="{{id+'_input_L'+row.number+'C'+cells_i}}" name="{{id+'_input_L'+row.number+'C'+cells_i}}"
                    type="date" disabled [(ngModel)]="row.cellsData[cells_i]" autofocus>
                  <!-- date -->

                  <!-- select -->
                  <div *ngIf="table.cols[cells_i].input.type == 'select'">
                    <div *ngFor="let o of row.selectOptions[cells_i]">
                      <span *ngIf="row.cellsData[cells_i] == o.value">
                        {{o.name}}
                      </span>
                    </div>
                  </div>
                  <!-- select -->
                </div>
                <!-- unchangeable cells -->

              </div>
              <!-- input data -->

              <!-- no input data -->
              <div *ngIf="!table.cols[cells_i].input">
                <span *ngIf="table.cols[cells_i].type=='text'">
                  {{cel}}
                </span>

                <div *ngIf="table.cols[cells_i].type=='html'" [innerHTML]="_trustHtml(cel)">
                </div>
              </div>
              <!-- no input data -->

            </div>
            <!-- Old row -->

            <!-- New row -->
            <div *ngIf="row.status== 'new'">

              <!-- input data -->
              <div *ngIf="table.cols[cells_i].input">

                <!-- text -->
                <input form="rowForm" class="ic-form-control" *ngIf="table.cols[cells_i].input.type == 'text'"
                  id="{{id+'_input_L'+row.number+'C'+cells_i}}" name="{{id+'_input_L'+row.number+'C'+cells_i}}"
                  type="text" minlength="{{table.cols[cells_i].input.minlength}}"
                  maxlength="{{table.cols[cells_i].input.maxlength}}"
                  [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i],row,aditionalData)"
                  [pattern]="table.cols[cells_i].input.pattern"
                  [required]="table.cols[cells_i].input.required(row.cellsData[cells_i],row,aditionalData)"
                  [(ngModel)]="row.cellsData[cells_i]" (ngModelChange)="_rowChange(rows_i, cells_i, rowForm.valid)"
                  autofocus>
                <!-- text -->

                <!-- number -->
                <input form="rowForm" class="ic-form-control" *ngIf="table.cols[cells_i].input.type == 'number'"
                  id="{{id+'_input_L'+row.number+'C'+cells_i}}" name="{{id+'_input_L'+row.number+'C'+cells_i}}"
                  type="number" min="{{table.cols[cells_i].input.min}}" max="{{table.cols[cells_i].input.max}}"
                  [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i],row,aditionalData)"
                  [pattern]="table.cols[cells_i].input.pattern"
                  [required]="table.cols[cells_i].input.required(row.cellsData[cells_i],row,aditionalData)"
                  [(ngModel)]="row.cellsData[cells_i]" (ngModelChange)="_rowChange(rows_i, cells_i, rowForm.valid)"
                  autofocus>
                <!-- number -->

                <!-- date -->
                <input form="rowForm" class="ic-form-control" *ngIf="table.cols[cells_i].input.type == 'date'"
                  id="{{id+'_input_L'+row.number+'C'+cells_i}}" name="{{id+'_input_L'+row.number+'C'+cells_i}}"
                  type="date" min="{{table.cols[cells_i].input.min}}" max="{{table.cols[cells_i].input.max}}"
                  [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i],row,aditionalData)"
                  [pattern]="table.cols[cells_i].input.pattern"
                  [required]="table.cols[cells_i].input.required(row.cellsData[cells_i],row,aditionalData)"
                  [(ngModel)]="row.cellsData[cells_i]" (ngModelChange)="_rowChange(rows_i, cells_i, rowForm.valid)"
                  autofocus>
                <!-- date -->

                <!-- select -->
                <ng-select form="rowForm" *ngIf="table.cols[cells_i].input.type == 'select'"
                  id="{{id+'_input_L'+row.number+'C'+cells_i}}" name="{{id+'_input_L'+row.number+'C'+cells_i}}"
                  [compareWith]="_compareFn" appendTo="body"
                  [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i],row,aditionalData)"
                  [required]="table.cols[cells_i].input.required(row.cellsData[cells_i],row,aditionalData)"
                  [pattern]="table.cols[cells_i].input.pattern" [(ngModel)]="row.cellsData[cells_i]"
                  (change)="_rowChange(rows_i, cells_i, rowForm.valid)">
                  <ng-option *ngFor="let o of row.selectOptions[cells_i]" [value]="o.value">
                    {{o.name}}
                  </ng-option>
                </ng-select>

                <p class="tiny-error"
                  *ngIf="showObligatoryStamp(table.cols[cells_i].input.required(row.cellsData[cells_i],row.objData,aditionalData),row.cellsData[cells_i])">
                  obrigatório
                </p>
              </div>
              <!-- input data -->

              <!-- no input data -->
              <div *ngIf="!table.cols[cells_i].input">
                <span *ngIf="table.cols[cells_i].type=='text'">
                  {{cel}}
                </span>

                <div *ngIf="table.cols[cells_i].type=='html'" [innerHTML]="_trustHtml(cel)">
                </div>
              </div>
              <!-- no input data -->

            </div>
            <!-- New row -->
          </div>
        </div>
      </div>
      <!-- custom table data -->

      <!-- options -->
      <div class="db-table-td" *ngIf="edit">
        <div class="inner" style="width: 30px;">
          <div class="wrapper">
            <div class="options-container">
              <div class="inner">
                <button (click)="_openOptionsPopDown(rows_i)">
                  <nb-icon icon="more-vertical-outline" pack="eva"></nb-icon>
                </button>
              </div>

              <!-- popDown -->
              <div class="popDown" *ngIf="showOptionsPopDown==rows_i">
                <div class="popDownContent" #OptionPopDownContentERef>
                  <ul>
                    <li *ngIf="canMoveItems && row.number>1">
                      <button (click)="_moveItem_rowUp(row.number)">
                        <nb-icon icon="arrow-upward" pack="eva"></nb-icon>
                        Mover p/ cima
                      </button>
                    </li>
                    <li *ngIf="canMoveItems && row.number<table.rows.length">
                      <button (click)="_moveItem_rowDown(row.number)">
                        <nb-icon icon="arrow-downward" pack="eva"></nb-icon>
                        Mover p/ baixo
                      </button>
                    </li>
                    <li *ngIf="canDuplicate">
                      <button (click)="_duplicateRow(row.number)">
                        <nb-icon icon="copy-outline" pack="eva"></nb-icon>
                        Duplicar
                      </button>
                    </li>
                    <li>
                      <button [disabled]="!canDelete" (click)="_tryDelete(row.number)">
                        <nb-icon style="color:red" icon="trash-2-outline" pack="eva"></nb-icon>
                        Deletar
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- popDown -->
            </div>
          </div>
        </div>
      </div>
      <!-- options -->

    </form>
    <!-- db-table-tbody -->

  </div>
  <!-- db-table -->

  <!-- pagination -->
  <ul class="_pagination">
    <li class="_item">
      <button [disabled]="actualPage == 1" (click)="_pageDown()">
        <nb-icon icon="chevron-left-outline" pack="eva"></nb-icon>
      </button>
    </li>
    <li class="_item-inner">
      <span>
        <input type="number" id="input_page" name="input_page" [(ngModel)]="input_page" [min]="1" [max]="lastPage"
          (keyup.enter)="_update_input_page()">
        / {{lastPage}}
      </span>
    </li>
    <li class="_item">
      <button [disabled]="actualPage == lastPage" (click)="_pageUp()">
        <nb-icon icon="chevron-right-outline" pack="eva"></nb-icon>
      </button>
    </li>
  </ul>
  <!-- pagination -->

  <div class="footer">
    <span>{{this.table.rows.length}} linha(s)</span>
    <button class="saveAsExcel" [disabled]="this.table.rows.length == 0" (click)="saveAsExcel()">
      <nb-icon icon="download-outline" pack="eva"></nb-icon>
      Excel
    </button>
  </div>

</div>