import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbActionsModule, NbButtonModule, NbCardModule, NbIconModule, NbSpinnerModule, NbTabsetModule, NbUserModule } from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxEchartsModule } from 'ngx-echarts';

import { DbRuleUiModule } from '../@db-rules-ui/db-rules-ui.module';
import { MagicTableModule } from '../@magic-table';
import { DataVisualizationEditorComponent } from './data-visualization-editor/data-visualization-editor.component';
import { EditorFieldsComponent } from './data-visualization-editor/source-tab/editor-fields/editor-fields.component';
import { EditorEngineConfigComponent } from './data-visualization-editor/vis-tab/editor-engine-config/editor-engine-config.component';
import { EditorEngineDisplayComponent } from './data-visualization-editor/vis-tab/editor-engine-display/editor-engine-display.component';
import { DataVisualizationHighlightDirective } from './data-visualization-highlight.directive';
import { DataVisualizationComponent } from './data-visualization/data-visualization.component';

const BASE_MODULES = [
  CommonModule,

  FormsModule,
  ReactiveFormsModule,
  NgSelectModule,

  NgxEchartsModule,
  Ng2SmartTableModule,
  MatDialogModule,
  NbCardModule,
  NbButtonModule,
  NbEvaIconsModule,
  NbUserModule,
  NbIconModule,
  NbActionsModule,
  NbTabsetModule,
  NbSpinnerModule,
  MagicTableModule,
  DbRuleUiModule,
];

const COMPONENTS = [
  DataVisualizationHighlightDirective,
  DataVisualizationComponent,
  DataVisualizationEditorComponent,
  EditorFieldsComponent,
  EditorEngineConfigComponent,
  EditorEngineDisplayComponent
];


@NgModule({
  imports: [...BASE_MODULES],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA]
})
export class DataVisualizationModule { }
