import firebase from "firebase/compat/app";

export class HomePageComms {

  id: string;
  status: 0 | 1;
  validFrom: string;
  validTo: string;

  title: string;
  position: number;

  html: string;
  text: string;
  words: string[];

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<HomePageComms>) {

    this.id = "";
    this.status = 0;
    this.validFrom = '';
    this.validTo = '';

    this.title = "";
    this.position = 0;

    this.html = "";
    this.text = "";
    this.words = [];

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;


    if (init)
      Object.assign(this, init);
  }
}
