import { _valueStandardizer } from "./valueStandardizer";

/**
 * 
 * @param data any[]
 * @param fieldName boolean | string | string[], use false to string Array the object
 * @param ord "asc" | "desc"
 * @returns 
 */
export function _localSort<ArrayType>(data: ArrayType[], fieldName: boolean | string | string[] = false, ord: "asc" | "desc" = "asc"): ArrayType[] {
    let tempRows = data;
    tempRows.sort((a, b) => {
        let valueA = null;
        let valueB = null;

        if (!fieldName) {
            valueA = _valueStandardizer(a);
            valueB = _valueStandardizer(b);
        }
        else if (!Array.isArray(fieldName)) {
            valueA = _valueStandardizer(a[fieldName as any]);
            valueB = _valueStandardizer(b[fieldName as any]);
        } else {
            for (let index = 0; index < fieldName.length; index++) {
                const fN = fieldName[index];
                if (index == 0) {
                    valueA = _valueStandardizer(a[fN]);
                    valueB = _valueStandardizer(b[fN]);
                }
                else {
                    valueA = valueA + "-" + a[fN];
                    valueB = valueB + "-" + b[fN];
                }
            }
        }
        if (ord == "asc") {
            if (valueA < valueB)
                return -1;
            if (valueA > valueB)
                return 1;
        } else {
            if (valueA > valueB)
                return -1;
            if (valueA < valueB)
                return 1;
        }
        return 0;
    });
    return tempRows;
}

export function _stringArraySort(data: any[], ord: "asc" | "desc"): any[] {
    let tempRows = data;
    tempRows.sort((a, b) => {
        let valueA = a;
        let valueB = b;

        if (ord == "asc") {
            if (valueA < valueB)
                return -1;
            if (valueA > valueB)
                return 1;
        } else {
            if (valueA > valueB)
                return -1;
            if (valueA < valueB)
                return 1;
        }
        return 0;
    });
    return tempRows;
}