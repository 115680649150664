import { APP_BASE_HREF, DatePipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

registerLocaleData(localePt, 'pt-BR');
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from './@core/core.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ThemeModule } from './@theme';
import { FirebaseModule } from './@firebase';
import { MainModalsModule } from './routes/main/main-modals.module';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,

        FirebaseModule,
        CoreModule.forRoot(),
        ThemeModule.forRoot(),

        AppRoutingModule,

        MatDialogModule,
        MainModalsModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        { provide: APP_BASE_HREF, useValue: '/' },
        DatePipe,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {
                hasBackdrop: true,
                disableClose: true,
                autoFocus: false,
            }
        },
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
}
