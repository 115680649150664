export class RoleAuth {
  id: string;
  roleId: string;
  objectId: string;
  authPairs: AuthPair[];
  public constructor(init?: Partial<RoleAuth>) {
    this.id = '';
    this.roleId = '';
    this.objectId = '';
    this.authPairs = [];
    if (init)
      Object.assign(this, init);
  }
}

export class AuthPair {
  field: string;
  values: string[];
  public constructor(init?: Partial<AuthPair>) {
    this.field = '';
    this.values = [];
    if (init)
      Object.assign(this, init);
  }
}
