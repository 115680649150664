import firebase from "firebase/compat/app";

export class WS_Team {

  id: string;
  workspaceId: string;
  parentKey: string;
  favoritedUnames: string[];

  name: string;
  description: string;
  picture: string;
  thumbnail: string;
  color: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<WS_Team>) {

    this.id = '';
    this.workspaceId = '';
    this.parentKey = '';
    this.favoritedUnames = [];

    this.name = '';
    this.description = '';
    this.picture = '';
    this.thumbnail = '';
    this.color = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
