import firebase from "firebase/compat/app";

export class WS_Association {

  id: string;
  workspaceId: string;
  parentKey: string;

  uName: string;
  roleId: string;
  status: 0 | 1;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<WS_Association>) {

    this.id = '';
    this.workspaceId = '';
    this.parentKey = '';

    this.uName = '';
    this.roleId = '';
    this.status = 1;

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
