import firebase from "firebase/compat/app";
import { SM_DocType } from "./SM_Document/SM_Document";

export class SM_ResolutionCause {

  id: string;
  status: 0 | 1;

  name: string;
  type: "*" | SM_DocType;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<SM_ResolutionCause>) {

    this.id = '';
    this.status = 0;
    this.name = '';
    this.type = '*';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
