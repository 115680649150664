import { SafeHtml } from "@angular/platform-browser";

export class SmartTableColumns {
  [name: string]: SmartTableColumn
};

export type SmartTableColumnType = 'text' | 'html';
export type SmartTableColumnFilterType = 'list' | 'completer' | 'checkbox';

export class SmartTableColumn {
  title: string;
  type?: SmartTableColumnType;
  //** width: '75px' */
  width?: string;
  valuePrepareFunction?: (cell?, row?) => string | SafeHtml;
  filterFunction?: (cell?, search?, row?) => boolean;
  filter?: {
    type: 'list' | 'completer' | 'checkbox',
    config: {
      /** list selectText  */
      selectText?: string;
      /** list for selection */
      list?: { value: string | number; title: string }[];
      /** completer config */
      completer?: {
        /** array used to create LocalDataSource */
        data?: any[],
        /** Field of the object on the data array  */
        searchFields?: string;
        /** Title for the field of the object on the data array  */
        titleField?: string;
      },
      /** checkbox true value */
      true?: string;
      /** checkbox false value */
      false?: string;
      /** checkbox resetText */
      resetText?: string;
    },
  };
}
