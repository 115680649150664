import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService, UtilService } from 'app/@core';
import { AuthObjectField, AuthObjectFieldValue, FirestoreService } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

declare var jQuery: any;

@Component({
  selector: 'app-edit-auth-object-field-modal',
  templateUrl: './edit-auth-object-field-modal.component.html',
  styleUrls: ['./edit-auth-object-field-modal.component.scss']
})
export class EditAuthObjectFieldModalComponent implements OnInit {

  className = 'EditAuthObjectFieldModalComponent';

  public loader = true;

  localAuthObjectField = new AuthObjectField();

  newFieldValue = new AuthObjectFieldValue();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,

    public db: FirestoreService,
    private authService: AuthService,
    public router: Router,
    public uiFeedBackCtrl: UiFeedBackService,
    public utilCtrl: UtilService,
  ) {
    this.localAuthObjectField = data['authObjectField'];
    (function ($) {
      $(document).ready(function () {
        $('.mat-dialog-container').css("width", "600px");
      });
    })(jQuery);
  }


  ngOnInit() {
    this.loader = false

  }

  toggle_valuesFromColection(nextV: boolean) {
    if (!nextV) {
      this.localAuthObjectField.valuesColection = '';
      this.localAuthObjectField.valuesField = '';
    } else {
      this.localAuthObjectField.values = [];
    }
  }


  addFieldValue() {
    this.localAuthObjectField.values.push(this.newFieldValue);
    this.newFieldValue = new AuthObjectFieldValue();
  }
  removeFieldValueConfirmation(i) {
    var r = confirm(`Tem certeza que deseja deletar "${this.localAuthObjectField.values[i].value}"?\n-----------------`);
    if (r == true) {
      this.removeFieldValue(i);
    } else {
      alert("Ação Cancela!");
    }
  }
  removeFieldValue(i) {
    this.localAuthObjectField.values.splice(i, 1);
  }


  save() {
    console.log(`E D I T - S A V E`, this.localAuthObjectField)

    this.loader = true;

    this.authService.getUser()
      .then(user => {

        this.localAuthObjectField.updatedBy = user.uName;
        this.localAuthObjectField.updatedOn = this.utilCtrl.timestamp.now();

        let tempValues = [];
        this.localAuthObjectField.values.forEach(fv => {
          tempValues.push(Object.assign({}, fv))
        })
        this.localAuthObjectField.values = tempValues;

        this.db.afs
          .collection(this.db.COLLECTIONS.sys.accessControl.authorizationStructure.authObjectFields)
          .doc(this.localAuthObjectField.id)
          .update(Object.assign({}, this.localAuthObjectField))
          .then(() => {

            this.loader = false;

            this.dialog.closeAll();

          })
          .catch((e) => {
            this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
          })
      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }
}
