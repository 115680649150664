import firebase from "firebase/compat/app";

export class S_A_Variable {
  id: string;
  description: string;
  value: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<S_A_Variable>) {
    this.id = '';
    this.description = '';
    this.value = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
