import { Location } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';

import { AuthService, UtilService } from 'app/@core';
import { FirestoreService, Role, RoleUser, User } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

declare var jQuery: any;

@Component({
  selector: 'app-create-role-user-modal',
  templateUrl: './create-role-user-modal.component.html',
  styleUrls: ['./create-role-user-modal.component.scss']
})
export class CreateRoleUserModalComponent implements OnInit {

  className = 'CreateRoleUserModalComponent';

  public loader = true;

  modalId = '';

  role = new Role();
  selectedUser = new User();
  users: User[] = [];

  newRoleUser = new RoleUser();

  users_source: LocalDataSource;
  users_columns = {
    uName: {
      title: 'User Name',
      type: 'text',
    },
    fullName: {
      title: 'Nome',
      type: 'text',
    },
    email: {
      title: 'Email',
      type: 'text',
    },
    registerDate: {
      title: 'Registrado em',
      type: 'text',
      valuePrepareFunction: (cell, row) => {
        return this.utilCtrl.timestamp.toLocalDate(cell);
      }
    },

    validTo: {
      title: 'Valido até',
      type: 'text',
      valuePrepareFunction: (cell, row) => {
        return this.utilCtrl.timestamp.toLocalDate(cell);
      }
    },
  };


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private mdDialogRef: MatDialogRef<CreateRoleUserModalComponent>,

    public uiFeedBackCtrl: UiFeedBackService,
    public db: FirestoreService,
    private authService: AuthService,
    public router: Router,
    private _location: Location,
    public utilCtrl: UtilService,
  ) {
    this.modalId = this.data.modalId;

    this.role = this.data.role != undefined ? JSON.parse(JSON.stringify(this.data.role)) : new Role();
    this.users = this.data.users != undefined ? JSON.parse(JSON.stringify(this.data.users)) : [];

    (function ($) {
      $(document).ready(function () {
        $('.mat-dialog-container').css("width", "600px");
      });
    })(jQuery);
  }

  ngOnInit() {
    this.authService.getUser()
      .then(u => {
        this.newRoleUser.id = this.db.createId();
        this.newRoleUser.roleId = this.role.id;

        this.newRoleUser.from = this.utilCtrl.date.getDateStr(new Date);
        this.newRoleUser.to = this.utilCtrl.date.getDateStr(new Date(new Date().getTime() + 86400000));

        if (this.role.type == 'C')
          this.newRoleUser.type = 'I'
        else
          this.newRoleUser.type = 'D'

        this.newRoleUser.createdBy = u.uName;
        this.users_source = new LocalDataSource(this.users);

        this.loader = false;
      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }


  selectUser() {
    this.uiFeedBackCtrl.showTableSelect(this.users_columns, this.users_source)
      .then(u => {
        if (u)
          this.selectedUser = u;
      })
  }

  cancel() {
    this.mdDialogRef.close();
  }
  save() {

    this.newRoleUser.uName = this.selectedUser.uName;

    this.mdDialogRef.close({
      save: true,
      newRoleUser: this.newRoleUser
    });

  }

}
