import { Injectable } from "@angular/core";
import { DocumentSnapshot, QuerySnapshot } from "@firebase/firestore-types";

@Injectable()
export class Validators {
    public validateDoc(doc: DocumentSnapshot): Promise<DocumentSnapshot> {
        return new Promise((resolve, reject) => {
            if (!doc.metadata.fromCache) {
                resolve(doc);
            } else {
                reject({ message: 'Failed, the client is offline.' })
            }
        })
    }
    public validateQuerySnapshot(querySnapshot: QuerySnapshot): Promise<QuerySnapshot> {
        return new Promise((resolve, reject) => {
            if (!querySnapshot.metadata.fromCache) {
                resolve(querySnapshot);
            } else {
                reject({ message: 'Failed, the client is offline.' })
            }
        })
    }
}