export const COLLECTIONS = {

  sys: {
    sysPages: 'SysPages',
    variables: 'S_A_Variables',
    users: 'Users',
    deviceTokens: 'DeviceTokens',
    notifications: 'Notifications',
    reportPreferences: 'ReportPreferences',
    LOCK_ENTRIES: 'LockEntries',
    logs: 'Logs',
    db: {
      dataFields: 'DB_DataFields',
      dataFieldTexts: 'DB_DataFieldTexts',
      dataSources: 'DB_DataSources',
      reports: 'DB_Reports'
    },
    accessControl: {
      authorizationProvisioning: {
        roles: 'Roles',
        roleAuths: 'RoleAuths',
        roleUsers: 'RoleUsers'
      },
      authorizationStructure: {
        authObjects: 'AuthObjects',
        authObjectClasses: 'authObjectClasses',
        authObjectFields: 'AuthObjectFields',
      },
    },
  },

  tools: {
    etlScripts: 'TL_EtlScripts',
  },

  home: {
    items: `HomePageItems`,
    tabs: `HomePageTabs`,
    comms: 'HomePageComms',
    notes: 'HomePageNotes'
  },

  help: {
    docs: 'H_docs',
    faqs: `H_Faqs`
  },

  timeManagement: {
    timeRecords: 'TM_TimeRecords',
    timerSessions: 'TM_TimerSessions',
    teams: 'TM_Teams',
    teamAscs: 'TM_TeamAscs',
    projects: 'TM_Projects',
    projectAscs: 'TM_ProjectAscs',
    projectTask: 'TM_ProjectTasks',
    projectHourPlan: 'TM_ProjectHourPlans',
    clients: 'TM_Clients',
    tags: 'TM_Tags',
  },

  workflow: {
    groups: 'WF_Groups',
    groupAscs: 'WF_GroupAscs',
  },

  organizationalStructure: {
    companies: 'OS_Companies',
    businessPlaces: 'OS_BusinessPlaces',
    calendars: 'OS_Calendars'
  },

  serviceManagement: {
    documents: {
      documents: 'SM_Documents',
      docActivities: 'SM_DocActivities',
      docAdditionalInfos: 'SM_DocAdditionalInfos',
      docAttachments: 'SM_DocAttachments',
      docSLAs: 'SM_DocSLAs',
    },
    teams: 'SM_Teams',
    teamAscs: 'SM_TeamAscs',
    resolutionCauses: 'SM_ResolutionCauses',
    resolutions: 'SM_Resolutions',
    serviceCatalog: {
      categories: 'SM_Categories',
      subcategories: 'SM_Subcategories',
      businessRoles: 'SM_BusinessRoles',
      customFields: 'SM_CustomFields',
      customFieldAscs: 'SM_CustomFieldAscs',
      slaDefinitions: 'SM_SLADefinitions',
    },
  },

  projectManagement: {
    kanban: {
      boards: 'PM_Boards',
      routes: 'PM_BoardRoutes',
      labels: 'PM_BoardLabels',
      cards: 'PM_Cards',
      boardActivities: 'PM_BoardActivities',
      cardAttachments: 'PM_CardAttachments'
    },
    toDoLists: 'PM_ToDoLists',
    toDos: 'PM_ToDos',
  }
}
