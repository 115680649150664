import firebase from "firebase/compat/app";

/**
 * @GR Geral
 * @C Comentário
 *
 * @CR Criação
 * @FC Alteraçõees
 * @DE Apagar
 */
export type PM_BoardActivityType =
  "GR" |
  "DE" |
  "CR" |
  "C" |
  "FC";

export class PM_BoardActivityChange {
  fieldName: string;
  oldValue: string;
  newValue: string;
  public constructor(init?: Partial<PM_BoardActivityChange>) {
    this.fieldName = '';
    this.oldValue = '';
    this.newValue = '';
    if (init)
      Object.assign(this, init);
  }
}

export class PM_BoardActivity {

  id: string;
  uName: string;

  boardId: string;
  cardId: string;
  parentKey: string;

  type: PM_BoardActivityType;

  html: string;
  text: string;
  mentions: string[];
  attachments: string[];

  pinned: boolean;

  likes: string[];
  dislikes: string[];
  views: string[];

  changes: PM_BoardActivityChange[];

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<PM_BoardActivity>) {

    this.id = '';
    this.uName = '';

    this.boardId = '';
    this.cardId = '';
    this.parentKey = '';

    this.type = 'GR';

    this.html = '';
    this.text = '';
    this.mentions = [];
    this.attachments = [];

    this.pinned = false;

    this.likes = [];
    this.dislikes = [];
    this.views = [];

    this.changes = [];

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
