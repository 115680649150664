import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';

import { AuthService, UtilService } from 'app/@core';
import { DB_OrderByRule, DB_RuleUiData, FirestoreService } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

@Component({
  selector: 'db-order-by-rule-ui',
  templateUrl: './db-order-by-rule-ui.component.html',
  styleUrls: ['./db-order-by-rule-ui.component.scss']

})
export class DbOrderByRuleUiComponent implements OnInit, OnChanges {

  readonly className = 'DbOrderByRuleUiComponent';

  @Input() disabled = false;
  @Output() onChange = new EventEmitter();
  hasChanges = false;
  hasError = false;

  @Input() edit = false;

  dataSourceId: string = '';
  oldOrderBy: DB_OrderByRule = new DB_OrderByRule();

  orderBy: DB_OrderByRule = new DB_OrderByRule();
  @Output() ruleSetChange = new EventEmitter<DB_OrderByRule[]>();

  _dataSourceData: DB_RuleUiData = new DB_RuleUiData();

  _loader = false;
  _showConstructor = false;
  _orderByStr = '';

  constructor(
    private authService: AuthService,
    public db: FirestoreService,
    public uiFeedBackCtrl: UiFeedBackService,
    public utilCtrl: UtilService,
  ) { }

  ngOnInit() { }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }

  public load(dataSourceId: string, orderBy: DB_OrderByRule) {
    this._loader = true;

    this.dataSourceId = dataSourceId;
    this.oldOrderBy = JSON.parse(JSON.stringify(orderBy));
    this.orderBy = JSON.parse(JSON.stringify(orderBy));

    this.hasChanges = false;
    this.hasError = false;

    this.db.dataSource.getRuleUiDataById(this.dataSourceId)
      .then(data => {
        this._dataSourceData = data;
        this._change();
        this._loader = false;
      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }
  public setInternalData(dataSourceId: string, orderBy: DB_OrderByRule, dataSourceData: DB_RuleUiData) {
    this.dataSourceId = dataSourceId;
    this.oldOrderBy = JSON.parse(JSON.stringify(orderBy));
    this.orderBy = JSON.parse(JSON.stringify(orderBy));
    this._dataSourceData = dataSourceData;
    this._change();
  }
  getData(): DB_OrderByRule {
    return JSON.parse(JSON.stringify(this.orderBy));
  }
  // Rotinas de controle
  _updateOrderByStr() {
    let tempOrderByStr = '';

    // fieldStr
    let fieldStr = 'Não Ordenar';
    let field = this._dataSourceData.fields.filter(f => { return f.id == this.orderBy.fieldId })[0];
    if (field)
      fieldStr = field.name;
    // fieldStr

    // orderantionStr
    let orderantionStr = '';
    if (this.orderBy.fieldId && this.orderBy.direction)
      orderantionStr = this.db.DICTIONARY.translator(this.orderBy.direction, this.db.DICTIONARY.sys.db.orderByRule.direction);
    // orderantionStr

    tempOrderByStr = `${fieldStr} ${orderantionStr}`;

    this._orderByStr = tempOrderByStr;
  }
  _sort(ord: "asc" | "desc") {
    this.orderBy.direction = ord;
    this._change();
  }
  _checkForChanges() {
    if (
      JSON.stringify(this.oldOrderBy) != JSON.stringify(this.orderBy)
    )
      this.hasChanges = true
    else
      this.hasChanges = false
  }
  _checkForError() {
    this.hasError = (this.orderBy.fieldId !== null && this.orderBy.fieldId !== '') && !this.orderBy.direction;
  }
  _change() {
    setTimeout(() => {
      this._updateOrderByStr();
      this._checkForChanges();
      this._checkForError();
      this.onChange.emit();
    }, 250);
  }
  _toggleShowConstructor() {
    this._showConstructor = !this._showConstructor;
  }
  // Rotinas de controle
}
