import firebase from "firebase/compat/app";

export function magicTableTimeStampToLocalDate(timeStamp: firebase.firestore.Timestamp): string {
    if (!timeStamp)
        return '';

    if (timeStamp.seconds != null && timeStamp.nanoseconds != null) {
        return new Date((timeStamp.seconds * 1000 + timeStamp.nanoseconds / 1e6)).toLocaleString('pt-BR');
    }

    let _timeStamp = timeStamp as any;
    if (_timeStamp._seconds != null && _timeStamp._nanoseconds != null) {
        return new Date((_timeStamp._seconds * 1000 + _timeStamp._nanoseconds / 1e6)).toLocaleString('pt-BR');
    }

    return '';
}
