import { DbTableComponent } from "../db-table.component";
import { dbTableFilterValueDetermination } from "./db-table-filter-value-determination";
import { dbTableValueStandardizer } from "./db-table-value-standardizer";

/**
 * Inicia os indices dos filtros de acordo com as linhas da tabela
 */
export function dbTableinitFilterIndexes(_this: DbTableComponent) {
    _this.filterIndexes = [];
    _this.filterActiveIndex = [];

    for (let c_index = 0; c_index < _this.table.cols.length; c_index++) {
        let tempColIndex = [];
        let tempSingleValues = [];

        for (let r of _this.table.rows) {
            const fValue = dbTableFilterValueDetermination(r, c_index, _this.table.cols[c_index]);
            if (tempSingleValues.indexOf(fValue) == -1) {
                tempSingleValues.push(fValue);
                tempColIndex.push({ show: true, value: fValue });
            }
        }

        tempColIndex.sort(function (a, b) {
            var valueA = dbTableValueStandardizer(a.value),
                valueB = dbTableValueStandardizer(b.value);
            if (valueA < valueB) return -1;
            if (valueA > valueB) return 1;
            return 0;
        });

        _this.filterIndexes.push(tempColIndex);
        _this.filterActiveIndex.push(false);
    }
}