import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NbOverlayRef } from '@nebular/theme';

import { OverlayService } from 'app/@theme/services/overlay.service';

@Component({
  selector: 'app-ui-feed-back-loader-modal',
  templateUrl: 'ui-feed-back-loader-modal.component.html',
  styleUrls: ['./../ui-feed-back-modals.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UiFeedBackLoaderModalComponent {

  @Input() id: string = '';
  @Input() overlay: NbOverlayRef;
  readonly className = 'UiFeedBackLoaderModalComponent';
  onCloseData = {};

  @Input() message = '';

  lottie = {
    loop: true,
    autoplay: true,
    path: 'assets/animations/uiFeedBack.service/loader.json'
  };

  constructor(
    public overlayCtrl: OverlayService,
  ) { }

}
