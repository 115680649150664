import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { DbTableComponent } from "../db-table.component";

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

// Excel
function _cleanValue(cell: any): string {
    let v = '';
    if (cell != undefined) {
        cell = cell + '';
        v = cell.replace(/<(?:.|\n)*?>/gm, '');
    }
    return v;
}
function saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().toISOString() + EXCEL_EXTENSION);
}
function exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcelFile(excelBuffer, excelFileName);
}
export function dbTableSaveAsExcel(_this: DbTableComponent) {
    let tempData = [];
    for (let row of _this.table.rows) {
        let data = {};
        let tempRow = row;
        for (let c_index = 0; c_index < _this.table.cols.length; c_index++)
            data[_this.table.cols[c_index].title] = _cleanValue(tempRow.cells[c_index]);
        tempData.push(data)
    }
    exportAsExcelFile(tempData, 'ic')
}
// Excel