import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FieldValue } from '@firebase/firestore-types';
import firebase from "firebase/compat/app";

import { FireFunctionsService } from '../fireFunctions.service';
import { CollectionCRUD } from './CollectionCRUD';
import { COLLECTIONS } from './COLLECTIONS';
import { DB_DataSourceService } from './DB_DataSourceService';
import { DB_MagicQueryService } from './DB_MagicQuery';
import { DICTIONARY } from './DICTIONARY';
import { LockEntries } from './lockEntries';
import { DB_DataField, DB_DataFieldText, DB_DataSource, DB_Report, DeviceToken, H_Doc, H_FAQ, HomePageComms, HomePageItem, HomePageNote, HomePageTab, Log, Notification, OS_BusinessPlace, OS_Calendar, OS_Company, PM_Board, PM_BoardActivity, PM_BoardLabel, PM_BoardModel, PM_BoardRoute, PM_Card, PM_CardAttachment, PM_ToDo, PM_ToDoList, ReportPreferences, Role, RoleUser, S_A_Variable, SM_BusinessRole, SM_Category, SM_CustomField, SM_CustomFieldAsc, SM_DocActivity, SM_DocAdditionalInfo, SM_DocAttachment, SM_DocSLA, SM_Document, SM_Resolution, SM_ResolutionCause, SM_SLADefinition, SM_Subcategory, SM_Team, SM_TeamAsc, SysPage, TL_EtlScript, TM_Client, TM_Project, TM_ProjectAsc, TM_ProjectHourPlan, TM_ProjectTask, TM_Tag, TM_Team, TM_TeamAsc, TM_TimeRecord, TM_TimerSession, User, WF_Group, WF_GroupAsc } from './models';
import { PM_BoardSnapshot } from './models/project-management/kanban/PM_BoardSnapshot';
import { PM_CardSnapshot } from './models/project-management/kanban/PM_CardSnapshot';
import { WS_Association, WS_Bookmark, WS_Client, WS_Portifolio, WS_Role, WS_Tag, WS_Team, WS_TeamAssociation, WS_Workspace } from './models/workspaces';
import { Validators } from './Validators';

@Injectable()
export class FirestoreService {

  public validators = new Validators;
  public COLLECTIONS = COLLECTIONS;
  public DICTIONARY = new DICTIONARY();
  public dataSource = new DB_DataSourceService(this.afs, this.aff);
  public magicQuery = new DB_MagicQueryService(this.afs, this.aff);
  public lockEntries = new LockEntries(this.afs);

  public sys: {
    sysPages: CollectionCRUD<SysPage>,
    variables: CollectionCRUD<S_A_Variable>,
    users: CollectionCRUD<User>,
    deviceTokens: CollectionCRUD<DeviceToken>,
    notifications: CollectionCRUD<Notification>,
    reportPreferences: CollectionCRUD<ReportPreferences>,
    logs: CollectionCRUD<Log>,
    db: {
      dataFields: CollectionCRUD<DB_DataField>,
      dataFieldTexts: CollectionCRUD<DB_DataFieldText>,
      dataSources: CollectionCRUD<DB_DataSource>,
      reports: CollectionCRUD<DB_Report>,
    },
    accessControl: {
      authorizationProvisioning: {
        roles: CollectionCRUD<Role>,
        roleUsers: CollectionCRUD<RoleUser>,
      }
    },
  };

  public tools: {
    etlScripts: CollectionCRUD<TL_EtlScript>,
  };

  public home: {
    items: CollectionCRUD<HomePageItem>,
    tabs: CollectionCRUD<HomePageTab>,
    comms: CollectionCRUD<HomePageComms>,
    notes: CollectionCRUD<HomePageNote>,
  };

  public help: {
    docs: CollectionCRUD<H_Doc>,
    faqs: CollectionCRUD<H_FAQ>,
  };

  public timeManagement: {
    timeRecords: CollectionCRUD<TM_TimeRecord>,
    timerSessions: CollectionCRUD<TM_TimerSession>,
    teams: CollectionCRUD<TM_Team>,
    teamAsc: CollectionCRUD<TM_TeamAsc>,
    projects: CollectionCRUD<TM_Project>,
    projectAscs: CollectionCRUD<TM_ProjectAsc>,
    projectTasks: CollectionCRUD<TM_ProjectTask>,
    projectHourPlans: CollectionCRUD<TM_ProjectHourPlan>,
    clients: CollectionCRUD<TM_Client>,
    tags: CollectionCRUD<TM_Tag>,
  };

  public workflow: {
    groups: CollectionCRUD<WF_Group>,
    groupAsc: CollectionCRUD<WF_GroupAsc>,
  };

  public organizationalStructure: {
    companies: CollectionCRUD<OS_Company>,
    businessPlaces: CollectionCRUD<OS_BusinessPlace>,
    calendars: CollectionCRUD<OS_Calendar>,
  };

  public serviceManagement: {
    documents: {
      documents: CollectionCRUD<SM_Document>,
      docActivities: CollectionCRUD<SM_DocActivity>,
      docAdditionalInfos: CollectionCRUD<SM_DocAdditionalInfo>,
      docAttachments: CollectionCRUD<SM_DocAttachment>,
      docSLAs: CollectionCRUD<SM_DocSLA>,
    }
    teams: CollectionCRUD<SM_Team>,
    teamAsc: CollectionCRUD<SM_TeamAsc>,
    resolutionCauses: CollectionCRUD<SM_ResolutionCause>,
    resolutions: CollectionCRUD<SM_Resolution>,
    serviceCatalog: {
      categories: CollectionCRUD<SM_Category>,
      subcategories: CollectionCRUD<SM_Subcategory>,
      businessRoles: CollectionCRUD<SM_BusinessRole>,
      customFields: CollectionCRUD<SM_CustomField>,
      customFieldAsc: CollectionCRUD<SM_CustomFieldAsc>,
      slaDefinitions: CollectionCRUD<SM_SLADefinition>,
    },
  };

  public projectManagement: {
    kanban: {
      boardModels: CollectionCRUD<PM_BoardModel>,
      boards: CollectionCRUD<PM_Board>,
      routes: CollectionCRUD<PM_BoardRoute>,
      labels: CollectionCRUD<PM_BoardLabel>,
      cards: CollectionCRUD<PM_Card>,
      boardActivities: CollectionCRUD<PM_BoardActivity>,
      cardAttachments: CollectionCRUD<PM_CardAttachment>,
      boardSnapshots: CollectionCRUD<PM_BoardSnapshot>,
      cardSnapshots: CollectionCRUD<PM_CardSnapshot>
    },
    toDoLists: CollectionCRUD<PM_ToDoList>,
    toDos: CollectionCRUD<PM_ToDo>,
  };

  public workspaces: {
    workspaces: CollectionCRUD<WS_Workspace>,
    associations: CollectionCRUD<WS_Association>
    roles: CollectionCRUD<WS_Role>,
    clients: CollectionCRUD<WS_Client>,
    tags: CollectionCRUD<WS_Tag>,
    bookmarks: CollectionCRUD<WS_Bookmark>,
    team: CollectionCRUD<WS_Team>,
    teamAssociations: CollectionCRUD<WS_TeamAssociation>,
    portifolios: CollectionCRUD<WS_Portifolio>,
  };

  private numberPadding(number: number, size: number): string {
    var s = number.toString();
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }
  public createTimeId(): string {
    var date = new Date();
    var components = [
      date.getFullYear(),
      this.numberPadding(date.getMonth(), 2),
      this.numberPadding(date.getDate(), 2),
      this.numberPadding(date.getHours(), 2),
      this.numberPadding(date.getMinutes(), 2),
      this.numberPadding(date.getSeconds(), 2),
      this.numberPadding(date.getMilliseconds(), 3),
    ];
    return components.join('');
  }
  public createId(): string { return this.afs.createId() };
  public createFriendlyId(): string {
    let d = new Date().getTime();
    const key = 'Ixxx-Cxxxx-xyy'.replace(/[xy]/g, (c) => {
      if (c === 'x') {
        // c === 'x' -> charSet
        const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const r = Math.floor((d + Math.random() * charSet.length) % charSet.length);
        d = Math.floor(d / charSet.length);
        return charSet.charAt(r);
      }
      if (c === 'y') {
        // c === 'y' -> 0-9
        const r = Math.floor((d + Math.random() * 10) % 10);
        d = Math.floor(d / 10);
        return r.toString();
      }
    });
    return key;
  };
  public createKey(): string {
    let d = new Date().getTime();
    const key = 'xxxxxxxx-Ixxxx-Cxxxx-xxxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return key;
  };
  public object_assign(object: {}): {} {
    // Verifica se o objeto é válido
    if (object === null || typeof object !== 'object')
      return object; // Retorna o valor original se não for um objeto

    let newObject = {};
    Object.entries(object).forEach(([key, value]) => {
      let newValue = value;

      if (value !== null && typeof value === 'object') {
        if (Array.isArray(value)) {
          // Mapeia os valores do array
          newValue = value.map(v => this.object_assign(v));
        } else {
          // Chama recursivamente para objetos
          newValue = this.object_assign(value);
        }
      }

      newObject[key] = newValue;
    });

    return newObject; // Retorna o novo objeto
  }
  public runTransaction<T>(updateFunction: (transaction: firebase.firestore.Transaction) => Promise<T>): Promise<T> { return this.afs.firestore.runTransaction(updateFunction) };

  public fieldValue = {
    serverTimestamp(): FieldValue { return firebase.firestore.FieldValue.serverTimestamp() },
    arrayRemove(...elements: any[]): FieldValue { return firebase.firestore.FieldValue.arrayRemove(...elements) },
    arrayUnion(...elements: any[]): FieldValue { return firebase.firestore.FieldValue.arrayUnion(...elements) },
    increment(n: number): FieldValue { return firebase.firestore.FieldValue.increment(n) },
  }

  constructor(
    public afs: AngularFirestore,
    private aff: FireFunctionsService
  ) {

    this.sys = {
      sysPages: new CollectionCRUD<SysPage>(this.afs, this.COLLECTIONS.sys.sysPages, 'id'),
      variables: new CollectionCRUD<S_A_Variable>(this.afs, this.COLLECTIONS.sys.variables, 'createdOn'),
      users: new CollectionCRUD<User>(this.afs, this.COLLECTIONS.sys.users, 'uName'),
      deviceTokens: new CollectionCRUD<DeviceToken>(this.afs, this.COLLECTIONS.sys.deviceTokens, 'lastSeen'),
      notifications: new CollectionCRUD<Notification>(this.afs, this.COLLECTIONS.sys.notifications, 'createdOn'),
      reportPreferences: new CollectionCRUD<ReportPreferences>(this.afs, this.COLLECTIONS.sys.reportPreferences, 'createdOn'),
      logs: new CollectionCRUD<Log>(this.afs, this.COLLECTIONS.sys.logs, 'createdOn'),
      db: {
        dataFields: new CollectionCRUD<DB_DataField>(this.afs, this.COLLECTIONS.sys.db.dataFields, 'id'),
        dataFieldTexts: new CollectionCRUD<DB_DataFieldText>(this.afs, this.COLLECTIONS.sys.db.dataFieldTexts, 'id'),
        dataSources: new CollectionCRUD<DB_DataSource>(this.afs, this.COLLECTIONS.sys.db.dataSources, 'id'),
        reports: new CollectionCRUD<DB_Report>(this.afs, this.COLLECTIONS.sys.db.reports, 'createdOn'),
      },
      accessControl: {
        authorizationProvisioning: {
          roles: new CollectionCRUD<Role>(this.afs, this.COLLECTIONS.sys.accessControl.authorizationProvisioning.roles, 'id'),
          roleUsers: new CollectionCRUD<RoleUser>(this.afs, this.COLLECTIONS.sys.accessControl.authorizationProvisioning.roleUsers, 'roleId'),
        }
      },
    };

    this.tools = {
      etlScripts: new CollectionCRUD<TL_EtlScript>(this.afs, this.COLLECTIONS.tools.etlScripts, 'id'),
    };

    this.home = {
      items: new CollectionCRUD<HomePageItem>(this.afs, this.COLLECTIONS.home.items, 'createdOn'),
      tabs: new CollectionCRUD<HomePageTab>(this.afs, this.COLLECTIONS.home.tabs, 'position'),
      comms: new CollectionCRUD<HomePageComms>(this.afs, this.COLLECTIONS.home.comms, 'position'),
      notes: new CollectionCRUD<HomePageNote>(this.afs, this.COLLECTIONS.home.notes, 'createdOn'),
    };

    this.help = {
      docs: new CollectionCRUD<H_Doc>(this.afs, this.COLLECTIONS.help.docs, 'createdOn'),
      faqs: new CollectionCRUD<H_FAQ>(this.afs, this.COLLECTIONS.help.faqs, 'createdOn'),
    };

    this.timeManagement = {
      timeRecords: new CollectionCRUD<TM_TimeRecord>(this.afs, this.COLLECTIONS.timeManagement.timeRecords, 'createdOn'),
      timerSessions: new CollectionCRUD<TM_TimerSession>(this.afs, this.COLLECTIONS.timeManagement.timerSessions, 'createdOn'),
      teams: new CollectionCRUD<TM_Team>(this.afs, this.COLLECTIONS.timeManagement.teams, 'id'),
      teamAsc: new CollectionCRUD<TM_TeamAsc>(this.afs, this.COLLECTIONS.timeManagement.teamAscs, 'createdOn'),
      projects: new CollectionCRUD<TM_Project>(this.afs, this.COLLECTIONS.timeManagement.projects, 'id'),
      projectAscs: new CollectionCRUD<TM_ProjectAsc>(this.afs, this.COLLECTIONS.timeManagement.projectAscs, 'createdOn'),
      projectTasks: new CollectionCRUD<TM_ProjectTask>(this.afs, this.COLLECTIONS.timeManagement.projectTask, 'id'),
      projectHourPlans: new CollectionCRUD<TM_ProjectHourPlan>(this.afs, this.COLLECTIONS.timeManagement.projectHourPlan, ['month', 'taskId']),
      clients: new CollectionCRUD<TM_Client>(this.afs, this.COLLECTIONS.timeManagement.clients, 'id'),
      tags: new CollectionCRUD<TM_Tag>(this.afs, this.COLLECTIONS.timeManagement.tags, 'id'),
    };

    this.workflow = {
      groups: new CollectionCRUD<WF_Group>(this.afs, this.COLLECTIONS.workflow.groups, 'id'),
      groupAsc: new CollectionCRUD<WF_GroupAsc>(this.afs, this.COLLECTIONS.workflow.groupAscs, 'createdOn'),
    };

    this.organizationalStructure = {
      companies: new CollectionCRUD<OS_Company>(this.afs, this.COLLECTIONS.organizationalStructure.companies, 'createdOn'),
      businessPlaces: new CollectionCRUD<OS_BusinessPlace>(this.afs, this.COLLECTIONS.organizationalStructure.businessPlaces, ['companyId', 'createdOn']),
      calendars: new CollectionCRUD<OS_Calendar>(this.afs, this.COLLECTIONS.organizationalStructure.calendars, 'createdOn'),
    };

    this.serviceManagement = {
      documents: {
        documents: new CollectionCRUD<SM_Document>(this.afs, this.COLLECTIONS.serviceManagement.documents.documents, 'createdOn'),
        docActivities: new CollectionCRUD<SM_DocActivity>(this.afs, this.COLLECTIONS.serviceManagement.documents.docActivities, 'createdOn'),
        docAdditionalInfos: new CollectionCRUD<SM_DocAdditionalInfo>(this.afs, this.COLLECTIONS.serviceManagement.documents.docAdditionalInfos, 'createdOn'),
        docAttachments: new CollectionCRUD<SM_DocAttachment>(this.afs, this.COLLECTIONS.serviceManagement.documents.docAttachments, 'createdOn'),
        docSLAs: new CollectionCRUD<SM_DocSLA>(this.afs, this.COLLECTIONS.serviceManagement.documents.docSLAs, 'createdOn'),
      },
      teams: new CollectionCRUD<SM_Team>(this.afs, this.COLLECTIONS.serviceManagement.teams, 'name'),
      teamAsc: new CollectionCRUD<SM_TeamAsc>(this.afs, this.COLLECTIONS.serviceManagement.teamAscs, 'createdOn'),
      resolutionCauses: new CollectionCRUD<SM_ResolutionCause>(this.afs, this.COLLECTIONS.serviceManagement.resolutionCauses, 'id'),
      resolutions: new CollectionCRUD<SM_Resolution>(this.afs, this.COLLECTIONS.serviceManagement.resolutions, 'id'),
      serviceCatalog: {
        categories: new CollectionCRUD<SM_Category>(this.afs, this.COLLECTIONS.serviceManagement.serviceCatalog.categories, 'name'),
        subcategories: new CollectionCRUD<SM_Subcategory>(this.afs, this.COLLECTIONS.serviceManagement.serviceCatalog.subcategories, ['categoryId', 'name', 'createdOn']),
        businessRoles: new CollectionCRUD<SM_BusinessRole>(this.afs, this.COLLECTIONS.serviceManagement.serviceCatalog.businessRoles, ['subcategoryId', 'name', 'createdOn']),
        customFields: new CollectionCRUD<SM_CustomField>(this.afs, this.COLLECTIONS.serviceManagement.serviceCatalog.customFields, 'name'),
        customFieldAsc: new CollectionCRUD<SM_CustomFieldAsc>(this.afs, this.COLLECTIONS.serviceManagement.serviceCatalog.customFieldAscs, 'createdOn'),
        slaDefinitions: new CollectionCRUD<SM_SLADefinition>(this.afs, this.COLLECTIONS.serviceManagement.serviceCatalog.slaDefinitions, 'createdOn'),
      }
    };

    this.projectManagement = {
      kanban: {
        boardModels: new CollectionCRUD<PM_BoardModel>(this.afs, this.COLLECTIONS.projectManagement.kanban.boardModels, 'position'),
        boards: new CollectionCRUD<PM_Board>(this.afs, this.COLLECTIONS.projectManagement.kanban.boards, 'createdOn'),
        routes: new CollectionCRUD<PM_BoardRoute>(this.afs, this.COLLECTIONS.projectManagement.kanban.routes, 'createdOn'),
        labels: new CollectionCRUD<PM_BoardLabel>(this.afs, this.COLLECTIONS.projectManagement.kanban.labels, 'createdOn'),
        cards: new CollectionCRUD<PM_Card>(this.afs, this.COLLECTIONS.projectManagement.kanban.cards, 'createdOn'),
        boardActivities: new CollectionCRUD<PM_BoardActivity>(this.afs, this.COLLECTIONS.projectManagement.kanban.boardActivities, 'createdOn'),
        cardAttachments: new CollectionCRUD<PM_CardAttachment>(this.afs, this.COLLECTIONS.projectManagement.kanban.cardAttachments, 'createdOn'),
        boardSnapshots: new CollectionCRUD<PM_BoardSnapshot>(this.afs, this.COLLECTIONS.projectManagement.kanban.boardSnapshots, 'time'),
        cardSnapshots: new CollectionCRUD<PM_CardSnapshot>(this.afs, this.COLLECTIONS.projectManagement.kanban.cardSnapshots, 'time')
      },
      toDoLists: new CollectionCRUD<PM_ToDoList>(this.afs, this.COLLECTIONS.projectManagement.toDoLists, 'createdOn'),
      toDos: new CollectionCRUD<PM_ToDo>(this.afs, this.COLLECTIONS.projectManagement.toDos, 'createdOn'),
    };

    this.workspaces = {
      workspaces: new CollectionCRUD<WS_Workspace>(this.afs, this.COLLECTIONS.workspaces.workspaces, 'createdOn'),
      associations: new CollectionCRUD<WS_Association>(this.afs, this.COLLECTIONS.workspaces.associations, 'createdOn'),
      roles: new CollectionCRUD<WS_Role>(this.afs, this.COLLECTIONS.workspaces.roles, 'createdOn'),
      clients: new CollectionCRUD<WS_Client>(this.afs, this.COLLECTIONS.workspaces.clients, 'createdOn'),
      tags: new CollectionCRUD<WS_Tag>(this.afs, this.COLLECTIONS.workspaces.tags, 'createdOn'),
      bookmarks: new CollectionCRUD<WS_Bookmark>(this.afs, this.COLLECTIONS.workspaces.bookmarks, 'createdOn'),
      team: new CollectionCRUD<WS_Team>(this.afs, this.COLLECTIONS.workspaces.team, 'createdOn'),
      teamAssociations: new CollectionCRUD<WS_TeamAssociation>(this.afs, this.COLLECTIONS.workspaces.teamAssociations, 'createdOn'),
      portifolios: new CollectionCRUD<WS_Portifolio>(this.afs, this.COLLECTIONS.workspaces.portifolios, 'createdOn'),
    };
  }
}
