import { Location } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AuthService, UtilService } from 'app/@core';
import { FirestoreService, RoleUser } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

declare var jQuery: any;

@Component({
  selector: 'app-role-user-modal',
  templateUrl: './role-user-modal.component.html',
  styleUrls: ['./role-user-modal.component.scss']
})
export class RoleUserModalComponent implements OnInit {

  public loader = true;
  className = 'RoleUserModalComponent';

  modalId = '';
  localRoleUser = new RoleUser();
  userFullName = '';
  openEditing = false;
  canEdit = false;
  mode = '';

  tempRoleUserDataBackup = new RoleUser();
  oldRoleUser = new RoleUser();
  editing = false;
  admin = false;


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private mdDialogRef: MatDialogRef<RoleUserModalComponent>,

    public uiFeedBackCtrl: UiFeedBackService,
    public db: FirestoreService,
    private authService: AuthService,
    public router: Router,
    private _location: Location,
    public utilCtrl: UtilService,
  ) {
    this.modalId = this.data.modalId;

    this.localRoleUser = this.data.ru != undefined ? JSON.parse(JSON.stringify(this.data.ru)) : new RoleUser();
    this.userFullName = this.data.ufn != undefined ? this.data.ufn : '-';
    this.openEditing = this.data.edit != undefined ? this.data.edit : false;
    this.canEdit = this.data.canEdit != undefined ? this.data.canEdit : false;
    this.mode = this.data.mode != undefined ? this.data.mode : 'display';

    (function ($) {
      $(document).ready(function () {
        $('.mat-dialog-container').css("width", "600px");
      });
    })(jQuery);
  }


  ngOnInit() {
    if (this.openEditing != undefined)
      this.editing = this.openEditing;

    this.loader = false;

    this.oldRoleUser = JSON.parse(JSON.stringify(this.localRoleUser));
    this.tempRoleUserDataBackup = JSON.parse(JSON.stringify(this.localRoleUser));

    this.authService.auth_check(
      [
        {
          object: 'ROLE_USERS',
          authPairs: [
            {
              field: 'ROLES', value: this.localRoleUser.roleId
            },
            {
              field: 'ACTV', value: '02'
            }
          ]
        },
      ]
    )
      .then((failed_auths) => {

        if (failed_auths.length > 0) {
          this.admin = false
        } else {
          this.admin = true;
        }

      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })

  }

  checkForChanges(): boolean {
    if (
      this.localRoleUser.from != this.oldRoleUser.from ||
      this.localRoleUser.to != this.oldRoleUser.to
    )
      return true;
    else
      return false;
  }
  toggleEditing() {
    if (this.editing) {
      this.editing = false;
      this.localRoleUser = JSON.parse(JSON.stringify(this.tempRoleUserDataBackup));
    } else {
      this.editing = true;
      this.tempRoleUserDataBackup = JSON.parse(JSON.stringify(this.localRoleUser));
    }
  }
  save() {
    this.mdDialogRef.close({
      op: 'save',
      ru: this.localRoleUser
    })
  }

}
