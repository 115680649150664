import { DbTableColumn, DbTableRowData } from "../db-table.types";
import { dbTableConvertFilterValue } from "./db-table-convert-filter-value";

export function dbTableFilterValueDetermination(row: DbTableRowData, c_index: number, col: DbTableColumn): string {
    let fValue = col.canChange ? dbTableConvertFilterValue(row.cellsData[c_index]) : dbTableConvertFilterValue(row.cells[c_index]);
    if (col.canChange && col.input && col.input.type == 'select') {
        let tempSelValue = '';
        for (let so of col.input.selectOptions.optionsData) {
            if (row.cellsData[c_index] == col.input.selectOptions.optionValue(so)) {
                tempSelValue = col.input.selectOptions.optionName(so);
                break;
            }
        }
        fValue = dbTableConvertFilterValue(tempSelValue);
    }
    return fValue;
}