export type PmCardModalSideMode = 'details' | 'assignees' | 'labels' | 'attachments' | 'time-records';

/**
 * for status use only: "basic" | "primary" | "success" | "info" | "warning" | "danger";
 */
export const PM_CARD_SIDE_MODES:
    {
        id: PmCardModalSideMode;
        title: string;
        status: string;
        icon: string;
        iconStatus?: string;
    }[]
    = [
        {
            id: 'details',
            title: 'Detalhes',
            status: 'basic',
            icon: 'list-outline',
        },

        {
            id: 'assignees',
            title: 'Designados',
            status: 'basic',
            icon: 'people-outline',
        },

        {
            id: 'labels',
            title: 'Rótulos',
            status: 'basic',
            icon: 'bookmark-outline',
        },

        {
            id: 'attachments',
            title: 'Anexos',
            status: 'basic',
            icon: 'attach-outline',
        },

        {
            id: 'time-records',
            title: 'Horas',
            status: 'basic',
            icon: 'clock-outline',
            iconStatus: 'primary',
        },
    ];