import { Injectable } from '@angular/core';
import { deleteToken, getMessaging, getToken, MessagePayload, onMessage } from 'firebase/messaging';
import { Observable, Subscriber } from "rxjs";

@Injectable()
export class FireMessagingService {

    public token: string = "";
    public lastMessage: MessagePayload | null = null;

    private messaging = getMessaging();
    private _onMessageSub: any = null;
    private _internalOnMessageSub: Subscriber<MessagePayload> = null;

    constructor() { }
    public getToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            getToken(this.messaging)
                .then((token) => {
                    this.token = token;
                    console.log('[FireMessagingService] Token: ', token);
                    this._open_onMessageSub();
                    resolve(token);
                })
                .catch((e) => {
                    console.error('[FireMessagingService] Unable to get permission to notify.', e);
                    reject(e);
                });
        });
    }
    public deleteToken(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            deleteToken(this.messaging)
                .then((deleted) => {
                    console.log('[FireMessagingService] deleted: ', deleted);
                    this._close_onMessageSub();
                    resolve(deleted);
                })
                .catch((e) => {
                    console.error('[FireMessagingService] Unable to delete Token.', e);
                    reject(e);
                });
        });
    }
    private _open_onMessageSub(): Promise<void> {
        return new Promise((resolve) => {
            this._onMessageSub = onMessage(
                this.messaging,
                (payload) => {
                    this.lastMessage = payload;
                    console.log('[FireMessagingService] OnMessage Payload: ', payload);
                    if (this._internalOnMessageSub)
                        this._internalOnMessageSub.next(payload);
                    resolve();
                }
            );
        })
    }
    private _close_onMessageSub(): Promise<void> {
        return new Promise((resolve) => {
            if (this._onMessageSub != null) {
                this._onMessageSub();
                this._onMessageSub = null;
            }
            resolve();
        })
    }
    public onMessage(): Observable<MessagePayload> {
        return new Observable<MessagePayload>(subscriber => {
            // Keep track of the Documents Changes
            this._internalOnMessageSub = subscriber;

            // Provide a way of canceling and disposing the interval resource
            return function unsubscribe() {
                this._internalOnMessageSub = null;
            };
        });
    }
}
