import { Component, ElementRef, HostListener, Inject, LOCALE_ID, OnInit, Optional, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AuthService, KeybindsService, UtilService } from 'app/@core';
import { FireStorageService, FirestoreService, PM_BoardActivity, TM_TimeRecord, User } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';
import { WeekInputComponent } from 'app/@theme/shared-components/week-input/week-input.component';

import { TimerListTabComponent } from '../timer/timer-list-tab/timer-list-tab.component';
import { TM_IntegrationData } from '../timer/timer-list-tab/TM_IntegrationData';
import { _endSession, initSession } from '../timer/timer-session-utils';
import { TIMER_PAGE_DEFINITION, TimerModeType } from '../timer/timer.component';

export class ActivityDataType {
  activity: PM_BoardActivity;
  user: User;
  public constructor(init?: Partial<ActivityDataType>) {
    this.activity = new PM_BoardActivity();
    this.user = new User();
    if (init)
      Object.assign(this, init);
  }
}

@Component({
  selector: 'app-timer-modal',
  templateUrl: './timer-modal.component.html',
  styleUrls: ['./timer-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TmTimerModalComponent implements OnInit {
  className = 'TmTimerModalComponent';
  _loader = true;

  // ****************************************
  // Global Variables
  // ****************************************

  // Selection
  mode: TimerModeType = 'week';
  modes = [
    { id: 'today', title: 'Hoje' },
    { id: 'week', title: 'Semana' },
    // { id: 'month', title: 'Mês' },
    // { id: 'manual', title: 'Manual' }
  ];
  months = [
    { id: 1, name: 'Janeiro' },
    { id: 2, name: 'Fevereiro' },
    { id: 3, name: 'Março' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Maio' },
    { id: 6, name: 'Junho' },
    { id: 7, name: 'Julho' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Setembro' },
    { id: 10, name: 'Outubro' },
    { id: 11, name: 'Novembro' },
    { id: 12, name: 'Dezembro' },
  ];

  dateWeek_inputVal: string;

  @ViewChild('Week_input_REF') week_input: WeekInputComponent = new WeekInputComponent(this.locale, this.platformId);

  // Tabs
  @ViewChild('Timer_list_tab_REF') timer_list_tab: TimerListTabComponent = new TimerListTabComponent(this.db, this.authService, this.uiFeedBackCtrl, this.utilCtrl, this.keybindsCtrl);
  // Tabs

  date: Date = new Date();

  dateWeek: number;
  dateMonth: number;
  dateYear: number;
  dateDay: number;

  datePeriodFrom_inputVal = ``;
  datePeriodTo_inputVal = ``;

  datePeriodFrom: Date = new Date();
  datePeriodTo: Date = new Date();
  // Selection

  localTimeRecords: TM_TimeRecord[] = [];

  canEdit = false;
  showOnlyRelated = true;

  errorMessage = '';

  integrationActivate = false;
  integrationData: TM_IntegrationData = new TM_IntegrationData();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {},
    @Inject(LOCALE_ID) public locale,
    @Inject(PLATFORM_ID) public platformId,
    private mdDialogRef: MatDialogRef<TmTimerModalComponent>,
    public db: FirestoreService,
    public storage: FireStorageService,
    private authService: AuthService,
    public utilCtrl: UtilService,
    public uiFeedBackCtrl: UiFeedBackService,
    private keybindsCtrl: KeybindsService
  ) {
    if (data) {
      if (data['integrationActivate'])
        this.integrationActivate = data['integrationActivate'];

      if (data['integrationData'])
        this.integrationData = new TM_IntegrationData(data['integrationData']);
    }
  }

  ngOnInit() {
    this._loader = true;

    this.authService.auth_check([
      {
        object: 'P_ID',
        authPairs: [
          {
            field: 'P_ID', value: TIMER_PAGE_DEFINITION.id
          }
        ]
      },
    ])
      .then((failed_auths) => {

        if (failed_auths.length > 0) {
          this.uiFeedBackCtrl.presentAlert('Acessos insuficientes!', `Voce não possui acessos suficientes para acessar esta pagina`, 'warning');
        } else {

          setTimeout(() => {
            this.loadPage();
          }, 500);

        }

      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }
  ngOnDestroy() {
    _endSession(this.db, this.utilCtrl);
  }
  safeClose() {
    if (this.timer_list_tab.hasChanges) {
      this.uiFeedBackCtrl.presentCustonAlert({
        title: 'Sair Sem Salvar?',
        message: `Atenção: Voce possui mudanças não salvas. Presione Cancelar para voltar e salvar estas mudanças, ou OK para sair sem salvar.`,
        buttons: [
          {
            text: 'Cancelar',
            icon: "close-outline",
            status: "primary",
          },
          {
            needFormValid: true,
            text: 'Sair',
            icon: "log-out",
            status: "danger",
            handler: () => {
              this._close();
            }
          }
        ]
      })
    } else
      this._close();
  }
  _close(data?) {
    this.mdDialogRef.close(data)
  }

  // Host Listeners
  @HostListener('window:keydown.esc', ['$event'])
  onEsc() {
    this.safeClose();
  }


  // ---------------------- UI
  loadPage() {
    this._loader = true;

    this.date = new Date();
    this.week_input.useActualWeek();

    if (this.integrationActivate)
      this.timer_list_tab.activateIntegration(this.integrationData)

    this._loader = false;
  }
  updateDatePeriodFrom() {
    this.datePeriodFrom = this.utilCtrl.date.getFromDateObj(this.datePeriodFrom_inputVal);
    this.loadData();
  }
  updateDatePeriodTo() {
    this.datePeriodTo = this.utilCtrl.date.getToDateObj(this.datePeriodTo_inputVal);
    this.loadData();
  }
  /**
   * @param {int} The month number, 0 based
   * @param {int} The year, not zero based, required to account for leap years
   * @return {Date[]} List with date objects for each day of the month
   */
  private getDaysInMonth(month: number, year: number) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }
  changeMode() {
    this.changePeriod();
  }
  changePeriod() {
    if (this.mode == 'today') {
      this.datePeriodFrom = new Date();
      this.datePeriodFrom.setHours(0, 0, 0, 0);
      this.datePeriodTo = new Date()
      this.datePeriodTo.setHours(23, 59, 59, 0);
      this.datePeriodTo_inputVal = this.utilCtrl.date.getDateStr(this.datePeriodTo);
      this.datePeriodFrom_inputVal = this.utilCtrl.date.getDateStr(this.datePeriodFrom);
    } else if (this.mode == 'week') {
      this.datePeriodFrom = this.week_input.weekStartDate;
      this.datePeriodTo = this.week_input.weekEndDate;
      this.datePeriodTo_inputVal = this.utilCtrl.date.getDateStr(this.datePeriodTo);
      this.datePeriodFrom_inputVal = this.utilCtrl.date.getDateStr(this.datePeriodFrom);
    } else if (this.mode == 'month') {
      let days = this.getDaysInMonth(this.dateMonth - 1, this.dateYear);
      this.datePeriodFrom = days[0];
      this.datePeriodTo = days[days.length - 1];
      this.datePeriodTo_inputVal = this.utilCtrl.date.getDateStr(this.datePeriodTo);
      this.datePeriodFrom_inputVal = this.utilCtrl.date.getDateStr(this.datePeriodFrom);
    }
    this.loadData();
  }
  // ---------------------- UI

  loadData() {

    if (!this.utilCtrl.date.dateValid(this.datePeriodFrom)) {
      this.uiFeedBackCtrl.presentAlert(`Data "De" invalida!`, `Favor verificar a data inserida!`, `warning`)
      return;
    }

    if (!this.utilCtrl.date.dateValid(this.datePeriodTo)) {
      this.uiFeedBackCtrl.presentAlert(`Data "Até" invalida!`, `Favor verificar a data inserida!`, `warning`)
      return;
    }

    if (this.mode == 'manual' && (this.datePeriodFrom.getTime() > this.datePeriodTo.getTime())) {
      this.uiFeedBackCtrl.presentAlert(`Datas invertidas!`, `Favor verificar as datas inseridas!`, `warning`)
      return;
    }

    this._loader = true;
    this.localTimeRecords = [];
    this.authService.getUName()
      .then((uName) => {
        return this.db.afs.collection(this.db.COLLECTIONS.timeManagement.timeRecords)
          .ref

          .where(`uName`, `==`, uName)
          .where(`date`, `>=`, this.utilCtrl.timestamp.fromDate(this.datePeriodFrom))
          .where(`date`, `<=`, this.utilCtrl.timestamp.fromDate(this.datePeriodTo))

          .orderBy(`date`)
          .get({ source: 'server' })
          .then(q => {
            for (let d of q.docs) {
              this.localTimeRecords.push(d.data() as TM_TimeRecord);
            }
            this.timer_list_tab.load(uName);
            this._loader = false;
            this.updatEditSession();
          })
      })
      .catch((e) => {
        this._loader = false;
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }
  updatEditSession() {
    this._loader = true;
    this.canEdit = false;
    this.errorMessage = '';
    initSession(this.db, this.utilCtrl, this.authService.localUser, { uName: this.authService.localUser.uName, dateFromTime: this.datePeriodFrom.getTime(), dateToTime: this.datePeriodTo.getTime() })
      .then(ts => {
        this._loader = false;
        this.canEdit = true;
      })
      .catch((e) => {
        this._loader = false;
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
        if (e.code == 423) {
          this.errorMessage = e.message;
        }
      })
  }
  changeTimeRecords(event) {
    // this.timer_dashboard_tab.load(this.localTimeRecords, this.datePeriodFrom, this.datePeriodTo);
  }


}
