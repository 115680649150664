import firebase from "firebase/compat/app";

export class AuthObjectField {

  id: string;
  description: string;
  values: AuthObjectFieldValue[];

  valuesFromColection: boolean;
  valuesColection: string;
  valuesField: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<AuthObjectField>) {

    this.id = '';
    this.description = '';
    this.values = [];

    this.valuesFromColection = false;
    this.valuesColection = '';
    this.valuesField = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}


export class AuthObjectFieldValue {
  value: string;
  description: string;
  public constructor(init?: Partial<AuthObjectFieldValue>) {
    this.value = '';
    this.description = '';
    if (init)
      Object.assign(this, init);
  }
}
