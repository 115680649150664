import { EventEmitter, Injectable } from '@angular/core';

export class CookiesType {
  id: string;
  name: string;
  description: string;
  public constructor(init?: Partial<CookiesType>) {
    this.id = '';
    this.name = '';
    this.description = '';
    if (init)
      Object.assign(this, init);
  }
}
export class CookiesSupplier {
  name: string;
  description: string;
  link: string;
  public constructor(init?: Partial<CookiesSupplier>) {
    this.name = '';
    this.description = '';
    this.link = '';
    if (init)
      Object.assign(this, init);
  }
}
export class Cookies {
  name: string;
  typeId: string;
  description: string;
  supplier: CookiesSupplier;
  validity: string;
  type: string;
  public constructor(init?: Partial<Cookies>) {
    this.name = '';
    this.typeId = '';
    this.description = '';
    this.supplier = new CookiesSupplier();
    this.validity = '';
    this.type = '';
    if (init)
      Object.assign(this, init);
  }
}
export class CookiesConsent {
  id: string;
  time: number;
  auths: string[];
  public constructor(init?: Partial<CookiesConsent>) {
    this.id = '';
    this.time = null;
    this.auths = ['necessary'];
    if (init)
      Object.assign(this, init);
  }
}

@Injectable()
export class CookiesService {

  private initialized = false;
  private localStorage = window.localStorage;
  private cookiesConsent: CookiesConsent = new CookiesConsent();
  public onCookiesConsentChange: EventEmitter<CookiesConsent> = new EventEmitter<CookiesConsent>();

  public cookiesSuppliers: CookiesSupplier[] = [
    { name: 'Tee', description: 'Política de Privacidade da Tee', link: 'https://lojatee.store/#/store/privacy-policy' },
    { name: 'Google', description: 'Política de privacidade do Google Tag Manager', link: 'https://policies.google.com/privacy' },
    { name: 'UOL - PagSeguro', description: 'POLÍTICA DE PRIVACIDADE GLOBAL – GRUPO UOL', link: 'https://sobreuol.noticias.uol.com.br/normas-de-seguranca-e-privacidade' },
  ];
  public cookies: Cookies[] = [
    //Tee
    { name: 'cookiesConsent', typeId: 'necessary', supplier: this.cookiesSuppliers[0], description: `Armazena o estado de consentimento para cookies do usuário para o domínio atual.`, validity: '1 ano', type: 'HTML Local Storage' },
    { name: 'Offline_DeltaBS', typeId: 'necessary', supplier: this.cookiesSuppliers[0], description: `Armazena os itens da sacola do usuário localmente.`, validity: 'Persistente', type: 'HTML Local Storage' },
    { name: 'T', typeId: 'necessary', supplier: this.cookiesSuppliers[0], description: `Usado para controlar as solicitações ao servidor.`, validity: '1 dia', type: 'HTML Local Storage' },
    { name: 'ProductView_statistics', typeId: 'statistics', supplier: this.cookiesSuppliers[0], description: `Usado para registrar anonimamente a visualização dos produtos.`, validity: 'Persistente', type: 'HTTP' },
    { name: 'ProductView_advertising', typeId: 'advertising', supplier: this.cookiesSuppliers[0], description: `Usado para registrar nominalmente a visualização dos produtos.`, validity: 'Persistente', type: 'HTTP' },
    //Tee

    //Google
    { name: 'firebaseLocalStorageDb', typeId: 'necessary', supplier: this.cookiesSuppliers[1], description: `Usado para controlar pelo mecanismo de autentição da google auth.`, validity: 'Persistente', type: 'HTML Local Storage' },
    { name: '__utm.gif', typeId: 'necessary', supplier: this.cookiesSuppliers[1], description: `Google Analytics Tracking Code that logs details about the visitor's browser and computer.`, validity: 'Session', type: 'Pixel Tracker' },
    { name: '_ga', typeId: 'necessary', supplier: this.cookiesSuppliers[1], description: `Registers a unique ID that is used to generate statistical data on how the visitor uses the website.`, validity: '2 ano', type: 'HTTP' },
    { name: '_ga_#', typeId: 'necessary', supplier: this.cookiesSuppliers[1], description: `Used by Google Analytics to collect data on the number of times a user has visited the website as well as dates for the first and most recent visit.`, validity: '2 ano', type: 'HTTP' },
    { name: '_gat', typeId: 'necessary', supplier: this.cookiesSuppliers[1], description: `Used by Google Analytics to throttle request rate.`, validity: '1 dia', type: 'HTTP' },
    { name: '_gid', typeId: 'necessary', supplier: this.cookiesSuppliers[1], description: `Registers a unique ID that is used to generate statistical data on how the visitor uses the website.`, validity: '1 dia', type: 'HTTP' },
    { name: 'rc::a', typeId: 'necessary', supplier: this.cookiesSuppliers[1], description: `This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.`, validity: 'Persistent', type: 'HTML Local Storage' },
    { name: 'rc::c', typeId: 'necessary', supplier: this.cookiesSuppliers[1], description: `This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.`, validity: 'Persistent', type: 'HTML Local Storage' },
    //Google

    // UOL - PagSeguro
    { name: 'UOL_OTR', typeId: 'necessary', supplier: this.cookiesSuppliers[2], description: ``, validity: 'Session', type: 'HTTP' },
    { name: 'JSESSIONID', typeId: 'necessary', supplier: this.cookiesSuppliers[2], description: ``, validity: 'Session', type: 'HTTP' },
    { name: 'csrfId', typeId: 'necessary', supplier: this.cookiesSuppliers[2], description: ``, validity: '1 dia', type: 'HTTP' },
    { name: 'LAST_SERVER_HIT', typeId: 'necessary', supplier: this.cookiesSuppliers[2], description: ``, validity: '1 dia', type: 'HTTP' },
    { name: 'TRANSACTION_COOKIE', typeId: 'necessary', supplier: this.cookiesSuppliers[2], description: ``, validity: '15 dias', type: 'HTTP' },
    // UOL - PagSeguro
  ];
  public cookiesTypes: CookiesType[] = [
    { id: 'necessary', name: 'Necessários', description: 'Os cookies necessários ajudam a tornar um website útil, permitindo funções básicas, como a navegação e o acesso à página para proteger áreas do website. O website pode não funcionar corretamente sem estes cookies.' },
    { id: 'preference', name: 'Preferências', description: 'Os cookies de preferência permitem que um website memorize as informações que mudam o comportamento ou o aspeto do website, como o seu idioma preferido ou a região em que se você encontra.' },
    { id: 'statistics', name: 'Estatísticas', description: 'Os cookies de estatística ajudam os proprietários de websites a entenderem como os visitantes interagem com os websites, recolhendo e divulgando informações de forma anónima.' },
    { id: 'advertising', name: 'Marketing', description: 'Os cookies de marketing são utilizados ​​para seguir os visitantes pelos websites. A intenção é exibir anúncios que sejam relevantes e apelativos para o utilizador individual e, logo, mais valiosos para os editores e anunciantes independentes.' },
    { id: 'unclassified', name: 'Não classificados', description: 'Os cookies não classificados são cookies que estão em processo de classificação, juntamente com os fornecedores de cookies individuais. Não utilizamos cookies deste tipo' },
  ];

  constructor() { }
  private init(): Promise<void> {
    return new Promise((resolve) => {
      let tempStorageConset = this.readFromStorage();
      let now = new Date();
      let msInAYear = 1000 * 60 * 60 * 24 * 365;
      if (tempStorageConset && tempStorageConset.time && (now.getTime() - tempStorageConset.time < msInAYear)) {
        this.cookiesConsent = tempStorageConset;
      } else
        this.saveToStorage(this.cookiesConsent);
      this.onCookiesConsentChange.emit(this.cookiesConsent);
      this.initialized = true;
      resolve();
    })
  }
  private saveToStorage(cookiesConsent: CookiesConsent) {
    this.localStorage.setItem('cookiesConsent', JSON.stringify(cookiesConsent));
  }
  private readFromStorage(): CookiesConsent {
    let tempStorageConset = JSON.parse(this.localStorage.getItem('cookiesConsent')) as CookiesConsent;
    return tempStorageConset;
  }
  public setConset(auths: string[]) {
    let tempConset = new CookiesConsent();
    tempConset.id = this.generateUUID();
    tempConset.time = new Date().getTime();
    tempConset.auths = auths;
    if (tempConset.auths.indexOf('necessary') == -1)
      tempConset.auths.push('necessary')
    this.cookiesConsent = tempConset;
    this.saveToStorage(this.cookiesConsent);
    this.onCookiesConsentChange.emit(this.cookiesConsent);
  }
  public clearConset() {
    this.cookiesConsent = new CookiesConsent();
    this.saveToStorage(this.cookiesConsent);
    this.onCookiesConsentChange.emit(this.cookiesConsent);
  }
  private generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
  private failSafe(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.initialized) {
        resolve()
      } else {
        this.init()
          .then(() => {
            resolve(this.failSafe());
          })
          .catch(e => reject(e));
      }
    })
  }

  public hasAuth(auth: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.failSafe()
        .then(() => {
          if (this.cookiesConsent.auths.indexOf(auth) == -1)
            resolve(false);
          else
            resolve(true);
        })
        .catch(e => reject(e));
    });
  }
  public getCookiesConsent(): Promise<CookiesConsent> {
    return new Promise((resolve, reject) => {
      this.failSafe()
        .then(() => {
          resolve(this.cookiesConsent);
        })
        .catch(e => reject(e));
    });
  }


}
