import { MagicTableComponent } from "../magic-table.component";
import { EMPTY_ARRAY_DATA_VALUE, EMPTY_ARRAY_FILTER_VALUE, EMPTY_CELL_VALUE } from "../magic-table.consts";
import { FilterPopdownData, MagicTableColumn, MagicTableRowData, SortField, UiFilterIndex } from "../magic-table.types";
import { _formatToMonetary, _progressBarPercentMaker, _timeStampToLocalDate, _translateBoolean } from "./magic-table_formatters";
import { _cellDataMaker, _compareFunction, _genFilterValue, _localSort, _orderByCore } from "./magic-table.util";

// Filter

/**
* Inicia os indices dos filtros de acordo com as linhas da tabela
*/
export function _initFilterIndexes(_this: MagicTableComponent) {
    _this.filterIndexes = [];
    _this.filterActiveIndex = [];

    for (let col_index = 0; col_index < _this.table.cols.length; col_index++) {
        let tempColIndex: UiFilterIndex = [];
        let tempSingleValues = [];
        for (let r of _this.table.rows) {
            const col = _this.table.cols[col_index];
            const fData = r.obj[col.fieldName];
            const fCell = _genDispFilterValue(col, r);

            if (!Array.isArray(fData)) {
                // not array
                if (tempSingleValues.indexOf(fCell) == -1) {
                    tempSingleValues.push(fCell)
                    tempColIndex.push({ show: true, data: fData, cell: fCell as any })
                }
            } else {
                // array
                for (let i = 0; i < fData.length; i++) {
                    const fd = fData[i];
                    const fc = fCell[i];
                    if (tempSingleValues.indexOf(fc) == -1) {
                        tempSingleValues.push(fc)
                        tempColIndex.push({ show: true, data: fd, cell: fc as any })
                    }
                }
                // empty array
                if (fData.length == 0) {
                    if (tempSingleValues.indexOf(EMPTY_ARRAY_FILTER_VALUE) == -1) {
                        tempSingleValues.push(EMPTY_ARRAY_FILTER_VALUE);
                        tempColIndex.push({ show: true, data: EMPTY_ARRAY_DATA_VALUE, cell: EMPTY_ARRAY_FILTER_VALUE })
                    }
                }
            }
        }
        tempColIndex.sort((a, b) => _orderByCore(a, b, "asc"));

        _this.filterIndexes.push(tempColIndex);
        _this.filterActiveIndex.push(false);
        _this.orderByIndex.push(null);
    }
}
/**
 * Atualiza as linhas filtradas apartir das linhas da tabela filtros
 */
export function _updateFiltredRows(_this: MagicTableComponent) {
    let tempRows: MagicTableRowData[] = [];
    for (let row of _this.table.rows) {
        let tempRow = row;
        let validRow = true;
        for (let col_index = 0; col_index < _this.table.cols.length; col_index++) {
            if (_this.filterActiveIndex[col_index]) {
                validRow = _getFilterStatus(_this, col_index, tempRow.data[col_index]);
                if (!validRow)
                    break;
            }
        }
        if (validRow)
            tempRows.push(row);
    }

    // sort rows
    // -sort array inside lines
    for (let row_index = 0; row_index < tempRows.length; row_index++) {
        for (let col_index = 0; col_index < _this.table.cols.length; col_index++)
            if (_this.orderByIndex[col_index]) {
                const col = _this.table.cols[col_index];
                let fieldData = tempRows[row_index].obj[col.fieldName];
                if (Array.isArray(fieldData)) {
                    fieldData.sort((a, b) => _orderByCore(a, b, _this.orderByIndex[col_index]));
                    tempRows[row_index].cells[col_index] = _cellDataMaker(col, fieldData);
                }
            }
    }
    // -sort rows
    tempRows.sort((rowA, rowB) => {
        let result = 0;
        for (let i = 0; i < _this.orderByIndex.length; i++) {
            if (_this.orderByIndex[i]) {
                const rowAData = rowA.data[i] == EMPTY_ARRAY_DATA_VALUE ? EMPTY_CELL_VALUE : rowA.data[i];
                const rowBData = rowB.data[i] == EMPTY_ARRAY_DATA_VALUE ? EMPTY_CELL_VALUE : rowB.data[i];
                result = _orderByCore(rowAData, rowBData, _this.orderByIndex[i]);
            }

            if (result !== 0)
                break;
        }
        return result;
    });

    // page determination
    let pageN = 1;
    let lastPageItens = 0;
    for (let r of tempRows) {
        r.page = pageN
        lastPageItens++;
        if (lastPageItens == _this.itensPerPage) {
            pageN++;
            lastPageItens = 0;
        }
    }
    _this.lastPage = Math.ceil(tempRows.length / _this.itensPerPage);
    _this.filtredRows = tempRows;
}

function __internalGenDispFilterValue(col: MagicTableColumn, fData: any): string {
    let cell = fData;

    if (col.type == 'timestamp')
        cell = _timeStampToLocalDate(fData);
    else if (col.type == 'monetary')
        cell = _formatToMonetary(fData);
    else if (col.type == 'boolean')
        cell = _translateBoolean(fData);
    else if (col.type == 'progress-bar')
        cell = _progressBarPercentMaker(fData);

    return _genFilterValue(cell);
}
export function _genDispFilterValue(col: MagicTableColumn, row: MagicTableRowData): string | number | string[] | number[] {
    let fieldData = row.obj[col.fieldName];
    let fieldDispValue = null;

    if (!Array.isArray(fieldData)) {
        // not array
        fieldDispValue = __internalGenDispFilterValue(col, fieldData);
    } else {
        // array
        fieldDispValue = [];
        for (const v of fieldData)
            fieldDispValue.push(
                __internalGenDispFilterValue(col, v)
            );
    }

    return fieldDispValue;
}
// sub routines
export function _getFilterStatus(_this: MagicTableComponent, col_index: number, filterOrFieldData: any | any[]): boolean {
    let tempShow = true;
    if (!Array.isArray(filterOrFieldData)) {
        // not array
        for (let f of _this.filterIndexes[col_index])
            if (_compareFunction(f.data, filterOrFieldData)) {
                tempShow = f.show;
                break;
            }
    } else {
        // array
        let showsFieldData = _this.filterIndexes[col_index].filter((f) => f.show).map((f) => f.data);
        tempShow = showsFieldData.some((val: any) => Array.isArray(filterOrFieldData) && filterOrFieldData.includes(val));
    }
    return tempShow;
}
export function _updateFilterStatus(_this: MagicTableComponent, col_index: number, filterData: any, status: boolean) {
    for (let f of _this.filterIndexes[col_index])
        if (_compareFunction(f.data, filterData)) {
            f.show = status;
            break;
        }
}
// sub routines




// UI
export function filterPopdown_filter(_this: MagicTableComponent, c_index: number) {
    if (_this._filterPopdownData.searchResult.length == 0)
        return;

    if (_this._filterPopdownData.AddCurSelTofilter) {
        // Add Cur Sel To filter
        for (let fi of _this._filterPopdownData.searchResult) {
            _updateFilterStatus(_this, c_index, fi.data, fi.show);
        }
    } else {
        if (_this._filterPopdownData.searchText.length > 0)
            for (let fi of _this.filterIndexes[c_index]) {
                let index = _this._filterPopdownData.searchResult.indexOf(fi);
                if (index == -1)
                    fi.show = false;
            }

        for (let fi of _this._filterPopdownData.searchResult)
            _updateFilterStatus(_this, c_index, fi.data, fi.show)
    }

    // Filter Active
    let tempFilterActive = false;
    for (let fi of _this.filterIndexes[c_index]) {
        if (!fi.show) {
            tempFilterActive = true;
            break;
        }
    }
    _this.filterActiveIndex[c_index] = tempFilterActive;

    _this.actualPage = 1;
    _this.input_page = _this.actualPage;
    _updateFiltredRows(_this);
    _this._updateRowsForDisplay();
    _this.closeFilterPopdown();
}
export function filterPopdown_cleanFilter(_this: MagicTableComponent, col_index: number) {
    for (let fi of _this.filterIndexes[col_index]) {
        _updateFilterStatus(_this, col_index, fi.data, true)
    }
    _this.filterActiveIndex[col_index] = false;
    _this.actualPage = 1;
    _this.input_page = _this.actualPage;
    _updateFiltredRows(_this);
    _this._updateRowsForDisplay();
    _this.closeFilterPopdown();
}
export function filterPopdown_updateSearchResult(filterPopdownData: FilterPopdownData) {
    if (!filterPopdownData.filterIndex) {
        return [];
    }
    if (!filterPopdownData.searchText) {
        return filterPopdownData.filterIndex;
    }
    filterPopdownData.searchText = filterPopdownData.searchText.toLocaleLowerCase();
    let tempSearchResult = filterPopdownData.filterIndex.filter(it => {
        return it.cell.toLocaleLowerCase().includes(filterPopdownData.searchText);
    });
    tempSearchResult.forEach(i => {
        i.show = true;
    })
    filterPopdownData.searchResult = tempSearchResult;
}
export function filterPopdown_updateSelectAll(filterPopdownData: FilterPopdownData) {
    let temp_filterSelectAll = true;
    for (let f of filterPopdownData.searchResult)
        if (!f.show) {
            temp_filterSelectAll = false;
            break
        }
    filterPopdownData.selectAll = temp_filterSelectAll;
}
export function filterPopdown_toogleAll(filterPopdownData: FilterPopdownData) {
    for (let f of filterPopdownData.searchResult)
        if (filterPopdownData.selectAll)
            f.show = false;
        else
            f.show = true;
    filterPopdownData.selectAll = !filterPopdownData.selectAll;
}
// UI

// Filter