import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { NbButtonModule, NbCardModule, NbIconModule } from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { CustomOpenBtnRendererComponent } from './custom-open-btn-renderer.component';
import { CustomSelectBtnRendererComponent } from './smart-table-select/custom-select-btn-renderer.component';
import { SmartTableSelectComponent } from './smart-table-select/smart-table-select.component';
import { SmartTableSelectService } from './smart-table-select/smart-table-select.service';
import { SmartTableComponent } from './smart-table.component';

const BASE_MODULES = [
  CommonModule,
  Ng2SmartTableModule,
  MatDialogModule,
  NbCardModule,
  NbButtonModule,
  NbIconModule
];

const COMPONENTS = [
  SmartTableComponent,
  SmartTableSelectComponent,
];

const ENTRY_COMPONENTS = [
  CustomOpenBtnRendererComponent,
  CustomSelectBtnRendererComponent,
];

@NgModule({
  imports: [...BASE_MODULES],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS, ...ENTRY_COMPONENTS],
  providers: [SmartTableSelectService]
})
export class SmartTableModule { }
