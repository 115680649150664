export class MagicOrgChartNodeData {
    key: string;
    name: string;
    title: string;
    pic: string;
    parent: string;
    public constructor(init?: Partial<MagicOrgChartNodeData>) {
        this.key = '';
        this.name = '';
        this.title = '';
        this.pic = '';
        this.parent = '';
        if (init) {
            Object.assign(this, init);
        }
    }
}