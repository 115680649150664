import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";

import { FireFunctionsService } from "../../fireFunctions.service";
import { Condition, DB_MagicQuery, Filter, FilterGroup } from "./DB_MagicQuery.types";
import { executeQueryLocally } from "./executeQueryLocally";

const MODE: "server" | "local" = "server";

@Injectable()
export class DB_MagicQueryService {
    constructor(private afs: AngularFirestore, private aff: FireFunctionsService) { }
    public get(query: DB_MagicQuery): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log("DB_MagicQueryService -> Query", query);
            this.printPrettyQuery(query);
            if (MODE == "server")
                return this.aff.callHttps('DB_MagicQueryGet', query)
                    .then((data) => resolve(data))
                    .catch((e) => reject(e))

            else
                return executeQueryLocally(this.afs, query)
                    .then((data) => resolve(data))
                    .catch((e) => reject(e))
        });
    }
    public generateFilterCombinationString(conditions: Condition[]): string {
        const filterToString = (filter: Filter): string => {
            const { outputField, operator, value } = filter;
            const valueStr = typeof value === 'string' ? `'${value}'` : value;
            return `${outputField} ${operator} ${valueStr}`;
        };
        const groupToString = (group: FilterGroup): string => {
            const filtersStr = group.filters.map(filterToString).join(` ${group.operator} `);
            return group.negate ? `NOT (${filtersStr})` : `(${filtersStr})`;
        };
        const conditionToString = (condition: Condition): string => {
            const groupsStr = condition.filterGroups.map(groupToString).join(` ${condition.groupOperator} `);
            return `(${groupsStr})`;
        };
        return conditions.map(conditionToString).join(' OR ');
    }
    public printPrettyQuery(query: DB_MagicQuery): void {
        console.log("Main Collection:", query.mainCollection);
        console.log("Output Fields:", query.outputFields.map((of) => typeof of === "string" ? of : of.outputField + "").join(", "));

        if (query.joins.length > 0) {
            console.log("Joins:");
            query.joins.forEach((join, index) => {
                console.log(`  Join ${index + 1}:`);
                console.log(`    Local Collection: ${join.localCollection || query.mainCollection}`);
                console.log(`    Local Field: ${join.localField}`);
                console.log(`    New Collection: ${join.newCollection}`);
                console.log(`    Foreign Field: ${join.foreignField}`);
            });
        }

        if (query.conditions.length > 0) {
            console.log("Conditions:");
            query.conditions.forEach((condition, index) => {
                console.log(`  Condition ${index + 1} (${condition.groupOperator}):`);
                condition.filterGroups.forEach((group, groupIndex) => {
                    console.log(`    Group ${groupIndex + 1} (${group.operator}${group.negate ? ', negated' : ''}):`);
                    group.filters.forEach((filter, filterIndex) => {
                        console.log(`      Filter ${filterIndex + 1}: ${filter.outputField} ${filter.operator} ${typeof filter.value === 'string' ? `'${filter.value}'` : filter.value + ""}`);
                    });
                });
            });
        }

        if (query.orderBy) {
            const order = typeof query.orderBy === 'string' ? { outputField: query.orderBy, direction: 'asc' } : query.orderBy;
            console.log("Order By:", `${order.outputField} ${order.direction}`);
        }

        if (query.limit && query.limit > 0) {
            console.log("Limit:", query.limit);
        }
    }
}
