<div id="{{className}}" class="body-section related-cards">
    <div class="body-section-header">
        <nb-icon class="header-icon" icon="link-2-outline" pack="eva"></nb-icon>
        <span class="header-title">
            Cartões Relacionados
            <span
                *ngIf="pmCardModal.card.relatedCardsIds.length > 0">({{pmCardModal.card.relatedCardsIds.length}})</span>
        </span>
        <button id="add-related-card-popdown-trigger" [disabled]="!pmCardModal.isBoardActive"
            title="Novo Cartão Relacionado" nbButton size="small" appearance="ghost"
            (click)="_showAddRelatedCardPopdown()">
            <nb-icon icon="link" pack="fas"></nb-icon>
        </button>

        <!-- Add Related Card Popdown -->
        <div id="add-related-card-popdown" class="popdown add-related-card-popdown"
            [ngClass]="{'hide': !showAddRelatedCardPopdown}">
            <div class="popdownContent">
                <div class="popdown-header">
                    <span class="popdown-header-title">Adicionar cartão</span>
                    <button class="popdown-close-button" nbButton size="small" (click)="_hideAddRelatedCardPopdown()">
                        <nb-icon icon="close-outline" pack="eva"></nb-icon>
                    </button>
                </div>
                <div class="popdown-body">
                    <add-related-card-list [pmCardModal]="pmCardModal" (cardSelected)="_addRelatedCard($event)">
                    </add-related-card-list>
                </div>
            </div>
        </div>
        <!-- Add Related Card Popdown -->
    </div>

    <!-- list -->
    <div class="list">
        <div *ngIf="_loader" class="loader">
            <p>Carregando...</p>
        </div>

        <div *ngIf="!_loader" class="related-card-list">
            <div *ngFor="let card of _cards; trackBy: _trackByFn" class="related-card">

                <div class="related-card-inner">
                    <!-- pm-card -->
                    <pm-card [card]="card" [board]="pmCardModal.board" [boardRoutes]="_routesPerBoardMap[card.boardId]"
                        [boardLabels]="_labelsPerBoardMap[card.boardId]"
                        [boardMembers]="_membersPerBoardMap[card.boardId]"
                        [boardToDos]="_toDosPerBoardMap[card.boardId]" [disableOpen]="true"
                        (onClick)="openRelatedCard(card)">
                    </pm-card>
                    <!-- pm-card -->

                    <spam class="pm-card-subtext">
                        <b>{{_boardNamesIndex[card.boardId]}}</b>/<i>{{_routesNamesIndex[card.id]}}</i><i
                            *ngIf="card.status==0" [style.color]="'red'"> - Arquivado</i>
                    </spam>
                </div>

                <div class="related-card-opts">
                    <button [disabled]="!pmCardModal.isBoardActive" (click)="_remRelatedCard(card)">
                        Remover
                    </button>
                    <button *ngIf="_connectCardsIds.indexOf(card.id) == -1" [disabled]="!pmCardModal.isBoardActive"
                        (click)="_connectRelatedCard(card)">
                        Conectar cartões
                    </button>
                    <button disabled *ngIf="_connectCardsIds.indexOf(card.id) != -1">
                        Cartões conectados
                    </button>
                </div>

            </div>
        </div>
    </div>
    <!-- list -->

</div>