import firebase from "firebase/compat/app";

export class WF_Group {

  id: string;
  name: string;

  approvalType: "ONE" | "ALL";

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<WF_Group>) {

    this.id = '';
    this.name = '';

    this.approvalType = 'ONE';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
