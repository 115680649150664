import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'custom-select-btn',
  styles: [`
    a {
        display: block !important;
        width: 100% !important;
        text-align: center !important;
    }
    a.selected{
        color: #5dcfe3 !important;
    }
    a.not-selected{
        cursor: pointer !important;
        color: #a4abb3 !important;
    }
    a.not-selected:hover {
        color: #5dcfe3 !important;
    }
    nb-icon{
        font-size: 2.2rem !important;
    }
      `],
  template: `
   <a *ngIf="selectedRowDataId != rowData.id" class="not-selected" (click)="click.emit(rowData)"><nb-icon icon="checkmark-circle-2-outline" pack="eva"></nb-icon></a>

   <a *ngIf="selectedRowDataId == rowData.id" class="selected"><nb-icon icon="checkmark-circle-2" pack="eva"></nb-icon></a>

    `,
})
export class CustomSelectBtnRenderer2Component implements ViewCell, OnInit {
  @Input() value: string | number;
  @Input() rowData: any = {};
  @Output() click: EventEmitter<any> = new EventEmitter(this.rowData);
  @Input() selectedRowDataId: string | number;
  @Input() selectedRowDataField: string;
  ngOnInit() { }
}
