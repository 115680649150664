import { DbTableComponent } from '../db-table.component';
import { DbTableRowData } from '../types';
import { __updateSelectAll, __updateSelectAllSearchResult, _convertFilterValue, _filterValueDetermination, _getFilterStatus, _sort_valueConverter } from './db-table_filter';

// Header PopDown
export function __openHeaderPopDown(_this: DbTableComponent, c_index: number) {
  _this.searchText = '';
  _this.filterAddCurSelTofilter = false;

  // Pre Filter
  let tempRows: DbTableRowData[] = [];
  for (let row of _this.table.rows) {
    let tempRow = row;
    let validRow = true;

    for (let c_index_pf = 0; c_index_pf < _this.table.cols.length; c_index_pf++) {
      if (_this.filterActiveIndex[c_index_pf] && c_index_pf != c_index) {
        const fValue = _filterValueDetermination(tempRow, c_index_pf, _this.table.cols[c_index_pf]);
        validRow = _getFilterStatus(_this, c_index_pf, fValue);
        if (!validRow)
          break;
      }
    }
    if (validRow)
      tempRows.push(row);
  }
  // Pre Filter

  let tempColIndex = [];
  let tempSingleValues = [];
  for (let r of tempRows) {
    const fValue = _filterValueDetermination(r, c_index, _this.table.cols[c_index]);
    if (tempSingleValues.indexOf(fValue) == -1) {
      tempSingleValues.push(fValue);
      tempColIndex.push({
        show: _getFilterStatus(_this, c_index, fValue),
        value: fValue,
      });
    }
  }
  tempColIndex.sort(function (a, b) {
    var valueA = _sort_valueConverter(a.value),
      valueB = _sort_valueConverter(b.value);
    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  });
  _this.filterIndex4Sel = tempColIndex;

  __updateSelectAll(_this, c_index);

  _this.showHeaderPopDown = c_index;

  setTimeout(() => {
    document.getElementById('headerPopDownSearchInput_' + c_index).focus();
  }, 200);
}
export function __closeHeaderPopDown(_this: DbTableComponent) {
  _this.showHeaderPopDown = -1;
}
export function __sort(_this: DbTableComponent, c_index: number, ord: 'asc' | 'desc') {
  // sort rows
  let tempRows = _this.filtredRows;
  tempRows.sort(function (a, b) {
    var valueA = _sort_valueConverter(a.cells[c_index]),
      valueB = _sort_valueConverter(b.cells[c_index]);
    if (ord == 'asc') {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
    } else {
      if (valueA > valueB) return -1;
      if (valueA < valueB) return 1;
    }
    return 0;
  });
  let pageN = 1;
  let lastPageItens = 0;
  for (let [i, r] of tempRows.entries()) {
    r.page = pageN;
    lastPageItens++;
    if (lastPageItens == _this.itensPerPage) {
      pageN++;
      lastPageItens = 0;
    }
  }
  _this.lastPage = Math.ceil(tempRows.length / _this.itensPerPage) > 0 ? Math.ceil(tempRows.length / _this.itensPerPage) : 1;
  _this.filtredRows = tempRows;
  // sort rows
  _this.actualPage = 1;
  _this.input_page = _this.actualPage;
  _this.__updateRowsForDisplay();
  __closeHeaderPopDown(_this);
}
