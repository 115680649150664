import firebase from "firebase/compat/app";

export class termsOfUse {

    id: string;
    textHtml: string;
    url: string;
    daysOfValidity: number;

    createdOn: firebase.firestore.Timestamp;
    createdBy: string;

    public constructor(init?: Partial<termsOfUse>) {

        this.id = '';
        this.textHtml = '';
        this.url = '';
        this.daysOfValidity = 0;

        this.createdOn = firebase.firestore.Timestamp.now();
        this.createdBy = '';

        if (init)
            Object.assign(this, init);
    }
}
