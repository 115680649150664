<mat-dialog-content>
  <nb-card [nbSpinner]="loader" nbSpinnerMessage="" nbSpinnerSize="large" nbSpinnerStatus="basic">
    <nb-card-header>
      Informações do Solicitante

      <button class="close" mat-button matDialogClose>
        <span aria-hidden="true">&times;</span>
      </button>
    </nb-card-header>
    <nb-card-body>

      <div class="row">

        <!-- Dados -->
        <div class="col-6">
          <nb-card>
            <nb-card-header>
              Dados
            </nb-card-header>
            <nb-card-body>
              <form #dataForm="ngForm">
                <div class="row">
                  <div class="col-12">
                    <label>Nome Completo</label>
                    <input disabled class="ic-form-control" type="name" id="input_name" name="input_name"
                      [(ngModel)]="callerUser.fullName" required>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label>Telefone</label>
                    <input disabled class="ic-form-control" type="tel" id="input_phone" name="input_phone"
                      [(ngModel)]="callerUser.phone">
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label>Cargo</label>
                    <input disabled class="ic-form-control" type="text" id="input_jobTitle" name="input_jobTitle"
                      [(ngModel)]="callerUser.jobTitle">
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label>Empresa</label>
                    <h6 class="ic-form-control">
                      {{userCompany.name}}
                    </h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label>Unidade</label>
                    <h6 *ngIf="userBusinessPlace.id==''" class="ic-form-control"></h6>
                    <h6 *ngIf="userBusinessPlace.id!=''" class="ic-form-control">
                      {{userBusinessPlace.name}} ({{userBusinessPlace.address}}, {{userBusinessPlace.city}} -
                      {{userBusinessPlace.state}})
                    </h6>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label>Departamento</label>
                    <input disabled class="ic-form-control" type="text" id="input_department" name="input_department"
                      [(ngModel)]="callerUser.department">
                  </div>
                </div>
              </form>
            </nb-card-body>
          </nb-card>
        </div>
        <!-- Dados -->

        <!-- Documentação -->
        <nb-card class="col-6">
          <nb-card-header>
            Documentação
          </nb-card-header>
          <nb-card-body>
            <form #docForm="ngForm">
              <div class="row">
                <div class="col-12">
                  <label>Descrição</label>
                  <input disabled class="ic-form-control" type="name" id="input_description" name="input_description"
                    [(ngModel)]="callerUser.description" maxlength="60">
                </div>
              </div>

              <div class="row no-gutters">
                <label class="col-12">Responsável</label>
                <div class="col-2">
                  <h6 disabled class="ic-form-control">{{userResp.uName}}</h6>
                </div>
                <div class="col-8">
                  <h6 disabled class="ic-form-control">{{userResp.fullName}}</h6>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <label>Documentação</label>
                  <textarea disabled class="ic-form-control" rows="6" id="input_documentation" name="input_documentation"
                    [(ngModel)]="callerUser.documentation"></textarea>
                </div>
              </div>
            </form>
          </nb-card-body>
        </nb-card>
        <!-- Documentação -->

      </div>

    </nb-card-body>
  </nb-card>

</mat-dialog-content>