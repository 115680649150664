// https://confetti.js.org/more.html
import { confetti } from '@tsparticles/confetti'
import { IConfettiOptions } from '@tsparticles/confetti/types/IConfettiOptions';
import { ICoordinates, RecursivePartial } from '@tsparticles/engine';

export type ConfettiParticleShapes = "image" | "emoji" | "circle" | "square" | "heart" | "star" | "triangle";

export class Confetti {
    zIndex = 2999;
    public _calculateOrigin(originElement?: HTMLElement): ICoordinates {
        if (!originElement)
            return { x: 0.5, y: 0.5 };

        const rect = originElement.getBoundingClientRect();
        const x = (rect.left + rect.width / 2) / window.innerWidth;
        const y = (rect.top + rect.height / 2) / window.innerHeight;
        return { x, y };
    }
    public _addConfetti(confettiOptions?: RecursivePartial<IConfettiOptions>) {
        confetti(confettiOptions);
    };
    public _customCanvas(canvas: HTMLCanvasElement, confettiOptions?: RecursivePartial<IConfettiOptions>) {
        confetti.create(canvas, confettiOptions)
    }
    public basicCannon(originElement?: HTMLElement) {
        confetti({
            particleCount: 100,
            spread: 70,
            origin: this._calculateOrigin(originElement),
            zIndex: this.zIndex,
        });
    }
    public randomDirection(originElement?: HTMLElement) {
        function randomInRange(min, max) {
            return Math.random() * (max - min) + min;
        }
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: randomInRange(50, 100),
            origin: this._calculateOrigin(originElement),
            zIndex: this.zIndex,
        });
    }
    public realisticLook(originElement?: HTMLElement) {
        const count = 200, defaults: RecursivePartial<IConfettiOptions> = {
            origin: this._calculateOrigin(originElement),
            zIndex: this.zIndex,
        };

        function fire(particleRatio, opts) {
            confetti(
                Object.assign({}, defaults, opts, {
                    particleCount: Math.floor(count * particleRatio),
                })
            );
        }

        fire(0.25, {
            spread: 26,
            startVelocity: 55,
        });

        fire(0.2, {
            spread: 60,
        });

        fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8,
        });

        fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2,
        });

        fire(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    }
    public valentinesDay(originElement?: HTMLElement) {
        const defaults: RecursivePartial<IConfettiOptions> = {
            spread: 360,
            ticks: 100,
            gravity: 0,
            decay: 0.94,
            startVelocity: 30,
            shapes: ["heart"],
            colors: ["FFC0CB", "FF69B4", "FF1493", "C71585"],
            origin: this._calculateOrigin(originElement),
            zIndex: this.zIndex,
        };

        confetti({
            ...defaults,
            particleCount: 50,
            scalar: 2,
        });

        confetti({
            ...defaults,
            particleCount: 25,
            scalar: 3,
        });

        confetti({
            ...defaults,
            particleCount: 10,
            scalar: 4,
        });
    }
    public stars(originElement?: HTMLElement) {
        const defaults: RecursivePartial<IConfettiOptions> = {
            spread: 360,
            ticks: 50,
            gravity: 0,
            decay: 0.94,
            startVelocity: 30,
            shapes: ["star"],
            colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
            origin: this._calculateOrigin(originElement),
            zIndex: this.zIndex,
        };

        function shoot() {
            confetti({
                ...defaults,
                particleCount: 40,
                scalar: 1.2,
                shapes: ["star"],
            });

            confetti({
                ...defaults,
                particleCount: 10,
                scalar: 0.75,
                shapes: ["circle"],
            });
        }

        setTimeout(shoot, 0);
        setTimeout(shoot, 100);
        setTimeout(shoot, 200);
    }
    public emojis(emojis: string[], originElement?: HTMLElement) {
        const defaults: RecursivePartial<IConfettiOptions> = {
            spread: 360,
            ticks: 100,
            gravity: 0,
            decay: 0.94,
            startVelocity: 30,
            origin: this._calculateOrigin(originElement),
            zIndex: this.zIndex,
        };

        function shoot() {
            // confetti({
            //     ...defaults,
            //     particleCount: 30,
            //     scalar: 1.2,
            //     shapes: ["circle", "square"],
            //     colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
            // });

            confetti({
                ...defaults,
                particleCount: 20,
                scalar: 2,
                shapes: ["emoji"],
                shapeOptions: {
                    emoji: {
                        value: emojis,
                    },
                },
            });
        }

        setTimeout(shoot, 0);
        setTimeout(shoot, 100);
        setTimeout(shoot, 200);
    }
    public images(images: { src: string; width: number; height: number; }[], originElement?: HTMLElement) {
        confetti({
            spread: 360,
            ticks: 200,
            gravity: 1,
            decay: 0.94,
            startVelocity: 30,
            particleCount: 100,
            scalar: 3,
            shapes: ["image"],
            shapeOptions: {
                image: images,
            },
            origin: this._calculateOrigin(originElement),
            zIndex: this.zIndex,
        });
    }
    public fireworks(durationInSeconds: number) {
        const duration = durationInSeconds * 1000,
            animationEnd = Date.now() + duration,
            defaults: RecursivePartial<IConfettiOptions> = {
                startVelocity: 30,
                spread: 360,
                ticks: 60,
                zIndex: this.zIndex,
            };

        function randomInRange(min, max) {
            return Math.random() * (max - min) + min;
        }

        const interval = setInterval(function () {
            const timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
                return clearInterval(interval);
            }

            const particleCount = 50 * (timeLeft / duration);

            // since particles fall down, start a bit higher than random
            confetti(
                Object.assign({}, defaults, {
                    particleCount,
                    origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
                })
            );
            confetti(
                Object.assign({}, defaults, {
                    particleCount,
                    origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
                })
            );
        }, 250);
    }
    public snow(durationInSeconds: number) {
        const duration = durationInSeconds * 1000,
            animationEnd = Date.now() + duration;

        let skew = 1;

        function randomInRange(min, max) {
            return Math.random() * (max - min) + min;
        }

        const frame = () => {
            const timeLeft = animationEnd - Date.now(),
                ticks = Math.max(200, 500 * (timeLeft / duration));

            skew = Math.max(0.8, skew - 0.001);

            confetti({
                particleCount: 1,
                startVelocity: 0,
                ticks: ticks,
                origin: {
                    x: Math.random(),
                    y: Math.random() * skew - 0.2,
                },
                colors: ["#ffffff"],
                shapes: ["circle"],
                gravity: randomInRange(0.4, 0.6),
                scalar: randomInRange(0.4, 1),
                drift: randomInRange(-0.4, 0.4),
                zIndex: this.zIndex,
            });

            if (timeLeft > 0) {
                requestAnimationFrame(frame);
            }
        };

        frame();
    }
    public schoolPride(colors: string[], durationInSeconds: number) {
        const end = Date.now() + durationInSeconds * 1000;

        const frame = () => {
            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: 0 },
                colors: colors,
                zIndex: this.zIndex,
            });

            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
                colors: colors,
                zIndex: this.zIndex,
            });

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        };

        frame();
    }
    public customShapes(shapes: { shape: ConfettiParticleShapes, image: { src: string, width: number; height: number; }; colors: string[]; }[], originElement?: HTMLElement) {
        let defaults: RecursivePartial<IConfettiOptions> = {
            scalar: 2,
            spread: 270,
            particleCount: 25,
            origin: this._calculateOrigin(originElement),
            startVelocity: 35,
            zIndex: this.zIndex,
        };

        shapes.forEach((shape) => {
            confetti({
                ...defaults,
                shapes: shape + "",
                shapeOptions: {
                    image: {
                        src: shape.image.src,
                        replaceColor: true,
                        width: shape.image.width,
                        height: shape.image.height,
                    },
                },
                colors: shape.colors,
            });
        });
    }
}