import { Component, ElementRef, HostListener, OnChanges, OnDestroy, OnInit, SimpleChange, ViewChild, ViewEncapsulation } from '@angular/core';

import { UiFeedBackService } from 'app/@theme';
import { ThemeService } from 'app/@theme/theme.service';

import { REPORTING_EMPTY_ARRAY_DATA_VALUE, REPORTING_EMPTY_ARRAY_DISP_VALUE } from '../../../report.consts';
import { ReportingUiColumnPopdownData, ReportingUiFilterIndex } from '../../../report.types';
import { _orderByCore } from '../../../report.util';
import { ReportingService } from '../../../services/report.service';
import { columnPopdown_Search, columnPopdown_ToogleAll, columnPopdown_UpdateSelectAll } from './column-popdown';

@Component({
  selector: 'reporting-columns',
  templateUrl: './reporting-columns.component.html',
  styleUrls: ['./reporting-columns.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ReportingColumnsComponent implements OnInit, OnChanges, OnDestroy {

  className = 'ReportingColumnsComponent';


  // Column Popdown
  @ViewChild('ColumnPopdownContentERef', { read: ElementRef, static: false }) _columnPopdownContentERef: ElementRef;
  _uiShowColumnPopdown = -1;
  _uiColumnPopdownData: ReportingUiColumnPopdownData = new ReportingUiColumnPopdownData();
  // Column Popdown

  constructor(
    public uiFeedBackCtrl: UiFeedBackService,
    public _reportingCtrl: ReportingService,
    public _themeService: ThemeService,
  ) { }
  @HostListener('window:keydown.esc', ['$event'])
  on_keydown_esc() {
    this._uiCloseColumnPopdown();
  }
  @HostListener('window:keydown.l', ['$event'])
  on_keydown_l() {
    const col = this._reportingCtrl.columns[this._uiShowColumnPopdown];
    if (this._uiShowColumnPopdown != -1 && this._reportingCtrl._filtersActiveMap[col.field])
      this._uiCleanFilter(col.field);
  }
  @HostListener('window:keydown.enter', ['$event'])
  on_keydown_enter() {
    if (this._uiShowColumnPopdown != -1 && this._uiColumnPopdownData.searchResult.length > 0) {
      const col = this._reportingCtrl.columns[this._uiShowColumnPopdown];
      this._uiFilter(col.field);
    }
  }
  @HostListener('document:mousedown', ['$event'])
  on_mousedown(event: MouseEvent): void {
    if (this._columnPopdownContentERef && !this._columnPopdownContentERef.nativeElement.contains(event.target)) {
      this._uiCloseColumnPopdown();
    }
  }

  ngOnInit() { }
  ngOnDestroy() { }
  ngAfterViewInit() { }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }

  // Column Popdown
  _uiOpenColumnPopdown(filter_index: number) {
    this._uiColumnPopdownData.searchText = '';
    const col = this._reportingCtrl.columns[filter_index];
    let tempData: object[] = [];

    for (let data of this._reportingCtrl.data) {
      let validTr = true;
      for (let filter_index1 = 0; filter_index1 < this._reportingCtrl.columns.length; filter_index1++) {
        const col1 = this._reportingCtrl.columns[filter_index1];
        if (filter_index1 != filter_index && this._reportingCtrl._filtersActiveMap[col1.field]) {
          validTr = this._reportingCtrl._getFilterStatus(col1.field, data[col1.field]);
          if (!validTr)
            break;
        }
      }
      if (validTr)
        tempData.push(data);
    }

    let tempFilterIndex: ReportingUiFilterIndex = [];
    let tempSingleValues = [];
    let filterActive = false;

    for (let dataObj of tempData) {
      const fieldValue = dataObj[col.field];
      const fieldDispValue = this._reportingCtrl._filterDisplayValuePrepareFunction(col, dataObj);

      if (!Array.isArray(fieldValue)) {
        // not array
        if (tempSingleValues.indexOf(fieldDispValue) == -1) {
          tempSingleValues.push(fieldDispValue)
          let show = this._reportingCtrl._getFilterStatus(col.field, fieldValue);
          if (!show)
            filterActive = true;
          tempFilterIndex.push({ show: show, value: fieldValue, dispValue: fieldDispValue as any })
        }
      } else {
        // array
        for (let i = 0; i < fieldValue.length; i++) {
          const fv = fieldValue[i];
          const fdv = fieldDispValue[i];
          if (tempSingleValues.indexOf(fdv) == -1) {
            tempSingleValues.push(fdv)
            let show = this._reportingCtrl._getFilterStatus(col.field, fv);
            if (!show)
              filterActive = true;
            tempFilterIndex.push({ show: show, value: fv, dispValue: fdv as any })
          }
        }
        // empty array
        if (fieldValue.length == 0) {
          if (tempSingleValues.indexOf(REPORTING_EMPTY_ARRAY_DISP_VALUE) == -1) {
            tempSingleValues.push(REPORTING_EMPTY_ARRAY_DISP_VALUE);
            let show = this._reportingCtrl._getFilterStatus(col.field, REPORTING_EMPTY_ARRAY_DATA_VALUE);
            tempFilterIndex.push({ show: show, value: REPORTING_EMPTY_ARRAY_DATA_VALUE, dispValue: REPORTING_EMPTY_ARRAY_DISP_VALUE })
          }
        }
      }
    }

    const ord = this._reportingCtrl._orderMap[col.field] ? this._reportingCtrl._orderMap[col.field] : "asc";
    tempFilterIndex.sort((a, b) => _orderByCore(a.dispValue, b.dispValue, ord));

    this._uiColumnPopdownData.filterIndex = JSON.parse(JSON.stringify(tempFilterIndex));
    this._uiColumnPopdownData.searchResult = JSON.parse(JSON.stringify(tempFilterIndex));
    this._uiColumnPopdownData.selectAll = !filterActive;
    this._uiShowColumnPopdown = filter_index;
    setTimeout(() => {
      document.getElementById('ColumnPopdownSearchInput_' + filter_index).focus();
    }, 200);
  }
  _uiCloseColumnPopdown() {
    this._uiColumnPopdownData.AddCurSelTofilter = false;
    this._uiShowColumnPopdown = -1;
  }
  _uiShowCleanFiltersBtn(): boolean {
    let show = false;
    for (let col of this._reportingCtrl.columns) {
      if (this._reportingCtrl._filtersActiveMap[col.field]) {
        show = true;
        break;
      }
    }
    return show;
  }
  _uiCleanFilters() {
    this._reportingCtrl.cleanFilters();
    this._reportingCtrl.updateFiltredData();
  }
  _uiShowCleanOrdBtn(): boolean {
    let show = false;
    for (let col of this._reportingCtrl.columns) {
      if (this._reportingCtrl._orderMap[col.field]) {
        show = true;
        break;
      }
    }
    return show;
  }
  _uiCleanOrd() {
    this._reportingCtrl.cleanOrd();
    this._reportingCtrl.updateFiltredData();
  }
  _uiToogleSort(field: string, ord: "asc" | "desc") {
    this._reportingCtrl._orderMap[field] = this._reportingCtrl._orderMap[field] == ord ? null : ord;
    this._reportingCtrl.updateFiltredData();
    this._uiCloseColumnPopdown();
  }


  // ---------------------- FILTERS
  _uiFilter(field: string) {
    if (this._uiColumnPopdownData.searchResult.length == 0)
      return;

    if (this._uiColumnPopdownData.AddCurSelTofilter) {
      // Add Cur Sel To filter
      for (let fi of this._uiColumnPopdownData.searchResult)
        this._reportingCtrl._updateFilterStatus(field, fi.value, fi.show);

    } else {
      if (this._uiColumnPopdownData.searchResult.length < this._reportingCtrl._filtersValuesMap[field].length)
        for (let fi of this._reportingCtrl._filtersValuesMap[field]) {
          let index = this._uiColumnPopdownData.searchResult.indexOf(fi);
          if (index != -1)
            fi = this._uiColumnPopdownData.searchResult[index];
          else if (this._uiColumnPopdownData.searchText.length > 0)
            fi.show = false;
        }

      for (let fi of this._uiColumnPopdownData.searchResult)
        this._reportingCtrl._updateFilterStatus(field, fi.value, fi.show);
    }

    // Filter Active
    let tempFilterActive = false;
    for (let fi of this._reportingCtrl._filtersValuesMap[field]) {
      if (!fi.show) {
        tempFilterActive = true;
        break;
      }
    }
    this._reportingCtrl._filtersActiveMap[field] = tempFilterActive;

    this._uiCloseColumnPopdown();
    this._reportingCtrl.updateFiltredData();
  }
  _uiCleanFilter(field: string) {
    for (let fi of this._reportingCtrl._filtersValuesMap[field])
      this._reportingCtrl._updateFilterStatus(field, fi.value, true);
    this._reportingCtrl._filtersActiveMap[field] = false;
    this._reportingCtrl.updateFiltredData();
    this._uiCloseColumnPopdown();
  }
  _uiUpdateSearchResult() {
    columnPopdown_Search(this._uiColumnPopdownData);
  }
  _uiToogleAll() {
    columnPopdown_ToogleAll(this._uiColumnPopdownData);
  }
  _uiUpdateSelectAll() {
    columnPopdown_UpdateSelectAll(this._uiColumnPopdownData);
  }
  // ---------------------- FILTERS
  // Filter Popdown

}
