import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { UiFeedBackService } from 'app/@theme';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private authService: AuthService,
    private uiFeedBackCtrl: UiFeedBackService
  ) { }

  private alert4InitPassShowed = false;
  private alert4ExpPassShowed = false;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      return this.afAuth.authState
        .subscribe((dataAuth) => {
          if (dataAuth == null) {
            this.router.navigate(['auth', 'login']);
            reject(false);
          }
          else {
            // User Data check
            return this.authService.getUser()
              .then((user) => {
                this.authService.isUserValid()
                  .then((userValid) => {

                    if (userValid == false) {
                      this.uiFeedBackCtrl.presentJsAlert('Usuário expirado!', 'Favor Entrar em contato com o Administrador do sistema!', 'warning');
                      this.authService.logout();
                      this.router.navigate(['auth', 'login']);
                      resolve(false);
                    }

                    if (user.passwordStatus == 'productive')
                      resolve(true);
                    if (user.passwordStatus == 'initial') {
                      this.router.navigate(['auth', 'change-password']);
                      if (!this.alert4InitPassShowed) {
                        this.alert4InitPassShowed = true;
                        this.uiFeedBackCtrl.presentJsAlert('Alterar senha inicial!', 'Favor criar uma nova senha!', 'warning');
                      }
                      resolve(false);
                    }
                    if (user.passwordStatus == 'expired') {
                      this.router.navigate(['auth', 'change-password']);
                      if (!this.alert4ExpPassShowed) {
                        this.alert4ExpPassShowed = true;
                        this.uiFeedBackCtrl.presentJsAlert('Senha Expirada!', 'Favor criar uma nova senha!', 'warning');
                      }
                      resolve(false);
                    }

                  })
              })
              .catch((e) => { console.error(`AuthGuard ERROR ->`, e) })
            // User Data check
          }
        })
    });
  }

}
