<div class="editor-engine-display-inner">
  <p>Exibir</p>

  <div class="box-background engine-display"
    *ngIf="_vis_type && _vis_type.engine && _vis_type.engine.customizationProps && _vis_type.engine.customizationProps.length>0">
    <form class="engine-data-form" id="engine-data-form" name="engine-data-form" #engineCustomizeForm="ngForm">
      <div class="box engine-data-input"
        *ngFor="let ec of _vis_type.engine.customizationProps; trackBy: _trackByFn; let i=index">


        <!-- ---------------------------------- -->
        <!-- hideLegend -->
        <div class="hideLegend" *ngIf="_engineCustomize[ec].id=='hideLegend'">
          <input id="hideLegend" name="hideLegend" [disabled]="!edit" type="checkbox"
            [(ngModel)]="_vis_engineCustomize.hideLegend"
            (ngModelChange)="_vis_engineDisplayChange(engineCustomizeForm.valid)" required />
          <label for="hideLegend">
            {{_engineCustomize[ec].name}}
          </label>
        </div>
        <!-- hideLegend -->

        <!-- invertAxes -->
        <div class="invertAxes" *ngIf="_engineCustomize[ec].id=='invertAxes'">
          <input id="invertAxes" name="invertAxes" [disabled]="!edit" type="checkbox"
            [(ngModel)]="_vis_engineCustomize.invertAxes"
            (ngModelChange)="_vis_engineDisplayChange(engineCustomizeForm.valid)" required />
          <label for="invertAxes">
            {{_engineCustomize[ec].name}}
          </label>
        </div>
        <!-- invertAxes -->
        <!-- ---------------------------------- -->


        <!-- ---------------------------------- -->
        <!-- colors -->
        <div class="box colors" *ngIf="_engineCustomize[ec].id=='colors'">
          <p>Cores</p>
          <hr>
          <div class="color"
            *ngFor="let color_label of _data_for_selection.colors_labels; trackBy: _trackByFn; let i=index">
            <label for="{{'color_'+i}}">
              {{color_label}}
            </label>
            <input id="{{'color_'+i}}" name="{{'color_'+i}}" [disabled]="!edit" type="color"
              [(ngModel)]="_vis_engineCustomize.colors[color_label]"
              (ngModelChange)="_vis_engineDisplayChange(engineCustomizeForm.valid)" list="presetColors" required />
            <datalist id="presetColors">
              <option *ngFor="let pc of _presetColors">{{pc}}</option>
            </datalist>
          </div>
        </div>
        <!-- colors -->
        <!-- ---------------------------------- -->
      </div>
    </form>
  </div>

</div>