// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseOptions: {
    apiKey: "AIzaSyAdC_LQv5Vwr2LjTdtbMfckN7Irj_w6Owo",
    authDomain: "icontrol-dev-001.firebaseapp.com",
    projectId: "icontrol-dev-001",
    storageBucket: "icontrol-dev-001.appspot.com",
    messagingSenderId: "1081245985829",
    appId: "1:1081245985829:web:4f6c571037c774311ee3cc",
    measurementId: "G-HXJJQ5TFVM"
  },
  firebaseCloudFunctionsURL: "https://us-central1-icontrol-dev-001.cloudfunctions.net",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
