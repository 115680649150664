
export type OrderByDirection = 'desc' | 'asc';

export type WhereFilterOp =
    | '<'
    | '<='
    | '=='
    | '!='
    | '>='
    | '>'
    | 'array-contains'
    | 'in'
    | 'array-contains-any'
    | 'not-in';

export interface Join {
    localCollection?: string;
    localField: string;
    newCollection: string;
    foreignField: string;
}
export interface Filter {
    outputField: string;
    operator: WhereFilterOp;
    value: any;
}
export interface OrderBy {
    outputField: string;
    direction: OrderByDirection;
}
export interface FieldMapping {
    originalCollection: string;
    originalField: string;
    outputField: string;
}
export class FilterGroup {
    /**
     * Operação lógica que será aplicada entre os filtros no grupo.
     * Pode ser "AND" para exigir que todos os filtros sejam atendidos,
     * ou "OR" para exigir que pelo menos um dos filtros seja atendido.
     */
    operator: 'AND' | 'OR';

    /** Lista de filtros que serão aplicados no grupo. */
    filters: Filter[];

    /**
     * Indica se a condição do grupo deve ser negada.
     * Se `true`, o resultado do grupo será negado.
     */
    negate?: boolean;
    public constructor(init?: Partial<FilterGroup>) {
        this.operator = 'AND';
        this.filters = [];
        if (init)
            Object.assign(this, init);
    }
}
export class Condition {
    /**
     * Operação lógica que será aplicada entre os grupos de filtros.
     * Pode ser "AND" para exigir que todos os grupos de filtros sejam atendidos,
     * ou "OR" para exigir que pelo menos um dos grupos de filtros seja atendido.
     */
    groupOperator: 'AND' | 'OR';

    /** Lista de grupos de filtros que serão aplicados na condição. */
    filterGroups: FilterGroup[];
    public constructor(init?: Partial<Condition>) {
        this.groupOperator = 'AND';
        this.filterGroups = [];
        if (init)
            Object.assign(this, init);
    }
}
export class DB_MagicQuery {
    /** Nome da coleção principal onde a consulta será executada */
    mainCollection: string;

    /**
     * Campos a serem retornados no resultado da consulta.
     * Pode ser uma string ou um objeto `FieldMapping`.
     */
    outputFields: (string | FieldMapping)[];

    /**
     * Joins para unir dados de outras coleções com a coleção principal.
     * É uma lista de objetos `Join`.
     */
    joins?: Join[];

    /**
     * Condições de filtro aplicadas na consulta.
     * As condições são combinadas usando o operador `OR`, ou seja,
     * se qualquer uma das condições for verdadeira, o dado será incluído no resultado.
     * É uma lista de objetos `Condition`.
     */
    conditions?: Condition[];

    /**
     * Definição da ordem de classificação dos resultados.
     * Pode ser uma string (nome do campo) ou um objeto `OrderBy`.
     */
    orderBy?: string | OrderBy;

    /** Limite de número de resultados a serem retornados. */
    limit?: number;
    public constructor(init?: Partial<DB_MagicQuery>) {
        this.mainCollection = '';
        this.outputFields = [];
        this.joins = [];
        this.conditions = [];
        this.orderBy = null;
        this.limit = 0;
        if (init)
            Object.assign(this, init);
    }
}
