import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbLayoutDirectionService, NbStatusService } from '@nebular/theme';

import { FirebaseModule } from 'app/@firebase';
import { ThemeModule } from 'app/@theme';

import { AM_MODALS } from './asset-management/AM_MODALS';
import { DM_MODALS } from './documents-management/DM_MODALS';
import { HELP_MODALS } from './help/HELP_MODALS';
import { HOME_MODALS } from './home/HOME_MODALS';
import { OS_MODALS } from './organizational-structure/OS_MODALS';
import { PM_MODALS } from './project-management/PM_MODALS';
import { SM_MODALS } from './service-management/SM_MODALS';
import { SYS_MODALS } from './sys/SYS_MODALS';
import { TM_MODALS } from './time-management/TM_MODALS';
import { TOOLS_MODALS } from './tools/TOOLS_MODALS';
import { WF_MODALS } from './workflow/WF_MODALS';

const MODALS = [
  ...SYS_MODALS,
  ...TM_MODALS,
  ...SM_MODALS,
  ...PM_MODALS,
  ...OS_MODALS,
  ...TOOLS_MODALS,
  ...WF_MODALS,
  ...HELP_MODALS,
  ...HOME_MODALS,
  ...AM_MODALS,
  ...DM_MODALS,
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    FirebaseModule,
    ThemeModule.forRoot(),

    ...MODALS,
  ],
  providers: [
    NbLayoutDirectionService,
    NbStatusService
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class MainModalsModule { }
