import { AngularFireStorage, AngularFireUploadTask } from "@angular/fire/compat/storage";

const path = 'main';

export class UploadAdapter {
  loader;
  task: AngularFireUploadTask;
  afStorage: AngularFireStorage;
  onUploadStart: () => void;
  onUploadComplete: () => void;

  constructor(loader, afStorage, onUploadStart: () => void, onUploadComplete: () => void) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.afStorage = afStorage;
    this.onUploadStart = onUploadStart;
    this.onUploadComplete = onUploadComplete;
  }

  private generateRandom(length: number): string {
    var s = '';
    var r = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
      s += r.charAt(Math.floor(Math.random() * r.length));
    }
    return s;
  }

  // Starts the upload process.
  public upload() {

    this.onUploadStart();

    // Return a promise that will be resolved when the file is uploaded.
    return this.loader.file
      .then(file =>
        new Promise((resolve, reject) => {

          const fileName = this.generateRandom(6) + '_MagicTextEditor_' + new Date().toISOString()
          const tempDocRef = this.afStorage.ref(path + "/" + fileName);

          this.task = tempDocRef.put(file) as AngularFireUploadTask;

          this.task.snapshotChanges().subscribe(snapshot => {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Fazendo Upload... " + Number(percentage).toFixed(2) + " %");
            // Update the loader's progress.
            this.loader.uploadTotal = snapshot.totalBytes;
            this.loader.uploaded = snapshot.bytesTransferred;
          });


          this.task.then(() => {
            tempDocRef.getDownloadURL().toPromise()
              .then(url => {
                console.log('Upload concluido: ' + url);
                resolve({
                  urls: {
                    default: url,
                    // Optional different sizes of images.
                  },
                  customProperty: fileName
                });
                this.onUploadComplete();
              })
              .catch((e) => {
                console.log({ log: 'erro ao recuperar Url', error: JSON.stringify(e) });
                reject({
                  error: {
                    message: 'erro ao recuperar Url' + JSON.stringify(e)
                  }
                });
                this.onUploadComplete();
              });
          });


          this.task.catch((e) => {
            console.log({ log: 'erro ao fazer upload', error: JSON.stringify(e) });
            reject({
              error: {
                message: 'erro ao fazer upload' + JSON.stringify(e)
              }
            });
            this.onUploadComplete();
          });

        })
      );
  }

  // Aborts the upload process.
  public abort() {
    // Reject the promise returned from the upload() method.
    this.task.cancel();
    // this.server.abortUpload();
  }
}
