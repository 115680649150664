<div class="ic-overlay db-table-col-options-overlay">
  <div class="ic-overlay-loader" [ngClass]="{'show': loader}"></div>

  <div class="ic-overlay-body">

    <button title="Classificar de A a Z" class="list-button" (click)="toogleSort('asc')"
      [ngClass]="{'sort-active': dbTableComponent.orderByIndex[col_index] == 'asc'}">
      <span class="ltext">
        <span style="color: blue;">A</span>
        <span>Z</span>
      </span>
      <nb-icon icon="arrow-downward-outline" pack="eva"></nb-icon>
      Classificar de A a Z
    </button>
    <button title="Classificar de Z a A" class="list-button" (click)="toogleSort('desc')"
      [ngClass]="{'sort-active': dbTableComponent.orderByIndex[col_index] == 'desc'}">
      <span class="ltext">
        <span>Z</span>
        <span style="color: blue;">A</span>
        <nb-icon icon="arrow-downward-outline" pack="eva"></nb-icon>
      </span>
      Classificar de Z a A
    </button>
    <hr>

    <button title="Limpar Filtro (Alt + L)" class="list-button"
      [disabled]="!dbTableComponent.filterActiveIndex[col_index]" (click)="cleanFilter()">
      <nb-icon style="color: red;" icon="funnel-outline" pack="eva"></nb-icon>
      Limpar Filtro
    </button>

    <input #SearchInputERef class="list-input" type="text" [id]="'searchInput_'+col_index"
      [name]="'searchInput_'+col_index" aria-describedby="search-text" placeholder="Pesquisar..." autocomplete="nope"
      [(ngModel)]="searchText" autofocus (ngModelChange)="updateSearchResult()" (keyup.enter)="filter()">

    <div class="search-list-group search-list-group-flush">

      <!-- (Selecionar Tudo) -->
      <div class="search-list-group-item" *ngIf="searchText.length == 0">
        <input [disabled]="searchResult.length == 0" (click)="toogleAll()" type="checkbox"
          id="{{'input_unselectAll-' + id}}" name="{{'input_unselectAll-' + id}}" [(ngModel)]="selectAll">
        <label for="{{'input_unselectAll-' + id}}">
          (Selecionar Tudo)
        </label>
      </div>
      <!-- (Selecionar Tudo) -->

      <!-- (Selecionar Todos os Resultados da Pesquisa) -->
      <div class="search-list-group-item" *ngIf="searchText.length > 0 && searchResult.length > 0">
        <input (click)="toogleAll()" type="checkbox" id="{{'input_filterSelectAllSearchResult-' + id}}"
          name="{{'input_filterSelectAllSearchResult-' + id}}" [(ngModel)]="selectAll">
        <label for="{{'input_filterSelectAllSearchResult-' + id}}">
          (Selecionar Todos os Resultados da Pesquisa)
        </label>
      </div>
      <!-- (Selecionar Todos os Resultados da Pesquisa) -->

      <!-- (Adicionar seleção atual ao filtro) -->
      <div class="search-list-group-item" *ngIf="searchText.length > 0 && searchResult.length > 0">
        <input type="checkbox" id="{{'input_addCurSelTofilter-' + id}}" name="{{'input_addCurSelTofilter-' + id}}"
          [(ngModel)]="addCurSelTofilter">
        <label for="{{'inputaaddCurSelTofilter-' + id}}">
          (Adicionar seleção atual ao filtro)
        </label>
      </div>
      <!-- (Adicionar seleção atual ao filtro) -->

      <div class="search-list-group-item" *ngFor="let c of searchResult; let input_filter_check_i=index">
        <input type="checkbox" [id]="'input_filter_check_' + input_filter_check_i"
          [name]="'input_filter_check_' + input_filter_check_i" [(ngModel)]="c.show"
          (ngModelChange)="updateSelectAll()">
        <label [for]="'input_filter_check_' + input_filter_check_i" dbTableHighlight [searchedWord]="searchText"
          [content]="c.value" [classToApply]="'highlight'" [setTitle]="true">{{c.value}}</label>
      </div>
    </div>

    <button title="Filtrar (Enter)" class="list-button" [disabled]="searchResult.length == 0" (click)="filter()">
      Filtrar
    </button>

  </div>
</div>