import { _localSort } from "./localSort";

export class ArraysUtil {
  // constructor() { }
  moveItem(vet: any[], from: number, to: number): any[] {
    let tempVet = JSON.parse(JSON.stringify(vet));
    // remove `from` item and store it
    var f = tempVet.splice(from, 1)[0];
    // insert stored item into position `to`
    tempVet.splice(to, 0, f);
    return tempVet;
  }
  sort = _localSort;
  chunk(arr: any[], size: number) {
    return arr.reduce((chunks, el, i) => {
      if (i % size === 0) {
        chunks.push([el])
      } else {
        chunks[chunks.length - 1].push(el)
      }
      return chunks
    }, [])
  }
  removeDuplicates(arr: any[]): any[] {
    let singleValuesMap = new Map();
    arr.forEach(obj => {
      singleValuesMap.set(JSON.stringify(obj), obj)
    });
    let singleValuesArray = [];
    singleValuesMap.forEach((value, key) => { singleValuesArray.push(value) });
    return singleValuesArray;
  }
}
