<div #CustonAlertModalContent class="ufb-modal">
  <div class="ufb-modal-inner">

    <div class="ufb-card ufb-custon-alert">
      <!-- header -->
      <div class="ufb-card-header">
        <span class="ufb-card-title">
          {{custonAlertOpts.title}}
        </span>
        <button class="close" mat-button matDialogClose (click)="custonAlertOpts.closeButtonHandler()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- header -->

      <!-- body -->
      <div class="ufb-card-body">
        <div id="alert-message-{{id}}"></div>

        <form #form="ngForm">
          <div *ngFor="let input of custonAlertOpts.inputs">
            <div *ngIf="input == 'spacer'">
              ------
            </div>

            <div *ngIf="input != 'spacer'">
              <input class="ic-form-control" *ngIf="input.type == CHECKBOX_TYPE" type="checkbox" id="{{input.name}}"
                name="{{input.name}}" [disabled]="input.disabled" [required]="input.required" [(ngModel)]="input.value"
                [style]="input.style">

              <label *ngIf="input.label" for="{{input.name}}">
                {{input.label}}
              </label>

              <input class="ic-form-control"
                *ngIf="input.type != TEXT_AREA_TYPE && input.type != SELECT_TYPE && input.type != CHECKBOX_TYPE"
                type="{{input.type}}" id="{{input.name}}" name="{{input.name}}" [minlength]="input.minlength"
                [maxlength]="input.maxlength" [min]="input.min" [max]="input.max" [placeholder]="input.placeholder"
                [disabled]="input.disabled" [pattern]="input.pattern" [required]="input.required"
                [(ngModel)]="input.value" [style]="input.style">

              <select class="ic-form-control"
                *ngIf="input.type != TEXT_AREA_TYPE && input.type == SELECT_TYPE && input.type != CHECKBOX_TYPE"
                id="{{input.name}}" name="{{input.name}}" [(ngModel)]="input.value" [disabled]="input.disabled"
                [required]="input.required" [pattern]="input.pattern" [style]="input.style">
                <option *ngFor="let o of input.options" [ngValue]="o.value">
                  {{o.name}}
                </option>
              </select>

              <textarea class="ic-form-control"
                *ngIf="input.type == TEXT_AREA_TYPE && input.type != SELECT_TYPE && input.type != CHECKBOX_TYPE"
                id="{{input.name}}" name="{{input.name}}" [minlength]="input.minlength" [maxlength]="input.maxlength"
                [placeholder]="input.placeholder" [disabled]="input.disabled" [pattern]="input.pattern"
                [required]="input.required" [(ngModel)]="input.value" [style]="input.style"></textarea>
            </div>
          </div>
        </form>
      </div>
      <!-- body -->

      <!-- footer -->
      <div class="ufb-card-footer">
        <div class="ic-button-grp">
          <button *ngFor="let btn of custonAlertOpts.buttons" nbButton size="small" title="{{btn.title}}"
            status="{{btn.status}}" [disabled]="!form.valid && btn.needFormValid" matDialogClose
            (click)="btn.handler(form.value)" [style]="btn.style">
            <nb-icon *ngIf="btn.icon" icon="{{btn.icon}}" pack="eva"></nb-icon>
            {{btn.text}}
          </button>
        </div>
      </div>
      <!-- footer -->
    </div>
  </div>
</div>