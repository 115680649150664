/**
 * Gera o valor para o filtro apartir do valor da celula
 * @param cell
 * @returns string
 */
export function dbTableConvertFilterValue(cell: any): string {
    let v = '';
    if (cell != undefined) {
        cell = cell + '';
        v = cell.replace(/<(?:.|\n)*?>/gm, '');
    }
    if (v == '')
        v = '(Vazias)';
    return v;
}