import firebase from "firebase/compat/app";

export const STANDARD_PM_BOARD_LABEL_COLOR = "#4a86e8";

export class PM_BoardLabel {

    id: string;
    boardId: string;
    parentKey: string;

    name: string;
    hex: string;

    createdOn: firebase.firestore.Timestamp;
    updatedOn: firebase.firestore.Timestamp;
    createdBy: string;
    updatedBy: string;

    public constructor(init?: Partial<PM_BoardLabel>) {

        this.id = '';
        this.boardId = '';
        this.parentKey = '';

        this.name = '';
        this.hex = STANDARD_PM_BOARD_LABEL_COLOR;

        this.createdOn = firebase.firestore.Timestamp.now();
        this.updatedOn = null;
        this.createdBy = '';
        this.updatedBy = null;

        if (init)
            Object.assign(this, init);
    }
}