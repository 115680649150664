import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { ThemeAppearance, ThemeShape, ThemeSize, ThemeStatus } from '../theme.types';

@Directive({
    selector: '[icButton]'
})
export class IcButtonDirective implements OnInit {
    @Input() size: ThemeSize;
    @Input() status: ThemeStatus;
    @Input() shape: ThemeShape;
    @Input() appearance: ThemeAppearance;
    @Input() fullWidth;
    constructor(private el: ElementRef, private renderer: Renderer2) { }
    ngOnInit() {
        // standard values
        if (!this.size)
            this.size = 'small'
        if (!this.status)
            this.status = 'basic';
        if (!this.shape)
            this.shape = 'rectangle';
        if (!this.appearance)
            this.appearance = 'filled';

        // add classes
        this.renderer.addClass(this.el.nativeElement, 'size-' + this.size);
        this.renderer.addClass(this.el.nativeElement, 'status-' + this.status);
        this.renderer.addClass(this.el.nativeElement, 'shape-' + this.shape);
        this.renderer.addClass(this.el.nativeElement, 'appearance-' + this.appearance);
        if (this.fullWidth !== undefined)
            this.renderer.addClass(this.el.nativeElement, 'full-width');
    }
}