import { Component, OnInit, ViewChild } from '@angular/core';

import { AuthService, LayoutService, UtilService } from 'app/@core';
import { DICTIONARY, FirestoreService, TM_Project, TM_ProjectTask, TM_Tag, TM_TimeRecord } from 'app/@firebase';
import { ThemeService, UiFeedBackService } from 'app/@theme';
import { MagicTableColumn } from 'app/components';

import { TimerWeekHoursBarChartDetailedComponent } from './charts/timer-week-hours-bar-chart-detailed.component';
import { TimerWeekHoursBarChartSummaryComponent } from './charts/timer-week-hours-bar-chart-summary.component';
import { TimerWeekHoursPieDoughnutChartComponent } from './charts/timer-week-hours-pie-doughnut-chart.component';

@Component({
  selector: 'timer-dashboard-tab',
  templateUrl: './timer-dashboard-tab.component.html',
  styleUrls: ['./timer-dashboard-tab.component.scss']
})
export class TimerDashboardTabComponent implements OnInit {

  className = 'TimerDashboardTabComponent';

  @ViewChild('TimerWeekHoursBarChartSummary_REF') timerWeekHoursBarChartSummary: TimerWeekHoursBarChartSummaryComponent = new TimerWeekHoursBarChartSummaryComponent(this.theme, this.layoutService, this.utilCtrl);
  @ViewChild('TimerWeekHoursBarChartDetailed_REF') timerWeekHoursBarChartDetailed: TimerWeekHoursBarChartDetailedComponent = new TimerWeekHoursBarChartDetailedComponent(this.theme, this.layoutService, this.utilCtrl);
  @ViewChild('TimerWeekHoursPieDoughnutChart_REF') timerWeekHoursPieDoughnutChart: TimerWeekHoursPieDoughnutChartComponent = new TimerWeekHoursPieDoughnutChartComponent(this.theme, this.layoutService, this.utilCtrl);

  loader = true;

  private DICTIONARY = new DICTIONARY();

  totalHours = 0;
  totalMin = 0;
  total = 0;

  tmProjects: TM_Project[] = [];
  tmProjectTasks: TM_ProjectTask[] = [];
  tmTags: TM_Tag[] = [];

  timeRecords: TM_TimeRecord[] = [];

  timeRecordsTable_data: any[] = [];
  timeRecordsTable_columns: MagicTableColumn[] = [
    {
      title: 'Projeto',
      fieldName: 'project_name',
      type: 'html',
      width: '200px',
      canGroup: true,
      valuePrepareFunction: (cell, row) => {
        return `<span style="color:${row['project_color']}">${cell}</span>`;
      }
    },
    {
      title: 'Tarefa',
      fieldName: 'task_name',
      type: 'text',
      width: '100px'
    },
    {
      title: 'Tags',
      fieldName: 'tags_names',
      type: 'text',
      width: '100px'
    },
    {
      title: 'Descrição',
      fieldName: 'description',
      type: 'text',
      width: '200px'
    },
    {
      title: 'Dia',
      fieldName: 'dateDay',
      type: 'text',
      width: '100px',
    },
    {
      title: 'Duração',
      fieldName: 'duration',
      type: 'text',
      width: '100px',
      groupValue: true,
      valuePrepareFunction: (cell, row) => {
        if (cell && cell.toFixed)
          return cell.toFixed(2);
        else
          return "";
      }
    },
    {
      title: 'HH:MM',
      type: 'text',
      width: '100px',
      valuePrepareFunction: (cell, row) => {
        return `${row.durationHours}h ${row.durationMinutes}m`;
      }
    },
  ];


  constructor(
    public db: FirestoreService,
    private authService: AuthService,
    private uiFeedBackCtrl: UiFeedBackService,
    private layoutService: LayoutService,
    private theme: ThemeService,
    protected utilCtrl: UtilService
  ) { }

  ngOnInit() { }

  private loadProjects(): Promise<void> {
    return new Promise((resolve, reject) => {

      let old_projectsIds = [];
      this.tmProjects.forEach(p => {
        old_projectsIds.push(p.id);
      })

      let old_projectsTasksIds = [];
      this.tmProjectTasks.forEach(t => {
        old_projectsTasksIds.push(t.id);
      })

      let old_tagsIds = [];
      this.tmTags.forEach(t => {
        old_tagsIds.push(t.id);
      })

      let new_projectsIds = [];
      let new_projectsTasksIds = [];
      let new_tagsIds = [];

      this.timeRecords
        .forEach(tr => {
          if (old_projectsIds.indexOf(tr.projectId) == -1)
            new_projectsIds.push(tr.projectId)

          if (old_projectsTasksIds.indexOf(tr.taskId) == -1)
            new_projectsTasksIds.push(tr.taskId)

          tr.tagsIds.forEach(tid => {
            if (old_tagsIds.indexOf(tid) == -1)
              new_tagsIds.push(tid)
          })
        });

      return this.db.timeManagement.projects.getDataByIds(new_projectsIds)
        .then(data => {
          data.forEach(d => {
            this.tmProjects.push(d)
          })

          return this.db.timeManagement.projectTasks.getDataByIds(new_projectsTasksIds)
            .then(data => {
              data.forEach(d => {
                this.tmProjectTasks.push(d)
              })

              return this.db.timeManagement.tags.getDataByIds(new_tagsIds)
                .then(data => {
                  data.forEach(d => {
                    this.tmTags.push(d)
                  })

                  resolve();
                })
            })
        })
        .catch(e => reject(e));

    });
  }

  public load(timeRecords: TM_TimeRecord[], datePeriodFrom: Date, datePeriodTo: Date) {

    this.loader = true;
    this.timeRecords = timeRecords;
    this.timeRecordsTable_data = [];

    this.loadProjects()
      .then(() => {

        this.timerWeekHoursBarChartSummary.load(timeRecords, datePeriodFrom, datePeriodTo, this.tmProjects)
        this.timerWeekHoursBarChartDetailed.load(timeRecords, datePeriodFrom, datePeriodTo, this.tmProjects)
        this.timerWeekHoursPieDoughnutChart.load(timeRecords, this.tmProjects)

        this.totalHours = 0;
        this.totalMin = 0;
        this.total = 0;
        let tempTimeRecordsTable_data = [];
        for (let tr of timeRecords) {
          // Update Total Hours
          this.totalHours += tr.durationHours;
          this.totalMin += tr.durationMinutes;
          if (this.totalMin >= 60) {
            this.totalHours++;
            this.totalMin = this.totalMin - 60;
          }
          this.total += tr.duration;
          // Update Total Hours

          // Update table data
          tempTimeRecordsTable_data.push(this.timeRecordsTable_dataMaker(tr, this.tmProjects));
          // Update table data
        }
        this.timeRecordsTable_data = tempTimeRecordsTable_data;

        this.loader = false;

      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e)
      })
  }
  private timeRecordsTable_dataMaker(timeRecord: TM_TimeRecord, tmProjects: TM_Project[]): any {
    let tempObj = JSON.parse(JSON.stringify(timeRecord));

    let tempProject = new TM_Project();
    for (let p of tmProjects)
      if (p.id == tempObj.projectId) {
        tempProject = p;
        break;
      }
    tempObj['project_name'] = `${tempProject.teamId} - ${tempProject.name}`;
    tempObj['project_color'] = this.DICTIONARY.getData(tempProject.colorId, this.DICTIONARY.timeManagement.project.color)['hex'];

    let tempTask = new TM_ProjectTask();
    if (tempObj.taskId != '') {
      for (let task of this.tmProjectTasks)
        if (task.id == tempObj.taskId) {
          tempTask = task;
          break;
        }
      tempObj['task_name'] = `${tempTask.name}`;
    } else
      tempObj['task_name'] = ``;

    if (tempObj.tagsIds.length > 0) {
      let tags_ids = '';
      let tags_names = '';
      for (let index = 0; index < tempObj.tagsIds.length; index++) {
        const tagId = tempObj.tagsIds[index];
        let tag = new TM_Tag();

        for (let t of this.tmTags) {
          if (t.id == tagId) {
            tag = t;
            break;
          }
        }

        if (index == 0) {
          tags_ids = tag.id;
          tags_names = tag.name;
        } else {
          tags_ids = `${tags_ids}, ${tag.id}`
          tags_names = `${tags_names}, ${tag.name}`
        }
        if (index == tempObj.tagsIds.length) {
          tags_ids = `${tags_ids}, ${tag.id}.`
          tags_names = `${tags_names}, ${tag.name}.`
        }
      }
      tempObj['tags_ids'] = tags_ids;
      tempObj['tags_names'] = tags_names;
    } else {
      tempObj['tags_ids'] = ``;
      tempObj['tags_names'] = ``;
    }

    tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(tempObj.createdOn);
    tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(tempObj.updatedOn);
    return tempObj;
  }

}
