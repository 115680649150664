import { Component, OnChanges, OnInit, SimpleChange } from '@angular/core';

import { getNextCardCreationDateToCard, getNextStartDateToCard, getPMCardDueDateStatusData, getPMCardPriorityData, PM_BoardLabel, PM_Card, PM_CARD_DUEDATE_STATUS_DATA_TYPE, PM_CARD_PRIORITY_DATA_ARRAY, PM_CARD_PRIORITY_DATA_TYPE, PM_CardRecurrence, PM_CardRecurrenceDaysOfWeek, pmCardDueDateStatusDetermination, User } from 'app/@firebase';
import { _updateCardRoute } from 'app/routes/main/project-management/kanban/open-board/subroutines/open-board_routes';
import { TIMES } from 'app/routes/main/sys/SYS_CONSTS';

import { PmCardModal, TM_TimeRecordsHoursSum } from '../../../pm-card-modal';

@Component({
  selector: 'mode-details',
  templateUrl: './mode-details.component.html',
  styleUrls: ['./mode-details.component.scss']
})
export class PMCardModalModeDetailsComponent implements OnInit, OnChanges {

  className = 'PMCardModalModeDetailsComponent';

  _loader = true;

  PM_CARD_PRIORITY_DATA_ARRAY = PM_CARD_PRIORITY_DATA_ARRAY;
  PM_CARD_PRIORITY_DATA_ARRAY_4_SELECTION = PM_CARD_PRIORITY_DATA_ARRAY.filter((p) => { return p.id });

  showCardDueDatePopdown = false;
  TIMES = TIMES;

  cardDoneAtDateTimeString = '';
  _dueDateStatus: PM_CARD_DUEDATE_STATUS_DATA_TYPE = new PM_CARD_DUEDATE_STATUS_DATA_TYPE();
  cardAssignees: User[] = [];
  cardLabels: PM_BoardLabel[] = [];

  timeRecordSum4Card: TM_TimeRecordsHoursSum = { h: 0, m: 0, val: 0, s: '' };

  // recurrence
  daysOfWeek: { value: PM_CardRecurrenceDaysOfWeek, label: string }[] = [
    { value: 0, label: 'Dom' },
    { value: 1, label: 'Seg' },
    { value: 2, label: 'Ter' },
    { value: 3, label: 'Qua' },
    { value: 4, label: 'Qui' },
    { value: 5, label: 'Sex' },
    { value: 6, label: 'Sáb' }
  ];
  monthsOfYear = [
    { value: 0, label: 'Janeiro' },
    { value: 1, label: 'Fevereiro' },
    { value: 2, label: 'Março' },
    { value: 3, label: 'Abril' },
    { value: 4, label: 'Maio' },
    { value: 5, label: 'Junho' },
    { value: 6, label: 'Julho' },
    { value: 7, label: 'Agosto' },
    { value: 8, label: 'Setembro' },
    { value: 9, label: 'Outubro' },
    { value: 10, label: 'Novembro' },
    { value: 11, label: 'Dezembro' }
  ];
  nextCardCreationDateToCardStr = '';
  nextStartDateToCardStr = '';
  // recurrence

  constructor(
    public pmCardModal: PmCardModal,
  ) { }

  ngOnInit() {
    this.load();
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }
  ngOnDestroy() { }
  _trackByFn(index, item) {
    return index;
  }
  _compareFn(o1, o2): boolean {
    let result = false;
    if (o1 === null && o2 === null)
      result = true;
    else if (o1 != null && o2 != null) {
      if (o1 === o2)
        result = true;
      else if (o1.id != null && o2.id != null && o1.id === o2.id)
        result = true;
    }
    return result;
  }
  load() {
    this._loader = true;

    // doneAt
    this.cardDoneAtDateTimeString = '';
    if (this.pmCardModal.card && this.pmCardModal.card.doneAtString && !this.pmCardModal.card.doneAtHourString)
      this.cardDoneAtDateTimeString = `${this.pmCardModal.card.doneAtString}T00:00:00`;
    if (this.pmCardModal.card && this.pmCardModal.card.doneAtString && this.pmCardModal.card.doneAtHourString)
      this.cardDoneAtDateTimeString = `${this.pmCardModal.card.doneAtString}T${this.pmCardModal.card.doneAtHourString}`;

    let tempDueDateStatus = pmCardDueDateStatusDetermination(this.pmCardModal.card);
    this._dueDateStatus = getPMCardDueDateStatusData(tempDueDateStatus);
    // doneAt

    this.nextCardCreationDateToCardStr = this.pmCardModal.utilCtrl.date.toLocalDate(getNextCardCreationDateToCard(this.pmCardModal.card, this.pmCardModal.utilCtrl));
    this.nextStartDateToCardStr = this.pmCardModal.utilCtrl.date.toLocalDate(getNextStartDateToCard(this.pmCardModal.card, this.pmCardModal.utilCtrl));

    // cardAssignees
    let tempCardAssignees = [];
    this.pmCardModal.card.assignedTo
      .forEach((ca) => {
        const tempUser = this.pmCardModal.boardMembers.filter((u) => u.uName == ca)[0];
        if (tempUser)
          tempCardAssignees.push(tempUser);
      })
    this.cardAssignees = tempCardAssignees;
    // cardAssignees

    // cardLabels
    let tempCardLabels = [];
    this.pmCardModal.card.labelsIds
      .forEach((labelId) => {
        const tempLabel = this.pmCardModal.boardLabels.find((l) => l.id == labelId);
        if (tempLabel)
          tempCardLabels.push(tempLabel);
      });
    this.cardLabels = tempCardLabels;
    // cardLabels

    this.__updateTimeRecordSum4Card();

    this._loader = false;
  }
  __updateTimeRecordSum4Card() {
    this.timeRecordSum4Card = this.pmCardModal.getHoursSum4Card();
  }
  _copyLink() {
    let linkUrl = window.location.origin + '/#/main/project-management/kanban/open-board?id=' + this.pmCardModal.card.boardId + '&cardIdToOpen=' + this.pmCardModal.card.id;
    navigator.clipboard.writeText(linkUrl)
      .then(() => {
        this.pmCardModal.uiFeedBackCtrl.presentToast(`Copiado para Clipboard`, ``, `success`)
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erros', e)
      })
  }
  _copyRef() {
    let refStr = `${this.pmCardModal.card.id} - ${this.pmCardModal.card.title}`;
    if (this.pmCardModal.card.priority)
      refStr = `[${this.pmCardModal.card.priority}]${refStr}`;
    navigator.clipboard.writeText(refStr)
      .then(() => {
        this.pmCardModal.uiFeedBackCtrl.presentToast(`Copiado para Clipboard`, ``, `success`)
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erros', e)
      })
  }
  _showSubscribersInfo() {
    this.pmCardModal._activeModal = true;
    const atertRef = this.pmCardModal.uiFeedBackCtrl.presentAlert('Informação', 'Quantidade de usuarios que optaram a ser notificados de atualizações neste cartão.', 'success');
    atertRef.afterClosed().subscribe(() => {
      this.pmCardModal._activeModal = false;
    });
  }
  _routeChange() {
    if (!this.pmCardModal.card.routeId) {
      this.pmCardModal.card.routeId = this.pmCardModal.oldCard.routeId;
      return;
    }
    this.pmCardModal.uiFeedBackCtrl.presentLoader()
      .then(() => {
        const oldRouteId = this.pmCardModal.oldCard.routeId;
        const newRouteId = this.pmCardModal.card.routeId;

        _updateCardRoute(
          this.pmCardModal.utilCtrl, this.pmCardModal.db, this.pmCardModal.authService, this.pmCardModal.className,
          oldRouteId, newRouteId, this.pmCardModal.boardRoutes, this.pmCardModal.card
        )
          .then(() => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
          })
          .catch((e) => {
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e);
          });
      });
  }
  _priorityChange() {
    // Priority
    this.pmCardModal.cardPriority = new PM_CARD_PRIORITY_DATA_TYPE();
    if (this.pmCardModal.card.priority)
      this.pmCardModal.cardPriority = getPMCardPriorityData(this.pmCardModal.card.priority);
    // Priority

    this.pmCardModal._updateCardAfterChange()
      .then(() => this.load());

    if (this.pmCardModal.cardPriority.id != '')
      this.pmCardModal.uiFeedBackCtrl.presentAlert(`Prioridade alterada: ${this.pmCardModal.cardPriority.id} - ${this.pmCardModal.cardPriority.name}`, `Uso/Ação Recomendada: '${this.pmCardModal.cardPriority.suggestedUse}'`, 'warning', true)
  }
  _cleanDoneAt() {
    this.pmCardModal.card.doneAt = null;
    this.pmCardModal.card.doneAtString = null;
    this.pmCardModal.card.doneAtHourString = null;
    this.pmCardModal._updateCardAfterChange()
      .then(() => this.load());
  }

  // CardDueDatePopdown
  _showCardDueDatePopdown() {
    this.showCardDueDatePopdown = true;
    this.pmCardModal.__showPopdownInSide('card-due-date-popdown-trigger', 'card-due-date-popdown', () => { this._hideCardDueDatePopdown(); });
  }
  _hideCardDueDatePopdown() {
    this.showCardDueDatePopdown = false;
  }
  _clearCardDueDatePopdown() {
    this.pmCardModal.card.startDate = null;
    this.pmCardModal.card.startDateString = null;
    this.pmCardModal.card.startDateHourString = null;

    this.pmCardModal.card.dueDate = null;
    this.pmCardModal.card.dueDateString = null;
    this.pmCardModal.card.dueDateHourString = null;

    this.pmCardModal.card.done = false;
    this.pmCardModal.card.doneAt = null;
    this.pmCardModal.card.doneAtString = null;
    this.pmCardModal.card.doneAtHourString = null;

    this.pmCardModal._updateCardAfterChange()
      .then(() => this.load());
    this._hideCardDueDatePopdown();
  }
  _toggleStartDate() {
    if (this.pmCardModal.card.startDateString != null) {
      this.pmCardModal.card.startDate = null;
      this.pmCardModal.card.startDateString = null;
      this.pmCardModal.card.startDateHourString = null;
      this.__saveStartDate();
    } else
      this.pmCardModal.card.startDateString = '';
  }
  _toggleStartDateHour() {
    if (this.pmCardModal.card.startDateHourString != null)
      this.pmCardModal.card.startDateHourString = null;
    else
      this.pmCardModal.card.startDateHourString = this.TIMES[0];
    this.__saveStartDate();
  }
  _toggleDueDateHour() {
    if (this.pmCardModal.card.dueDateHourString != null)
      this.pmCardModal.card.dueDateHourString = null;
    else
      this.pmCardModal.card.dueDateHourString = this.TIMES[this.TIMES.length - 1];
    this.__saveDueDate();
  }
  _cardStartDateStringInput_onChange() {
    this.__saveStartDate();
  }
  _cardStartDateHourStringInput_onChange() {
    this.__saveStartDate();
  }
  _cardDueDateStringInput_onChange() {
    this.__saveDueDate();
  }
  _cardDueDateHourStringInput_onChange() {
    this.__saveDueDate();
  }
  private __saveStartDate() {
    if (!this.pmCardModal.card.startDateString || typeof this.pmCardModal.card.startDateString !== 'string') {
      // missing startDateString
      this.pmCardModal.card.startDate = null;
      this.pmCardModal.card.startDateString = null;
      this.pmCardModal.card.startDateHourString = null;
    } else {
      let dateParts = this.pmCardModal.card.startDateString.split('-');
      if (dateParts.length !== 3)
        throw new Error('Invalid date format. Expected format: YYYY-MM-DD');

      let year = parseInt(dateParts[0], 10);
      let month = parseInt(dateParts[1], 10) - 1; // Months are zero-based in JavaScript Date
      let day = parseInt(dateParts[2], 10);

      let hours = 0;
      let minutes = 0;

      if (this.pmCardModal.card.startDateHourString && typeof this.pmCardModal.card.startDateHourString === 'string' && this.pmCardModal.card.startDateHourString.trim() !== '') {
        let timeParts = this.pmCardModal.card.startDateHourString.split(':');
        if (timeParts.length !== 2)
          throw new Error('Invalid time format. Expected format: HH:MM');

        hours = parseInt(timeParts[0], 10);
        minutes = parseInt(timeParts[1], 10);
      } else {
        // missing startDateHourString
        this.pmCardModal.card.startDateHourString = null;
      }

      let date = new Date(year, month, day, hours, minutes, 0, 0);
      if (isNaN(date.getTime()))
        throw new Error('Invalid date or time');

      this.pmCardModal.card.startDate = this.pmCardModal.utilCtrl.timestamp.fromDate(date);
    }
    this.pmCardModal._updateCardAfterChange()
      .then(() => this.load());
  }
  private __saveDueDate() {
    if (!this.pmCardModal.card.dueDateString || typeof this.pmCardModal.card.dueDateString !== 'string') {
      // missing startDateString
      this.pmCardModal.card.startDate = null;
      this.pmCardModal.card.startDateString = null;
      this.pmCardModal.card.startDateHourString = null;
    } else {
      let dateParts = this.pmCardModal.card.dueDateString.split('-');
      if (dateParts.length !== 3)
        throw new Error('Invalid date format. Expected format: YYYY-MM-DD');

      let year = parseInt(dateParts[0], 10);
      let month = parseInt(dateParts[1], 10) - 1; // Months are zero-based in JavaScript Date
      let day = parseInt(dateParts[2], 10);

      let hours = 23;
      let minutes = 59;

      if (this.pmCardModal.card.dueDateHourString && typeof this.pmCardModal.card.dueDateHourString === 'string' && this.pmCardModal.card.dueDateHourString.trim() !== '') {
        let timeParts = this.pmCardModal.card.dueDateHourString.split(':');
        if (timeParts.length !== 2)
          throw new Error('Invalid time format. Expected format: HH:MM');

        hours = parseInt(timeParts[0], 10);
        minutes = parseInt(timeParts[1], 10);
      } else {
        // missing dueDateHourString
        this.pmCardModal.card.dueDateHourString = null;
      }

      let date = new Date(year, month, day, hours, minutes, 59, 999);
      if (isNaN(date.getTime()))
        throw new Error('Invalid date or time');

      this.pmCardModal.card.dueDate = this.pmCardModal.utilCtrl.timestamp.fromDate(date);
    }
    this.pmCardModal._updateCardAfterChange()
      .then(() => this.load());
  }
  // CardDueDatePopdown
  _cardDoneAtStringInput_onChange() {
    this.__saveDoneAt();
  }
  _cardDoneAtDateTimeStringInput_onChange() {
    if (this.cardDoneAtDateTimeString) {
      let dateTimeStringParts = this.cardDoneAtDateTimeString.split('T');
      if (dateTimeStringParts.length !== 2)
        throw new Error('Invalid date format. Expected format: YYYY-MM-DDTHH:MM:SS');

      this.pmCardModal.card.doneAtString = dateTimeStringParts[0];
      if (this.pmCardModal.card.dueDateHourString) {
        let timeParts = dateTimeStringParts[1].split(':');
        if (timeParts.length !== 2 && timeParts.length !== 3)
          throw new Error('Invalid time format. Expected format: HH:MM or HH:MM:SS');

        this.pmCardModal.card.doneAtHourString = `${timeParts[0]}:${timeParts[1]}`;
      }
    }
    this.__saveDoneAt();
  }
  private __saveDoneAt() {
    if (!this.pmCardModal.card.doneAtString || typeof this.pmCardModal.card.doneAtString !== 'string') {
      // missing doneAtString
      this.pmCardModal.card.doneAt = null;
      this.pmCardModal.card.doneAtString = null;
      this.pmCardModal.card.doneAtHourString = null;
    } else {
      let dateParts = this.pmCardModal.card.doneAtString.split('-');
      if (dateParts.length !== 3)
        throw new Error('Invalid date format. Expected format: YYYY-MM-DD');

      let year = parseInt(dateParts[0], 10);
      let month = parseInt(dateParts[1], 10) - 1; // Months are zero-based in JavaScript Date
      let day = parseInt(dateParts[2], 10);

      let hours = 0;
      let minutes = 0;

      if (this.pmCardModal.card.doneAtHourString && typeof this.pmCardModal.card.doneAtHourString === 'string' && this.pmCardModal.card.doneAtHourString.trim() !== '') {
        let timeParts = this.pmCardModal.card.doneAtHourString.split(':');
        if (timeParts.length !== 2)
          throw new Error('Invalid time format. Expected format: HH:MM');

        hours = parseInt(timeParts[0], 10);
        minutes = parseInt(timeParts[1], 10);
      } else {
        // missing doneAtHourString
        this.pmCardModal.card.doneAtHourString = null;
      }

      let date = new Date(year, month, day, hours, minutes, 0, 0);
      if (isNaN(date.getTime()))
        throw new Error('Invalid date or time');

      this.pmCardModal.card.doneAt = this.pmCardModal.utilCtrl.timestamp.fromDate(date);
    }
    this.pmCardModal._updateCardAfterChange()
      .then(() => this.load());
  }
  _toggleCardDone() {
    this.pmCardModal.card.done = !this.pmCardModal.card.done;
    this.pmCardModal._updateCardAfterChange()
      .then(() => this.load());
  }
  _generateDueDateStr(card: PM_Card): string {
    let result = '';
    if (card.startDate)
      result += `${this.pmCardModal.utilCtrl.timestamp.formatToDateString(card.startDate, card.dueDateHourString != null)} - `;
    if (card.dueDate)
      result += `${this.pmCardModal.utilCtrl.timestamp.formatToDateString(card.dueDate, card.dueDateHourString != null)}`;
    return result;
  }

  // recurrence
  _showRecurrenceInfo() {
    let recurrenceInfoHTML =
      `             
<p class="tiny-text">
    <b>Benefícios da Recorrência:</b> Automatiza tarefas repetitivas, economiza tempo e garante que
    nenhuma tarefa importante seja esquecida, melhorando a eficiência e a organização do trabalho.
</p>
<br>

<h6>1. Tipos de Recorrência:</h6>
<ul class="tiny-text">
    <li><b>Diária:</b> Para tarefas diárias, como atualizações e reuniões de equipe.</li>
    <li><b>Semanal:</b> Escolha dias específicos para repetir tarefas semanalmente, como reuniões e
        relatórios.</li>
    <li><b>Mensal:</b> Repetição mensal em datas ou dias específicos, para relatórios ou reuniões
        mensais.</li>
    <li><b>Anual:</b> Para eventos anuais, como aniversários e revisões de contratos.</li>
</ul>
<hr>

<h6>2. Dicas:</h6>
<ul class="tiny-text">
    <li><b>Pausar/Cancelar Recorrência:</b> Pause ou cancele tarefas recorrentes conforme a necessidade.
    </li>
    <li><b>Personalização Avançada:</b> Ajuste frequência e dias específicos, por exemplo, a cada 2
        semanas em terças e quintas.</li>
    <li><b>Criação Antecipada:</b> Configure os cartões para serem criados com antecedência, definindo
        dias ou horas antes da data prevista, ajudando a evitar surpresas e a se preparar melhor para as
        tarefas.</li>
    <li><b>Seleção da Rota:</b> Defina a rota onde os novos cartões serão criados para garantir que eles
        sejam organizados corretamente no fluxo de trabalho.</li>
</ul>
<hr>

<h6>3. Substituições de Título:</h6>
<p class="tiny-text">
    As substituições de título permitem personalizar os títulos dos cartões criados a partir de padrões recorrentes, usando placeholders. Utilize o símbolo <code>$</code> seguido do nome do placeholder para inserir informações dinâmicas nos títulos. Aqui estão os placeholders disponíveis:
</p>
<ul class="tiny-text">
    <li><b>$MONTH</b> Substitui pelo mês atual (1-12). Exemplo: <code>$MONTH</code> será substituído por <code>09</code> em setembro.</li>
    <li><b>$YEAR</b> Substitui pelo ano atual. Exemplo: <code>$YEAR</code> será substituído por <code>2024</code>.</li>
    <li><b>$DAY</b> Substitui pelo dia atual do mês (1-31). Exemplo: <code>$DAY</code> será substituído por <code>03</code> no terceiro dia do mês.</li>
    <li><b>$DATE</b> Substitui pela data atual no formato local. Exemplo: <code>$DATE</code> pode ser substituído por <code>03/09/2024</code>.</li>
    <li><b>$DAYOFWEEK</b> Substitui pelo dia atual da semana. Exemplo: <code>$DAYOFWEEK</code> será substituído por <code>Terça-feira</code>.</li>
    <li><b>$STARTDATE</b> Usa a data de início do cartão, se disponível. Exemplo: <code>$STARTDATE</code> pode ser substituído por <code>01/09/2024</code>.</li>
    <li><b>$DUEDATE</b> Usa a data de vencimento do cartão, se disponível. Exemplo: <code>$DUEDATE</code> pode ser substituído por <code>05/09/2024</code>.</li>
    <li><b>$ORIGCARDID</b> Substitui pelo ID do cartão original. Exemplo: <code>$ORIGCARDID</code> será substituído pelo ID único do cartão.</li>
    <li><b>$CARDNUMBER</b> Substitui pelo número sequencial do cartão dentro da recorrência. Exemplo: <code>$CARDNUMBER</code> será substituído por <code>1</code> para o primeiro cartão, <code>2</code> para o segundo, e assim por diante.</li>
</ul>
<p class="tiny-text">
    Para usar estas substituições, basta incluir os placeholders desejados nos títulos dos seus cartões. Por exemplo, um título configurado como "Tarefa $DAY/$MONTH" será automaticamente substituído por "Tarefa 03/09" no terceiro dia de setembro.
</p>

<h6>4. Orientações Importantes:</h6>
<ul class="tiny-text">
    <li>ℹ️ A data de início do cartão determina o início da recorrência.</li>
    <li>ℹ️ Novos cartões seguirão o prazo deste cartão.</li>
    <li>ℹ️ Cartões recorrentes são criados para dias específicos configurados, como segundas e sextas.
    </li>
    <li>ℹ️ Para intervalos maiores que uma semana, a próxima ocorrência segue o primeiro dia configurado
        após o intervalo.</li>
    <li>ℹ️ Configurar múltiplos dias da semana pode criar cartões na mesma semana, dependendo dos dias
        selecionados.</li>
</ul>
<hr>
`;
    this.pmCardModal._activeModal = true;
    const atertRef = this.pmCardModal.uiFeedBackCtrl.presentAlert('Informação sobre recorrência de cartões', recurrenceInfoHTML, 'success');
    atertRef.afterClosed().subscribe(() => {
      this.pmCardModal._activeModal = false;
    });
  }
  _activeRecurrence() {
    this.pmCardModal.card.recurrence = new PM_CardRecurrence();
    this.pmCardModal._updateCardAfterChange()
      .then(() => this.load());
  }
  _inactiveRecurrenceConfirmation() {
    this.pmCardModal._activeModal = true;
    this.pmCardModal.uiFeedBackCtrl.presentCustonAlert({
      title: 'Desativar Recorrencia?',
      message: `⚠️ Ao desativar a recorrencia as informações referentes aos cartões ja criados serão apagadas.`,
      buttons: [
        {
          text: 'Cancelar',
          icon: "close-outline",
          handler: () => {
            this.pmCardModal._activeModal = false;
          }
        },
        {
          needFormValid: true,
          text: 'Desativar',
          icon: "repeat-outline",
          status: "danger",
          handler: () => {
            this.pmCardModal._activeModal = false;
            this._inactiveRecurrence();
          }
        }
      ]
    })
  }
  _inactiveRecurrence() {
    this.pmCardModal.card.recurrence = null;
    this.pmCardModal._updateCardAfterChange()
      .then(() => this.load());
  }
  _selectDayOfWeek(val: PM_CardRecurrenceDaysOfWeek) {
    if (this.pmCardModal.card.recurrence.daysOfWeek.includes(val))
      this.pmCardModal.card.recurrence.daysOfWeek = this.pmCardModal.card.recurrence.daysOfWeek.filter((v) => v != val);
    else
      this.pmCardModal.card.recurrence.daysOfWeek.push(val);
    this.pmCardModal._updateCardAfterChange()
      .then(() => this.load());
  }
  // recurrence
}
