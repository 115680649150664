import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FirebaseModule } from 'app/@firebase';
import { ThemeModule } from 'app/@theme';

import { TimerListTabModule } from '../timer/timer-list-tab/timer-list-tab.module';
import { TmTimerModalComponent } from './timer-modal.component';

const BASE_MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    FirebaseModule,
    ThemeModule,
    TimerListTabModule
];

const COMPONENTS = [
    TmTimerModalComponent
];


@NgModule({
    imports: [...BASE_MODULES],
    exports: [...COMPONENTS],
    declarations: [...COMPONENTS],
})
export class TmTimerModalModule { }