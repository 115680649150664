import firebase from "firebase/compat/app";

export class HomePageNote {
  id: string;
  uName: string

  title: string;
  body: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;
  
  public constructor(init?: Partial<HomePageNote>) {
    this.id = "";
    this.uName = "";

    this.title = "";
    this.body = "";

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
