import firebase from "firebase/compat/app";

export class PM_BoardIntegration_TM {
  active: boolean;
  teamId: string;
  projectId: string;
  public constructor(init?: Partial<PM_BoardIntegration_TM>) {
    this.active = false;
    this.teamId = '';
    this.projectId = '';
    if (init)
      Object.assign(this, init);
  }
}
export class PM_BoardIntegration {
  tm: PM_BoardIntegration_TM;
  public constructor(init?: Partial<PM_BoardIntegration>) {
    this.tm = Object.assign({}, new PM_BoardIntegration_TM());
    if (init)
      Object.assign(this, init);
  }
}

export class PM_Board {

  id: string;
  name: string;
  status: 0 | 1;

  childsKey: string;

  members: string[];
  admins: string[];

  routes: string[];
  labels: string[];

  color: string;

  // quick picker
  showQuickPicker: boolean;
  showMemberNames: boolean;

  showRoutes: boolean;
  showRouteNames: boolean;

  showPriorities: boolean;
  showPrioritiesNames: boolean;

  showDueDateStatus: boolean;
  showDueDateStatusNames: boolean;

  showLabels: boolean;
  showLabelNames: boolean;

  showDone: boolean;
  showDoneNames: boolean;

  showAging: boolean;
  // quick picker

  // card configs
  compactCards: boolean;
  showCardInfo: boolean;
  // card configs

  // administration
  // -- access control
  onlyAdmCreateCard: boolean;
  onlyAdmChangeDueDate: boolean;
  onlyAdmChangeCardMembers: boolean;
  onlyAdmMassCreateCard: boolean;
  // -- access control

  // -- features
  massCreateCard: boolean;
  privateCards: boolean;
  autoSetStartDate: boolean;
  // -- features
  // administration

  integration: PM_BoardIntegration;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<PM_Board>) {

    this.id = '';
    this.name = '';
    this.status = 1;

    this.childsKey = '';

    this.members = [];
    this.admins = [];

    this.routes = [];
    this.labels = [];

    this.color = '';

    // quick picker
    this.showQuickPicker = true;
    this.showMemberNames = false;

    this.showRoutes = true;
    this.showRouteNames = true;

    this.showPriorities = true;
    this.showPrioritiesNames = true;

    this.showDueDateStatus = true;
    this.showDueDateStatusNames = true;

    this.showLabels = true;
    this.showLabelNames = true;

    this.showDone = true;
    this.showDoneNames = true;

    this.showAging = true;
    // quick picker

    // card configs
    this.compactCards = true;
    this.showCardInfo = true;
    // card configs

    // administration
    // -- access control
    this.onlyAdmCreateCard = false;
    this.onlyAdmChangeDueDate = true;
    this.onlyAdmChangeCardMembers = false;
    this.onlyAdmMassCreateCard = true;
    // -- access control

    // -- features
    this.massCreateCard = true;
    this.privateCards = false;
    this.autoSetStartDate = true;
    // -- features
    // administration

    this.integration = Object.assign({}, new PM_BoardIntegration());

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
