export class Formatters {
  // private DECIMAL_SEPARATOR = ".";
  // private GROUP_SEPARATOR = ",";
  // private pureResult = "";
  // maskedId
  // private _unFormatForCPF(val) {
  //   if (!val) {
  //     return '';
  //   }
  //   val = val.replace(/\D/g, '');

  //   if (this.GROUP_SEPARATOR === ',') {
  //     return val.replace(/,/g, '');
  //   } else {
  //     return val.replace(/\./g, '');
  //   }
  // };
  // private _cpf_mask(v: string) {
  //   v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
  //   v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
  //   v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
  //   //de novo (para o segundo bloco de números)
  //   v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
  //   return v;
  // }
  // private cnpj(v: string) {
  //   v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
  //   v = v.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
  //   v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
  //   v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitavo e o nono dígitos
  //   v = v.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos
  //   return v;
  // }

  // constructor() { }

  format_date(val: string): string {
    if (!val) {
      return '';
    }
    let _val = val;
    _val = _val.replace(/\D/g, '');       //Remove tudo o que não é dígito
    _val = _val.replace(/(\d{2})(\d)/, '$1/$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    _val = _val.replace(/(\d{2})(\d)/, '$1/$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    return _val;
  }
  romanize(num: number): string {
    if (!num) {
      return '';
    }
    let _num = num;
    let result = '';
    if (_num < 0) {
      _num = Math.abs(_num);
      result = '-';
    }
    var decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
    var roman = ["M", "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I"];
    for (var i = 0; i <= decimal.length; i++) {
      while (_num % decimal[i] < _num) {
        result += roman[i];
        _num -= decimal[i];
      }
    }
    return result;
  }
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  millisecondsToHHMMSS(ms: number) {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / 1000 / 60) % 60);
    const hours = Math.floor((ms / 1000 / 3600) % 24)

    const humanized = [
      this.numberPadding(hours, 2),
      this.numberPadding(minutes, 2),
      this.numberPadding(seconds, 2),
    ].join(':');

    return humanized;
  }
  numberPadding(number: number, size: number): string {
    var s = number.toString();
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }
  format_phone(val) {
    if (!val) {
      return '';
    }
    let _val = val;
    _val = _val.replace(/\D/g, '');       //Remove tudo o que não é dígito
    _val = _val.replace(/(\d{2})(\d)/, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
    _val = _val.replace(/(\d{4})(\d{1,4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos\
    return _val;
  }
  unFormatStr(val: string): string {
    if (!val) {
      return '';
    }
    let _val = val;
    _val = _val.replace(/\D/g, '');
    return _val.replace(/,/g, '');
  }
  /**
   * Converte o Nome do estado para a uf
   * @param {string} str String para remover os caracteres
   * @return {string} `string` somente com numeros
   */
  removeCaracs(str: string): string {
    return str.replace(/\D/g, "");
  }
  formatToMonetary(numero: number): string {
    // Converte o número para uma string formatada com duas casas decimais
    const numeroFormatado = numero.toFixed(2);

    // Separa a parte inteira da parte decimal
    const [parteInteira, parteDecimal] = numeroFormatado.split('.');

    // Adiciona o separador de milhares
    const parteInteiraFormatada = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Retorna o valor formatado
    return `R$ ${parteInteiraFormatada},${parteDecimal}`;
  }
  formatNumber(num: number): string {
    // Se o número for inteiro, retornar com separadores de milhar
    if (Number.isInteger(num)) {
      return num.toLocaleString('pt-BR', { maximumFractionDigits: 0 });
    }

    // Caso contrário, formatar com 2 casas decimais
    return num.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  formatLargeNumber(num: number): string {
    const units = ["", "K", "M", "B", "T"];
    let unitIndex = 0;
    let scaledNum = num;

    while (scaledNum >= 1000 && unitIndex < units.length - 1) {
      scaledNum /= 1000;
      unitIndex++;
    }

    // Limitar a 1 casa decimal, exceto se for um número inteiro
    const formattedNum = scaledNum % 1 === 0
      ? scaledNum.toFixed(0)
      : scaledNum.toFixed(1);

    return `${formattedNum}${units[unitIndex]}`;
  }
  formatVectorWithCommasAndAnd(array: string[]): string {
    if (array.length === 0) {
      return '';
    } else if (array.length === 1) {
      return array[0];
    } else if (array.length === 2) {
      return array.join(' e ') + '.';
    } else {
      const lastItem = array.pop();
      const formattedArray = array.join(', ') + ', e ' + lastItem + '.';
      array.push(lastItem!); // Adicionando o último item de volta ao vetor original
      return formattedArray;
    }
  }
  /**
  * CPF
  *  EXEMPLE
  * <ion-item lines="none">
  * <ion-input #cpf_input [formControl]="credentialsForm.controls['cpf']" type="tel" name="cpf" maxlength="14"
  *   (ionChange)="cpf = utilCtrl.formatCpf_Cnpj(cpf)" [(ngModel)]="cpf" (keyup.enter)="tryToAdvance()"
  *   [ngClass]="{'invalid-ion-input': utilCtrl.checkFormControlAlert(credentialsForm.controls['cpf'])}">
  * </ion-input>
  * </ion-item>
  * <div *ngIf="utilCtrl.checkFormControlAlert(credentialsForm.controls['cpf'])" class="validator-error">
  * <ion-item (click)="UiFeedBackCtrl.presentInfoAlert('O cpf deve estar no Formato 999.999.999-99')">
  *   <ion-label>CPF inválido!</ion-label>
  *   <ion-icon name="ios-information-circle-outline" item-end></ion-icon>
  * </ion-item>
   */
  // formatCpf_Cnpj(valString): string {
  //   if (!valString) {
  //     return '';
  //   }
  //   let val = valString.toString();
  //   const parts = this._unFormatForCPF(val).split(this.DECIMAL_SEPARATOR);
  //   this.pureResult = parts;
  //   if (parts[0].length <= 11) {
  //     this.maskedId = this.cpf_mask(parts[0]);
  //     return this.maskedId;
  //   } else {
  //     this.maskedId = this.cnpj(parts[0]);
  //     return this.maskedId;
  //   }
  // }
}
