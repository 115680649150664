import firebase from "firebase/compat/app";

export class SM_DocAttachment {

  id: string;
  smDocId: string;

  name: string;
  size: number;
  mimeType: string;
  extension: string;
  url: string;

  createdOn: firebase.firestore.Timestamp;
  createdBy: string;

  public constructor(init?: Partial<SM_DocAttachment>) {

    this.id = '';
    this.smDocId = '';

    this.name = '';
    this.size = 0;
    this.mimeType = '';
    this.extension = '';
    this.url = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.createdBy = '';

    if (init)
      Object.assign(this, init);
  }
}
