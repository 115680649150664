<div class="notifications-list" #notifSidebarContent>
  <!-- header -->
  <div id="notifications-list-header" class="notifications-list-header">
    <!-- mode-selector -->
    <div class="mode-selector">
      <div *ngFor="let mode of filterModes; trackBy: _trackByFn" class="mode"
        [ngClass]="{'selected': filterMode == mode.id}">
        <div class="mode-btn" (click)="_selectMode(mode.id)">
          <span class="mode-name">{{mode.name()}}</span>
        </div>

        <div class="mode-selected-bar"></div>
      </div>
    </div>
    <!-- mode-selector -->

    <!-- actions-button-grp -->
    <div class="ic-button-grp">
      <button [disabled]="_loader" nbButton size="small" title="Atualizar" (click)="updateFilterMode()">
        <nb-icon icon="refresh-outline" pack="eva"></nb-icon>
      </button>

      <button *ngIf="filterMode == 'all' || filterMode == 'readed'" [disabled]="_loader || numOfNotifMap.readed <= 0"
        nbButton size="small" n title="Apagar Notificações lidas" (click)="_deleteReadedNotif()">
        <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
      </button>

      <button *ngIf="filterMode == 'all' || filterMode == 'unReaded'"
        [disabled]="_loader || numOfNotifMap.unReaded <= 0" nbButton size="small" title="Marcar todas como lidas"
        (click)="_markAllAsReaded()">
        <nb-icon icon="done-all-outline" pack="eva"></nb-icon>
      </button>
    </div>
    <!-- actions-button-grp -->
  </div>
  <!-- header -->

  <!-- body -->
  <div class="notifications-list-body">
    <div *ngIf="filterMode == 'unReaded' && !_loader && notifications.length == 0" class="no-notif">
      <p style="text-align: center;">Você não possui notificações não Lidas!</p>
      <ng-lottie [options]="noNotifLottie" [width]="'300px'">
      </ng-lottie>
    </div>

    <div *ngIf="_loader" class="loader">
      <ng-lottie [options]="loaderLottie" [width]="'150px'">
      </ng-lottie>
    </div>

    <!-- notif-cards-list -->
    <cdk-virtual-scroll-viewport itemSize="180" minBufferPx="720" maxBufferPx="1440" id="list-body"
      class="notif-cards-list">

      <div *cdkVirtualFor="let notif of notifications; trackBy: _trackByFn; let i = index" id="{{notif.id}}"
        class="notif-cards-list-item">
        <!-- day-date-separator -->
        <div *ngIf="i > 0 && dateStringMap[notifications[(i-1)].id] != dateStringMap[notif.id]"
          class="day-date-separator">
          <div class="notif-date">
            <nb-icon icon="calendar-outline" pack="eva"></nb-icon>
            <span>{{dateStringMap[notif.id]}}</span>
          </div>
        </div>
        <!-- day-date-separator -->

        <!-- notif-card -->
        <div class="notif-card" [ngClass]="{'unread': !_uiIsNotifReaded(notif),'readed': _uiIsNotifReaded(notif)}">
          <!-- header -->
          <div class="notif-card-header">
            <!-- badge -->
            <div *ngIf="!_uiIsNotifReaded(notif)" class="notif-badge">
            </div>
            <!-- badge -->

            <!-- icon or user pic -->
            <nb-icon *ngIf="notif.senderName == ''" class="notif-icon" icon="{{iconMap[notif.id]['icon']}}"
              pack="{{iconMap[notif.id]['pack']}}" status="{{notif.status}}"></nb-icon>

            <nb-user *ngIf="notif.senderName != ''" class="notif-sender" [name]="notif.senderName"
              [title]="notif.senderTitle" [picture]="notif.senderPicture" onlyPicture>
            </nb-user>
            <!-- icon or user pic -->

            <div class="info-container">
              <div id="notif-title-{{notif.id}}" class="notif-title inner-html-content"
                ngClass="{{'status-'+notif.status}}" [innerHTML]="notif.titleHtml"></div>
              <div class="notif-subtitle">
                <span class="time-passed">{{elapsedTimeStrMap[notif.id]}}</span>
                <span class="time-createdOn">{{createdOnStrMap[notif.id]}} </span>
              </div>
            </div>

          </div>
          <!-- header -->

          <!-- body -->
          <div class="notif-card-body">
            <div id="notif-body-{{notif.id}}" class="notif-body inner-html-content" [innerHTML]="notif.bodyHtml"></div>
          </div>
          <!-- body -->

          <!-- footer -->
          <div class="notif-card-footer">
            <div class="btn-grp">
              <button *ngIf="notif.action != 'none' && notif.action != 'url'" nbButton size="tiny" status="info"
                title="{{notif.actionBtnText}}" class="action-btn" (mousedown)="_openNotif(notif, $event)">
                <nb-icon status="info" icon="expand-outline" pack="eva"></nb-icon>
                <span>{{notif.actionBtnText}}</span>
              </button>

              <a *ngIf="notif.action == 'url'" (mousedown)="_openNotif(notif, $event)" href="{{'/#'+notif.actionData}}"
                title="{{notif.actionBtnText}}" class="action-btn underline">
                <nb-icon status="info" icon="external-link-outline" pack="eva"></nb-icon>
                <span>{{notif.actionBtnText}}</span>
              </a>
            </div>

            <!-- reader-btns -->
            <button *ngIf="!_uiIsNotifReaded(notif)" nbButton size="tiny" title="Marcar como lida" class="reader-btns"
              (click)="_markNotifAsReaded(notif)">
              Marcar como lida
              <nb-icon icon="checkmark-outline" pack="eva"></nb-icon>
            </button>

            <button disabled *ngIf="_uiIsNotifReaded(notif)" nbButton size="tiny" title="Lida" class="reader-btns">
              Lida
              <nb-icon icon="done-all-outline" pack="eva"></nb-icon>
            </button>
            <!-- reader-btns -->
          </div>
          <!-- footer -->
        </div>
        <!-- notif-card -->
      </div>
    </cdk-virtual-scroll-viewport>
    <!-- notif-cards-list -->
  </div>
  <!-- body -->
</div>