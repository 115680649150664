import { Component, OnDestroy, OnInit } from '@angular/core';

import { UtilService } from 'app/@core';
import { FirestoreService, TM_Project, TM_ProjectTask, TM_Tag } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

@Component({
  selector: 'timer-list-tab-timer',
  templateUrl: './timer-list-tab-timer.component.html',
  styleUrls: ['./timer-list-tab-timer.component.scss']
})
export class TimerListTabTimerComponent implements OnInit, OnDestroy {

  className = 'TimerListTabTimerComponent';

  timerRef;
  syncData_serviceRef;

  oldWindowTitle = '';
  running = false;
  runnedTimeMs = 0;
  data = {
    h: 0,
    m: 0,
    s: 0,
    val: 0,
    timeStr: '00:00:00'
  };

  tmProjects: TM_Project[] = [];
  tmProjectTasks: TM_ProjectTask[] = [];
  tmTags: TM_Tag[] = [];

  constructor(
    public db: FirestoreService,
    private uiFeedBackCtrl: UiFeedBackService,
    protected utilCtrl: UtilService
  ) { }

  ngOnInit() {
    this.oldWindowTitle = window.document.title;
    this.syncData_service();
  }
  ngOnDestroy() {
    clearInterval(this.timerRef);
    clearInterval(this.syncData_serviceRef);
  }

  syncData_service() {
    this.syncData_serviceRef = setInterval(() => {
      if (this.running) {
        this.syncData();
        window.document.title = `${this.data.timeStr} | IC`;
      } else
        window.document.title = this.oldWindowTitle;
    });
  }

  syncData() {
    this.data.s = Math.floor((this.runnedTimeMs / 1000) % 60);
    this.data.m = Math.floor((this.runnedTimeMs / 1000 / 60) % 60);
    this.data.h = Math.floor((this.runnedTimeMs / 1000 / 3600) % 24);
    this.data.val = this.data.h + this.data.m / 60;
    this.data.timeStr = this.utilCtrl.formatters.millisecondsToHHMMSS(this.runnedTimeMs);
  }
  toggle() {
    this.running = !this.running;
    if (this.running) {
      const startTime = Date.now() - (this.runnedTimeMs || 0);
      this.timerRef = setInterval(() => {
        this.runnedTimeMs = Date.now() - startTime;
      });
    } else {
      clearInterval(this.timerRef);
    }
    console.log(`timer -> toggle:`, this.data);
  }
  reset() {
    clearInterval(this.timerRef);
    this.running = false;
    this.runnedTimeMs = 0;
    this.syncData();
    console.log(`timer -> reset:`, this.data);
  }
}
