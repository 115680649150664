<div class="mode mode_time_records">
    <div *ngIf="_loader" class="loader">
        <p>Carregando...</p>
    </div>

    <!-- inactive-msg -->
    <div *ngIf="!pmCardModal.board.integration.tm.active" class="inactive-msg">
        <h6>Integração de Gestão de Horas Inativa</h6>
        <p>
            A integração de gestão de horas não está ativa neste quadro.
            <br>
            Entre em contato com o administrador.
        </p>
    </div>
    <!-- inactive-msg -->

    <!-- time_records -->
    <div *ngIf="pmCardModal.board.integration.tm.active" class="time_records">

        <div class="header">
            <span>Horas Apontadas</span>
            <br>
            <span>
                <b>{{pmCardModal.getTotalHoursSum().s}}</b>
            </span>
        </div>

        <mode-time-records-record-type-pie-chart #ModeTimeRecordsRecordTypePieERef style="margin: auto;">
        </mode-time-records-record-type-pie-chart>

    </div>
    <!-- time_records -->
</div>