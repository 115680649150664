<nb-card>
  <nb-card-body>

    <label class="search-label" for="search">Pesquisar</label>
    <input nbInput [nbFilterInput]="dataSource" id="search" class="ic-form-control search-input" placeholder="Search">

    <table [nbTreeGrid]="dataSource" [nbSort]="dataSource" (sort)="updateSort($event)">

      <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
      <tr nbTreeGridRow [clickToToggle]="false" *nbTreeGridRowDef="let row; columns: allColumns"></tr>

      <ng-container [nbTreeGridColumnDef]="customColumn">
        <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(customColumn)" *nbTreeGridHeaderCellDef>
          {{customColumn}}
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row" (click)="selectNode(row.data)">
          <nb-fs-icon [isDir]="row.data.isDir" [expanded]="row.expanded"></nb-fs-icon>
          {{row.data[customColumn]}}
        </td>
      </ng-container>

      <ng-container *ngFor="let column of defaultColumns; let index = index" [nbTreeGridColumnDef]="column"
        [showOn]="getShowOn(index)">
        <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(column)" *nbTreeGridHeaderCellDef>
          {{column}}
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data[column] || '-'}}</td>
      </ng-container>

    </table>

  </nb-card-body>
</nb-card>