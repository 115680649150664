<div class="reporting-preferences">

    <div *ngIf="_reportingCtrl.loading" class="loader">Carregando...</div>

    <div class="modes">
        <!-- pref-list -->
        <div *ngIf="_reportingCtrl._reportingPreferencesMode == 'pref-list'" class="mode pref-list">
            <div class="input-wrap">
                <label for="input_reportPref">
                    Relatório
                </label>
                <ng-select id="input_reportPref" name="input_reportPref" [(ngModel)]="_reportingCtrl.selectedReportPref"
                    (change)="input_reportPrefChange()" [compareWith]="_utilCtrl.compareFn">
                    <ng-option *ngFor="let rp of _reportingCtrl.reportPreferences" [value]="rp">
                        <span>{{rp.title}}</span>
                        <span *ngIf="!rp.isPrivate"> (Público)</span>
                    </ng-option>
                </ng-select>
            </div>

            <button [disabled]="!_reportingCtrl.selectedReportPref" icButton title="Selecionar" status="success"
                size="small" (click)="changeMode('pref-selected')">
                <nb-icon icon="play-circle-outline" pack="eva"></nb-icon>
            </button>

            <div class="separator"></div>

            <button icButton title="Novo Relatório" status="primary" size="small" (click)="changeMode('create-pref')">
                <nb-icon icon="file-add-outline" pack="eva"></nb-icon>
            </button>
        </div>
        <!-- pref-list -->


        <!-- pref-selected not fullscreen -->
        <div *ngIf="_reportingCtrl._reportingPreferencesMode == 'pref-selected' && !_themeService.fullscreen"
            class="mode pref-selected">
            <div class="input-wrap">
                <label>
                    Relatório
                </label>
                <div class="pref-title">
                    <span>{{_reportingCtrl.selectedReportPref.title}}</span>
                    <span *ngIf="!_reportingCtrl.selectedReportPref.isPrivate"> (Público)</span>
                </div>
            </div>

            <button icButton title="Alterar Seleção" status="success" size="small" (click)="changeMode('pref-list')">
                <nb-icon icon="flip-outline" pack="eva"></nb-icon>
            </button>

            <div class="separator"></div>

            <button icButton title="Novo Relatório" status="primary" size="small" (click)="changeMode('create-pref')">
                <nb-icon icon="file-add-outline" pack="eva"></nb-icon>
            </button>

            <div class="separator"></div>

            <div class="ic-button-grp">
                <button icButton title="Atualizar Relatório" size="small" (click)="refreshData()">
                    <nb-icon icon="refresh-outline" pack="eva"></nb-icon>
                </button>

                <button icButton title="Informações" size="small" (click)="info()">
                    <nb-icon icon="info-outline" pack="eva"></nb-icon>
                </button>

                <button [disabled]="_reportingCtrl.selectedReportPref.uName != _authService.localUser.uName" icButton
                    title="Editar" size="small" (click)="changeMode('edit-pref')">
                    <nb-icon icon="edit-outline" pack="eva"></nb-icon>
                </button>

                <!-- Tornar Preset -->
                <button *ngIf="!_reportingCtrl.selectedReportPref.presetUnames.includes(_authService.localUser.uName)"
                    icButton title="Tornar Favorito" size="small" (click)="makePreset()">
                    <nb-icon icon="heart-outline" pack="eva"></nb-icon>
                </button>
                <button *ngIf="_reportingCtrl.selectedReportPref.presetUnames.includes(_authService.localUser.uName)"
                    icButton title="Remover Favorito" status="danger" size="small" (click)="unmakePreset()">
                    <nb-icon icon="heart" pack="eva"></nb-icon>
                </button>
                <!-- Tornar Preset -->

                <!-- Tornar Público/Privado -->
                <button *ngIf="_reportingCtrl.selectedReportPref.isPrivate"
                    [disabled]="_reportingCtrl.selectedReportPref.uName != _authService.localUser.uName" icButton
                    title="Tornar Público" size="small" (click)="makePublic()">
                    <nb-icon icon="people-outline" pack="eva"></nb-icon>
                </button>
                <button *ngIf="!_reportingCtrl.selectedReportPref.isPrivate"
                    [disabled]="_reportingCtrl.selectedReportPref.uName != _authService.localUser.uName" icButton
                    title="Tornar Privado" status="warning" size="small" (click)="makePrivate()">
                    <nb-icon icon="people" pack="eva"></nb-icon>
                </button>
                <!-- Tornar Público/Privado -->

                <button *ngIf="_reportingCtrl.selectedReportPref.isPrivate"
                    [disabled]="_reportingCtrl.selectedReportPref.uName != _authService.localUser.uName" icButton
                    title="Compartilhar" size="small" (click)="share()">
                    <nb-icon icon="share" pack="eva"></nb-icon>
                </button>

                <button icButton title="Copiar Link" size="small" (click)="copyLink()">
                    <nb-icon icon="link-2" pack="eva"></nb-icon>
                </button>

                <button *ngIf="!_themeService.fullscreen" icButton title="Tela Cheia" size="small"
                    (click)="_themeService.enterFullscreen()">
                    <nb-icon icon="expand-outline" pack="eva"></nb-icon>
                </button>

                <button [disabled]="_reportingCtrl.selectedReportPref.uName != _authService.localUser.uName" icButton
                    title="Apagar Relatório" status="danger" size="small" (click)="deleteConfirmation()">
                    <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
                </button>
            </div>
        </div>
        <!-- pref-selected not fullscreen -->


        <!-- pref-selected fullscreen -->
        <div *ngIf="_reportingCtrl._reportingPreferencesMode == 'pref-selected' && _themeService.fullscreen"
            class="mode pref-selected">
            <div class="input-wrap">
                <div class="pref-title fullscreen">
                    {{_reportingCtrl.selectedReportPref.title}}
                </div>
            </div>

            <div class="separator"></div>

            <div class="ic-button-grp">
                <button icButton title="Atualizar Relatório" size="small" (click)="refreshData()">
                    <nb-icon icon="refresh-outline" pack="eva"></nb-icon>
                </button>

                <button *ngIf="_themeService.fullscreen" icButton title="Sair Tela Cheia" status="primary" size="small"
                    (click)="_themeService.exitFullscreen()">
                    <nb-icon icon="collapse-outline" pack="eva"></nb-icon>
                </button>
            </div>
        </div>
        <!-- pref-selected fullscreen -->

        <!-- new-pref -->
        <div *ngIf="_reportingCtrl._reportingPreferencesMode == 'create-pref'" class="mode new-pref">
            <form class="input-wrap" id="newReportPrefForm" #newReportPrefForm="ngForm">
                <label for="input_title">
                    Novo Relatório
                </label>
                <input class="ic-form-control" type="text" id="input_title" name="input_title"
                    [(ngModel)]="newReportPref.title" required [minlength]="input_title.minlength"
                    [maxlength]="input_title.maxlength" Placeholder="Título para novo Relatório">
            </form>

            <div class="separator"></div>

            <div class="ic-button-grp">
                <button icButton status="danger" title="Cancelar" size="small" (click)="changeMode('pref-list')">
                    <nb-icon icon="close-outline" pack="eva"></nb-icon>
                </button>
                <button [disabled]="!newReportPrefForm.valid" icButton status="primary" title="Salvar" size="small"
                    (click)="saveReport()">
                    <nb-icon icon="save-outline" pack="eva"></nb-icon>
                </button>
            </div>
        </div>
        <!-- new-pref -->


        <!-- edit-pref -->
        <div *ngIf="_reportingCtrl._reportingPreferencesMode == 'edit-pref'" class="mode edit-pref">
            <form class="input-wrap" id="editReportPrefForm" #editReportPrefForm="ngForm">
                <label for="input_title">
                    Editar Relatório
                </label>
                <input class="ic-form-control" type="text" id="input_title" name="input_title"
                    [(ngModel)]="editReportPref.title" required [minlength]="input_title.minlength"
                    [maxlength]="input_title.maxlength" Placeholder="Título do Relatório">
            </form>

            <div class="separator"></div>

            <div class="ic-button-grp">
                <button icButton status="danger" title="Cancelar" size="small" (click)="changeMode('pref-list')">
                    <nb-icon icon="close-outline" pack="eva"></nb-icon>
                </button>
                <button [disabled]="!editReportPrefForm.valid" icButton status="primary" title="Salvar" size="small"
                    (click)="updateReport()">
                    <nb-icon icon="save-outline" pack="eva"></nb-icon>
                </button>
            </div>
        </div>
        <!-- edit-pref -->
    </div>

</div>