import { Component, ElementRef, Inject, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export interface UiFeedBackLoaderModalDialogData {
  message: string;
}

@Component({
  selector: 'app-ui-feed-back-loader-modal',
  templateUrl: 'ui-feed-back-loader-modal.component.html',
  styleUrls: ['./../ui-feed-back-modals.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UiFeedBackLoaderModalComponent {

  message = '';

  lottie = {
    loop: true,
    autoplay: true,
    path: 'assets/animations/uiFeedBack.service/loader.json'
  };

  @ViewChild('LoaderModalContent', { read: ElementRef, static: false }) loaderModalContent: ElementRef;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UiFeedBackLoaderModalDialogData,
    private dialog: MatDialog
  ) {
    this.message = data.message;
  }

}
