<mat-dialog-content>
  <nb-card [nbSpinner]="loader" nbSpinnerMessage="" nbSpinnerSize="large" nbSpinnerStatus="basic">
    <nb-card-header>
      Selecionar Documento

      <button class="close" mat-button matDialogClose>
        <span aria-hidden="true">&times;</span>
      </button>
    </nb-card-header>
    <nb-card-body>
      <form #idForm="ngForm">
        <div class="row no-gutters">
          <label class="col-12" for="sMDocId">ID</label>

          <div class="col-8">
            <input style="font-family: Consola-Bold" class="ic-form-control" type="name" id="sMDocId" name="sMDocId"
              [(ngModel)]="sMDocId" required [pattern]="utilCtrl.regexValidators.noSpaces">
          </div>

          <div class="col-auto">
            <button [disabled]="!idForm.valid" nbButton status="primary" size="small" (click)="search()">
              <nb-icon icon="search-outline" pack="eva"></nb-icon>
            </button>
            <button nbButton [disabled]="sMDocIdStatus!='found'" title="Outro Documento" status="primary" size="small"
              (click)="goToSmDocDetail(sMDocId)">
              <nb-icon icon="external-link-outline" pack="eva"></nb-icon>
            </button>
          </div>
        </div>
      </form>

      <p *ngIf="sMDocIdStatus=='not-found'" style="color: red;">Documento não encontrado!</p>

      <div class="row">
        <div class="col-12">
          <label class="col-12">Título</label>
          <h6 class="ic-form-control">{{sMDoc.title}}</h6>
        </div>
      </div>

      <div class="row no-gutters">
        <label class="col-12 sm-doc-input">Solicitante*</label>
        <div class="col-4">
          <h6 class="ic-form-control">{{sMDoc.callerUname}}</h6>
        </div>
        <div class="col-8">
          <h6 class="ic-form-control">{{sMDoc.callerFullName}}</h6>
        </div>
      </div>

    </nb-card-body>
  </nb-card>

</mat-dialog-content>
