import firebase from "firebase/compat/app";

import { _translateBoolean } from "./report_formatters";
import { REPORTING_EMPTY_FIELD_DISP_VALUE } from "./report.consts";
import { ReportingOrderDirection, ReportingSortField } from "./report.types";

const TIMESTAMP_KEYS = ["seconds", "nanoseconds"];
const TIMESTAMP2_KEYS = ["_seconds", "_nanoseconds"];

function __compareObjectKeysArrays(keys1: string[], keys2: string[]): boolean {
    let equals = true;
    for (let k1 of keys1) {
        if (keys2.indexOf(k1) == -1) {
            equals = false;
            break;
        }
    }
    if (equals)
        for (let k2 of keys2) {
            if (keys1.indexOf(k2) == -1) {
                equals = false;
                break;
            }
        }
    return equals;
}
function __newTimestamp(seconds: number, nanoseconds: number): firebase.firestore.Timestamp { return new firebase.firestore.Timestamp(seconds, nanoseconds) }
/**
 * Função para facilitar a comparação entre valores, para valores vazios sempre retornará "".
 * @param value 
 * @returns 
 */
function _valueStandardizer(value: any): any {

    // null | undefined
    if (!value) return "";

    // Array
    if (Array.isArray(value)) {
        if (value.length === 0) return "";
        return value.map((item) => _valueStandardizer(item));
    }

    // Object
    if (typeof value === 'object') {
        const value_keys = Object.keys(value);

        // Date or Timestamp
        if (value instanceof Date) return value.getTime();
        if (__compareObjectKeysArrays(value_keys, TIMESTAMP_KEYS))
            return __newTimestamp(value['seconds'], value['nanoseconds']).toDate().getTime();
        if (__compareObjectKeysArrays(value_keys, TIMESTAMP2_KEYS))
            return __newTimestamp(value['_seconds'], value['_nanoseconds']).toDate().getTime();

        return JSON.stringify(value);
    }

    // Date and time format - ISO 8601
    const dateTimeRegex = /\d{4}(.\d{2}){2}(\s|T)(\d{2}.){2}\d{2}/g;
    if (dateTimeRegex.test(value)) return new Date(value).getTime();

    // Number
    const tempNum = Number(value);
    if (!isNaN(tempNum)) return tempNum;

    return value;
}
export function _compareFunction(v1: any, v2: any) {
    const vA = _valueStandardizer(v1);
    const vB = _valueStandardizer(v2);
    return vA == vB;
}
export function _genFilterValue(value: any): string {
    let v = '';
    // Tratar valores nulos e indefinidos
    if (value === null || value === undefined) {
        return REPORTING_EMPTY_FIELD_DISP_VALUE;
    }

    // Tratar valores booleanos
    if (typeof value === 'boolean') {
        v = _translateBoolean(value);
    }
    // Tratar valores numéricos
    else if (typeof value === 'number') {
        v = value.toLocaleString('pt-BR');
    }
    // Tratar valores string (remover HTML)
    else if (typeof value === 'string') {
        v = value.replace(/<(?:.|\n)*?>/gm, ' ');
    }
    // Tratar outros tipos de dados
    else {
        v = value + "";
    }

    // Caso o valor seja vazio após a remoção de HTML
    if (v.trim() === '') {
        v = REPORTING_EMPTY_FIELD_DISP_VALUE;
    }
    return v;
}
export function _orderByCore(a: any, b: any, direction: ReportingOrderDirection): number {
    let aValue = _valueStandardizer(a);
    let bValue = _valueStandardizer(b);
    if (Array.isArray(aValue)) {
        aValue = aValue.sort((a, b) => _orderByCore(a, b, direction));
        aValue = aValue[0];
    }
    if (Array.isArray(bValue)) {
        bValue = bValue.sort((a, b) => _orderByCore(a, b, direction));
        bValue = bValue[0];
    }
    if (aValue < bValue) return direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return direction === 'asc' ? 1 : -1;
    return 0;
};
/**
 * 
 * @param data any[]
 * @param sortFields Array of objects containing fieldName and direction
 * @returns 
 */
export function _localSort(data: any[], sortFields: ReportingSortField[] = []): any[] {
    return data.sort((a, b) => {
        let result = 0;
        for (let i = 0; i < sortFields.length; i++) {
            const { fieldName, direction } = sortFields[i];
            result = _orderByCore(a[fieldName], b[fieldName], direction);

            if (result !== 0)
                break;
        }
        return result;
    });
}
