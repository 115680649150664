import firebase from "firebase/compat/app";

export type SM_DocSLAStage = 'in_progress' | 'cancelled' | 'paused' | 'completed';

export class SM_DocSLA {

  id: string;
  smDocId: string;
  slaDefId: string;
  calendarId: string;

  /**
   * in_progress - Condition `start` or `reset`
   * cancelled - Condition `cancel`
   * paused - Condition `pause`
   * completed - Condition `stop`
   */
  stage: SM_DocSLAStage;

  active: boolean;
  breached: boolean;
  firstBreachedOn: firebase.firestore.Timestamp;

  /** Hora de início - stage: in_progress*/
  startTime: firebase.firestore.Timestamp;
  /** Hora de parada - stage: completed*/
  endTime: firebase.firestore.Timestamp;

  /** Hora da última Pausa */
  lastPausedOn: firebase.firestore.Timestamp;
  /** Duração última pausa */
  lastPausedDurationMs: number;

  /** Prazo - Tempo em que o ANS será violado. Ajustado para duração da pausa.*/
  plannedEndTime: firebase.firestore.Timestamp;
  plannedEndTimeDurationMs: number;

  /** Tempo real decorrido */
  durationMs: number;
  /** % real decorrido */
  percentage: number;
  /** Tempo real restante */
  timeLeftMs: number;


  /** Tempo útil decorrido */
  businessDurationMs: number;
  /** % de tempo útil decorrido */
  businessPercentage: number;
  /** Tempo útil restante */
  businessTimeLeftMs: number;


  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<SM_DocSLA>) {

    this.id = '';
    this.smDocId = '';
    this.slaDefId = '';
    this.calendarId = '';

    this.stage = 'in_progress';

    this.active = true;
    this.breached = false;
    this.firstBreachedOn = null;

    this.startTime = null;
    this.endTime = null;

    this.lastPausedOn = null;
    this.lastPausedDurationMs = 0;

    this.plannedEndTime = null;
    this.plannedEndTimeDurationMs = 0;

    this.durationMs = 0;
    this.percentage = 0;
    this.timeLeftMs = 0;

    this.businessDurationMs = 0;
    this.businessPercentage = 0;
    this.businessTimeLeftMs = 0;

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
