<mat-dialog-content>

  <nb-card [nbSpinner]="loader" nbSpinnerMessage="Loading..." nbSpinnerSize="large" nbSpinnerStatus="basic">
    <nb-card-header>
      Create Role User Modal
      <button class="close" mat-button matDialogClose>
        <span aria-hidden="true">&times;</span>
      </button>
    </nb-card-header>
    <nb-card-body>


      <div class="row">
        <div class="col-4">
          <label>User</label>
          <h6 class="ic-form-control">{{selectedUser.uName}}</h6>
        </div>

        <div class="col-8">
          <label>User Name</label>
          <h6 class="ic-form-control">{{selectedUser.fullName}}</h6>
        </div>
      </div>


      <div class="row">
        <div class="col-4">
          <button nbButton status="primary" size="small" (click)="selectUser()">
            Select User
            <nb-icon icon="plus-outline" pack="eva"></nb-icon>
          </button>
        </div>
      </div>

      <form #form="ngForm">
        <div class="row">
          <div class="col-6">
            <label>FROM</label>
            <input class="ic-form-control" [(ngModel)]="newRoleUser.from" id="input_from" type="date" name="input_from"
              required />
          </div>

          <div class="col-6">
            <label>TO</label>
            <input class="ic-form-control" [(ngModel)]="newRoleUser.to" id="input_to" type="date" name="input_to"
              required />
          </div>
        </div>
      </form>

      <nb-card>
        <nb-card-body>
          <div class="row">
            <div class="col-md-auto">
              <button nbButton status="danger" size="small" (click)="cancel()">
                <nb-icon icon="close-outline" pack="eva"></nb-icon>
                cancel
              </button>
            </div>
            <div class="col-md-auto">
              <button [disabled]="!form.valid || selectedUser.uName == ''" nbButton status="primary" size="small"
                (click)="save()">
                <nb-icon icon="save-outline" pack="eva"></nb-icon>
                save
              </button>
            </div>
          </div>
        </nb-card-body>
      </nb-card>


    </nb-card-body>
  </nb-card>

</mat-dialog-content>
