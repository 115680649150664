import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { UtilService } from './@core';
import { FirePerformance } from './@firebase';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class AppComponent implements OnInit {
  title = 'icontrol';
  changeDetectorTimerRef;
  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    public utilCtrl: UtilService,
    public Performance: FirePerformance,
  ) { }
  ngOnInit() {
    this.changeDetectorTimerRef = setInterval(() => {
      this.changeDetector.detectChanges();
    }, 250);

    this.displayDevMessage();
  }
  ngOnDestroy() {
    clearInterval(this.changeDetectorTimerRef);
  }
  displayDevMessage() {
    const title = "   Greetings from the iControl Dev Team!";
    const titleStyle = `
    font-size: 30px;
    line-height: normal;
    font-weight: bold;
    color: #FF4500;`;

    const subTitle = "Spotted a bug 🐛? Your vigilance is legendary!";
    const subTitleStyle = `
    font-size: 20px;
    line-height: normal;
    color: #1E90FF;`;

    const contact = "Reach out to us at: contato@icontrol.tech";
    const contactStyle = `
    font-size: 15px;
    line-height: normal;
    color: #32CD32;`;

    console.log("%c" + title, titleStyle);
    console.log("%c" + subTitle, subTitleStyle);
    console.log("%c" + contact, contactStyle);
  }
}
