<div class="ic-overlay-modal magic-table-settings-modal">
  <nb-card class="modal_card">
    <nb-card-header>
      <nb-actions size="medium">
        <nb-action>
          Configurações da tabela
        </nb-action>
      </nb-actions>
      <div class="close_btn_div">
        <button class="close" (click)="_close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="container">

        <div class="row">
          <div class="col-5">
            <p>Disponiveis</p>
            <div class="col_options_wrap">
              <button *ngFor="let c of availableColumns; let i=index" (click)="select_availableColumn(i, c)"
                [ngClass]="{'selected': c == seleted_availableColumn}">
                {{c}}
              </button>
            </div>
          </div>
          <div class="col-1 ctrl_btn_wrap">
            <button icButton [disabled]="seleted_availableColumn_i==-1" (click)="makeColumSelected()">
              <nb-icon icon="arrow-forward-outline" pack="eva"></nb-icon>
            </button>
            <button icButton [disabled]="seleted_selectedColumn_i==-1" (click)="makeColumAvailable()">
              <nb-icon icon="arrow-back-outline" pack="eva"></nb-icon>
            </button>
          </div>


          <div class="col-5">
            <p>Selecionado</p>
            <div class="col_options_wrap">
              <button *ngFor="let c of tableSettings.selectedColumns;let i=index" (click)="select_selectedColumn(i, c)"
                [ngClass]="{'selected': c == seleted_selectedColumn}">
                {{c}}
              </button>
            </div>
          </div>

          <div class="col-1 ctrl_btn_wrap">
            <button icButton [disabled]="seleted_selectedColumn_i == -1 || seleted_selectedColumn_i == 0"
              (click)="selectedColumnUp()">
              <nb-icon icon="arrow-upward-outline" pack="eva"></nb-icon>
            </button>
            <button icButton
              [disabled]="seleted_selectedColumn_i == -1 || seleted_selectedColumn_i == tableSettings.selectedColumns.length - 1"
              (click)="selectedColumnDown()">
              <nb-icon icon="arrow-downward-outline" pack="eva"></nb-icon>
            </button>
          </div>
        </div>
      </div>

      <button class="reset-defaults-btn" (click)="resetDefaults()">
        Restaurar padrões
      </button>

    </nb-card-body>
    <nb-card-footer>
      <div class="ic-button-grp">
        <button icButton status="danger" size="small" (click)="_close()">
          <nb-icon icon="close-outline" pack="eva"></nb-icon>
          Cancelar
        </button>

        <button icButton status="primary" size="small" (click)="save()">
          <nb-icon icon="save-outline" pack="eva"></nb-icon>
          Salvar
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>