<div class="mode mode_attachments">
    <div *ngIf="_loader" class="loader">
        <p>Carregando...</p>
    </div>

    <div class="pm-attachment" *ngFor="let attachmentsData of _attachments">
        <div class="attachment-icon" *ngIf="attachmentsData.icon">
            <nb-icon icon="{{attachmentsData.icon.icon}}" pack="{{attachmentsData.icon.pack}}"
                [style.color]="attachmentsData.icon.colorHex"></nb-icon>
        </div>

        <div class="attachment-name">
            <span class="attachment-name">
                <a [href]="attachmentsData.attachment.url" target="_blank">{{attachmentsData.attachment.name}}</a>
            </span>
            <span class="attachment-subtitle">
                ({{pmCardModal.utilCtrl.formatters.formatBytes(attachmentsData.attachment.size)}})
                {{pmCardModal.utilCtrl.timestamp.toLocalDate(attachmentsData.attachment.createdOn)}}
            </span>
        </div>

        <div class="attachment-opt">
            <button [disabled]="!pmCardModal.isBoardActive" nbButton status="danger" appearance="ghost" size="tiny"
                (click)="_remAttachment(attachmentsData.attachment)">
                <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
            </button>
        </div>
    </div>

    <!-- new attachment -->
    <div class="new-attachment">
        <div class="attachment-btn">
            <input required [multiple]="true" style="display:none;" id="input_new_attachment"
                name="input_new_attachment" type="file" (change)="_addAttachment($event)" />
            <button [disabled]="!pmCardModal.isBoardActive" nbButton status="primary" size="small"
                onclick="document.getElementById('input_new_attachment').click()">
                <nb-icon icon="attach-outline" pack="eva"></nb-icon>
                Novo Anexo
            </button>
        </div>
        <!-- new attachment -->
    </div>
    <!-- attachments -->
</div>