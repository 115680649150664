<mat-dialog-content>

  <nb-card [nbSpinner]="loader" nbSpinnerMessage="Loading..." nbSpinnerSize="large" nbSpinnerStatus="basic"
    class="col-12">
    <nb-card-header>
      Edit Auth Values Modal

      <button class="close" mat-button matDialogClose>
        <span aria-hidden="true">&times;</span>
      </button>
    </nb-card-header>
    <nb-card-body>


      <div class="row">
        <div class="form-group col-4">
          <label>Object Id</label>
          <h6 class="ic-form-control">{{localObject.id}}</h6>
        </div>

        <div class="form-group col-8">
          <label>Object Description</label>
          <h6 class="ic-form-control">{{localObject.description}}</h6>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-4">
          <label>Field Id</label>
          <h6 class="ic-form-control">{{localObjectField.id}}</h6>
        </div>

        <div class="form-group col-8">
          <label>Field Description</label>
          <h6 class="ic-form-control">{{localObjectField.description}}</h6>
        </div>
      </div>


      <nb-card>
        <nb-card-header>
          Values
        </nb-card-header>
        <nb-card-body>

          <div class="row">
            <!-- Manual Entry -->
            <div class="col-6">
              <nb-card>
                <nb-card-header>
                  Manual Entry
                </nb-card-header>
                <nb-card-body>

                  <form #formManual="ngForm">
                    <div class="row">

                      <label>Entry</label>
                      <input class="ic-form-control" type="text" id="input_manual_newValue" name="input_manual_newValue"
                        [(ngModel)]="manual_newValue" required placeholder="New Entry"
                        [pattern]="utilCtrl.regexValidators.noSpaces">
                      <p *ngIf="localRoleAuth.authPairs[roleAuth_AP_field_index].values.indexOf(manual_newValue) != -1"
                        style="color: red;font-weight: bold;">
                        Entry Already exists
                      </p>
                    </div>

                    <div class="row">
                      <button nbButton status="primary" size="small"
                        [disabled]="!formManual.valid || localRoleAuth.authPairs[roleAuth_AP_field_index].values.indexOf(manual_newValue) != -1"
                        (click)="addManual()">
                        <nb-icon icon="plus-outline" pack="eva"></nb-icon>
                        Add Entry
                      </button>
                    </div>
                  </form>

                </nb-card-body>
              </nb-card>
            </div>
            <!-- Manual Entry -->

            <!-- Listed Entry -->
            <div class="col-6" *ngIf="!localObjectField.valuesFromColection">
              <nb-card>
                <nb-card-header>
                  Listed Entry
                </nb-card-header>
                <nb-card-body>

                  <form #formLiested="ngForm">
                    <div class="row">

                      <label>Entry</label>
                      <select required id="inputUF" name="input_listed_newValue" class="ic-form-control"
                        [(ngModel)]="listed_newValue">

                        <option></option>

                        <option *ngFor="let v of this.localObjectField.values; let opt_i = index"
                          [disabled]="localRoleAuth.authPairs[roleAuth_AP_field_index].values.indexOf(v.value) != -1"
                          [ngValue]="v.value">
                          {{v.value}} - {{v.description}}
                        </option>

                      </select>

                    </div>

                    <div class="row">
                      <button nbButton status="primary" size="small"
                        [disabled]="!formLiested.valid || localRoleAuth.authPairs[roleAuth_AP_field_index].values.indexOf(listed_newValue) != -1"
                        (click)="addListed()">
                        <nb-icon icon="plus-outline" pack="eva"></nb-icon>
                        Add Entry
                      </button>
                    </div>
                  </form>

                </nb-card-body>
              </nb-card>
            </div>
            <!-- Listed Entry -->

            <!-- Values From Colection -->
            <div class="col-6" *ngIf="localObjectField.valuesFromColection">
              <nb-card>
                <nb-card-header>
                  Values From Colection: {{localObjectField.valuesColection}}[{{localObjectField.valuesField}}]
                </nb-card-header>
                <nb-card-body>

                  <form #formValuesFromColection="ngForm">
                    <div class="row">

                      <label>Entry</label>
                      <select required id="inputUF" name="input_valuesFromColection_newValue" class="ic-form-control"
                        [(ngModel)]="valuesFromColection_newValue">

                        <option></option>

                        <option *ngFor="let v of this.valuesFromColection_values; let opt_i = index"
                          [disabled]="localRoleAuth.authPairs[roleAuth_AP_field_index].values.indexOf(v) != -1"
                          [ngValue]="v">
                          {{v}}
                        </option>

                      </select>

                    </div>

                    <div class="row">
                      <button nbButton status="primary" size="small"
                        [disabled]="!formValuesFromColection.valid || localRoleAuth.authPairs[roleAuth_AP_field_index].values.indexOf(valuesFromColection_newValue) != -1"
                        (click)="addValuesFromColection()">
                        <nb-icon icon="plus-outline" pack="eva"></nb-icon>
                        Add Entry
                      </button>
                    </div>
                  </form>

                </nb-card-body>
              </nb-card>
            </div>
            <!-- Values From Colection -->


          </div>



          <table *ngIf="roleAuth_AP_field_index!=-1 && localRoleAuth.authPairs[roleAuth_AP_field_index].values.length>0"
            class="clean-table">
            <thead>
              <tr>
                <th style="width: 30px;">Nº</th>
                <th style="width: 30px;">Value</th>
                <th style="width: 80px;">Description</th>

                <th style="width: 30px;"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ap of localRoleAuth.authPairs[roleAuth_AP_field_index].values; let i=index">
                <td>{{i+1}}</td>
                <td>
                  <p>{{ap}}</p>
                </td>
                <td>
                  <p>{{getObjFieldValueDescriptonData(ap)}}</p>
                </td>
                <td>
                  <button nbButton status="danger" size="small" (click)="removeConfirmation(i)">
                    <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </nb-card-body>
      </nb-card>

      <nb-card>
        <nb-card-body>

          <div class="row">
            <div class="col-12 padding" style="text-align: center">
              <div class="button-container">
                <button nbButton status="primary" size="small" (click)="save()">
                  <nb-icon icon="save-outline" pack="eva"></nb-icon>
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </nb-card-body>
      </nb-card>

    </nb-card-body>

  </nb-card>

</mat-dialog-content>
