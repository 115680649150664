<div class="pm-card-upload-modal">
  <nb-card class="modal-card" [nbSpinner]="_loader">
    <nb-card-header>
      <nb-actions size="medium">
        <nb-action>
          Carregar cartões via planilha
        </nb-action>
      </nb-actions>
      <div class="close-btn-div">
        <button class="close" mat-button (click)="safeClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <!-- pm-card-upload-inner -->

      <nb-stepper #stepper orientation="horizontal" disableStepNavigation>

        <!-- Baixar Modelo -->
        <nb-step [label]="label_1">
          <ng-template #label_1>Baixar Modelo</ng-template>
          <h4>#1 Baixar Modelo</h4>

          <div class="lottie-row">
            <ng-lottie [options]="userWorkingLottie" [width]="'300px'">
            </ng-lottie>
          </div>

          <p>
            Baixe o Modelo em xlsx para o preenchimento de dados da carga
            <a href="assets/project-management/icontrol_PM_Card_Upload_Model.xlsx"
              download="icontrol_PM_Card_Upload_Model.xlsx">Baixar</a>
          </p>

          <hr>
          <div class="ic-button-grp">
            <button icButton size="small" nbStepperNext>
              Avançar
              <nb-icon icon="arrow-forward-outline" pack="eva"></nb-icon>
            </button>
          </div>
        </nb-step>
        <!-- Baixar Modelo -->

        <!-- Preencher Modelo no excel -->
        <nb-step [label]="label_2">
          <ng-template #label_2>Preencher Modelo</ng-template>
          <h4>#2 Preencher Modelo no excel</h4>
          <p>
            Preencha o modelo conforme abaixo:
          </p>

          <table class="clean-table">
            <thead>
              <tr>
                <th>Coluna</th>
                <th>Exemplo</th>
                <th>Descrição</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>title</td>
                <td>Exemple Card</td>
                <td>Título do cartão (String)</td>
              </tr>
              <tr>
                <td>description</td>
                <td>Exemple Card Description</td>
                <td>Descrição do cartão (String)</td>
              </tr>
              <tr>
                <td>route</td>
                <td>A fazer</td>
                <td>Nome da Rota do cartão (String)</td>
              </tr>
              <tr>
                <td>priority</td>
                <td>P1</td>
                <td>Prioridade do cartão (P1, P2, P3, P4, P5 ou vazio)</td>
              </tr>
              <tr>
                <td>startDate</td>
                <td>2021-06-30T00:00</td>
                <td>Data de vencimento do cartão (AAAA-MM-DDTHH:MM)</td>
              </tr>
              <tr>
                <td>dueDate</td>
                <td>2021-06-30T00:00</td>
                <td>Data de vencimento do cartão (AAAA-MM-DDTHH:MM)</td>
              </tr>
              <tr>
                <td>done</td>
                <td>X</td>
                <td>Indicador se a data de vencimento foi cumprida (X ou vazio)</td>
              </tr>
              <tr>
                <td>doneAt</td>
                <td>2021-06-30T00:00</td>
                <td>Data em que o cartão foi concluído (AAAA-MM-DDTHH:MM)</td>
              </tr>
              <tr>
                <td>assignedTo</td>
                <td>UNAME;UNAME2</td>
                <td>Usuários atribuídos ao cartão (String; separado por ponto e vírgula)</td>
              </tr>
              <tr>
                <td>labels</td>
                <td>Defeito;Feature</td>
                <td>Rótulos do cartão (String; separado por ponto e vírgula)</td>
              </tr>
              <tr>
                <td>toDoLists</td>
                <td>
                  Utilizar o formato para Listas de To-Dos
                </td>
                <td>Listas de tarefas do cartão (Para mais informações consulte a documentação sobre "Formato de To-Dos
                  e Listas de To-Dos")</td>
              </tr>
            </tbody>
          </table>

          <hr>
          <div class="ic-button-grp">
            <button icButton size="small" nbStepperPrevious>
              <nb-icon icon="arrow-back-outline" pack="eva"></nb-icon>
              Voltar
            </button>
            <button icButton size="small" nbStepperNext>
              Avançar
              <nb-icon icon="arrow-forward-outline" pack="eva"></nb-icon>
            </button>
          </div>
        </nb-step>
        <!-- Preencher Modelo no excel -->

        <!-- Ler a base -->
        <nb-step [label]="label_3">
          <ng-template #label_3>Ler a base</ng-template>
          <h4>#3 Ler a base</h4>

          <div class="lottie-row">
            <ng-lottie [options]="readingfileLottie" [width]="'100px'">
            </ng-lottie>
          </div>

          <p>
            1 - Selecione o arquivo no seu PC
            <br>
            2 - Clique em Ler Arquivo
            <br>
            3 - Verifique a quantidade de linhas lidas do seu arquivo
          </p>

          <input [multiple]="false" style="display:none;" id="input_cards_file" name="input_cards_file" type="file"
            (change)="incoming_cards_file($event)" />

          <div class="ic-button-grp">
            <button icButton title="Escolher Arquivo" onclick="document.getElementById('input_cards_file').click()">
              <nb-icon icon="folder-outline" pack="eva"></nb-icon>
              Escolher Arquivo
            </button>

            <button icButton [disabled]="cards_file === undefined" status="primary" (click)="load_cards_file()">
              <nb-icon icon="book-open-outline" pack="eva"></nb-icon>
              ler Arquivo
            </button>
          </div>

          <p>Numero linhas lidas do arquivo {{cards_file_lines.length}}</p>

          <hr>
          <div class="ic-button-grp">
            <button icButton size="small" nbStepperPrevious>
              <nb-icon icon="arrow-back-outline" pack="eva"></nb-icon>
              Voltar
            </button>
            <button icButton size="small" nbStepperNext [disabled]="cards_file_lines.length == 0"
              (click)="generate_cards()">
              Avançar
              <nb-icon icon="arrow-forward-outline" pack="eva"></nb-icon>
            </button>
          </div>
        </nb-step>
        <!-- Ler a base -->

        <!-- Confirmar Dados -->
        <nb-step [label]="label_4">
          <ng-template #label_4>Confirmar Dados</ng-template>
          <h4>#4 Confirmar Dados</h4>

          <div class="lottie-row">
            <ng-lottie [options]="loadSheetLottie" [width]="'200px'">
            </ng-lottie>
          </div>

          <p>
            Verifique se todas as entradas estão de acordo com o esperado!
            <br>
            <br>
            ⚠️Caso ocorram erros nesta fase a ferramenta não permitirá seguir com a carga!
          </p>

          <div class="padding"></div>

          <div *ngIf="!_loader && erros.length > 0">
            <h6>⛔ Erros identificados !</h6>
            <table class="clean-table">
              <thead>
                <tr>
                  <th>Linha</th>
                  <th>Descrição</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let team of erros">
                  <td>{{team.line}}</td>
                  <td>{{team.text}}</td>
                </tr>
              </tbody>
            </table>
            <div class="padding"></div>
          </div>

          <div style="width: 100%;padding-bottom: 10px;">
            <magic-table [data]="cardsTable_data" [columns]="cardsTable_columns">
            </magic-table>
          </div>

          <hr>
          <div class="ic-button-grp">
            <button icButton size="small" nbStepperPrevious>
              <nb-icon icon="arrow-back-outline" pack="eva"></nb-icon>
              Voltar
            </button>
            <button icButton size="small" nbStepperNext [disabled]="_loader || erros.length > 0">
              Avançar
              <nb-icon icon="arrow-forward-outline" pack="eva"></nb-icon>
            </button>
          </div>
        </nb-step>
        <!-- Confirmar Dados -->

        <!-- Salvar Dados -->
        <nb-step [label]="label_5">
          <ng-template #label_5>Salvar Dados</ng-template>
          <h4>#5 Salvar Dados</h4>

          <div class="lottie-row">
            <ng-lottie [options]="uploadFileCloudLottie" [width]="'300px'">
            </ng-lottie>
          </div>

          <p>
            Ao selecionar o botão abaixo a carga de dados será salva no Banco!
          </p>

          <button icButton status="primary" size="medium" (click)="save_cards()">
            Salvar Cartões
            <nb-icon icon="save-outline" pack="eva"></nb-icon>
          </button>

          <hr>
          <div class="ic-button-grp">
            <button icButton size="small" nbStepperPrevious>
              <nb-icon icon="arrow-back-outline" pack="eva"></nb-icon>
              Voltar
            </button>
          </div>
        </nb-step>
        <!-- Salvar Dados -->

        <!-- Carga Efetuada -->
        <nb-step [label]="label_6">
          <ng-template #label_6>Carga Efetuada</ng-template>
          <h4>#6 Carga Efetuada</h4>

          <div class="lottie-row">
            <ng-lottie [options]="loadSheetLottie" [width]="'200px'">
            </ng-lottie>
          </div>

          <p>
            <nb-icon icon="download-outline" pack="eva"></nb-icon>
            Caso seja necessario faça o download dos cartoes na planilha abaixo
          </p>

          <div style="width: 100%;padding-bottom: 10px;">
            <magic-table [data]="cardsTable_data" [columns]="download_cardsTable_columns">
            </magic-table>
          </div>

          <hr>
          <button icButton size="small" (click)="safeClose()">
            <nb-icon icon="log-out-outline" pack="eva"></nb-icon>
            Fechar
          </button>
        </nb-step>
        <!-- Carga Efetuada -->

      </nb-stepper>

      <!-- pm-card-upload-inner -->
    </nb-card-body>
  </nb-card>
</div>