import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { KeybindsService } from "app/@core";

import { OverlayService } from "app/@theme";
import { DbTableComponent } from "app/components/@db-table/db-table.component";
import { DbTableRowData, DbTableUiFilterIndex } from "app/components/@db-table/db-table.types";
import { dbTableCleanFilter } from "app/components/@db-table/shared/db-table-clean-filter";
import { dbTableFilterValueDetermination } from "app/components/@db-table/shared/db-table-filter-value-determination";
import { dbTableGetFilterStatus } from "app/components/@db-table/shared/db-table-get-filter-status";
import { dbTableOrderByCore } from "app/components/@db-table/shared/db-table-order-by-core";
import { dbTableSetFilterStatus } from "app/components/@db-table/shared/db-table-set-filter-status";
import { dbTableToogleSort } from "app/components/@db-table/shared/db-table-toogle-sort";
import { dbTableUpdateFiltredRows } from "app/components/@db-table/shared/db-table-update-filtred-rows";

@Component({
    selector: 'db-table-col-options-overlay',
    templateUrl: './db-table-col-options-overlay.component.html',
    styleUrls: ['./db-table-col-options-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DbTableColOptionsOverlayComponent implements OnInit, OnDestroy {

    @Input() id: string = '';
    className = 'DbTableColOptionsOverlayComponent';
    loader: boolean = true;
    onCloseData = {};

    @Input() dbTableComponent: DbTableComponent;
    @Input() col_index: number;

    searchText: string = '';
    @ViewChild('SearchInputERef', { static: true }) searchInputERef: ElementRef;

    filterIndex: DbTableUiFilterIndex = [];
    searchResult: DbTableUiFilterIndex = [];

    selectAll: boolean = false;
    selectAllSearchResult: boolean = true;
    addCurSelTofilter: boolean = true;

    constructor(
        public overlayCtrl: OverlayService,
        public keybindsCtrl: KeybindsService,
    ) { }
    @HostListener('window:keydown.enter', ['$event'])
    on_keydown_enter() {
        if (this.searchResult.length > 0)
            this.filter();
    }

    ngOnInit() {
        this.loader = true;

        this.keybindsCtrl.add(this.className, {
            keyCode: 'KeyL',
            altKey: true,
            function: () => this.cleanFilter()
        });

        this.searchText = '';
        this.addCurSelTofilter = false;

        // Pre Filter
        let tempRows: DbTableRowData[] = [];
        for (let row of this.dbTableComponent.table.rows) {
            let tempRow = row;
            let validRow = true;

            for (let c_index_pf = 0; c_index_pf < this.dbTableComponent.table.cols.length; c_index_pf++) {
                if (this.dbTableComponent.filterActiveIndex[c_index_pf] && c_index_pf != this.col_index) {
                    const fValue = dbTableFilterValueDetermination(tempRow, c_index_pf, this.dbTableComponent.table.cols[c_index_pf]);
                    validRow = dbTableGetFilterStatus(this.dbTableComponent, c_index_pf, fValue);
                    if (!validRow)
                        break;
                }
            }
            if (validRow)
                tempRows.push(row);
        }
        // Pre Filter

        let tempColIndex = [];
        let tempSingleValues = [];
        for (let r of tempRows) {
            const fValue = dbTableFilterValueDetermination(r, this.col_index, this.dbTableComponent.table.cols[this.col_index]);
            if (tempSingleValues.indexOf(fValue) == -1) {
                tempSingleValues.push(fValue);
                tempColIndex.push({
                    show: dbTableGetFilterStatus(this.dbTableComponent, this.col_index, fValue),
                    value: fValue,
                });
            }
        }

        const ord = this.dbTableComponent.orderByIndex[this.col_index] ? this.dbTableComponent.orderByIndex[this.col_index] : "asc";
        tempColIndex.sort((a, b) => dbTableOrderByCore(a.cell, b.cell, ord));

        this.filterIndex = JSON.parse(JSON.stringify(tempColIndex));
        this.searchResult = JSON.parse(JSON.stringify(tempColIndex));
        this.selectAll = !this.dbTableComponent.filterActiveIndex[this.col_index];

        this.loader = false;

        setTimeout(() => {
            this.searchInputERef.nativeElement.focus();
        }, 200);
    }
    ngOnDestroy() {
        this.keybindsCtrl.remove(this.className);
    }
    close() {
        this.overlayCtrl.closeOverlay(this.id);
    }

    // ---------------------- FILTERS
    filter() {
        if (this.searchResult.length == 0)
            return;

        if (this.addCurSelTofilter) {
            // Add Cur Sel To filter
            for (let fi of this.searchResult)
                dbTableSetFilterStatus(this.dbTableComponent, this.col_index, fi.value, fi.show);
        } else {
            if (this.searchText.length > 0)
                for (let fi of this.dbTableComponent.filterIndexes[this.col_index]) {
                    let index = this.searchResult.indexOf(fi);
                    if (index == -1)
                        fi.show = false;
                }

            for (let fi of this.searchResult)
                dbTableSetFilterStatus(this.dbTableComponent, this.col_index, fi.value, fi.show);
        }

        // Filter Active
        let tempFilterActive = false;
        for (let fi of this.dbTableComponent.filterIndexes[this.col_index]) {
            if (!fi.show) {
                tempFilterActive = true;
                break;
            }
        }
        this.dbTableComponent.filterActiveIndex[this.col_index] = tempFilterActive;
        // Filter Active

        dbTableUpdateFiltredRows(this.dbTableComponent);
        this.close();
    }
    toogleAll() {
        for (let f of this.searchResult)
            if (this.selectAll)
                f.show = false;
            else
                f.show = true;
        this.selectAll = !this.selectAll;
    }
    cleanFilter() {
        dbTableCleanFilter(this.dbTableComponent, this.col_index);
        this.close();
    }
    updateSearchResult() {
        if (!this.filterIndex)
            return [];
        if (!this.searchText)
            return this.filterIndex;
        this.searchText = this.searchText.toLocaleLowerCase();
        let tempSearchResult = this.filterIndex.filter((it) => {
            return it.value.toLocaleLowerCase().includes(this.searchText);
        });
        tempSearchResult.forEach((item) => {
            item.show = true;
        })
        this.searchResult = tempSearchResult;
    }
    search() {
        this.updateSearchResult();
    }
    toogleSort(ord: 'asc' | 'desc') {
        dbTableToogleSort(this.dbTableComponent, this.col_index, ord);
        this.close();
    }
    updateSelectAll() {
        let temp_filterSelectAll = true;
        for (let f of this.searchResult)
            if (!f.show) {
                temp_filterSelectAll = false;
                break
            }
        this.selectAll = temp_filterSelectAll;
    }
    // ---------------------- FILTERS

}