import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DB_DataResult } from 'app/@firebase';

import { DataVisualization, FieldsIndexType } from '../../../types';

@Component({
  selector: 'editor-fields',
  styleUrls: ['./editor-fields.component.scss'],
  templateUrl: './editor-fields.component.html',
})
export class EditorFieldsComponent implements OnInit {

  className = "EditorFieldsComponent";

  loader = false;

  _oldVisFields = [];
  _visFields = [];

  DB_DsDataResult: DB_DataResult = new DB_DataResult();

  _selectAll = true;
  _index: FieldsIndexType[] = [];

  @Output() onChange = new EventEmitter();
  @Input() hasChanges = false;

  @Input() edit = false;

  constructor() { }

  ngOnInit() { }
  ngOnDestroy(): void { }

  _trackByFn(index, item) {
    return index;
  }
  private _moveItem(vet: any[], from: number, to: number): any[] {
    let tempVet = JSON.parse(JSON.stringify(vet));
    // remove `from` item and store it
    var f = tempVet.splice(from, 1)[0];
    // insert stored item into position `to`
    tempVet.splice(to, 0, f);
    return tempVet;
  }

  public loadFromVis(vis: DataVisualization, DB_DsDataResult: DB_DataResult) {
    this._oldVisFields = JSON.parse(JSON.stringify(vis.source.fields));
    this._visFields = JSON.parse(JSON.stringify(vis.source.fields));
    this.hasChanges = false;

    this.DB_DsDataResult = DB_DsDataResult;
    this._index = [];
    this.DB_DsDataResult.fields
      .forEach(f => {
        this._index.push({
          id: f.id,
          name: f.name,
          active: vis.source.fields.indexOf(f.id) != -1
        })
      })

    this._updateSelectAll();
  }
  public loadFromDsDataResult(DB_DsDataResult: DB_DataResult) {
    this.DB_DsDataResult = DB_DsDataResult;
    this._index = [];
    this.DB_DsDataResult.fields
      .forEach(f => {
        this._index.push({
          id: f.id,
          name: f.name,
          active: true
        })
      })
    this._updateVisFields();
    this._selectAll = true;
    this._change();
  }
  public getFields(): any[] {
    return JSON.parse(JSON.stringify(this._visFields));
  }
  public getFieldsForTable() {
    let tempFields = [];
    this._index
      .forEach(fi => {
        if (fi.active) {
          let fieldData = this.DB_DsDataResult.fields.filter(f => { return f.id == fi.id })[0];
          if (fieldData)
            tempFields.push(fieldData)
        }
      })
    return tempFields;
  }

  _toogleAll() {
    for (let f of this._index)
      if (this._selectAll)
        f.active = false;
      else
        f.active = true;
    this._selectAll = !this._selectAll;
    this._change();
  }
  _updateSelectAll() {
    let temp_selectAll = true;
    for (let f of this._index)
      if (!f.active) {
        temp_selectAll = false;
        break
      }
    this._selectAll = temp_selectAll;
  }
  _rowUp(index: number) {
    let fromIndex = index;
    let toIndex = fromIndex - 1;
    this._index = this._moveItem(this._index, fromIndex, toIndex);
    this._change();
  }
  _rowDown(index: number) {
    let fromIndex = index;
    let toIndex = fromIndex + 1;
    this._index = this._moveItem(this._index, fromIndex, toIndex);
    this._change();
  }
  _updateVisFields() {
    this._visFields = [];
    this._index
      .forEach(fi => {
        if (fi.active) {
          let fieldData = this.DB_DsDataResult.fields.filter(f => { return f.id == fi.id })[0];
          if (fieldData)
            this._visFields.push(fieldData.id)
        }
      })
  }
  _change() {
    setTimeout(() => {
      this._updateVisFields();
      this._updateSelectAll();
      this.checkForChanges();
      this.onChange.emit(this.getFields());
    }, 250);
  }
  checkForChanges() {
    if (
      JSON.stringify(this._oldVisFields) != JSON.stringify(this._visFields)
    )
      this.hasChanges = true
    else
      this.hasChanges = false
  }
}
