<mat-dialog-content>

  <nb-card [nbSpinner]="loader" nbSpinnerMessage="Loading..." nbSpinnerSize="large" nbSpinnerStatus="basic"
    class="col-12">
    <nb-card-header>
      Auth Object Data

      <button class="close" mat-button matDialogClose>
        <span aria-hidden="true">&times;</span>
      </button>
    </nb-card-header>
    <nb-card-body>
      <form #form1="ngForm">
        <div class="row">
          <div class="col-12">
            <label>Id</label>
            <input disabled class="ic-form-control" type="name" id="input_id" name="input_id"
              [(ngModel)]="localAuthObject.id" required placeholder="Name"
              [pattern]="utilCtrl.regexValidators.noSpaces">
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label class="control-label">Description</label>
            <textarea autofocus class="ic-form-control" id="description" name="description"
              [(ngModel)]="localAuthObject.description" required></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label>Class Name</label>
            <select required id="input_classId" name="input_classId" class="ic-form-control"
              [(ngModel)]="localAuthObject.classId">
              <option *ngFor="let oc of localAuthObjectClasses" [ngValue]="oc.id">{{oc.id}} - {{oc.description}}
              </option>
            </select>
          </div>
        </div>
      </form>

      <div class="padding"></div>

      <nb-card>
        <nb-card-header>
          Fields
        </nb-card-header>
        <nb-card-body>

          <div class="form-group">
            <label class="control-label">Add Fields</label>

            <div class="row">

              <div class="col-4">
                <button nbButton status="primary" size="small" (click)="addField()">
                  <nb-icon icon="plus-outline" pack="eva"></nb-icon>
                </button>
              </div>
            </div>
          </div>

          <table *ngIf="localAuthObject.fields.length>0" class="clean-table">
            <thead>
              <tr>
                <th style="width: 30px;">Nº</th>
                <th>Field</th>
                <th style="width: 30px;"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let b of localAuthObject.fields; let i=index">
                <td>{{i+1}}</td>
                <td>
                  <p>{{b}}</p>
                </td>
                <td>
                  <button nbButton status="danger" size="small" (click)="removeFieldValueConfirmation(i)">
                    <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <br>

        </nb-card-body>
      </nb-card>
    </nb-card-body>
  </nb-card>

  <nb-card>
    <nb-card-body>

      <ng-container *ngIf="form1.valid; else elseTemplate_1">
        <p>✅ Data</p>
      </ng-container>
      <ng-template #elseTemplate_1>
        <p>❌ Data</p>
      </ng-template>


      <ng-container *ngIf="localAuthObject.fields.length>0; else elseTemplate_2">
        <p>✅ Fields</p>
      </ng-container>
      <ng-template #elseTemplate_2>
        <p>❌ Fields</p>
      </ng-template>

      <div class="row">
        <div class="col-12 padding" style="text-align: center">
          <div class="button-container">
            <button nbButton status="primary" size="small"
              [disabled]="!form1.valid || localAuthObject.fields.length == 0" (click)="save()">
              <nb-icon icon="save-outline" pack="eva"></nb-icon>
              Salvar
            </button>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>

</mat-dialog-content>
