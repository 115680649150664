import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbCardModule, NbIconModule, NbTableModule, NbTreeGridModule } from '@nebular/theme';

import { FsIconComponent } from './fs-Icon.component';
import { TableTreeComponent } from './table-tree.component';

const BASE_MODULES = [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,

  NbIconModule,
  NbTreeGridModule,
  NbCardModule,
  NbTableModule,

];

const COMPONENTS = [
  TableTreeComponent,
  FsIconComponent
];

// const ENTRY_COMPONENTS = [
// ];

@NgModule({
  imports: [...BASE_MODULES],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  // declarations: [...COMPONENTS, ...ENTRY_COMPONENTS],
  // entryComponents: [...ENTRY_COkMPONENTS],
})
export class TableTreeModule { }
