import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';

import { PM_BoardLabel } from 'app/@firebase';

import { PmCardModal } from '../../../pm-card-modal';

@Component({
  selector: 'mode-labels',
  templateUrl: './mode-labels.component.html',
  styleUrls: ['./mode-labels.component.scss']
})
export class PMCardModalModeLabelsComponent implements OnInit, OnChanges {

  className = 'PMCardModalModeLabelsComponent';

  _loader = true;

  cardLabels: PM_BoardLabel[] = [];
  boardLabels4Sel: PM_BoardLabel[] = [];
  newCardLabel = new PM_BoardLabel();

  constructor(
    public pmCardModal: PmCardModal,
  ) { }

  ngOnInit() {
    this.load();
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }
  ngOnDestroy() { }
  _trackByFn(index, item) {
    return index;
  }

  load() {
    this._loader = true;
    let tempCardLabels = [];
    this.pmCardModal.card.labelsIds
      .forEach((labelId) => {
        const tempLabel = this.pmCardModal.boardLabels.find((l) => l.id == labelId);
        if (tempLabel)
          tempCardLabels.push(tempLabel);
      });
    this.cardLabels = tempCardLabels;
    this.boardLabels4Sel = this.pmCardModal.boardLabels.filter((l) => { return !this.pmCardModal.card.labelsIds.includes(l.id); });
    this._loader = false;
  }

  _labelsOnDrop(event: CdkDragDrop<PM_BoardLabel[]>) {
    if (event.previousContainer == event.container) {
      this._loader = true;

      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      )

      let tempLabelsId = [];
      event.container.data.forEach((l) => {
        tempLabelsId.push(l.id);
      })

      this.pmCardModal.card.labelsIds = tempLabelsId;

      this.pmCardModal._updateCard()
        .then(() => {
          this.load();
        })
        .catch((e) => {
          this._loader = false;
          this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
        })
    }
  }
  _addLabel() {
    this._loader = true;

    this.pmCardModal.card.labelsIds.push(this.newCardLabel.id);

    this.pmCardModal._updateCard(
      {
        labelsIds: this.pmCardModal.db.fieldValue.arrayUnion(this.newCardLabel.id) as any,
      }
    )
      .then(() => {
        this.newCardLabel = new PM_BoardLabel();
        this.load();
      })
      .catch((e) => {
        this._loader = false;
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
      })
  }
  _remLabel(labelId: string) {
    this._loader = true;

    this.pmCardModal.card.labelsIds = this.pmCardModal.card.labelsIds.filter(_labelId => { return _labelId != labelId });

    this.pmCardModal._updateCard(
      {
        labelsIds: this.pmCardModal.db.fieldValue.arrayRemove(labelId) as any,
      }
    )
      .then(() => {
        this.load();
      })
      .catch((e) => {
        this._loader = false;
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
      })
  }
}
