import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NbOverlayRef } from '@nebular/theme';

import { AuthService } from 'app/@core';
import { MagicTableSettings } from 'app/@firebase';
import { OverlayService, UiFeedBackService } from 'app/@theme';
import { MagicTableColumn } from 'app/components/@magic-table/magic-table.types';

@Component({
  selector: 'magic-table-settings-modal',
  styleUrls: ['./magic-table-settings-modal.component.scss'],
  templateUrl: './magic-table-settings-modal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MagicTableSettingsModalComponent implements OnInit {

  @Input() id: string = '';
  @Input() overlay: NbOverlayRef;
  className = 'MagicTableSettingsModalComponent';
  loader = true;
  onCloseData = {
    needLoad: false,
    tableSettings: new MagicTableSettings()
  };

  @Input() tableId: string = '';
  @Input() tableSettings: MagicTableSettings = new MagicTableSettings();
  @Input() columns: MagicTableColumn[] = [];

  availableColumns: string[] = [];

  seleted_availableColumn_i = -1;
  seleted_availableColumn = '';

  seleted_selectedColumn_i = -1;
  seleted_selectedColumn = '';

  constructor(
    public overlayCtrl: OverlayService,
    public authService: AuthService,
    public uiFeedBackCtrl: UiFeedBackService,
  ) { }
  @HostListener('window:keydown.esc', ['$event'])
  onEsc() {
    this._close();
  }
  _close() {
    this.overlayCtrl.closeOverlay(this.id)
  }
  ngOnInit() {
    this.overlay.backdropClick().subscribe(() => this._close());

    this.tableSettings = this.tableSettings;

    let columns: MagicTableColumn[] = this.columns;
    this.availableColumns = [];
    for (let col of columns) {
      if (this.tableSettings.selectedColumns.indexOf(col.title) == -1)
        this.availableColumns.push(col.title)
    }
  }
  select_availableColumn(index: number, title: string) {
    this.seleted_availableColumn_i = index;
    this.seleted_availableColumn = title;
  }
  select_selectedColumn(index: number, title: string) {
    this.seleted_selectedColumn_i = index;
    this.seleted_selectedColumn = title;
  }
  makeColumSelected() {
    this.availableColumns.splice(this.seleted_availableColumn_i, 1);
    this.tableSettings.selectedColumns.push(this.seleted_availableColumn);
    this.seleted_availableColumn_i = -1;
    this.seleted_availableColumn = '';
    this.tableSettingsChange();
  }
  makeColumAvailable() {
    this.tableSettings.selectedColumns.splice(this.seleted_selectedColumn_i, 1);
    this.availableColumns.push(this.seleted_selectedColumn);
    this.seleted_selectedColumn_i = -1;
    this.seleted_selectedColumn = '';
    this.tableSettingsChange();
  }
  private moveItem(vet: any[], from: number, to: number): any[] {
    let tempVet = JSON.parse(JSON.stringify(vet));
    // remove `from` item and store it
    var f = tempVet.splice(from, 1)[0];
    // insert stored item into position `to`
    tempVet.splice(to, 0, f);
    return tempVet;
  }
  selectedColumnUp() {
    this.tableSettings.selectedColumns = this.moveItem(this.tableSettings.selectedColumns, this.seleted_selectedColumn_i, (this.seleted_selectedColumn_i - 1));
    this.seleted_selectedColumn_i = this.seleted_selectedColumn_i - 1;
    this.tableSettingsChange();
  }
  selectedColumnDown() {
    this.tableSettings.selectedColumns = this.moveItem(this.tableSettings.selectedColumns, this.seleted_selectedColumn_i, (this.seleted_selectedColumn_i + 1));
    this.seleted_selectedColumn_i = this.seleted_selectedColumn_i + 1;
    this.tableSettingsChange();
  }
  resetDefaults() {
    let columns: MagicTableColumn[] = this.columns;
    this.tableSettings.selectedColumns = [];
    for (let col of columns) {
      if (col.show) {
        this.tableSettings.selectedColumns.push(col.title);
      }
    }
    this.availableColumns = [];
    for (let col of columns) {
      if (this.tableSettings.selectedColumns.indexOf(col.title) == -1)
        this.availableColumns.push(col.title)
    }
    this.seleted_availableColumn_i = -1;
    this.seleted_availableColumn = '';
    this.seleted_selectedColumn_i = -1;
    this.seleted_selectedColumn = '';

    this.tableSettingsChange();
  }
  tableSettingsChange() {
  }
  save() {
    if (this.id == '') {
      console.error('Magic Table does not Have ID to save settings');
      return;
    }
    this.uiFeedBackCtrl.presentLoader()
      .then(() => {
        let data = JSON.stringify(this.tableSettings);
        this.authService.set_parameter(`table_${this.tableId}_settings`, data)
          .then(() => {
            this.onCloseData.needLoad = true;
            this.onCloseData.tableSettings = this.tableSettings;
            this._close();

            this.uiFeedBackCtrl.dismissLoader();
            this.uiFeedBackCtrl.presentToast('Configurações Salvas!', '', 'success');
          })
          .catch((e) => {
            this.uiFeedBackCtrl.presentErrorAlert(``, this.className, this.authService.localUser.uName, 'Erro', e);
          })
      })
  }
}
