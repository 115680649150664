import firebase from "firebase/compat/app";

export class TM_TimerSession {

  id: string;
  uName: string;
  userFullName: string;

  target: {
    uName: string;
    dateFromTime: number;
    dateToTime: number;
  };

  log: { operation: 'kill' | 'revive', time: firebase.firestore.Timestamp, uName: string; userFullName: string; }[];

  alive: boolean;
  timeOutedOn: firebase.firestore.Timestamp;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<TM_TimerSession>) {

    this.id = '';
    this.uName = '';
    this.userFullName = '';

    this.target = {
      uName: '',
      dateFromTime: 0,
      dateToTime: 0
    };

    this.log = [];

    this.alive = true;
    this.timeOutedOn = null;

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;


    if (init)
      Object.assign(this, init);
  }
}
