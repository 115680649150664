import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbActionsModule, NbButtonModule, NbCardModule, NbIconModule, NbUserModule } from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { MagicControlOptionComponent } from './magic-control/magic-control-option.component';
import { MagicControlComponent } from './magic-control/magic-control.component';
import { MagicTableHighlightDirective } from './magic-table-highlight.directive';
import { MagicTableSettingsModalComponent } from './magic-table-settings-modal/magic-table-settings-modal.component';
import { MagicTableComponent } from './magic-table.component';

const BASE_MODULES = [
  CommonModule,

  FormsModule,
  ReactiveFormsModule,

  Ng2SmartTableModule,
  MatDialogModule,
  NbCardModule,
  NbButtonModule,
  NbEvaIconsModule,
  NbUserModule,
  NbIconModule,
  NbActionsModule,
];

const COMPONENTS = [
  MagicTableComponent,
  MagicControlComponent,
  MagicControlOptionComponent,
  MagicTableHighlightDirective,
  MagicTableSettingsModalComponent
];

@NgModule({
  imports: [...BASE_MODULES],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS]
})
export class MagicTableModule { }
