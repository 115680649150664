<mat-dialog-content>

  <nb-card [nbSpinner]="loader" nbSpinnerMessage="Loading..." nbSpinnerSize="large" nbSpinnerStatus="basic">
    <nb-card-header>
      Role User Modal

      <button class="close" mat-button matDialogClose>
        <span aria-hidden="true">&times;</span>
      </button>
    </nb-card-header>
    <nb-card-body>


      <div class="row">
        <div class="col-4">
          <label>User</label>
          <h6 class="ic-form-control">{{localRoleUser.uName}}</h6>
        </div>

        <div class="col-8">
          <label>User Name</label>
          <h6 class="ic-form-control">{{userFullName}}</h6>
        </div>
      </div>

      <form #form="ngForm">
        <div class="row">
          <div class="col-6">
            <label>FROM</label>
            <input [disabled]="!canEdit" class="ic-form-control" [(ngModel)]="localRoleUser.from" [disabled]="!editing"
              id="input_from" type="date" name="input_from" required />
          </div>

          <div class="col-6">
            <label>TO</label>
            <input [disabled]="!canEdit" class="ic-form-control" [(ngModel)]="localRoleUser.to" [disabled]="!editing"
              id="input_to" type="date" name="input_to" required />
          </div>
        </div>
      </form>

      <div class="row">
        <div class="col-6">
          <label>CreatedBy</label>
          <h6 class="ic-form-control">{{localRoleUser.createdBy}}</h6>
        </div>

        <div class="col-6">
          <label>UpdatedBy</label>
          <h6 class="ic-form-control">{{localRoleUser.updatedBy}}</h6>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label>CreatedOn</label>
          <h6 class="ic-form-control">
            {{this.utilCtrl.timestamp.toLocalDate(localRoleUser.createdOn)}}
          </h6>
        </div>

        <div class="col-6">
          <label>UpdatedOn</label>
          <h6 class="ic-form-control">
            {{this.utilCtrl.timestamp.toLocalDate(localRoleUser.updatedOn)}}
          </h6>
        </div>
      </div>



      <nb-card *ngIf="mode == 'change'">
        <nb-card-body>
          <div class="row" *ngIf="!canEdit">
            <p>For Change this Role Atribution acess the Role: <a target="_blank"
                [href]="'#/main/sys/access-control/roles/detail?id='+ localRoleUser.roleId"
                style="font-weight: bold;text-decoration: underline;">{{localRoleUser.roleId}}</a>
            </p>
          </div>


          <div class="row" *ngIf="canEdit">


            <div class="row" *ngIf="!editing">
              <div class="col-md-auto">
                <button nbButton [disabled]="!admin" nbSpinnerStatus="primary" nbSpinnerSize="small" nbSpinnerMessage=""
                  status="primary" size="small" (click)="toggleEditing()">
                  <nb-icon icon="edit-outline" pack="eva"></nb-icon>
                  change
                </button>
              </div>
            </div>

            <div class="row" *ngIf="editing">
              <div class="col-md-auto">
                <button nbButton status="danger" size="small" (click)="toggleEditing()">
                  <nb-icon icon="close-outline" pack="eva"></nb-icon>
                  cancel
                </button>
              </div>
              <div class="col-md-auto">
                <button [disabled]="!form.valid || !checkForChanges()" nbButton status="primary" size="small"
                  (click)="save()">
                  <nb-icon icon="save-outline" pack="eva"></nb-icon>
                  save
                </button>
              </div>
            </div>

          </div>


        </nb-card-body>
      </nb-card>

    </nb-card-body>
  </nb-card>

</mat-dialog-content>
