import firebase from "firebase/compat/app";

export type NOTIF_STATUS_ICON_TYPE = { icon: string; pack?: string; }
export const NOTIF_STATUS_ICONS = {
    'basic': { icon: 'bulb', pack: 'eva' },
    'info': { icon: 'info', pack: 'eva' },
    'success': { icon: 'checkmark-circle', pack: 'eva' },
    'warning': { icon: 'alert-triangle', pack: 'eva' },
    'danger': { icon: 'close-circle', pack: 'eva' },
} as Record<string, NOTIF_STATUS_ICON_TYPE>;

export type NotificationStatus = 'basic' | 'info' | 'success' | 'warning' | 'danger';
export type NotificationAction = 'none' | 'url';

export class Notification {

    id: string;
    uName: string;

    status: NotificationStatus;
    module: string;
    subModule: string;

    action: NotificationAction;
    actionBtnText: string;
    actionData: string;

    title: string;
    titleHtml: string;
    body: string;
    bodyHtml: string;

    readed: boolean;
    readedOn: firebase.firestore.Timestamp;

    senderName: string;
    senderTitle: string;
    senderPicture: string;

    createdOn: firebase.firestore.Timestamp;
    updatedOn: firebase.firestore.Timestamp;
    createdBy: string;
    updatedBy: string;

    public constructor(init?: Partial<Notification>) {

        this.id = '';
        this.uName = '';

        this.status = 'basic';
        this.module = '';
        this.subModule = '';

        this.action = 'none';
        this.actionBtnText = 'Abrir';
        this.actionData = '';

        this.title = '';
        this.titleHtml = '';
        this.body = '';
        this.bodyHtml = '';

        this.readed = false;
        this.readedOn = null;

        this.senderName = '';
        this.senderTitle = '';
        this.senderPicture = '';

        this.createdOn = firebase.firestore.Timestamp.now();
        this.updatedOn = null;
        this.createdBy = '';
        this.updatedBy = null;

        if (init)
            Object.assign(this, init);
    }
}