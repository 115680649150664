import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbButtonModule, NbCardModule, NbIconModule, NbUserModule } from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { DbTableHighlightDirective } from './db-table-highlight.directive';
import { DbTableComponent } from './db-table.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ThemeModule } from 'app/@theme';
import { DbTableColOptionsOverlayComponent } from './components/overlays/db-table-col-options-overlay/db-table-col-options-overlay.component';
import { DbTableRowOptionsOverlayComponent } from './components/overlays/db-table-row-options-overlay/db-table-row-options-overlay.component';

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  ThemeModule,

  Ng2SmartTableModule,
  NbCardModule,
  NbButtonModule,
  NbEvaIconsModule,
  NbIconModule,
  ScrollingModule,
];

const COMPONENTS = [
  DbTableComponent,
  DbTableHighlightDirective,
  DbTableColOptionsOverlayComponent,
  DbTableRowOptionsOverlayComponent,
];

@NgModule({
  imports: [...BASE_MODULES],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS]
})
export class DbTableModule { }
