import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NbComponentStatus } from '@nebular/theme';

import { AuthService, UtilService } from 'app/@core';
import { FirestoreService, getPMCardDueDateStatusData, getPMCardPriorityData, PM_Board, PM_BoardLabel, PM_BoardRoute, PM_Card, PM_CARD_DUEDATE_STATUS_DATA_TYPE, PM_CARD_PRIORITY_DATA_TYPE, PM_ToDo, pmCardDueDateStatusDetermination, SM_DocActivity, User } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

import { PmCardModal } from '../pm-card-modal/pm-card-modal';

export class DataType {
  activity: SM_DocActivity;
  user: User;
  visibName: string;
  accent: NbComponentStatus;
  icon: string;
  public constructor(init?: Partial<DataType>) {
    this.activity = new SM_DocActivity();
    this.user = new User();
    this.visibName = '';
    this.accent = 'basic';
    this.icon = '';
    if (init)
      Object.assign(this, init);
  }
}



@Component({
  selector: 'pm-card',
  templateUrl: './pm-card.component.html',
  styleUrls: ['./pm-card.component.scss']
})
export class PMCardComponent implements OnInit, OnChanges {

  className = 'PMCardComponent';

  @Input() card: PM_Card = new PM_Card();
  cardTitleToDisplay = "";

  @Input() board: PM_Board = new PM_Board();
  @Input() boardRoutes: PM_BoardRoute[] = [];
  @Input() boardLabels: PM_BoardLabel[] = [];
  @Input() boardMembers: User[] = [];
  @Input() boardToDos: PM_ToDo[] = [];

  @Input() disableOpen: boolean = false;
  @Output() onDrop = new EventEmitter<CdkDragDrop<any[]>>();
  @Output() onOpen = new EventEmitter<void>();
  @Output() onClick = new EventEmitter<void>();

  @Input() showSumTimeRecords: boolean = false;
  @Input() sumTimeRecordsStr = '';

  _labels = [];

  _assignees = [];

  _priority: PM_CARD_PRIORITY_DATA_TYPE = new PM_CARD_PRIORITY_DATA_TYPE();
  _dueDateStatus: PM_CARD_DUEDATE_STATUS_DATA_TYPE = new PM_CARD_DUEDATE_STATUS_DATA_TYPE();

  _dueDateStr = "";

  _toDoListStr = "";

  _elapsedTimeStr = "";

  constructor(
    public db: FirestoreService,
    public authService: AuthService,
    public uiFeedBackCtrl: UiFeedBackService,
    public utilCtrl: UtilService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.load();
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    this.load();
  }
  ngOnDestroy() { }

  private load() {
    let timeNowMs = new Date().getTime();

    this.cardTitleToDisplay = this.card.title.length > 50 ? this.card.title.substring(0, 45) + '[...]' : this.card.title;

    // labels
    this._labels = this.boardLabels.filter((l) => { return this.card.labelsIds.includes(l.id); });
    // labels

    // assignees
    this._assignees = [];
    if (this.card.assignedTo.length > 0) {
      this.card.assignedTo.forEach((uName) => {
        let tempAssignee = this.boardMembers.filter(m => { return m.uName == uName })[0];
        if (tempAssignee)
          this._assignees.push(tempAssignee);
      })
    }
    // assignees

    // Priority
    this._priority = new PM_CARD_PRIORITY_DATA_TYPE();
    if (this.card.priority)
      this._priority = getPMCardPriorityData(this.card.priority);
    // Priority

    // dueDate
    this._dueDateStr = '';
    if (this.card.dueDate)
      this._dueDateStr = `${this.utilCtrl.timestamp.formatToDateString(this.card.dueDate, this.card.dueDateHourString != null, true)}`;

    let tempDueDateStatus = pmCardDueDateStatusDetermination(this.card);
    this._dueDateStatus = getPMCardDueDateStatusData(tempDueDateStatus);
    // dueDate

    // To Do Lists
    this._toDoListStr = '';
    const cardToDos = this.boardToDos.filter((toDo) => { return toDo.cardId == this.card.id; });
    if (cardToDos.length > 0)
      this._toDoListStr = `${cardToDos.filter((toDo) => { return toDo.done; }).length}/${cardToDos.length}`;
    // To Do Lists

    let _createdOn = this.utilCtrl.timestamp.toDate(this.card.createdOn);
    this._elapsedTimeStr = this.utilCtrl.converters.millisecondsToHuman(timeNowMs - _createdOn.getTime());
  }


  // Rotinas de controle
  _trackByFn(index, item) {
    return index;
  }
  _click() {
    console.log(`Card Id: "${this.card.id}" clicked!`);
    this.onClick.emit();

    if (this.disableOpen)
      console.log(`Open Card Disable!`)
    else
      this._openCard();
  }
  _openCard() {
    console.log(`Open Card: "${this.card.id}"`)
    this.onOpen.emit();

    if (this.board.id == this.card.boardId) {
      let active_PmCardModalRef = this.uiFeedBackCtrl.dialog_opener(PmCardModal, {
        id: 'PmCardModalRef_' + new Date().getTime(),
        data: {
          card: JSON.parse(JSON.stringify(this.card)),
          board: JSON.parse(JSON.stringify(this.board)),
          boardRoutes: JSON.parse(JSON.stringify(this.boardRoutes)),
          boardLabels: JSON.parse(JSON.stringify(this.boardLabels)),
          boardMembers: JSON.parse(JSON.stringify(this.boardMembers)),
        }
      });
      active_PmCardModalRef.afterClosed()
        .subscribe((data) => {
          // if (data)
          // this.saveUserConfigModal(data);
        });
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          'id': this.card.boardId,
          'cardIdToOpen': this.card.id
        }
      };
      this.router.navigate(['/main/project-management/kanban/open-board'], navigationExtras);
    }

  }
  _onDropEmitter(event: CdkDragDrop<any[]>) {
    this.onDrop.emit(event)
  }

}
