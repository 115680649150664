import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NgSelectModule } from '@ng-select/ng-select';
import player from 'lottie-web';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { LottieModule } from 'ngx-lottie';

export function playerFactory() {
    return player;
}


import {
    NbSpinnerModule,
    NbListModule,
    NbInputModule,
    NbSelectModule,
    NbCheckboxModule,
    NbRadioModule,
    NbCardModule,
    NbActionsModule,
    NbTabsetModule,
    NbUserModule,
    NbButtonModule,
    NbToggleModule,
    NbIconModule,
    NbLayoutDirectionService,
    NbDatepickerModule,
    NbStatusService,
    NbStepperModule,
    NbAccordionModule,
} from '@nebular/theme';

import { AuthService } from 'app/@core/services/auth.service';
import { PMCardComponent } from './pm-card.component';

const Components = [
    PMCardComponent,
];

@NgModule({
    declarations: [
        ...Components
    ],
    exports: [
        ...Components
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LottieModule.forRoot({ player: playerFactory }),
        DragDropModule,

        // Ng*
        // NgxEchartsModule,
        Ng2SmartTableModule,
        NgSelectModule,

        // Nb*
        NbDatepickerModule,
        NbListModule,
        NbInputModule,
        NbSelectModule,
        NbRadioModule,
        NbCheckboxModule,
        NbSpinnerModule,
        NbCardModule,
        NbActionsModule,
        NbTabsetModule,
        NbUserModule,
        NbButtonModule,
        NbIconModule,
        NbEvaIconsModule,
        NbToggleModule,
        NbStepperModule,
        NbAccordionModule,

        // Mat*
        MatDialogModule,
        MatListModule,

    ],
    providers: [
        NbLayoutDirectionService,
        AuthService,
        NbStatusService
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class PmCardModule { }
