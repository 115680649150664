import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from 'app/@theme';

import { AddObjectToRoleModalComponent } from './add-object-to-role-modal.component';

const BASE_MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ThemeModule
];

const COMPONENTS = [
    AddObjectToRoleModalComponent
];

@NgModule({
    imports: [...BASE_MODULES],
    exports: [...COMPONENTS],
    declarations: [...COMPONENTS],
})
export class AddObjectToRoleModalModule { }