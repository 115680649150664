import { OS_Calendar, WorkDay } from "app/@firebase";

// Consts
const DAY_IN_MS = 1000 * 60 * 60 * 24;
const HOUR_IN_MS = 1000 * 60 * 60;
const MIN_IN_MS = 1000 * 60;
const SECOND_IN_MS = 1000;
// Consts

// Internal functions
function __getDateStr(date: Date): string {
    return date.toISOString().split('T')[0];
}
/**
* Converte string "yyyy-mm-dd" para Date()
* @param {string} yyyy_mm_dd `date as yyyy-mm-dd`
* @param {Optional} hh_mm_ss `time as hh:mm:ss`
* @return {string} `Date`
*/
function __getDateObj(yyyy_mm_dd: string, hh_mm_ss: string = '00:00:00'): Date {
    if (!yyyy_mm_dd)
        return null;
    return new Date(`${yyyy_mm_dd} ${hh_mm_ss} GMT-0300 (Hora oficial do Brasil)`);
}
// Internal functions


/**
 * calculates the working ms between two dates for the giveng OS_Calendar 
 * @param startDate 
 * @param endDate 
 * @param calendar 
 * @returns number
 */
export function _workingMsBetweenDates(startDate: Date, endDate: Date, calendar: OS_Calendar): number {
    const startDate_time = startDate.getTime();
    const endDate_time = endDate.getTime();
    let _startDate = startDate;
    let _endDate = endDate;

    // validate input
    if (endDate_time <= startDate_time)
        return 0;

    // check if the dates is on the calendar
    const cal_validFrom_time = __getDateObj(calendar.validFrom, '00:00:00').getTime();
    const cal_validTo_time = __getDateObj(calendar.validTo, '23:59:59').getTime();
    if (
        startDate_time < cal_validFrom_time &&
        endDate_time > cal_validTo_time
    )
        return 0;

    // corret the start or the end date if needed
    const cal_validFrom = __getDateObj(calendar.validFrom, '00:00:00');
    const cal_validTo = __getDateObj(calendar.validTo, '23:59:59');
    if (startDate.getTime() < cal_validFrom.getTime())
        _startDate = cal_validFrom;
    if (endDate.getTime() > cal_validTo.getTime())
        _endDate = cal_validTo;


    // mount days array
    let days: Date[] = [];
    let _temp_startDate = new Date(_startDate.getTime());
    let _temp_endDate = new Date(_endDate.getTime());
    _temp_startDate.setHours(0, 0, 0);
    _temp_endDate.setHours(23, 59, 59);

    let _temp_day = _temp_startDate;
    do {
        days.push(_temp_day);
        _temp_day = new Date(_temp_day.getTime() + DAY_IN_MS);
    } while (_temp_day.getTime() < _temp_endDate.getTime());


    // calculation
    const _startDate_time = _startDate.getTime();
    const _endDate_time = _endDate.getTime();
    let ms = 0;
    for (let days_i = 0; days_i < days.length; days_i++) {
        const day = days[days_i];
        const day_time = day.getTime();
        const day_dateStr = __getDateStr(day);
        const isFirst = days_i == 0;
        const isLast = days_i == days.length - 1;
        const day_number = day.getDay();
        const day_workDay = new WorkDay(calendar.officeHours[day_number]);
        let isHoliday = false;
        for (let hd of calendar.holidays) {
            const hd_from_time = __getDateObj(hd.from, '00:00:00').getTime();
            const hd_to_time = __getDateObj(hd.to, '23:59:59').getTime();
            if (day_time >= hd_from_time && day_time <= hd_to_time) {
                isHoliday = true;
                break;
            }
        }


        if (day_workDay.work && !isHoliday) {
            const workDay_begin = __getDateObj(day_dateStr, day_workDay.begin);
            const workDay_end = __getDateObj(day_dateStr, day_workDay.end);

            let workDay_begin_time = workDay_begin.getTime();
            let workDay_end_time = workDay_end.getTime();

            if (isFirst || isLast) {

                if (isFirst && _startDate_time > workDay_begin_time)
                    workDay_begin_time = _startDate_time;

                if (isLast && _endDate_time < workDay_end_time)
                    workDay_end_time = _endDate_time;
            }

            let deltaTms = (workDay_end_time - workDay_begin_time);
            if (deltaTms > 0)
                ms += deltaTms;
        }
    }
    return ms;
}
/**
 * Calculate the next wokable date and time
 * @param startDate 
 * @param calendar 
 * @returns 
 */
export function _calculateNextWorkDate(startDate: Date, calendar: OS_Calendar): Date {
    const startDate_time = startDate.getTime();
    let _startDate = startDate;

    // validate input
    const cal_validTo = __getDateObj(calendar.validTo, '23:59:59');
    if (cal_validTo.getTime() < startDate_time)
        return null;

    // create a end Date based on the calendar end Date
    let _endDate = cal_validTo;

    // calculation
    let _temp_startDate = new Date(_startDate.getTime());
    let _temp_endDate = new Date(_endDate.getTime());
    _temp_startDate.setHours(0, 0, 0);
    _temp_endDate.setHours(23, 59, 59);

    let lastSuposedWorkTime = startDate_time;
    let isFirst = true;
    let day = _temp_startDate;
    do {
        const day_time = day.getTime();
        const day_dateStr = __getDateStr(day);
        const day_number = day.getDay();
        const day_workDay = new WorkDay(calendar.officeHours[day_number]);
        let isHoliday = false;
        for (let hd of calendar.holidays) {
            const hd_from_time = __getDateObj(hd.from, '00:00:00').getTime();
            const hd_to_time = __getDateObj(hd.to, '23:59:59').getTime();
            if (day_time >= hd_from_time && day_time <= hd_to_time) {
                isHoliday = true;
                break;
            }
        }

        if (day_workDay.work && !isHoliday) {
            const workDay_begin = __getDateObj(day_dateStr, day_workDay.begin);
            const workDay_end = __getDateObj(day_dateStr, day_workDay.end);
            const workDay_begin_time = workDay_begin.getTime();
            const workDay_end_time = workDay_end.getTime();

            if (lastSuposedWorkTime == -1) {
                lastSuposedWorkTime = workDay_begin_time;
                break;
            }

            if (lastSuposedWorkTime >= workDay_begin_time && lastSuposedWorkTime <= workDay_end_time)
                break;
            else
                lastSuposedWorkTime = -1;
        }

        if (isFirst && (!day_workDay.work || isHoliday))
            lastSuposedWorkTime = -1;

        day = new Date(day.getTime() + DAY_IN_MS);
        isFirst = false;
    } while (day.getTime() < _temp_endDate.getTime());

    return new Date(lastSuposedWorkTime);
}

/**
 * calculates the date to the passed workTimeMs (due time)
 * @param startDate 
 * @param workTimeMs 
 * @param calendar 
 * @returns Date
 */
export function _calculateWorkEndDate(startDate: Date, workTimeMs: number, calendar: OS_Calendar): Date {
    const startDate_time = startDate.getTime();
    let _startDate = startDate;

    // validate input
    if (workTimeMs < 0)
        return null;
    const cal_validTo = __getDateObj(calendar.validTo, '23:59:59');
    if (cal_validTo.getTime() < startDate_time)
        return null;

    // corret the start date if needed
    const cal_validFrom = __getDateObj(calendar.validFrom, '00:00:00');
    if (startDate.getTime() < cal_validFrom.getTime())
        _startDate = cal_validFrom;

    // adjust the start date to the next workable day if needed
    _startDate = _calculateNextWorkDate(startDate, calendar)

    // create a end Date based on the calendar end Date
    let _endDate = cal_validTo;

    //  calculation
    const _startDate_time = _startDate.getTime();
    const _endDate_time = _endDate.getTime();

    let _temp_startDate = new Date(_startDate.getTime());
    let _temp_endDate = new Date(_endDate.getTime());
    _temp_startDate.setHours(0, 0, 0);
    _temp_endDate.setHours(23, 59, 59);

    let ms = 0;
    let msWorkable = 0;
    let remaning_workTimeMs = workTimeMs;

    let day = _temp_startDate;
    let isFirst = true;
    let isLast = false;
    do {
        const day_time = day.getTime();
        const day_dateStr = __getDateStr(day);
        const day_number = day.getDay();
        const day_workDay = new WorkDay(calendar.officeHours[day_number]);
        let isHoliday = false;
        for (let hd of calendar.holidays) {
            const hd_from_time = __getDateObj(hd.from, '00:00:00').getTime();
            const hd_to_time = __getDateObj(hd.to, '23:59:59').getTime();
            if (day_time >= hd_from_time && day_time <= hd_to_time) {
                isHoliday = true;
                break;
            }
        }
        const nextDay = new Date(day_time + DAY_IN_MS);

        if (day_workDay.work && !isHoliday) {
            remaning_workTimeMs = workTimeMs - msWorkable;
            const workDay_begin = __getDateObj(day_dateStr, day_workDay.begin);
            const workDay_end = __getDateObj(day_dateStr, day_workDay.end);

            let workDay_begin_time = workDay_begin.getTime();
            let workDay_end_time = workDay_end.getTime();

            if (isFirst && _startDate_time > workDay_begin_time)
                workDay_begin_time = _startDate_time;

            isLast = (workDay_begin_time + remaning_workTimeMs) < workDay_end_time;
            if (isLast)
                workDay_end_time = (workDay_begin_time + remaning_workTimeMs);

            if (_endDate_time < workDay_end_time)
                workDay_end_time = _endDate_time;

            let deltaTms = (workDay_end_time - workDay_begin_time);
            if (deltaTms > 0) {
                msWorkable += deltaTms;
                if (isLast)
                    ms += deltaTms;
            }

        }

        // Move the ms couter to futher on time
        if (!isLast)
            ms += DAY_IN_MS;

        // stop condition
        if (msWorkable >= workTimeMs)
            break;

        day = nextDay;
        isFirst = false;
    } while (day.getTime() < _temp_endDate.getTime());

    return new Date(_startDate_time + ms);
}