import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { KeybindsService } from "app/@core";

import { OverlayService } from "app/@theme";
import { MagicTableComponent } from "app/components/@magic-table/magic-table.component";
import { FilterIndexData, MagicTableRowData, UiFilterIndex } from "app/components/@magic-table/magic-table.types";
import { magicTableCleanFilter } from "app/components/@magic-table/shared/magic-table-clean-filter";
import { magicTableGenDispFilterValue, magicTableGetFilterStatus, magicTableUpdateFilterStatus, magicTableUpdateFiltredRows } from "app/components/@magic-table/shared/magic-table-filter";
import { magicTableOrderByCore } from "app/components/@magic-table/shared/magic-table-order-by-core";
import { magicTableToogleSort } from "app/components/@magic-table/shared/magic-table-toogle-sort";
import { MAGIC_TABLE_CONSTS } from "app/components/@magic-table/shared/MAGIC_TABLE_CONSTS";

@Component({
    selector: 'magic-table-col-options-overlay',
    templateUrl: './magic-table-col-options-overlay.component.html',
    styleUrls: ['./magic-table-col-options-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MagicTableColOptionsOverlayComponent implements OnInit, OnDestroy {

    @Input() id: string = '';
    className = 'MagicTableColOptionsOverlayComponent';
    loader: boolean = true;
    onCloseData = {};

    @Input() magicTableComponent: MagicTableComponent;
    @Input() col_index: number;

    searchText: string = '';
    @ViewChild('SearchInputERef', { static: true }) searchInputERef: ElementRef;

    filterIndex: UiFilterIndex = [];
    searchResult: UiFilterIndex = [];

    selectAll: boolean = false;
    addCurSelTofilter: boolean = true;

    constructor(
        public overlayCtrl: OverlayService,
        public keybindsCtrl: KeybindsService,
    ) { }
    @HostListener('window:keydown.enter', ['$event'])
    on_keydown_enter() {
        if (this.searchResult.length > 0)
            this.filter();
    }

    ngOnInit() {
        this.loader = true;

        this.keybindsCtrl.add(this.className, {
            keyCode: 'KeyL',
            altKey: true,
            function: () => this.cleanFilter()
        });

        this.searchText = '';
        this.addCurSelTofilter = false;

        let tempRows: MagicTableRowData[] = [];
        for (let row of this.magicTableComponent.table.rows) {
            let tempRow = row;
            let validRow = true;
            for (let col_index1 = 0; col_index1 < this.magicTableComponent.table.cols.length; col_index1++) {
                if (col_index1 != this.col_index && this.magicTableComponent.filterActiveIndex[col_index1]) {
                    validRow = magicTableGetFilterStatus(this.magicTableComponent, col_index1, tempRow.cells[col_index1]);
                    if (!validRow)
                        break;
                }
            }
            if (validRow)
                tempRows.push(row);
        }
        let tempColIndex: FilterIndexData[] = [];
        let tempSingleValues = [];

        for (let r of tempRows) {
            const col = this.magicTableComponent.table.cols[this.col_index];
            const fData = r.obj[col.fieldName];
            const fCell = magicTableGenDispFilterValue(col, r);

            if (!Array.isArray(fData)) {
                // not array
                if (tempSingleValues.indexOf(fCell) == -1) {
                    tempSingleValues.push(fCell)
                    let show = magicTableGetFilterStatus(this.magicTableComponent, this.col_index, fData);
                    tempColIndex.push({ show: show, data: fData, cell: fCell as any })
                }
            } else {
                // array
                for (let i = 0; i < fData.length; i++) {
                    const fd = fData[i];
                    const fc = fCell[i];
                    if (tempSingleValues.indexOf(fc) == -1) {
                        tempSingleValues.push(fc)
                        let show = magicTableGetFilterStatus(this.magicTableComponent, this.col_index, fd);
                        tempColIndex.push({ show: show, data: fd, cell: fc as any })
                    }
                }
                // empty array
                if (fData.length == 0) {
                    if (tempSingleValues.indexOf(MAGIC_TABLE_CONSTS.EMPTY_ARRAY_FILTER_VALUE) == -1) {
                        tempSingleValues.push(MAGIC_TABLE_CONSTS.EMPTY_ARRAY_FILTER_VALUE);
                        let show = magicTableGetFilterStatus(this.magicTableComponent, this.col_index, MAGIC_TABLE_CONSTS.EMPTY_ARRAY_DATA_VALUE);
                        tempColIndex.push({ show: show, data: MAGIC_TABLE_CONSTS.EMPTY_ARRAY_DATA_VALUE, cell: MAGIC_TABLE_CONSTS.EMPTY_ARRAY_FILTER_VALUE })
                    }
                }
            }
        }

        const ord = this.magicTableComponent.orderByIndex[this.col_index] ? this.magicTableComponent.orderByIndex[this.col_index] : "asc";
        tempColIndex.sort((a, b) => magicTableOrderByCore(a.cell, b.cell, ord));

        this.filterIndex = JSON.parse(JSON.stringify(tempColIndex));
        this.searchResult = JSON.parse(JSON.stringify(tempColIndex));
        this.selectAll = !this.magicTableComponent.filterActiveIndex[this.col_index];

        this.loader = false;

        setTimeout(() => {
            this.searchInputERef.nativeElement.focus();
        }, 200);
    }
    ngOnDestroy() {
        this.keybindsCtrl.remove(this.className);
    }
    close() {
        this.overlayCtrl.closeOverlay(this.id);
    }

    // ---------------------- FILTERS
    filter() {
        if (this.searchResult.length == 0)
            return;

        if (this.addCurSelTofilter) {
            // Add Cur Sel To filter
            for (let fi of this.searchResult)
                magicTableUpdateFilterStatus(this.magicTableComponent, this.col_index, fi.data, fi.show);
        } else {
            if (this.searchText.length > 0)
                for (let fi of this.magicTableComponent.filterIndexes[this.col_index]) {
                    let index = this.searchResult.indexOf(fi);
                    if (index == -1)
                        fi.show = false;
                }

            for (let fi of this.searchResult)
                magicTableUpdateFilterStatus(this.magicTableComponent, this.col_index, fi.data, fi.show)
        }

        // Filter Active
        let tempFilterActive = false;
        for (let fi of this.magicTableComponent.filterIndexes[this.col_index]) {
            if (!fi.show) {
                tempFilterActive = true;
                break;
            }
        }
        this.magicTableComponent.filterActiveIndex[this.col_index] = tempFilterActive;

        magicTableUpdateFiltredRows(this.magicTableComponent);
        this.close();
    }
    toogleAll() {
        for (let f of this.searchResult)
            if (this.selectAll)
                f.show = false;
            else
                f.show = true;
        this.selectAll = !this.selectAll;
    }
    cleanFilter() {
        magicTableCleanFilter(this.magicTableComponent, this.col_index);
        this.close();
    }
    updateSearchResult() {
        if (!this.filterIndex)
            return [];
        if (!this.searchText)
            return this.filterIndex;
        this.searchText = this.searchText.toLocaleLowerCase();
        let tempSearchResult = this.filterIndex.filter((it) => {
            return it.cell.toLocaleLowerCase().includes(this.searchText);
        });
        tempSearchResult.forEach((item) => {
            item.show = true;
        })
        this.searchResult = tempSearchResult;
    }
    search() {
        this.updateSearchResult();
    }
    toogleSort(ord: 'asc' | 'desc') {
        magicTableToogleSort(this.magicTableComponent, this.col_index, ord);
        this.close();
    }
    updateSelectAll() {
        let temp_filterSelectAll = true;
        for (let f of this.searchResult)
            if (!f.show) {
                temp_filterSelectAll = false;
                break
            }
        this.selectAll = temp_filterSelectAll;
    }
    // ---------------------- FILTERS

}