import firebase from "firebase/compat/app";

export class PM_ToDo {

    id: string;
    boardId: string;
    cardId: string;
    listId: string;
    parentKey: string;

    title: string;
    assignedTo: string;

    startDate: firebase.firestore.Timestamp | null;
    startDateString: string | null;
    startDateHourString: string | null;

    dueDate: firebase.firestore.Timestamp;
    dueDateString: string | null;
    dueDateHourString: string | null;

    done: boolean;
    doneAt: firebase.firestore.Timestamp | null;
    doneAtString: string | null;
    doneAtHourString: string | null;

    createdOn: firebase.firestore.Timestamp;
    updatedOn: firebase.firestore.Timestamp;
    createdBy: string;
    updatedBy: string;

    public constructor(init?: Partial<PM_ToDo>) {

        this.id = '';
        this.boardId = '';
        this.cardId = '';
        this.listId = '';
        this.parentKey = '';

        this.title = '';
        this.assignedTo = '';

        this.startDate = null;
        this.startDateString = null;
        this.startDateHourString = null;

        this.dueDate = null;
        this.dueDateString = null;
        this.dueDateHourString = null;

        this.done = false;
        this.doneAt = null;
        this.doneAtString = null;
        this.doneAtHourString = null;

        this.createdOn = firebase.firestore.Timestamp.now();
        this.updatedOn = null;
        this.createdBy = '';
        this.updatedBy = null;

        if (init)
            Object.assign(this, init);
    }
}