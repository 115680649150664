import { CreateAuthObjectFieldModalModule } from "./access-control/authorization-structure/create-auth-object-field-modal/create-auth-object-field-modal.module";
import { CreateAuthObjectModalModule } from "./access-control/authorization-structure/create-auth-object-modal/create-auth-object-modal.module";
import { EditAuthObjectFieldModalModule } from "./access-control/authorization-structure/edit-auth-object-field-modal/edit-auth-object-field-modal.module";
import { EditAuthObjectModalModule } from "./access-control/authorization-structure/edit-auth-object-modal/edit-auth-object-modal.module";
import { AddObjectToRoleModalModule } from "./access-control/roles/role-detail/add-object-to-role-modal/add-object-to-role-modal.module";
import { CreateRoleUserModalModule } from "./access-control/roles/role-detail/create-role-user-modal/create-role-user-modal.module";
import { EditAuthValuesModalModule } from "./access-control/roles/role-detail/edit-auth-values-modal/edit-auth-values-modal.module";
import { RoleUserModalModule } from "./access-control/roles/role-detail/role-user-modal/role-user-modal.module";

export const SYS_MODALS = [
    CreateAuthObjectModalModule,
    CreateAuthObjectFieldModalModule,
    EditAuthObjectFieldModalModule,
    EditAuthObjectModalModule,
    AddObjectToRoleModalModule,
    EditAuthValuesModalModule,
    RoleUserModalModule,
    CreateRoleUserModalModule,
];
