import { Component, OnChanges, OnInit, SimpleChange } from '@angular/core';

import { Log, PM_BoardActivity, PM_CardAttachment } from 'app/@firebase';
import { SYS_FILE_GENERIC_TYPE_ICON, SYS_FILE_TYPES_ICONS } from 'app/routes/main/sys/SYS_CONSTS';

import { PmCardModal } from '../../../pm-card-modal';

export class AttachmentsDataType {
  attachment: PM_CardAttachment;
  icon: {
    icon: string;
    pack: string;
    colorHex: string;
  }
  public constructor(init?: Partial<AttachmentsDataType>) {
    this.attachment = new PM_CardAttachment();
    this.icon = {
      icon: "",
      pack: "",
      colorHex: ""
    };
    if (init)
      Object.assign(this, init);
  }
}

@Component({
  selector: 'mode-attachments',
  templateUrl: './mode-attachments.component.html',
  styleUrls: ['./mode-attachments.component.scss']
})
export class PMCardModalModeAttachmentsComponent implements OnInit, OnChanges {

  className = 'PMCardModalModeAttachmentsComponent';

  _loader = true;

  _attachmentsToCreate: PM_CardAttachment[] = [];
  _attachments: AttachmentsDataType[] = [];
  _attachmentsMap: Record<string, AttachmentsDataType> = {};

  constructor(
    public pmCardModal: PmCardModal,
  ) { }

  ngOnInit() {
    this.load();
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }
  ngOnDestroy() { }
  _trackByFn(index, item) {
    return index;
  }

  load() {
    this._loader = true;
    this.pmCardModal.db.projectManagement.kanban.cardAttachments
      .getDataByWhere('cardId', '==', this.pmCardModal.card.id)
      .then((data) => {
        this._attachments = data.map((attachment) => {
          let fileIcon = SYS_FILE_TYPES_ICONS.filter((fi) => { return fi.extensions.includes(attachment.extension); })[0];
          if (!fileIcon)
            fileIcon = SYS_FILE_GENERIC_TYPE_ICON;
          return new AttachmentsDataType({
            attachment: attachment,
            icon: fileIcon
          });
        });
        let tempAttachmentsMap = {};
        this._attachments.forEach((attachmentData) => {
          tempAttachmentsMap[attachmentData.attachment.id] = attachmentData;
        });
        this._attachmentsMap = tempAttachmentsMap;
        this._loader = false;
      })
      .catch((e) => {
        this._loader = false;
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erros', e)
      })
  }
  _addAttachment(e) {
    this.pmCardModal.uiFeedBackCtrl.presentLoader('Lendo arquivos...')
      .then(() => {

        const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        if (!files || files.length == 0) {
          this.pmCardModal.uiFeedBackCtrl.dismissLoader();
          return;
        }

        this._attachmentsToCreate = [];
        let readAsDataURLPromises = [];

        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          // const pattern = /image-*/;
          const reader = new FileReader();
          // if (!file.type.match(pattern)) {
          //   alert('invalid format');
          //   return;
          // }

          // Attachment
          let newAttachment = new PM_CardAttachment();
          newAttachment.id = this.pmCardModal.db.createId();
          newAttachment.cardId = this.pmCardModal.card.id;
          newAttachment.boardId = this.pmCardModal.card.boardId;
          newAttachment.parentKey = this.pmCardModal.board.childsKey;
          newAttachment.createdBy = this.pmCardModal.authService.localUser.uName;

          newAttachment.name = file.name;
          newAttachment.extension = file.name.split('.').pop();
          newAttachment.size = file.size;
          newAttachment.mimeType = file.type;

          this._attachmentsToCreate.push(newAttachment);
          // Attachment

          readAsDataURLPromises.push(
            new Promise((resolve, reject) => {
              try {
                const lastIndex = this._attachmentsToCreate.length - 1;
                reader.onload = (file) => {
                  const dataUrl = reader.result.toString();
                  this._attachmentsToCreate[lastIndex].url = dataUrl;
                  resolve(undefined);
                }
                reader.readAsDataURL(file)
              } catch (e) {
                reject(e);
              }
            })
          )

        }

        Promise.all(readAsDataURLPromises)
          .then(() => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
            this.__saveAttachments();
          })
          .catch((e) => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e);
          })

      });
  }
  private __saveAttachments() {
    this.pmCardModal.uiFeedBackCtrl.presentLoader('Salvando...')
      .then(() => {
        this.pmCardModal.authService.getUser()
          .then(user => {

            let COLECTION = this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.cardAttachments;
            let LOGS_COLECTION = this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.cards;

            // Transaction
            this.pmCardModal.db.runTransaction(transaction => {

              // Activity
              let tempActivity = new PM_BoardActivity();

              tempActivity.id = this.pmCardModal.db.afs.createId();
              tempActivity.uName = this.pmCardModal.authService.localUser.uName;
              tempActivity.cardId = this.pmCardModal.card.id;
              tempActivity.boardId = this.pmCardModal.card.boardId;
              tempActivity.parentKey = this.pmCardModal.board.childsKey;

              tempActivity.type = "C";

              tempActivity.text = `Anexo adicionado(s)`;
              tempActivity.html = `<p>Anexo adicionado(s)</p>`;
              tempActivity.attachments = this._attachmentsToCreate.map((a) => a.id);

              transaction.set(
                this.pmCardModal.db.afs.firestore
                  .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
                Object.assign({}, tempActivity)
              );
              // Activity

              let uploadPromises = [];

              // CREATE
              this._attachmentsToCreate
                .forEach(d => {
                  uploadPromises.push(
                    new Promise((resolve, reject) => {
                      this.pmCardModal.storage.uploadFileAsDataUrl(COLECTION, d.name, d.url)
                        .then(url => {
                          d.url = url;

                          // Log
                          let tempLog = new Log();

                          tempLog.id = `${COLECTION}-${this.pmCardModal.db.afs.createId()}`;
                          tempLog.className = this.pmCardModal.className;
                          tempLog.uName = user.uName;

                          tempLog.type = "AUDIT";
                          tempLog.category = "CR";

                          tempLog.description = `Anexo adicionado: ${d.name}`;

                          tempLog.docPath = LOGS_COLECTION;
                          tempLog.docId = this.pmCardModal.card.id;
                          // Log

                          // Attachment
                          transaction.set(
                            this.pmCardModal.db.afs.firestore
                              .collection(COLECTION).doc(d.id),
                            Object.assign({}, d)
                          );
                          // Attachment

                          // Log
                          transaction.set(
                            this.pmCardModal.db.afs.firestore
                              .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
                            Object.assign({}, tempLog)
                          );
                          // Log

                          resolve(undefined);
                        })
                        .catch(e => reject(e))
                    })
                  )
                });
              // CREATE


              return Promise.all(uploadPromises)
                .then(() => Promise.resolve())
                .catch(e => Promise.reject(e))

            })
              .then(() => {
                this.load();
                this.pmCardModal.uiFeedBackCtrl.dismissLoader();
                this.pmCardModal.uiFeedBackCtrl.presentAlert(`Anexos Salvos!`, ``, `success`, true);
              })
              .catch((e) => {
                this.pmCardModal.uiFeedBackCtrl.dismissLoader();
                this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e);
              })
            // Transaction


          })
          .catch((e) => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e);
          })
      });
  }
  _remAttachment(docAttachment: PM_CardAttachment) {
    this.pmCardModal.uiFeedBackCtrl.presentLoader('Salvando...')
      .then(() => {
        this.pmCardModal.authService.getUser()
          .then(user => {

            let COLECTION = this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.cardAttachments;
            let LOGS_COLECTION = this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.cards;

            // Transaction
            this.pmCardModal.db.runTransaction(transaction => {


              // Log
              let tempLog = new Log();

              tempLog.id = `${COLECTION}-${this.pmCardModal.db.afs.createId()}`;
              tempLog.className = this.pmCardModal.className;
              tempLog.uName = user.uName;

              tempLog.type = "AUDIT";
              tempLog.category = "DE";

              tempLog.description = `Anexo Removido: ${docAttachment.name}`;

              tempLog.docPath = LOGS_COLECTION;
              tempLog.docId = this.pmCardModal.card.id;
              // Log

              // Activity
              let tempActivity = new PM_BoardActivity();

              tempActivity.id = this.pmCardModal.db.afs.createId();
              tempActivity.uName = this.pmCardModal.authService.localUser.uName;
              tempActivity.cardId = this.pmCardModal.card.id;
              tempActivity.boardId = this.pmCardModal.card.boardId;
              tempActivity.parentKey = this.pmCardModal.board.childsKey;

              tempActivity.type = "C";

              tempActivity.text = `Anexo Removido: ${docAttachment.name}`;
              tempActivity.html = `<p>Anexo Removido: ${docAttachment.name}</p>`;
              // Activity


              // Attachment
              transaction.delete(
                this.pmCardModal.db.afs.firestore
                  .collection(COLECTION).doc(docAttachment.id)
              );
              // Attachment

              // Log
              transaction.set(
                this.pmCardModal.db.afs.firestore
                  .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
                Object.assign({}, tempLog)
              );
              // Log

              // Activity
              transaction.set(
                this.pmCardModal.db.afs.firestore
                  .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
                Object.assign({}, tempActivity)
              );
              // Activity

              return Promise.resolve()

            })
              .then(() => {
                this.load();
                this.pmCardModal.uiFeedBackCtrl.dismissLoader();
                this.pmCardModal.uiFeedBackCtrl.presentAlert(`Anexo Removido!`, ``, `success`, true);
              })
              .catch((e) => {
                this.pmCardModal.uiFeedBackCtrl.dismissLoader();
                this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e);
              })
            // Transaction

          })
          .catch((e) => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e);
          })
      });
  }
}
