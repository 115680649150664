<div class="data-visualization">
  <div class="inner">

    <div *ngIf="_loader" class="loader">
      <p><b>Carregando...</b></p>
    </div>

    <div class="errors" *ngIf="_errors.length>0">
      <p><b>Errors:</b></p>
      <p *ngFor="let e of _errors">{{e}}</p>
    </div>

    <div class="engine">
      <div *ngIf="_engine=='magic-table'" class="magic-table">
        <magic-table [id]="'data-visualization_'+id" [title]="title" [loader]="_loader" [data]="_magicTable_data"
          [columns]="_magicTable_columns">
        </magic-table>
      </div>
      <div *ngIf="_engine=='echart'" class="echart">
        <div echarts (chartInit)="_onChartInit($event)" [options]="_echartsOptions" class="chart"></div>
      </div>
    </div>
  </div>
</div>
