<div class="reporting-columns" [ngClass]="{'fullscreen': _themeService.fullscreen}">

    <div class="columns" *ngFor="let columData of _reportingCtrl.columns; let column_i=index;">

        <div class="column-wrap" *ngIf="columData.show"
            [ngClass]="{'active': (_reportingCtrl._filtersActiveMap[columData.field] || _reportingCtrl._orderMap[columData.field]), 'error': _reportingCtrl._filtersErrorMap[columData.field]}">

            <button [disabled]="!_reportingCtrl._editColumns || _reportingCtrl.loading" class="action"
                title="Limpar Filtro" *ngIf="_reportingCtrl._filtersActiveMap[columData.field]"
                (click)="_uiCleanFilter(columData.field)">
                <div class="close-icon" icon="close-outline" pack="eva"></div>
                <nb-icon icon="funnel-outline" pack="eva"></nb-icon>
            </button>

            <button [disabled]="!_reportingCtrl._editColumns || _reportingCtrl.loading" class="action"
                title="Limpar Ordenação" *ngIf="_reportingCtrl._orderMap[columData.field]"
                (click)="_uiToogleSort(columData.field, _reportingCtrl._orderMap[columData.field])">
                <div class="close-icon" icon="close-outline" pack="eva"></div>

                <nb-icon *ngIf="_reportingCtrl._orderMap[columData.field] =='asc'" icon="arrow-upward-outline"
                    pack="eva"></nb-icon>

                <nb-icon *ngIf="_reportingCtrl._orderMap[columData.field] =='desc'" icon="arrow-downward-outline"
                    pack="eva"></nb-icon>
            </button>

            <button [disabled]="_reportingCtrl.loading" (click)="_uiOpenColumnPopdown(column_i)">
                <nb-icon
                    *ngIf="!_reportingCtrl._filtersActiveMap[columData.field] && !_reportingCtrl._orderMap[columData.field]"
                    [icon]="columData.icon" pack="eva"></nb-icon>
                <span>{{columData.title}}</span>
                <nb-icon icon="chevron-down-outline" pack="eva"></nb-icon>
            </button>
        </div>

        <!-- Popdown -->
        <div class="popdown" *ngIf="_uiShowColumnPopdown == column_i">
            <div class="popdownContent" #ColumnPopdownContentERef>

                <button [disabled]="!_reportingCtrl._editColumns || _reportingCtrl.loading" class="list-button"
                    (click)="_uiToogleSort(columData.field, 'asc')"
                    [ngClass]="{'sort-active': _reportingCtrl._orderMap[columData.field] == 'asc'}">
                    <span class="ltext">
                        <span style="color: blue;">A</span>
                        <span>Z</span>
                    </span>
                    <nb-icon icon="arrow-downward-outline" pack="eva"></nb-icon>
                    Classificar de A a Z
                </button>
                <button [disabled]="!_reportingCtrl._editColumns || _reportingCtrl.loading" class="list-button"
                    (click)="_uiToogleSort(columData.field, 'desc')"
                    [ngClass]="{'sort-active': _reportingCtrl._orderMap[columData.field] == 'desc'}">
                    <span class="ltext">
                        <span>Z</span>
                        <span style="color: blue;">A</span>
                        <nb-icon icon="arrow-downward-outline" pack="eva"></nb-icon>
                    </span>
                    Classificar de Z a A
                </button>
                <hr>
                <button
                    [disabled]="!_reportingCtrl._editColumns || _reportingCtrl.loading || !_reportingCtrl._filtersActiveMap[columData.field]"
                    class="list-button" (click)="_uiCleanFilter(columData.field)">
                    <nb-icon style="color: red;" icon="funnel-outline" pack="eva"></nb-icon>
                    Limpar Filtro
                </button>

                <input #ColumnPopdownSearchInputERef class="list-input" type="text"
                    [id]="'ColumnPopdownSearchInput_'+column_i" [name]="'headerPopdownSearchInput_'+column_i"
                    aria-describedby="search-text" placeholder="Pesquisar..." autocomplete="nope"
                    [(ngModel)]="_uiColumnPopdownData.searchText" autofocus (ngModelChange)="_uiUpdateSearchResult()"
                    (keyup.enter)="_uiFilter(columData.field)">

                <div class="search-list-group search-list-group-flush">

                    <!-- (Selecionar Tudo) -->
                    <div class="search-list-group-item" *ngIf="_uiColumnPopdownData.searchText.length == 0">
                        <input
                            [disabled]="!_reportingCtrl._editColumns || _reportingCtrl.loading || _uiColumnPopdownData.searchResult.length == 0"
                            (click)="_uiToogleAll()" type="checkbox" id="{{'input_unselectAll-'+_reportingCtrl.id}}"
                            name="{{'input_unselectAll-'+_reportingCtrl.id}}"
                            [(ngModel)]="_uiColumnPopdownData.selectAll">
                        <label for="{{'input_unselectAll-'+_reportingCtrl.id}}">
                            (Selecionar Tudo)
                        </label>
                    </div>
                    <!-- (Selecionar Tudo) -->

                    <!-- (Selecionar Todos os Resultados da Pesquisa) -->
                    <div class="search-list-group-item"
                        *ngIf="_uiColumnPopdownData.searchText.length > 0 && _uiColumnPopdownData.searchResult.length > 0">
                        <input [disabled]="!_reportingCtrl._editColumns || _reportingCtrl.loading"
                            (click)="_uiToogleAll()" type="checkbox"
                            id="{{'input_filterSelectAllSearchResult-'+_reportingCtrl.id}}"
                            name="{{'input_filterSelectAllSearchResult-'+_reportingCtrl.id}}"
                            [(ngModel)]="_uiColumnPopdownData.selectAll">
                        <label for="{{'input_filterSelectAllSearchResult-'+_reportingCtrl.id}}">
                            (Selecionar Todos os Resultados da Pesquisa)
                        </label>
                    </div>
                    <!-- (Selecionar Todos os Resultados da Pesquisa) -->

                    <!-- (Adicionar seleção atual ao filtro) -->
                    <div class="search-list-group-item"
                        *ngIf="_uiColumnPopdownData.searchText.length > 0 && _uiColumnPopdownData.searchResult.length > 0">
                        <input [disabled]="!_reportingCtrl._editColumns || _reportingCtrl.loading" type="checkbox"
                            id="{{'input_addCurSelTofilter-'+_reportingCtrl.id}}"
                            name="{{'input_addCurSelTofilter-'+_reportingCtrl.id}}"
                            [(ngModel)]="_uiColumnPopdownData.AddCurSelTofilter">
                        <label for="{{'input_addCurSelTofilter-'+_reportingCtrl.id}}">
                            (Adicionar seleção atual ao filtro)
                        </label>
                    </div>
                    <!-- (Adicionar seleção atual ao filtro) -->

                    <div class="search-list-group-item"
                        *ngFor="let c of _uiColumnPopdownData.searchResult; let input_filter_check_i=index">
                        <input [disabled]="!_reportingCtrl._editColumns || _reportingCtrl.loading" type="checkbox"
                            [id]="'input_filter_check_'+input_filter_check_i"
                            [name]="'input_filter_check_'+input_filter_check_i" [(ngModel)]="c.show"
                            (ngModelChange)="_uiUpdateSelectAll()">
                        <label [for]="'input_filter_check_'+input_filter_check_i" reportingHighlight
                            [searchedWord]="_uiColumnPopdownData.searchText" [content]="c.dispValue"
                            [classToApply]="'highlight'" [setTitle]="true">{{c.dispValue}}</label>
                    </div>
                </div>

                <button
                    [disabled]="!_reportingCtrl._editColumns || _reportingCtrl.loading || _uiColumnPopdownData.searchResult.length == 0"
                    class="list-button" (click)="_uiFilter(columData.field)">
                    Filtrar
                </button>

            </div>
        </div>
        <!-- Popdown -->

    </div>

    <button [disabled]="!_reportingCtrl._editColumns || _reportingCtrl.loading" class="clean-filter-btn"
        *ngIf="_uiShowCleanFiltersBtn()" icButton status="danger" size="small" title="Limpar todos os filtros"
        (click)="_uiCleanFilters()">
        <nb-icon icon="funnel-outline" pack="eva"></nb-icon>
    </button>

    <button [disabled]="!_reportingCtrl._editColumns || _reportingCtrl.loading" class="clean-filter-btn"
        *ngIf="_uiShowCleanOrdBtn()" icButton status="danger" size="small" title="Limpar todos os ordenadores"
        (click)="_uiCleanOrd()">
        <nb-icon icon="arrow-downward-outline" pack="eva"></nb-icon>
    </button>
</div>