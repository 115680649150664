import firebase from "firebase/compat/app";

/**
 * @SYS Systema
 * @AUDIT Aditoria
 */
export type LogType =
  "SYS" |
  "AUDIT";

/**
 * @GR Geral
 * @C Comentário
 *
 * @CR Criação
 * @FC Alteraçõees
 * @DE Apagar
 *
 * @PR Impressão
 * @ER Erro
 * @SEC Security
 *
 * @DL Documento Bloqueado
 * @DU Documento Desbloqueado
 */
export type LogCategory =
  "GR" |
  "DE" |
  "CR" |
  "C" |
  "FC" |
  "PR" |
  "ER" |
  "SEC" |
  "DL" |
  "DU";

export class AuditLogChange {
  fieldName: string;
  oldValue: string;
  newValue: string;
  public constructor(init?: Partial<AuditLogChange>) {
    this.fieldName = '';
    this.oldValue = '';
    this.newValue = '';
    if (init)
      Object.assign(this, init);
  }
}

export class Log {

  id: string;
  pageId: string;
  className: string;
  uName: string;

  type: LogType;
  category: LogCategory;

  description: string;
  createdOn: firebase.firestore.Timestamp;

  // AUDIT
  docPath: string;
  docId: string;
  changes: AuditLogChange[];
  // AUDIT

  public constructor(init?: Partial<Log>) {

    this.id = '';
    this.pageId = '';
    this.className = '';
    this.uName = '';

    this.type = 'SYS';
    this.category = 'GR';

    this.description = '';
    this.createdOn = firebase.firestore.Timestamp.now();

    // AUDIT
    this.docPath = '';
    this.docId = '';
    this.changes = [];
    // AUDIT

    if (init)
      Object.assign(this, init);
  }
}
