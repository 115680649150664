import firebase from "firebase/compat/app";

export class TM_ProjectTask {

  id: string;
  projectId: string;
  parentKey: string;
  tagId: string;

  name: string;
  description: string;

  hoursBudget: number;
  conclusion: number;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<TM_ProjectTask>) {

    this.id = '';
    this.projectId = '';
    this.parentKey = '';
    this.tagId = '';

    this.name = '';
    this.description = '';

    this.hoursBudget = 0;
    this.conclusion = 0;

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;


    if (init)
      Object.assign(this, init);
  }
}
