import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import firebase from "firebase/compat/app";

import { UtilService } from 'app/@core';
import { FirestoreService, Log } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

class DocData {
  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;
  public constructor(init?: Partial<DocData>) {
    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;
    if (init)
      Object.assign(this, init);
  }
}

@Component({
  selector: 'version-tab',
  templateUrl: './version-tab.component.html',
  styleUrls: ['./version-tab.component.scss']
})
export class VersionTabComponent implements OnInit {

  @Input() activities: Log[];
  @Input() docData: DocData = new DocData();
  @Input() showPrice_Cost = false;

  constructor(
    public db: FirestoreService,
    public uiFeedBackCtrl: UiFeedBackService,
    public utilCtrl: UtilService,
  ) { }

  ngOnInit() { }

  openUserDetail(act: Log) {
    // this.uiFeedBackCtrl.presentCustonAlert({
    //   title: `Detalhes do usuário do log de ${this.utilCtrl.timestamp.toLocalDate(act.createdOn)}`,
    //   message: act.description,
    //   inputs: [
    //     {
    //       disabled: true,
    //       type: 'text',
    //       label: 'Nome',
    //       name: 'Nome',
    //       value: act.user.fullName,
    //     },
    //     {
    //       disabled: true,
    //       type: 'text',
    //       label: 'Email',
    //       name: 'Email',
    //       value: act.user.email,
    //     },

    //   ],
    //   buttons: [
    //     {
    //       text: 'Fechar'
    //     }
    //   ]
    // })
  }



}
