import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { AuthService } from 'app/@core/services/auth.service';
import { UiFeedBackService } from 'app/@theme/services';

const USER_CONFIG_PARAM_ID = "performance-measurement-display";

@Component({
    selector: 'performance-measurement-display',
    styleUrls: ['./performance-measurement-display.component.scss'],
    templateUrl: './performance-measurement-display.component.html',
})
export class PerformanceMeasurementDisplayComponent implements OnInit, OnDestroy {
    className = "PerformanceMeasurementDisplayComponent";
    private destroy$: Subject<void> = new Subject<void>();
    private keyCode = 'Backquote';
    private keyPressed = true;
    public show = false;
    public fps = {
        frameCount: 0,
        lastTime: performance.now(),
        fps: 0,
    };
    public memoryUsage = {
        total: '',
        used: '',
        limit: '',
    };
    constructor(
        public authService: AuthService,
        public uiFeedBackService: UiFeedBackService,
    ) { }
    @HostListener('document:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.code == this.keyCode && event.ctrlKey && !this.keyPressed) {
            this.keyPressed = true;
            this.toogleShow();
        }
    }
    @HostListener('document:keyup', ['$event'])
    onKeyUp(event: KeyboardEvent) {
        if (event.code == this.keyCode) {
            this.keyPressed = false;
        }
    }
    toogleShow() {
        this.uiFeedBackService.presentLoader()
            .then(() => {
                this.authService.set_parameter(USER_CONFIG_PARAM_ID, !this.show)
                    .then(() => {
                        this.uiFeedBackService.dismissLoader();
                    })
                    .catch((e) => {
                        this.uiFeedBackService.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
                    });
            });
    }
    shouldShowPerformance() {
        return this.show;
    }
    _updateShow() {
        this.fps = {
            frameCount: 0,
            lastTime: performance.now(),
            fps: 0,
        };
        this.memoryUsage = {
            total: '',
            used: '',
            limit: '',
        };
        this.measureFPS();
        this.measureMemory();
    }
    ngOnInit() {
        this._updateShow();
        this.authService.getUserParametersChanges()
            .pipe(
                takeUntil(this.destroy$),
            )
            .subscribe((userParameters) => {
                this.authService.get_parameter(USER_CONFIG_PARAM_ID)
                    .then((data) => {
                        const newShow = data ? data == true : false;
                        if (newShow != this.show) {
                            this.show = newShow;
                            this._updateShow();
                        }
                    })
                    .catch((e) => {
                        this.uiFeedBackService.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
                    });
            });
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
    measureFPS() {
        const currentTime = performance.now();
        this.fps.frameCount++;
        if (currentTime - this.fps.lastTime >= 1000) {
            this.fps.fps = this.fps.frameCount;
            this.fps.frameCount = 0;
            this.fps.lastTime = currentTime;
        }

        if (this.shouldShowPerformance())
            requestAnimationFrame(() => this.measureFPS())
    }
    measureMemory() {
        const formatBytes = (bytes, decimals = 0) => {
            if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
        if (window.performance && (window.performance as any).memory) {
            const memory = (window.performance as any).memory;
            this.memoryUsage = {
                total: formatBytes(memory.totalJSHeapSize), // Tamanho total da heap JS em bytes
                used: formatBytes(memory.usedJSHeapSize), // Memória usada pela heap JS em bytes
                limit: formatBytes(memory.jsHeapSizeLimit), // Limite da heap JS
            };
        }

        if (this.shouldShowPerformance())
            setTimeout(() => this.measureMemory(), 500);
    }
}
