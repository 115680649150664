export type DB_OrderByRuleDirection = "asc" | "desc";

export class DB_OrderByRule {
  fieldId: string;
  direction: DB_OrderByRuleDirection;
  public constructor(init?: Partial<DB_OrderByRule>) {
    this.fieldId = "";
    this.direction = 'asc';
    if (init)
      Object.assign(this, init);
  }
}