import firebase from "firebase/compat/app";

export class PM_ToDoList {

    id: string;
    boardId: string;
    cardId: string;
    uName: string;
    parentKey: string;

    title: string;
    toDosIds: string[];

    createdOn: firebase.firestore.Timestamp;
    updatedOn: firebase.firestore.Timestamp;
    createdBy: string;
    updatedBy: string;

    public constructor(init?: Partial<PM_ToDoList>) {

        this.id = '';
        this.boardId = '';
        this.cardId = '';
        this.uName = '';
        this.parentKey = '';

        this.title = '';
        this.toDosIds = [];

        this.createdOn = firebase.firestore.Timestamp.now();
        this.updatedOn = null;
        this.createdBy = '';
        this.updatedBy = null;

        if (init)
            Object.assign(this, init);
    }
}
