import { DB_DataResult, DB_OrderByRule } from "app/@firebase";
import { ThemeService } from "app/@theme/theme.service";

import { MagicTableColumn } from "../@magic-table";

// Others
export type VisTypeId =
  | 'bar'
  | 'line'
  | 'area'
  | 'pie'
  | 'bubble'
  | 'heat_map'
  | 'summary_table'
  | 'table'
  | 'treemap'
  | 'statistics';

export type VisEngineTypeId =
  | 'echart'
  | 'magic-table';

export class VisDataCube {
  labels: string[];
  values: number[];
  categoriesDataCube: Map<string, number[]>;
  public constructor(init?: Partial<VisDataCube>) {
    this.labels = [];
    this.values = [];
    this.categoriesDataCube = new Map<string, number[]>();
    if (init)
      Object.assign(this, init);
  }
};

// ------ Egine Data ------
export class AxisCalculationType {
  id: string;
  name: string;
  dataCubeMaker?: (data: any[], labelsFieldId: string, valueFieldId: string, categoriesFieldId: string) => VisDataCube;
  public constructor(init?: Partial<AxisCalculationType>) {
    this.id = '';
    this.name = '';
    if (init)
      Object.assign(this, init);
  }
};
export class AxisCategoryType {
  id: string;
  visTypeId: VisTypeId;
  name: string;
  description: string;
  optionsMaker?: (vis: DataVisualization, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService) => VisEchartsOptions;
  public constructor(init?: Partial<AxisCategoryType>) {
    this.id = '';
    this.name = '';
    this.visTypeId = null;
    this.description = '';
    if (init)
      Object.assign(this, init);
  }
};
// ------ Egine Data ------

// ------ V I S ------
export class VisType {
  id: string;
  name: string;
  description: string;
  base64Img: string;
  engine: {
    dataProps: string[];
    customizationProps: string[];
    type: VisEngineTypeId;
    optionsMaker?: (vis: DataVisualization, DB_DsDataResult: DB_DataResult, options: VisEchartsOptions, dataCube: VisDataCube, theme: ThemeService) => VisEchartsOptions | VisMagicTableOptions;
    customiseOptions?: (vis: DataVisualization, options: VisEchartsOptions) => VisEchartsOptions;
  };
  public constructor(init?: Partial<VisType>) {
    this.id = '';
    this.name = '';
    this.description = '';
    this.base64Img = '';
    this.engine = {
      dataProps: [],
      customizationProps: [],
      type: null
    };
    if (init)
      Object.assign(this, init);
  }
};
// ------ V I S ------
export type EchartsSeriesType =
  | 'bar'
  | 'line'
  | 'pie';
export class VisMagicTableOptions {
  columns: MagicTableColumn[];
  public constructor(init?: Partial<VisType>) {
    this.columns = [];
    if (init)
      Object.assign(this, init);
  }
};
export class VisEchartsOptions {
  title: {
    text: string;
    subtext: string;
    textStyle: {
      color: string;
      fontSize: string;
    }
  };
  backgroundColor: string;
  tooltip: {
    trigger: 'axis' | 'item';
    formatter?: string,
    axisPointer?: {
      type: 'shadow' | 'cross';
      label?: {
        backgroundColor: string;
      }
      shadowStyle?: {
        color: string;
      };
    }
  };
  toolbox: {
    show: boolean;
    feature: {
      dataView?: { show: boolean; readOnly: boolean };
      magicType?: { show: boolean; type: string[] };
      restore?: { show: boolean };
      saveAsImage?: { show: boolean }
    }
  };
  legend: {
    type: string;
    top: number;
    bottom: number;
    orient: string;
    textStyle: {
      color: string
    };
  };
  grid: {
    left: string;
    right: string;
    bottom: string;
    containLabel: boolean;
  };

  // xAxis
  xAxis: {
    /**
      * Type of axis.
      * Options:
      *  `value` Numerical axis, suitable for continuous data.
      *  `category` Category axis, suitable for discrete category data. Category data can be auto retrieved from series.data or dataset.source, or can be specified via yAxis.data.
      *  `time` Time axis, suitable for continuous time series data. As compared to value axis, it has a better formatting for time and a different tick calculation method. For example, it decides to use month, week, day or hour for tick based on the range of span.
      *  `log` Log axis, suitable for log data.
      */
    type: 'value' | 'category' | 'time' | 'log';
    /** Minimum gap between split lines. */
    minInterval?: number;
    /** Maximum gap between split lines. */
    maxInterval?: number;
    /** The maximum value of axis. */
    min?: number | ((value: number) => number);
    /** The maximum value of axis. */
    max?: number | ((value: number) => number);
    data: number[] | string[];
    /** The boundary gap on both sides of a coordinate axis. The setting and behavior of category axes and non-category axes are different. */
    boundaryGap: boolean | string[],
    axisTick: {
      alignWithLabel: boolean;
    };
    axisLine: {
      lineStyle: {
        color: string;
      };
    };
    axisLabel: {
      color: string;
      fontSize: string;
    };
  };
  // xAxis

  // yAxis
  yAxis: {
    /**
     * Type of axis.
     * Options:
     *  `value` Numerical axis, suitable for continuous data.
     *  `category` Category axis, suitable for discrete category data. Category data can be auto retrieved from series.data or dataset.source, or can be specified via yAxis.data.
     *  `time` Time axis, suitable for continuous time series data. As compared to value axis, it has a better formatting for time and a different tick calculation method. For example, it decides to use month, week, day or hour for tick based on the range of span.
     *  `log` Log axis, suitable for log data.
     */
    type: 'value' | 'category' | 'time' | 'log';
    /** Minimum gap between split lines. */
    minInterval?: number;
    /** Maximum gap between split lines. */
    maxInterval?: number;
    /** The maximum value of axis. */
    min?: number | ((value: number) => number);
    /** The maximum value of axis. */
    max?: number | ((value: number) => number);
    data?: number[] | string[];
    axisLine: {
      lineStyle: {
        color: string;
      };
    };
    splitLine: {
      lineStyle: {
        color: string;
      };
    };
    axisLabel: {
      color: string;
      fontSize: string;
    };
  };
  // yAxis

  series: {
    name?: string;
    type: EchartsSeriesType;
    stack?: string;
    radius?: string | string[];
    label?: {
      show: boolean,
    },
    avoidLabelOverlap?: boolean;
    itemStyle?: {
      emphasis?: {
        shadowBlur: number,
        shadowOffsetX: number,
        shadowColor: string,
      },
      color?: string,
    };
    areaStyle?: {},
    emphasis?: {
      focus: string;
    },
    data: number[] | string[] | { value: number, name: string }[];
  }[];

  /** The color list of palette.
   * If no color is set in series, the colors would be adopted sequentially and circularly from this list as the colors of series.
   * */
  color?: string[];
  _colorMap: Map<string, string>;
}

export class FieldsIndexType {
  id: string; name: string; active: boolean;
}
export class FilterIndexType {
  show: boolean; value: any
}
export class VisTypeEngine {
  dataProps: [];
  customizationProps: [];
  type: "magic-table" | 'echart';
  seriesType: 'bar' | 'pie' | 'line';
  model: {};
}
// Others




// Data Vis
export class VisSource {
  dbDataSourceId: string;
  fields: string[];
  filterRuleSetJson: string;
  orderByRule: DB_OrderByRule;
  limit: number;
  public constructor(init?: Partial<VisSource>) {
    this.dbDataSourceId = "";
    this.fields = [];
    this.filterRuleSetJson = '';
    this.orderByRule = {
      fieldId: '',
      direction: 'asc'
    };
    this.limit = 0;
    if (init)
      Object.assign(this, init);
  }
}

export class VisAxisData {
  xAxis_fieldId: string;
  yAxis_fieldId: string;
  categorizeBy_fieldId: string;
  public constructor(init?: Partial<VisEngine>) {
    this.xAxis_fieldId = null;
    this.yAxis_fieldId = null;
    this.categorizeBy_fieldId = null;
    if (init)
      Object.assign(this, init);
  }
}
export class VisEngineData {
  xAxis?: {
    calcTypeId?: string;
    fieldId?: string;
  };
  categories?: {
    fieldId?: string;
  };

  yAxis?: {
    calcTypeId?: string;
    fieldId?: string;
  };
  values?: {
    calcTypeId?: string;
    fieldId?: string;
  };

  categorizeBy?: {
    catTypeId?: string;
    fieldId?: string;
  };
}
export class VisEngineCustomize {
  hideLegend?: boolean;
  invertAxes?: boolean;
  colors?: {};
}
export class VisEngine {
  data: VisEngineData;
  customize: VisEngineCustomize;
  public constructor(init?: Partial<VisEngine>) {
    this.data = {};
    this.customize = {};
    if (init)
      Object.assign(this, init);
  }
}

export class DataVisualization {
  typeId: VisTypeId;
  source: VisSource;
  engine: VisEngine;
  public constructor(init?: Partial<DataVisualization>) {
    this.typeId = null;
    this.source = new VisSource();
    this.engine = new VisEngine();
    if (init)
      Object.assign(this, init);
  }
}
