import { getLocaleFirstDayOfWeek } from "@angular/common";
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, LOCALE_ID, OnChanges, OnInit, Output, PLATFORM_ID, SimpleChange, ViewChild } from '@angular/core';

export class WeekForSelection {
  year: number;
  week: number;
  month: number;
  weekStartDate: Date;
  weekEndDate: Date;
  days: { day: number; month: number }[];
  public constructor(init?: Partial<WeekForSelection>) {
    this.year = 0;
    this.week = 0;
    this.month = 0;
    this.weekStartDate = new Date();
    this.weekEndDate = new Date();
    this.days = [];
    if (init)
      Object.assign(this, init);
  }
}


@Component({
  selector: 'week-input',
  templateUrl: './week-input.component.html',
  styleUrls: ['./week-input.component.scss']

})
export class WeekInputComponent implements OnInit, OnChanges {

  @ViewChild('popdownContent', { read: ElementRef, static: false }) popdownContent: ElementRef;

  @Input() disabled = false;
  touched = false;

  showSelect = false;

  @Input() year: number = 0;
  @Input() month: number = 0;
  @Input() week: number = 0;
  @Input() weekStartDate: Date = null;
  @Input() weekEndDate: Date = null;

  @Output() yearChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() monthChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() weekChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() weekStartDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() weekEndDateChange: EventEmitter<Date> = new EventEmitter<Date>();

  months = [
    { id: 1, name: 'Janeiro' },
    { id: 2, name: 'Fevereiro' },
    { id: 3, name: 'Março' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Maio' },
    { id: 6, name: 'Junho' },
    { id: 7, name: 'Julho' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Setembro' },
    { id: 10, name: 'Outubro' },
    { id: 11, name: 'Novembro' },
    { id: 12, name: 'Dezembro' },
  ];
  weekDays = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];

  yearsWeeks: WeekForSelection[] = [];
  weeksForSelection: WeekForSelection[] = [];

  constructor(
    @Inject(LOCALE_ID) public locale,
    @Inject(PLATFORM_ID) public platformId
  ) {
    // this.test();
  }
  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.popdownContent && !this.popdownContent.nativeElement.contains(event.target)) {
      this.closeSelect();
    }
  }

  private test() {
    console.log('platformId:', this.platformId);
    console.log('locale:', this.locale);
    console.log(`First Day Of Week: `, this.getFirstDayOfWeek())
  }
  private getFirstDayOfWeek() {
    return getLocaleFirstDayOfWeek(this.locale);
  }
  private getDateStr(date: Date) {
    return date.toISOString().split('T')[0];
  }
  private getDate_Year(date: Date): number {
    return Number(date.toISOString().split('T')[0].split('-')[0]);
  }
  private getDate_Month(date: Date): number {
    return Number(date.toISOString().split('T')[0].split('-')[1]);
  }
  private getDate_Day(date: Date): number {
    return Number(date.toISOString().split('T')[0].split('-')[2]);
  }
  private numberPadding(number: number, size: number): string {
    let s = number.toString();
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }
  /**
   * Get week number in the year.
   * @param  {Date} [date]
   * @param  {Integer} [weekStart=0]  First day of the week. 0-based. 0 for Sunday, 6 for Saturday.
   * @return {Integer}                0-based number of week.
   */
  private getWeek(date: Date, weekStart = this.getFirstDayOfWeek()): number {
    let januaryFirst = new Date(date.getFullYear(), 0, 1);
    let numOfdaysPastSinceFirstDayOfFirstWeek = januaryFirst.getDay();
    let firstDayOfFirstWeek = new Date(januaryFirst.getTime() - (numOfdaysPastSinceFirstDayOfFirstWeek * 86400000));

    if (weekStart !== undefined && (typeof weekStart !== 'number' || weekStart % 1 !== 0 || weekStart < 0 || weekStart > 6)) {
      throw new Error('Wrong argument. Must be an integer between 0 and 6.');
    }
    weekStart = weekStart || 0;
    let tempWeekNo = Math.floor((((date.getTime() - januaryFirst.getTime()) / 86400000) + januaryFirst.getDay() - weekStart) / 7);

    if (firstDayOfFirstWeek.getFullYear() == date.getFullYear())
      tempWeekNo++;

    return tempWeekNo;
  }
  private generateYearsWeeks(year: number): WeekForSelection[] {
    let januaryFirst = new Date(year, 0, 1);
    let numOfdaysPastSinceFirstDayOfFirstWeek = januaryFirst.getDay();
    let firstDayOfFirstWeek = new Date(januaryFirst.getTime() - (numOfdaysPastSinceFirstDayOfFirstWeek * 86400000));

    let weekStartDateYear = this.getDate_Year(firstDayOfFirstWeek);
    let tempYearsWeeks: WeekForSelection[] = [];

    for (let countOfWeeks = 0; weekStartDateYear <= year; countOfWeeks++) {
      let tempWeek = new WeekForSelection();

      for (let count = 0; count <= 6; count++) {
        let lastDayOffLastWeek = null;

        if (tempYearsWeeks.length == 0)
          lastDayOffLastWeek = new Date(firstDayOfFirstWeek.getTime() - 86400000);
        else
          lastDayOffLastWeek = tempYearsWeeks[tempYearsWeeks.length - 1].weekEndDate;

        let firstDayOffWeek = new Date(lastDayOffLastWeek.getTime() + 86400000);
        let tempDay = new Date(firstDayOffWeek.getTime() + (86400000 * count));
        tempWeek.days.push(
          {
            day: this.getDate_Day(tempDay),
            month: this.getDate_Month(tempDay)
          }
        );

        if (count == 0) {
          tempWeek.weekStartDate = tempDay;
          tempWeek.week = this.getWeek(tempWeek.weekStartDate, this.getFirstDayOfWeek());
          tempWeek.year = this.getDate_Year(tempWeek.weekStartDate);
          tempWeek.month = this.getDate_Month(tempWeek.weekStartDate);
        }

        if (count == 6)
          tempWeek.weekEndDate = tempDay;
      }
      weekStartDateYear = this.getDate_Year(tempWeek.weekStartDate);

      if (tempWeek.year <= year)
        tempYearsWeeks.push(tempWeek)
    }
    return tempYearsWeeks;
  };
  private updateYearsWeeks() {
    this.yearsWeeks = this.generateYearsWeeks(this.year);
  };
  private getWeeksForMonth(yearsWeeks: WeekForSelection[], month: number, year: number): WeekForSelection[] {
    return yearsWeeks.filter(w => { return w.month == month && w.year == year });
  }
  private getWeekForSelection(yearsWeeks: WeekForSelection[], year: number, weekNo: number): WeekForSelection {
    let tempWeek = new WeekForSelection();
    for (let w of yearsWeeks) {
      if (w.year == year && w.week == weekNo) {
        tempWeek = w;
        break;
      }
    }
    return tempWeek;
  }
  private getWeeksForShow(month: number, year: number): WeekForSelection[] {
    let tempWeeks = this.getWeeksForMonth(this.yearsWeeks, month, year);

    let pastMonth = (month - 1) > 0 ? month - 1 : 12;
    let lasWeek_pastMonth = new WeekForSelection();
    let tempWeeks_pastMonth = [];

    if (pastMonth == 12) {
      tempWeeks_pastMonth = this.getWeeksForMonth(this.generateYearsWeeks(year - 1), pastMonth, year - 1);
    } else
      tempWeeks_pastMonth = this.getWeeksForMonth(this.yearsWeeks, pastMonth, year);

    if (tempWeeks_pastMonth.length > 0)
      lasWeek_pastMonth = tempWeeks_pastMonth[tempWeeks_pastMonth.length - 1]

    tempWeeks = [
      lasWeek_pastMonth,
      ...tempWeeks
    ];
    return tempWeeks;
  }

  ngOnInit() {

    // -> Comentado para evitar dupla emição do evento weekChange, utilizar o useActualWeek() para inicializar.
    // let date = new Date();
    // this.year = this.getDate_Year(date);
    // this.month = this.getDate_Month(date);
    // this.week = this.getWeek(date, this.getFirstDayOfWeek());

    // if (this.yearsWeeks.length == 0)
    //   this.updateYearsWeeks();

    // let tempWeek4Sel = this.getWeekForSelection(this.yearsWeeks, this.year, this.week);
    // this.weekStartDate = tempWeek4Sel.weekStartDate;
    // this.weekEndDate = tempWeek4Sel.weekEndDate;

    // this.yearChange.emit(this.year);
    // this.monthChange.emit(this.month);
    // this.weekStartDateChange.emit(this.weekStartDate);
    // this.weekEndDateChange.emit(this.weekEndDate);

    // this.weekChange.emit(this.week);

  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    for (const propName in changes) {
      // if (propName == 'week') {
      //   this.selectByBackEnd();
      // }
    }
  }

  showWeek(): string {
    if (this.week > 0)
      return this.numberPadding(this.week, 2)
    else
      return `--`
  }
  showYear(): string {
    if (this.year > 0)
      return this.numberPadding(this.year, 4)
    else
      return `----`
  }
  trigger() {
    if (!this.disabled && !this.showSelect) {
      this.showSelect = true;
      this.openSelect();
    }
  }
  closeSelect() {
    this.showSelect = false;
  }
  openSelect() {
    if (this.yearsWeeks.length == 0)
      this.updateYearsWeeks();
    this.weeksForSelection = [];
    this.weeksForSelection = this.getWeeksForShow(this.month, this.year);
    this.showSelect = true;
  }
  useActualWeek() {
    let date = new Date();
    this.popdown_select(this.getWeekForSelection(this.generateYearsWeeks(this.getDate_Year(date)), this.getDate_Year(date), this.getWeek(date, this.getFirstDayOfWeek())))
  }


  // popdown
  popdown_useActualWeek() {
    this.useActualWeek();
    this.showSelect = false;
  }
  popdown_updateMonth() {
    this.weeksForSelection = [];
    this.weeksForSelection = this.getWeeksForShow(this.month, this.year);
  }
  popdown_updateYear() {
    this.updateYearsWeeks();
    this.popdown_updateMonth();
  }
  popdown_monthBack() {
    if (this.month - 1 >= 1) {
      this.month--;
      this.popdown_updateMonth();
    } else {
      this.month = 12;
      this.year--;
      this.popdown_updateYear();
    }
  }
  popdown_monthAdvance() {
    if (this.month + 1 <= 12) {
      this.month++;
      this.popdown_updateMonth();
    } else {
      this.month = 1;
      this.year++;
      this.popdown_updateYear();
    }
  }
  popdown_select(week4Sel: WeekForSelection) {

    if (week4Sel.year != this.year) {
      this.year = week4Sel.year;
      this.updateYearsWeeks();
    }

    this.year = week4Sel.year;
    this.month = week4Sel.month;
    this.week = week4Sel.week;
    this.weekStartDate = week4Sel.weekStartDate;
    this.weekEndDate = week4Sel.weekEndDate;

    this.yearChange.emit(this.year);
    this.monthChange.emit(this.month);
    this.weekStartDateChange.emit(this.weekStartDate);
    this.weekEndDateChange.emit(this.weekEndDate);

    this.weekChange.emit(this.week);

    this.showSelect = false;
  }
  // popdown


}
