// import { ComponentPortal } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NbConnectedPosition, NbOverlay, NbOverlayRef } from '@nebular/theme';

export type OverlayPosition = 'up' | 'down' | 'left' | 'right';
export type OverlayModalPosition = 'top' | 'center' | 'left' | 'right' | 'bottom';

@Injectable({
    providedIn: 'root',
})
export class OverlayService {


    public overlays: Map<string, NbOverlayRef> = new Map();
    public overlaysIds: string[] = [];
    public systemOverlaysIds: string[] = [];
    public overlaysComponents: Map<string, ComponentRef<any>> = new Map();
    public onCloseCallbacks: Map<string, Function | null> = new Map();

    constructor(private overlay: NbOverlay, private injector: Injector) { }

    /**
     * Abre um overlay com posicionamento dinâmico baseado no elemento trigger.
     */
    openOverlay<T>(
        id: string,
        component: ComponentType<T>,
        triggerElement: HTMLElement,
        position: OverlayPosition = 'down', // Posição padrão
        inputs: { [key: string]: any } = {},
        outputs: { [key: string]: Function } = {},
        onClose: Function | null = null, // Callback opcional para fechar o overlay será passado a variavel onCloseData caso o overlay a possua
        closeOnBackdropClick: boolean = true, // Controle de clique no backdrop
        closeOnEscape: boolean = true, // Controle de fechamento com tecla ESC
        system: boolean = false
    ): NbOverlayRef {

        // Define o callback para o fechamento
        this.onCloseCallbacks.set(id, onClose);

        // Estratégia de posicionamento dinâmica
        const positionStrategy = this.getPositionStrategy(triggerElement, position);

        const overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'ic-backdrop',
            panelClass: 'ic-overlay-panel',
            scrollStrategy: this.overlay.scrollStrategies.block(), // Bloqueia o scroll
            positionStrategy: positionStrategy,
        });
        overlayRef.addPanelClass(id);

        // Adiciona o componente ao overlay
        const overlayPortal = new ComponentPortal(component);
        const overlayComponent = overlayRef.attach(overlayPortal);

        // Passa os inputs para o componente
        (overlayComponent.instance as any).id = id;
        (overlayComponent.instance as any).overlay = overlayRef;
        Object.keys(inputs).forEach((key) => {
            (overlayComponent.instance as any)[key] = inputs[key];
        });

        // Conecta os outputs
        Object.keys(outputs).forEach((key) => {
            if ((overlayComponent.instance as any)[key] instanceof EventEmitter) {
                ((overlayComponent.instance as any)[key] as EventEmitter<any>).subscribe(outputs[key]);
            }
        });

        // Fecha ao clicar no backdrop, se permitido
        if (closeOnBackdropClick) {
            overlayRef.backdropClick().subscribe(() => this.closeOverlay(id));
        }

        // Fecha ao pressionar ESC, se permitido
        if (closeOnEscape) {
            overlayRef.keydownEvents().subscribe((event) => {
                if (event.key === 'Escape' && this.isOverlayLastOpened(id) && !this.systemOverlaysIds.includes(id))
                    this.closeOverlay(id);
            });
        }

        this.overlaysComponents.set(id, overlayComponent)
        this.overlays.set(id, overlayRef);
        this.overlaysIds.push(id);

        if (system)
            this.systemOverlaysIds.push(id);

        return overlayRef;
    }

    /**
    * Abre um overlay com posicionamento dinâmico baseado no elemento trigger.
    */
    openOverlayModal<T>(
        id: string,
        component: ComponentType<T>,
        position: OverlayModalPosition = 'center', // Posição padrão
        inputs: { [key: string]: any } = {},
        outputs: { [key: string]: Function } = {},
        onClose: Function | null = null, // Callback opcional para fechar o overlay será passado a variavel onCloseData caso o overlay a possua
        closeOnBackdropClick: boolean = true, // Controle de clique no backdrop
        closeOnEscape: boolean = true, // Controle de fechamento com tecla ESC
        system: boolean = false
    ): NbOverlayRef {

        // Define o callback para o fechamento
        this.onCloseCallbacks.set(id, onClose);

        // Estratégia de posicionamento
        const positionStrategy = this.overlay.position().global();

        switch (position) {
            case 'top':
                positionStrategy.top('5%').centerHorizontally();
                break;
            case 'bottom':
                positionStrategy.bottom('5%').centerHorizontally();
                break;
            case 'left':
                positionStrategy.left('5%').centerVertically();
                break;
            case 'right':
                positionStrategy.right('5%').centerVertically();
                break;
            default:
                positionStrategy.centerHorizontally().centerVertically();
        }

        const overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'ic-backdrop',
            panelClass: 'ic-overlay-modal-panel',
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy: positionStrategy,
        });
        overlayRef.addPanelClass(id);

        const overlayPortal = new ComponentPortal(component);
        const overlayComponent = overlayRef.attach(overlayPortal);

        // Passa os inputs para o componente
        (overlayComponent.instance as any).id = id;
        (overlayComponent.instance as any).overlay = overlayRef;
        Object.keys(inputs).forEach((key) => {
            (overlayComponent.instance as any)[key] = inputs[key];
        });

        // Conecta os outputs
        Object.keys(outputs).forEach((key) => {
            if ((overlayComponent.instance as any)[key] instanceof EventEmitter) {
                ((overlayComponent.instance as any)[key] as EventEmitter<any>).subscribe(outputs[key]);
            }
        });

        // Fecha ao clicar no backdrop, se permitido
        if (closeOnBackdropClick) {
            overlayRef.backdropClick().subscribe(() => this.closeOverlay(id));
        }

        // Fecha ao pressionar ESC, se permitido
        if (closeOnEscape) {
            overlayRef.keydownEvents().subscribe((event) => {
                if (event.key === 'Escape' && this.isOverlayLastOpened(id) && !this.systemOverlaysIds.includes(id))
                    this.closeOverlay(id);
            });
        }

        this.overlaysComponents.set(id, overlayComponent)
        this.overlays.set(id, overlayRef);
        this.overlaysIds.push(id);

        if (system)
            this.systemOverlaysIds.push(id);

        return overlayRef;
    }

    /**
      * Fecha o overlay atual e chama o callback `onClose`.
      */
    closeOverlay(id: string) {
        const overlayRef = this.overlays.get(id);
        if (overlayRef) {
            overlayRef.dispose();
            this.overlays.delete(id);
            const onCloseCallback = this.onCloseCallbacks.get(id);
            if (onCloseCallback) {
                const overlayComponent = this.overlaysComponents.get(id);
                const onCloseData = overlayComponent.instance['onCloseData'] || {};
                onCloseCallback(onCloseData);
            }
            this.onCloseCallbacks.delete(id);
            this.overlaysIds = this.overlaysIds.filter((i) => i != id);
        }
    }
    /**
     * Use this function with caution!
     * This function cannot close system overlays
     */
    closeAllOverlays() {
        this.overlays.forEach((_, id) => {
            if (!this.systemOverlaysIds.includes(id))
                this.closeOverlay(id)
        });
    }
    isOverlayLastOpened(id: string) {
        return this.overlaysIds[this.overlaysIds.length - 1] == id;
    }

    /**
     * Define a estratégia de posicionamento com base no elemento trigger e na posição desejada.
     */
    private getPositionStrategy(triggerElement: HTMLElement, position: OverlayPosition) {
        const positions = {
            up: {
                originX: 'center',
                originY: 'top',
                overlayX: 'center',
                overlayY: 'bottom',
                offsetY: -8,
            } as NbConnectedPosition,
            down: {
                originX: 'center',
                originY: 'bottom',
                overlayX: 'center',
                overlayY: 'top',
                offsetY: 8,
            } as NbConnectedPosition,
            left: {
                originX: 'start',
                originY: 'center',
                overlayX: 'end',
                overlayY: 'center',
                offsetX: -8,
            } as NbConnectedPosition,
            right: {
                originX: 'end',
                originY: 'center',
                overlayX: 'start',
                overlayY: 'center',
                offsetX: 8,
            } as NbConnectedPosition,
        };

        const positionConfig = positions[position];

        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(triggerElement)
            .withFlexibleDimensions(true)
            .withPositions([
                positionConfig,
                {
                    originX: 'end',
                    originY: 'top',
                    overlayX: 'start',
                    overlayY: 'top',
                },
                {
                    originX: 'start',
                    originY: 'top',
                    overlayX: 'end',
                    overlayY: 'top',
                },
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'bottom',
                },
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'bottom',
                },
            ]);

        return positionStrategy;
    }
}
