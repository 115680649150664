import { Component, Inject, OnInit, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';

import { AuthService, SmartTableAuxService, UtilService } from 'app/@core';
import { AuthObject, AuthObjectClass, AuthObjectField, FirestoreService } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

declare var jQuery: any;

@Component({
  selector: 'app-create-auth-object-modal',
  templateUrl: './create-auth-object-modal.component.html',
  styleUrls: ['./create-auth-object-modal.component.scss']
})
export class CreateAuthObjectModalComponent implements OnInit {

  className = 'CreateAuthObjectModalComponent';

  public loader = true;


  newAuthObject = new AuthObject();

  localAuthObjectClasses: AuthObjectClass[] = [];
  source_AuthObjectClasses: LocalDataSource;

  localAuthObjectFields: AuthObjectField[] = [];
  source_AuthObjectFields: LocalDataSource;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public smartTableAuxService: SmartTableAuxService,
    public db: FirestoreService,
    private authService: AuthService,
    public router: Router,
    public uiFeedBackCtrl: UiFeedBackService,
    public utilCtrl: UtilService,
  ) {
    (function ($) {
      $(document).ready(function () {
        $('.mat-dialog-container').css("width", "600px");
      });
    })(jQuery);
  }


  ngOnInit() {
    this.loadModalData();
  }

  loadModalData() {
    this.loader = true;
    Promise.all(
      [
        this.loadAuthObjectClasses(),
        this.loadAuthObjectFields(),
      ]
    )
      .then(() => {
        this.loader = false
      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }


  loadAuthObjectClasses(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.localAuthObjectClasses = [];
      this.db.afs.collection(this.db.COLLECTIONS.sys.accessControl.authorizationStructure.authObjectClasses).ref.get({ source: 'server' })
        .then(q => {
          for (let d of q.docs) {
            this.localAuthObjectClasses.push(d.data() as AuthObjectClass);
          }
          this.source_AuthObjectClasses = new LocalDataSource(this.localAuthObjectClasses);
          resolve();
        })
        .catch(e => reject(e))
    });
  }
  loadAuthObjectFields(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.localAuthObjectFields = [];
      this.db.afs.collection(this.db.COLLECTIONS.sys.accessControl.authorizationStructure.authObjectFields).ref.get({ source: 'server' })
        .then(q => {
          for (let d of q.docs) {
            this.localAuthObjectFields.push(d.data() as AuthObjectField);
            this.source_AuthObjectFields = new LocalDataSource(this.localAuthObjectFields);
          }
          resolve();
        })
        .catch(e => reject(e))
    });
  }


  addField() {
    this.uiFeedBackCtrl.showTableSelect(this.smartTableAuxService.columns.sys.accessControl.authorizationStructure.authObjectFields, this.source_AuthObjectFields)
      .then(aof => {
        if (!aof)
          return;

        let tempAuthObjectField = aof as AuthObjectField;
        if (this.newAuthObject.fields.indexOf(tempAuthObjectField.id) == -1)
          this.newAuthObject.fields.push(tempAuthObjectField.id)
      })
  }
  removeFieldValueConfirmation(i) {
    var r = confirm(`Tem certeza que deseja deletar "${this.newAuthObject.fields[i]}"?\n-----------------`);
    if (r == true) {
      this.removeFieldValue(i);
    } else {
      alert("Ação Cancela!");
    }
  }
  removeFieldValue(i) {
    this.newAuthObject.fields.splice(i, 1);
  }

  save() {
    console.log(`S A V E`, this.newAuthObject)

    this.loader = true;

    this.authService.getUser()
      .then(user => {

        this.newAuthObject.createdBy = user.uName;

        this.db.afs
          .collection(this.db.COLLECTIONS.sys.accessControl.authorizationStructure.authObjects)
          .doc(this.newAuthObject.id)
          .set(Object.assign({}, this.newAuthObject))
          .then(() => {

            this.loader = false;
            this.dialog.closeAll();

          })
          .catch((e) => {
            this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
          })
      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }

}
