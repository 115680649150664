<div class="mode mode_labels" cdkDropList [cdkDropListData]="cardLabels" (cdkDropListDropped)="_labelsOnDrop($event)">
    <div *ngIf="_loader" class="loader">
        <p>Carregando...</p>
    </div>

    <!-- cdkDrag -->
    <div *ngFor="let label of cardLabels; trackBy: _trackByFn" [title]="label.name" class="pm-card-modal-label" cdkDrag
        [cdkDragDisabled]="!pmCardModal.isBoardActive">

        <span class="color" [style.background-color]="label.hex" [style.border-color]="label.hex"></span>

        <span class="name">
            {{label.name}}
        </span>

        <div class="ic-button-grp">
            <button nbButton status="danger" appearance="ghost" size="tiny" (click)="_remLabel(label.id)">
                <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
            </button>
        </div>
    </div>
    <!-- cdkDrag -->

    <!-- new label -->
    <div class="new-label">
        <span class="title">
            Adicionar
        </span>

        <div class="form">
            <ng-select id="newCardLabel" name="newCardLabel" required [disabled]="!pmCardModal.isBoardActive"
                [(ngModel)]="newCardLabel">
                <ng-option *ngFor="let boardLabel of boardLabels4Sel" [value]="boardLabel">
                    <span class="newCardLabel-ng-option-color" [style.background-color]="boardLabel.hex"
                        [style.border-color]="boardLabel.hex"
                        style="width: 10px; height: 10px;border-radius: 50%; margin-right: 5px;">
                    </span>

                    <span>
                        {{boardLabel.name}}
                    </span>
                </ng-option>
            </ng-select>

            <button [disabled]="!pmCardModal.isBoardActive || newCardLabel.id == ''" nbButton size="small"
                (click)="_addLabel()">
                <nb-icon icon="plus-circle-outline" pack="eva"></nb-icon>
            </button>
        </div>
    </div>
    <!-- new label -->
</div>