import { _valueStandardizer } from "app/@core/services/util/src/valueStandardizer";

import { DB_DataFieldType } from "../models";

export type DB_FilterConditionOperationFieldType = DB_DataFieldType | '*';

export class DB_FilterConditionOperation {
    id: string;
    fieldTypes: DB_FilterConditionOperationFieldType[];
    inputType: 'none' | 'single' | 'multi-select' | 'select-other-field';
    test: (data: any, fieldId: string, value?: any) => boolean;
}
export const DB_FILTER_CONDITION_OPERATIONS: DB_FilterConditionOperation[] = [
    // é
    {
        id: 'EQ',
        fieldTypes: ['boolean', 'string', 'number', 'timestamp'],
        inputType: 'single',
        test: (data: any, fieldId: string, value: any): boolean => {
            const dataValue = _valueStandardizer(data[fieldId]);
            const _value = _valueStandardizer(value);
            return dataValue == _value;
        }
    },
    // é

    // não é
    {
        id: 'NE',
        fieldTypes: ['boolean', 'string', 'number', 'timestamp'],
        inputType: 'single',
        test: (data: any, fieldId: string, value: any): boolean => {
            const dataValue = _valueStandardizer(data[fieldId]);
            const _value = _valueStandardizer(value);
            return dataValue != _value;
        }
    },
    // não é

    // é maior que
    {
        id: 'GT',
        fieldTypes: ['number', 'timestamp'],
        inputType: 'single',
        test: (data: any, fieldId: string, value: any): boolean => {
            const dataValue = _valueStandardizer(data[fieldId]);
            const _value = _valueStandardizer(value);
            if (isNaN(dataValue))
                return false;
            return dataValue > _value;
        }
    },
    // é maior que

    // é menor que
    {
        id: 'LT',
        fieldTypes: ['number', 'timestamp'],
        inputType: 'single',
        test: (data: any, fieldId: string, value: any): boolean => {
            const dataValue = _valueStandardizer(data[fieldId]);
            const _value = _valueStandardizer(value);
            if (isNaN(dataValue))
                return false;
            return dataValue < _value;
        }
    },
    // é menor que

    // é maior ou igual
    {
        id: 'GE',
        fieldTypes: ['number', 'timestamp'],
        inputType: 'single',
        test: (data: any, fieldId: string, value: any): boolean => {
            const dataValue = _valueStandardizer(data[fieldId]);
            const _value = _valueStandardizer(value);
            if (isNaN(dataValue))
                return false;
            return dataValue >= _value;
        }
    },
    // é maior ou igual

    // é menor ou igual
    {
        id: 'LE',
        fieldTypes: ['number', 'timestamp'],
        inputType: 'single',
        test: (data: any, fieldId: string, value: any): boolean => {
            const dataValue = _valueStandardizer(data[fieldId]);
            const _value = _valueStandardizer(value);
            if (isNaN(dataValue))
                return false;
            return dataValue <= _value;
        }
    },
    // é menor ou igual

    // está vazio
    {
        id: 'IS_NULL',
        fieldTypes: ['*'],
        inputType: 'none',
        test: (data: any, fieldId: string, value: any): boolean => {
            const dataValue = _valueStandardizer(data[fieldId]);
            return dataValue === null;
        }
    },
    // está vazio

    // não está vazio
    {
        id: 'IS_NOT_NULL',
        fieldTypes: ['*'],
        inputType: 'none',
        test: (data: any, fieldId: string, value: any): boolean => {
            const dataValue = _valueStandardizer(data[fieldId]);
            return dataValue != null;
        }
    },
    // não está vazio

    // é qualquer coisa
    {
        id: 'IS_ANYTHING',
        fieldTypes: ['*'],
        inputType: 'none',
        test: (data: any, fieldId: string, value: any): boolean => {
            const dataValue = _valueStandardizer(data[fieldId]);
            return dataValue != null;
        }
    },
    // é qualquer coisa

    // é o mesmo que
    {
        id: 'SAME_AS',
        fieldTypes: ['*'],
        inputType: 'select-other-field',
        test: (data: any, fieldId: string, value: any): boolean => {
            const dataValue = _valueStandardizer(data[fieldId]);
            const dataValue2 = _valueStandardizer(data[value]);
            return dataValue == dataValue2;
        }
    },
    // é o mesmo que

    // é diferente de
    {
        id: 'NOT_SAME_AS',
        fieldTypes: ['*'],
        inputType: 'select-other-field',
        test: (data: any, fieldId: string, value: any): boolean => {
            const dataValue = _valueStandardizer(data[fieldId]);
            const dataValue2 = _valueStandardizer(data[value]);
            return dataValue != dataValue2;
        }
    },
    // é diferente de
];