import firebase from "firebase/compat/app";

import { PM_Card } from "app/@firebase";

function _toDate(timeStamp: firebase.firestore.Timestamp): Date {
    if (!timeStamp) return null;

    const seconds = timeStamp.seconds ?? timeStamp['_seconds'];
    const nanoseconds = timeStamp.nanoseconds ?? timeStamp['_nanoseconds'];
    return seconds != null && nanoseconds != null
        ? new Date(seconds * 1000 + nanoseconds / 1e6)
        : null;
}

export const PM_CARDS_AGINGS = [
    // created
    {
        id: 'created-this-week',
        title: 'Criado esta semana',
        condition: (card: PM_Card): boolean => {
            const createdOn = _toDate(card.createdOn);
            if (!createdOn)
                return false;

            const startOfWeekTime = new Date(new Date().setDate(new Date().getDate() - new Date().getDay())).getTime();
            return createdOn.getTime() >= startOfWeekTime;
        }
    },
    {
        id: 'created-this-month',
        title: 'Criado este mês',
        condition: (card: PM_Card): boolean => {
            const createdOn = _toDate(card.createdOn);
            if (!createdOn)
                return false;

            const now = new Date();
            return createdOn.getFullYear() === now.getFullYear() && createdOn.getMonth() === now.getMonth();
        }
    },
    {
        id: 'created-this-quarter',
        title: 'Criado este trimestre',
        condition: (card: PM_Card): boolean => {
            const createdOn = _toDate(card.createdOn);
            if (!createdOn)
                return false;

            const month = new Date().getMonth();
            const startOfQuarterTime = new Date(new Date().getFullYear(), month - (month % 3), 1).getTime();
            return createdOn.getTime() >= startOfQuarterTime;
        }
    },
    {
        id: 'created-last-week',
        title: 'Criado na semana passada',
        condition: (card: PM_Card): boolean => {
            const createdOn = _toDate(card.createdOn);
            if (!createdOn)
                return false;

            const now = new Date();
            const startOfThisWeekTime = new Date(now.setDate(now.getDate() - now.getDay())).getTime(); // Início desta semana
            const startOfLastWeek = new Date(startOfThisWeekTime);
            startOfLastWeek.setDate(startOfLastWeek.getDate() - 7);// Início da semana passada
            const startOfLastWeekTime = startOfLastWeek.getTime();
            const endOfLastWeek = new Date(startOfThisWeekTime);
            endOfLastWeek.setDate(endOfLastWeek.getDate() - 1); // Fim da semana passada (domingo)
            const endOfLastWeekTime = endOfLastWeek.getTime();

            return (
                createdOn.getTime() >= startOfLastWeekTime &&
                createdOn.getTime() < endOfLastWeekTime
            );
        }
    },
    {
        id: 'created-last-month',
        title: 'Criado no mês passado',
        condition: (card: PM_Card): boolean => {
            const createdOn = _toDate(card.createdOn);
            if (!createdOn)
                return false;

            const now = new Date();
            const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
            return createdOn.getFullYear() === lastMonth.getFullYear() && createdOn.getMonth() === lastMonth.getMonth();
        }
    },
    {
        id: 'created-last-quarter',
        title: 'Criado no trimestre passado',
        condition: (card: PM_Card): boolean => {
            const createdOn = _toDate(card.createdOn);
            if (!createdOn)
                return false;

            const month = new Date().getMonth();
            const startOfThisQuarterTime = new Date(new Date().getFullYear(), month - (month % 3), 1).getTime();
            const startOfLastQuarter = new Date(startOfThisQuarterTime);
            startOfLastQuarter.setMonth(startOfLastQuarter.getMonth() - 3);
            const startOfLastQuarterTime = startOfLastQuarter.getTime();
            return (
                createdOn.getTime() >= startOfLastQuarterTime &&
                createdOn.getTime() < startOfThisQuarterTime
            );
        }
    },

    // updated
    {
        id: 'updated-last-week',
        title: 'Atualizado na última semana',
        condition: (card: PM_Card): boolean => {
            const updatedOn = _toDate(card.updatedOn);
            if (!updatedOn)
                return false;

            const startOfWeekTime = new Date(new Date().setDate(new Date().getDate() - new Date().getDay())).getTime();
            return updatedOn.getTime() >= startOfWeekTime;
        }
    },

    // due
    {
        id: 'due-today',
        title: 'Vencendo hoje',
        important: true,
        condition: (card: PM_Card): boolean => {
            const dueDate = _toDate(card.dueDate);
            if (!dueDate) return false;

            const today = new Date();
            const startOfTodayTime = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime();
            const endOfTodayTime = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).getTime();
            return (
                dueDate.getTime() >= startOfTodayTime &&
                dueDate.getTime() < endOfTodayTime
            );
        }
    },
    {
        id: 'due-this-week',
        title: 'Vencendo esta semana',
        important: true,
        condition: (card: PM_Card): boolean => {
            const dueDate = _toDate(card.dueDate);
            if (!dueDate) return false;

            const startOfWeekTime = new Date(new Date().setDate(new Date().getDate() - new Date().getDay())).getTime();
            const endOfWeekTime = new Date(new Date().setDate(new Date().getDate() + (6 - new Date().getDay()))).getTime();
            return dueDate.getTime() >= startOfWeekTime && dueDate.getTime() <= endOfWeekTime;
        }
    },
    {
        id: 'due-this-month',
        title: 'Vencendo este mês',
        important: true,
        condition: (card: PM_Card): boolean => {
            const dueDate = _toDate(card.dueDate);
            if (!dueDate) return false;

            const now = new Date();
            const currentMonth = now.getMonth();
            const currentYear = now.getFullYear();

            return (
                dueDate.getMonth() === currentMonth &&
                dueDate.getFullYear() === currentYear
            );
        }
    },
    {
        id: 'due-last-month',
        title: 'Vencendo no mês passado',
        important: true,
        condition: (card: PM_Card): boolean => {
            const dueDate = _toDate(card.dueDate);
            if (!dueDate) return false;

            const now = new Date();
            const lastMonth = now.getMonth() === 0 ? 11 : now.getMonth() - 1;
            const lastMonthYear = now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear();

            return (
                dueDate.getMonth() === lastMonth &&
                dueDate.getFullYear() === lastMonthYear
            );
        }
    },

    // in-progress
    {
        id: 'in-progress-over-month',
        title: 'Em andamento há mais de um mês',
        important: true,
        condition: (card: PM_Card): boolean => {
            if (card.done) return false;
            const createdOn = _toDate(card.createdOn);
            const startDate = _toDate(card.startDate);
            if (!createdOn && !startDate) return false;

            const internalStartTime = createdOn ? createdOn.getTime() : startDate.getTime();
            const oneMonthAgoTime = new Date(new Date().setMonth(new Date().getMonth() - 1)).getTime();
            return !card.done && internalStartTime <= oneMonthAgoTime;
        }
    }
];
