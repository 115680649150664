export function genFilterValue(value: any): string {
  let v = '';
  if (value != undefined) {
    value = value + '';
    v = value.replace(/<(?:.|\n)*?>/gm, '');
  }
  if (v == '')
    v = '(Vazias)';
  return v;
}
export function stringToColourHex(string: string): string {
  if (!string)
    return null;
  var hash = 0;
  for (var i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}
