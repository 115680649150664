import { ColorSelectComponent } from "./color-select";
import { IcUserComponent } from "./ic-user";
import { SvgInlineComponent } from "./svg-inline";
import { WeekInputComponent } from "./week-input";

export const SHARED_COMPONENTS_COMPONENTS = [
    ColorSelectComponent,
    WeekInputComponent,
    SvgInlineComponent,
    IcUserComponent
];

export const SHARED_COMPONENTS_MODULES = [
];