import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';
import { ArraysUtil } from './src/ArraysUtil';
import { Booleans } from './src/Booleans';
import { Converters } from './src/Converters';
import { DateUtil } from './src/DateUtil';
import { Formatters } from './src/Formatters';
import { Generators } from './src/Generators';
import { Gets } from './src/Gets';
import { regexValidators } from './src/regexValidators';
import { TimestampUtil } from './src/TimestampUtil';
import { _valueStandardizer } from './src/valueStandardizer';
import { WeekUtil } from './src/WeekUtil';

export const SECOND_IN_MS = 1000;
export const MINUTE_IN_MS = 60 * 1000;
export const HOUR_IN_MS = 60 * MINUTE_IN_MS;
export const DAY_IN_MS = 24 * HOUR_IN_MS;
export const WEEK_IN_MS = 7 * DAY_IN_MS;
export const MONTH_IN_MS = 30 * DAY_IN_MS; // Aprox to 30 days
export const YEAR_IN_MS = 365 * DAY_IN_MS; // Aprox to 365 days

@Injectable()
export class UtilService {

  public regexValidators: regexValidators = new regexValidators();
  autocomplete_off = "nope"

  public date: DateUtil;
  public timestamp: TimestampUtil;
  public week: WeekUtil;
  public arrays: ArraysUtil;
  public formatters: Formatters;
  public generators: Generators;
  public gets: Gets;
  public converters: Converters;
  public booleans: Booleans;

  constructor(
    @Inject(LOCALE_ID) private locale,
    @Inject(PLATFORM_ID) private platformId,
    private _sanitizer: DomSanitizer,
  ) {
    this.date = new DateUtil();
    this.timestamp = new TimestampUtil();
    this.week = new WeekUtil(this.locale);
    this.arrays = new ArraysUtil();
    this.formatters = new Formatters();
    this.generators = new Generators();
    this.gets = new Gets();
    this.converters = new Converters();
    this.booleans = new Booleans();
  }
  public _valueStandardizer = _valueStandardizer;
  public trustHtml(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }
  public trustStyle(v: string): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(v);
  }
  public compareFn(o1, o2): boolean {
    let result = false;
    if (o1 === null && o2 === null)
      result = true;
    else if (o1 != null && o2 != null) {
      if (o1 === o2)
        result = true;
      else if (o1.id != null && o2.id != null && o1.id === o2.id)
        result = true;
    }
    return result;
  }
}
