import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { NbAccordionModule, NbButtonModule, NbCardModule, NbIconModule, NbSpinnerModule, NbUserModule } from "@nebular/theme";
import { Ng2SmartTableModule } from "ng2-smart-table";

import { ActivitiesTabComponent } from "./activities-tab.component";
import { ThemeModule } from "app/@theme";

const BASE_MODULES = [
  CommonModule,
  Ng2SmartTableModule,
  MatDialogModule,
  NbCardModule,
  NbButtonModule,
  NbSpinnerModule,
  NbEvaIconsModule,
  NbUserModule,
  NbIconModule,
  NbAccordionModule,
  ThemeModule
];

const COMPONENTS = [
  ActivitiesTabComponent,
];

@NgModule({
  imports: [...BASE_MODULES],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class ActivitiesTabModule { }
