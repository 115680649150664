import { Component } from '@angular/core';

@Component({
  selector: 'ic-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Copyright &copy; {{getFullYear()}} | <a href="https://icontrol.tech/" target="_blank">iControl</a>
  `,
})
export class FooterComponent {
  getFullYear() { return new Date().getFullYear() }
}
