import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { COMPONENTS_COMPONENTS, COMPONENTS_MODULES } from './components';
import { DIRECTIVES } from './directives';
import { LAYOUTS_COMPONENTS } from './layouts';
import { NB_MODULES_EXPORTS, NB_MODULES_IMPORTS, NB_MODULES_PROVIDERS } from './NB_MODULES';
import { NG_MODULES_EXPORTS, NG_MODULES_IMPORTS } from './NG_MODULES';
import { PIPES } from './pipes';
import { NgSelectConfigService, SvgService, UI_FEED_BACK_COMPONENTS, UiFeedBackService } from './services';
import { SHARED_COMPONENTS_COMPONENTS, SHARED_COMPONENTS_MODULES } from './shared-components';
import { ThemeService } from './theme.service';
import { OverlayService } from './services/overlay.service';

const BASE_MODULES = [
  FormsModule,
  ReactiveFormsModule,
];

const PROVIDERS = [
  ThemeService,
  UiFeedBackService,
  SvgService,
  NgSelectConfigService,
  OverlayService
];

@NgModule({
  imports: [
    CommonModule,
    ...BASE_MODULES,
    ...NB_MODULES_IMPORTS,
    ...NG_MODULES_IMPORTS,
    // locals
    ...COMPONENTS_MODULES,
    ...SHARED_COMPONENTS_MODULES,
  ],
  declarations: [
    ...COMPONENTS_COMPONENTS,
    ...SHARED_COMPONENTS_COMPONENTS,
    ...LAYOUTS_COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
    ...UI_FEED_BACK_COMPONENTS,
  ],
  exports: [
    CommonModule,
    ...BASE_MODULES,
    ...NB_MODULES_EXPORTS,
    ...NG_MODULES_EXPORTS,
    ...LAYOUTS_COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
    ...SHARED_COMPONENTS_COMPONENTS,
    ...SHARED_COMPONENTS_MODULES,
  ],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NB_MODULES_PROVIDERS,
        ...PROVIDERS
      ],
    };
  }
}
