import { AfterViewInit, Component, OnChanges, OnInit, SimpleChange } from '@angular/core';

import { Log, PM_BoardActivity, PM_BoardLabel, PM_BoardRoute, PM_Card, PM_ToDo, User } from 'app/@firebase';

import { PM_Card_dataChangGen } from '../../../PM_Card_dataChangGen';
import { PmCardModal } from '../../../pm-card-modal';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'related-cards',
  templateUrl: './related-cards.component.html',
  styleUrls: ['./related-cards.component.scss']
})
export class PMCardModalRelatedCardsComponent implements OnInit, AfterViewInit, OnChanges {

  className = 'PMCardModalRelatedCardsComponent';

  showAddRelatedCardPopdown = false;

  _loader = false;

  _cards: PM_Card[] = [];
  _boardNamesIndex = {};
  _routesNamesIndex = {};

  _membersPerBoardMap: Record<string, User[]> = {};
  _routesPerBoardMap: Record<string, PM_BoardRoute[]> = {};
  _labelsPerBoardMap: Record<string, PM_BoardLabel[]> = {};
  _toDosPerBoardMap: Record<string, PM_ToDo[]> = {};

  _relatedCardsIds: string[] = [];
  _connectCardsIds: string[] = [];

  constructor(
    public pmCardModal: PmCardModal,
  ) { }

  ngOnInit() { }
  ngAfterViewInit() { }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }
  ngOnDestroy() { }

  // Rotinas de controle
  _trackByFn(index, item) {
    return index;
  }
  // Rotinas de controle

  // related-cards
  public load() {
    this._loader = true;

    let cardsIds = this.pmCardModal.card.relatedCardsIds;

    this._relatedCardsIds = [];
    this._connectCardsIds = [];
    this._boardNamesIndex = {};
    this._routesNamesIndex = {};

    this._membersPerBoardMap = {};
    this._routesPerBoardMap = {};
    this._labelsPerBoardMap = {};
    this._toDosPerBoardMap = {};

    this.pmCardModal.db.projectManagement.kanban.cards
      .getDataByIds(cardsIds)
      .then(cards => {

        this._cards = this.pmCardModal.utilCtrl.arrays.sort(cards, 'boardId', 'asc');

        const boardsIds = Array.from(new Set(cards.map((card) => card.boardId)));

        return this.pmCardModal.db.projectManagement.kanban.boards
          .getDataByIds(boardsIds)
          .then(boards => {

            const userIdSet = new Set<string>();
            boards.forEach((board) => { board.members.forEach((member) => { userIdSet.add(member); }); })
            const usersIds = Array.from(userIdSet);

            return this.pmCardModal.db.sys.users
              .getDataByWheres('uName', '==', usersIds)
              .then(users => {

                return this.pmCardModal.db.projectManagement.kanban.routes
                  .getDataByWheres('boardId', '==', boardsIds)
                  .then(routes => {
                    return this.pmCardModal.db.projectManagement.kanban.labels
                      .getDataByWheres('boardId', '==', boardsIds)
                      .then(labels => {

                        return this.pmCardModal.db.projectManagement.toDos
                          .getDataByWheres('boardId', '==', boardsIds)
                          .then(toDos => {

                            boards
                              .forEach((board) => {
                                this._boardNamesIndex[board.id] = board.name;
                                this._membersPerBoardMap[board.id] = board.members.map((member) => { return users.find((user) => { return user.uName == member; }) });
                                this._routesPerBoardMap[board.id] = routes.filter((r) => { return r.boardId == board.id; });
                                this._labelsPerBoardMap[board.id] = labels.filter((l) => { return l.boardId == board.id; });
                                this._toDosPerBoardMap[board.id] = toDos.filter((t) => { return t.boardId == board.id; });
                              });

                            this._cards
                              .forEach((card) => {

                                this._relatedCardsIds.push(card.id);

                                let card_relatedCardsIds = card.relatedCardsIds;
                                if (card_relatedCardsIds.indexOf(this.pmCardModal.card.id) != -1)
                                  this._connectCardsIds.push(card.id);

                                this._routesNamesIndex[card.id] = '';
                                const cardRoute = routes.find((r) => { return r.id == card.routeId; })
                                if (cardRoute)
                                  this._routesNamesIndex[card.id] = cardRoute.name;

                              });

                            this._loader = false;
                          })
                      })
                  })
              })
          })
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
      })
  }
  _showAddRelatedCardPopdown() {
    this.showAddRelatedCardPopdown = true;
    this.pmCardModal.__showPopdownInMain("add-related-card-popdown-trigger", "add-related-card-popdown", () => { this._hideAddRelatedCardPopdown(); });
  }
  _hideAddRelatedCardPopdown() {
    const _this = this;
    _this.showAddRelatedCardPopdown = false;
  }
  _addRelatedCard(card: PM_Card) {
    this._hideAddRelatedCardPopdown();
    this.pmCardModal.uiFeedBackCtrl.presentLoader('Salvando...')
      .then(() => {

        this.pmCardModal.card.relatedCardsIds.push(card.id);

        this.pmCardModal._updateCard(
          {
            relatedCardsIds: this.pmCardModal.db.fieldValue.arrayUnion(card.id) as any
          }
        )
          .then(() => {
            this.load();
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
          })
          .catch((e) => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
          })
      });
  }
  _remRelatedCard(card: PM_Card) {
    this.pmCardModal.uiFeedBackCtrl.presentLoader('Salvando...')
      .then(() => {

        this.pmCardModal.card.relatedCardsIds = this.pmCardModal.card.relatedCardsIds.filter(cardId => { return cardId != card.id });

        this.pmCardModal._updateCard(
          {
            relatedCardsIds: this.pmCardModal.db.fieldValue.arrayRemove(card.id) as any
          }
        )
          .then(() => {
            this.load();
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
          })
          .catch((e) => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.pmCardModal.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
          })
      });
  }
  _connectRelatedCard(card: PM_Card) {
    this.pmCardModal.uiFeedBackCtrl.presentLoader('Salvando...')
      .then(() => {

        let _card = new PM_Card(card);
        let _oldCard = new PM_Card(card);

        _card.relatedCardsIds.push(this.pmCardModal.card.id);

        // Transaction
        this.pmCardModal.db.runTransaction(transaction => {

          // Log
          let tempLog = new Log();

          tempLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
          tempLog.className = this.pmCardModal.className;
          tempLog.uName = this.pmCardModal.authService.localUser.uName;

          tempLog.type = "AUDIT";
          tempLog.category = "FC";

          tempLog.docPath = this.pmCardModal.localDocPath;
          tempLog.docId = _card.id;
          tempLog.changes = PM_Card_dataChangGen(_oldCard, _card, this.pmCardModal.boardMembers, this.pmCardModal.boardLabels, this.pmCardModal._toDoLists.cardToDoLists, this.pmCardModal.utilCtrl);
          // Log

          // Activity
          let tempActivity = new PM_BoardActivity();

          tempActivity.id = this.pmCardModal.db.afs.createId();
          tempActivity.uName = this.pmCardModal.authService.localUser.uName;
          tempActivity.cardId = _card.id;
          tempActivity.boardId = _card.boardId;

          tempActivity.type = "FC";

          tempActivity.changes = PM_Card_dataChangGen(_oldCard, _card, this.pmCardModal.boardMembers, this.pmCardModal.boardLabels, this.pmCardModal._toDoLists.cardToDoLists, this.pmCardModal.utilCtrl);
          // Activity

          // Card
          let updateData: Partial<PM_Card> = {};

          updateData.id = _card.id;
          updateData.relatedCardsIds = this.pmCardModal.db.fieldValue.arrayUnion(this.pmCardModal.card.id) as any;
          updateData.updatedBy = this.pmCardModal.authService.localUser.uName;
          updateData.updatedOn = this.pmCardModal.utilCtrl.timestamp.now();

          transaction.update(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.localDocPath).doc(_card.id),
            updateData
          );
          // Card

          // Log
          transaction.set(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
            Object.assign({}, tempLog)
          );
          // Log

          // Activity
          transaction.set(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
            Object.assign({}, tempActivity)
          );
          // Activity

          return Promise.resolve();
          // Transaction

        })
          .then(() => {
            this.load();
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
          })
          .catch((e) => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.pmCardModal.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
          })
      });
  }
  // related-cards

  openRelatedCard(card: PM_Card) {
    this.pmCardModal.uiFeedBackCtrl.presentLoader('Abrindo Cartão...')
      .then(() => {
        this.pmCardModal.__openCardOnNgDestroyFunction = () => {
          if (this.pmCardModal.board.id == this.pmCardModal.card.boardId) {
            // on the same board
            this.pmCardModal.uiFeedBackCtrl.dialog_opener(PmCardModal, {
              id: 'PmCardModalRef_' + new Date().getTime(),
              data: {
                card: JSON.parse(JSON.stringify(card)),
                board: JSON.parse(JSON.stringify(this.pmCardModal.board)),
                boardRoutes: JSON.parse(JSON.stringify(this.pmCardModal.boardRoutes)),
                boardLabels: JSON.parse(JSON.stringify(this.pmCardModal.boardLabels)),
                boardMembers: JSON.parse(JSON.stringify(this.pmCardModal.boardMembers)),
              }
            });
          } else {
            // on other board
            const navigationExtras: NavigationExtras = {
              queryParams: {
                'id': card.boardId,
                'cardIdToOpen': card.id
              }
            };
            this.pmCardModal.router.navigate(['/main/project-management/kanban/open-board'], navigationExtras);
          }
        };

        this.pmCardModal.safeClose()
          .then((closed) => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
            if (!closed)
              this.pmCardModal.__openCardOnNgDestroyFunction = null;
          })
      })
  }
}
