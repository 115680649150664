export const DIRECTORIES = {

  main: 'main',

  sys: {
    users_pictures: 'users_pictures',
    db: {
      reports: 'DB_Reports'
    },
  },

  serviceManagement: {
    documents: {
      docAttachments: 'SM_DocAttachments',
    },
  },

  projectManagement: {
    boards: {
      cardAttachments: 'PM_CardAttachments'
    }
  }
}
