import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { CustonAlertOptions } from '../../types';

@Component({
  selector: 'app-ui-feed-back-custon-alert-modal',
  templateUrl: 'ui-feed-back-custon-alert-modal.component.html',
  styleUrls: ['./../ui-feed-back-modals.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UiFeedBackCustonAlertModal {

  public TEXT_AREA_TYPE = "textarea";
  public SELECT_TYPE = "select";
  public CHECKBOX_TYPE = "checkbox";

  public id = this.generateRandom(30);
  public custonAlertOpts: CustonAlertOptions = new CustonAlertOptions();

  @ViewChild('CustonAlertModalContent', { read: ElementRef, static: false }) custonAlertModalContent: ElementRef;
  @ViewChild('form', { read: ElementRef, static: false }) form: FormGroup;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: CustonAlertOptions,
    private mdDialogRef: MatDialogRef<UiFeedBackCustonAlertModal>,
  ) {
    this.custonAlertOpts = data;

    this._setMessageHTML(this.custonAlertOpts.message);
  }

  close() {
    this.mdDialogRef.close();
    if (this.data.closeButtonHandler)
      this.data.closeButtonHandler(null)
  }

  @HostListener('window:keydown.esc', ['$event'])
  onEsc() {
    this.close();
  }
  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    // if (this.popdownContent && !this.popdownContent.nativeElement.contains(event.target)) {
    //   this.close();
    // }
  }
  @HostListener('window:keydown.enter', ['$event'])
  onEnter() {
    let btnTexts = ['OK', 'SIM'];
    for (let btn of this.custonAlertOpts.buttons) {
      if (btnTexts.indexOf(btn.text.toUpperCase()) != -1) {
        if (!btn.needFormValid || !this.form || this.form.valid) {
          let formValue = undefined;
          if (this.form)
            formValue = this.form.value;

          this.close();
          btn.handler(formValue);
          break;
        }
      }
    }
  }
  private generateRandom(length: number): string {
    var s = '';
    var r = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
      s += r.charAt(Math.floor(Math.random() * r.length));
    }
    return s;
  }
  private _setMessageHTML(message: string) {
    const retryDelayMs = 50;
    let messageElemendId = `alert-message-${this.id}`;
    const element = document.getElementById(messageElemendId);
    if (element)
      document.getElementById(messageElemendId).innerHTML = message;
    else
      setTimeout(() => {
        this._setMessageHTML(message);
      }, retryDelayMs);
  }
}
