import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { AuthService } from 'app/@core/services/auth.service';
import { MagicTableSettings } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';
import { environment } from 'environments/environment';

import { MagicTableSettingsModalComponent } from './magic-table-settings-modal/magic-table-settings-modal.component';
import { __saveAsExcel } from './magic-table-subroutines/magic-table_excel';
import { _genDispFilterValue, _initFilterIndexes, _updateFilterStatus, _updateFiltredRows, filterPopdown_cleanFilter, filterPopdown_filter, filterPopdown_toogleAll, filterPopdown_updateSearchResult, filterPopdown_updateSelectAll } from './magic-table-subroutines/magic-table_filter';
import { _closeFilterPopDown, _openFilterPopDown, _toogleSort } from './magic-table-subroutines/magic-table_filterPopDown';
import { _cellDataMaker, _normalizeString } from './magic-table-subroutines/magic-table.util';
import { EMPTY_ARRAY_DATA_VALUE } from './magic-table.consts';
import { FilterPopdownData, MagicControlData, MagicControlOptionData, MagicTable, MagicTableColumn, MagicTableRowData, OrderDirection, UiFilterIndex } from './magic-table.types';

@Component({
  selector: 'magic-table',
  templateUrl: './magic-table.component.html',
  styleUrls: ['./magic-table.component.scss']
})
export class MagicTableComponent implements OnInit, OnChanges {

  @Input() id: string = '';
  @Input() title: string = '';
  @Input() loader = false;

  @Input() showHeader = true;
  @Input() canConfig = true;

  @Input() data: any[] = [];
  @Input() itensPerPage: number = 10;
  @Input() columns: MagicTableColumn[] = [];

  className = `MagicTableComponent`;
  table: MagicTable = new MagicTable();

  filterActiveIndex: boolean[] = [];
  orderByIndex: OrderDirection[] = [];
  filterIndexes: UiFilterIndex[] = [];
  filtredRows: MagicTableRowData[] = [];

  // ui
  cleanedColumns: MagicTableColumn[] = [];
  rowsForDisplay: MagicTableRowData[] = [];
  actualPage: number = 1;
  lastPage: number = 1;
  input_page: number = 1;
  // ui

  groups4sel: MagicControlOptionData[] = [];
  selGroups: { title: string, filed: string }[] = [];
  groupControls: MagicControlData[] = [];

  tableSettings: MagicTableSettings = new MagicTableSettings();

  // Filter Popdown
  showFilterPopdown = -1;
  @ViewChild('FilterPopdownContentERef', { read: ElementRef, static: false }) filterPopdownContentERef: ElementRef;
  _filterPopdownData: FilterPopdownData = new FilterPopdownData();
  // Filter Popdown

  constructor(
    private _sanitizer: DomSanitizer,
    public authService: AuthService,
    public uiFeedBackCtrl: UiFeedBackService
  ) { }
  @HostListener('window:keydown.esc', ['$event'])
  on_keydown_esc() {
    this.closeFilterPopdown();
  }
  @HostListener('window:keydown.l', ['$event'])
  on_keydown_l() {
    if (this.showFilterPopdown != -1 && this.filterActiveIndex[this.showFilterPopdown])
      this.cleanFilter(this.showFilterPopdown);
  }
  @HostListener('window:keydown.enter', ['$event'])
  on_keydown_enter() {
    if (this.showFilterPopdown != -1 && this._filterPopdownData.searchResult.length > 0)
      this.filter(this.showFilterPopdown);
  }
  @HostListener('document:mousedown', ['$event'])
  on_mousedown(event: MouseEvent): void {
    if (this.filterPopdownContentERef && !this.filterPopdownContentERef.nativeElement.contains(event.target)) {
      this.closeFilterPopdown();
    }
  }
  _trackByFn(index, item) {
    return index;
  }
  trustHtml(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName == 'data') {
        setTimeout(() => {
          this.ngOnInit();
        }, 250);
      }
    }
  }
  ngOnInit() {
    this.cleanCols();
    this.initTableSettings()
      .then(() => {
        this.initTableData();
      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }

  private _logger(message?: any, ...optionalParams: any[]) {
    if (!environment.production)
      console.log(message, ...optionalParams);
  }
  // ---------------------- GLOBAL
  /**
   * Inicia a tabela apartir do vetor de dados fornecido
   */
  private initTableData() {
    this.actualPage = 1;
    this.initTableCols();
    this.initTableRows();
    this.initFilterIndexes();
    this._logger(`MagicTable: ${this.id} ->`, " Table", this.table, " FilterIndexes", this.filterIndexes);
    _updateFiltredRows(this);
    this._updateRowsForDisplay();
  }
  private cleanCols() {
    let tempCols: MagicTableColumn[] = [];
    for (let col_index = 0; col_index < this.columns.length; col_index++)
      tempCols.push(new MagicTableColumn(this.columns[col_index]));

    this.cleanedColumns = tempCols;
  }
  /**
   * Inicia as colunas
   */
  private initTableCols() {
    let tempCols: MagicTableColumn[] = [];
    let tempGroups4sel = [];
    for (let colTitle of this.tableSettings.selectedColumns) {
      let tempCol = this.cleanedColumns.filter(c => { return c.title == colTitle })[0];
      if (tempCol) {
        tempCols.push(tempCol);

        if (tempCol.canGroup)
          tempGroups4sel.push(
            { value: tempCol.fieldName, title: tempCol.title }
          )
      }
      this.table.cols = tempCols;
      this.groups4sel = tempGroups4sel;
    }
  }
  /**
   * Inicia as linhas sem levar em consideração os filtros
   */
  private initTableRows() {
    let tempRows: MagicTableRowData[] = [];
    let pageN = 1;
    let lastPageItens = 0;
    for (let obj of this.data) {
      // row maker
      let row = new MagicTableRowData();
      row.obj = obj;
      for (let col_index = 0; col_index < this.table.cols.length; col_index++) {
        const col = this.table.cols[col_index];

        // Value Prepare
        if (col.valuePrepareFunction)
          row.obj[col.fieldName] = col.valuePrepareFunction(row.obj[col.fieldName], row.obj);

        let fieldData = row.obj[col.fieldName];

        // row.data
        if (Array.isArray(fieldData) && fieldData.length == 0)
          row.data.push(EMPTY_ARRAY_DATA_VALUE);
        else
          row.data.push(fieldData);

        // row.cells
        row.cells.push(_cellDataMaker(col, fieldData));

        // row.hrefs
        let href = undefined;
        if (col.hrefPrepareFunction)
          href = col.hrefPrepareFunction(fieldData, obj) + "";
        row.hrefs.push(href);
      }
      // row maker

      // page control
      if (lastPageItens < this.itensPerPage) {
        row.page = pageN;
        tempRows.push(row);
        lastPageItens++;
      } else {
        pageN++;
        row.page = pageN;
        tempRows.push(row);
        lastPageItens = 1;
      }
      // page control
    }
    this.lastPage = Math.ceil(tempRows.length / this.itensPerPage);
    this.table.rows = tempRows;
  }
  /**
   * Atualiza as linhas que são mostradas no HTML de acordo com a pagina selecionada e apartir das linhas filtradas
   */
  _updateRowsForDisplay() {
    let tempRows: MagicTableRowData[] = [];
    for (let r of this.filtredRows)
      if (r.page == this.actualPage)
        tempRows.push(r)
    this.rowsForDisplay = tempRows;
  }
  // ---------------------- GLOBAL

  // Settings
  private initTableSettings(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.authService.get_parameter(`table_${this.id}_settings`)
        .then(tableSettingsJson => {
          let data = null;
          if (tableSettingsJson)
            data = JSON.parse(tableSettingsJson as string);

          if (data) {
            this.tableSettings = data;
          } else {
            let tempTableSettings = new MagicTableSettings();
            for (let col of this.cleanedColumns) {
              if (col.show)
                tempTableSettings.selectedColumns.push(col.title);
            }
            this.tableSettings = tempTableSettings;
          }
          resolve();
        })
        .catch(e => reject(e))
    });
  }
  changeSettings() {
    if (this.id == '') {
      console.error('Magic Table does not Have ID to save settings');
      return;
    }
    this.uiFeedBackCtrl.presentLoader()
      .then(() => {
        let data = JSON.stringify(this.tableSettings);
        this.authService.set_parameter(`table_${this.id}_settings`, data)
          .then(() => {
            this.uiFeedBackCtrl.dismissLoader();
          })
          .catch((e) => {
            this.uiFeedBackCtrl.presentErrorAlert(``, this.className, this.authService.localUser.uName, 'Erro', e);
          })
      })
  }
  showSettings() {
    let tempId = 'MagicTableSettingsModalComponent_' + new Date().getTime();
    const dialogRef = this.uiFeedBackCtrl.dialog_opener(MagicTableSettingsModalComponent, {
      id: tempId,
      data: {
        columns: this.cleanedColumns,
        tableSettings: this.tableSettings
      }
    });
    dialogRef.afterClosed()
      .subscribe(data => {
        if (data) {
          this.tableSettings = data;
          this.changeSettings();
          this.initTableData();
        }
      });
  }
  // Settings

  // ---------------------- PAGE CONTROLS
  pageDown() {
    this.actualPage--;
    this.setPage(this.actualPage);
  }
  pageUp() {
    this.actualPage++;
    this.setPage(this.actualPage);
  }
  setPage(page: number) {
    this.actualPage = page;
    this.input_page = this.actualPage;
    this._updateRowsForDisplay();
  }
  update_input_page() {
    if (this.input_page && this.input_page > 0 && this.input_page <= this.lastPage)
      this.setPage(this.input_page)
    else
      this.input_page = this.actualPage;
  }
  // ---------------------- PAGE CONTROLS

  // ---------------------- Group Controls
  addGroupControl() {
    this.groupControls.push({
      title: 'Agrupar por:',
      action: 'group',
      value: ''
    })
  }
  controlValueChange(control_i: number, val: any) {
    console.log(control_i, val)
    if (val == '')
      this.groupControls.splice(control_i, 1)
  }
  // ---------------------- Group Controls

  // Filter PopDown
  openFilterPopdown(col_index: number) { _openFilterPopDown(this, col_index) }
  closeFilterPopdown() { _closeFilterPopDown(this) }
  toogleSort(col_index: number, ord: "asc" | "desc") { _toogleSort(this, col_index, ord) }
  // ---------------------- FILTERS
  private initFilterIndexes() { _initFilterIndexes(this) }
  filter(col_index: number) { filterPopdown_filter(this, col_index) }
  cleanFilter(col_index: number) { filterPopdown_cleanFilter(this, col_index) }
  updateSearchResult() { filterPopdown_updateSearchResult(this._filterPopdownData) }
  search() { filterPopdown_updateSearchResult(this._filterPopdownData); }
  toogleAll() { filterPopdown_toogleAll(this._filterPopdownData); }
  updateSelectAll() { filterPopdown_updateSelectAll(this._filterPopdownData); }
  // ---------------------- FILTERS
  // Filter PopDown

  // -- Excel
  public saveAsExcel() { __saveAsExcel(this); }
  // -- Excel

}
