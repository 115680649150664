export type ReportingPreferencesModeType = "pref-list" | "pref-selected" | "create-pref" | "edit-pref";
export type ReportingOrderDirection = "asc" | "desc";
export interface ReportingSortField {
    fieldName: string;
    direction: ReportingOrderDirection;
}
export class ReportingUiFilterIndexData {
    show: boolean; value: any; dispValue: string
}
export type ReportingUiFilterIndex = ReportingUiFilterIndexData[];
export class ReportingUiFilterMode {
    id: string; array: boolean; selectValues: boolean; selectSingle: boolean; title: string;
}
export class ReportingUiColumnPopdownData {
    searchText: string;
    filterIndex: ReportingUiFilterIndex = [];
    searchResult: ReportingUiFilterIndex = [];
    selectAll: boolean;
    AddCurSelTofilter: boolean;
    public constructor(init?: Partial<ReportingUiColumnPopdownData>) {
        this.searchText = '';
        this.filterIndex = [];
        this.searchResult = [];
        this.selectAll = false;
        this.AddCurSelTofilter = false;
        if (init)
            Object.assign(this, init);
    }
}

// export type FilterTest =
//     /** Verifica se o campo contém qualquer um dos valores especificados */
//     | 'array-contains-any'

//     /** Verifica se o campo está presente em uma lista de valores */
//     | 'in'

//     /** Verifica se o campo contém um valor especificado em um array */
//     | 'array-contains'

//     /** Verifica se o campo é igual a um valor específico */
//     | '==';
export type ReportColumnIcon = 'funnel-outline'
    | 'people-outline'
    | 'person-outline'
    | 'person-outline'
    | 'pricetags-outline'
    | 'folder-outline'
    | 'file-text-outline'
    | 'text-outline';


export class ReportColumn {
    field: string;
    title: string;
    icon: ReportColumnIcon;
    show?: boolean;
    isArray?: boolean;
    filterData: {
        // todo
        /** Default value for the filter */
        defaultValue?: any;
        // todo
        /** Predefined selection options for the filter */
        selectionOptions?: { value: any; label: string }[];
        /** Prepare a new value for show at the filter menu */
        displayValuePrepareFunction?: (field?: any, object?: any, aditionalData?: {}) => string | number;
    };
    /** Prepare Data Localy - Use this will also filter data localy */
    dataPrepareFunction?: (field?: any, object?: any, aditionalData?: {}) => string | number;
    public constructor(init?: Partial<ReportColumn>) {
        this.field = "";
        this.title = "";
        this.icon = 'funnel-outline';
        this.filterData = {
            displayValuePrepareFunction: (field?: any, object?: any, aditionalData?: {}) => field,
        };
        if (init)
            Object.assign(this, init);
    }
}