<mat-dialog-content #popdownContent>
  <nb-card style="width: 600px;">
    <nb-card-header>
      Configurações da tabela
      <button class="close" mat-button (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
      </button>
    </nb-card-header>
    <nb-card-body>
      <div class="container">

        <div class="row">
          <div class="col-5">
            <p>Disponiveis</p>
            <div class="col-options-wrap">
              <button *ngFor="let c of availableColumns; let i=index" (click)="select_availableColumn(i,c)"
                [ngClass]="{'selected': c==seleted_availableColumn}">
                {{c}}
              </button>
            </div>
          </div>
          <div class="col-1 ctrl-btn-wrap">
            <button nbButton [disabled]="seleted_availableColumn_i==-1" (click)="makeColumSelected()">
              <nb-icon icon="arrow-forward-outline" pack="eva"></nb-icon>
            </button>
            <button nbButton [disabled]="seleted_selectedColumn_i==-1" (click)="makeColumAvailable()">
              <nb-icon icon="arrow-back-outline" pack="eva"></nb-icon>
            </button>
          </div>


          <div class="col-5">
            <p>Selecionado</p>
            <div class="col-options-wrap">
              <button *ngFor="let c of selectedColumns;let i=index" (click)="select_selectedColumn(i,c)"
                [ngClass]="{'selected': c==seleted_selectedColumn}">
                {{c}}
              </button>
            </div>
          </div>

          <div class="col-1 ctrl-btn-wrap">
            <button nbButton [disabled]="seleted_selectedColumn_i==-1||seleted_selectedColumn_i==0"
              (click)="selectedColumnUp()">
              <nb-icon icon="arrow-upward-outline" pack="eva"></nb-icon>
            </button>
            <button nbButton
              [disabled]="seleted_selectedColumn_i==-1||seleted_selectedColumn_i==selectedColumns.length-1"
              (click)="selectedColumnDown()">
              <nb-icon icon="arrow-downward-outline" pack="eva"></nb-icon>
            </button>
          </div>
        </div>
      </div>

      <button class="reset-defaults-btn" (click)="resetDefaults()">Restaurar padrões</button>

    </nb-card-body>
    <nb-card-footer>
      <nb-actions size="medium">

        <nb-action>
          <button nbButton status="danger" size="tiny" (click)="closeDialog()">
            <nb-icon icon="close-outline" pack="eva"></nb-icon>
            Cancelar
          </button>
        </nb-action>

        <nb-action>
          <button nbButton status="primary" size="tiny" (click)="save()">
            <nb-icon icon="save-outline" pack="eva"></nb-icon>
            Salvar
          </button>
        </nb-action>

      </nb-actions>
    </nb-card-footer>
  </nb-card>
</mat-dialog-content>