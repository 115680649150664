import { Converters, TimestampUtil } from "app/@core";
import { PM_Board } from "app/@firebase";

const converters = new Converters();
const timestamp = new TimestampUtil();
export const boards4Sel_columns = {
    name: {
        title: 'Titulo',
        type: 'text',
        width: '300px'
    },
    members: {
        title: 'Membros',
        type: 'text',
        width: '250px'
    },

    createdBy: {
        title: 'Criado Por',
        type: 'text',
        width: '50px'
    },
    createdOn_str: {
        title: 'Criado em',
        type: 'text',
        width: '50px'
    },
    updatedBy: {
        title: 'Atualizado Por',
        type: 'text',
        width: '50px'
    },
    updatedOn_str: {
        title: 'Atualizado em',
        type: 'text',
        width: '50px'
    },
};
export function boards4SelDataSourceMaker(boards: PM_Board[]): any[] {
    let tempVet = []
    for (let board of boards) {
        let tempObj = board;

        tempObj['createdOn_str'] = timestamp.toLocalDate(board.createdOn);
        tempObj['updatedOn_str'] = timestamp.toLocalDate(board.updatedOn);
        tempVet.push(tempObj)
    }
    return tempVet;
}
