import { _valueStandardizer } from "app/@core/services/util/src/valueStandardizer";

import { OrderDirection } from "../magic-table.types";

export function magicTableOrderByCore(a: any, b: any, direction: OrderDirection): number {
    let aValue = _valueStandardizer(a);
    let bValue = _valueStandardizer(b);
    if (Array.isArray(aValue)) {
        aValue = aValue.sort((a, b) => magicTableOrderByCore(a, b, direction));
        aValue = aValue[0];
    }
    if (Array.isArray(bValue)) {
        bValue = bValue.sort((a, b) => magicTableOrderByCore(a, b, direction));
        bValue = bValue[0];
    }
    if (aValue < bValue) return direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return direction === 'asc' ? 1 : -1;
    return 0;
};