import { UtilService } from "app/@core";
import { PM_ToDo, PM_ToDoList } from "app/@firebase";

// ToDo
export function _encodeToDoToString(utilCtrl: UtilService, toDo: PM_ToDo): string {
    const additionalData = [
        toDo.startDate ? utilCtrl.timestamp.getDateTimeString(toDo.startDate) : '',
        toDo.dueDate ? utilCtrl.timestamp.getDateTimeString(toDo.dueDate) : '',
        toDo.done ? 'true' : 'false',
        toDo.doneAt ? utilCtrl.timestamp.getDateTimeString(toDo.doneAt) : '',
        toDo.assignedTo || '',
    ].join(';');

    return `${toDo.title}[${additionalData}]`;
}
export function _decodeToDoFromString(utilCtrl: UtilService, toDoData: string): PM_ToDo {
    const newToDo = new PM_ToDo();
    newToDo.id = utilCtrl.generators.generateRandom(30);

    // Dividindo o título e os dados adicionais
    const openBracketIndex = toDoData.indexOf('[');
    const closeBracketIndex = toDoData.indexOf(']');

    if (openBracketIndex !== -1 && closeBracketIndex !== -1) {
        // Existe um título e dados adicionais
        newToDo.title = toDoData.substring(0, openBracketIndex).trim();
        const additionalData = toDoData.substring(openBracketIndex + 1, closeBracketIndex).split(';');

        // Start Date
        if (additionalData[0]) {
            const tempStartDate = utilCtrl.date.getDateTimeObj(additionalData[0]);
            const startDateData = utilCtrl.gets.getDateAndClosestTime(tempStartDate);
            newToDo.startDate = utilCtrl.timestamp.fromDate(startDateData.closestDateTime);
            newToDo.startDateString = startDateData.formattedDate;
            newToDo.startDateHourString = startDateData.closestTime;
        }

        // DueDate
        if (additionalData[1]) {
            const tempDueDate = utilCtrl.date.getDateTimeObj(additionalData[1]);
            const dueDateData = utilCtrl.gets.getDateAndClosestTime(tempDueDate);
            newToDo.dueDate = utilCtrl.timestamp.fromDate(dueDateData.closestDateTime);
            newToDo.dueDateString = dueDateData.formattedDate;
            newToDo.dueDateHourString = dueDateData.closestTime;
        }

        // done
        if (additionalData[2])
            newToDo.done = additionalData[2] === 'true';

        // DoneAt
        if (additionalData[3]) {
            const tempDoneAtDate = utilCtrl.date.getDateTimeObj(additionalData[3]);
            newToDo.doneAt = utilCtrl.timestamp.fromDate(tempDoneAtDate);
            const doneAtDateTimeStr = utilCtrl.timestamp.getDateTimeString(newToDo.doneAt);
            newToDo.doneAtString = doneAtDateTimeStr.split('T')[0];
            newToDo.doneAtHourString = doneAtDateTimeStr.split('T')[1];
        }

        // assignedTo
        if (additionalData[4]) newToDo.assignedTo = additionalData[4];
    } else {
        // Caso só tenha o título
        newToDo.title = toDoData.trim();
    }

    return newToDo;
}
export function _encodeToDosToString(utilCtrl: UtilService, toDos: PM_ToDo[]): string {
    const toDosStr = toDos
        .map((toDo) => _encodeToDoToString(utilCtrl, toDo!))
        .join('\r\n');
    return toDosStr;
}
export function _decodeToDosFromString(utilCtrl: UtilService, data: string): PM_ToDo[] {
    const toDos: PM_ToDo[] = [];
    data = data.replace(/\\n/g, '\n'); // Transforma quebras de linha literais (como \\n) em quebras de linha reais (\n)
    data = data.replace(/\r\n/g, '\n'); // Normaliza quebras de linha para \n
    const lines = data.split('\n').filter((line) => line.trim().length > 0);
    lines.forEach((toDoData) => {
        const newToDo = _decodeToDoFromString(utilCtrl, toDoData);
        toDos.push(newToDo);
    });
    return toDos;
}
// ToDo

// ToDoList
export function _encodeMultipleToDoListsToString(utilCtrl: UtilService, toDoLists: PM_ToDoList[], toDos: PM_ToDo[]): string {
    return toDoLists.map((toDoList) => {
        // Codificar o título da lista
        const listString = `#[${toDoList.title}]#\r\n`;

        // Codificar os to-dosks
        const listToDos = toDoList.toDosIds
            .map((toDoId) => toDos.find((todo) => todo.id == toDoId))
            .filter((toDo) => toDo !== undefined);
        const toDoStrings = _encodeToDosToString(utilCtrl, listToDos);

        return listString + toDoStrings;
    }).join('\r\n\r\n'); // Separar listas com uma linha em branco
}
export function _decodeMultipleToDoListsFromString(utilCtrl: UtilService, data: string): { toDoLists: PM_ToDoList[], toDos: PM_ToDo[] } {
    const toDoLists: PM_ToDoList[] = [];
    const toDos: PM_ToDo[] = [];

    data = data.replace(/\\n/g, '\n'); // Transforma quebras de linha literais (como \\n) em quebras de linha reais (\n)
    data = data.replace(/\r\n/g, '\n'); // Normaliza quebras de linha para \n

    const listDataArray = data.trim().split('\n\n');

    for (const listData of listDataArray) {
        const newToDoList = new PM_ToDoList();
        newToDoList.id = utilCtrl.generators.generateRandom(30);
        let newToDos = [];

        // Encontrar o índice do início e fim do título da lista
        const startIndex = listData.indexOf('#[');
        const endIndex = listData.indexOf(']#');

        if (startIndex !== -1 && endIndex !== -1) {
            // Extrair o título da lista
            const title = listData.substring(startIndex + 2, endIndex).trim();
            newToDoList.title = title;

            // Extrair os dados dos to-dos após o título da lista
            const toDoData = listData.substring(endIndex + 2).trim();

            // Decodificar os to-dos
            if (toDoData.length > 0)
                newToDos = toDoData.split('\n').map((line) => _decodeToDoFromString(utilCtrl, line));

            newToDos.forEach((toDo) => {
                toDo.listId = newToDoList.id;
            });
            newToDoList.toDosIds = newToDos.map((toDo) => toDo.id);
        } else {
            throw new Error('Formato do título da Lista de ToDos inválido!');
        }

        toDoLists.push(newToDoList);
        toDos.push(...newToDos);
    }

    return { toDoLists: toDoLists, toDos: toDos };
}
// ToDoList
