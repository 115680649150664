export class Generators {
  // constructor() { }
  generateWordsVet(text: string): string[] {
    let tempKeyWords: string[] = [];
    let tempCleanText = text.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    let tempWordsVect = tempCleanText.split(' ');
    for (let word of tempWordsVect) {
      if (tempKeyWords.indexOf(word.toLowerCase()) == -1) {
        if (word.length > 2) {
          tempKeyWords.push(word.toLowerCase())
        }
      }
    }
    return tempKeyWords;
  }
  generateRandom(length: number): string {
    var s = '';
    var r = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
      s += r.charAt(Math.floor(Math.random() * r.length));
    }
    return s;
  }
}
