import { _normalizeString } from "./magic-table-subroutines/magic-table.util";

export type OrderDirection = "asc" | "desc";
export interface SortField {
  fieldName: string;
  direction: OrderDirection;
}
export class MagicTable {
  cols: MagicTableColumn[] = [];
  rows: MagicTableRowData[] = [];
}
export class MagicTableColumn {
  title: string;
  fieldName?: string;
  type?: "text" | "monetary" | "html" | "href" | "timestamp" | "boolean" | "progress-bar";
  width?: string = "auto";
  show?: boolean;
  canFilter?: boolean;
  canGroup?: boolean;
  groupValue?: boolean;
  hrefTarget?: "_blank" | "_parent";
  hrefTitle?: string;
  valuePrepareFunction?: (cell?, row?) => string | number;
  hrefPrepareFunction?: (cell?, row?) => string | number;
  public constructor(init?: Partial<MagicTableColumn>) {
    this.title = "";
    this.fieldName = init.fieldName ? init.fieldName : `${_normalizeString(init.title)}`;
    this.type = "text";
    this.width = "auto";
    this.show = true;
    this.canFilter = true;
    this.canGroup = false;
    this.groupValue = false;
    this.hrefTarget = "_parent";
    this.hrefTitle = "";
    if (init)
      Object.assign(this, init);
  }
}
export class MagicTableRowData {
  obj: object;
  /** Original Value */
  data: any[];
  /** Display Value */
  cells: any[];
  /** Display Link */
  hrefs: string[];
  subRows: MagicTableRowData[];
  page: number;
  public constructor(init?: Partial<MagicTableRowData>) {
    this.obj = null;
    this.data = [];
    this.cells = [];
    this.hrefs = [];
    this.subRows = [];
    this.page = 0;
    if (init)
      Object.assign(this, init);
  }
}


export class MagicControlOptionData {
  title: string; value: string;
}
export class MagicControlData {
  title: string = "";
  action: "group" | "filter" | "OP" = "group";
  value: string | "AND" | "OR"[] = [];
}

export class FilterIndexData {
  show: boolean;
  /** Original Value */
  data: any
  /** Display Value */
  cell: string;
}
export type UiFilterIndex = FilterIndexData[];
export class FilterPopdownData {
  searchText: string;
  filterIndex: UiFilterIndex = [];
  searchResult: UiFilterIndex = [];
  selectAll: boolean;
  AddCurSelTofilter: boolean;
  public constructor(init?: Partial<FilterPopdownData>) {
    this.searchText = "";
    this.filterIndex = [];
    this.searchResult = [];
    this.selectAll = false;
    this.AddCurSelTofilter = true;
    if (init)
      Object.assign(this, init);
  }
}