<div id="{{id}}" class="db_table" [style.width]="tableWidth" [ngClass]="{'editing': edit}">

  <div id="db_table_loader" class="db_table_loader" [ngClass]="{'show': loader}"></div>

  <!-- db_table_wrapper -->
  <div class="db_table_wrapper">
    <!-- table_header -->
    <div class="table_header">

      <!-- header cells -->
      <div class="header_cell row_number_cell" [style.width]="numberCellWidth">
        <span class="col_title">
          Nº
        </span>
      </div>

      <!-- custom cols -->
      <div class="header_cell" *ngFor="let col of table.cols; trackBy: _trackByFn; let col_i = index"
        title="{{col.title}}" [style.width]="col.width"
        [ngClass]="{'first': col_i == 0,  'last': !edit && col_i == table.cols.length - 1}">

        <button *ngIf="filterActiveIndex[col_i]" class="action" title="Limpar Filtro" (click)="cleanFilter(col_i)">
          <nb-icon style="color: blue;" icon="funnel-outline" pack="eva"></nb-icon>
        </button>

        <button class="action" *ngIf="orderByIndex[col_i]" title="Limpar Ordenação"
          (click)="toogleSort(col_i, orderByIndex[col_i])">
          <nb-icon *ngIf="orderByIndex[col_i] == 'asc'" style="color: blue;" icon="arrow-upward-outline"
            pack="eva"></nb-icon>

          <nb-icon *ngIf="orderByIndex[col_i] == 'desc'" style="color: blue;" icon="arrow-downward-outline"
            pack="eva"></nb-icon>
        </button>

        <span class="col_title">
          {{col.title}}
        </span>

        <button #colOptionsOverlayTrigger class="col-options-btn"
          (click)="openColOptionsOverlay(colOptionsOverlayTrigger, col_i)">
          <nb-icon icon="chevron-down-outline" pack="eva"></nb-icon>
        </button>

      </div>
      <!-- custom cols -->

      <button *ngIf="edit" class="header_cell last row_option_cell" [disabled]="!canCreate"
        [style.width]="optionCellWidth" (click)="addLine()">
        <nb-icon icon="file-add-outline" pack="eva"></nb-icon>
      </button>
      <!-- header cells -->

    </div>
    <!-- table_header -->

    <!-- table_body -->
    <cdk-virtual-scroll-viewport id="{{'db_table_body:'+id}}" class="table_body" itemSize="30" minBufferPx="450"
      maxBufferPx="600">

      <!-- table_line -->
      <form class="table_line" id="{{formIdGen(row_i)}}" name="{{formIdGen(row_i)}}" #rowForm="ngForm"
        *cdkVirtualFor="let row of filtredRows; trackBy: _trackByFn; let row_i = index">

        <!-- rows cells -->
        <div class="table_cell row_number_cell" [style.width]="numberCellWidth">
          <nb-icon *ngIf="row.hasError" icon="alert-triangle-outline" pack="eva" style="color: red;"></nb-icon>
          <p>{{row.number}}</p>
        </div>

        <!-- custom cols -->
        <div class="table_cell" *ngFor="let cel of row.cells; trackBy: _trackByFn; let cells_i = index"
          [style.--table-cell-width]="table.cols[cells_i].width" [ngClass]="getCellClasses(row_i, cells_i, rowForm)">

          <!-- Old row -->
          <div *ngIf="row.status == 'old' || row.status == 'changed'" class="intermediary-div">

            <!-- input data -->
            <div *ngIf="table.cols[cells_i].input" class="intermediary-div">

              <!-- changeable cells -->
              <div *ngIf="table.cols[cells_i].canChange" class="intermediary-div">
                <!-- text -->
                <input form="rowForm" class="table_cell_input" *ngIf="table.cols[cells_i].input.type == 'text'"
                  id="{{formControlIdGen(row_i, cells_i)}}" name="{{formControlIdGen(row_i, cells_i)}}" type="text"
                  minlength="{{table.cols[cells_i].input.minlength}}"
                  maxlength="{{table.cols[cells_i].input.maxlength}}"
                  [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i], row, aditionalData)"
                  [pattern]="table.cols[cells_i].input.pattern"
                  [required]="table.cols[cells_i].input.required(row.cellsData[cells_i], row, aditionalData)"
                  [(ngModel)]="row.cellsData[cells_i]" (ngModelChange)="_rowChange(row_i, cells_i, rowForm.valid)"
                  autofocus>
                <!-- text -->

                <!-- number -->
                <input form="rowForm" class="table_cell_input" *ngIf="table.cols[cells_i].input.type == 'number'"
                  id="{{formControlIdGen(row_i, cells_i)}}" name="{{formControlIdGen(row_i, cells_i)}}" type="number"
                  min="{{table.cols[cells_i].input.min}}" max="{{table.cols[cells_i].input.max}}"
                  [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i], row, aditionalData)"
                  [pattern]="table.cols[cells_i].input.pattern"
                  [required]="table.cols[cells_i].input.required(row.cellsData[cells_i], row, aditionalData)"
                  [(ngModel)]="row.cellsData[cells_i]" (ngModelChange)="_rowChange(row_i, cells_i, rowForm.valid)"
                  autofocus>
                <!-- number -->

                <!-- date -->
                <input form="rowForm" class="table_cell_input" *ngIf="table.cols[cells_i].input.type == 'date'"
                  id="{{formControlIdGen(row_i, cells_i)}}" name="{{formControlIdGen(row_i, cells_i)}}" type="date"
                  min="{{table.cols[cells_i].input.min}}" max="{{table.cols[cells_i].input.max}}"
                  [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i], row, aditionalData)"
                  [pattern]="table.cols[cells_i].input.pattern"
                  [required]="table.cols[cells_i].input.required(row.cellsData[cells_i], row, aditionalData)"
                  [(ngModel)]="row.cellsData[cells_i]" (ngModelChange)="_rowChange(row_i, cells_i, rowForm.valid)"
                  autofocus>
                <!-- date -->

                <!-- select -->
                <ng-select form="rowForm" class="table_cell_input" *ngIf="table.cols[cells_i].input.type == 'select'"
                  id="{{formControlIdGen(row_i, cells_i)}}" name="{{formControlIdGen(row_i, cells_i)}}"
                  [compareWith]="_compareFn"
                  [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i], row, aditionalData)"
                  [required]="table.cols[cells_i].input.required(row.cellsData[cells_i], row, aditionalData)"
                  [pattern]="table.cols[cells_i].input.pattern" [(ngModel)]="row.cellsData[cells_i]"
                  (change)="_rowChange(row_i, cells_i, rowForm.valid)" autofocus [appendTo]="'body'">
                  <ng-option *ngFor="let o of row.selectOptions[cells_i]" [value]="o.value">
                    {{o.name}}
                  </ng-option>
                </ng-select>
                <!-- select -->
              </div>
              <!-- changeable cells -->

              <!-- unchangeable cells -->
              <div *ngIf="!table.cols[cells_i].canChange" class="intermediary-div">
                <!-- text -->
                <p *ngIf="table.cols[cells_i].input.type == 'text'">
                  {{cel}}
                </p>
                <!-- text -->

                <!-- number -->
                <p *ngIf="table.cols[cells_i].input.type == 'number'">
                  {{cel}}
                </p>
                <!-- number -->

                <!-- date -->
                <input form="rowForm" class="table_cell_input" *ngIf="table.cols[cells_i].input.type == 'date'"
                  id="{{formControlIdGen(row_i, cells_i)}}" name="{{formControlIdGen(row_i, cells_i)}}" type="date"
                  disabled [(ngModel)]="row.cellsData[cells_i]" autofocus>
                <!-- date -->

                <!-- select -->
                <div *ngIf="table.cols[cells_i].input.type == 'select'">
                  <div *ngFor="let o of row.selectOptions[cells_i]">
                    <p *ngIf="row.cellsData[cells_i] == o.value">
                      {{o.name}}
                    </p>
                  </div>
                </div>
                <!-- select -->
              </div>
              <!-- unchangeable cells -->

            </div>
            <!-- input data -->

            <!-- no input data -->
            <div *ngIf="!table.cols[cells_i].input" class="intermediary-div">
              <p *ngIf="table.cols[cells_i].type == 'text'">
                {{cel}}
              </p>

              <div *ngIf="table.cols[cells_i].type == 'html'" [innerHTML]="_trustHtml(cel)">
              </div>
            </div>
            <!-- no input data -->

          </div>
          <!-- Old row -->

          <!-- New row -->
          <div *ngIf="row.status == 'new'" class="intermediary-div">

            <!-- input data -->
            <div *ngIf="table.cols[cells_i].input" class="intermediary-div">

              <!-- text -->
              <input form="rowForm" class="table_cell_input" *ngIf="table.cols[cells_i].input.type == 'text'"
                id="{{formControlIdGen(row_i, cells_i)}}" name="{{formControlIdGen(row_i, cells_i)}}" type="text"
                minlength="{{table.cols[cells_i].input.minlength}}" maxlength="{{table.cols[cells_i].input.maxlength}}"
                [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i], row, aditionalData)"
                [pattern]="table.cols[cells_i].input.pattern"
                [required]="table.cols[cells_i].input.required(row.cellsData[cells_i], row, aditionalData)"
                [(ngModel)]="row.cellsData[cells_i]" (ngModelChange)="_rowChange(row_i, cells_i, rowForm.valid)"
                autofocus>
              <!-- text -->

              <!-- number -->
              <input form="rowForm" class="table_cell_input" *ngIf="table.cols[cells_i].input.type == 'number'"
                id="{{formControlIdGen(row_i, cells_i)}}" name="{{formControlIdGen(row_i, cells_i)}}" type="number"
                min="{{table.cols[cells_i].input.min}}" max="{{table.cols[cells_i].input.max}}"
                [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i], row, aditionalData)"
                [pattern]="table.cols[cells_i].input.pattern"
                [required]="table.cols[cells_i].input.required(row.cellsData[cells_i], row, aditionalData)"
                [(ngModel)]="row.cellsData[cells_i]" (ngModelChange)="_rowChange(row_i, cells_i, rowForm.valid)"
                autofocus>
              <!-- number -->

              <!-- date -->
              <input form="rowForm" class="table_cell_input" *ngIf="table.cols[cells_i].input.type == 'date'"
                id="{{formControlIdGen(row_i, cells_i)}}" name="{{formControlIdGen(row_i, cells_i)}}" type="date"
                min="{{table.cols[cells_i].input.min}}" max="{{table.cols[cells_i].input.max}}"
                [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i], row, aditionalData)"
                [pattern]="table.cols[cells_i].input.pattern"
                [required]="table.cols[cells_i].input.required(row.cellsData[cells_i], row, aditionalData)"
                [(ngModel)]="row.cellsData[cells_i]" (ngModelChange)="_rowChange(row_i, cells_i, rowForm.valid)"
                autofocus>
              <!-- date -->

              <!-- select -->
              <ng-select form="rowForm" class="table_cell_input" *ngIf="table.cols[cells_i].input.type == 'select'"
                id="{{formControlIdGen(row_i, cells_i)}}" name="{{formControlIdGen(row_i, cells_i)}}"
                [compareWith]="_compareFn" appendTo="body"
                [disabled]="!edit || table.cols[cells_i].input.disabled(row.cellsData[cells_i], row, aditionalData)"
                [required]="table.cols[cells_i].input.required(row.cellsData[cells_i], row, aditionalData)"
                [pattern]="table.cols[cells_i].input.pattern" [(ngModel)]="row.cellsData[cells_i]"
                (change)="_rowChange(row_i, cells_i, rowForm.valid)" [appendTo]="'body'">
                <ng-option *ngFor="let o of row.selectOptions[cells_i]" [value]="o.value">
                  {{o.name}}
                </ng-option>
              </ng-select>
              <!-- select -->
            </div>
            <!-- input data -->

            <!-- no input data -->
            <div *ngIf="!table.cols[cells_i].input" class="intermediary-div">
              <p *ngIf="table.cols[cells_i].type == 'text'">
                {{cel}}
              </p>

              <div *ngIf="table.cols[cells_i].type == 'html'" [innerHTML]="_trustHtml(cel)">
              </div>
            </div>
            <!-- no input data -->

          </div>
          <!-- New row -->
        </div>
        <!-- custom cols -->

        <div *ngIf="edit" class="table_cell row_option_cell" [style.width]="optionCellWidth">
          <button #rowOptionsOverlayTrigger class="row-options-btn"
            (click)="openRowOptionsOverlay(rowOptionsOverlayTrigger, row.number)">
            <nb-icon icon="more-vertical-outline" pack="eva"></nb-icon>
          </button>
        </div>
        <!-- rows cells -->

      </form>
      <!-- table_line -->

    </cdk-virtual-scroll-viewport>
    <!-- table_body -->

    <!-- table_footer -->
    <div class="table_footer">
      <span *ngIf="!hasFilterActive()">
        {{table.rows.length}} linha(s)
      </span>
      <span *ngIf="hasFilterActive()">
        <b>{{filtredRows.length}}</b> de {{table.rows.length}} linha(s)
      </span>

      <button class="save_as_excel_btn" [disabled]="table.rows.length == 0" (click)="saveAsExcel()">
        <i class="fa-solid fa-download"></i>
        Excel
      </button>
    </div>
    <!-- table_footer -->
  </div>
  <!-- db_table_wrapper -->
</div>