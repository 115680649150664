export type TranslationType = "name" | "description";

export class DICTIONARY {

  /**
   * Retorna a a string para o boolean
   * @param {boolean} boolean
   * @return  {string} Sim ou Não
   */
  public translateBoolean(boolean: boolean): string {
    return this.translator(boolean, this.general.boolean);
  }
  public translator(typeId: string | Number | boolean, dictionaryVet: any[], tType?: TranslationType): string {
    if (typeId === null)
      return '';
    for (let v of dictionaryVet) {
      if (v.id == typeId)
        if (tType === "name" || tType === undefined)
          return v.name
        else
          return v.description
    }
    return `${typeId}`;
  }
  public getData(typeId: string | Number, dictionaryVet: any[], tType?: TranslationType): any {
    for (let v of dictionaryVet) {
      if (v.id == typeId)
        if (tType === "name" || tType === undefined)
          return v
        else
          return v
    }
    return {};
  }

  // General
  readonly general = {
    month: [
      { id: 1, name: 'Janeiro' },
      { id: 2, name: 'Fevereiro' },
      { id: 3, name: 'Março' },
      { id: 4, name: 'Abril' },
      { id: 5, name: 'Maio' },
      { id: 6, name: 'Junho' },
      { id: 7, name: 'Julho' },
      { id: 8, name: 'Agosto' },
      { id: 9, name: 'Setembro' },
      { id: 10, name: 'Outubro' },
      { id: 11, name: 'Novembro' },
      { id: 12, name: 'Dezembro' },
    ],
    boolean: [
      { id: true, name: 'Verdadeiro' },
      { id: false, name: 'Falso' },
    ],
    status: [
      { id: 0, name: 'Inativo' },
      { id: 1, name: 'Ativo' }
    ],
  };

  // sys
  readonly sys = {
    db: {
      dataField: {
        type: [
          {
            id: 'boolean',
            name: 'boolean'
          },
          {
            id: 'string',
            name: 'string'
          },
          {
            id: 'number',
            name: 'number'
          },
          {
            id: 'timestamp',
            name: 'timestamp'
          },
          {
            id: 'object',
            name: 'object'
          },
          {
            id: 'array',
            name: 'array'
          },
        ],
      },
      dataSource: {
        type: [
          {
            id: 'table',
            name: 'table'
          },
          {
            id: 'view',
            name: 'view'
          },
        ],
      },
      orderByRule: {
        direction: [
          { id: 'asc', name: 'ascendente' },
          { id: 'desc', name: 'descendente' },
        ]
      },
      filterRule: {
        conditions: {
          operation: [
            { id: 'EQ', name: 'é' },
            { id: 'NE', name: 'não é' },
            { id: 'GT', name: 'é maior que' },
            { id: 'LT', name: 'é menor que' },
            { id: 'GE', name: 'é maior ou igual' },
            { id: 'LE', name: 'é menor ou igual' },
            { id: 'IS_NULL', name: 'está vazio' },
            { id: 'IS_NOT_NULL', name: 'não está vazio' },
            { id: 'IS_ANYTHING', name: 'é qualquer coisa' },
            { id: 'SAME_AS', name: 'é o mesmo que' },
            { id: 'NOT_SAME_AS', name: 'é diferente de' },
          ],
        },
        clause: [
          { id: 'AND', name: 'E' },
          { id: 'OR', name: 'OU' },
        ],
      },
    },
    user: {
      gender: [
        {
          id: 'M',
          name: 'Masculino'
        },
        {
          id: 'F',
          name: 'Feminino'
        },
        {
          id: 'O',
          name: 'Outro / Não Declarar'
        },
      ]
    },
    accessControl: {
      authorizationProvisioning: {
        roles: {
          type: [
            {
              id: 'S',
              name: 'SINGLE ROLE'
            },
            {
              id: 'C',
              name: 'COMPOSITE ROLE'
            },
            {
              id: 'D',
              name: 'DERVIED ROLE'
            },
          ],
        },
      },
    },
    logs: {
      type: [
        {
          id: 'SYS',
          name: 'Sistema'
        },
        {
          id: 'AUDIT',
          name: 'Aditoria'
        },
      ],
      category: [
        {
          id: 'GR',
          name: 'Geral'
        },
        {
          id: 'C',
          name: 'Comentário'
        },
        // ------------
        {
          id: 'CR',
          name: 'Criação'
        },
        {
          id: 'FC',
          name: 'Alterações'
        },
        {
          id: 'DE',
          name: 'Apagar'
        },
        // ------------
        {
          id: 'PR',
          name: 'Imprimir'
        },
        {
          id: 'ER',
          name: 'Erro'
        },
        {
          id: 'SEC',
          name: 'Security'
        },
        // ------------
        {
          id: 'DL',
          name: 'Documento Bloqueado'
        },
        {
          id: 'DU',
          name: 'Documento Desbloqueado'
        },
      ]
    }
  };
  // sys

  // home
  readonly home = {
    tabs: {
      itemAscs: {
        type: [
          {
            id: 'hpi',
            name: 'Home Page Item'
          },
          {
            id: 'note',
            name: 'Note App'
          },
          {
            id: 'comms',
            name: 'Comunicações'
          },
          // {
          //   id: 'wf-inbox',
          //   name: 'Caixa de entrada do WF'
          // },
        ]
      }
    },
  };
  // home

  // Time Management
  readonly timeManagement = {
    teamAsc: {
      role: [
        {
          id: 'Admin',
          name: 'Administrador'
        },
        {
          id: 'Member',
          name: 'Membro'
        },
      ],
    },
    project: {
      color: [
        {
          id: 'c1',
          name: 'Lochmara',
          hex: '#0B83D9'
        },
        {
          id: 'c2',
          name: 'Christi',
          hex: '#2DA608'
        },
        {
          id: 'c3',
          name: 'Saara',
          hex: '#C7AF14'
        },
        {
          id: 'c4',
          name: 'Roxo Médio',
          hex: '#9E5BD9'
        },
        {
          id: 'c5',
          name: 'Niágara',
          hex: '#06A893'
        },
        {
          id: 'c6',
          name: 'Saratoga',
          hex: '#596614'
        },
        {
          id: 'c7',
          name: 'Cabaré',
          hex: '#D94182'
        },
        {
          id: 'c8',
          name: 'Bronze antigo',
          hex: '#C9806B'
        },
        {
          id: 'c9',
          name: 'Soco',
          hex: '#D92B2B'
        },
        {
          id: 'c10',
          name: 'Clementine',
          hex: '#E36A00'
        },
        {
          id: 'c11',
          name: 'San Marino',
          hex: '#465BB3'
        },
        {
          id: 'c12',
          name: 'Scarpa Flow',
          hex: '#525266'
        },
        {
          id: 'c13',
          name: 'Indochina',
          hex: '#BF7000'
        },
        {
          id: 'c14',
          name: 'Flerte',
          hex: '#990099'
        },
        {
          id: 'c15',
          name: 'Sangria',
          hex: '#991102'
        },
      ],
    },
    projectAsc: {
      role: [
        {
          id: 'Admin',
          name: 'Administrador'
        },
        {
          id: 'Member',
          name: 'Membro'
        },
      ],
    },
  };
  // Time Management

  // Workflow
  readonly workflow = {
    group: {
      approvalType: [
        {
          id: 'ONE',
          name: 'Um dos aprovadores'
        },
        {
          id: 'ALL',
          name: 'Todos os aprovadores'
        },
      ],
    },
  };
  // Workflow

  // Service Management
  readonly serviceManagement = {
    teamAsc: {
      role: [
        {
          id: 'Admin',
          name: 'Administrador'
        },
        {
          id: 'Member',
          name: 'Membro'
        },
      ],
    },
    serviceCatalog: {
      customField: {
        type: [
          {
            id: 'text',
            name: 'Texto'
          },
          {
            id: 'textarea',
            name: 'Caixa de texto'
          },
          {
            id: 'number',
            name: 'Número'
          },
          {
            id: 'select',
            name: 'Lista de Seleção'
          },
          {
            id: 'multiselect',
            name: 'Lista de Seleção Multipla'
          },
          {
            id: 'date',
            name: 'Data'
          },
          {
            id: 'file',
            name: 'Arquivo'
          },
        ],
      },
      customFieldAsc: {
        type: [
          {
            id: 'Optional',
            name: 'Opcional'
          },
          {
            id: 'Mandatory',
            name: 'obrigatório'
          },
        ],
      },
      slaDefinition: {
        type: [
          {
            id: 'SLA',
            name: 'SLA - Acordo de Nível de Serviço'
          },
          {
            id: 'OLA',
            name: 'OLA - Acordo de Nível Operacional'
          },
          {
            id: 'UC',
            name: 'UC - Contrato de Apoio'
          },
        ],
        target: [
          {
            id: 'response',
            name: 'Resposta'
          },
          {
            id: 'resolution',
            name: 'Resolução'
          },
        ],
        startCondition: {
          whemToCancel: [
            {
              id: 'no_match',
              name: 'As condições de início não foram atendidas'
            },
            {
              id: 'on_condition',
              name: 'As condições de cancelamento foram atendidas'
            },
            {
              id: 'never',
              name: 'Nunca'
            },
          ]
        },
        pauseCondition: {
          whenToResume: [
            {
              id: 'no_match',
              name: 'As condições de pausa não foram atendidas'
            },
            {
              id: 'on_condition',
              name: 'As condições de reinício foram atendidas'
            },
          ]
        },
        resetCondition: {
          resetAction: [
            {
              id: 'cancel',
              name: 'Cancelar SLA existente'
            },
            {
              id: 'complete',
              name: 'Concluir SLA existente'
            },
          ]
        },
      }
    },
    documents: {
      type: [
        {
          id: 'request',
          name: 'Solicitação'
        },
        {
          id: 'task',
          name: 'Tarefa'
        },
        {
          id: 'incident',
          name: 'Incidente'
        },
        {
          id: 'problem',
          name: 'Problema'
        },
        {
          id: 'change',
          name: 'Mudança'
        },
      ],
      impact: [
        {
          id: 1,
          name: 'Baixo'
        },
        {
          id: 2,
          name: 'Médio'
        },
        {
          id: 3,
          name: 'Alto'
        },
      ],
      urgency: [
        {
          id: 1,
          name: 'Baixo'
        },
        {
          id: 2,
          name: 'Médio'
        },
        {
          id: 3,
          name: 'Alto'
        },
      ],
      priority: [
        {
          id: 'P1',
          name: 'Crítico'
        },
        {
          id: 'P2',
          name: 'Importante'
        },
        {
          id: 'P3',
          name: 'Normal'
        },
        {
          id: 'P4',
          name: 'Baixo'
        },
        {
          id: 'P5',
          name: 'Planejado'
        },
      ],
      contactType: [
        {
          id: 'email',
          name: 'Email'
        },
        {
          id: 'phone',
          name: 'Telefone'
        },
        {
          id: 'selfService',
          name: 'Self Service'
        },
        {
          id: 'walk_in',
          name: 'Walk-in'
        },
      ],
      state: [
        {
          id: 'new',
          name: 'Novo',
          hex: 'gren'
        },
        {
          id: 'work_in_progress',
          name: 'Em atendimento',
          hex: 'blue'
        },
        {
          id: 'awainting_assignment',
          name: 'Aguardando atendimento',
          hex: 'yellow'
        },
        {
          id: 'awainting_approval',
          name: 'Aguardando aprovação',
          hex: 'orange'
        },
        {
          id: 'work_scheduled',
          name: 'Agendado',
          hex: 'yellow'
        },
        {
          id: 'pending_problem',
          name: 'Pendente problema',
          hex: 'orange'
        },
        {
          id: 'pending_caller',
          name: 'Pendente usuário',
          hex: 'orange'
        },
        {
          id: 'pending_vendor',
          name: 'Pendente fornecedor',
          hex: 'orange'
        },
        {
          id: 'pending_change',
          name: 'Pendente mudança',
          hex: 'orange'
        },
        {
          id: 'resolved',
          name: 'Resolvido',
          hex: 'gren'
        },
        {
          id: 'closed',
          name: 'Fechado',
          hex: 'ligth-gren'
        },
        {
          id: 'canceled',
          name: 'Cancelado',
          hex: 'red'
        },
      ],
      approvalStatus: [
        {
          id: 'A',
          name: 'Aprovado'
        },
        {
          id: 'R',
          name: 'Rejeitado'
        },
        {
          id: 'P',
          name: 'Pendente'
        },
        {
          id: 'NA',
          name: 'Não Aplicável'
        },
      ]
    },
    docActivity: {
      type: [
        {
          id: 'GR',
          name: 'Geral'
        },
        {
          id: 'CR',
          name: 'Criação'
        },
        {
          id: 'C',
          name: 'Comentário'
        },
      ],
      visibility: [
        {
          id: 'G',
          name: 'Geral'
        },
        {
          id: 'I',
          name: 'Interna'
        }
      ],
    },
    docSLA: {
      stage: [
        {
          id: 'in_progress',
          name: 'Em andamento'
        },
        {
          id: 'cancelled',
          name: 'Cancelado'
        },
        {
          id: 'paused',
          name: 'Pausado'
        },
        {
          id: 'completed',
          name: 'Concluído'
        },
      ],
    }
  };
  // Service Management


  // Project Management
  readonly projectManagement = {
    boards: {
      cards: {
        priority: [
          {
            id: 'P1',
            name: 'Crítico'
          },
          {
            id: 'P2',
            name: 'Importante'
          },
          {
            id: 'P3',
            name: 'Normal'
          },
          {
            id: 'P4',
            name: 'Baixo'
          },
          {
            id: 'P5',
            name: 'Planejado'
          },
        ],
      }
    }
  };
  // Project Management


}