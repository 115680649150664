import { TM_IntegrationType, TM_Project, TM_ProjectTask, TM_Tag } from "app/@firebase";

export const weekDayNames = [
  'Dom',
  'Seg',
  'Ter',
  'Qua',
  'Qui',
  'Sex',
  'Sáb'
];

export class DataCoreType {
  projects: TM_Project[] = [];
  projectTasks: TM_ProjectTask[] = [];
  tags: TM_Tag[] = [];
  public constructor(init?: Partial<DataCoreType>) {
    this.projects = [];
    this.projectTasks = [];
    this.tags = [];
    if (init)
      Object.assign(this, init);
  }
};

export class TimerListLineDay {
  timeRecordId: string;
  h: number;
  m: number;
  time: string;
  val: number;
  createdBy: string;
  updatedBy: string;
  managed: boolean;
  public constructor(init?: Partial<TimerListLineDay>) {
    this.timeRecordId = '';
    this.h = 0;
    this.m = 0;
    this.time = '00:00:00';
    this.val = 0;
    this.createdBy = null;
    this.updatedBy = null;
    this.managed = false;
    if (init)
      Object.assign(this, init);
  }
};

export class TimerListLine {
  /** projectId + projectTaskId + projectTagsIds + description */
  mergeKey: string;

  status: 'old' | 'new' | 'changed';
  managed: boolean;

  hasError: boolean;
  auth: boolean;

  project: TM_Project;
  task: TM_ProjectTask;
  tags: TM_Tag[];
  description: string;
  days: TimerListLineDay[];
  value: number;
  firstValuedDayIndex: number;

  selectionData: {
    projectTasks: TM_ProjectTask[];
    projectTags: TM_Tag[];
  };

  oldData: {
    projectId: string;
    taskId: string;
    tagsIds: string[];
    description: string;
    days: TimerListLineDay[];
  };

  referenceType: TM_IntegrationType;
  reference: string;
  reference1: string;
  reference2: string;
  reference3: string;

  public constructor(init?: Partial<TimerListLine>) {
    this.mergeKey = '';

    this.status = 'new';
    this.managed = false;

    this.hasError = false;
    this.auth = false;

    this.project = new TM_Project();
    this.task = new TM_ProjectTask();
    this.tags = [];
    this.description = "";
    this.days = [];
    this.value = 0;
    this.firstValuedDayIndex = -1;

    this.selectionData = { projectTasks: [], projectTags: [] };
    this.oldData = { projectId: '', taskId: '', tagsIds: [], description: '', days: [] };

    this.referenceType = null;
    this.reference = '';
    this.reference1 = '';
    this.reference2 = '';
    this.reference3 = '';

    if (init)
      Object.assign(this, init);
  }
};