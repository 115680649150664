import firebase from "firebase/compat/app";

export class TM_Tag {

  id: string;
  name: string;
  teamId: string;
  parentKey: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<TM_Tag>) {

    this.id = "";
    this.name = "";
    this.teamId = "";
    this.parentKey = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;


    if (init)
      Object.assign(this, init);
  }
}
