import { CustomSelectBtnRenderer2Component } from "./smart-table-select-modal/custom-select-btn-renderer2.component";
import { SmartTableSelectModalComponent } from "./smart-table-select-modal/smart-table-select-modal.component";
import { UiFeedBackAlertModalComponent } from "./ui-feed-back-alert-modal/ui-feed-back-alert-modal.component";
import { UiFeedBackCustonAlertModal } from "./ui-feed-back-custon-alert-modal/ui-feed-back-custon-alert-modal.component";
import { UiFeedBackLoaderModalComponent } from "./ui-feed-back-loader-modal/ui-feed-back-loader-modal.component";

export const UI_FEED_BACK_COMPONENTS = [
    UiFeedBackLoaderModalComponent,
    UiFeedBackCustonAlertModal,
    UiFeedBackAlertModalComponent,
    SmartTableSelectModalComponent,
    CustomSelectBtnRenderer2Component
];