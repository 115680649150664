<div id="{{className}}" class="body-section add-note">
    <div class="body-section-header">
        <nb-icon class="header-icon" icon="edit-2-outline" pack="eva"></nb-icon>
        <span class="header-title">Nova Atividade</span>
    </div>

    <magic-text-editor #noteEditorERef [usersToMention]="pmCardModal.magicTextEditorUsersToMention" [data]="_noteHtml"
        [disabled]="!pmCardModal.isBoardActive" (onChange)="_noteHtmlChange($event)"
        (onUsersMentionedChange)="_noteUsersMentionedChange($event)">
    </magic-text-editor>

    <!-- note-attachments -->
    <div class="note-attachments" *ngIf="_noteAttachmentsToCreate.length > 0">
        <div *ngFor="let attachment of _noteAttachmentsToCreate">
            <div class="pm-attachment">
                <div class="attachment-icon" *ngIf="_noteAttachmentsToCreateIconMap[attachment.id]">
                    <nb-icon icon="{{_noteAttachmentsToCreateIconMap[attachment.id].icon}}"
                        pack="{{_noteAttachmentsToCreateIconMap[attachment.id].pack}}"
                        [style.color]="_noteAttachmentsToCreateIconMap[attachment.id].colorHex"></nb-icon>
                </div>

                <div class="attachment-name">
                    <span class="attachment-name">
                        <a>{{attachment.name}}</a>
                    </span>
                    <span class="attachment-subtitle">
                        ({{pmCardModal.utilCtrl.formatters.formatBytes(attachment.size)}})
                        {{pmCardModal.utilCtrl.timestamp.toLocalDate(attachment.createdOn)}}
                    </span>
                </div>

                <div class="attachment-opt">
                    <button nbButton status="danger" appearance="ghost" size="tiny"
                        (click)="_remFileToCommentNote(attachment)">
                        <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- note-attachments -->

    <div class="note-btn-row">
        <input required [multiple]="true" style="display:none;" id="input_file_to_comment_note"
            name="input_file_to_comment_note" type="file" (change)="_incomingFileToCommentNote($event)" />

        <div class="ic-button-grp">
            <button [disabled]="!pmCardModal.isBoardActive" nbButton appearance="ghost" title="Adionar Anexo"
                status="primary" size="small" onclick="document.getElementById('input_file_to_comment_note').click()">
                <nb-icon icon="attach-outline" pack="eva"></nb-icon>
            </button>

            <button [disabled]="!pmCardModal.isBoardActive" nbButton appearance="ghost" title="Publicar"
                status="primary" (click)="_addNoteActv()">
                <nb-icon icon="paper-plane" pack="far"></nb-icon>
                Publicar
            </button>
        </div>
    </div>
</div>