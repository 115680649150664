import firebase from "firebase/compat/app";

export class DeviceToken {
    id: string;
    uName: string;

    deviceInfo: string;
    ipAddress: string;
    location: string;

    loginTime: string;
    lastSeen: firebase.firestore.Timestamp;
    public constructor(init?: Partial<DeviceToken>) {
        this.id = '';
        this.uName = '';

        this.deviceInfo = '';
        this.ipAddress = '';
        this.location = '';

        this.loginTime = '';
        this.lastSeen = firebase.firestore.Timestamp.now();
        if (init)
            Object.assign(this, init);
    }
}
