import firebase from "firebase/compat/app";
import { DAY_IN_MS, HOUR_IN_MS, UtilService } from "app/@core/services";

import { PM_Card } from "./PM_Card";

export type PM_CardRecurrenceFrequency = 'daily' | 'weekly' | 'monthly' | 'yearly' | null;

export type PM_CardRecurrenceDaysOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type PM_CardRecurrenceDaysOfMonth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31;
export type PM_CardRecurrenceMonthOfYear = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export type PM_CardRecurrenceEarlyCreation = 'd' | 'h' | null;

function __getNextStartDate_daily(lastStart: Date, recurrence: PM_CardRecurrence, utilCtrl: UtilService): Date {
    const nextStartDate = utilCtrl.date.newDate(lastStart.getTime());
    nextStartDate.setDate(lastStart.getDate() + recurrence.interval);
    return nextStartDate;
}
function __getNextStartDate_weekly(lastStart: Date, recurrence: PM_CardRecurrence, utilCtrl: UtilService): Date {
    // Create a new date object based on the last start date
    const nextStartDate = utilCtrl.date.newDate(lastStart.getTime());

    // Get the number of weeks to add from the recurrence interval
    let weeksToAdd = recurrence.interval;

    // Get the days of the week array (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    let daysOfWeek = recurrence.daysOfWeek;

    // If no specific days of the week are set, just add the weekly interval
    if (!daysOfWeek || daysOfWeek.length === 0) {
        // Add the interval in weeks to the current date
        nextStartDate.setDate(lastStart.getDate() + (weeksToAdd * 7));
        return nextStartDate;
    }

    // Decrement weeksToAdd because we may find a valid day within the current week
    weeksToAdd--;

    // Sort the days of the week in ascending order (Sunday -> Saturday)
    daysOfWeek = daysOfWeek.slice().sort((a, b) => a - b);

    // Determine the day of the week for the last start date
    const today = lastStart.getDay();
    let foundDay = false;

    // Set the default next day to the first available day of the next week
    let nextDay = daysOfWeek[0];

    // Loop through the available days of the week to find the next one
    for (let i = 0; i < daysOfWeek.length; i++)
        if (daysOfWeek[i] > today) {
            // If a day in the current week is found, set it as the next day
            nextDay = daysOfWeek[i];
            // If a valid day is found this week, reset weeksToAdd to 0 since we don't need to jump to the next week
            weeksToAdd = 0;
            foundDay = true;
            break;
        }

    // Calculate how many days need to be added to reach the next valid start date
    const daysToAdd = ((weeksToAdd * 7) + (nextDay - today + (foundDay ? 0 : 7)));

    // Set the new start date by adding the calculated number of days
    nextStartDate.setDate(lastStart.getDate() + daysToAdd);

    return nextStartDate;
}
function __getNextStartDate_monthly(lastStart: Date, recurrence: PM_CardRecurrence, utilCtrl: UtilService): Date {
    const nextStartDate = utilCtrl.date.newDate(lastStart.getTime());
    let monthToAdd = recurrence.interval;

    // Properly adjust for intervals greater than 12 months
    while (monthToAdd > 0)
        if (nextStartDate.getMonth() + monthToAdd > 11) {
            const monthsLeftInYear = 11 - nextStartDate.getMonth(); // Months remaining in the current year
            nextStartDate.setFullYear(nextStartDate.getFullYear() + 1);
            monthToAdd -= (monthsLeftInYear + 1); // Move to next year and subtract months added
            nextStartDate.setMonth(0); // Reset to January after adjusting the year
        } else {
            nextStartDate.setMonth(nextStartDate.getMonth() + monthToAdd);
            monthToAdd = 0;
        }


    // Adjust the day of the month if it exceeds the last day of the new month
    const daysInNextMonth = utilCtrl.date.newDate(nextStartDate.getFullYear(), nextStartDate.getMonth() + 1, 0).getDate(); // Last day of the new month

    // Ensure the day is valid for the month
    if (recurrence.dayOfMonth > daysInNextMonth)
        nextStartDate.setDate(daysInNextMonth); // Adjust to the last valid day
    else
        nextStartDate.setDate(recurrence.dayOfMonth);

    return nextStartDate;
}
function __getNextStartDate_yearly(lastStart: Date, recurrence: PM_CardRecurrence, utilCtrl: UtilService): Date {
    const nextStartDate = utilCtrl.date.newDate(lastStart.getTime());
    nextStartDate.setFullYear(lastStart.getFullYear() + recurrence.interval); // Calculate next start date based on yearly interval

    if (recurrence.monthOfYear)
        nextStartDate.setMonth(recurrence.monthOfYear);

    // Adjust day if specified
    if (recurrence.dayOfMonth) {
        // Adjust the day of the month if it exceeds the last day of the new month
        const daysInNextMonth = utilCtrl.date.newDate(nextStartDate.getFullYear(), nextStartDate.getMonth() + 1, 0).getDate(); // Last day of the new month

        // Ensure the day is valid for the month
        if (recurrence.dayOfMonth > daysInNextMonth)
            nextStartDate.setDate(daysInNextMonth); // Adjust to the last valid day
        else
            nextStartDate.setDate(recurrence.dayOfMonth);
    }

    return nextStartDate;
}
export function getNextStartDateToCard(card: PM_Card, utilCtrl: UtilService): Date {
    if (!card.startDate)
        return null;

    if (!card.recurrence)
        return null;

    if (!card.recurrence.frequency)
        return null;

    if (!card.recurrence.interval || card.recurrence.interval <= 0)
        return null;

    // lastStart date determination
    let lastStartDateDate = null;
    const cardStartDate = utilCtrl.timestamp.toDate(card.startDate);
    const histDataLastCardStartDate = card.recurrence?.histData?.lastCardStartDate ? utilCtrl.timestamp.toDate(card.recurrence.histData.lastCardStartDate) : null;

    if (cardStartDate && histDataLastCardStartDate)
        // Both dates are available, pick the most recent one
        lastStartDateDate = utilCtrl.date.newDate(Math.max(cardStartDate.getTime(), histDataLastCardStartDate.getTime()));
    else
        // Only one of the dates is available, use the available one
        lastStartDateDate = cardStartDate || histDataLastCardStartDate;


    if (!lastStartDateDate)
        return null;

    let nextStartDate: Date = null;

    switch (card.recurrence.frequency) {
        case 'daily':
            nextStartDate = __getNextStartDate_daily(lastStartDateDate, card.recurrence, utilCtrl);
            break;
        case 'weekly':
            nextStartDate = __getNextStartDate_weekly(lastStartDateDate, card.recurrence, utilCtrl);
            break;
        case 'monthly':
            nextStartDate = __getNextStartDate_monthly(lastStartDateDate, card.recurrence, utilCtrl);
            break;
        case 'yearly':
            nextStartDate = __getNextStartDate_yearly(lastStartDateDate, card.recurrence, utilCtrl);
            break;
    }

    if (card.startDateHourString) {
        const [hours, minutes] = card.startDateHourString.split(':').map(Number);
        nextStartDate = utilCtrl.date.setHours(nextStartDate, hours, minutes);
    }

    return utilCtrl.date.newDate(nextStartDate.getTime());
}
export function getNextCardCreationDateToCard(card: PM_Card, utilCtrl: UtilService): Date {
    if (!card.startDate)
        return null;

    if (!card.recurrence)
        return null;

    if (!card.recurrence.frequency)
        return null;

    if (!card.recurrence.interval || card.recurrence.interval <= 0)
        return null;

    const nextCardCreationDate = getNextStartDateToCard(card, utilCtrl);

    // earlyCreation
    if (card.recurrence && card.recurrence.earlyCreation) {
        const earlyCreationInMs = card.recurrence.earlyCreation === 'h' ? (card.recurrence.earlyCreationValue * HOUR_IN_MS) : (card.recurrence.earlyCreationValue * DAY_IN_MS);
        nextCardCreationDate.setTime(nextCardCreationDate.getTime() - earlyCreationInMs);
    }

    return utilCtrl.date.newDate(nextCardCreationDate.getTime());
}

export class PM_CardRecurrence {
    frequency: PM_CardRecurrenceFrequency;
    /** Interval between occurrences (e.g., every 2 weeks) */
    interval: number;
    /** End date or null if indefinite */
    endDate: string | null;
    /** Days of the week (0 for Sunday, 1 for Monday, etc.) */
    daysOfWeek: PM_CardRecurrenceDaysOfWeek[];
    /** Specific day of the month */
    dayOfMonth: PM_CardRecurrenceDaysOfMonth | null;
    /** Specific month of the year (0 for January, 1 for February, etc.) */
    monthOfYear: PM_CardRecurrenceMonthOfYear | null;

    earlyCreation: PM_CardRecurrenceEarlyCreation;
    earlyCreationValue: number;
    creationRouteId: string | null;

    histData: {
        cardsIds: string[];
        lastCardStartDate: firebase.firestore.Timestamp | null;
    };

    public constructor(init?: Partial<PM_CardRecurrence>) {
        this.frequency = null;
        this.interval = 1;
        this.endDate = null;
        this.daysOfWeek = [];
        this.dayOfMonth = null;
        this.monthOfYear = null;

        this.earlyCreation = 'h'; // Default to early creation as hours ('h')
        this.earlyCreationValue = 1; // Default early creation value
        this.creationRouteId = null;

        this.histData = {
            cardsIds: [],
            lastCardStartDate: null
        };

        if (init)
            Object.assign(this, init);
    }
}