import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChange, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocalDataSource } from 'ng2-smart-table';

import { CustomSelectBtnRendererComponent } from './custom-select-btn-renderer.component';
import { SmartTableSelectService } from './smart-table-select.service';

@Component({
  selector: 'smart-table-select',
  styleUrls: ['./smart-table-select.component.scss'],
  templateUrl: './smart-table-select.component.html',
})
export class SmartTableSelectComponent implements OnInit {

  @Input() required = false;
  @Input() disabled = false;
  @Input() disableUi = false;
  touched = false;
  private selectViabackEndPromiseResolve = null;

  @Input() columns: any;
  @Input() source: LocalDataSource;


  nstSettings = {

    mode: 'external',
    noDataMessage: 'Não Existem Dados.',
    pager: {
      display: true,
      perPage: 10,
    },

    add: {
      addButtonContent: '<i class="nb-compose"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
    },

    actions: {
      columnTitle: 'Ações',
      edit: false,
      delete: false,
      add: false,
      position: 'right',
    },
    columns: {},
  };

  @Input() label = '';
  @Input() dialogTitle = 'Selecionar';

  @Input() idForNameField = '';

  nameFieldValue = '';

  @Input() nameFieldValueFunc = (data: any): string => {
    if (data[this.idForNameField] !== undefined) {
      return `${data.id} - ${data[this.idForNameField]}`;
    }
    return 'error';
  };


  @Input() NgModel = null;
  @Output() NgModelChange = new EventEmitter();

  @Input() NgModelId: string = '';
  @Output() NgModelIdChange = new EventEmitter();

  private active_SelectionDialogRef = null;
  @ViewChild('SelectionDialog') myDialog: TemplateRef<any>;

  instName: string = '';

  constructor(
    public dialog: MatDialog,
    public STSS: SmartTableSelectService
  ) {
    this.instName = `smart-table-select_${new Date().toISOString()}_${Math.random().toString()}`;
  }

  ngOnInit() {
    this.STSS.setValue(this.instName, 'state', 'not-selected');

    var self = this;

    this.nstSettings.columns = {
      openColumn: {
        title: 'Selecionar',
        type: 'custom',
        renderComponent: CustomSelectBtnRendererComponent,
        filter: false,
        onComponentInitFunction(instance) {
          instance.selectedRowDataId = self.NgModelId;
          instance.click.subscribe(data => { self.onSelection(data) })
        }
      },
    }

    let tempOtherColuns = Object.entries(this.columns);
    tempOtherColuns.forEach(function (e) {
      self.nstSettings.columns[e[0]] = e[1];
    });
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName == 'NgModelId' || propName == 'source') {
        this.updateState();
      }
    }
  }

  updateState() {
    if (this.NgModelId == '') {
      this.STSS.setValue(this.instName, 'state', 'not-selected');
    } else {
      if (this.source != undefined) {
        this.source.getAll()
          .then(data => {
            for (let d of data) {
              if (d.id == this.NgModelId) {
                this.NgModel = d;
                this.nameFieldValue = this.nameFieldValueFunc(this.NgModel);
                break;
              }
            }
            this.STSS.setValue(this.instName, 'state', 'selected');
          })
      } else {
        this.nameFieldValue = this.nameFieldValueFunc({ id: this.NgModelId, name: '', instName: this.instName });
        this.STSS.setValue(this.instName, 'state', 'selected');
      }
    }
  }

  onSelection(data) {
    if (this.selectViabackEndPromiseResolve) {
      this.selectViabackEndPromiseResolve(data)
    }
    this.NgModelId = data.id;
    this.NgModelIdChange.emit(this.NgModelId);
    this.NgModel = data;
    this.NgModelChange.emit(this.NgModel);
    this.closeDialog();
  }

  initSelect() {
    if (!this.touched)
      this.touched = true;
    this.active_SelectionDialogRef = this.dialog.open(this.myDialog, {
      id: 'SelectionDialogRef_' + new Date().getTime(),
      hasBackdrop: true,
      disableClose: true,
      minWidth: '400px',
      minHeight: '80px',
      autoFocus: true,
    });
  }

  selectViabackEnd(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.selectViabackEndPromiseResolve == null) {
        this.selectViabackEndPromiseResolve = resolve;
        this.initSelect();
      } else {
        reject({ message: 'Já Existe uma seleção em andamento!' })
      }
    })
  }

  closeDialog() {
    this.active_SelectionDialogRef.close();
    this.active_SelectionDialogRef = null;
    this.selectViabackEndPromiseResolve = null;
  }


  @HostListener('window:keydown.esc', ['$event'])
  onEsc() {
    if (this.active_SelectionDialogRef != null)
      this.closeDialog();
  }

}
