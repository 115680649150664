import { AfterViewInit, Component, OnChanges, OnInit, SimpleChange, ViewChild } from '@angular/core';

import { Log, PM_BoardActivity, PM_CardAttachment } from 'app/@firebase';
import { MagicTextEditorComponent, MagicTextUserToMention } from 'app/components';
import { genNotif_cardComment, genNotif_cardCommentMention } from 'app/routes/main/project-management/PM_NOTIFICATIONS';
import { SYS_FILE_GENERIC_TYPE_ICON, SYS_FILE_TYPES_ICONS } from 'app/routes/main/sys/SYS_CONSTS';

import { PmCardModal } from '../../../pm-card-modal';

@Component({
  selector: 'add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class PMCardModalAddNoteComponent implements OnInit, AfterViewInit, OnChanges {

  className = 'PMCardModalAddNoteComponent';

  _noteUsersToMention: MagicTextUserToMention[] = [];

  @ViewChild('noteEditorERef') _noteEditorERef: MagicTextEditorComponent = new MagicTextEditorComponent(this.pmCardModal.afStorage);

  _noteHtml = '';
  _noteText = '';
  _noteUsersMentioned: MagicTextUserToMention[] = [];
  _noteAttachmentsToCreate: PM_CardAttachment[] = [];
  _noteAttachmentsToCreateIconMap: Record<string, { icon: string; pack: string; colorHex: string; }> = {};

  constructor(
    public pmCardModal: PmCardModal,
  ) { }

  ngOnInit() { }
  ngAfterViewInit() { }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }
  ngOnDestroy() { }


  _noteHtmlChange(ev) {
    this._noteHtml = ev;
    this._noteText = this.pmCardModal.utilCtrl.converters.htmltoTextStr(this._noteHtml);
  }
  _noteUsersMentionedChange(ev) {
    this._noteUsersMentioned = ev;
  }
  _cleanNoteEditor() {
    this._noteEditorERef.setData('');
    this._noteText = '';
    this._noteHtml = '';
    this._noteAttachmentsToCreate = [];
    this._noteAttachmentsToCreateIconMap = {};
  }
  _incomingFileToCommentNote(event) {
    this.pmCardModal.uiFeedBackCtrl.presentLoader('Lendo arquivos...')
      .then(() => {

        const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        if (!files || files.length == 0) {
          this.pmCardModal.uiFeedBackCtrl.dismissLoader();
          return;
        }

        let readAsDataURLPromises = [];

        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          // const pattern = /image-*/;
          const reader = new FileReader();
          // if (!file.type.match(pattern)) {
          //   alert('invalid format');
          //   return;
          // }

          // Attachment
          let newAttachment = new PM_CardAttachment();
          newAttachment.id = this.pmCardModal.db.createId();
          newAttachment.cardId = this.pmCardModal.card.id;
          newAttachment.boardId = this.pmCardModal.card.boardId;
          newAttachment.parentKey = this.pmCardModal.board.childsKey;
          newAttachment.createdBy = this.pmCardModal.authService.localUser.uName;

          newAttachment.name = file.name;
          newAttachment.extension = file.name.split('.').pop();
          newAttachment.size = file.size;
          newAttachment.mimeType = file.type;

          this._noteAttachmentsToCreate.push(newAttachment);
          let fileIcon = SYS_FILE_TYPES_ICONS.filter((fi) => { return fi.extensions.includes(newAttachment.extension); })[0]
          if (!fileIcon)
            fileIcon = SYS_FILE_GENERIC_TYPE_ICON;
          this._noteAttachmentsToCreateIconMap[newAttachment.id] = fileIcon;
          // Attachment

          readAsDataURLPromises.push(
            new Promise((resolve, reject) => {
              try {
                const lastIndex = this._noteAttachmentsToCreate.length - 1;
                reader.onload = (file) => {
                  const dataUrl = reader.result.toString();
                  this._noteAttachmentsToCreate[lastIndex].url = dataUrl;
                  resolve(undefined);
                }
                reader.readAsDataURL(file)
              } catch (e) {
                reject(e);
              }
            })
          )
        }

        Promise.all(readAsDataURLPromises)
          .then(() => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
          })
          .catch((e) => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', e);
          })
      });
  }
  _remFileToCommentNote(noteAttachment: PM_CardAttachment) {
    this._noteAttachmentsToCreate = this._noteAttachmentsToCreate.filter((na) => { return na.id != noteAttachment.id; });
  }

  _addNoteActv() {
    if (this._noteText.length > 2) {
      this.pmCardModal.uiFeedBackCtrl.presentLoader('Salvando...')
        .then(() => {

          // Log
          let tempLog = new Log();

          tempLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
          tempLog.className = this.className;
          tempLog.uName = this.pmCardModal.authService.localUser.uName;

          tempLog.type = "AUDIT";
          tempLog.category = "C";

          tempLog.description = this._noteText;

          tempLog.docPath = this.pmCardModal.localDocPath;
          tempLog.docId = this.pmCardModal.card.id;
          // Log

          // Activity
          let tempActivity = new PM_BoardActivity();

          tempActivity.id = this.pmCardModal.db.afs.createId();
          tempActivity.uName = this.pmCardModal.authService.localUser.uName;

          tempActivity.boardId = this.pmCardModal.card.boardId;
          tempActivity.parentKey = this.pmCardModal.board.childsKey;
          tempActivity.cardId = this.pmCardModal.card.id;

          tempActivity.createdBy = this.pmCardModal.authService.localUser.uName;

          tempActivity.type = "C";

          tempActivity.text = this._noteText;
          tempActivity.html = this._noteHtml;
          tempActivity.mentions = this._noteUsersMentioned.map((um) => um.reference);
          tempActivity.attachments = this._noteAttachmentsToCreate.map((a) => a.id);
          // Activity

          // Transaction
          this.pmCardModal.db.runTransaction(transaction => {

            // Log
            transaction.set(
              this.pmCardModal.db.afs.firestore
                .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
              Object.assign({}, tempLog)
            );
            // Log

            // Activity
            transaction.set(
              this.pmCardModal.db.afs.firestore
                .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
              Object.assign({}, tempActivity)
            );
            // Activity

            // Notifications
            // -- subscribers
            this.pmCardModal.card.subscribers
              .filter((uName) => uName != this.pmCardModal.authService.localUser.uName && !tempActivity.mentions.includes(uName))
              .forEach((uName) => {
                let notification = genNotif_cardComment(this.pmCardModal.card, tempActivity, this.pmCardModal.authService.localUser, uName);
                transaction.set(
                  this.pmCardModal.db.afs.firestore
                    .collection(this.pmCardModal.db.COLLECTIONS.sys.notifications).doc(notification.id),
                  Object.assign({}, notification)
                );
              })

            // -- mentions
            tempActivity.mentions
              .filter((uName) => uName != this.pmCardModal.authService.localUser.uName)
              .forEach((uName) => {
                let notification = genNotif_cardCommentMention(this.pmCardModal.card, tempActivity, this.pmCardModal.authService.localUser, uName);
                transaction.set(
                  this.pmCardModal.db.afs.firestore
                    .collection(this.pmCardModal.db.COLLECTIONS.sys.notifications).doc(notification.id),
                  Object.assign({}, notification)
                );
              })
            // Notifications

            // Attachments
            let uploadPromises = [];

            this._noteAttachmentsToCreate
              .forEach(d => {
                uploadPromises.push(
                  new Promise((resolve, reject) => {
                    this.pmCardModal.storage.uploadFileAsDataUrl(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.cardAttachments, d.name, d.url)
                      .then(url => {
                        d.url = url;

                        // Log
                        let tempLog = new Log();

                        tempLog.id = `${this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.cardAttachments}-${this.pmCardModal.db.afs.createId()}`;
                        tempLog.className = this.pmCardModal.className;
                        tempLog.uName = this.pmCardModal.authService.localUser.uName;

                        tempLog.type = "AUDIT";
                        tempLog.category = "CR";

                        tempLog.description = `Anexo adicionado: ${d.name}`;

                        tempLog.docPath = this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.cards;
                        tempLog.docId = this.pmCardModal.card.id;
                        // Log

                        // Attachment
                        transaction.set(
                          this.pmCardModal.db.afs.firestore
                            .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.cardAttachments).doc(d.id),
                          Object.assign({}, d)
                        );
                        // Attachment

                        // Log
                        transaction.set(
                          this.pmCardModal.db.afs.firestore
                            .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
                          Object.assign({}, tempLog)
                        );
                        // Log

                        resolve(undefined);
                      })
                      .catch(e => reject(e))
                  })
                )
              });
            // Attachments

            return Promise.all(uploadPromises)
              .then(() => Promise.resolve())
              .catch(e => Promise.reject(e))

          })
            .then(() => {
              this._cleanNoteEditor();
              this.pmCardModal.activityIdToFocus = tempActivity.id;
              this.pmCardModal.uiFeedBackCtrl.dismissLoader();
            })
            .catch((e) => {
              this.pmCardModal.uiFeedBackCtrl.dismissLoader();
              this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
            })
        })

    } else {
      this.pmCardModal.uiFeedBackCtrl.presentAlert(`Nota inválida!`, `Não é possível criar notas sem texto. Por gentileza atualize o texto de sua nota e tente novamante.`, `warning`, true);
    }
  }
}
