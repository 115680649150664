import { TimerListTabComponent } from "./timer-list-tab.component";
import { TimerListLine } from "./timer-list-tab.types";

export const INPUT_HOUR = {
    min: 0,
    max: 24
};
export const INPUT_MIN = {
    min: 0,
    max: 59
};
export const LINE_TESTS = [
    {
        test: function (_this: TimerListTabComponent, line: TimerListLine): boolean {
            // true if passes
            let pass = true;
            if (line.project.id == '')
                pass = false;
            return pass;
        },
        failText: `Selecionar o Projeto!`
    },
    {
        test: function (_this: TimerListTabComponent, line: TimerListLine): boolean {
            // true if passes
            let pass = true;
            if (line.project.id != '' && line.project.taskControl && line.task.id == '')
                pass = false;
            return pass;
        },
        failText: `Selecionar a Tarefa!`
    },
    {
        test: function (_this: TimerListTabComponent, line: TimerListLine): boolean {
            // true if passes
            let pass = true;
            for (let day of line.days) {
                if (day.h < INPUT_HOUR.min || day.h > INPUT_HOUR.max) {
                    pass = false;
                    break;
                }
            }
            return pass;
        },
        failText: `As horas da linha devem estar entre ${INPUT_HOUR.min} e ${INPUT_HOUR.max}!`
    },
    {
        test: function (_this: TimerListTabComponent, line: TimerListLine): boolean {
            // true if passes
            let pass = true;
            for (let day of line.days) {
                if (!Number.isInteger(day.h)) {
                    pass = false;
                    break;
                }
            }
            return pass;
        },
        failText: `As horas da linha devem estar em numeros inteiros!`
    },
    {
        test: function (_this: TimerListTabComponent, line: TimerListLine): boolean {
            // true if passes
            let pass = true;
            for (let day of line.days) {
                if (!Number.isInteger(day.m)) {
                    pass = false;
                    break;
                }
            }
            return pass;
        },
        failText: `Os minutos da linha devem estar em numeros inteiros!`
    },
    {
        test: function (_this: TimerListTabComponent, line: TimerListLine): boolean {
            // true if passes
            let pass = true;
            for (let day of line.days) {
                if (day.m < INPUT_MIN.min || day.m > INPUT_MIN.max) {
                    pass = false;
                    break;
                }
            }
            return pass;
        },
        failText: `Os minutos da linha devem estar entre ${INPUT_MIN.min} e ${INPUT_MIN.max}!`
    },
    {
        test: function (_this: TimerListTabComponent, line: TimerListLine): boolean {
            // true if passes
            let pass = true;
            for (let day of line.days) {
                if (day.val > INPUT_HOUR.max) {
                    pass = false;
                    break;
                }
            }
            return pass;
        },
        failText: `Valor de tempo inserido em um dos dias ultrapassa ${INPUT_HOUR.max}h!`
    },
    {
        test: function (_this: TimerListTabComponent, line: TimerListLine): boolean {
            // true if passes
            let pass = true;
            let numZeroDays = 0;
            for (let day of line.days) {
                if (day.h == 0 && day.m == 0 || day.h == null && day.m == null || day.h == 0 && day.m == null || day.h == null && day.m == 0)
                    numZeroDays++;
            }
            if (numZeroDays == line.days.length)
                pass = false;
            return pass;
        },
        failText: `Linha com horas vazias!`
    },
];
export const DAY_TESTS = [
    {
        test: function (_this: TimerListTabComponent, dayIndex: number): boolean {
            // true if passes
            let pass = true;
            if (_this.days[dayIndex].val > INPUT_HOUR.max)
                pass = false;
            return pass;
        },
        failText: `Dia com mais de ${INPUT_HOUR.max}h lançadas!`
    },
];