import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { HttpsCallableOptions } from '@firebase/functions-types';
import { first } from 'rxjs';

@Injectable()
export class FireFunctionsService {
    constructor(private aff: AngularFireFunctions) { }
    public httpsCallable = this.aff.httpsCallable;
    public callHttps(name: string, data: any, options?: HttpsCallableOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            return this.aff.httpsCallable(name, options)(data)
                .pipe(first())
                .subscribe(
                    resp => resolve(resp),
                    err => reject(err)
                );
        });
    }
}

