import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';

import { AuthService, UtilService } from 'app/@core';
import { DB_FilterRuleSet, DB_OrderByRule, FirestoreService } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

import { DbFilterRuleUiComponent } from './db-filter-rule-ui/db-filter-rule-ui.component';
import { DbOrderByRuleUiComponent } from './db-order-by-rule-ui/db-order-by-rule-ui.component';

@Component({
  selector: 'db-rules-ui',
  templateUrl: './db-rules-ui.component.html',
  styleUrls: ['./db-rules-ui.component.scss']

})
export class DbRulesUiComponent implements OnInit, OnChanges {

  readonly className = 'DbRulesUiComponent';

  @Input() disabled = false;
  @Output() onChange = new EventEmitter();
  public hasChanges: boolean = false;
  public hasError: boolean = false;

  @Input() edit = false;


  _loader = false;

  // ERefs
  @ViewChild('DbFilterRuleUiERef') dbFilterRuleUiERef: DbFilterRuleUiComponent = new DbFilterRuleUiComponent(this.authService, this.db, this.uiFeedBackCtrl, this.utilCtrl);
  @ViewChild('DbOrderByRuleUiERef') dbOrderByRuleUiERef: DbOrderByRuleUiComponent = new DbOrderByRuleUiComponent(this.authService, this.db, this.uiFeedBackCtrl, this.utilCtrl);
  // ERefs

  constructor(
    private authService: AuthService,
    public db: FirestoreService,
    public uiFeedBackCtrl: UiFeedBackService,
    public utilCtrl: UtilService,
  ) { }

  ngOnInit() { }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }

  public load(dataSourceId: string, ruleSet: DB_FilterRuleSet, orderBy: DB_OrderByRule) {
    this._loader = true;

    this.hasChanges = false;
    this.hasError = false;

    this.db.dataSource.getRuleUiDataById(dataSourceId)
      .then(data => {

        this.dbFilterRuleUiERef.setInternalData(dataSourceId, ruleSet, data);
        this.dbOrderByRuleUiERef.setInternalData(dataSourceId, orderBy, data);

        this._loader = false;
      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }
  _change() {
    this.hasChanges = this.dbFilterRuleUiERef.hasChanges || this.dbOrderByRuleUiERef.hasChanges;
    this.hasError = this.dbFilterRuleUiERef.hasError || this.dbOrderByRuleUiERef.hasError;
    this.onChange.emit();
  }
  getData(): { orderBy: DB_OrderByRule, filter: DB_FilterRuleSet, queryText: string } {
    return {
      orderBy: this.dbOrderByRuleUiERef.getData(),
      filter: this.dbFilterRuleUiERef.getData(),
      queryText: this.dbFilterRuleUiERef.getQueryText()
    };
  }
}
