export interface GeneralThemeVariables {
    bg: string;
    bg2: string;
    bg3: string;
    bg4: string;

    border: string;
    border2: string;
    border3: string;
    border4: string;
    border5: string;

    separator: string;

    fg: string;
    fgHeading: string;
    fgHighlight: string;
    fgText: string;
    fontMain: string;
    fontSecondary: string;

    success: string;
    primary: string;
    warning: string;
    danger: string;
}

export class EchartsTheme {
    bg: string;
    barGap: string;
    barWidth: string;
    fontSize: string;
    fontWeight: string;
    textColor: string;
    axisLineColor: string;
    axisTextColor: string;
    axisFontSize: string;
    splitLineColor: string;
    itemHoverShadowColor: string;
    tooltipBackgroundColor: string;
    areaOpacity: string;
    public constructor(init?: Partial<EchartsTheme>) {
        this.bg = "";
        this.barGap = "";
        this.barWidth = "";
        this.fontSize = "";
        this.fontWeight = "";
        this.textColor = "";
        this.axisLineColor = "";
        this.axisTextColor = "";
        this.axisFontSize = "";
        this.splitLineColor = "";
        this.itemHoverShadowColor = "";
        this.tooltipBackgroundColor = "";
        this.areaOpacity = "";
        if (init)
            Object.assign(this, init);
    }
}

export class HeaderButtonOpt {
    show: boolean;
    disabled: boolean;
    public constructor(init?: Partial<HeaderButtonOpt>) {
        this.show = true;
        this.disabled = false;
        if (init)
            Object.assign(this, init);
    }
}

export class HeaderButtons {
    openNotif: HeaderButtonOpt;
    openTmTimerModal: HeaderButtonOpt;
    public constructor(init?: Partial<HeaderButtons>) {
        this.openNotif = new HeaderButtonOpt();
        this.openTmTimerModal = new HeaderButtonOpt();
        if (init)
            Object.assign(this, init);
    }
}