<div id="{{id}}" class="magic_table" [style.width]="tableWidth">

  <div id="magic_table_loader" class="magic_table_loader" [ngClass]="{'show': loader}"></div>

  <div class="magic_table_controls" *ngIf="showHeader">

    <button *ngIf="canConfig" (click)="showSettings()">
      <nb-icon icon="settings-2-outline" pack="eva"></nb-icon>
    </button>

    <p title="{{id}}">{{title}}</p>

  </div>

  <!-- magic_table_wrapper -->
  <div class="magic_table_wrapper">
    <!-- table_header -->
    <div class="table_header">

      <!-- header_cell -->
      <div class="header_cell" *ngFor="let col of table.cols; trackBy: _trackByFn; let col_i=index"
        title="{{col.title}}" [style.width]="col.width"
        [ngClass]="{'first': col_i == 0,  'last': col_i == table.cols.length - 1}">

        <button *ngIf="filterActiveIndex[col_i]" class="action" title="Limpar Filtro" (click)="cleanFilter(col_i)">
          <nb-icon style="color: blue;" icon="funnel-outline" pack="eva"></nb-icon>
        </button>

        <button class="action" *ngIf="orderByIndex[col_i]" title="Limpar Ordenação"
          (click)="toogleSort(col_i, orderByIndex[col_i])">
          <nb-icon *ngIf="orderByIndex[col_i] =='asc'" style="color: blue;" icon="arrow-upward-outline"
            pack="eva"></nb-icon>

          <nb-icon *ngIf="orderByIndex[col_i] =='desc'" style="color: blue;" icon="arrow-downward-outline"
            pack="eva"></nb-icon>
        </button>

        <p class="col_title">
          {{col.title}}
        </p>

        <button #colOptionsOverlayTrigger class="col-options-btn"
          (click)="openColOptionsOverlay(colOptionsOverlayTrigger, col_i)">
          <nb-icon icon="chevron-down-outline" pack="eva"></nb-icon>
        </button>

      </div>
      <!-- header_cell -->

    </div>
    <!-- table_header -->

    <!-- table_body -->
    <cdk-virtual-scroll-viewport id="{{'magic_table_body:'+id}}" class="table_body" itemSize="30" minBufferPx="450"
      maxBufferPx="600">

      <!-- table_line -->
      <div class="table_line" id="{{id + '_row_index:' + row.number}}" [ngClass]="id + '_row_index:' + rows_i"
        *cdkVirtualFor="let row of filtredRows; trackBy: _trackByFn; let rows_i = index">

        <!-- table_cell -->
        <div *ngFor="let cell of row.cells; trackBy: _trackByFn; let cells_i = index">

          <!-- Types: text or timestamp or boolean -->
          <div
            *ngIf="table.cols[cells_i].type == 'text' || table.cols[cells_i].type == 'timestamp' || table.cols[cells_i].type == 'boolean'"
            title="{{cell}}" class="table_cell" [ngClass]="table.cols[cells_i].fieldName"
            [style.width]="table.cols[cells_i].width">
            <span>{{cell}}</span>
          </div>

          <!-- Types: html -->
          <div *ngIf="table.cols[cells_i].type == 'html'" class="table_cell" [ngClass]="table.cols[cells_i].fieldName"
            [style.width]="table.cols[cells_i].width" [innerHTML]="trustHtml(cell)"></div>

          <!-- Types: href -->
          <div *ngIf="table.cols[cells_i].type == 'href'" class="table_cell" [ngClass]="table.cols[cells_i].fieldName"
            [style.width]="table.cols[cells_i].width">
            <a href="{{row.hrefs[cells_i]}}" target="{{table.cols[cells_i].hrefTarget}}"
              title="{{table.cols[cells_i].hrefTitle}}">
              {{cell}}
            </a>
          </div>

          <!-- Types: progress-bar -->
          <div *ngIf="table.cols[cells_i].type == 'progress-bar'" class="table_cell type_progress_bar"
            [ngClass]="table.cols[cells_i].fieldName" [style.width]="table.cols[cells_i].width">
            <span title="{{cell}}" class="percent_text">{{cell}}</span>
            <div class="progress">
              <span class="progress_bar" [style.width]="cell"></span>
            </div>
          </div>

        </div>
        <!-- table_cell -->

      </div>
      <!-- table_line -->

    </cdk-virtual-scroll-viewport>
    <!-- table_body -->

    <!-- table_footer -->
    <div class="table_footer">
      <span *ngIf="!hasFilterActive()">
        {{table.rows.length}} linha(s)
      </span>
      <span *ngIf="hasFilterActive()">
        <b>{{filtredRows.length}}</b> de {{table.rows.length}} linha(s)
      </span>

      <button class="save_as_excel_btn" [disabled]="table.rows.length == 0" (click)="saveAsExcel()">
        <i class="fa-solid fa-download"></i>
        Excel
      </button>
    </div>
    <!-- table_footer -->
  </div>
  <!-- magic_table_wrapper -->

</div>