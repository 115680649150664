import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbButtonModule, NbCardModule, NbIconModule, NbUserModule } from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { DbTableHighlightDirective } from './db-table-highlight.directive';
import { DbTableComponent } from './db-table.component';

const BASE_MODULES = [
  CommonModule,

  FormsModule,
  ReactiveFormsModule,

  Ng2SmartTableModule,
  MatDialogModule,
  NbCardModule,
  NbButtonModule,
  NbEvaIconsModule,
  NbUserModule,
  NbIconModule,
  NgSelectModule,
];

const COMPONENTS = [
  DbTableComponent,
  DbTableHighlightDirective
];

@NgModule({
  imports: [...BASE_MODULES],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS]
})
export class DbTableModule { }
