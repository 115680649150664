import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbActionsModule, NbButtonModule, NbCardModule, NbIconModule, NbUserModule } from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ThemeModule } from 'app/@theme/theme.module';

import { ReportingHighlightDirective } from './components/reporting-highlight.directive';
import { ReportingColumnsComponent } from './components/reporting-parts/reporting-columns/reporting-columns.component';
import { ReportingPreferencesComponent } from './components/reporting-parts/reporting-preferences/reporting-preferences.component';
import { ReportingService } from './services/report.service';

const BASE_MODULES = [
  CommonModule,

  FormsModule,
  ReactiveFormsModule,

  Ng2SmartTableModule,
  MatDialogModule,
  ThemeModule,
  NbCardModule,
  NbButtonModule,
  NbEvaIconsModule,
  NbUserModule,
  NbIconModule,
  NbActionsModule,
  NgSelectModule,
];

const COMPONENTS = [
  ReportingHighlightDirective,
  ReportingColumnsComponent,
  ReportingPreferencesComponent,
  // MagicControlComponent,
  // MagicControlOptionComponent,
  // MagicTableHighlightDirective,
  // MagicTableSettingsModalComponent
];
const SERVICES = [
  ReportingService
];

@NgModule({
  imports: [...BASE_MODULES],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  providers: [...SERVICES]
})
export class ReportingModule { }

