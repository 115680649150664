import { Component, HostListener, Inject, OnInit, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NbStepperComponent } from '@nebular/theme';
import * as XLSX from 'xlsx';

import { AuthService, UtilService } from 'app/@core';
import { FireStorageService, FirestoreService, getPMCardDueDateStatusData, Log, PM_Board, PM_BoardActivity, PM_BoardLabel, PM_BoardRoute, PM_Card, PM_CARD_PRIORITY_DATA_ARRAY, PM_ToDo, PM_ToDoList, pmCardDueDateStatusDetermination, User } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';
import { MagicTableColumn } from 'app/components';

import { _decodeMultipleToDoListsFromString } from '../../../toDoList/toDoList-utils';

export class CARD_XLSX_LINE {
  title: string;
  description: string;
  route: string;
  priority: string;
  startDate: string;
  dueDate: string;
  done: string;
  doneAt: string;
  assignedTo: string;
  labels: string;
  toDoLists: string;
  public constructor(init?: Partial<CARD_XLSX_LINE>) {
    this.title = '';
    this.description = '';
    this.route = '';
    this.priority = '';
    this.startDate = '';
    this.dueDate = '';
    this.done = '';
    this.doneAt = '';
    this.assignedTo = '';
    this.labels = '';
    this.toDoLists = '';
    if (init)
      Object.assign(this, init);
  }
}

@Component({
  selector: 'app-pm-card-upload-modal',
  templateUrl: './pm-card-upload-modal.html',
  styleUrls: ['./pm-card-upload-modal.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PmCardUploadModal implements OnInit {
  className = 'PmCardUploadModal';
  localDocPath = this.db.COLLECTIONS.projectManagement.kanban.boards;

  @ViewChild("stepper") stepper: NbStepperComponent;

  _loader = false;

  board = new PM_Board();
  boardRoutes: PM_BoardRoute[] = [];
  boardLabels: PM_BoardLabel[] = [];
  boardMembers: User[] = [];

  // animations
  userWorkingLottie = {
    loop: true,
    autoplay: true,
    path: 'assets/animations/user_working.json'
  };
  readingfileLottie = {
    loop: true,
    autoplay: true,
    path: 'assets/animations/reading_file.json'
  };
  loadSheetLottie = {
    loop: true,
    autoplay: true,
    path: 'assets/animations/load_sheet.json'
  };
  uploadFileCloudLottie = {
    loop: true,
    autoplay: true,
    path: 'assets/animations/upload_file_cloud.json'
  };
  // animations

  cards_ArrayBuffer: any;
  cards_file: File;
  cards_file_lines: CARD_XLSX_LINE[] = [];

  newCards: PM_Card[] = [];
  newToDoLists: PM_ToDoList[] = [];
  newToDos: PM_ToDo[] = [];

  cardsTable_data: any[] = [];
  cardsTable_columns: MagicTableColumn[] = [
    {
      title: 'Título',
      fieldName: 'title',
      type: 'text',
      width: '200px',
    },
    {
      title: 'Descrição',
      fieldName: 'description',
      type: 'text',
      width: '200px',
    },
    {
      title: 'Prioridade',
      fieldName: 'priority_str',
      type: 'text',
      width: '200px',
    },
    {
      title: 'Rota',
      fieldName: 'routeName',
      type: 'text',
    },
    {
      title: 'Status do Prazo',
      fieldName: 'dueDateStatus',
      type: 'text',
    },
    {
      title: 'Rótulos',
      fieldName: 'labels_names',
      type: 'text',
      width: '150px',
    },
    {
      title: 'toDos',
      fieldName: 'toDoList_str',
      type: 'text',
    },
    {
      title: 'Designados',
      fieldName: 'assignedToFullNames',
      type: 'text',
      width: '200px'
    },
    {
      title: 'Prazo',
      fieldName: 'dueDate_str',
      type: 'text',
      width: '200px'
    },
    {
      title: 'Entregue',
      fieldName: 'done',
      type: 'boolean',
      width: '100px'
    },
    {
      title: 'Entregue em',
      fieldName: 'doneAt_str',
      type: 'text',
      width: '200px'
    },
  ];
  download_cardsTable_columns: MagicTableColumn[] = [
    {
      title: 'Quadro',
      fieldName: 'boardName',
      width: '150px',
      type: 'href',
      hrefTarget: '_blank',
      hrefTitle: "Abrir Quadro",
      hrefPrepareFunction: (cell, row) => {
        return "#/main/project-management/kanban/open-board?id=" + row.boardId;
      },
    },
    {
      title: 'ID',
      fieldName: 'id',
      width: '100px',
      type: 'href',
      hrefTarget: '_blank',
      hrefTitle: "Abrir Cartão",
      hrefPrepareFunction: (cell, row) => {
        return "#/main/project-management/kanban/open-board?id=" + row.boardId + '&cardIdToOpen=' + row.id;
      },
    },
    {
      title: 'Título',
      fieldName: 'title',
      type: 'text',
      width: '200px',
    },
    {
      title: 'Descrição',
      fieldName: 'description',
      type: 'text',
      width: '200px',
    },
    {
      title: 'Prioridade',
      fieldName: 'priority_str',
      type: 'text',
      width: '200px',
    },
    {
      title: 'Rota',
      fieldName: 'routeName',
      type: 'html',
      valuePrepareFunction: (cell, row) => {
        return `<span style="color:${row['routeHex']}"><b>${cell}</b></span>`;
      }
    },
    {
      title: 'Status do Prazo',
      fieldName: 'dueDateStatus',
      type: 'html',
      valuePrepareFunction: (cell, row) => {
        return `<span style="color:${row['dueDateStatusHex']}"><b>${cell}</b></span>`;
      }
    },
    {
      title: 'Rótulos',
      fieldName: 'labels_names',
      type: 'text',
      width: '150px',
    },
    {
      title: 'toDos',
      fieldName: 'toDoList_str',
      type: 'text',
    },
    {
      title: 'Designados',
      fieldName: 'assignedToFullNames',
      type: 'text',
      width: '200px'
    },
    {
      title: 'Prazo',
      fieldName: 'dueDate_str',
      type: 'text',
      width: '200px'
    },
    {
      title: 'Entregue',
      fieldName: 'done',
      type: 'boolean',
      width: '100px'
    },
    {
      title: 'Entregue em',
      fieldName: 'doneAt_str',
      type: 'text',
      width: '200px'
    },
  ];

  erros: { line: number, text: string }[] = [];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {},
    private mdDialogRef: MatDialogRef<PmCardUploadModal>,
    public db: FirestoreService,
    private afStorage: AngularFireStorage,
    public storage: FireStorageService,
    public authService: AuthService,
    public utilCtrl: UtilService,
    public uiFeedBackCtrl: UiFeedBackService,
    private _sanitizer: DomSanitizer
  ) {
    if (data) {
      if (data['board']) {
        this.board = new PM_Board(data['board']);
      }

      if (data['boardRoutes']) {
        this.boardRoutes = data['boardRoutes'];
      }

      if (data['boardLabels'])
        this.boardLabels = data['boardLabels'];

      if (data['boardMembers'])
        this.boardMembers = data['boardMembers'];
    }
  }

  ngOnInit() { }
  ngOnDestroy() { }
  load() { }

  safeClose() {
    if (this.cards_file_lines.length > 0 && this.stepper.selectedIndex <= 4) {
      this.uiFeedBackCtrl.presentCustonAlert({
        title: 'Sair Sem Salvar?',
        message: `Atenção: Voce possui mudanças não salvas. Presione Cancelar para voltar e salvar estas mudanças, ou OK para sair sem salvar.`,
        buttons: [
          {
            text: 'Cancelar',
            icon: "close-outline",
            status: "primary",
          },
          {
            needFormValid: true,
            text: 'Sair',
            icon: "log-out",
            status: "danger",
            handler: () => {
              this._close();
            }
          }
        ]
      })
    } else
      this._close();
  }
  _close(data?) {
    this.mdDialogRef.close(data)
  }


  // Host Listeners
  @HostListener('window:keydown.esc', ['$event'])
  onEsc() {
    this.safeClose();
  }
  // Host Listeners


  // --------------- Ui Rotines
  _trackByFn(index, item) {
    return index;
  }
  _trustHtml(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }
  // --------------- Ui Rotines


  incoming_cards_file(event) {
    this.cards_file = event.target.files[0];
  }
  load_cards_file() {
    this._loader = true;
    this.uiFeedBackCtrl.presentLoader(`Lendo Arquivo...`)
      .then(() => {

        this.cards_file_lines = [];
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          this.cards_ArrayBuffer = fileReader.result;
          var data = new Uint8Array(this.cards_ArrayBuffer);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, { type: "binary" });
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          let tempJson = XLSX.utils.sheet_to_json(worksheet, { raw: true });

          console.log(`JSON INICIAL LIDO DO ARQUIVO XLSL:`)
          console.log(tempJson);

          for (let i = 0; i < tempJson.length; i++) {
            this.cards_file_lines.push(tempJson[i] as CARD_XLSX_LINE)
          }

          console.log(`VETOR TEMPORARIO INTERPRETADO DO ARQUIVO XLSL:`);
          console.log(this.cards_file_lines);

          this.uiFeedBackCtrl.dismissLoader()
          this._loader = false;
        }
        fileReader.readAsArrayBuffer(this.cards_file);

      });
  }
  _checkFieldValue(fieldValue: string): boolean {
    if (fieldValue === "X") {
      return true;
    } else if (fieldValue === "") {
      return false;
    } else {
      return null;
    }
  }
  generate_cards() {
    this._loader = true;
    this.uiFeedBackCtrl.presentLoader(`Lendo Arquivo...`)
      .then(() => {

        let tempCards: PM_Card[] = [];
        this.erros = [];

        for (let line_index = 0; line_index < this.cards_file_lines.length; line_index++) {
          const lineData = new CARD_XLSX_LINE(this.cards_file_lines[line_index]);

          let tempCard = new PM_Card();

          tempCard.id = `${tempCards.length + 1}`;

          // title
          if (lineData.title != "")
            tempCard.title = lineData.title;
          else
            this.erros.push({ line: line_index + 2, text: `Titulo do cartão Vazio!` })
          // title

          tempCard.description = lineData.description;

          tempCard.boardId = this.board.id;
          tempCard.parentKey = this.board.childsKey;
          tempCard.createdBy = this.authService.localUser.uName;

          // route
          let route = this.boardRoutes.find((r) => { return r.name == lineData.route })
          if (route)
            tempCard.routeId = route.id;
          else
            this.erros.push({ line: line_index + 2, text: `Rota: "${lineData.route}" não existe!` })
          // route

          // priority
          if ([null, "", "P1", "P2", "P3", "P4", "P5"].includes(lineData.priority))
            tempCard.priority = lineData.priority as any;
          else
            this.erros.push({ line: line_index + 2, text: `Prioridade: "${lineData.priority}" Inválida!` })
          // priority

          // startDate
          if (lineData.startDate) {
            let startDate = new Date(lineData.dueDate);
            if (startDate != null) {
              let startDateTimeStr = this.utilCtrl.date.getDateTimeString(startDate);
              tempCard.startDate = this.utilCtrl.timestamp.fromDate(startDate);
              tempCard.startDateString = startDateTimeStr.split('T')[0];
              tempCard.startDateHourString = startDateTimeStr.split('T')[1];
            } else
              this.erros.push({ line: line_index + 2, text: `Data de início do cartão: "${lineData.startDate}" Inválida!` })
          } else if (this.board.autoSetStartDate) {
            let startDateData = this.utilCtrl.gets.getDateAndClosestTime(this.utilCtrl.timestamp.toDate(tempCard.createdOn))
            tempCard.startDate = this.utilCtrl.timestamp.fromDate(startDateData.closestDateTime);
            tempCard.startDateString = startDateData.formattedDate;
            tempCard.startDateHourString = startDateData.closestTime;
          }
          // startDate

          // dueDate
          if (lineData.dueDate) {
            let dueDate = new Date(lineData.dueDate);
            if (dueDate != null) {
              let dueDateTimeStr = this.utilCtrl.date.getDateTimeString(dueDate);
              tempCard.dueDate = this.utilCtrl.timestamp.fromDate(dueDate);
              tempCard.dueDateString = dueDateTimeStr.split('T')[0];
              tempCard.dueDateHourString = dueDateTimeStr.split('T')[1];
            } else
              this.erros.push({ line: line_index + 2, text: `Data de vencimento do cartão: "${lineData.dueDate}" Inválida!` })
          }
          // dueDate

          // done
          let done = this._checkFieldValue(lineData.done)
          if (done != null)
            tempCard.done = done;
          else
            this.erros.push({ line: line_index + 2, text: `Indicador se a data de vencimento foi cumprida: "${lineData.done}" Inválida!` })
          // done

          // doneAt
          if (lineData.doneAt) {
            let doneAt = new Date(lineData.doneAt)
            if (doneAt != null) {
              let doneAtTimeStr = this.utilCtrl.date.getDateTimeString(doneAt);
              tempCard.doneAt = this.utilCtrl.timestamp.fromDate(doneAt);
              tempCard.doneAtString = doneAtTimeStr.split('T')[0];
              tempCard.doneAtHourString = doneAtTimeStr.split('T')[1];
            } else
              this.erros.push({ line: line_index + 2, text: `Data em que o cartão foi concluído: "${lineData.doneAt}" Inválida!` })
          }
          // doneAt

          // assignedTo
          let rawAssignedTo = lineData.assignedTo.split(';');
          if (rawAssignedTo)
            rawAssignedTo = rawAssignedTo.filter((a) => { return a.length > 0; });
          if (rawAssignedTo && rawAssignedTo.length > 0) {
            let boardMembersUnames = this.boardMembers.map((u) => u.uName);
            let invalidAssignedTo = rawAssignedTo.filter((a) => { return !boardMembersUnames.includes(a) })

            if (invalidAssignedTo.length > 0)
              invalidAssignedTo.forEach((a) => {
                this.erros.push({ line: line_index + 2, text: `Usuários atribuídos ao cartão: "${a}" Inválido!` })
              })
            else
              tempCard.assignedTo = rawAssignedTo;
          }
          // assignedTo

          // labels
          let rawLabels = lineData.labels.split(';');
          if (rawLabels)
            rawLabels = rawLabels.filter((l) => { return l.length > 0; });
          if (rawLabels && rawLabels.length > 0) {
            let boardLabelsNames = this.boardLabels.map((l) => l.name);
            let invalidLabels = rawLabels.filter((a) => { return !boardLabelsNames.includes(a) })

            if (invalidLabels.length > 0)
              invalidLabels.forEach((a) => {
                this.erros.push({ line: line_index + 2, text: `Rótulos do cartão: "${a}" Inválido!` })
              })
            else
              tempCard.labelsIds = rawLabels.map((labelName) => this.boardLabels.find((l) => l.name == labelName).id);
          }
          // labels

          // toDoLists
          if (lineData.toDoLists)
            try {
              let data = _decodeMultipleToDoListsFromString(this.utilCtrl, lineData.toDoLists);
              let membersToAddToCard: string[] = [];

              data.toDoLists
                .forEach((toDoList) => {
                  toDoList.boardId = tempCard.boardId;
                  toDoList.parentKey = tempCard.parentKey;
                  toDoList.createdBy = this.authService.localUser.uName;

                  tempCard.toDoListsIds.push(toDoList.id);
                  this.newToDoLists.push(toDoList);
                });

              data.toDos
                .forEach((toDo) => {
                  toDo.boardId = tempCard.boardId;
                  toDo.parentKey = tempCard.parentKey;
                  toDo.createdBy = this.authService.localUser.uName;

                  // autoSetStartDate
                  if (!toDo.startDate && this.board.autoSetStartDate) {
                    let startDateData = this.utilCtrl.gets.getDateAndClosestTime(this.utilCtrl.timestamp.toDate(toDo.createdOn));
                    toDo.startDate = this.utilCtrl.timestamp.fromDate(startDateData.closestDateTime);
                    toDo.startDateString = startDateData.formattedDate;
                    toDo.startDateHourString = startDateData.closestTime;
                  }

                  // assignedTo validation
                  const validMember = this.boardMembers.find((m) => m.uName == toDo.assignedTo) ? true : false;
                  if (validMember && !tempCard.assignedTo.includes(toDo.assignedTo) && !membersToAddToCard.includes(toDo.assignedTo))
                    membersToAddToCard.push(toDo.assignedTo);
                  if (!validMember)
                    toDo.assignedTo = '';

                  this.newToDos.push(toDo);
                });

              membersToAddToCard
                .forEach((uName) => {
                  let newMember = this.boardMembers.find((m) => m.uName == uName);
                  if (newMember)
                    tempCard.assignedTo.push(newMember.uName);
                });
            } catch (e) {
              this.erros.push({ line: line_index + 2, text: e })
            }
          // toDoLists

          tempCard.subscribers = [...tempCard.assignedTo, tempCard.createdBy]
            .filter((elem, index, self) => {
              return index == self.indexOf(elem);
            });

          tempCards.push(tempCard);
        }

        console.log(`newCards`, tempCards);

        this.newCards = tempCards;
        this.cardsTable_data = tempCards.map((c) => { return this.card_dataMaker(c) });

        this.uiFeedBackCtrl.dismissLoader()
        this._loader = false;
      });
  }
  private card_dataMaker(card: PM_Card): any {
    let tempObj = JSON.parse(JSON.stringify(card)) as PM_Card;
    let timeNowMs = new Date().getTime();

    tempObj['boardName'] = this.board.name;

    let route = this.boardRoutes.find((r) => { return r.id == card.routeId })
    tempObj['routeName'] = route ? route.name : "";
    tempObj['routeHex'] = route ? route.hex : "";

    tempObj['assignedToFullNames'] = tempObj.assignedTo.map((uName) => {
      let m = this.boardMembers.find((u) => u.uName == uName);
      return m.fullName;
    });

    tempObj['labels_names'] = tempObj.labelsIds.map((labelId) => {
      let l = this.boardLabels.find((l) => l.id == labelId);
      return l.name;
    });

    let p = PM_CARD_PRIORITY_DATA_ARRAY.find((p) => p.id == card.priority);
    tempObj["priority_str"] = p ? p.id ? `${p.id} - ${p.name}` : `${p.name}` : "";

    let tempDueDateStatus = pmCardDueDateStatusDetermination(card);
    let _dueDateStatus = getPMCardDueDateStatusData(tempDueDateStatus);
    tempObj["dueDateStatus"] = _dueDateStatus.name;
    tempObj["dueDateStatusHex"] = _dueDateStatus.background_color;

    // To Do Lists
    let toDoList_str = '';
    const cardToDos = this.newToDos.filter((toDo) => { return toDo.cardId == card.id; });
    if (cardToDos.length > 0)
      toDoList_str = `${cardToDos.filter((toDo) => { return toDo.done; }).length}/${cardToDos.length}`;
    tempObj['toDoList_str'] = toDoList_str;
    // To Do Lists

    tempObj['status_str'] = this.db.DICTIONARY.translator(tempObj.status, this.db.DICTIONARY.general.status);

    tempObj['dueDate_str'] = this.utilCtrl.timestamp.toLocalDate(tempObj.dueDate);
    tempObj['doneAt_str'] = this.utilCtrl.timestamp.toLocalDate(tempObj.doneAt);

    tempObj['createdOn_str'] = this.utilCtrl.timestamp.toLocalDate(tempObj.createdOn);
    tempObj['updatedOn_str'] = this.utilCtrl.timestamp.toLocalDate(tempObj.updatedOn);
    return tempObj;
  }
  save_cards() {
    this._loader = true;
    this.uiFeedBackCtrl.presentLoader(`Salvando...`)
      .then(() => {
        const now = this.utilCtrl.timestamp.now();

        const batches = this.utilCtrl.arrays.chunk(this.newCards, 50)
          .map(cards => {

            let transactionId = `T-ID-${this.db.createTimeId()}`;
            return this.db.runTransaction(transaction => {

              let batchesPromises = [];

              cards.forEach(card => {
                batchesPromises.push(
                  new Promise((resolve, reject) => {
                    const route = this.boardRoutes.find((r) => { return r.id == card.routeId })
                    let newToDosLists = this.newToDoLists.filter((toDoLists) => { return toDoLists.cardId == card.id })
                    let newToDos = this.newToDos.filter((toDo) => { return toDo.cardId == card.id })

                    // Card
                    return this.db.projectManagement.kanban.cards.createWithSerial(card, transaction, transactionId)
                      .then(id => {

                        card.id = id;

                        // Route
                        transaction.update(
                          this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.projectManagement.kanban.routes).doc(card.routeId),
                          Object.assign({},
                            {
                              cards: this.db.fieldValue.arrayUnion(card.id) as any,

                              updatedBy: this.authService.localUser.uName,
                              updatedOn: now
                            }
                          )
                        );
                        // Route

                        // ToDoLists
                        newToDosLists
                          .forEach((newToDoList) => {
                            newToDoList.cardId = card.id;
                            transaction.set(
                              this.db.afs.firestore
                                .collection(this.db.COLLECTIONS.projectManagement.toDoLists).doc(newToDoList.id),
                              Object.assign({}, newToDoList)
                            );
                          })
                        // ToDoLists


                        // toDos
                        newToDos
                          .forEach((newToDo) => {
                            newToDo.cardId = card.id;
                            transaction.set(
                              this.db.afs.firestore
                                .collection(this.db.COLLECTIONS.projectManagement.toDos).doc(newToDo.id),
                              Object.assign({}, newToDo)
                            );
                          })
                        // toDos

                        // Log
                        let tempLog = new Log();

                        tempLog.id = `${this.localDocPath}-${this.db.afs.createId()}`;
                        tempLog.className = this.className;
                        tempLog.uName = this.authService.localUser.uName;

                        tempLog.type = "AUDIT";
                        tempLog.category = "CR";

                        tempLog.docPath = this.db.COLLECTIONS.projectManagement.kanban.cards;
                        tempLog.docId = card.id;

                        tempLog.description = `Cartão Criado via carga: ${card.id} - ${card.title}`;
                        // Log

                        // Activity
                        let tempActivity = new PM_BoardActivity();

                        tempActivity.id = this.db.afs.createId();
                        tempActivity.uName = this.authService.localUser.uName;
                        tempActivity.cardId = card.id;
                        tempActivity.boardId = card.boardId;

                        tempActivity.type = "CR";

                        tempActivity.text = `Cartão Criado via carga: ${card.id} - ${card.title} em ${route.name}`;
                        tempActivity.html = `<p>Cartão Criado via carga: ${card.id} - ${card.title} em ${route.name}</p>`;
                        // Activity

                        // Log
                        transaction.set(
                          this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.sys.logs).doc(tempLog.id),
                          Object.assign({}, tempLog)
                        );
                        // Log

                        // Activity
                        transaction.set(
                          this.db.afs.firestore
                            .collection(this.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
                          Object.assign({}, tempActivity)
                        );
                        // Activity

                        resolve(null);
                      })
                      .catch(e => reject(e))
                    // Card
                  })
                )
              });

              return Promise.all(batchesPromises)
                .then(() => Promise.resolve())
                .catch(e => Promise.reject(e))

            });
          });

        Promise.all(batches)
          .then(() => {

            console.log(`newCards: `, this.newCards);
            this.cardsTable_data = this.newCards.map((c) => { return this.card_dataMaker(c) });


            this.stepper.next();
            this._loader = false;

            this.uiFeedBackCtrl.dismissLoader();
            this.uiFeedBackCtrl.presentAlert(`Cartões salvos com sucesso!`, ``, `success`);
          })
          .catch((e) => {
            this._loader = false;
            this.uiFeedBackCtrl.dismissLoader();
            this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
          })
      });
  }
}
