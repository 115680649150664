import { Injectable } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';

@Injectable()
export class NgSelectConfigService {
  constructor(private config: NgSelectConfig) {

    // NgSelect global configuration
    this.config.addTagText = "Adicionar item";
    this.config.appearance = "underline";
    this.config.clearAllText = "Limpar";
    this.config.disableVirtualScroll = true;
    this.config.loadingText = "Carregando...";
    this.config.notFoundText = "Nenhum item encontrado";
    this.config.openOnEnter = true;
    this.config.typeToSearchText = "Pesquisar";
    this.config.appendTo = "body";
    // NgSelect global configuration

  }
}
