import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SvgService {
    // Cache com a lista de requisições realizadas
    private requests = new Map<string, Promise<string | null>>();

    getSvgContent(url: string): Observable<string | null> {
        // Verificamos se já fizemos uma requisição para essa url
        let req = this.requests.get(url);

        if (!req) {
            // Ainda não temos a requisição, então vamos criar uma
            req = fetch(url)
                .then(response => {
                    if (response.ok) {
                        return response.text(); // Retorna o conteúdo SVG como texto
                    }
                    throw new Error('Failed to load SVG');
                })
                .catch((error) => {
                    console.error('Error fetching SVG:', error);
                    return null; // Retorna null em caso de erro
                });

            // Armazena a requisição para fazer o cache dela na lista
            this.requests.set(url, req);
        }

        // Retorna um observable com a requisição do cache/criada
        return from(req).pipe(
            map(svgContent => svgContent), // Mapeia o conteúdo do SVG
            catchError(err => {
                console.error('Error in observable:', err);
                return from(Promise.resolve(null)); // Retorna null em caso de erro no observable
            })
        );
    }
}