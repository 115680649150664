import { CapitalizePipe } from "./capitalize.pipe";
import { NumberWithCommasPipe } from "./number-with-commas.pipe";
import { PluralPipe } from "./plural.pipe";
import { RoundPipe } from "./round.pipe";
import { TimingPipe } from "./timing.pipe";

export const PIPES = [
    CapitalizePipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
    NumberWithCommasPipe,
];