import { ReportingUiFilterMode } from "./report.types";

/** Valor necessario para utilizar comparação de vetores vazios */
export const REPORTING_EMPTY_ARRAY_DATA_VALUE = "IC-_EMPTY_ARRAY_-IC";
export const REPORTING_EMPTY_ARRAY_DISP_VALUE = "(Vazias)";

export const REPORTING_EMPTY_FIELD_DISP_VALUE = "(Vazias)";

export const REPORTING_REPORT_LINK_URL_PARAMETER_NAME = "reportPreferenceId";


export const REPORTING_FILTERS_OPERATORS: ReportingUiFilterMode[] = [
    {
        id: "==",
        array: false,
        selectValues: true,
        selectSingle: false,
        title: "igual á"
    },
    {
        id: "!=",
        array: false,
        selectValues: true,
        selectSingle: false,
        title: "diferente á"
    },



    {
        id: "array-contains-any",
        array: true,
        selectValues: true,
        selectSingle: false,
        title: "Contém qualquer"
    },
    {
        id: "array-contains",
        array: true,
        selectValues: true,
        selectSingle: true,
        title: "Contém"
    },
] 