<div class="week-input">

  <!-- ic-form-control -->
  <div class="ic-form-control trigger" [ngClass]="{'disabled':disabled }" (click)="trigger()">
    <div class="text">
      Semana {{showWeek()}}, {{showYear()}}
    </div>
    <div class="icon">
      <nb-icon icon="calendar-outline" pack="eva"></nb-icon>
    </div>
  </div>
  <!-- ic-form-control -->

  <!-- popdown -->
  <div class="popdown" *ngIf="showSelect">
    <div class="popdownContent" #popdownContent>
      <div class="header">
        <div class="buttons">
          <button (click)="popdown_monthBack()">
            <nb-icon icon="arrow-upward-outline" pack="eva"></nb-icon>
          </button>
          <button (click)="popdown_monthAdvance()">
            <nb-icon icon="arrow-downward-outline" pack="eva"></nb-icon>
          </button>
        </div>
        <div class="inputs">
          <select class="input_Month input ic-form-control" style="width: 95px;" id="input_Month" name="input_Month"
            [(ngModel)]="month" (ngModelChange)="popdown_updateMonth()">
            <option *ngFor="let m of months" [ngValue]="m.id">{{m.name}}</option>
          </select>
          <input class="input ic-form-control" style="width: 60px;margin-left: 4px;" type="number" id="input_Year"
            name="input_Year" [(ngModel)]="year" min="1501" (ngModelChange)="popdown_updateYear()">
        </div>
      </div>

      <div class="week-table">

        <div class="thead">
          <div class="th week-name">Semana</div>
          <div class="th week-day" *ngFor="let wd of weekDays; let i_wd=index">
            {{wd}}
          </div>
        </div>

        <div class="tbody">
          <div class="tr" *ngFor="let week4Sel of weeksForSelection; let i_week4Sel=index"
            [ngClass]="{'selected': week4Sel.number == week }" (click)="popdown_select(week4Sel)">

            <div class="td week-name">
              {{week4Sel.number}}
            </div>

            <div class="td week-day" *ngFor="let wd of weekDays; let i_wd=index"
              [ngClass]="{ 'other-month': week4Sel.days[i_wd]?.month != month && month > 0, 'today': week4Sel.days[i_wd] && isToday(week4Sel.days[i_wd].day, week4Sel.days[i_wd].month, year)}">
              {{ week4Sel.days[i_wd]?.day || '' }}
            </div>

          </div>
        </div>

      </div>

      <div class="footer">
        <button class="useActualWeek" (click)="popdown_useActualWeek()">Esta Semana</button>
      </div>
    </div>
  </div>
  <!-- popdown -->

</div>