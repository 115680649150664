import firebase from "firebase/compat/app";

export class TM_TimerUserConfig {
  showSaveDoneAlert: boolean;
  showWeekend: boolean;
  weeklyGoal: {
    active: boolean;
    days: {
      h: number;
      m: number;
      time: string;
      val: number;
    }[];
    value: number;
  };
  public constructor(init?: Partial<TM_TimerUserConfig>) {
    this.showSaveDoneAlert = true;
    this.showWeekend = false;
    this.weeklyGoal = {
      active: false,
      days: [
        { h: 0, m: 0, time: '00:00:00', val: 0 },
        { h: 0, m: 0, time: '00:00:00', val: 0 },
        { h: 0, m: 0, time: '00:00:00', val: 0 },
        { h: 0, m: 0, time: '00:00:00', val: 0 },
        { h: 0, m: 0, time: '00:00:00', val: 0 },
        { h: 0, m: 0, time: '00:00:00', val: 0 },
        { h: 0, m: 0, time: '00:00:00', val: 0 },
      ],
      value: 0
    };
    if (init)
      Object.assign(this, init);
  }
};
export class MagicTableSettings {
  selectedColumns: string[] = [];
  public constructor(init?: Partial<MagicTableSettings>) {
    this.selectedColumns = [];
    if (init)
      Object.assign(this, init);
  }
}

export type UserStatus = 0 | 1;
export type PasswordStatus = 'initial' | 'productive' | 'expired';

export class User {

  id: string;
  uName: string;

  // Documentation
  respUName: string;
  description: string;
  documentation: string;

  // Data
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  jobTitle: string;
  department: string;
  picture: string;
  thumbnail: string;
  companyId: string;
  businessPlaceId: string;
  phone: string;

  // System Data
  status: UserStatus;
  passwordStatus: PasswordStatus;
  passwordDate: firebase.firestore.Timestamp;
  validFrom: string;
  validTo: string;
  parameters: object;
  apiToken: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<User>) {

    this.id = '';
    this.uName = '';

    // Documentation
    this.respUName = '';
    this.description = '';
    this.documentation = '';

    // Data
    this.firstName = '';
    this.lastName = '';
    this.fullName = '';
    this.email = '';
    this.jobTitle = '';
    this.department = '';
    this.picture = '';
    this.thumbnail = '';
    this.companyId = '';
    this.businessPlaceId = '';
    this.phone = '';

    // System Data
    this.status = 1;
    this.passwordStatus = 'initial';
    this.passwordDate = firebase.firestore.Timestamp.now();
    this.validFrom = '';
    this.validTo = '';
    this.parameters = {};
    this.apiToken = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
