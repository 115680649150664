<div class="ic-overlay db-table-row-options-overlay">
  <div class="ic-overlay-loader" [ngClass]="{'show': loader}"></div>

  <div class="ic-overlay-body">
    <ul>
      <li *ngIf="dbTableComponent.canMoveItems && row_number > 1">
        <button (click)="moveItem_rowUp(row_number)">
          <nb-icon icon="arrow-upward" pack="eva"></nb-icon>
          Mover p/ cima
        </button>
      </li>
      <li *ngIf="dbTableComponent.canMoveItems && row_number < dbTableComponent.table.rows.length">
        <button (click)="moveItem_rowDown(row_number)">
          <nb-icon icon="arrow-downward" pack="eva"></nb-icon>
          Mover p/ baixo
        </button>
      </li>
      <li *ngIf="dbTableComponent.canDuplicate">
        <button (click)="duplicateRow(row_number)">
          <nb-icon icon="copy-outline" pack="eva"></nb-icon>
          Duplicar
        </button>
      </li>
      <li>
        <button [disabled]="!dbTableComponent.canDelete" (click)="deleteRow(row_number)">
          <nb-icon style="color:red" icon="trash-2-outline" pack="eva"></nb-icon>
          Deletar
        </button>
      </li>
    </ul>
  </div>
</div>