import firebase from "firebase/compat/app";

export class WorkDay {
  begin: string;
  end: string;
  work: boolean;
  public constructor(init?: Partial<WorkDay>) {
    this.begin = '00:00:00';
    this.end = '23:59:59';
    this.work = true;
    if (init)
      Object.assign(this, init);
  }
}

export class Holiday {
  from: string;
  to: string;
  title: string;
  public constructor(init?: Partial<Holiday>) {
    this.from = '00:00:00';
    this.to = '23:59:59';
    this.title = '';
    if (init)
      Object.assign(this, init);
  }
}

export class OS_Calendar {

  id: string;
  status: 0 | 1;
  name: string;

  officeHours: {};
  holidays: Holiday[];

  validFrom: string;
  validTo: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<OS_Calendar>) {

    this.id = '';
    this.status = 0;
    this.name = '';

    this.officeHours =
    {
      0: new WorkDay(),
      1: new WorkDay(),
      2: new WorkDay(),
      3: new WorkDay(),
      4: new WorkDay(),
      5: new WorkDay(),
      6: new WorkDay()
    };

    this.holidays = [];

    this.validFrom = '';
    this.validTo = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
