import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { AuthService } from 'app/@core/services/auth.service';
import { MagicTableSettings } from 'app/@firebase';
import { OverlayService, UiFeedBackService } from 'app/@theme';
import { environment } from 'environments/environment';

import { MagicTableSettingsModalComponent } from './components/modals/magic-table-settings-modal/magic-table-settings-modal.component';
import { MagicTableColOptionsOverlayComponent } from './components/overlays/magic-table-col-options-overlay/magic-table-col-options-overlay.component';
import { MagicTable, MagicTableColumn, MagicTableRowData, OrderDirection, UiFilterIndex } from './magic-table.types';
import { MAGIC_TABLE_CONSTS } from './shared/MAGIC_TABLE_CONSTS';
import { magicTableCellDataMaker } from './shared/magic-table-cell-data-maker';
import { magicTableCleanFilter } from './shared/magic-table-clean-filter';
import { magicTableInitFilterIndexes, magicTableUpdateFiltredRows } from './shared/magic-table-filter';
import { magicTableSaveAsExcel } from './shared/magic-table-save-as-excel';
import { magicTableToogleSort } from './shared/magic-table-toogle-sort';

@Component({
  selector: 'magic-table',
  templateUrl: './magic-table.component.html',
  styleUrls: ['./magic-table.component.scss']
})
export class MagicTableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() id: string = '';
  @Input() title: string = '';
  @Input() loader = false;

  @Input() showHeader = true;
  @Input() canConfig = true;

  @Input() data: any[] = [];
  @Input() itensPerPage: number = 10;
  @Input() columns: MagicTableColumn[] = [];

  className = `MagicTableComponent`;
  table: MagicTable = new MagicTable();
  tableWidth = '';

  filterActiveIndex: boolean[] = [];
  orderByIndex: OrderDirection[] = [];
  filterIndexes: UiFilterIndex[] = [];
  filtredRows: MagicTableRowData[] = [];

  cleanedColumns: MagicTableColumn[] = [];
  tableSettings: MagicTableSettings = new MagicTableSettings();

  constructor(
    private _sanitizer: DomSanitizer,
    public authService: AuthService,
    public uiFeedBackCtrl: UiFeedBackService,
    public overlayCtrl: OverlayService,
  ) { }
  ngOnInit() {
    if (this.id)
      this.load();
    else
      console.error('[MagicTableComponent] table id not defined!');
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    for (const propName in changes)
      if (propName == 'data')
        this.load();
  }
  ngOnDestroy() { }

  load() {
    this.loader = true;

    let tempCols: MagicTableColumn[] = [];
    for (let col_index = 0; col_index < this.columns.length; col_index++)
      tempCols.push(new MagicTableColumn(this.columns[col_index]));

    this.cleanedColumns = tempCols;

    this.initTableSettings()
      .then(() => {
        this.initTableData();

        this.loader = false;
      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }
  private _logger(message?: any, ...optionalParams: any[]) {
    if (!environment.production)
      console.log(message, ...optionalParams);
  }
  // ---------------------- GLOBAL
  /**
   * Inicia a tabela apartir do vetor de dados fornecido
   */
  private initTableData() {
    this.table.cols = this.tableColsMaker(this.tableSettings.selectedColumns);

    this.tableWidth = '';
    if (this.table.cols.length > 0) {
      this.tableWidth = 'calc(';
      for (let index = 0; index < this.table.cols.length; index++) {
        const col = this.table.cols[index];
        this.tableWidth += col.width;
        if (index < this.table.cols.length - 1)
          this.tableWidth += ' + ';
        else
          this.tableWidth += ')';
      }
    }

    this.table.rows = this.tableRowsMaker(this.data);
    magicTableInitFilterIndexes(this);
    this._logger(`MagicTable: ${this.id} ->`, " Table", this.table, " FilterIndexes", this.filterIndexes);
    magicTableUpdateFiltredRows(this);
  }
  private tableColsMaker(selectedColumnsTitles: string[]): MagicTableColumn[] {
    let tempCols: MagicTableColumn[] = [];
    for (let colTitle of selectedColumnsTitles) {
      let tempCol = this.cleanedColumns.find((col) => col.title == colTitle);
      if (tempCol)
        tempCols.push(tempCol);
    }
    return [...tempCols];
  }
  private tableRowsMaker(data: any[]): MagicTableRowData[] {
    let tempRows: MagicTableRowData[] = [];
    for (let obj of data) {

      // row maker
      let row = new MagicTableRowData();
      row.obj = obj;
      for (let col_index = 0; col_index < this.table.cols.length; col_index++) {
        const col = this.table.cols[col_index];

        // Value Prepare
        if (col.valuePrepareFunction)
          row.obj[col.fieldName] = col.valuePrepareFunction(row.obj[col.fieldName], row.obj);

        let fieldData = row.obj[col.fieldName];

        // row.data
        if (Array.isArray(fieldData) && fieldData.length == 0)
          row.data.push(MAGIC_TABLE_CONSTS.EMPTY_ARRAY_DATA_VALUE);
        else
          row.data.push(fieldData);

        // row.cells
        row.cells.push(magicTableCellDataMaker(col, fieldData));

        // row.hrefs
        let href = undefined;
        if (col.hrefPrepareFunction)
          href = col.hrefPrepareFunction(fieldData, obj) + '';
        row.hrefs.push(href);
      }
      // row maker

      tempRows.push(row);
    }
    return [...tempRows];
  }
  // ---------------------- GLOBAL

  hasFilterActive(): boolean {
    for (const isActive of this.filterActiveIndex)
      if (isActive)
        return true;
    return false;
  }
  openColOptionsOverlay(triggerElement: HTMLElement, col_index: number) {
    this.overlayCtrl.openOverlay(
      `[${this.className}]MagicTableColOptionsOverlayComponent-${new Date().getTime()}`,
      MagicTableColOptionsOverlayComponent,
      triggerElement,
      'down',
      {
        magicTableComponent: this,
        col_index: col_index
      },
      {},
      (onCloseData) => { }
    );
  }
  cleanFilter(col_index: number) {
    magicTableCleanFilter(this, col_index);
  }
  toogleSort(col_index: number, ord: "asc" | "desc") {
    magicTableToogleSort(this, col_index, ord);
  }
  saveAsExcel() { magicTableSaveAsExcel(this); }

  // Settings
  private initTableSettings(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.authService.get_parameter(`table_${this.id}_settings`)
        .then(tableSettingsJson => {
          let data = null;
          if (tableSettingsJson)
            data = JSON.parse(tableSettingsJson as string);

          if (data) {
            this.tableSettings = data;
          } else {
            let tempTableSettings = new MagicTableSettings();
            for (let col of this.cleanedColumns) {
              if (col.show)
                tempTableSettings.selectedColumns.push(col.title);
            }
            this.tableSettings = tempTableSettings;
          }
          resolve();
        })
        .catch(e => reject(e))
    });
  }
  showSettings() {
    this.overlayCtrl.openOverlayModal(
      `[${this.className}]MagicTableSettingsModalComponent-${new Date().getTime()}`,
      MagicTableSettingsModalComponent,
      'top',
      {
        tableId: this.id,
        columns: this.cleanedColumns,
        tableSettings: this.tableSettings
      },
      {},
      (onCloseData) => {
        if (onCloseData.needLoad) {
          this.tableSettings = onCloseData.tableSettings;
          this.initTableData();
        }
      }
    );
  }
  // Settings

  _trackByFn(index, item) {
    return index;
  }
  trustHtml(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }
}
