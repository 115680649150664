import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PM_TimerUserConfig } from 'app/@firebase';

import { weekDayNames } from '../timer-list-tab.types';

@Component({
  selector: 'app-timer-list-tab-user-config-modal',
  templateUrl: './timer-list-tab-user-config-modal.component.html',
  styleUrls: ['./timer-list-tab-user-config-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimerListTabUserConfigModalComponent {

  weekDayNames = weekDayNames;

  localDoc: PM_TimerUserConfig = new PM_TimerUserConfig();
  oldDoc: PM_TimerUserConfig = new PM_TimerUserConfig();

  hasChanges = false;

  input_hour = {
    min: 0,
    max: 24
  };
  input_min = {
    min: 0,
    max: 59
  };
  dayTests = [
    {
      test: function (_this, dayIndex: number): boolean {
        // true if passes
        let pass = true;
        if (_this.localDoc.weeklyGoal.days[dayIndex].val > _this.input_hour.max)
          pass = false;
        return pass;
      },
      failText: `Dia com mais de ${this.input_hour.max}h lançadas!`
    },
    {
      test: function (_this, dayIndex: number): boolean {
        // true if passes
        let pass = true;
        if (_this.localDoc.weeklyGoal.days[dayIndex].m > _this.input_min.max)
          pass = false;
        return pass;
      },
      failText: `Dia com mais de ${this.input_min.max}m lançados!`
    },
  ];
  errors: string[] = [];
  dayIndexesWithErros: number[] = [];

  @ViewChild('popdownContent', { read: ElementRef, static: false }) popdownContent: ElementRef;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {},
    private mdDialogRef: MatDialogRef<TimerListTabUserConfigModalComponent>,
  ) {
    (function ($) {
      $(document).ready(function () {
        $('.mat-dialog-container').css("display", "contents");
      });
    })(jQuery);
    this.localDoc = data as any;
    this.oldDoc = JSON.parse(JSON.stringify(this.localDoc));
  }
  close(data?) {
    this.mdDialogRef.close(data)
  }
  @HostListener('window:keydown.esc', ['$event'])
  onEsc() {
    this.close();
  }
  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.popdownContent && !this.popdownContent.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  _checkForChanges() {
    setTimeout(() => {
      if (
        this.oldDoc.showSaveDoneAlert != this.localDoc.showSaveDoneAlert ||
        this.oldDoc.showWeekend != this.localDoc.showWeekend ||
        this.oldDoc.showTimer != this.localDoc.showTimer ||

        this.oldDoc.weeklyGoal.active != this.localDoc.weeklyGoal.active ||
        JSON.stringify(this.oldDoc.weeklyGoal.days) != JSON.stringify(this.localDoc.weeklyGoal.days)
      )
        this.hasChanges = true
      else
        this.hasChanges = false
    }, 250);
  }

  // Day
  _checkErrors() {
    let tempErrors = [];
    let tempDayIndexesWithErros = [];

    if (this.localDoc.weeklyGoal.active) {
      for (let i = 0; i < this.localDoc.weeklyGoal.days.length; i++) {
        this.dayTests.forEach(dt => {
          if (!dt.test(this, i)) {
            tempDayIndexesWithErros.push(i);
            tempErrors.push(`Dia: ${weekDayNames[i]} - ${dt.failText}`);
          }
        });
      }

      if (this.localDoc.weeklyGoal.value == 0)
        tempErrors.push('Meta vazia, preencha os dias da semana!')
    }

    this.dayIndexesWithErros = tempDayIndexesWithErros;
    this.errors = tempErrors;
  }
  _dayInputModelChange(dayIndex: number) {
    setTimeout(() => {
      this._updateDay(dayIndex)
    }, 200);
  }
  _dayInputBlur(dayIndex: number) {
    setTimeout(() => {
      if (!this.localDoc.weeklyGoal.days[dayIndex].time)
        this.localDoc.weeklyGoal.days[dayIndex].time = '00:00:00';
      this._updateDay(dayIndex)
    }, 200);
  }
  private __getHourFromHHMMSS(HHMMSS: string): number {
    return Number(HHMMSS.split(':')[0]);
  }
  private __getMinFromHHMMSS(HHMMSS: string): number {
    return Number(HHMMSS.split(':')[1]);
  }
  _updateDay(dayIndex: number) {
    if (!this.localDoc.weeklyGoal.days[dayIndex].time)
      return;

    this.localDoc.weeklyGoal.days[dayIndex].h = this.__getHourFromHHMMSS(this.localDoc.weeklyGoal.days[dayIndex].time);
    this.localDoc.weeklyGoal.days[dayIndex].m = this.__getMinFromHHMMSS(this.localDoc.weeklyGoal.days[dayIndex].time);

    this.localDoc.weeklyGoal.days[dayIndex].val = this.localDoc.weeklyGoal.days[dayIndex].h + this.localDoc.weeklyGoal.days[dayIndex].m / 60;

    this.localDoc.weeklyGoal.value = 0;

    for (let i = 0; i < this.localDoc.weeklyGoal.days.length; i++)
      this.localDoc.weeklyGoal.value += this.localDoc.weeklyGoal.days[i].val;

    this._checkErrors();
    this._checkForChanges();
  }
  _dayHasError(index: number): boolean {
    return this.dayIndexesWithErros.indexOf(index) != -1;
  }
  _showWeekendInputModelChange() {
    if (!this.localDoc.showWeekend) {
      this.localDoc.weeklyGoal.days[0].time = '00:00:00';
      this._updateDay(0);
      this.localDoc.weeklyGoal.days[6].time = '00:00:00';
      this._updateDay(6);
    } else
      this._checkForChanges();
  }

  save() {
    console.log('U S E R _ C O N F I G _ TO _ SAVE', this.localDoc);
    this.close(this.localDoc);
  }
}
