import firebase from "firebase/compat/app";

export class TL_EtlScript {

  id: string;
  status: 0 | 1;

  name: string;
  code: string;

  users: string[];

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<TL_EtlScript>) {

    this.id = '';
    this.status = 1;

    this.name = '';
    this.code = '';

    this.users = [];

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;


    if (init)
      Object.assign(this, init);
  }
}
