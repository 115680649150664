import firebase from "firebase/compat/app";

import { DataVisualization } from "app/components/@data-visualization/types";

export class HomePageItem {

  id: string;
  status: 0 | 1;

  title: string;

  vis: DataVisualization;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<HomePageItem>) {

    this.id = "";
    this.status = 0;

    this.title = "";

    this.vis = new DataVisualization();

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
