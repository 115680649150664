import firebase from "firebase/compat/app";

import { DICTIONARY } from "app/@firebase";

export function _timeStampToLocalDate(timeStamp: firebase.firestore.Timestamp): string {
    if (!timeStamp)
        return "";

    if (timeStamp.seconds != null && timeStamp.nanoseconds != null) {
        return new Date((timeStamp.seconds * 1000 + timeStamp.nanoseconds / 1e6)).toLocaleString('pt-BR');
    }

    let _timeStamp = timeStamp as any;
    if (_timeStamp._seconds != null && _timeStamp._nanoseconds != null) {
        return new Date((_timeStamp._seconds * 1000 + _timeStamp._nanoseconds / 1e6)).toLocaleString('pt-BR');
    }

    return "";
}
export function _formatVectorWithCommasAndAnd(array: string[]): string {
    if (array.length === 0) {
        return '';
    } else if (array.length === 1) {
        return array[0];
    } else if (array.length === 2) {
        return array.join(' e ') + '.';
    } else {
        const lastItem = array.pop();
        const formattedArray = array.join(', ') + ', e ' + lastItem + '.';
        array.push(lastItem!); // Adicionando o último item de volta ao vetor original
        return formattedArray;
    }
}
export function _formatToMonetary(numero: number): string {
    const numeroFormatado = numero.toFixed(2);
    const [parteInteira, parteDecimal] = numeroFormatado.split('.');
    const parteInteiraFormatada = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `R$ ${parteInteiraFormatada},${parteDecimal}`;
}
export function _translateBoolean(boolean: boolean): string {
    const dic = new DICTIONARY();
    return dic.translateBoolean(boolean);
}
export function _progressBarPercentMaker(value: any): string {
    let percent_text = `0%`;
    let perct = Number(value);
    if (!isNaN(perct))
        percent_text = `${perct.toFixed(2)}%`;
    return percent_text;
}