import { Component, ElementRef, HostListener, OnChanges, OnInit, SimpleChange, ViewChild } from "@angular/core";

import { Log, PM_BoardActivity, User } from "app/@firebase";
import { MagicTextEditorComponent, MagicTextUserToMention } from "app/components";
import { genNotif_cardComment, genNotif_cardCommentMention, genNotif_dislikeComment, genNotif_likeComment } from "app/routes/main/project-management/PM_NOTIFICATIONS";
import { SYS_FILE_GENERIC_TYPE_ICON, SYS_FILE_TYPES_ICONS } from "app/routes/main/sys/SYS_CONSTS";

import { PmCardModal } from "../../../pm-card-modal";
import { AttachmentsDataType } from "../../side/mode-attachments/mode-attachments.component";

export class ActivityDataType {
  index: number;
  page: number;
  ui_id: string;
  activity: PM_BoardActivity;
  user: User;
  public constructor(init?: Partial<ActivityDataType>) {
    this.index = -1;
    this.page = 0;
    this.ui_id = '';
    this.activity = new PM_BoardActivity();
    this.user = new User();
    if (init)
      Object.assign(this, init);
  }
};

@Component({
  selector: 'activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
})
export class PMCardModalActivitiesComponent implements OnInit, OnChanges {

  className = 'PMCardModalActivitiesComponent';

  private activitiesSub = null;

  _loader = false;
  _reviewsLoader = false;

  activitiesChunkSizeToLoad = 20;

  itensPerPage: number = 10;
  actualPage: number = 1;
  lastPage: number = 1;
  input_page: number = 1;
  pageOfActivityIdToFocus: number;

  users: User[] = [];
  activitiesRaw: PM_BoardActivity[] = [];
  activitiesData: ActivityDataType[] = [];
  activitiesDataForDisplay: ActivityDataType[] = [];

  activityToFocus: PM_BoardActivity = new PM_BoardActivity();

  attachmentsMap: Record<string, AttachmentsDataType> = {};
  timeRecordMap: Record<string, string> = {};

  @ViewChild('tempNoteEditorERef', { read: ElementRef, static: false }) _tempNoteEditorERef: MagicTextEditorComponent = new MagicTextEditorComponent(this.pmCardModal.afStorage);
  @ViewChild('ActivityOptERef', { read: ElementRef, static: false }) activityOptERef: ElementRef;
  @ViewChild('ActivitiesList', { static: false }) activitiesListERef: ElementRef;

  _editNoteId = '';
  _editNoteHtml = '';
  _editNoteText = '';
  _editNoteUsersMentioned: MagicTextUserToMention[] = [];

  _showActivityOptActivityId = "";

  constructor(
    public pmCardModal: PmCardModal
  ) { }
  @HostListener('document:mousedown', ['$event'])
  on_mousedown(event: MouseEvent): void {
    if (this.activityOptERef && !this.activityOptERef.nativeElement.contains(event.target))
      this._hideActivityOpt();
  }
  ngOnInit() {
    this.openActivitiesSub();
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }
  ngOnDestroy() {
    this.closeActivitiesSub();
  }
  _trackByFn(index: number, item: ActivityDataType) {
    return item.ui_id;
  }
  private openActivitiesSub() {
    if (this.activitiesSub == null && this.pmCardModal.card.id != '') {
      let query = this.pmCardModal.db.projectManagement.kanban.boardActivities
        .ref
        .where('cardId', '==', this.pmCardModal.card.id)
        .where('type', '==', 'C');

      this.activitiesSub = query.onSnapshot(
        (querySnapshot) => {
          let docChanges = querySnapshot.docChanges();
          if (docChanges.length > 0) {
            // update only if has changes
            docChanges.forEach((change) => {
              const newActivity = new PM_BoardActivity(change.doc.data());
              const existingIndex = this.activitiesRaw.findIndex((act) => act.id == newActivity.id);
              if (change.type === "added") {
                if (existingIndex === -1)
                  this.activitiesRaw.push(newActivity);
              } else if (change.type === "modified") {
                if (existingIndex > -1)
                  this.activitiesRaw[existingIndex] = newActivity;
              } else if (change.type === "removed") {
                if (existingIndex > -1)
                  this.activitiesRaw.splice(existingIndex, 1);
              }
            });
            this.activitiesRaw = this.pmCardModal.utilCtrl.arrays.sort(this.activitiesRaw, 'createdOn', 'desc');
            this.activitiesRaw = this.pmCardModal.utilCtrl.arrays.sort(this.activitiesRaw, 'pinned', 'desc');

            this.__loadActivitiesData();
          }
        },
        (error) => {
          this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro', error);
        }
      );
    }
  }
  private closeActivitiesSub() {
    if (this.activitiesSub != null) {
      this.activitiesSub();
      this.activitiesSub = null;
    }
  }
  __updateTimeRecordMap() {
    let tempMap = {};
    this.activitiesRaw
      .forEach((actv) => {
        const sum = this.pmCardModal.getHoursSum4Activity(actv.id);
        if (sum.val > 0)
          tempMap[actv.id] = sum.s;
      });
    this.timeRecordMap = tempMap;
  }
  private __updateAttachmentsMap() {
    this.pmCardModal.db.projectManagement.kanban.cardAttachments
      .getDataByWhere('cardId', '==', this.pmCardModal.card.id)
      .then((data) => {
        const attachments = data.map((attachment) => {
          let fileIcon = SYS_FILE_TYPES_ICONS.filter((fi) => { return fi.extensions.includes(attachment.extension); })[0];
          if (!fileIcon)
            fileIcon = SYS_FILE_GENERIC_TYPE_ICON;
          return new AttachmentsDataType({
            attachment: attachment,
            icon: fileIcon
          });
        });
        let tempAttachmentsMap = {};
        attachments.forEach((attachmentData) => {
          tempAttachmentsMap[attachmentData.attachment.id] = attachmentData;
        });
        this.attachmentsMap = tempAttachmentsMap;
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erros', e)
      })
  }
  private ___setInnerHTML(id: string, html: string) {
    if (!this.activitiesListERef?.nativeElement) {
      console.error('activitiesListERef not defined');
      return;
    }

    let getDivElementById = (id: string): HTMLElement | null => {
      return this.activitiesListERef.nativeElement.querySelector(`#${id}`);
    };
    const setContent = (element: HTMLElement) => {
      element.innerHTML = html;
    };

    const element = getDivElementById(id);
    const animation = (element: HTMLElement) => requestAnimationFrame(() => setContent(element));

    if (element) {
      animation(element);
    } else {
      const observer = new MutationObserver((mutations, obs) => {
        const element = getDivElementById(id);
        if (element) {
          animation(element);
          obs.disconnect();
        }
      });
      observer.observe(this.activitiesListERef.nativeElement, { childList: true, subtree: true });
    }
  }
  private __loadActivitiesData() {
    this._loader = true;
    let pageN = 1;
    let lastPageItens = 0;

    const tempOldUsersUnames = new Set(this.users.map((u) => u.uName));
    const tempNewUsersUnames = this.activitiesRaw
      .map((actv) => actv.uName)
      .filter((uName) => !tempOldUsersUnames.has(uName));

    this.pmCardModal.db.sys.users.getDataByWheres('uName', '==', tempNewUsersUnames)
      .then((usersData) => {
        this.users.push(...usersData);

        const activityQueue = [...this.activitiesRaw];
        this.activitiesData = [];

        let frameId: number;
        const processChunk = () => {
          const chunk = activityQueue.splice(0, this.activitiesChunkSizeToLoad);

          chunk.forEach((actv) => {
            const ui_id = this.pmCardModal.db.createKey();

            let actvData = new ActivityDataType({
              index: this.activitiesData.length,
              page: 0,
              ui_id: ui_id,
              activity: actv,
              user: new User(this.users.find((u) => u.uName == actv.uName)),
            });

            // page control
            if (lastPageItens < this.itensPerPage) {
              actvData.page = pageN;
              this.activitiesData.push(actvData);
              lastPageItens++;
            } else {
              pageN++;
              actvData.page = pageN;
              this.activitiesData.push(actvData);
              lastPageItens = 1;
            }
            // page control

            if (this.pmCardModal.activityIdToFocus === actv.id) {
              this.pmCardModal.activityIdToFocus = ui_id;
              this.pageOfActivityIdToFocus = actvData.page;
            }
          });

          if (activityQueue.length > 0) {
            if (activityQueue.length > 0) {
              frameId = requestAnimationFrame(processChunk);
            } else {
              cancelAnimationFrame(frameId);
            }
          } else {
            // Após processamento
            this._updateActivitiesDataForDisplay();

            this.lastPage = Math.ceil(this.activitiesData.length / this.itensPerPage);
            this._loader = false;

            // focus
            if (this.pageOfActivityIdToFocus)
              this.setPage(this.pageOfActivityIdToFocus);
            if (this.pmCardModal.activityIdToFocus)
              setTimeout(() => {
                this.pmCardModal.focusElementById(this.pmCardModal.activityIdToFocus, () => {
                  this.pmCardModal.activityIdToFocus = undefined;
                  this.pageOfActivityIdToFocus = undefined;
                });
              }, 250);

            // Operações adiadas até que a renderização inicial termine
            setTimeout(() => {
              this.__updateTimeRecordMap();
              this.__updateAttachmentsMap();
            }, 250);
          }
        };

        processChunk(); // Inicia o processamento em blocos
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, '', 'Erro', e);
      });
  }
  _updateActivitiesDataForDisplay(focusTimelineFirstElement = false, activityIdToFocus: string = null) {
    let tempData: ActivityDataType[] = [];
    for (let ad of this.activitiesData)
      if (ad.page == this.actualPage) {
        tempData.push(ad);
        this.___setInnerHTML(`activity-content-${ad.ui_id}`, ad.activity.html);
      }
    this.activitiesDataForDisplay = [...tempData];

    setTimeout(() => {
      if (activityIdToFocus)
        this.pmCardModal.focusElementById(activityIdToFocus, () => { });
    }, 250);
  }
  _showActivityOpt(activityId: string) {
    this._showActivityOptActivityId = activityId;
  }
  _hideActivityOpt() {
    this._showActivityOptActivityId = '';
  }
  // ---------------------- PAGE CONTROLS
  pageDown() {
    this.actualPage--;
    this.setPage(this.actualPage, true);
  }
  pageUp() {
    this.actualPage++;
    this.setPage(this.actualPage, true);
  }
  setPage(page: number, focusTimelineFirstElement = false, activityIdToFocus: string = null) {
    this.actualPage = page;
    this.input_page = this.actualPage;
    this.pmCardModal.focusElementById("activities-body-section-header", () => { });
    this._updateActivitiesDataForDisplay(focusTimelineFirstElement, activityIdToFocus);
  }
  update_input_page() {
    if (this.input_page && this.input_page > 0 && this.input_page <= this.lastPage)
      this.setPage(this.input_page, true)
    else
      this.input_page = this.actualPage;
  }
  // ---------------------- PAGE CONTROLS

  // Notes
  _openEditNoteActv(note: PM_BoardActivity) {
    this._hideActivityOpt();
    this._editNoteId = note.id;
    this._editNoteHtml = note.html + "";
    this._editNoteText = note.text + "";
  }
  _editNoteActv_editorOnReady(editor: MagicTextEditorComponent) {
    editor.setData(this._editNoteHtml);
  }
  _editNoteActv_noteHtmlChange(ev) {
    this._editNoteHtml = ev;
    this._editNoteText = this.pmCardModal.utilCtrl.converters.htmltoTextStr(this._editNoteHtml);
  }
  _editNoteActv__noteUsersMentionedChange(ev) {
    this._editNoteUsersMentioned = ev;
  }
  _editNoteActv_cleanNoteEditor() {
    this._editNoteHtml = "";
    this._editNoteText = "";
    this._editNoteId = "";
  }
  _editNoteActv_cancel(activityData: ActivityDataType) {
    this._editNoteActv_cleanNoteEditor();
  }
  _editNoteActv_save(note: PM_BoardActivity) {
    this.pmCardModal.uiFeedBackCtrl.presentLoader()
      .then(() => {

        // Log
        let tempLog = new Log();

        tempLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
        tempLog.uName = this.pmCardModal.authService.localUser.uName;

        tempLog.type = "AUDIT";
        tempLog.category = "FC";

        tempLog.description = `Nota Alterada: "${note.id}
  De: "${note.text}";
  Para: "${this._editNoteText}";`;

        tempLog.docPath = this.pmCardModal.localDocPath;
        tempLog.docId = this.pmCardModal.card.id;
        // Log

        // Activity
        let tempActivity = new PM_BoardActivity();

        tempActivity.id = this.pmCardModal.db.afs.createId();
        tempActivity.uName = this.pmCardModal.authService.localUser.uName;
        tempActivity.boardId = this.pmCardModal.card.boardId;
        tempActivity.parentKey = this.pmCardModal.board.childsKey;
        tempActivity.cardId = this.pmCardModal.card.id;

        tempActivity.type = "FC";

        tempActivity.text = `Nota Alterada: "${note.id}
    De: "${note.text}";
    Para: "${this._editNoteText}";`;
        tempActivity.html = `<p>Nota Alterada:</p><p>${note.id}</p><p>De: "${note.html}";</p><p>Para: "${this._editNoteHtml}";</p>`;
        // Activity

        // Transaction
        this.pmCardModal.db.runTransaction(transaction => {

          // Update Activity
          note.html = this._editNoteHtml;
          note.text = this._editNoteText;
          note.mentions = this._editNoteUsersMentioned.map((um) => um.reference);

          transaction.update(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(note.id),
            {
              html: note.html,
              text: note.text,
              mentions: note.mentions,

              updatedBy: this.pmCardModal.authService.localUser.uName,
              updatedOn: this.pmCardModal.utilCtrl.timestamp.now()
            }
          );
          // Update Activity

          // Log
          transaction.set(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
            Object.assign({}, tempLog)
          );
          // Log

          // Activity
          transaction.set(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
            Object.assign({}, tempActivity)
          );
          // Activity

          // Notifications
          // -- subscribers
          this.pmCardModal.card.subscribers
            .filter((uName) => uName != this.pmCardModal.authService.localUser.uName && !tempActivity.mentions.includes(uName))
            .forEach((uName) => {
              let notification = genNotif_cardComment(this.pmCardModal.card, note, this.pmCardModal.authService.localUser, uName);
              transaction.set(
                this.pmCardModal.db.afs.firestore
                  .collection(this.pmCardModal.db.COLLECTIONS.sys.notifications).doc(notification.id),
                Object.assign({}, notification)
              );
            })

          // -- mentions
          note.mentions
            .filter((uName) => uName != this.pmCardModal.authService.localUser.uName)
            .forEach((uName) => {
              let notification = genNotif_cardCommentMention(this.pmCardModal.card, note, this.pmCardModal.authService.localUser, uName);
              transaction.set(
                this.pmCardModal.db.afs.firestore
                  .collection(this.pmCardModal.db.COLLECTIONS.sys.notifications).doc(notification.id),
                Object.assign({}, notification)
              );
            })
          // Notifications

          return Promise.resolve();
        })
          .then(() => {
            this._editNoteActv_cleanNoteEditor();
            this.pmCardModal.activityIdToFocus = note.id;
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
          })
          .catch((e) => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.pmCardModal.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
          })
      })
  }
  _deleteNoteActv(note: PM_BoardActivity) {
    this._hideActivityOpt();

    if (note.uName != this.pmCardModal.authService.localUser.uName && !this.pmCardModal.board.admins.includes(this.pmCardModal.authService.localUser.uName)) {
      this.pmCardModal.uiFeedBackCtrl.presentAlert(`Permisões insuifientes!`, `Somente administradores do quadro podem apagar atividades de outros usuarios!`, `warning`);
      return;
    }

    this.pmCardModal.uiFeedBackCtrl.presentCustonAlert({
      title: 'Apagar Atividade?',
      message: `⚠️Apagar a Atividade: "${note.text}"`,
      buttons: [
        {
          text: 'Cancelar',
          icon: "close-outline",
        },
        {
          needFormValid: true,
          text: 'Deletar',
          icon: "trash-2-outline",
          status: "danger",
          handler: () => this.__doDeleteNoteActv(note)
        }
      ]
    });
  }
  private __doDeleteNoteActv(note: PM_BoardActivity) {
    this.pmCardModal.uiFeedBackCtrl.presentLoader('Apagando...')
      .then(() => {

        // Log
        let tempLog = new Log();

        tempLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
        tempLog.uName = this.pmCardModal.authService.localUser.uName;

        tempLog.type = "AUDIT";
        tempLog.category = "FC";

        tempLog.description = `Comentario deletado: ${note.text}`;

        tempLog.docPath = this.pmCardModal.localDocPath;
        tempLog.docId = this.pmCardModal.card.id;
        // Log

        // Activity
        let tempActivity = new PM_BoardActivity();

        tempActivity.id = this.pmCardModal.db.afs.createId();
        tempActivity.uName = this.pmCardModal.authService.localUser.uName;
        tempActivity.boardId = this.pmCardModal.card.boardId;
        tempActivity.parentKey = this.pmCardModal.board.childsKey;
        tempActivity.cardId = this.pmCardModal.card.id;

        tempActivity.type = "FC";

        tempActivity.text = `Comentario deletado: ${note.text}`;
        tempActivity.html = `<p>Comentario deletado:</p><p></p>${note.html}`;
        // Activity

        // Transaction
        this.pmCardModal.db.runTransaction(transaction => {

          // Activity
          transaction.delete(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(note.id)
          );
          // Activity

          // Log
          transaction.set(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
            Object.assign({}, tempLog)
          );
          // Log

          // Activity
          transaction.set(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
            Object.assign({}, tempActivity)
          );
          // Activity

          return Promise.resolve();

        })
          .then(() => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
          })
          .catch((e) => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
          })
      })
  }
  // Notes

  // activities
  _registerActvView(activity: PM_BoardActivity) {
    const uName = this.pmCardModal.authService.localUser.uName;

    // own actv
    if (activity.uName == uName)
      return;

    // already view
    if (activity.views.includes(uName))
      return;

    // Transaction
    this.pmCardModal.db
      .runTransaction(transaction => {
        transaction.update(
          this.pmCardModal.db.afs.firestore.collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(activity.id),
          { views: this.pmCardModal.db.fieldValue.arrayUnion(uName) as any }
        );

        transaction.update(
          this.pmCardModal.db.afs.firestore.collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(activity.id),
          { updatedBy: uName, updatedOn: this.pmCardModal.utilCtrl.timestamp.now() }
        );

        return Promise.resolve();
      })
      .then(() => {
        // local
        activity.views.push(uName);
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar view no Banco de dados!', e)
      })
    // Transaction
  }
  _pinActv(activity: PM_BoardActivity) {
    this._hideActivityOpt();
    this.pmCardModal.uiFeedBackCtrl.presentLoader()
      .then(() => {

        // Log
        let tempLog = new Log();

        tempLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
        tempLog.uName = this.pmCardModal.authService.localUser.uName;

        tempLog.type = "AUDIT";
        tempLog.category = "FC";

        tempLog.description = `Nota Fixada: 
        "${activity.id} -
        ${activity.text}"`;

        tempLog.docPath = this.pmCardModal.localDocPath;
        tempLog.docId = this.pmCardModal.card.id;
        // Log

        // Activity
        let tempActivity = new PM_BoardActivity();

        tempActivity.id = this.pmCardModal.db.afs.createId();
        tempActivity.uName = this.pmCardModal.authService.localUser.uName;
        tempActivity.boardId = this.pmCardModal.card.boardId;
        tempActivity.parentKey = this.pmCardModal.board.childsKey;
        tempActivity.cardId = this.pmCardModal.card.id;

        tempActivity.type = "FC";

        tempActivity.text = `Nota Fixada: ${activity.id}`;
        tempActivity.html = `<p>Nota Fixada:</p><p>${activity.id}</p>"${activity.html}"`;
        // Activity

        // Transaction
        this.pmCardModal.db.runTransaction(transaction => {

          // Update Activity
          transaction.update(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(activity.id),
            {
              pinned: true,
              updatedBy: this.pmCardModal.authService.localUser.uName,
              updatedOn: this.pmCardModal.utilCtrl.timestamp.now()
            }
          );
          // Update Activity

          // Log
          transaction.set(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
            Object.assign({}, tempLog)
          );
          // Log

          // Activity
          transaction.set(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
            Object.assign({}, tempActivity)
          );
          // Activity

          return Promise.resolve();

        })
          .then(() => {
            this.pmCardModal.activityIdToFocus = activity.id;
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
          })
          .catch((e) => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
          })
      })
  }
  _unpinActv(activity: PM_BoardActivity) {
    this._hideActivityOpt();
    this.pmCardModal.uiFeedBackCtrl.presentLoader()
      .then(() => {

        // Log
        let tempLog = new Log();

        tempLog.id = `${this.pmCardModal.localDocPath}-${this.pmCardModal.db.afs.createId()}`;
        tempLog.uName = this.pmCardModal.authService.localUser.uName;

        tempLog.type = "AUDIT";
        tempLog.category = "FC";

        tempLog.description = `Nota Desfixada: 
        "${activity.id} -
        ${activity.text}"`;

        tempLog.docPath = this.pmCardModal.localDocPath;
        tempLog.docId = this.pmCardModal.card.id;
        // Log

        // Activity
        let tempActivity = new PM_BoardActivity();

        tempActivity.id = this.pmCardModal.db.afs.createId();
        tempActivity.uName = this.pmCardModal.authService.localUser.uName;
        tempActivity.boardId = this.pmCardModal.card.boardId;
        tempActivity.parentKey = this.pmCardModal.board.childsKey;
        tempActivity.cardId = this.pmCardModal.card.id;

        tempActivity.type = "FC";

        tempActivity.text = `Nota Desfixada: ${activity.id}`;
        tempActivity.html = `<p>Nota Desfixada:</p><p>${activity.id}</p>"${activity.html}"`;
        // Activity

        // Transaction
        this.pmCardModal.db.runTransaction(transaction => {

          // Update Activity
          transaction.update(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(activity.id),
            {
              pinned: false,
              updatedBy: this.pmCardModal.authService.localUser.uName,
              updatedOn: this.pmCardModal.utilCtrl.timestamp.now()
            }
          );
          // Update Activity

          // Log
          transaction.set(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.sys.logs).doc(tempLog.id),
            Object.assign({}, tempLog)
          );
          // Log

          // Activity
          transaction.set(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(tempActivity.id),
            Object.assign({}, tempActivity)
          );
          // Activity

          return Promise.resolve();

        })
          .then(() => {
            this.pmCardModal.activityIdToFocus = activity.id;
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
          })
          .catch((e) => {
            this.pmCardModal.uiFeedBackCtrl.dismissLoader();
            this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar no Banco de dados!', e)
          })
      })
  }
  _toggleLikeActivity(activity: PM_BoardActivity) {
    this._reviewsLoader = true;

    const uName = this.pmCardModal.authService.localUser.uName;
    const like = activity.likes.includes(uName);
    const dislike = activity.dislikes.includes(uName);

    // Transaction
    this.pmCardModal.db
      .runTransaction(transaction => {

        if (!like) {
          transaction.update(
            this.pmCardModal.db.afs.firestore.collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(activity.id),
            { likes: this.pmCardModal.db.fieldValue.arrayUnion(uName) as any }
          );

          // Notifications
          let notification = genNotif_likeComment(this.pmCardModal.card, activity, this.pmCardModal.authService.localUser, activity.uName);
          transaction.set(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.sys.notifications).doc(notification.id),
            Object.assign({}, notification)
          );
          // Notifications
        } else
          transaction.update(
            this.pmCardModal.db.afs.firestore.collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(activity.id),
            { likes: this.pmCardModal.db.fieldValue.arrayRemove(uName) as any }
          );

        if (dislike)
          transaction.update(
            this.pmCardModal.db.afs.firestore.collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(activity.id),
            { dislikes: this.pmCardModal.db.fieldValue.arrayRemove(uName) as any }
          );

        transaction.update(
          this.pmCardModal.db.afs.firestore.collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(activity.id),
          { updatedBy: uName, updatedOn: this.pmCardModal.utilCtrl.timestamp.now() }
        );

        return Promise.resolve();
      })
      .then(() => {
        // local
        if (!like)
          activity.likes.push(uName);
        else
          activity.likes = activity.likes.filter((l) => l != uName);

        if (dislike)
          activity.dislikes = activity.dislikes.filter((dl) => dl != uName);

        this.pmCardModal.activityIdToFocus = activity.id;
        this._reviewsLoader = false;
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar like no Banco de dados!', e)
      })
    // Transaction
  }
  _toggleDislikeActivity(activity: PM_BoardActivity) {
    this._reviewsLoader = true;

    const uName = this.pmCardModal.authService.localUser.uName;
    const like = activity.likes.includes(uName);
    const dislike = activity.dislikes.includes(uName);

    // Transaction
    this.pmCardModal.db
      .runTransaction(transaction => {

        if (!dislike) {
          transaction.update(
            this.pmCardModal.db.afs.firestore.collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(activity.id),
            { dislikes: this.pmCardModal.db.fieldValue.arrayUnion(uName) as any }
          );

          // Notifications
          let notification = genNotif_dislikeComment(this.pmCardModal.card, activity, this.pmCardModal.authService.localUser, activity.uName);
          transaction.set(
            this.pmCardModal.db.afs.firestore
              .collection(this.pmCardModal.db.COLLECTIONS.sys.notifications).doc(notification.id),
            Object.assign({}, notification)
          );
          // Notifications
        } else
          transaction.update(
            this.pmCardModal.db.afs.firestore.collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(activity.id),
            { dislikes: this.pmCardModal.db.fieldValue.arrayRemove(uName) as any }
          );

        if (like)
          transaction.update(
            this.pmCardModal.db.afs.firestore.collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(activity.id),
            { likes: this.pmCardModal.db.fieldValue.arrayRemove(uName) as any }
          );

        transaction.update(
          this.pmCardModal.db.afs.firestore.collection(this.pmCardModal.db.COLLECTIONS.projectManagement.kanban.boardActivities).doc(activity.id),
          { updatedBy: uName, updatedOn: this.pmCardModal.utilCtrl.timestamp.now() }
        );

        return Promise.resolve();
      })
      .then(() => {
        // local
        if (!dislike)
          activity.dislikes.push(uName);
        else
          activity.dislikes = activity.dislikes.filter((dl) => dl != uName);

        if (like)
          activity.likes = activity.likes.filter((l) => l != uName);

        this.pmCardModal.activityIdToFocus = activity.id;
        this._reviewsLoader = false;
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erro ao salvar dislike no Banco de dados!', e)
      })
    // Transaction
  }
  _copyActivityLink(activity: PM_BoardActivity) {
    let linkUrl = window.location.origin + '/#/main/project-management/kanban/open-board?id=' + this.pmCardModal.card.boardId + '&cardIdToOpen=' + this.pmCardModal.card.id + '&activityIdToFocus=' + activity.id;
    navigator.clipboard.writeText(linkUrl)
      .then(() => {
        this.pmCardModal.uiFeedBackCtrl.presentToast(`Copiado para Clipboard`, ``, `success`);
      })
      .catch((e) => {
        this.pmCardModal.uiFeedBackCtrl.presentErrorAlert('', this.className, this.pmCardModal.authService.localUser.uName, 'Erros', e)
      })
  }
  // activities

}
