import { magicTableTranslateBoolean } from "./magic-table-translate-boolean";
import { MAGIC_TABLE_CONSTS } from "./MAGIC_TABLE_CONSTS";

export function magicTableCleanValue(value: any, type: 'cell' | 'filter'): string {
    let empty_value = "";
    if (type == "cell")
        empty_value = MAGIC_TABLE_CONSTS.EMPTY_CELL_VALUE;
    else if (type == "filter")
        empty_value = MAGIC_TABLE_CONSTS.EMPTY_CELL_FILTER_VALUE;
    let v = '';
    // Tratar valores nulos e indefinidos
    if (value === null || value === undefined) {
        return empty_value;
    }

    // Tratar valores booleanos
    if (typeof value === 'boolean') {
        v = magicTableTranslateBoolean(value);
    }
    // Tratar valores numéricos
    else if (typeof value === 'number') {
        v = value.toLocaleString('pt-BR');
    }
    // Tratar valores string (remover HTML)
    else if (typeof value === 'string' && type != "cell") {
        v = value.replace(/<(?:.|\n)*?>/gm, ' ');
    }
    // Tratar outros tipos de dados
    else {
        v = value + "";
    }

    // Caso o valor seja vazio após a remoção de HTML
    if (v.trim() === '') {
        v = empty_value;
    }
    return v;
}
