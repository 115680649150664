<div class="inner">

  <input #SearchInputERef [disabled]="_loader" class="pm-card-list-search-input" type="text" id="searchInput"
    name="searchInput" aria-describedby="search-text" placeholder="Pesquisar" autocomplete="nope"
    [(ngModel)]="_searchText" autofocus (ngModelChange)="_updateSearchResult()">

  <input [disabled]="_loader" id="input_showArchivedCards" name="input_showArchivedCards"
    [(ngModel)]="_showArchivedCards" type="checkbox" (ngModelChange)="_showArchivedCardsChange()">
  <label for="input_showArchivedCards">Mostrar cartões arquivados</label>

  <input [disabled]="_loader" id="input_showOthersBoardsCards" name="input_showOthersBoardsCards"
    [(ngModel)]="_showOthersBoardsCards" type="checkbox" (ngModelChange)="_showOthersBoardsCardsChange()">
  <label for="input_showOthersBoardsCards">Mostrar cartões em outros quadros</label>

  <h4 class="sub-title">
    Cartões:
  </h4>

  <div *ngIf="_loader" class="loader">
    <p>Carregando...</p>
  </div>

  <div *ngIf="!_loader" class="pm-card-list">
    <div class="pm-card" *ngFor="let c of _cards4Sel; let i=index" (click)="_selectCard(c)">

      <spam class="pm-card-title" PMCardListHighlight [searchedWord]="_searchText" [content]="c.id+' - '+c.title"
        [classToApply]="'highlight'" [setTitle]="true">{{c.id}} - {{c.title}}</spam>
      <spam class="pm-card-subtext">
        <b>{{_boardNamesIndex[c.boardId]}}</b>/<i>{{_routesNamesIndex[c.id]}}</i><i *ngIf="c.status==0"
          [style.color]="'red'"> - Arquivado</i>
      </spam>

    </div>
  </div>

  <div class="empty-title" *ngIf="_searchText.length > 0 && _cards4Sel.length == 0">
    <p>Nenhum resultado</p>
  </div>
</div>