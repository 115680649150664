import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";

import { AuthGuard, throwIfAlreadyLoaded } from "./guards";
import { PIPES } from "./pipes";
import { AuthService, CookiesService, ExcelService, LayoutService, PendingChangesGuard, SmartTableAuxService, UtilService } from "./services";

const MODULES = [
  HttpClientModule,
];

const SERVICES = [
  AuthService,
  AuthGuard,
  PendingChangesGuard,
  CookiesService,
  UtilService,

  // AnalyticsService,
  LayoutService,
  SmartTableAuxService,
  ExcelService
];

@NgModule({
  imports: [
    CommonModule,
    ...MODULES,
  ],
  exports: [
    ...PIPES,
  ],
  declarations: [
    ...PIPES,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...SERVICES,]
    };
  }
}
