<nb-card>
  <nb-card-body>
    <div class="row">
      <div class="form-group col-6">
        <label>Criado Por</label>
        <h6 class="ic-form-control">{{docData.createdBy}}</h6>
      </div>

      <div class="form-group col-6">
        <label>Atualizado Por</label>
        <h6 class="ic-form-control">{{docData.updatedBy}}</h6>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-6">
        <label>Criado em</label>
        <h6 class="ic-form-control">{{utilCtrl.timestamp.toLocalDate(docData.createdOn)}}</h6>
      </div>

      <div class="form-group col-6">
        <label>Atualizado em</label>
        <h6 class="ic-form-control">{{utilCtrl.timestamp.toLocalDate(docData.updatedOn)}}</h6>
      </div>
    </div>
  </nb-card-body>
</nb-card>
