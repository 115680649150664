<div class="magic-text-editor" [ngClass]="{'ready':ready }">

  <div *ngIf="_loader" class="loader">
    <span>Carregando...</span>
  </div>

  <main [ngClass]="type">
    <div class="row-toolbar">
      <div class="document-editor__toolbar" [class]="editorToolbarClass"></div>
    </div>
    <div class="row-editor">
      <div class="editor-container">
        <div [class]="editorClass">
        </div>
      </div>
    </div>
  </main>
</div>