import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DB_DataResult, DB_DataSource, FirestoreService } from 'app/@firebase';
import { DB_DataCoreType } from 'app/routes/main/sys/db/DB_DataCoreType';

import { ENGINE_CUSTOMIZE_REF, get_AXIS_DATA, VIS_TYPES } from '../../../data';
import { DataVisualization, VisEchartsOptions, VisEngineCustomize, VisType } from '../../../types';
import { genFilterValue, stringToColourHex } from '../../../util';

@Component({
  selector: 'editor-engine-display',
  styleUrls: ['./editor-engine-display.component.scss'],
  templateUrl: './editor-engine-display.component.html',
})
export class EditorEngineDisplayComponent implements OnInit {

  className = "EditorEngineDisplayComponent";

  loader = false;

  private vis: DataVisualization = null;

  _vis_type: VisType = null;

  _vis_types = VIS_TYPES;
  _engineCustomize = ENGINE_CUSTOMIZE_REF;

  dataCore = new DB_DataCoreType();
  DB_DataSource = new DB_DataSource();
  DB_DsDataResult: DB_DataResult = new DB_DataResult();

  @Output() onChange = new EventEmitter();
  @Input() hasChanges = false;

  @Input() edit = false;

  engineCustomizeForm_valid = false;

  _oldVis_engineCustomize: VisEngineCustomize = {};
  _vis_engineCustomize: VisEngineCustomize = {};

  _data_for_selection = {
    colors_labels: []
  };

  _presetColors = [
    // Standard
    "#000000", "#404040", "#808080", "#c0c0c0", "#ffffff",
    "#980000", "#ff0000", "#ff9900", "#ffff00", "#00ff00",
    "#00ffff", "#4a86e8", "#0000ff", "#9900ff", "#ff00ff",
    // IC Colors
    "#0B83D9", "#2DA608", "#C7AF14", "#9E5BD9", "#06A893",
    "#596614", "#D94182", "#C9806B", "#D92B2B", "#E36A00",
    "#465BB3", "#525266", "#BF7000", "#990099", "#991102",
  ];

  constructor(
    public db: FirestoreService,
  ) { }

  ngOnInit() { }
  ngOnDestroy(): void { }

  public load(vis: DataVisualization) {
    if (!vis.engine || !vis.engine.customize)
      vis.engine.customize = {};

    this.vis = JSON.parse(JSON.stringify(vis));
    this._oldVis_engineCustomize = JSON.parse(JSON.stringify(vis.engine.customize));
    this._vis_engineCustomize = JSON.parse(JSON.stringify(vis.engine.customize));
    this.hasChanges = false;
    this.engineCustomizeForm_valid = true;

    let vis_type = this._vis_types.filter(vt => { return vt.id == vis.typeId })[0];

    if (vis_type)
      this._vis_type = vis_type;
  }
  private getFieldsTextsValues(ds: DB_DataSource, fieldId: string): string[] {
    let values = [];
    let realDataSourceId = '';
    let realFieldId = '';

    if (ds.type == 'table') {
      realDataSourceId = ds.id;
      realFieldId = fieldId;
    } else if (ds.type == 'view') {
      realDataSourceId = this.db.dataSource.decomposeViewFieldId(fieldId).dataSourceId;
      realFieldId = this.db.dataSource.decomposeViewFieldId(fieldId).fieldId;
    }

    let fieldsTexts = this.dataCore.dataFieldTexts.filter(ft => { return (ft.dataSourceId == null || ft.dataSourceId == realDataSourceId) && ft.fieldId == realFieldId; });
    fieldsTexts.forEach(ft => { values.push(ft.text); })

    return values;
  }
  public updateOptions(options: VisEchartsOptions, dataCore: DB_DataCoreType, DB_DataSource: DB_DataSource, DB_DsDataResult: DB_DataResult) {
    this._data_for_selection.colors_labels = [];

    this.dataCore = dataCore;
    this.DB_DataSource = DB_DataSource;
    this.DB_DsDataResult = DB_DsDataResult;

    if (this._vis_type) {

      // colors
      if (this._vis_type.engine.customizationProps.indexOf('colors') != -1) {
        let temp_colors_labels = [];

        if (!this._vis_engineCustomize.colors)
          this._vis_engineCustomize.colors = {};

        if (options._colorMap.forEach)
          options._colorMap
            .forEach((value, key) => {
              if (!this._vis_engineCustomize.colors[key])
                this._vis_engineCustomize.colors[key] = value;
              temp_colors_labels.push(key);
            });

        //    DS Fields Texts
        if (this.DB_DsDataResult.fields) {

          // determinate label field ID
          let AXIS_DATA = get_AXIS_DATA(this.vis);
          let fieldId = AXIS_DATA.categorizeBy_fieldId;
          if (this.vis.typeId == 'pie')
            fieldId = AXIS_DATA.xAxis_fieldId;
          // determinate label field ID

          if (fieldId != null && fieldId != '')
            this.DB_DsDataResult.fields
              .forEach(f => {
                if (f.id == fieldId) {
                  let fieldsTextValues = this.getFieldsTextsValues(this.DB_DataSource, f.id);
                  fieldsTextValues.forEach(value => {
                    const fValue = genFilterValue(value);
                    if (!this._vis_engineCustomize.colors[fValue])
                      this._vis_engineCustomize.colors[fValue] = stringToColourHex(fValue);
                    if (temp_colors_labels.indexOf(fValue) == -1)
                      temp_colors_labels.push(fValue);
                  })
                }
              })
        }
        //    DS Fields Texts

        temp_colors_labels.sort(function (a, b) {
          var valueA = a, valueB = b;
          if (valueA < valueB)
            return -1;
          if (valueA > valueB)
            return 1;
          return 0;
        });

        this._data_for_selection.colors_labels = temp_colors_labels;
      }
      // colors

    }
  }

  public getVisEngineCustomize(): VisEngineCustomize {
    return JSON.parse(JSON.stringify(this._vis_engineCustomize));
  }

  _vis_engineDisplayChange(form_valid: boolean) {
    this.engineCustomizeForm_valid = form_valid;

    console.log('-> engineCustomizeForm_valid:', this.engineCustomizeForm_valid);
    console.log('-> _vis_engineCustomize:', this._vis_engineCustomize);
    this._change();
  }
  _compareFn(o1, o2): boolean {
    let result = false;
    if (o1 === null && o2 === null)
      result = true;
    else if (o1 != null && o2 != null) {
      if (o1 === o2)
        result = true;
      else if (o1.id != null && o2.id != null && o1.id === o2.id)
        result = true;
    }
    return result;
  }
  _trackByFn(index, item) {
    return index;
  }
  _change() {
    setTimeout(() => {
      this.checkForChanges();
      this.onChange.emit();
    }, 250);
  }
  checkForChanges() {
    const oldData = JSON.stringify(this._oldVis_engineCustomize);
    const newData = JSON.stringify(this._vis_engineCustomize);
    if (
      oldData != newData
    )
      this.hasChanges = true
    else
      this.hasChanges = false
  }
}
