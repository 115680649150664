import firebase from "firebase/compat/app";
import { DateUtil } from './DateUtil';

export class TimestampUtil {
  private dateUtil = new DateUtil();

  /**
  * Retorna o timestamp atual do Firestore.
  * @return {firebase.firestore.Timestamp} Um timestamp do Firestore representando o momento atual.
  */
  now(): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.fromDate(this.dateUtil.newDate());
  }

  /**
   * Converte um objeto Date para um timestamp do Firestore.
   * @param {Date} date A data a ser convertida.
   * @return {firebase.firestore.Timestamp} Um timestamp do Firestore correspondente à data fornecida.
   */
  fromDate(date: Date): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.fromDate(date);
  }

  /**
   * Converte um valor de milissegundos para um timestamp do Firestore.
   * @param {number} milliseconds Número de milissegundos desde a época (1 de janeiro de 1970).
   * @return {firebase.firestore.Timestamp} Um timestamp do Firestore correspondente aos milissegundos fornecidos.
   */
  fromMillis(milliseconds: number): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.fromDate(this.dateUtil.newDate(milliseconds));
  }

  /**
   * Cria um novo timestamp do Firestore a partir de segundos e nanossegundos.
   * @param {number} seconds Número de segundos desde a época (1 de janeiro de 1970).
   * @param {number} nanoseconds Número adicional de nanossegundos para precisão.
   * @return {firebase.firestore.Timestamp} Um timestamp do Firestore correspondente aos segundos e nanossegundos fornecidos.
   */
  new(seconds: number, nanoseconds: number): firebase.firestore.Timestamp {
    return this.fromMillis((seconds * 1000 + nanoseconds / 1e6));
  }

  /**
   * Converte um timestamp do Firestore em um objeto Date.
   * @param {firebase.firestore.Timestamp} timeStamp O timestamp do Firestore a ser convertido.
   * @return {Date} Um objeto Date correspondente ao timestamp fornecido, ou null se o timestamp for inválido.
   */
  toDate(timeStamp: firebase.firestore.Timestamp): Date {
    if (!timeStamp)
      return null;

    if (timeStamp.seconds != null && timeStamp.nanoseconds != null)
      return this.dateUtil.newDate((timeStamp.seconds * 1000 + timeStamp.nanoseconds / 1e6));

    const _timeStamp = timeStamp as any;
    if (_timeStamp._seconds != null && _timeStamp._nanoseconds != null)
      return this.dateUtil.newDate((_timeStamp._seconds * 1000 + _timeStamp._nanoseconds / 1e6));

    return null;
  }

  /**
   * Retorna uma string formatada da data e hora de um timestamp do Firestore.
   * @param {firebase.firestore.Timestamp} timeStamp O timestamp do Firestore a ser formatado.
   * @param {boolean} [showSeconds=false] - Se verdadeiro, inclui segundos na string formatada.
   * @return {string} Uma string representando a data e hora formatada.
   */
  getDateTimeString(timeStamp: firebase.firestore.Timestamp, showSeconds = false): string {
    return this.dateUtil.getDateTimeString(this.toDate(timeStamp), showSeconds);
  }

  /**
   * Retorna uma string da data local a partir de um timestamp do Firestore.
   * @param {firebase.firestore.Timestamp} timeStamp O timestamp do Firestore a ser convertido.
   * @return {string} Uma string representando a data no formato local.
   */
  toLocalDate(timeStamp: firebase.firestore.Timestamp): string {
    return this.dateUtil.toLocalDate(this.toDate(timeStamp));
  }

  /**
   * Formata um timestamp do Firestore para uma string de data, com a opção de incluir o tempo.
   * Utiliza termos como 'Hoje', 'Ontem', 'Amanhã' e dias da semana se a data estiver na mesma semana.
   * @param {firebase.firestore.Timestamp} timeStamp O timestamp do Firestore a ser formatado.
   * @param {boolean} [includeTime=true] - Se verdadeiro, inclui a hora na string formatada.
   * @param {boolean} [easy=true] - Se verdadeiro, usa termos amigáveis como 'Hoje', 'Ontem', 'Amanhã'.
   * @returns {string} Uma string representando a data formatada.
   */
  formatToDateString(timeStamp: firebase.firestore.Timestamp, includeTime: boolean = true, easy: boolean = true): string {
    return this.dateUtil.formatToDateString(this.toDate(timeStamp), includeTime, easy);
  }
}
