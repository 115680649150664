import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NbOverlayRef } from '@nebular/theme';

import { OverlayService } from 'app/@theme/services/overlay.service';

import { CustonAlertButton, CustonAlertInputOptions, CustonAlertOptions } from '../../types';

@Component({
  selector: 'app-ui-feed-back-custon-alert-modal',
  templateUrl: 'ui-feed-back-custon-alert-modal.component.html',
  styleUrls: ['./../ui-feed-back-modals.scss'],
  encapsulation: ViewEncapsulation.None
})

export class UiFeedBackCustonAlertModalComponent implements OnInit, OnDestroy {

  @Input() id: string = '';
  @Input() overlay: NbOverlayRef;
  readonly className = 'UiFeedBackCustonAlertModalComponent';
  onCloseData = {};

  public TEXT_AREA_TYPE = "textarea";
  public SELECT_TYPE = "select";
  public CHECKBOX_TYPE = "checkbox";

  @Input() alertOptions: CustonAlertOptions = new CustonAlertOptions();

  @ViewChild('CustonAlertModalContent', { read: ElementRef, static: false }) custonAlertModalContent: ElementRef;
  @ViewChild('form', { read: ElementRef, static: false }) form: FormGroup;

  constructor(
    public overlayCtrl: OverlayService,
  ) { }
  ngOnInit() {

    this.overlay.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {

        let btnTexts = ['OK', 'SIM'];
        for (let btn of this.alertOptions.buttons) {
          if (btnTexts.indexOf(btn.text.toUpperCase()) != -1) {
            if (!btn.needFormValid || !this.form || this.form.valid) {
              let formValue = undefined;
              if (this.form)
                formValue = this.form.value;

              this.close();
              btn.handler(formValue);
              break;
            }
          }
        }

      }
    });

  }
  ngAfterViewInit() {
    this._setMessageHTML(this.alertOptions.message);
  }
  ngOnDestroy() { }
  close() {
    this.overlayCtrl.closeOverlay(this.id);
  }
  closeButton() {
    this.close();
    this.alertOptions.closeButtonHandler();
  }
  clickButton(handler, formValue) {
    this.close();
    handler(formValue);
  }

  private _setMessageHTML(message: string) {
    const retryDelayMs = 50;
    let messageElemendId = `alert-message-${this.id}`;
    const element = document.getElementById(messageElemendId);
    if (element)
      document.getElementById(messageElemendId).innerHTML = message;
    else
      setTimeout(() => {
        this._setMessageHTML(message);
      }, retryDelayMs);
  }
  getClassesForInput(input: CustonAlertInputOptions): string[] {
    let classes: string[] = [input.type];
    if (input.classes)
      classes.push(...input.classes);
    return classes;
  }
  getClassesForBtn(btn: CustonAlertButton): string[] {
    let classes: string[] = [];
    if (btn.classes)
      classes.push(...btn.classes);
    return classes;
  }
}
