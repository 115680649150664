import { SM_DocImpact, SM_DocUrgency } from "./SM_Document";

/**
 * @P1 Critical
 * @P2 Important
 * @P3 Normal
 * @P4 Low
 * @P5 Planning
 */
export type SM_DocPriority = 'P1' | 'P2' | 'P3' | 'P4' | 'P5';


export function calculatePriority(impact: SM_DocImpact, urgency: SM_DocUrgency): SM_DocPriority {
  let priority: SM_DocPriority = "P5";

  if (impact == 1) {
    if (urgency == 1)
      priority = "P5";
    else
      if (urgency == 2)
        priority = "P4";
      else
        if (urgency == 3)
          priority = "P3";
  } else

    if (impact == 2) {
      if (urgency == 1)
        priority = "P4";
      else
        if (urgency == 2)
          priority = "P3";
        else
          if (urgency == 3)
            priority = "P2";
    } else

      if (impact == 3) {
        if (urgency == 1)
          priority = "P3";
        else
          if (urgency == 2)
            priority = "P2";
          else
            if (urgency == 3)
              priority = "P1";
      }
  return priority;
}

