export const DAYS_OF_WEEK = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
export const MONTHS_SHORT = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
export const MONTHS_LONG = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
export const MONTHS = MONTHS_SHORT;
export const TIMES = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'];


export const SYS_FILE_GENERIC_TYPE_ICON = {
    extensions: ["*"],
    icon: "file",
    pack: "fas",
    colorHex: "#7F7F7F"
};
export const SYS_FILE_TYPES_ICONS = [
    {
        extensions: ["xlsx", "xls"],
        icon: "file-excel",
        pack: "fas",
        colorHex: "#1D6F42"
    },
    {
        extensions: ["docx", "doc"],
        icon: "file-word",
        pack: "fas",
        colorHex: "#2B579A"
    },
    {
        extensions: ["pptx", "ppt"],
        icon: "file-powerpoint",
        pack: "fas",
        colorHex: "#D24726"
    },
    {
        extensions: ["pdf"],
        icon: "file-pdf",
        pack: "fas",
        colorHex: "#E52F2B"
    },
    {
        extensions: ["jpg", "jpeg", "png", "gif", "bmp"],
        icon: "file-image",
        pack: "fas",
        colorHex: "#FFB900"
    },
    {
        extensions: ["zip", "rar", "7z", "tar", "gz"],
        icon: "file-archive",
        pack: "fas",
        colorHex: "#B30E0E"
    },
    {
        extensions: ["html", "css", "js", "ts", "json", "xml"],
        icon: "file-code",
        pack: "fas",
        colorHex: "#E44D26"
    },
    {
        extensions: ["mp3", "wav", "ogg", "flac"],
        icon: "file-audio",
        pack: "fas",
        colorHex: "#1DB954"
    },
    {
        extensions: ["mp4", "avi", "mkv", "mov"],
        icon: "file-video",
        pack: "fas",
        colorHex: "#FF5733"
    },
    {
        extensions: ["txt", "rtf"],
        icon: "file-text",
        pack: "fas",
        colorHex: "#4A4A4A"
    },
    {
        extensions: ["msg", "eml"],
        icon: "envelope",
        pack: "fas",
        colorHex: "#3F729B"
    },
    {
        extensions: ["csv"],
        icon: "file-csv",
        pack: "fas",
        colorHex: "#1D6F42"
    },
    SYS_FILE_GENERIC_TYPE_ICON
];
