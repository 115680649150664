import firebase from "firebase/compat/app";

export class SysPage {

  id: string;
  name: string;
  url: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<SysPage>) {

    this.id = '';
    this.name = '';
    this.url = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
