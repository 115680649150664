import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';

import { AuthService, LayoutService, SmartTableAuxService, UtilService } from 'app/@core';
import { _parseRuleSet, _stringifyRuleSet, DB_DataResult, DB_DataSource, FirestoreService } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';
import { ThemeService } from 'app/@theme/theme.service';
import { DbRulesUiComponent } from 'app/components/@db-rules-ui/db-rules-ui.component';
import { DB_DataCoreType, loadDataCore } from 'app/routes/main/sys/db/DB_DataCoreType';

import { DataVisualizationComponent } from '../data-visualization/data-visualization.component';
import { DataVisualization } from '../types';
import { EditorFieldsComponent } from './source-tab/editor-fields/editor-fields.component';
import { EditorEngineConfigComponent } from './vis-tab/editor-engine-config/editor-engine-config.component';
import { EditorEngineDisplayComponent } from './vis-tab/editor-engine-display/editor-engine-display.component';

@Component({
  selector: 'data-visualization-editor',
  styleUrls: ['./data-visualization-editor.component.scss'],
  templateUrl: './data-visualization-editor.component.html',
})
export class DataVisualizationEditorComponent implements OnInit {

  className = "DataVisualizationEditorComponent";

  loader = false;

  @ViewChild('DataVisualizationERef') dataVis: DataVisualizationComponent = new DataVisualizationComponent(this.layoutService, this.theme, this.db);

  // ERefs
  @ViewChild('DbRulesUiERef') dbRulesUiERef: DbRulesUiComponent = new DbRulesUiComponent(this.authService, this.db, this.uiFeedBackCtrl, this.utilCtrl);
  @ViewChild('EditorFieldsERef') editorFields: EditorFieldsComponent = new EditorFieldsComponent();

  @ViewChild('EditorEngineConfigERef') editorEngineConfig: EditorEngineConfigComponent = new EditorEngineConfigComponent();
  @ViewChild('EditorEngineDisplayERef') editorEngineDisplay: EditorEngineDisplayComponent = new EditorEngineDisplayComponent(this.db);
  // ERefs

  @Input() id: string = '';
  @Input() title: string = '';

  oldVis: DataVisualization = new DataVisualization();
  vis: DataVisualization = new DataVisualization();
  @Output() onChange = new EventEmitter();
  @Input() hasChanges = false;

  @Input() edit = false;

  _tabSource_selectedDataSource = new DB_DataSource();
  _tabSource_dataSource_source = new LocalDataSource;
  _tabSource_input_max_n_of_hits = 0;
  _tabSource_table_data = [];
  _tabSource_table_columns = [];

  dataCore = new DB_DataCoreType();
  DB_DsDataResult: DB_DataResult = new DB_DataResult();

  constructor(
    private layoutService: LayoutService,
    private theme: ThemeService,
    public db: FirestoreService,
    public uiFeedBackCtrl: UiFeedBackService,
    public smartTableAuxService: SmartTableAuxService,
    private authService: AuthService,
    public utilCtrl: UtilService,
  ) { }

  ngOnInit() { }
  ngOnDestroy(): void { }

  load(vis?: DataVisualization) {

    if (!vis) {
      this._tabSource_loadPage();
      return;
    }

    this.loader = true;
    this.oldVis = { ...vis };
    this.vis = { ...vis };
    this.hasChanges = false;

    loadDataCore(this.db)
      .then(dataCore => {
        this.dataCore = dataCore;
        this._tabSource_dataSource_source = this.smartTableAuxService.dataSourceGenerators.sys.db.dataSources(this.dataCore.dataSources) as any;

        this._tabSource_input_max_n_of_hits = this.vis.source.limit;

        if (this.vis.source.dbDataSourceId != '') {

          let ds = dataCore.dataSources.filter(ds => { return ds.id == this.vis.source.dbDataSourceId; })[0]

          if (!ds) {
            this.loader = false;
            console.error('dbDataSourceId not found!')
          } else {
            this._tabSource_selectedDataSource = ds;

            return this._tabSource_run()
              .then(() => {

                // -> load Components
                this.editorFields.loadFromVis(vis, this.DB_DsDataResult);
                this.dbRulesUiERef.load(vis.source.dbDataSourceId, _parseRuleSet(vis.source.filterRuleSetJson), vis.source.orderByRule);

                this.editorEngineConfig.loadFromVis(vis, this.DB_DsDataResult);
                this.editorEngineDisplay.load(vis);
                // -> load Components

                this._sync_tabSource_table();

                this.loader = false;

              })
              .catch((e) => {
                this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
              })
          }
        } else
          this.loader = false;

      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }
  getVis(): DataVisualization {
    return { ...this.vis };
  }

  _trackByFn(index, item) {
    return index;
  }
  // _tabSource
  _tabSource_loadPage() {
    this.loader = true;
    loadDataCore(this.db)
      .then(dataCore => {
        this.dataCore = dataCore;
        this._tabSource_dataSource_source = this.smartTableAuxService.dataSourceGenerators.sys.db.dataSources(this.dataCore.dataSources) as any;
        this.loader = false;
      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
      })
  }
  _tabSource_selectDS() {
    this.uiFeedBackCtrl.showTableSelect(this.smartTableAuxService.columns.sys.db.dataSources, this._tabSource_dataSource_source)
      .then(ds => {
        if (!ds)
          return;
        this._tabSource_selectedDataSource = ds;
        this.vis.source.dbDataSourceId = ds.id;
        this._tabSource_change();
        this._tabSource_run()
          .then(() => {
            this.vis.source.filterRuleSetJson = '';
            this.vis.source.orderByRule = { fieldId: 'id', direction: 'asc' };

            // sync
            this.dbRulesUiERef.load(this._tabSource_selectedDataSource.id, _parseRuleSet(this.vis.source.filterRuleSetJson), this.vis.source.orderByRule);
            this.editorFields.loadFromDsDataResult(this.DB_DsDataResult);

            this.editorEngineConfig.loadFromDsDataResult(this.DB_DsDataResult, this.vis.source);
            // sync

            this._sync_tabSource_table();
          })
      })
  }
  _tabSource_run(): Promise<void> {
    return new Promise((resolve) => {
      this.loader = true;
      this.db.dataSource.get(this._tabSource_selectedDataSource, this._tabSource_input_max_n_of_hits, _parseRuleSet(this.vis.source.filterRuleSetJson), this.vis.source.orderByRule)
        .then(data => {
          this.DB_DsDataResult = data;

          // sync
          this.editorEngineConfig.loadFromDsDataResult(this.DB_DsDataResult, this.vis.source);
          // sync

          this._sync_tabSource_table();
          this.loader = false;
          resolve();
        })
        .catch((e) => {
          this.uiFeedBackCtrl.presentErrorAlert('', this.className, this.authService.localUser.uName, 'Erro', e);
          this.loader = false;
        })
    });
  }
  _sync_tabSource_table() {
    this._tabSource_table_columns = [];
    this._tabSource_table_data = [];
    this._tabSource_table_columns = this.db.dataSource.convertFieldsDataToTableColumns(this.editorFields.getFieldsForTable());
    this._tabSource_table_data = JSON.parse(JSON.stringify(this.DB_DsDataResult.data));
  }
  _tabSource_change() {
    this.vis.source.limit = this._tabSource_input_max_n_of_hits;
    this._sync_tabSource_table();
    this._change();
  }
  // _tabSource


  _fieldsChange() {
    this._sync_tabSource_table();
    this.vis.source.fields = this.editorFields.getFields();

    // sync
    this.editorEngineConfig.loadFromDsDataResult(this.DB_DsDataResult, this.vis.source);
    // sync

    console.log('Vis Editor -> vis: ', this.vis);
    this._sync_tabSource_table();
    this._change();
  }
  _dbRulesChange() {
    let dbRulesUiData = this.dbRulesUiERef.getData();
    this.vis.source.filterRuleSetJson = _stringifyRuleSet(dbRulesUiData.filter);
    this.vis.source.orderByRule = dbRulesUiData.orderBy;
    console.log('Vis Editor -> vis: ', this.vis);
    this._sync_tabSource_table();
    this._change();
  }
  _engineConfigChange() {
    this.vis.typeId = this.editorEngineConfig.getVisTypeId();
    this.vis.engine.data = this.editorEngineConfig.getVisEngineData();
    this.editorEngineDisplay.load(this.vis);
    this._change();
  }
  _engineDisplayChange() {
    this.vis.engine.customize = this.editorEngineDisplay.getVisEngineCustomize();
    this._change();
  }
  _dataVisEnigneReady(data) {
    this.editorEngineDisplay.updateOptions(data.echartsOptions, this.dataCore, this._tabSource_selectedDataSource, this.DB_DsDataResult);
  }
  _change() {
    setTimeout(() => {
      this.dataVis.load();
      this.checkForChanges();
      this.onChange.emit(this.vis);
    }, 250);
  }
  checkForChanges() {
    if (
      this.dbRulesUiERef.hasChanges ||
      this.editorFields.hasChanges ||
      this.editorEngineConfig.hasChanges ||
      this.editorEngineDisplay.hasChanges
    )
      this.hasChanges = true
    else
      this.hasChanges = false
  }
}
