import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

import { MagicControlComponent } from './magic-control.component';

@Component({
  selector: 'magic-control-option',
  template: ``,
}) export class MagicControlOptionComponent implements OnInit, OnDestroy {
  @Input() title: string = "";
  @Input() value: any;
  constructor(public _select: MagicControlComponent) {
  };
  ngOnInit() {
    this._select.writeValue({ title: this.title, data: this.value })
  }
  ngOnDestroy(): void {
    this._select.remValue({ title: this.title, data: this.value })
  };
}
