import { getLocaleFirstDayOfWeek } from "@angular/common";

export class WeekUtil {
  locale: string = '';
  constructor(_locale: string) {
    this.locale = _locale;
  }
  _getFirstDayOfWeek() {
    return getLocaleFirstDayOfWeek(this.locale);
  }
  /**
   * Get week number in the year.
   * @param  {Date} [date]
   * @param  {Integer} [weekStart=0]  First day of the week. 0-based. 0 for Sunday, 6 for Saturday.
   * @return {Integer}                0-based number of week.
   */
  getDate_Week(date: Date, weekStart = this._getFirstDayOfWeek()): number {
    let januaryFirst = new Date(date.getFullYear(), 0, 1);
    if (weekStart !== undefined && (typeof weekStart !== 'number' || weekStart % 1 !== 0 || weekStart < 0 || weekStart > 6)) {
      throw new Error('Wrong argument. Must be an integer between 0 and 6.');
    }
    weekStart = weekStart || 0;
    let tempWeekNo = Math.floor((((date.getTime() - januaryFirst.getTime()) / 86400000) + januaryFirst.getDay() - weekStart) / 7);
    if (tempWeekNo == 0)
      tempWeekNo = 1;
    return tempWeekNo;
  }
  getFirstMondayOfWeek(weekNo) {
    var firstMonday = new Date(new Date().getFullYear(), 0, 4, 0, 0, 0, 0);

    while (firstMonday.getDay() != 1) {
      firstMonday.setDate(firstMonday.getDate() - 1);
    }
    if (1 <= weekNo && weekNo <= 52)
      return firstMonday.setDate(firstMonday.getDate() + 7 * (weekNo - 1));

    firstMonday.setDate(firstMonday.getDate() + 7 * (weekNo - 1));
    if (weekNo = 53 && firstMonday.getDate() >= 22 && firstMonday.getDate() <= 28)
      return firstMonday;
    return null;
  }
}
