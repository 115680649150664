import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';

import { AuthService } from 'app/@core';
import { DB_MagicQuery, Filter, FirestoreService, ReportPreferences, ReportPreferencesFilterMap, ReportPreferencesOrderMap } from 'app/@firebase';
import { UiFeedBackService } from 'app/@theme';

import { environment } from '../../../../environments/environment';
import { REPORTING_EMPTY_ARRAY_DATA_VALUE, REPORTING_EMPTY_ARRAY_DISP_VALUE, REPORTING_REPORT_LINK_URL_PARAMETER_NAME } from '../report.consts';
import { ReportColumn, ReportingOrderDirection, ReportingPreferencesModeType, ReportingSortField, ReportingUiFilterIndex, ReportingUiFilterIndexData } from '../report.types';
import { _compareFunction, _genFilterValue, _localSort, _orderByCore } from '../report.util';

type OrderMapType = Record<string, ReportingOrderDirection>;
type FiltersActiveMapType = Record<string, boolean>;
type FiltersErrorMapType = Record<string, boolean>;
type FiltersOperatorMapType = Record<string, string>;
type FiltersValuesMapType = Record<string, ReportingUiFilterIndexData[]>;

@Injectable()
export class ReportingService {

    id: string = "";
    reportPageClassName: string = "";
    reportPageUrl: string = "";
    linkReportPreferenceId: string = "";

    selectedReportPref: ReportPreferences = null;
    reportPreferences: ReportPreferences[] = [];

    columns: ReportColumn[] = [];
    aditionalData?: any;

    public data: object[] = [];
    public filtredData: object[] = [];

    _orderMap: OrderMapType = {};
    _filtersActiveMap: FiltersActiveMapType = {};
    // todo - Adicionar no retorno na query a possibilidade de retornar os filtros que nao possuem resultados possiveis, ou seja, estão com erro.
    _filtersErrorMap: FiltersErrorMapType = {};
    _filtersOperatorMap: FiltersOperatorMapType = {};
    _filtersValuesMap: FiltersValuesMapType = {};

    private query: DB_MagicQuery = new DB_MagicQuery();
    private _filtersSub: Subscriber<object[]> = null;
    private _filtredDataSub: Subscriber<object[]> = null;
    private _routeSubs = null;

    public _reportingPreferencesMode: ReportingPreferencesModeType = "pref-list";
    public _editColumns = true;
    public loading = false;
    public showLoaderFunction = () => { };
    public hideLoaderFunction = () => { };

    constructor(
        private uiFeedBackCtrl: UiFeedBackService,
        private db: FirestoreService,
        private authService: AuthService,
        private route: ActivatedRoute,
    ) {
        this.id = this.db.createTimeId();
    }

    private _showLoader() {
        this.loading = true;
        this.showLoaderFunction();
    }
    private _hideLoader() {
        this.loading = false;
        this.hideLoaderFunction();
    }
    private _logger(message?: any, ...optionalParams: any[]) {
        if (!environment.production)
            console.log(message, ...optionalParams);
    }

    private _queryMaker(): DB_MagicQuery {
        let tempQuery: DB_MagicQuery = JSON.parse(JSON.stringify(this.query));

        for (let col of this.columns) {
            if (
                // is a ui column
                col.show &&
                // is active
                this._filtersActiveMap[col.field] &&
                // data is not make locally
                !col.dataPrepareFunction
            ) {
                const values = this._filtersValuesMap[col.field].filter((f) => { return f.show }).map((f) => f.value)
                let tempFilters: Filter[] = [];
                values.forEach((v) => {
                    tempFilters.push({
                        outputField: col.field,
                        operator: (!col.isArray || v == REPORTING_EMPTY_ARRAY_DATA_VALUE) ? "==" : "array-contains",
                        value: v != REPORTING_EMPTY_ARRAY_DATA_VALUE ? v : []
                    })
                })
                tempQuery.conditions[0].groupOperator = "AND";
                tempQuery.conditions[0].filterGroups.push({
                    filters: tempFilters,
                    operator: "OR"
                })
            }

        }

        this._logger("ReportingService -> queryMaker", tempQuery);
        return tempQuery;
    }
    loadColumns(columns: ReportColumn[]) {
        let tempColumns: ReportColumn[] = [];
        columns.forEach((c) => {
            tempColumns.push(new ReportColumn(c));
        });
        this.columns = tempColumns;
        this._emitOnFiltersChange();
    }
    load(reportPageClassName: string, reportPageUrl: string, query: DB_MagicQuery, columns: ReportColumn[], aditionalData?: any): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                this.reportPageClassName = reportPageClassName;
                this.reportPageUrl = reportPageUrl;
                this._showLoader();

                this._logger("ReportingService -> load", query, columns, aditionalData);

                this.query = JSON.parse(JSON.stringify(query));
                this.aditionalData = aditionalData;

                this.loadColumns(columns);

                // reportPreferences
                this.reportPreferences = await this.loadReportPreferences();
                const presetReportPref = this.reportPreferences.filter((rp) => { return rp.presetUnames.includes(this.authService.localUser.uName) })[0];
                let linkReportPref = null;

                // -- linkReportPreference
                this.linkReportPreferenceId = this.route.snapshot.queryParamMap.has(REPORTING_REPORT_LINK_URL_PARAMETER_NAME) ? this.route.snapshot.queryParamMap.get(REPORTING_REPORT_LINK_URL_PARAMETER_NAME) : "";
                if (this.linkReportPreferenceId) {
                    const tempLinkReportPref = this.reportPreferences.filter((rp) => { return rp.id == this.linkReportPreferenceId })[0];
                    if (tempLinkReportPref)
                        linkReportPref = tempLinkReportPref;
                }

                if (this.linkReportPreferenceId && !linkReportPref)
                    this.uiFeedBackCtrl.presentAlert('Acessos insuficientes!', `Voce não possui acessos suficientes para acessar este relatório! Solicite acesso ao proprietário do mesmo.`, 'warning');

                if (linkReportPref)
                    this.selectReportPref(linkReportPref)

                if (!linkReportPref && presetReportPref)
                    this.selectReportPref(presetReportPref);

                if (this.selectedReportPref)
                    this.syncColumnsIndexes();
                // reportPreferences

                this.data = await this.getData(this._queryMaker());

                this.syncColumnsIndexes();

                await this.updateFiltredData();

                this._hideLoader();
                resolve();
            } catch (e) {
                this._hideLoader();
                reject(e);
            }
        });
    }
    loadCleanData(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                this._showLoader();
                this.data = await this.getData(this.query);
                this.syncColumnsIndexes();
                await this.updateFiltredDataLocally4Indexes();
                await this.updateFiltredData();

                this._hideLoader();
                resolve();
            } catch (e) {
                this._hideLoader();
                reject(e);
            }
        });
    }
    cleanFilters() {
        for (let col of this.columns) {
            if (this._filtersActiveMap[col.field]) {
                for (let fi of this._filtersValuesMap[col.field])
                    this._updateFilterStatus(col.field, fi.value, true);
                this._filtersActiveMap[col.field] = false;
            }
        }
    }
    cleanOrd() {
        for (let col_index = 0; col_index < this.columns.length; col_index++) {
            const col = this.columns[col_index];
            this._orderMap[col.field] = null;
        }
    }
    selectReportPref(rp: ReportPreferences): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                this._showLoader();

                this._orderMap = {};
                this._filtersActiveMap = {};
                this._filtersValuesMap = {};

                this.selectedReportPref = rp;
                this._reportingPreferencesMode = "pref-selected";
                this._editColumns = false;

                // prepara os indexes para rodar a query
                this.syncColumnsIndexes();

                await this.updateFiltredData()

                // Atualiza indexes para seleção
                this.syncColumnsIndexes();

                this._hideLoader();
                resolve();
            } catch (e) {
                this._hideLoader();
                reject(e);
            }
        });
    }
    private async loadReportPreferences(): Promise<ReportPreferences[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const query1 = this.db.sys.reportPreferences.ref
                    .where('className', '==', this.reportPageClassName)
                    .where('uName', '==', this.authService.localUser.uName)
                    .get({ source: "server" });

                const query2 = this.db.sys.reportPreferences.ref
                    .where('className', '==', this.reportPageClassName)
                    .where('sharedWithUnames', 'array-contains', this.authService.localUser.uName)
                    .get({ source: "server" });

                const query3 = this.db.sys.reportPreferences.ref
                    .where('className', '==', this.reportPageClassName)
                    .where('isPrivate', '==', false)
                    .get({ source: "server" });

                const [querySnapshot1, querySnapshot2, querySnapshot3] = await Promise.all([query1, query2, query3]);

                // Combine results from both queries
                const reportPreferences = [
                    ...querySnapshot1.docs.map((d) => d.data()),
                    ...querySnapshot2.docs.map((d) => d.data()),
                    ...querySnapshot3.docs.map((d) => d.data()),
                ];

                // Remove duplicates
                const uniqueReportPreferences = Array.from(new Set(reportPreferences.map(pref => JSON.stringify(pref))))
                    .map(str => JSON.parse(str)) as ReportPreferences[];

                uniqueReportPreferences.sort((a, b) => _orderByCore(a.title, b.title, "asc"));

                resolve(uniqueReportPreferences);
            } catch (error) {
                reject(error);
            }
        });
    }
    public updateFiltredDataLocally4Indexes(): Promise<void> {
        return this.updateFiltredData(true);
    }
    public updateFiltredData(onlyLocally: boolean = false): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                this._showLoader();

                let tempData = this.data;

                if (!onlyLocally)
                    tempData = await this.getData(this._queryMaker());

                // filter data
                let tempFiltredData = [];
                for (let d of tempData) {
                    let valid = true;
                    for (const col of this.columns) {
                        if (this._filtersActiveMap[col.field] && col.dataPrepareFunction) {
                            valid = this._getFilterStatus(col.field, d[col.field]);
                            if (!valid)
                                break;
                        }
                    }
                    if (valid)
                        tempFiltredData.push(d);
                }

                // sort data
                let sortFields: ReportingSortField[] = [];
                Object.entries(this._orderMap).forEach(([fieldName, direction]) => {
                    if (direction)
                        sortFields.push({ fieldName: fieldName, direction: direction });
                });
                tempData = _localSort(tempData, sortFields);

                this.filtredData = tempFiltredData;

                if (!onlyLocally)
                    this._emitOnFiltredDataChange();

                this._hideLoader();
                resolve();
            } catch (e) {
                this._hideLoader();
                reject(e);
            }
        });
    }


    // data 
    getData(query: DB_MagicQuery): Promise<object[]> {
        return new Promise((resolve, reject) => {
            this.db.magicQuery.get(query)
                .then(data => {
                    let tempData = this._dataPrepare(data);
                    resolve(tempData);
                })
                .catch(e => reject(e))
        })
    }
    private _dataPrepare(data: object[]): object[] {
        let tempNewData = [];
        data.forEach((d) => {
            for (let col_index = 0; col_index < this.columns.length; col_index++)
                d[this.columns[col_index].field] = this._dataPrepareFunction(this.columns[col_index], d);
            tempNewData.push(d);
        })
        return tempNewData;
    }
    private _dataPrepareFunction(col: ReportColumn, object?: any): string | number | string[] | number[] {
        let fieldValue = null;
        let rawValue = object[col.field];

        if (Array.isArray(rawValue) && this._orderMap[col.field])
            rawValue.sort((a, b) => _orderByCore(a, b, this._orderMap[col.field]));

        if (col.dataPrepareFunction)
            fieldValue = col.dataPrepareFunction(rawValue, object, this.aditionalData);
        else
            fieldValue = rawValue;

        return fieldValue;
    }





    private _emitOnFiltersChange() {
        // if (this._filtersSub)
        //     this._filtersSub.next(this.filters);
    }
    public onFiltersChange(): Observable<Notification[]> {
        return new Observable<Notification[]>(subscriber => {
            // Keep track of the Documents Changes
            this._filtersSub = subscriber;
            this._filtersSub.next(this.filtredData);

            // Provide a way of canceling and disposing the interval resource
            const _that = this;
            return function unsubscribe() {
                _that._filtersSub = null;
            };
        });
    }
    private _emitOnFiltredDataChange() {
        this._logger("ReportingService -> FiltredDataChange", this.filtredData);
        if (this._filtredDataSub)
            this._filtredDataSub.next(this.filtredData);
    }
    public onFiltredDataChange(): Observable<Notification[]> {
        return new Observable<Notification[]>(subscriber => {
            // Keep track of the Documents Changes
            this._filtredDataSub = subscriber;
            this._filtredDataSub.next(this.filtredData);

            // Provide a way of canceling and disposing the interval resource
            const _that = this;
            return function unsubscribe() {
                _that._filtredDataSub = null;
            };
        });
    }

    _updateFilterStatus(field: string, filterValue: string, status: boolean) {
        for (let f of this._filtersValuesMap[field])
            if (_compareFunction(f.value, filterValue)) {
                f.show = status;
                break;
            }
    }
    _getFilterStatus(field: string, value: any): boolean {
        let tempShow = true;
        if (!Array.isArray(value)) {
            if (this._filtersValuesMap[field])
                for (let f of this._filtersValuesMap[field])
                    if (_compareFunction(f.value, value)) {
                        tempShow = f.show;
                        break;
                    }
        } else {
            let showsFieldData = this._filtersValuesMap[field].filter((f) => f.show).map((f) => f.value);
            tempShow = showsFieldData.some((val: any) => Array.isArray(value) && value.includes(val));
        }
        return tempShow;
    }
    _filterDisplayValuePrepareFunction(col: ReportColumn, object?: any): string | number | string[] | number[] {
        const rawValue = object[col.field];
        let fieldDispValue = null;

        if (col.show && col.filterData && col.filterData.displayValuePrepareFunction) {
            // local filter
            if (!Array.isArray(rawValue)) {
                // not array
                fieldDispValue = _genFilterValue(
                    col.filterData.displayValuePrepareFunction(rawValue, object, this.aditionalData)
                );
            } else {
                // array
                fieldDispValue = [];
                for (const v of rawValue)
                    fieldDispValue.push(
                        _genFilterValue(col.filterData.displayValuePrepareFunction(v, object, this.aditionalData))
                    );
                // empty array
                if (rawValue.length == 0)
                    return [REPORTING_EMPTY_ARRAY_DISP_VALUE];
            }
        } else {
            // query filter
            fieldDispValue = _genFilterValue(rawValue);
        }

        return fieldDispValue;
    }

    private syncColumnsIndexes() {

        let tempFilterValuesMap: FiltersValuesMapType = {};
        let tempFilterActiveMap: FiltersActiveMapType = {};
        let tempOrderMap: OrderMapType = {};

        for (const col of this.columns) {
            let tempFilterIndex: ReportingUiFilterIndex = [];
            let tempSingleValues: any[] = [];
            let tempFilterActive = false;

            // filter index maker
            if (col.show) {
                // from data
                for (let dataObj of this.data) {
                    const fieldValue = dataObj[col.field];
                    const fieldDispValue = this._filterDisplayValuePrepareFunction(col, dataObj);

                    if (!Array.isArray(fieldValue)) {
                        // not array
                        if (tempSingleValues.indexOf(fieldDispValue) == -1) {
                            tempSingleValues.push(fieldDispValue)
                            let show = this._getFilterStatus(col.field, fieldDispValue);
                            if (!show)
                                tempFilterActive = true;
                            tempFilterIndex.push({ show: show, value: fieldValue, dispValue: fieldDispValue as any })
                        }
                    } else {
                        // array
                        for (let i = 0; i < fieldValue.length; i++) {
                            const fv = fieldValue[i];
                            const fdv = fieldDispValue[i];
                            if (tempSingleValues.indexOf(fdv) == -1) {
                                tempSingleValues.push(fdv)
                                let show = this._getFilterStatus(col.field, fv);
                                if (!show)
                                    tempFilterActive = true;
                                tempFilterIndex.push({ show: show, value: fv, dispValue: fdv as any })
                            }
                        }
                        // empty array
                        if (fieldValue.length == 0) {
                            if (tempSingleValues.indexOf(REPORTING_EMPTY_ARRAY_DISP_VALUE) == -1) {
                                tempSingleValues.push(REPORTING_EMPTY_ARRAY_DISP_VALUE);
                                let show = this._getFilterStatus(col.field, REPORTING_EMPTY_ARRAY_DATA_VALUE);
                                tempFilterIndex.push({ show: show, value: REPORTING_EMPTY_ARRAY_DATA_VALUE, dispValue: REPORTING_EMPTY_ARRAY_DISP_VALUE })
                            }
                        }
                    }
                }

                // from reportPreferences
                if (this.selectedReportPref) {
                    if (this.selectedReportPref.filterMap[col.field]) {
                        tempFilterActive = true;
                        tempFilterIndex.forEach((fi) => { fi.show = false });
                        this.selectedReportPref.filterMap[col.field]
                            .forEach((fmv) => {
                                let found = false;
                                for (let i = 0; i < tempFilterIndex.length; i++) {
                                    if (_compareFunction(tempFilterIndex[i].value, fmv.value)) {
                                        tempFilterIndex[i].show = fmv.show;
                                        found = true;
                                        break;
                                    }
                                }
                                if (!found)
                                    tempFilterIndex.push({ show: fmv.show, value: fmv.value, dispValue: "" });
                            })
                    }

                    if (this.selectedReportPref.orderMap[col.field])
                        tempOrderMap[col.field] = this.selectedReportPref.orderMap[col.field];
                }
            }


            const ord = this._orderMap[col.field] ? this._orderMap[col.field] : "asc";
            tempFilterIndex.sort((a, b) => _orderByCore(a.value, b.value, ord));
            // filter index maker

            tempFilterValuesMap[col.field] = tempFilterIndex;
            tempFilterActiveMap[col.field] = tempFilterActive;
            if (!tempOrderMap[col.field])
                tempOrderMap[col.field] = this._orderMap[col.field] ? null : this._orderMap[col.field];
        }

        this._filtersValuesMap = tempFilterValuesMap;
        this._filtersActiveMap = tempFilterActiveMap;
        this._orderMap = tempOrderMap;

        this._logger('_filtersValuesMap', this._filtersValuesMap);
    }


    getSelectedReportPrefLink(): string {
        return window.location.origin + "/#" + this.reportPageUrl + "?" + REPORTING_REPORT_LINK_URL_PARAMETER_NAME + "=" + this.selectedReportPref.id;
    }
    getFilterMap(): ReportPreferencesFilterMap {
        let tempFilterMap: ReportPreferencesFilterMap = {};
        for (const col of this.columns) {
            if (this._filtersActiveMap[col.field]) {
                if (!tempFilterMap[col.field])
                    tempFilterMap[col.field] = []

                this._filtersValuesMap[col.field].forEach((fv) => {
                    tempFilterMap[col.field].push({
                        show: fv.show,
                        value: fv.value != REPORTING_EMPTY_ARRAY_DATA_VALUE ? fv.value : []
                    })
                })
            }
        }
        return tempFilterMap;
    }
    getOrderMap(): ReportPreferencesOrderMap {
        let tempOrderMap: ReportPreferencesOrderMap = {};
        for (const col of this.columns) {
            if (this._orderMap[col.field])
                tempOrderMap[col.field] = this._orderMap[col.field];
        }
        return tempOrderMap;
    }
}
