import firebase from "firebase/compat/app";

// https://docs.servicenow.com/bundle/sandiego-it-service-management/page/product/service-level-management/reference/r_SLATransitions.html

// ServiceNow - Retroactive Pause - SLA Condition
// https://docs.servicenow.com/pt-BR/bundle/quebec-it-service-management/page/product/service-level-management/task/t_UseSLARetroactiveStartAndPause.html
// https://www.youtube.com/watch?v=DKv4LAZSH-0&ab_channel=AAspenNowSolutions
// ServiceNow - Retroactive Pause - SLA Condition

// https://www.youtube.com/watch?v=1_T2VtFbldQ&ab_channel=AAspenNowSolutions

export class SM_SLADefinition {

  id: string;
  status: 0 | 1;
  name: string;

  type: 'SLA' | 'OLA' | 'UC';
  target: '' | 'response' | 'resolution';
  calendarId: string;

  durationMs: number;

  startCondition: {
    retroactiveStart: boolean;
    startTimeFieldId: string;
    retroactivePause: boolean;
    startConditionJson: string;
    whemToCancel: 'no_match' | 'on_condition' | 'never';
    cancelConditionsJson: string;
  };
  pauseCondition: {
    pauseConditionJson: string;
    whenToResume: 'no_match' | 'on_condition';
    resumeConditionJson: string;
  };
  stopCondition: {
    stopConditionJson: string;
  };
  resetCondition: {
    resetConditionJson: string;
    resetAction: 'cancel' | 'complete';
  };

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<SM_SLADefinition>) {

    this.id = '';
    this.status = 0;
    this.name = '';

    this.type = 'SLA';
    this.target = '';
    this.calendarId = '';

    this.durationMs = 0;

    this.startCondition = {
      retroactiveStart: true,
      startTimeFieldId: '',
      retroactivePause: true,
      startConditionJson: '',
      whemToCancel: 'no_match',
      cancelConditionsJson: ''
    };
    this.pauseCondition = {
      pauseConditionJson: '',
      whenToResume: 'no_match',
      resumeConditionJson: ''
    };
    this.stopCondition = {
      stopConditionJson: ''
    };
    this.resetCondition = {
      resetConditionJson: '',
      resetAction: 'cancel'
    };

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
