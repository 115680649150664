import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';

import { CustomOpenBtnRendererComponent } from './custom-open-btn-renderer.component';
import { SmartTableColumns } from './types';

//https://stackoverflow.com/questions/47966752/ng2-smart-table-how-to-hide-the-delete-icon-depending-on-column-value

@Component({
  selector: 'smart-table',
  styleUrls: ['./smart-table.component.scss'],
  templateUrl: './smart-table.component.html',
})
export class SmartTableComponent implements OnInit {

  @Input() columns: SmartTableColumns;
  @Input() source: LocalDataSource;
  @Input() showOpenBtn = false;
  @Input() showAdminBtns = false;
  @Input() showAddBtn = false;
  @Input() showEditBtn = false;
  @Input() showDeleteBtn = false;

  @Output() open = new EventEmitter();
  @Output() create = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();

  showTable = true;

  nstSettings = {

    mode: 'external',
    noDataMessage: 'Não Existem Dados.',
    pager: {
      display: true,
      perPage: 10,
    },

    add: {
      addButtonContent: '<i class="nb-compose"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
    },

    actions: {
      columnTitle: 'Ações',
      edit: false,
      delete: false,
      add: false,
      position: 'right',
    },
    columns: {},
  };

  constructor() { }

  ngOnInit() {
    if (this.showAdminBtns) {
      this.nstSettings.actions.add = true;
      this.nstSettings.actions.edit = true;
      this.nstSettings.actions.delete = true;
    } else {
      this.nstSettings.actions.add = this.showAddBtn;
      this.nstSettings.actions.edit = this.showEditBtn;
      this.nstSettings.actions.delete = this.showDeleteBtn;
    }

    var self = this;
    if (this.showOpenBtn) {

      this.nstSettings.columns = {
        openColumn: {
          title: 'Detalhes',
          type: 'custom',
          width: '50px',
          renderComponent: CustomOpenBtnRendererComponent,
          filter: false,
          onComponentInitFunction(instance) {
            instance.click.subscribe(data => { self.open_emit(data) })
          }
        },
      }

      let tempOtherColuns = Object.entries(this.columns);
      tempOtherColuns.forEach(function (e) {
        self.nstSettings.columns[e[0]] = e[1];
      });

    } else {
      let tempOtherColuns = Object.entries(this.columns);
      tempOtherColuns.forEach(function (e) {
        self.nstSettings.columns[e[0]] = e[1];
      });
    }

  }

  updateButtons() {
    this.showTable = false;
    this.ngOnInit();
    setTimeout(() => {
      this.showTable = true;
    }, 250);
  }

  //event Emitters
  open_emit(data) {
    this.open.emit(data);
  }

  create_emit() {
    this.create.emit();
  }

  edit_emit(ev) {
    this.edit.emit(ev.data);
  }

  delete_emit(ev) {
    this.delete.emit(ev.data);
  }

}
