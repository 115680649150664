import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbIconLibraries, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { Observable, Subject } from 'rxjs';

import { OverlayService, UiFeedBackService } from './services';
import { FULLSCREEN_URL_PARAM } from './theme.consts';
import { EchartsTheme, GeneralThemeVariables, HeaderButtons } from './theme.types';
import { isValidThemeName } from './theme.util';

@Injectable()
export class ThemeService {

  readonly menuSidebarTag = 'menu-sidebar';
  readonly notifSidebarTag = 'notif-sidebar';
  public headerButtons: HeaderButtons;

  private themeSubscription: any;
  public generalTheme: GeneralThemeVariables;
  public echartsTheme: EchartsTheme;
  public variables;
  public themeReady = false;

  public fullscreen = false;
  private fullscreenSubject = new Subject<boolean>();
  fullscreen$ = this.fullscreenSubject.asObservable();

  constructor(
    public nbTheme: NbThemeService,
    public sidebar: NbSidebarService,
    public menu: NbMenuService,
    public uiFeedBack: UiFeedBackService,
    public overlay: OverlayService,
    private iconLibraries: NbIconLibraries,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.iconLibraries.registerFontPack('fa', { packClass: 'fa', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('fas', { packClass: 'fa-solid', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('far', { packClass: 'fa-regular', iconClassPrefix: 'fa' });

    this.headerButtons = new HeaderButtons();
    this.echartsTheme = new EchartsTheme();

    this.themeSubscription = this.nbTheme.getJsTheme()
      .subscribe((theme) => {
        this.variables = theme.variables;
        this.generalTheme = theme.variables.general as any;
        this.echartsTheme = theme.variables.echarts as any;
        this.themeReady = true;

        console.log('Theme Change', theme)
      });

    this.readRouteFullscreen();
  }

  changeTheme(themeName: string) {
    if (isValidThemeName(themeName))
      this.nbTheme.changeTheme(themeName);
    else
      console.error(`themeName: ${themeName} is not valid!`);
  }

  // Menu Side Bar
  toggleMenuSidebar() {
    if (this.getMenuSidebarstatus() == 'expanded')
      this.closeMenuSidebar()
    else
      this.openMenuSidebar();
  }
  openMenuSidebar() {
    this.sidebar.expand(this.menuSidebarTag)
  }
  closeMenuSidebar() {
    this.sidebar.collapse(this.menuSidebarTag)
  }
  getMenuSidebarstatus(): 'collapsed' | 'compacted' | 'expanded' {
    var sb = $(`.${this.menuSidebarTag}`);
    if (sb.hasClass('collapsed'))
      return 'collapsed'
    else if (sb.hasClass('compacted'))
      return 'compacted'
    else if (sb.hasClass('expanded'))
      return 'expanded'
    return null;
  }
  // Menu Side Bar

  // Notif Side Bar
  toggleNotifSidebar() {
    if (this.getNotifSidebarstatus() == 'expanded')
      this.closeNotifSidebar()
    else
      this.openNotifSidebar();
  }
  openNotifSidebar() {
    this.sidebar.expand(this.notifSidebarTag)
  }
  closeNotifSidebar() {
    this.sidebar.collapse(this.notifSidebarTag)
  }
  getNotifSidebarstatus(): 'collapsed' | 'compacted' | 'expanded' {
    var sb = $(`.${this.notifSidebarTag}`);
    if (sb.hasClass('collapsed'))
      return 'collapsed'
    else if (sb.hasClass('compacted'))
      return 'compacted'
    else if (sb.hasClass('expanded'))
      return 'expanded'
    return null;
  }
  onNotifSidebarOpen(): Observable<void> {
    return new Observable<void>((subscriber) => {
      // Keep track of the Documents Changes
      const notifSidebarOnExpandUnsubscriber = this.sidebar.onExpand()
        .subscribe(
          (next) => {
            if (next.tag == this.notifSidebarTag)
              subscriber.next();
          },
          (error) => {
            subscriber.error(error);
          }
        );

      // Provide a way of canceling and disposing the interval resource
      return function unsubscribe() {
        notifSidebarOnExpandUnsubscriber.unsubscribe();
      };
    });
  }
  onNotifSidebarClose(): Observable<void> {
    return new Observable<void>((subscriber) => {
      // Keep track of the Documents Changes
      const notifSidebarOnCollapseUnsubscriber = this.sidebar.onCollapse()
        .subscribe(
          (next) => {
            if (next.tag == this.notifSidebarTag)
              subscriber.next();
          },
          (error) => {
            subscriber.error(error);
          }
        );

      // Provide a way of canceling and disposing the interval resource
      return function unsubscribe() {
        notifSidebarOnCollapseUnsubscriber.unsubscribe();
      };
    });
  }
  // Notif Side Bar

  closeAllSidebars() {
    this.closeMenuSidebar();
    this.closeNotifSidebar();
  }

  // Fullscreen
  readRouteFullscreen() {
    if (this.route.snapshot.queryParamMap.has(FULLSCREEN_URL_PARAM)) {
      this.fullscreen = this.route.snapshot.queryParamMap.get(FULLSCREEN_URL_PARAM) === 'true' ? true : false;
      if (this.fullscreen)
        this.enterFullscreen();
    }
  }
  emit_fullscreen() {
    this.fullscreenSubject.next(this.fullscreen);
  }
  enterFullscreen(): void {
    const elem = document.documentElement as HTMLElement & {
      mozRequestFullScreen?: () => Promise<void>;
      webkitRequestFullscreen?: () => Promise<void>;
      msRequestFullscreen?: () => Promise<void>;
    };

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { // Firefox
      elem.mozRequestFullScreen?.();
    } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen?.();
    } else if (elem.msRequestFullscreen) { // IE/Edge
      elem.msRequestFullscreen?.();
    }

    this.fullscreen = true;
    this.emit_fullscreen();
  }
  exitFullscreen(): void {
    const doc = document as Document & {
      mozCancelFullScreen?: () => Promise<void>;
      webkitExitFullscreen?: () => Promise<void>;
      msExitFullscreen?: () => Promise<void>;
    };

    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.mozCancelFullScreen) { // Firefox
      doc.mozCancelFullScreen?.();
    } else if (doc.webkitExitFullscreen) { // Chrome, Safari, and Opera
      doc.webkitExitFullscreen?.();
    } else if (doc.msExitFullscreen) { // IE/Edge
      doc.msExitFullscreen?.();
    }

    this.fullscreen = false;
    this.emit_fullscreen();
  }
  // Fullscreen 
}
