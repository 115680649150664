import firebase from "firebase/compat/app";

export class SM_CustomField {

  id: string;
  status: 0 | 1;
  name: string;
  helpText: string;

  type: 'textarea' | 'text' | 'number' | 'select' | 'multiselect' | 'date';

  minlength: number;
  maxlength: number;
  min: number;
  max: number;

  pattern: string;

  selectOptions: string[];

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<SM_CustomField>) {

    this.id = '';
    this.status = 0;
    this.name = '';
    this.helpText = '';

    this.type = 'text';

    this.minlength = 0;
    this.maxlength = 0;
    this.min = 0;
    this.max = 0;
    this.pattern = '';

    this.selectOptions = [];

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
