import { DbTableComponent } from "../db-table.component";
import { dbTableUpdateFilterStatus } from "./db-table-upade-filter-status";
import { dbTableUpdateFiltredRows } from "./db-table-update-filtred-rows";

export function dbTableCleanFilter(dbTableComponent: DbTableComponent, col_index: number) {
    for (let fi of dbTableComponent.filterIndexes[col_index]) {
        dbTableUpdateFilterStatus(dbTableComponent, col_index, fi.value, true)
    }
    dbTableComponent.filterActiveIndex[col_index] = false;
    dbTableUpdateFiltredRows(dbTableComponent);
}