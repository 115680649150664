import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';

// import { Gantt, gantt } from 'dhtmlx-gantt';
// import { Gantt, gantt } from './dhtmlx-gantt/dhtmlxgantt';
// import { config } from './src/config/ic-gantt-config';
// import { locale } from './src/ic-gantt-locale';
// import { ICGanttTask } from './models/ic-gantt-task';
// import { ICGanttLink } from './models/ic-gantt-link';
// import { defineTemplates } from './src/ic-gantt-templates';
// import { defineMarkers } from './src/ic-gantt-markers';
// import { keyboardNavigation } from './src/ic-gantt-keyboardNavigation';
// import { zoomInit } from './src/ic-gantt-zoom';
// import { ganttLevels } from './src/config/ic-gantt-config_levels';

// https://docs.dhtmlx.com/gantt/api__gantt_calculateduration.html

@Component({
  selector: 'ic-gantt',
  templateUrl: './ic-gantt.component.html',
  styleUrls: ['./ic-gantt.component.scss']

})
export class IcGanttComponent implements OnInit {
  @ViewChild('gantt_here', { static: true }) ganttContainer!: ElementRef;
  groupMode = false;
  groupModeStr = "Mostrar Recursos";

  undoBtnActive = false;
  redoBtnActive = false;

  // scales4Sel = ganttLevels;
  // scale = this.scales4Sel[2];



  constructor() { }

  ngOnInit() {

    // gantt.i18n.setLocale(locale);
    // gantt.plugins({
    //   marker: true,
    //   fullscreen: true,
    //   critical_path: true,
    //   auto_scheduling: true,
    //   tooltip: true,
    //   undo: true,
    //   keyboard_navigation: true,
    //   multiselect: true,
    // });

    // // config
    // gantt.config.auto_types = config.auto_types;
    // gantt.config.date_format = config.date_format;
    // gantt.config.duration_unit = config.duration_unit;

    // gantt.config.row_height = config.row_height;
    // gantt.config.order_branch = config.order_branch;
    // gantt.config.order_branch_free = config.order_branch_free;
    // gantt.config.grid_resize = config.grid_resize;

    // gantt.config.static_background = config.static_background;
    // gantt.config.open_tree_initially = config.open_tree_initially;
    // gantt.config.scale_height = config.scale_height;
    // gantt.config.auto_scheduling = config.auto_scheduling;
    // gantt.config.auto_scheduling_strict = config.auto_scheduling_strict;
    // gantt.config.work_time = config.work_time;

    // gantt.config.columns = config.columns;

    // gantt.config.lightbox = config.lightbox;

    // gantt.config.layout = config.layout;

    // gantt.config.resource_store = config.resource_store;
    // gantt.config.resource_property = config.resource_property;
    // gantt.config.resource_attribute = config.resource_attribute;

    // defineTemplates(gantt);
    // defineMarkers(gantt);
    // keyboardNavigation(gantt);
    // zoomInit(gantt);
    // this._changeScale();


    // console.log(`gantt.config setted: `, gantt.config)
    // // config


    // // var resourcesStore = gantt.createDatastore({
    // //   name: gantt.config.resource_store
    // // }) as any;

    // var resourcesStore = gantt.createDatastore({
    //   name: gantt.config.resource_store,
    //   type: "treeDatastore",
    //   initItem: function (item) {
    //     item.parent = item.parent || gantt.config.root_id;
    //     item[gantt.config.resource_property] = item.parent;
    //     item.open = true;
    //     return item;
    //   }
    // }) as any;
    // resourcesStore.attachEvent("onAfterSelect", function (id) {
    //   gantt.refreshData();
    // });




    // // gantt.init
    // gantt.init(this.ganttContainer.nativeElement);
    // setInterval(this.__refreshUndoBtns, 1000);
    // var dp = gantt.createDataProcessor(this._dataProcessor);
    // // gantt.init

    // // updateCollection people
    // resourcesStore.attachEvent("onParse", function () {
    //   var people = [];
    //   resourcesStore.eachItem(function (res) {
    //     if (!resourcesStore.hasChild(res.id)) {
    //       var copy = gantt.copy(res);
    //       copy.key = res.id;
    //       copy.label = res.text;
    //       if (copy.specialty) {
    //         copy.label += ", " + copy.specialty;
    //       }
    //       people.push(copy);
    //     }
    //   });
    //   gantt.updateCollection("people", people);
    // });
    // // updateCollection people

    // gantt.parse({
    //   data: [
    //     { id: '1', name: "Projeto", type: "project", start_date: "2023-01-01", duration: 18, open: true },
    //     { id: '2', parent: '1', type: "task", name: "Tarefa #1", start_date: "2023-01-01", resources: ['1', '9'], duration: 8 },
    //     { id: '3', parent: '1', type: "task", name: "Tarefa #2", start_date: "2023-01-01", resources: ['2'], duration: 8 }
    //   ],
    //   links: [
    //     // { id: '1', source: '1', target: '2', type: "1" },
    //     // { id: '2', source: '2', target: '3', type: "0" }
    //   ]
    // });


    // resourcesStore.parse([
    //   // { id: '1', name: "Architect" },
    //   // { id: '2', name: "Construction worker" },
    //   // { id: '3', name: "Plasterer" },
    //   // { id: '4', name: "Plumber" },
    //   // { id: '5', name: "Electrician" },
    //   // { id: '6', name: "Handyman" },
    //   // { id: '7', name: "Anna", specialty: "Architect", parent: 1 },
    //   // { id: '8', name: "Finn", specialty: "Construction worker", parent: 2 },
    //   // { id: '9', name: "Jake", specialty: "Construction worker", parent: 2 },
    //   // { id: '10', name: "Floe", specialty: "Plasterer", parent: 3 },
    //   // { id: '11', name: "Tom", specialty: "Plumber", parent: 4 },
    //   // { id: '12', name: "Mike", specialty: "Electrician", parent: 5 },
    //   // { id: '13', name: "Joe", specialty: "Handyman", parent: 6 }
    // ]);
    // // gantt.parse(taskData);



    // resourcesStore.parse([
    //   { id: '1', text: "QA", parent: null },
    //   { id: '2', text: "Development", parent: null },
    //   { id: '3', text: "Sales", parent: null },
    //   { id: '4', text: "Other", parent: null },
    //   { id: '5', text: "Unassigned", parent: 4, default: true },
    //   { id: '6', text: "John", parent: 1 },
    //   { id: '7', text: "Mike", parent: 2 },
    //   { id: '8', text: "Anna", parent: 2 },
    //   { id: '9', text: "Bill", parent: 3 },
    //   { id: '10', text: "Floe", parent: 3 }
    // ]);

































    // ------------------------------------------
    // gantt.config.date_format = "%d/%m/%Y %H:%i";

    // gantt.config.columns = [
    //   { name: "text", tree: true, width: 200, resize: true },
    //   { name: "start_date", align: "center", width: 80, resize: true },
    //   {
    //     name: "owner", align: "center", width: 75, label: "Owner", resize: true,
    //     template: function (task) {
    //       if (task.type == gantt.config.types.project) {
    //         return "";
    //       }

    //       var store = gantt.getDatastore("resource");
    //       var ownerValue = task.owner_id;
    //       var singleResult = "";
    //       var result = "Unassigned";

    //       if (ownerValue) {
    //         if (!(ownerValue instanceof Array)) {
    //           ownerValue = [ownerValue];
    //         }
    //         ownerValue.forEach(function (ownerId) {
    //           var owner = store.getItem(ownerId);
    //           if (!owner) {
    //             return;
    //           }
    //           if (singleResult === "") {
    //             result = singleResult = owner.text;
    //             return;
    //           }
    //           if (result === singleResult) {
    //             result = "<div class='owner-label' title='" + singleResult + "'>" + singleResult.substr(0, 1) + "</div>"
    //           }
    //           result += "<div class='owner-label' title='" + owner.text + "'>" + owner.text.substr(0, 1) + "</div>";
    //         });
    //       }

    //       return result;
    //     }
    //   },
    //   { name: "duration", width: 60, align: "center" },
    //   { name: "add", width: 44 }
    // ];




    // gantt.init(this.ganttContainer.nativeElement);

    // Promise.all([this.taskService.get(), this.linkService.get()])
    //   .then(([data, links]) => {
    //     gantt.parse({ data, links });
    //   });

  }


  // toggleGroups() {
  //   this.groupMode = !this.groupMode;
  //   if (this.groupMode) {
  //     this.groupModeStr = "Mostrar Gantt";

  //     debugger
  //     var resourcesStore = gantt.getDatastore(gantt.config.resource_store) as any;

  //     var groups = resourcesStore.getItems().map(function (item) {
  //       var group = gantt.copy(item);
  //       group.group_id = group.id;
  //       group.id = gantt.uid();
  //       return group;
  //     });

  //     gantt.groupBy({
  //       groups: groups,
  //       relation_property: gantt.config.resource_property,
  //       group_id: "group_id",
  //       group_text: "text",
  //       delimiter: ", ",
  //       default_group_label: "Not Assigned"
  //     });
  //   } else {
  //     this.groupModeStr = "Mostrar Recursos";
  //     gantt.groupBy(false);
  //   }
  // }

  // // DataProcessor
  // // entity - "task"|"link"
  // // action - "create"|"update"|"delete"
  // // data - an object with task or link data
  // // id – the id of a processed object (task or link)
  // _dataProcessor(entity, action, data, id) {
  //   console.log('gantt change:', { entity: entity, action: action, data: data, id: id })
  // }
  // // DataProcessor

  // // ic-gantt-actions
  // __refreshUndoBtns() {
  //   if (!gantt.getUndoStack || !gantt.getUndoStack().length) {
  //     this.undoBtnActive = false;
  //   } else {
  //     this.undoBtnActive = true;
  //   }

  //   if (!gantt.getRedoStack || !gantt.getRedoStack().length) {
  //     this.redoBtnActive = false;
  //   } else {
  //     this.redoBtnActive = true;
  //   }
  // }
  // _undo() {
  //   gantt.undo();
  //   this.__refreshUndoBtns();
  // }
  // _redo() {
  //   gantt.redo();
  //   this.__refreshUndoBtns();
  // }
  // __collapseAll() {
  //   gantt.eachTask(function (task) {
  //     task.$open = false;
  //   });
  //   gantt.render();
  // }
  // __expandAll() {
  //   gantt.eachTask(function (task) {
  //     task.$open = true;
  //   });
  //   gantt.render();
  // }
  // _toggleCollapseRows(nextV: boolean) {
  //   config.collapse = nextV;
  //   if (nextV)
  //     this.__collapseAll();
  //   else
  //     this.__expandAll();
  // }
  // _toggleAutoScheduling(nextV: boolean) {
  //   gantt.config.auto_scheduling = nextV;
  //   if (gantt.config.auto_scheduling) {
  //     gantt.autoSchedule();
  //   }
  // }
  // _toggleCriticalPath(nextV: boolean) {
  //   gantt.config.highlight_critical_path = nextV;
  //   this.updateConfig();
  // }

  // _toggleZoomToFit(nextV: boolean) {
  //   gantt.config.autofit = nextV;
  //   this.updateConfig();
  //   // gantt.mod
  // }

  // _changeScale() {
  //   gantt.ext.zoom.setLevel(this.scale.name);
  // }

  // updateConfig() {
  //   gantt.init(this.ganttContainer.nativeElement);
  //   console.log(`gantt.config setted: `, gantt.config)
  // }

  // _exportToPDF() {
  //   gantt.exportToPDF({
  //     name: "ic_gantt.pdf",
  //     // header: "<h1>My company</h1>",
  //     // footer: "<h4>Bottom line</h4>",
  //     // locale: "en",
  //     // start: "01-04-2013",
  //     // end: "11-04-2013",
  //     // skin: 'terrace',
  //     // data: {},
  //     // server: "https://myapp.com/myexport/gantt",
  //     // raw: true,
  //     // callback: function (res) {
  //     //   alert(res.url);
  //     // }
  //   });
  // }
  // _exportToPNG() {
  //   gantt.exportToPNG({
  //     name: "ic_gantt.png",
  //     // header: "<h1>My company</h1>",
  //     // footer: "<h4>Bottom line</h4>",
  //     // locale: "en",
  //     // start: "01-04-2013",
  //     // end: "11-04-2013",
  //     // skin: 'terrace',
  //     // data: {},
  //     // server: "https://myapp.com/myexport/gantt",
  //     // raw: true,
  //     // callback: function (res) {
  //     //   alert(res.url);
  //     // }
  //   });
  // }
  // _exportToExcel() {
  //   gantt.exportToExcel({
  //     name: "ic_gantt.xlsx",
  //     // columns: [
  //     //   { id: "text", header: "Title", width: 150 },
  //     //   { id: "start_date", header: "Start date", width: 250, type: "date" }
  //     // ],
  //     // server:"https://myapp.com/myexport/gantt",
  //     // callback: function(res){
  //     //     alert(res.url);
  //     // },
  //     visual: true,
  //     cellColors: true,
  //     // date_format: "dddd d, mmmm yyyy"
  //   });
  // }
  // _exportToMSProject() {
  //   gantt.exportToMSProject({
  //     name: 'ic_gantt.xml',
  //     auto_scheduling: gantt.config.auto_scheduling,
  //     // skip_circular_links:false,
  //     // resources: [],
  //     // server: "https://myapp.com/myexport/gantt",
  //     // callback: function (res) {
  //     //   alert(res.url);
  //     // }
  //   });
  // }
  // _fullscreen() {
  //   gantt.ext.fullscreen.toggle();
  // }
  // ic-gantt-actions

}
