import { SafeStyle } from "@angular/platform-browser";

export type ToastStatus = "basic" | "info" | "success" | "warning" | "danger";
export type AlertStatus = "info" | "success" | "warning" | "error";

export class CustonAlertSelectInputOptions {
  value: string | number | boolean | object;
  name: string;
}
export class CustonAlertInputOptions {
  id?: string;
  type: "tel" | "text" | "textarea" | "select" | "number" | "checkbox";
  name: string;
  label: string;

  placeholder?: string;
  value?: string | Number | boolean;
  options?: CustonAlertSelectInputOptions[];
  pattern?: RegExp;
  required?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  minlength?: number;
  maxlength?: number;

  style?: string | SafeStyle;;
  classes?: string[];

  public constructor(init?: Partial<CustonAlertInputOptions>) {

    this.id = "";
    this.type = "text";
    this.name = "";
    this.label = "";

    this.placeholder = "";
    this.value = "";
    this.options = [];
    this.pattern = null;
    this.required = false;
    this.disabled = false;
    this.min = null;
    this.max = null;
    this.minlength = null;
    this.maxlength = null;

    this.style = "";
    this.classes = [];

    if (init)
      Object.assign(this, init);
  }
}

export class CustonAlertButton {
  title?: string;
  icon?: string;
  text?: string;
  status?: "basic" | "primary" | "success" | "warning" | "danger" | "info" = "basic";
  needFormValid?: boolean;
  style?: string | SafeStyle;
  classes?: string[];
  handler?: (value: any) => boolean | void;
  public constructor(init?: Partial<CustonAlertButton>) {
    if (init.text && !init.title)
      this.title = init.text;
    this.icon = "";
    this.text = "";
    this.status = "basic";
    this.needFormValid = false;
    this.style = "";
    this.classes = [];
    this.handler = () => { console.log(`btn ${init.text} clicked`); }
    if (init)
      Object.assign(this, init);
  }
}
export class CustonAlertOptions {
  title?: string;
  message?: string;
  inputs?: (CustonAlertInputOptions | "spacer")[];
  buttons?: CustonAlertButton[];
  closeButtonHandler?: (value?: any) => boolean | void;
  public constructor(init?: Partial<CustonAlertOptions>) {
    this.title = '';
    this.message = '';
    this.inputs = [];
    this.buttons = [];
    this.closeButtonHandler = () => { console.log(`close Button clicked`) };
    if (init)
      Object.assign(this, init);

    // inputs
    for (let i = 0; i < this.inputs.length; i++) {
      if (this.inputs[i] !== "spacer")
        this.inputs[i] = new CustonAlertInputOptions(this.inputs[i] as any);
      else
        this.inputs[i] = this.inputs[i];
    }

    // btns
    for (let i = 0; i < this.buttons.length; i++)
      this.buttons[i] = new CustonAlertButton(this.buttons[i]);
  }
}