import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { OverlayService } from 'app/@theme';

import { DbTableColOptionsOverlayComponent } from './components/overlays/db-table-col-options-overlay/db-table-col-options-overlay.component';
import { DbTableRowOptionsOverlayComponent } from './components/overlays/db-table-row-options-overlay/db-table-row-options-overlay.component';
import { DbTable, DbTableColumn, DbTableDataChanges, DbTableInput, DbTableOrderDirection, DbTableRowData, DbTableRowSelectOption, DbTableSelectOptions, DbTableUiFilterIndex } from './db-table.types';
import { dbTableCleanFilter } from './shared/db-table-clean-filter';
import { dbTableinitFilterIndexes } from './shared/db-table-init-filter-indexes';
import { dbTableSaveAsExcel } from './shared/db-table-save-as-excel';
import { dbTableToogleSort } from './shared/db-table-toogle-sort';
import { dbTableUpdateFilterIndexes } from './shared/db-table-update-filter-indexes';
import { dbTableUpdateFiltredRows } from './shared/db-table-update-filtred-rows';

@Component({
  selector: 'db-table',
  templateUrl: './db-table.component.html',
  styleUrls: ['./db-table.component.scss'],
})
export class DbTableComponent implements OnInit, OnChanges {

  id: string = 'tid-' + this._generateRandom(6);
  data: any[] = [];
  dataToDelete: any[] = [];
  aditionalData: any = {};

  @Input() columns: DbTableColumn[] = [];

  className = `DbTableComponent`;
  table: DbTable = new DbTable();

  numberCellWidth = '40px';
  optionCellWidth = '50px';
  tableWidth = '';

  rowsNumIndex: {};

  @Input() edit = false;
  @Input() canCreate = true;
  @Input() canDelete = true;
  @Input() canDuplicate = false;
  @Input() canMoveItems = false;
  @Input() loader = false;

  @Output() onChange = new EventEmitter();
  @Input() hasChanges = false;
  @Output() hasChangesChange = new EventEmitter();
  @Input() hasError = false;
  @Output() hasErrorChange = new EventEmitter();

  filterActiveIndex: boolean[] = [];
  orderByIndex: DbTableOrderDirection[] = [];
  filterIndexes: DbTableUiFilterIndex[] = [];
  filtredRows: DbTableRowData[] = [];

  queue_workingOnPromise = false;
  queue_promises = [];

  constructor(
    private _sanitizer: DomSanitizer,
    public overlayCtrl: OverlayService,
  ) { }
  _trustHtml(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }
  _trackByFn(index, item) {
    return index;
  }
  private _generateRandom(length: number): string {
    var s = '';
    var r = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (var i = 0; i < length; i++) {
      s += r.charAt(Math.floor(Math.random() * r.length));
    }
    return s;
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName == 'edit')
        this.updateTableWidth();

    }
  }
  execQueue() {
    if (this.queue_promises.length > 0 && !this.queue_workingOnPromise) {
      this.queue_workingOnPromise = true;
      this.loader = true;
      let promise = this.queue_promises.shift() as any;
      promise()
        .then(() => {
          this.queue_workingOnPromise = false;
          this.loader = false;
          if (this.queue_promises.length > 0) this.execQueue();
        })
        .catch((err) => {
          this.queue_workingOnPromise = false;
          this.loader = false;
          console.error('execQueue ERROR', err);
        });
    } else {
      setTimeout(() => {
        this.execQueue();
      }, 200);
    }
  }
  ngOnInit() { }

  // ------------------------------------------------- L O A D
  load(data: any[], aditionalData?: any) {
    this.dataToDelete = [];
    this.data = JSON.parse(JSON.stringify(data));
    if (aditionalData) this.aditionalData = aditionalData;
    this.__initCols();
    this.updateTableWidth();
    this.__initTableRows();
    dbTableinitFilterIndexes(this);
    dbTableUpdateFiltredRows(this);
    this.hasChanges = false;
    this.hasChangesChange.emit(this.hasChanges);
    this.hasError = false;
    this.hasErrorChange.emit(this.hasError);
  }
  updateTableWidth() {
    this.tableWidth = '';
    if (this.table.cols.length > 0) {
      this.tableWidth = 'calc(';
      this.tableWidth += this.numberCellWidth + ' + ';
      if (this.edit)
        this.tableWidth += this.optionCellWidth + ' + ';
      for (let index = 0; index < this.table.cols.length; index++) {
        const col = this.table.cols[index];
        this.tableWidth += col.width;
        if (index < this.table.cols.length - 1)
          this.tableWidth += ' + ';
        else
          this.tableWidth += ')';
      }
    }
  }
  /**
   * Inicia as colunas
   */
  private __initCols() {
    let tempCols: DbTableColumn[] = [];
    for (let col of this.columns) {
      let tempCol = new DbTableColumn();
      tempCol.title = col.title ? col.title : tempCol.title;
      tempCol.fieldName = col.fieldName ? col.fieldName : tempCol.fieldName;
      tempCol.width = col.width ? col.width : tempCol.width;
      tempCol.type = col.type ? col.type : tempCol.type;
      tempCol.canChange =
        col.canChange != undefined ? col.canChange : tempCol.canChange;
      // -- Input
      if (col.input) {
        tempCol.input = new DbTableInput();
        tempCol.input.type = col.input.type
          ? col.input.type
          : tempCol.input.type;

        tempCol.input.minlength = col.input.minlength;
        tempCol.input.maxlength = col.input.maxlength;
        tempCol.input.min = col.input.min;
        tempCol.input.max = col.input.max;

        tempCol.input.pattern = col.input.pattern
          ? col.input.pattern
          : tempCol.input.pattern;
        tempCol.input.required = col.input.required
          ? col.input.required
          : tempCol.input.required;
        tempCol.input.disabled = col.input.disabled
          ? col.input.disabled
          : tempCol.input.disabled;
        tempCol.input.onChange = col.input.onChange
          ? col.input.onChange
          : tempCol.input.onChange;

        // ---- selectOptions
        if (col.input.selectOptions) {
          tempCol.input.selectOptions.optionsData = col.input.selectOptions
            .optionsData
            ? col.input.selectOptions.optionsData
            : tempCol.input.selectOptions.optionsData;

          tempCol.input.selectOptions.optionsFilter = col.input.selectOptions
            .optionsFilter
            ? col.input.selectOptions.optionsFilter
            : tempCol.input.selectOptions.optionsFilter;

          tempCol.input.selectOptions.optionValue = col.input.selectOptions
            .optionValue
            ? col.input.selectOptions.optionValue
            : tempCol.input.selectOptions.optionValue;
          tempCol.input.selectOptions.optionName = col.input.selectOptions
            .optionName
            ? col.input.selectOptions.optionName
            : tempCol.input.selectOptions.optionName;
        }
        // ---- selectOptions
      }
      // -- Input
      tempCol.valuePrepareFunction = col.valuePrepareFunction
        ? col.valuePrepareFunction
        : tempCol.valuePrepareFunction;
      tempCols.push(tempCol);
    }
    this.table.cols = tempCols;
  }
  /**
   * Inicia as linhas sem levar em consideração os filtros
   */
  private __initTableRows() {
    let tempRows: DbTableRowData[] = [];
    let tempRowsNumIndex = {};
    for (let d of this.data) {
      let row = new DbTableRowData();
      row.id = `${this.id}_${this._generateRandom(10)}`;
      row.status = 'old';
      row.objData = d;
      row.number = tempRows.length + 1;
      for (let c_index = 0; c_index < this.table.cols.length; c_index++) {
        const col = this.table.cols[c_index];
        let value = row.objData[col.fieldName];

        if (value == undefined) value = null;

        row.originalCellsData.push(value);
        row.cellsData.push(value);

        let cell = col.valuePrepareFunction(
          value,
          row.objData,
          this.aditionalData
        );
        row.cells.push(cell);

        row.selectOptions.push([]);
      }
      tempRows.push(row);
      tempRowsNumIndex[row.id] = row.number;
    }

    // init selectOptions
    for (let row_index = 0; row_index < tempRows.length; row_index++) {
      for (let c_index = 0; c_index < this.table.cols.length; c_index++) {
        tempRows[row_index].selectOptions[c_index] = this._rowSelectOptionsGen(
          this.table.cols[c_index].input,
          tempRows[row_index].cellsData[c_index],
          tempRows[row_index].objData,
          { rows: tempRows, cols: this.table.cols },
          this.aditionalData
        );
      }
    }
    // init selectOptions

    this.table.rows = tempRows;
    this.rowsNumIndex = tempRowsNumIndex;
  }

  // ------------------------------------------------- L O A D

  // ------------------------------------------------- G E N E R A L
  public _compareFn(o1, o2): boolean {
    let result = false;
    if (o1 === null && o2 === null) result = true;
    else if (o1 != null && o2 != null) {
      if (o1 === o2) result = true;
      else if (o1.id != null && o2.id != null && o1.id === o2.id) result = true;
    }
    return result;
  }
  public formIdGen(row_index: number): string {
    return `${this.id}_row_index[${row_index}]`;
  }
  public formControlIdGen(row_index: number, col_index: number): string {
    return `${this.id}_input[${row_index}:${col_index}]`;
  }
  public getCellClasses(row_index: number, col_index: number, rowForm: NgForm): string[] {
    let classes = [];

    if (this.table.cols[col_index].fieldName)
      classes.push(`fieldName_${this.table.cols[col_index].fieldName}`);

    if (this.table.cols[col_index].canChange)
      classes.push('can_change');

    if (this.table.cols[col_index].input) {
      classes.push('input');
      classes.push(`input_type:${this.table.cols[col_index].input.type}`);

      if (this.edit)
        classes.push('editing');

      const formControlId = this.formControlIdGen(row_index, col_index);
      const formControl = rowForm.controls[formControlId] as FormControl;

      if (formControl) {
        let disabled = !this.edit || this.table.cols[col_index].input.disabled(this.filtredRows[row_index].cellsData[col_index], this.filtredRows[row_index], this.aditionalData);
        if (disabled)
          classes.push('input_disabled');

        let required = this.table.cols[col_index].input.required(this.filtredRows[row_index].cellsData[col_index], this.filtredRows[row_index], this.aditionalData);
        if (required)
          classes.push('input_required');

        if (!formControl.invalid)
          classes.push('input_valid');
        else
          classes.push('input_invalid');
      }
    }

    return classes;
  }
  public _rowSelectOptionsGen(input: DbTableInput, cellData?: any, row?: any, table?: DbTable, aditionalData?: {}): DbTableRowSelectOption[] {
    if (!input || input.type != 'select' || !input.selectOptions) return [];

    let selOpt = new DbTableSelectOptions(input.selectOptions);
    let tempData = selOpt.optionsFilter(cellData, row, selOpt.optionsData, table, aditionalData);
    let tempOptions4Sel: DbTableRowSelectOption[] = [];
    tempData.forEach((data) => {
      tempOptions4Sel.push({
        name: selOpt.optionName(data),
        value: selOpt.optionValue(data),
      });
    });
    return tempOptions4Sel;
  }

  public _validateSelectOption(selectedOption: string, options: DbTableRowSelectOption[]): boolean {
    if (!selectedOption || selectedOption.length == 0 || options.length == 0)
      return true;

    let valid = false;
    for (let o of options) {
      if (this._compareFn(selectedOption, o.value)) {
        valid = true;
        break;
      }
    }
    return valid;
  }
  public createLine(data: any) {
    this.loader = true;
    this.queue_promises.push(() => this._createLine(data));
    this.execQueue();
  }
  private _createLine(data: any): Promise<void> {
    return new Promise((resolve, reject) => {

      let row = new DbTableRowData();
      row.status = 'new';
      row.hasError = true;
      row.number = this.table.rows.length + 1;
      const row_index = this.table.rows.length;
      let virtualObject = {};

      for (let c_index = 0; c_index < this.table.cols.length; c_index++) {
        const col = this.table.cols[c_index];

        let value = data[col.fieldName];

        if (value == undefined) value = null;

        if (col.fieldName != '') {
          if (col.input) {
            if (col.input.type == 'text' && value == null) value = '';
            else if (col.input.type == 'number' && value == null) value = 0;
          }
          virtualObject[col.fieldName] = value;
        }

        row.originalCellsData.push(value);
        row.cellsData.push(value);

        let cell = col.valuePrepareFunction(
          value,
          virtualObject,
          this.aditionalData
        );
        row.cells.push(cell);

        if (col.canChange)
          row.selectOptions[c_index] = this._rowSelectOptionsGen(this.table.cols[c_index].input, row.cellsData[c_index], row.objData, this.table, this.aditionalData);
      }
      row.objData = virtualObject;
      this.table.rows.push(row);

      dbTableUpdateFiltredRows(this);

      return this._syncFormValidWithHtm(row_index)
        .then(() => {
          this.updateHasError();
          this.updateHasChanges();
          this._onChangeEmitter();

          resolve();
        })
        .catch((e) => reject(e));
    });
  }
  /**
   * sync form valid with html
   * @param row_index 
   * @returns 
   */
  private async _syncFormValidWithHtm(row_index: number): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        let row = this.table.rows[row_index];
        let formId = this.formIdGen(row_index);
        let formElem = document.getElementById(formId);
        if (formElem) {
          let formValid = formElem.classList.contains('ng-valid');
          row.hasError = !formValid;
          resolve();
        } else {
          setTimeout(() => {
            resolve(this._syncFormValidWithHtm(row_index));
          }, 50);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  addLine() {
    let row = new DbTableRowData();
    row.status = 'new';
    row.hasError = true;
    row.number = this.table.rows.length + 1;
    let virtualObject = {};
    for (let c_index = 0; c_index < this.table.cols.length; c_index++) {
      const col = this.table.cols[c_index];
      let value = null;
      if (col.fieldName != '') {
        if (col.input) {
          if (col.input.type == 'text') value = '';
          else if (col.input.type == 'number') value = 0;
        }
        virtualObject[col.fieldName] = value;
      }

      row.originalCellsData.push(value);
      row.cellsData.push(value);

      let cell = col.valuePrepareFunction(
        value,
        virtualObject,
        this.aditionalData
      );
      row.cells.push(cell);

      row.selectOptions[c_index] = this._rowSelectOptionsGen(
        this.table.cols[c_index].input,
        row.cellsData[c_index],
        row.objData,
        this.table,
        this.aditionalData
      );
    }
    row.objData = virtualObject;
    this.table.rows.push(row);
    this.updateHasError();
    this.updateHasChanges();
    this._onChangeEmitter();
    dbTableUpdateFiltredRows(this);
  }

  _rowChange(dr_index: number, c_index: number, line_form_valid: boolean) {
    this.filtredRows[dr_index].hasError = !line_form_valid;

    const col = this.table.cols[c_index];

    this.filtredRows[dr_index].objData[col.fieldName] =
      this.filtredRows[dr_index].cellsData[c_index];

    if (
      this.filtredRows[dr_index].status == 'old' ||
      this.filtredRows[dr_index].status == 'changed'
    )
      this.filtredRows[dr_index].status =
        this.filtredRows[dr_index].cellsData[c_index] !=
          this.filtredRows[dr_index].originalCellsData[c_index]
          ? 'changed'
          : 'old';

    for (let _c_index = 0; _c_index < this.table.cols.length; _c_index++) {
      const col = this.table.cols[_c_index];
      let value = this.filtredRows[dr_index].objData[col.fieldName];
      if (!col.input) {
        let cell = col.valuePrepareFunction(
          value,
          this.filtredRows[dr_index].objData,
          this.aditionalData
        );
        this.filtredRows[dr_index].cells[_c_index] = cell;
      }
      if (col.input !== undefined && _c_index == c_index) {
        col.input.onChange(
          value,
          this.filtredRows[dr_index],
          this.aditionalData
        );
      }
      this.filtredRows[dr_index].selectOptions[_c_index] =
        this._rowSelectOptionsGen(
          this.table.cols[_c_index].input,
          this.filtredRows[dr_index].cellsData[_c_index],
          this.filtredRows[dr_index].objData,
          this.table,
          this.aditionalData
        );
    }

    this.updateHasError();
    this.updateHasChanges();

    dbTableUpdateFilterIndexes(this, c_index);

    this._onChangeEmitter();
  }
  _onChangeEmitter() {
    this.onChange.emit(this.getDataChanges());
  }
  private updateHasError() {
    let hasError = false;
    for (let r of this.table.rows) {
      if (r.hasError) {
        hasError = true;
        break;
      }
    }
    if (this.hasError != hasError) this.hasErrorChange.emit(hasError);
    this.hasError = hasError;
  }
  updateHasChanges() {
    let hasChanges = false;
    if (this.dataToDelete.length > 0) hasChanges = true;
    else {
      for (let r of this.table.rows) {
        if (r.status == 'changed' || r.status == 'new') {
          hasChanges = true;
          break;
        }
      }
    }
    if (this.hasChanges != hasChanges) this.hasChangesChange.emit(hasChanges);
    this.hasChanges = hasChanges;
  }

  public getDataChanges(): DbTableDataChanges {
    let finalData = [];
    let dataToCreate = [];
    let dataToUpdate = [];
    for (let r of this.table.rows) {
      finalData.push(r.objData);
      if (r.status == 'new')
        dataToCreate.push(r.objData);
      else if (r.status == 'changed')
        dataToUpdate.push(r.objData);
    }
    return {
      finalData: finalData,
      dataToCreate: dataToCreate,
      dataToUpdate: dataToUpdate,
      dataToDelete: this.dataToDelete,
    };
  }
  showObligatoryStamp(input_required: boolean, cellsData) {
    return (
      this.edit && input_required && (cellsData == null || cellsData === '')
    );
  }

  // ------------------------------------------------- G E N E R A L

  openColOptionsOverlay(triggerElement: HTMLElement, col_index: number) {
    this.overlayCtrl.openOverlay(
      `[${this.className}]DbTableColOptionsOverlayComponent-${new Date().getTime()}`,
      DbTableColOptionsOverlayComponent,
      triggerElement,
      'down',
      {
        dbTableComponent: this,
        col_index: col_index
      },
      {},
      (onCloseData) => { }
    );
  }

  // Move Item
  private _syncRowsNumbers() {
    for (let i = 0; i < this.table.rows.length; i++)
      this.table.rows[i].number = i + 1;
  }
  private _moveItem(vet: any[], from: number, to: number): any[] {
    let tempVet = JSON.parse(JSON.stringify(vet));
    // remove `from` item and store it
    var f = tempVet.splice(from, 1)[0];
    // insert stored item into position `to`
    tempVet.splice(to, 0, f);
    return tempVet;
  }
  private _rowNumberChange(r_id: string) {
    let row = new DbTableRowData();
    let r_index = 0;

    for (r_index = 0; r_index < this.table.rows.length; r_index++) {
      if (this.table.rows[r_index].id == r_id) {
        row = this.table.rows[r_index];
        break;
      }
    }

    if (row.status == 'old' || row.status == 'changed') {
      let rowOldNumber = this.rowsNumIndex[row.id];
      if (rowOldNumber != row.number) row.status = 'changed';
      else
        for (let c_index = 0; c_index < this.table.cols.length; c_index++) {
          const col = this.table.cols[c_index];
          row.objData[col.fieldName] = row.cellsData[c_index];

          row.status =
            row.cellsData[c_index] != row.originalCellsData[c_index]
              ? 'changed'
              : 'old';
          if (row.status == 'changed') break;
        }
    }

    this.table.rows[r_index].status = row.status;
  }
  public moveItem_rowUp(row_number: number) {
    let fromIndex = row_number - 1;
    let toIndex = fromIndex - 1;
    let fromId = this.table.rows[fromIndex].id;
    let toId = this.table.rows[toIndex].id;

    this.table.rows = this._moveItem(this.table.rows, fromIndex, toIndex);

    this._syncRowsNumbers();

    this._rowNumberChange(fromId);
    this._rowNumberChange(toId);

    this.updateHasError();
    this.updateHasChanges();
    this._onChangeEmitter();
    dbTableUpdateFiltredRows(this);
  }
  public moveItem_rowDown(row_number: number) {
    let fromIndex = row_number - 1;
    let toIndex = fromIndex + 1;
    let fromId = this.table.rows[fromIndex].id;
    let toId = this.table.rows[toIndex].id;

    this.table.rows = this._moveItem(this.table.rows, fromIndex, toIndex);

    this._syncRowsNumbers();

    this._rowNumberChange(fromId);
    this._rowNumberChange(toId);

    this.updateHasError();
    this.updateHasChanges();
    this._onChangeEmitter();
    dbTableUpdateFiltredRows(this);
  }
  // Move Item
  public deleteRow(row_number: number) {
    let tempRows: DbTableRowData[] = [];
    for (let i = 0; i < this.table.rows.length; i++) {
      let tempRow = JSON.parse(
        JSON.stringify(this.table.rows[i])
      ) as DbTableRowData;
      if (this.table.rows[i].number != row_number) {
        tempRow.number = tempRows.length + 1;
        tempRows.push(tempRow);
      } else {
        if (tempRow.status == 'old' || tempRow.status == 'changed')
          this.dataToDelete.push(tempRow.objData);
      }
    }
    this.table.rows = tempRows;

    this.updateHasError();
    this.updateHasChanges();
    this._onChangeEmitter();
    dbTableUpdateFiltredRows(this);
  }
  public duplicateRow(row_number: number) {
    let tempRows: DbTableRowData[] = [];
    for (let i = 0; i < this.table.rows.length; i++) {
      let tempRow = JSON.parse(
        JSON.stringify(this.table.rows[i])
      ) as DbTableRowData;
      tempRow.number = tempRows.length + 1;
      tempRows.push(JSON.parse(JSON.stringify(tempRow)));

      if (this.table.rows[i].number == row_number) {
        tempRow.status = 'new';
        tempRow.number = tempRows.length + 1;
        tempRows.push(JSON.parse(JSON.stringify(tempRow)));
      }
    }
    this.table.rows = tempRows;

    this.updateHasError();
    this.updateHasChanges();
    this._onChangeEmitter();
    dbTableUpdateFiltredRows(this);
  }
  openRowOptionsOverlay(triggerElement: HTMLElement, row_number: number) {
    this.overlayCtrl.openOverlay(
      `[${this.className}]DbTableRowOptionsOverlayComponent-${new Date().getTime()}`,
      DbTableRowOptionsOverlayComponent,
      triggerElement,
      'down',
      {
        dbTableComponent: this,
        row_number: row_number
      },
      {},
      (onCloseData) => { }
    );
  }
  cleanFilter(col_index: number) {
    dbTableCleanFilter(this, col_index);
  }
  toogleSort(col_index: number, ord: 'asc' | 'desc') {
    dbTableToogleSort(this, col_index, ord);
  }
  hasFilterActive(): boolean {
    for (const isActive of this.filterActiveIndex)
      if (isActive)
        return true;
    return false;
  }
  public saveAsExcel() { dbTableSaveAsExcel(this); }


}
