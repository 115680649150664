import { Location } from '@angular/common';
import { Component, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService, ComponentCanDeactivate, KeybindsService, LayoutService, SmartTableAuxService, UtilService } from 'app/@core';
import { FirestoreService, TM_TimeRecord } from 'app/@firebase';
import { ThemeService, UiFeedBackService } from 'app/@theme';
import { WeekInputComponent } from 'app/@theme/shared-components/week-input/week-input.component';

import { TimerListTabComponent } from './timer-list-tab/timer-list-tab.component';
import { _endSession, initSession } from './timer-session-utils';

export type TimerModeType = 'today' | 'week' | 'month' | 'manual';

export const TIMER_PAGE_DEFINITION = {
  id: 'TIMER',
  className: 'TimerComponent',
  name: 'Timer',
  url: '/main/time-management/timer'
};

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return !this.timer_list_tab.hasChanges;
  }


  loader = false;

  // ****************************************
  // Global Variables
  // ****************************************

  // Selection
  selectedViewMode = "list";
  readonly VIEW_MODES = [
    // {
    //   id: 'overview',
    //   icon: 'assets/img/overview-icon.svg',
    //   name: 'Overview',
    // },
    {
      id: 'list',
      icon: 'assets/img/list-icon.svg',
      name: 'Lista',
    },
    {
      id: 'dashboard',
      icon: 'assets/img/overview-icon.svg',
      name: 'Dashboard',
    },
  ];
  mode: TimerModeType = 'week';
  modes = [
    { id: 'today', title: 'Hoje' },
    { id: 'week', title: 'Semana' },
    { id: 'month', title: 'Mês' },
    // { id: 'manual', title: 'Manual' }
  ];
  months = [
    { id: 1, name: 'Janeiro' },
    { id: 2, name: 'Fevereiro' },
    { id: 3, name: 'Março' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Maio' },
    { id: 6, name: 'Junho' },
    { id: 7, name: 'Julho' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Setembro' },
    { id: 10, name: 'Outubro' },
    { id: 11, name: 'Novembro' },
    { id: 12, name: 'Dezembro' },
  ];

  dateWeek_inputVal: string;

  @ViewChild('Week_input_REF') week_input: WeekInputComponent = new WeekInputComponent(this.locale, this.platformId);

  @ViewChild('Timer_list_tab_REF') timer_list_tab: TimerListTabComponent = new TimerListTabComponent(this.db, this.authService, this.uiFeedBackCtrl, this.utilCtrl, this.keybindsCtrl);

  date: Date = new Date();

  dateWeek: number;
  dateMonth: number;
  dateYear: number;
  dateDay: number;

  datePeriodFrom_inputVal = ``;
  datePeriodTo_inputVal = ``;

  datePeriodFrom: Date = new Date();
  datePeriodTo: Date = new Date();
  // Selection

  localTimeRecords: TM_TimeRecord[] = [];

  canEdit = false;

  errorMessage = '';

  constructor(
    @Inject(LOCALE_ID) public locale,
    @Inject(PLATFORM_ID) public platformId,
    public smartTableAuxService: SmartTableAuxService,
    private authService: AuthService,
    public router: Router,
    private _location: Location,
    public uiFeedBackCtrl: UiFeedBackService,
    public utilCtrl: UtilService,
    public db: FirestoreService,
    private layoutService: LayoutService,
    private theme: ThemeService,
    private keybindsCtrl: KeybindsService
  ) { }

  ngOnInit() {
    this.theme.headerButtons.openTmTimerModal.disabled = true;
    window.document.title = `IC | Temporizador`;

    this.loader = true;

    this.authService.auth_check([
      {
        object: 'P_ID',
        authPairs: [
          {
            field: 'P_ID', value: TIMER_PAGE_DEFINITION.id
          }
        ]
      },
    ])
      .then((failed_auths) => {

        if (failed_auths.length > 0) {
          this.uiFeedBackCtrl.presentAlert('Acessos insuficientes!', `Voce não possui acessos suficientes para acessar esta pagina`, 'warning');
        } else {

          this.loadPage();

        }

      })
      .catch((e) => {
        this.uiFeedBackCtrl.presentErrorAlert(TIMER_PAGE_DEFINITION.id, TIMER_PAGE_DEFINITION.className, this.authService.localUser.uName, 'Erro', e);
      })

  }
  ngOnDestroy() {
    this.theme.headerButtons.openTmTimerModal.disabled = false;
    _endSession(this.db, this.utilCtrl);
  }

  // ---------------------- UI
  loadPage() {
    this.loader = true;

    this.date = new Date();
    this.week_input.useActualWeek();

    this.loader = false;
  }
  updateDatePeriodFrom() {
    this.datePeriodFrom = this.utilCtrl.date.getFromDateObj(this.datePeriodFrom_inputVal);
    this.loadData();
  }
  updateDatePeriodTo() {
    this.datePeriodTo = this.utilCtrl.date.getToDateObj(this.datePeriodTo_inputVal);
    this.loadData();
  }
  /**
   * @param {int} The month number, 0 based
   * @param {int} The year, not zero based, required to account for leap years
   * @return {Date[]} List with date objects for each day of the month
   */
  private getDaysInMonth(month: number, year: number) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }
  changeMode() {
    this.changePeriod();
  }
  changePeriod() {
    if (this.mode == 'today') {
      this.datePeriodFrom = new Date();
      this.datePeriodFrom.setHours(0, 0, 0, 0);
      this.datePeriodTo = new Date()
      this.datePeriodTo.setHours(23, 59, 59, 0);
      this.datePeriodTo_inputVal = this.utilCtrl.date.getDateStr(this.datePeriodTo);
      this.datePeriodFrom_inputVal = this.utilCtrl.date.getDateStr(this.datePeriodFrom);
    } else if (this.mode == 'week') {
      this.datePeriodFrom = this.week_input.weekStartDate;
      this.datePeriodTo = this.week_input.weekEndDate;
      this.datePeriodTo_inputVal = this.utilCtrl.date.getDateStr(this.datePeriodTo);
      this.datePeriodFrom_inputVal = this.utilCtrl.date.getDateStr(this.datePeriodFrom);
    } else if (this.mode == 'month') {
      let days = this.getDaysInMonth(this.dateMonth - 1, this.dateYear);
      this.datePeriodFrom = days[0];
      this.datePeriodTo = days[days.length - 1];
      this.datePeriodTo_inputVal = this.utilCtrl.date.getDateStr(this.datePeriodTo);
      this.datePeriodFrom_inputVal = this.utilCtrl.date.getDateStr(this.datePeriodFrom);
    }
    this.loadData();
  }
  _trackByFn(index, item) {
    return index;
  }
  _selectViewMode(mode: string) {
    this.selectedViewMode = mode;
    this.loadViewMode();
  }
  // ---------------------- UI



  loadData() {

    if (!this.utilCtrl.date.dateValid(this.datePeriodFrom)) {
      this.uiFeedBackCtrl.presentAlert(`Data "De" invalida!`, `Favor verificar a data inserida!`, `warning`)
      return;
    }

    if (!this.utilCtrl.date.dateValid(this.datePeriodTo)) {
      this.uiFeedBackCtrl.presentAlert(`Data "Até" invalida!`, `Favor verificar a data inserida!`, `warning`)
      return;
    }

    if (this.mode == 'manual' && (this.datePeriodFrom.getTime() > this.datePeriodTo.getTime())) {
      this.uiFeedBackCtrl.presentAlert(`Datas invertidas!`, `Favor verificar as datas inseridas!`, `warning`)
      return;
    }

    this.loader = true;
    this.localTimeRecords = [];
    this.authService.getUName()
      .then((uName) => {
        return this.db.afs.collection(this.db.COLLECTIONS.timeManagement.timeRecords)
          .ref

          .where(`uName`, `==`, uName)
          .where(`date`, `>=`, this.utilCtrl.timestamp.fromDate(this.datePeriodFrom))
          .where(`date`, `<=`, this.utilCtrl.timestamp.fromDate(this.datePeriodTo))

          .orderBy(`date`)
          .get({ source: 'server' })
          .then(q => {
            for (let d of q.docs) {
              this.localTimeRecords.push(d.data() as TM_TimeRecord);
            }

            this.timer_list_tab.load(uName);
            this.loadViewMode();

            this.loader = false;
            this.updatEditSession();
          })
      })
      .catch((e) => {
        this.loader = false;
        this.uiFeedBackCtrl.presentErrorAlert(TIMER_PAGE_DEFINITION.id, TIMER_PAGE_DEFINITION.className, this.authService.localUser.uName, 'Erro', e);
      })
  }
  loadViewMode() {
  }
  updatEditSession() {
    this.loader = true;
    this.canEdit = false;
    this.errorMessage = '';
    initSession(this.db, this.utilCtrl, this.authService.localUser, { uName: this.authService.localUser.uName, dateFromTime: this.datePeriodFrom.getTime(), dateToTime: this.datePeriodTo.getTime() })
      .then(ts => {
        this.loader = false;
        this.canEdit = true;
      })
      .catch((e) => {
        this.loader = false;
        this.uiFeedBackCtrl.presentErrorAlert(TIMER_PAGE_DEFINITION.id, TIMER_PAGE_DEFINITION.className, this.authService.localUser.uName, 'Erro', e);
        if (e.code == 423) {
          this.errorMessage = e.message;
        }
      })
  }
  changeTimeRecords(event: TM_TimeRecord[]) {
  }
}
