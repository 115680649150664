import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IcGanttComponent } from './ic-gantt.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NbButtonModule, NbIconModule, NbToggleModule } from '@nebular/theme';

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,

  NbButtonModule,
  NbIconModule,
  NbToggleModule,

];

const COMPONENTS = [
  IcGanttComponent
];


@NgModule({
  imports: [...BASE_MODULES],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS]
})
export class IcGanttModule { }
