<div class="magic-table">

  <div *ngIf="loader" class="magic-table-loader">
    <p><b>Carregando...</b></p>
  </div>

  <div class="controls" *ngIf="showHeader">
    <div class="inner">

      <button *ngIf="canConfig" (click)="showSettings()">
        <nb-icon icon="settings-2-outline" pack="eva"></nb-icon>
      </button>

      <p title="{{id}}">{{title}}</p>

      <!-- to Do  -->
      <!-- <div *ngFor="let c of groupControls; let control_i=index">
        <magic-control *ngIf="c.action=='group' || c.action=='filter'" [title]="c.title" [(value)]="c.value"
          (valueChange)="controlValueChange(control_i,$event)">
          <magic-control-option title="Remover" value=""></magic-control-option>
          <magic-control-option *ngFor="let g of groups4sel" [title]="g.title" [value]="g"></magic-control-option>
        </magic-control>
      </div>
      <button [disabled]="groups4sel.length == 0 || groups4sel.length == groupControls.length" title="Agrupar"
         (click)="addGroupControl()">
        <nb-icon icon="collapse-outline" pack="eva"></nb-icon>
      </button> -->
      <!-- to Do  -->

    </div>
  </div>


  <table class="table">

    <thead>
      <tr>
        <th *ngFor="let col of table.cols; trackBy: _trackByFn; let col_i=index" title="{{col.title}}"
          [ngClass]="col.fieldName">

          <div class="inner" [style.width]="col.width">
            <div class="wrapper">

              <button class="action" title="Limpar Filtro" (click)="cleanFilter(col_i)">
                <nb-icon *ngIf="filterActiveIndex[col_i]" style="color: blue;" icon="funnel-outline"
                  pack="eva"></nb-icon>
              </button>

              <button class="action" *ngIf="orderByIndex[col_i]" title="Limpar Ordenação"
                (click)="toogleSort(col_i,orderByIndex[col_i])">
                <nb-icon *ngIf="orderByIndex[col_i] =='asc'" style="color: blue;" icon="arrow-upward-outline"
                  pack="eva"></nb-icon>

                <nb-icon *ngIf="orderByIndex[col_i] =='desc'" style="color: blue;" icon="arrow-downward-outline"
                  pack="eva"></nb-icon>
              </button>

              <span class="col-title">
                {{col.title}}
              </span>

              <!-- filter -->
              <button class="col-btn" (click)="openFilterPopdown(col_i)">
                <nb-icon icon="chevron-down-outline" pack="eva"></nb-icon>
              </button>
            </div>

            <!-- Filter Popdown -->
            <div class="popdown" *ngIf="showFilterPopdown == col_i">
              <div class="popdownContent" #FilterPopdownContentERef>

                <button class="list-button" (click)="toogleSort(col_i,'asc')"
                  [ngClass]="{'sort-active': orderByIndex[col_i] == 'asc'}">
                  <span class="ltext">
                    <span style="color: blue;">A</span>
                    <span>Z</span>
                  </span>
                  <nb-icon icon="arrow-downward-outline" pack="eva"></nb-icon>
                  Classificar de A a Z
                </button>
                <button class="list-button" (click)="toogleSort(col_i,'desc')"
                  [ngClass]="{'sort-active': orderByIndex[col_i] == 'desc'}">
                  <span class="ltext">
                    <span>Z</span>
                    <span style="color: blue;">A</span>
                    <nb-icon icon="arrow-downward-outline" pack="eva"></nb-icon>
                  </span>
                  Classificar de Z a A
                </button>
                <hr>

                <button [disabled]="!filterActiveIndex[col_i]" class="list-button" (click)="cleanFilter(col_i)">
                  <nb-icon style="color: red;" icon="funnel-outline" pack="eva"></nb-icon>
                  Limpar Filtro
                </button>

                <input #FilterPopdownSearchInputERef class="list-input" type="text"
                  [id]="'FilterPopdownSearchInput_'+col_i" [name]="'FilterPopdownSearchInput_'+col_i"
                  aria-describedby="search-text" placeholder="Pesquisar" autocomplete="nope"
                  [(ngModel)]="_filterPopdownData.searchText" autofocus (ngModelChange)="updateSearchResult()"
                  (keyup.enter)="filter(col_i)">

                <div class="search-list-group search-list-group-flush">

                  <!-- (Selecionar Tudo) -->
                  <div class="search-list-group-item" *ngIf="_filterPopdownData.searchText.length == 0">
                    <input [disabled]="_filterPopdownData.searchResult.length==0" (click)="toogleAll()" type="checkbox"
                      id="{{'input_unselectAll-'+id}}" name="{{'input_unselectAll-'+id}}"
                      [(ngModel)]="_filterPopdownData.selectAll">
                    <label for="{{'input_unselectAll-'+id}}">
                      (Selecionar Tudo)
                    </label>
                  </div>
                  <!-- (Selecionar Tudo) -->

                  <!-- (Selecionar Todos os Resultados da Pesquisa) -->
                  <div class="search-list-group-item"
                    *ngIf="_filterPopdownData.searchText.length > 0 && _filterPopdownData.searchResult.length > 0">
                    <input (click)="toogleAll()" type="checkbox" id="{{'input_filterSelectAllSearchResult-'+id}}"
                      name="{{'input_filterSelectAllSearchResult-'+id}}" [(ngModel)]="_filterPopdownData.selectAll">
                    <label for="{{'input_filterSelectAllSearchResult-'+id}}">
                      (Selecionar Todos os Resultados da Pesquisa)
                    </label>
                  </div>
                  <!-- (Selecionar Todos os Resultados da Pesquisa) -->

                  <!-- (Adicionar seleção atual ao filtro) -->
                  <div class="search-list-group-item"
                    *ngIf="_filterPopdownData.searchText.length>0 && _filterPopdownData.searchResult.length>0">
                    <input type="checkbox" id="{{'input_addCurSelTofilter-'+id}}"
                      name="{{'input_addCurSelTofilter-'+id}}" [(ngModel)]="_filterPopdownData.AddCurSelTofilter">
                    <label for="{{'input_addCurSelTofilter-'+id}}">
                      (Adicionar seleção atual ao filtro)
                    </label>
                  </div>
                  <!-- (Adicionar seleção atual ao filtro) -->

                  <div class="search-list-group-item"
                    *ngFor="let c of _filterPopdownData.searchResult; let input_filter_check_i=index">
                    <input type="checkbox" [id]="'input_filter_check_'+input_filter_check_i"
                      [name]="'input_filter_check_'+input_filter_check_i" [(ngModel)]="c.show"
                      (ngModelChange)="updateSelectAll()">
                    <label [for]="'input_filter_check_'+input_filter_check_i" magicTableHighlight
                      [searchedWord]="_filterPopdownData.searchText" [content]="c.cell" [classToApply]="'highlight'"
                      [setTitle]="true">{{c.cell}}</label>
                  </div>
                </div>

                <button [disabled]="_filterPopdownData.searchResult.length == 0" class="list-button"
                  (click)="filter(col_i)">
                  Filtrar
                </button>

              </div>
            </div>
            <!-- Filter Popdown -->
            <!-- filter -->

          </div>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let row of rowsForDisplay; trackBy: _trackByFn; let rows_i=index">
        <td *ngFor="let cell of row.cells; trackBy: _trackByFn; let cells_i=index"
          [ngClass]="table.cols[cells_i].fieldName">
          <div class="td-inner" [style.width]="table.cols[cells_i].width">
            <span
              *ngIf="table.cols[cells_i].type=='text' || table.cols[cells_i].type=='timestamp' || table.cols[cells_i].type=='boolean'"
              title="{{cell}}">
              {{cell}}
            </span>
            <div *ngIf="table.cols[cells_i].type=='html'" [innerHTML]="trustHtml(cell)"></div>
            <div *ngIf="table.cols[cells_i].type=='href'">
              <a href="{{row.hrefs[cells_i]}}" target="{{table.cols[cells_i].hrefTarget}}"
                title="{{table.cols[cells_i].hrefTitle}}">
                {{cell}}
              </a>
            </div>
            <div *ngIf="table.cols[cells_i].type=='progress-bar'" class="type-progress-bar">
              <span title="{{cell}}" class="percent-text">{{cell}}</span>
              <div class="progress">
                <span class="progress-bar" [style.width]="cell"></span>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>


  <!-- pagination -->
  <ul class="_pagination">
    <li class="_item">
      <button [disabled]="actualPage == 1" (click)="pageDown()">
        <nb-icon icon="chevron-left-outline" pack="eva"></nb-icon>
      </button>
    </li>
    <li class="_item-inner">
      <span>
        <input type="number" id="input_page" name="input_page" [(ngModel)]="input_page" [min]="1" [max]="lastPage"
          (keyup.enter)="update_input_page()">
        / {{lastPage}}
      </span>
    </li>
    <li class="_item">
      <button [disabled]="actualPage == lastPage" (click)="pageUp()">
        <nb-icon icon="chevron-right-outline" pack="eva"></nb-icon>
      </button>
    </li>
  </ul>
  <!-- pagination -->

  <div class="footer">
    <span>{{this.table.rows.length}} linha(s)</span>
    <button class="saveAsExcel" [disabled]="this.table.rows.length == 0" (click)="saveAsExcel()">
      <nb-icon icon="download-outline" pack="eva"></nb-icon>
      Excel
    </button>
  </div>

</div>