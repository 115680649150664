export class PM_CARD_TYPE_DATA_TYPE {
  id: PM_CardType;
  title: string;
  icon: {
    icon: string;
    pack: string
  };
  public constructor(init?: Partial<PM_CARD_TYPE_DATA_TYPE>) {
    this.id = null;
    this.title = "";
    this.icon = {
      icon: "",
      pack: ""
    };
  }
}

export const PM_CARDS_TYPES: PM_CARD_TYPE_DATA_TYPE[] = [
  {
    id: 'task',
    title: 'Tarefa',
    icon: {
      icon: "checkmark-square-2-outline",
      pack: "eva"
    }
  },
  {
    id: 'milestone',
    title: 'Marco',
    icon: {
      icon: "flag-outline",
      pack: "eva"
    }
  },
  {
    id: 'group',
    title: 'Grupo',
    icon: {
      icon: "folder-outline",
      pack: "eva"
    }
  }
];
export function getPMCardTypeData(type: PM_CardType): PM_CARD_TYPE_DATA_TYPE {
  let td = new PM_CARD_TYPE_DATA_TYPE();
  for (let _td of PM_CARDS_TYPES)
    if (_td.id == type) {
      td = _td;
      break;
    }
  return td;
}

/**
 * @task Tarefa
 * @milestone Marco
 * @group Grupo
 */
export type PM_CardType = 'task' | 'milestone' | 'group';

