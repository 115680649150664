import { Component, OnChanges, OnInit, SimpleChange, ViewChild } from '@angular/core';

import { PmCardModal } from '../../../pm-card-modal';
import { ModeTimeRecordsRecordTypePieChartComponent } from './charts/mode-time-records-record-type-pie-chart.component';

@Component({
  selector: 'mode-time-records',
  templateUrl: './mode-time-records.component.html',
  styleUrls: ['./mode-time-records.component.scss']
})
export class PMCardModalModeTimeRecordsComponent implements OnInit, OnChanges {

  className = 'PMCardModalModeTimeRecordsComponent';

  _loader = true;

  @ViewChild('ModeTimeRecordsRecordTypePieERef') _modeTimeRecordsRecordTypePieChartERef: ModeTimeRecordsRecordTypePieChartComponent = new ModeTimeRecordsRecordTypePieChartComponent(this.pmCardModal.layoutService, this.pmCardModal.theme, this.pmCardModal.utilCtrl);

  constructor(
    public pmCardModal: PmCardModal,
  ) { }

  ngOnInit() {
    this.load();
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) { }
  ngOnDestroy() { }
  _trackByFn(index, item) {
    return index;
  }

  load() {
    this._loader = true;
    this._modeTimeRecordsRecordTypePieChartERef.load(this.pmCardModal.timeRecords, this.pmCardModal.boardMembers)
    this._loader = false;
  }
}
