import { Component, OnInit, ViewChild, HostListener, Optional, Inject, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomSelectBtnRenderer2Component } from './custom-select-btn-renderer2.component';

@Component({
  selector: 'smart-table-select-modal',
  templateUrl: './smart-table-select-modal.component.html',
  styleUrls: ['./../ui-feed-back-modals.scss'],
})
export class SmartTableSelectModalComponent implements OnInit {

  nstSettings = {
    mode: 'external',
    noDataMessage: 'Não Existem Dados.',
    pager: {
      display: true,
      perPage: 10,
    },

    add: {
      addButtonContent: '<i class="nb-compose"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
    },

    actions: {
      columnTitle: 'Ações',
      edit: false,
      delete: false,
      add: false,
      position: 'right',
    },
    columns: {},
  };


  showTable = false;

  @ViewChild('SmartTableSelectModalContent', { read: ElementRef, static: false }) smartTableSelectModalContent: ElementRef;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private mdDialogRef: MatDialogRef<SmartTableSelectModalComponent>,
  ) { }

  ngOnInit() {

    var self = this;

    this.nstSettings.columns = {
      openColumn: {
        title: 'Selecionar',
        type: 'custom',
        renderComponent: CustomSelectBtnRenderer2Component,
        filter: false,
        onComponentInitFunction(instance) {
          instance.selectedRowDataId = self.data.lastSelected_id;
          instance.selectedRowDataField = self.data.lastSelected_id;
          instance.click.subscribe(data => { self.onSelection(data) })
        }
      },
    }

    let tempOtherColuns = Object.entries(this.data.columns);
    tempOtherColuns.forEach(function (e) {
      self.nstSettings.columns[e[0]] = e[1];
    });

    this.showTable = true;

  }



  onSelection(data) {
    this.closeDialog(data);
  }


  closeDialog(data?: any) {
    this.mdDialogRef.close(data)
  }

  @HostListener('window:keydown.esc', ['$event'])
  onEsc() {
    this.closeDialog();
  }
  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.smartTableSelectModalContent && !this.smartTableSelectModalContent.nativeElement.contains(event.target))
      this.closeDialog();
  }

}
