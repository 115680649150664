import { DbTableComponent } from "../db-table.component";

export function dbTableSetFilterStatus(_this: DbTableComponent, c_index: number, filterValue: string, status: boolean) {
    let newfs = true;
    for (let f of _this.filterIndexes[c_index]) {
        if (f.value == filterValue) {
            f.show = status;
            newfs = false;
            break;
        }
    }
    if (newfs)
        _this.filterIndexes[c_index].push({ value: filterValue, show: status });
}
