<div *ngIf="!disableUi" class="smart-table-select-ui-wrap" style="text-align: initial">
  <label>{{label}}</label>

  <div class="ic-form-control"
    [ngClass]="{ 'invalid': required && touched && STSS.getValue(instName,'state') == 'not-selected' }">

    <div *ngIf="STSS.getValue(instName,'state') == 'not-selected'">
      <button [disabled]="disabled" (click)="initSelect()">Selecionar</button>
    </div>

    <div class="show-selected" *ngIf="STSS.getValue(instName,'state') == 'selected'">
      <p>{{nameFieldValue}}</p>
      <button [disabled]="disabled" (click)="initSelect()" color="primary">Alterar</button>
    </div>
  </div>
</div>
<!-- Dialog template -->
<ng-template #SelectionDialog>
  <div class="custon-dialog-header">

    <h5 matDialogTitle>
      {{dialogTitle}}
    </h5>

    <button class="close" mat-button (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <mat-dialog-content>

    <ng2-smart-table [settings]="nstSettings" [source]="source">
    </ng2-smart-table>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button nbButton (click)="closeDialog()" status="danger" size="small">
      Cancelar
    </button>
  </mat-dialog-actions>
</ng-template>
