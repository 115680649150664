import firebase from "firebase/compat/app";

export class SM_Team {

  id: string;
  name: string;

  childsKey: string;

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<SM_Team>) {

    this.id = '';
    this.name = '';

    this.childsKey = '';

    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
