export class Converters {
  // constructor() { }
  /**
  * Retorna a a string para o boolean
  * @param {boolean} boolean
  * @return  {string} Sim ou Não
  */
  booleanToStr(boolean: boolean): string {
    if (boolean) {
      return `Verdadeiro`
    } else {
      return `Falso`
    }
  }
  stringToColourHex(str: string): string {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i2 = 0; i2 < 3; i2++) {
      var value = (hash >> (i2 * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }
  htmltoTextStr(html: string): string {
    let v = '';
    let _html = '';
    if (html) {
      _html = html + '';
      v = _html.replace(/<(?:.|\n)*?>/gm, '');
      v = v.replace(/\&nbsp;/g, '');
    }
    return v;
  }
  millisecondsToHuman(milliseconds: number, textLength: 'tiny' | `normal` = 'normal', useSpace: boolean = true, infoNumber: 2 | 3 | 4 | 5 = 2): string {
    if (!milliseconds || milliseconds <= 0)
      return '-';

    const yearInMs = 1000 * 60 * 60 * 24 * 365;
    const dayInMs = 1000 * 60 * 60 * 24;
    const hourInMs = 1000 * 60 * 60;
    const minInMs = 1000 * 60;
    const secInMs = 1000;

    let textsMap = {
      "singular": {
        "y": {
          "tiny": "a",
          "normal": "ano"
        },
        "d": {
          "tiny": "s",
          "normal": "dia"
        },
        "h": {
          "tiny": "h",
          "normal": "hora"
        },
        "m": {
          "tiny": "m",
          "normal": "minuto"
        },
        "s": {
          "tiny": "s",
          "normal": "segundo"
        },
        "ms": {
          "tiny": "",
          "normal": "milisegundo"
        },
      },
      "plural": {
        "y": {
          "tiny": "a",
          "normal": "anos"
        },
        "d": {
          "tiny": "s",
          "normal": "dias"
        },
        "h": {
          "tiny": "h",
          "normal": "horas"
        },
        "m": {
          "tiny": "m",
          "normal": "minutos"
        },
        "s": {
          "tiny": "s",
          "normal": "segundos"
        },
        "ms": {
          "tiny": "",
          "normal": "milisegundos"
        },
      }
    };

    let y = Math.floor(milliseconds / yearInMs);
    let d = Math.floor((milliseconds % yearInMs) / dayInMs);
    let h = Math.floor(((milliseconds % yearInMs) % dayInMs) / hourInMs);
    let m = Math.floor(((milliseconds % yearInMs) % dayInMs) % hourInMs / minInMs);
    let s = Math.floor((((milliseconds % yearInMs) % dayInMs) % hourInMs % minInMs) / secInMs);
    let ms = ((((milliseconds % yearInMs) % dayInMs) % hourInMs % minInMs) % secInMs);

    let str = '';
    let infoVet = [];

    // year
    if (y == 1)
      infoVet.push(`${y}${textsMap.singular.y[textLength]}`);
    else if (y > 0)
      infoVet.push(`${y}${textsMap.plural.y[textLength]}`);

    // day
    if (d == 1)
      infoVet.push(`${d}${textsMap.singular.d[textLength]}`);
    else if (d > 0)
      infoVet.push(`${d}${textsMap.plural.d[textLength]}`);

    // hour
    if (h == 1)
      infoVet.push(`${h}${textsMap.singular.h[textLength]}`);
    else if (h > 0)
      infoVet.push(`${h}${textsMap.plural.h[textLength]}`);

    // minutos
    if (m == 1)
      infoVet.push(`${m}${textsMap.singular.m[textLength]}`);
    else if (m > 0)
      infoVet.push(`${m}${textsMap.plural.m[textLength]}`);
    else
      infoVet.push(`0${textsMap.singular.m[textLength]}`);

    // segundos
    if (s == 1)
      infoVet.push(`${s}${textsMap.singular.s[textLength]}`);
    else if (s > 0)
      infoVet.push(`${s}${textsMap.plural.s[textLength]}`);
    else
      infoVet.push(`0${textsMap.singular.s[textLength]}`);

    // milisegundos
    if (ms == 1)
      infoVet.push(`${ms}${textsMap.singular.ms[textLength]}`);
    else if (ms >= 0)
      infoVet.push(`${ms}${textsMap.plural.ms[textLength]}`);

    for (let index = 0; index < infoNumber; index++) {
      if (infoVet[index])
        str += infoVet[index];
      if (useSpace && index < infoNumber)
        str += " ";
    }

    return str;
  }
  /**
   * Converts a strig HH:MM:SS to Number from 0 to 24
   * @param HHMMSS 
   * @returns number
   */
  timeHHMMSStoNumber(HHMMSS: string): number {
    let val = 0;
    let strVet = HHMMSS.split(':');

    const hourToHour = 1;
    const minToHour = 1 / 60;
    const secToHour = (1 / 60) / 60;

    let h = 0;
    let m = 0;
    let s = 0;

    if (strVet[0])
      h = Number(strVet[0]) * hourToHour;
    if (strVet[1])
      m = Number(strVet[1]) * minToHour;
    if (strVet[2])
      s = Number(strVet[2]) * secToHour;

    val += h;
    val += m;
    val += s;

    return val;
  }
  hoursToMs(hours: number): number { return hours * 3600000; }
  daysToMs(days: number): number { return days * 86400000; }
  arrayToCsv(array: any[]): string {
    if (array.length === 0)
      return '';

    const headers = Object.keys(array[0]);
    const csvRows: string[] = [];

    // Adiciona os cabeçalhos ao CSV
    csvRows.push(headers.join(','));

    // Adiciona os dados das linhas ao CSV
    array.forEach((obj) => {
      const row = headers.map((header) => {
        let value = obj[header];

        // Trata valores nulos ou indefinidos
        if (value === null || value === undefined)
          return '';

        // Converte valores para string e escapa caracteres especiais
        if (typeof value === 'string')
          // Remove aspas desnecessárias e escapa as aspas duplas
          value = `"${value.replace(/"/g, '""')}"`;
        else if (typeof value === 'number' || typeof value === 'boolean')
          // Converte números e booleanos para string
          value = value.toString();
        else if (Array.isArray(value) || typeof value === 'object')
          // Converte arrays e objetos para string (como objetos JSON)
          value = `"${JSON.stringify(value).replace(/"/g, '""')}"`;


        return value;
      });
      csvRows.push(row.join(','));
    });

    return '\uFEFF' + csvRows.join('\n'); // Adiciona BOM para UTF-8
  }
}
