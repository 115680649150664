<div class="db-filter-rule-ui-inner" [ngClass]="{'has-error': hasError}">
    <div class="header">
        <button *ngIf="!_showConstructor" icButton size="small" title="Mostrar Construtor de Query"
            (click)="_toggleShowConstructor()">
            <nb-icon icon="funnel-outline" pack="eva"></nb-icon>
        </button>

        <button *ngIf="_showConstructor" icButton size="small" title="Ocultar Construtor de Query"
            (click)="_toggleShowConstructor()">
            <nb-icon icon="funnel" pack="eva"></nb-icon>
        </button>

        <div class="query-text">
            {{_queryStr}}
        </div>
    </div>


    <div class="constructor" *ngIf="_showConstructor">
        <span class="title">Condições</span>

        <!-- rule-set -->
        <div class="rule-set">


            <!-- filter-rule -->
            <div class="filter-rule" *ngFor="let filterRules of ruleSet; trackBy: _trackByFn; let fr_index=index">

                <!-- rules -->
                <div class="rules">
                    <span class="info">Todas estas condições devem ser atendidas</span>

                    <!-- rule -->
                    <div class="rule" *ngFor="let rule of filterRules; trackBy: _trackByFn; let r_index=index">

                        <!-- rule-inner -->
                        <div class="rule-inner">
                            <!-- rule-clause -->
                            <div *ngIf="rule.conditions.length >= 2" class="rule-clause"
                                [ngClass]="{'or-clause': rule.clause == OR_CLAUSE, 'and-clause': rule.clause == AND_CLAUSE}">
                                <span class="clause-label" *ngIf="rule.clause == AND_CLAUSE"
                                    [ngClass]="{'or-clause': rule.clause == OR_CLAUSE, 'and-clause': rule.clause == AND_CLAUSE}">
                                    E
                                </span>

                                <span class="clause-label" *ngIf="rule.clause == OR_CLAUSE"
                                    [ngClass]="{'or-clause': rule.clause == OR_CLAUSE, 'and-clause': rule.clause == AND_CLAUSE}">
                                    OU
                                </span>
                            </div>
                            <!-- rule-clause -->

                            <!-- conditions -->
                            <div class="conditions">

                                <!-- condition -->
                                <div class="condition"
                                    *ngFor="let condition of rule.conditions; trackBy: _trackByFn; let condition_index=index">

                                    <!-- fieldId -->
                                    <ng-select class="fieldId_input"
                                        id="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']fieldId_input'}}"
                                        name="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']fieldId_input'}}"
                                        required [disabled]="!edit" [(ngModel)]="condition.fieldId"
                                        (change)="_fieldId_input_change(fr_index, r_index, condition_index)">
                                        <ng-option *ngFor="let field of _dataSourceData.fields" [value]="field.id">
                                            {{field.name}}
                                        </ng-option>
                                    </ng-select>
                                    <!-- fieldId -->

                                    <!-- operation -->
                                    <select class="operation_input ic-form-control" [disabled]="!condition.fieldId"
                                        id="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']operation_input'}}"
                                        name="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']operation_input'}}"
                                        [(ngModel)]="condition.operationId"
                                        (ngModelChange)="_operation_input_change(fr_index, r_index, condition_index)"
                                        [compareWith]="_compareFn" required [disabled]="!edit">
                                        <option *ngFor="let op of _operations4Sel[fr_index][r_index][condition_index]"
                                            [ngValue]="op.id">
                                            {{op['name']}}
                                        </option>
                                    </select>
                                    <!-- operation -->


                                    <!-- value -->
                                    <div class="value_input" *ngIf="_operationDataIndex[fr_index][r_index] &&
             _operationDataIndex[fr_index][r_index][condition_index] &&
              _operationDataIndex[fr_index][r_index][condition_index].inputType!='none';else elseTemplate_1">

                                        <!-- single -->
                                        <div
                                            *ngIf="_operationDataIndex[fr_index][r_index][condition_index].inputType=='single'">

                                            <div *ngIf="_fieldTexts4Sel[fr_index][r_index][condition_index]">

                                                <!-- dont has field texts -->
                                                <div
                                                    *ngIf="_fieldTexts4Sel[fr_index][r_index][condition_index].length==0">

                                                    <div
                                                        *ngIf="_fieldDataIndex[fr_index][r_index] && _fieldDataIndex[fr_index][r_index][condition_index]">

                                                        <!-- boolean -->
                                                        <div
                                                            *ngIf="_fieldDataIndex[fr_index][r_index][condition_index].type == 'boolean'">
                                                            <select class="ic-form-control"
                                                                id="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']value_input'}}"
                                                                name="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']value_input'}}"
                                                                required [disabled]="!edit"
                                                                [(ngModel)]="condition.value"
                                                                (ngModelChange)="_change()">
                                                                <option *ngFor="let n of db.DICTIONARY.general.boolean"
                                                                    [ngValue]="n.id">
                                                                    {{n.name}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <!-- boolean -->

                                                        <!-- string -->
                                                        <div
                                                            *ngIf="_fieldDataIndex[fr_index][r_index][condition_index].type == 'string'">
                                                            <input class="ic-form-control" type="text"
                                                                id="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']value_input'}}"
                                                                name="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']value_input'}}"
                                                                required [disabled]="!edit"
                                                                [(ngModel)]="condition.value"
                                                                (ngModelChange)="_change()">
                                                        </div>
                                                        <!-- string -->

                                                        <!-- number -->
                                                        <div
                                                            *ngIf="_fieldDataIndex[fr_index][r_index][condition_index].type == 'number'">
                                                            <input class="ic-form-control" type="number"
                                                                id="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']value_input'}}"
                                                                name="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']value_input'}}"
                                                                required [disabled]="!edit"
                                                                [(ngModel)]="condition.value"
                                                                (ngModelChange)="_change()">
                                                        </div>
                                                        <!-- number -->

                                                        <!-- timestamp -->
                                                        <div
                                                            *ngIf="_fieldDataIndex[fr_index][r_index][condition_index].type == 'timestamp'">
                                                            <input class="ic-form-control" type="datetime-local"
                                                                id="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']value_input'}}"
                                                                name="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']value_input'}}"
                                                                step="1" required [disabled]="!edit"
                                                                [(ngModel)]="condition.value"
                                                                (ngModelChange)="_change()">
                                                        </div>
                                                        <!-- timestamp -->

                                                    </div>
                                                </div>
                                                <!-- dont has field texts -->

                                                <!-- has field texts -->
                                                <div
                                                    *ngIf="_fieldTexts4Sel[fr_index][r_index][condition_index].length>0">
                                                    <!-- [disabled]="!condition.fieldId || condition.operation==''" -->
                                                    <ng-select
                                                        id="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']value_input'}}"
                                                        name="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']value_input'}}"
                                                        required [disabled]="!edit" [(ngModel)]="condition.value"
                                                        (change)="_change()">
                                                        <ng-option
                                                            *ngFor="let ft of _fieldTexts4Sel[fr_index][r_index][condition_index]"
                                                            [value]="ft.value">
                                                            {{ft.text}}
                                                        </ng-option>
                                                    </ng-select>
                                                </div>
                                                <!-- has field texts -->

                                            </div>

                                        </div>
                                        <!-- single -->

                                        <!-- select-other-field -->
                                        <div
                                            *ngIf="_operationDataIndex[fr_index][r_index][condition_index].inputType=='select-other-field'">

                                            <ng-select
                                                id="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']value_input'}}"
                                                name="{{'filter-rule['+fr_index+']rule['+r_index+']condition['+condition_index+']value_input'}}"
                                                required [disabled]="!edit" [(ngModel)]="condition.value"
                                                (change)="_change()">
                                                <ng-option *ngFor="let field of _dataSourceData.fields"
                                                    [value]="field.id">
                                                    {{field.name}}
                                                </ng-option>
                                            </ng-select>

                                        </div>
                                        <!-- select-other-field -->

                                        <!-- multi-select -->
                                        <div
                                            *ngIf="_operationDataIndex[fr_index][r_index][condition_index].inputType=='multi-select'">
                                            multi-select
                                        </div>
                                        <!-- multi-select -->

                                    </div>
                                    <ng-template #elseTemplate_1>
                                        <div class="value_input"></div>
                                    </ng-template>
                                    <!-- value -->


                                    <!-- Rem Condition -->
                                    <button [disabled]="!edit" icButton size="small" status="danger"
                                        (click)="_remCondition(fr_index, r_index, condition_index)">
                                        <nb-icon icon="minus-circle-outline" pack="eva"></nb-icon>
                                    </button>
                                    <!-- Rem Condition -->

                                    <!-- Add Condition OR -->
                                    <button [disabled]="!edit" class="add-or-condition-btn" icButton size="small"
                                        (click)="_addCondition(fr_index, r_index, condition_index, OR_CLAUSE)">
                                        OU
                                    </button>
                                    <!-- Add Condition OR -->

                                    <!-- Add Condition AND -->
                                    <button class="add-and-condition-btn"
                                        [disabled]="!edit || condition_index != (rule.conditions.length-1)" icButton
                                        size="small"
                                        (click)="_addCondition(fr_index, r_index, condition_index, AND_CLAUSE)">
                                        E
                                    </button>
                                    <!-- Add Condition AND -->

                                </div>
                                <!-- condition -->

                            </div>
                            <!-- conditions -->
                        </div>
                        <!-- rule-inner -->

                        <!-- rules divider -->
                        <div *ngIf="filterRules.length>1 && r_index != (filterRules.length-1)" class="rule-divider">
                            <span>
                                e
                            </span>
                        </div>
                        <!-- rules divider -->

                    </div>
                    <!-- rule -->

                </div>
                <!-- rules -->

                <!-- filter-rule divider -->
                <div *ngIf="ruleSet.length>1 && fr_index != (ruleSet.length-1)" class="filter-rule-divider">
                    <span>
                        ou
                    </span>
                </div>
                <!-- filter-rule divider -->

            </div>
            <!-- filter-rule -->


        </div>
        <!-- rule-set -->

        <!-- Add filter-rule -->
        <button icButton [disabled]="!edit || dataSourceId ==''" size="small" (click)="_addSet()">
            Novos Critérios
            <nb-icon icon="plus-circle-outline" pack="eva"></nb-icon>
        </button>
        <!-- Add filter-rule -->

        <p *ngIf="dataSourceId ==''" class="tiny-error">
            Selecione um Data Source para começar
        </p>
    </div>
</div>