import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'magic-control',
  templateUrl: './magic-control.component.html',
  styleUrls: ['./magic-control.component.scss'],
})
export class MagicControlComponent implements OnInit {

  @ViewChild('popdownContent', { read: ElementRef, static: false }) popdownContent: ElementRef;

  @Input() title: string = "";
  @Input() disabled = false;
  touched = false;
  list4Selection: { title: string; data: any }[] = [];
  showSelect = false;

  selected: { title: string; data: any } = { title: '', data: null };

  @Input()
  value: any;
  @Output() valueChange = new EventEmitter();

  constructor() { }
  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.popdownContent && !this.popdownContent.nativeElement.contains(event.target)) {
      this.trigger();
    }
  }

  public writeValue(value: { title: string; data: any }): void {
    this.list4Selection.push(value);
  };
  public remValue(value: { title: string; data: any }): void {
    this.list4Selection.splice(this.list4Selection.indexOf(value));
  };

  ngOnInit() { }

  trigger() {
    if (!this.disabled)
      this.showSelect = !this.showSelect;
  }
  select(i: { title: string; data: any }) {
    this.showSelect = false;
    this.selected = i;
    this.value = i.data
    this.valueChange.emit(this.value);
  }

}
