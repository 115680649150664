<div class="editor-engine-config-inner">
  <p>Configuração</p>
  <div class="box type-selector">
    <label for="input_type">
      Tipo <a href="#/main/sys/data-visualization-info" target="_blank" rel="noopener noreferrer">(ajuda)</a>
    </label>

    <ng-select [disabled]="!edit" id="input_field" name="input_field" labelForId="input_field" [(ngModel)]="_vis_type"
      (change)="_typeChange()" [compareWith]="_compareFn">
      <ng-option *ngFor="let vt of _vis_types" [value]="vt">
        <img src="{{vt.base64Img}}" alt="{{vt.id}}" width="20px" height="20px"
          style="background-color: #fff;padding: 1px;" />
        {{vt.name}}
      </ng-option>
    </ng-select>
  </div>

  <hr>

  <div class="box-background engine-data"
    *ngIf="_vis_type && _vis_type.engine && _vis_type.engine.dataProps && _vis_type.engine.dataProps.length>0">
    <p>Dados</p>

    <form class="engine-data-form" id="engine-data-form" name="engine-data-form" #engineDataForm="ngForm">
      <div class="box engine-data-input"
        *ngFor="let ed of _vis_type.engine.dataProps; trackBy: _trackByFn; let i=index">
        <p><b>{{_engineData[ed].name}}</b></p>

        <!-- ---------------------------------- -->
        <!-- xAxis -->
        <div class="xAxis" *ngIf="_engineData[ed].id=='xAxis'">
          <label for="engineData_input_xAxis_fieldId">
            Campo
          </label>
          <ng-select [disabled]="!edit" id="engineData_input_xAxis_fieldId" name="engineData_input_xAxis_fieldId"
            [(ngModel)]="_vis_engineData.xAxis.fieldId" (change)="_vis_engineDataChange(engineDataForm.valid)"
            [compareWith]="_compareFn" required>
            <ng-option *ngFor="let f of _data_for_selection.fields" [value]="f.id">
              {{f.name}}
            </ng-option>
          </ng-select>
        </div>
        <!-- xAxis -->

        <!-- yAxis -->
        <div class="yAxis" *ngIf="_engineData[ed].id=='yAxis'">
          <label for="engineData_input_yAxis_calcTypeId">
            Tipo de Cálculo
          </label>
          <select [disabled]="!edit" id="engineData_input_yAxis_calcTypeId" name="engineData_input_yAxis_calcTypeId"
            class="ic-form-control" [(ngModel)]="_vis_engineData.yAxis.calcTypeId"
            (ngModelChange)="_vis_engineDataChange(engineDataForm.valid)" [compareWith]="_compareFn" required>
            <option *ngFor="let t of _axis_calcTypes" [ngValue]="t.id">
              {{t.name}}
            </option>
          </select>


          <label for="engineData_input_yAxis_fieldId">
            Campo
          </label>
          <ng-select id="engineData_input_yAxis_fieldId" name="engineData_input_yAxis_fieldId"
            [disabled]="!edit||!_vis_engineData.yAxis || !_vis_engineData.yAxis.calcTypeId || _vis_engineData.yAxis.calcTypeId == 'count'"
            [(ngModel)]="_vis_engineData.yAxis.fieldId" (change)="_vis_engineDataChange(engineDataForm.valid)"
            [compareWith]="_compareFn" required>
            <ng-option *ngFor="let f of _data_for_selection.fields" [value]="f.id">
              {{f.name}}
            </ng-option>
          </ng-select>
        </div>
        <!-- yAxis -->

        <!-- categorizeBy -->
        <div class="categorizeBy" *ngIf="_engineData[ed].id=='categorizeBy'">
          <label for="engineData_input_categorizeBy_catTypeId">
            Tipo de Categorização
          </label>
          <select [disabled]="!edit" id="engineData_input_categorizeBy_catTypeId"
            name="engineData_input_categorizeBy_catTypeId" class="ic-form-control"
            [(ngModel)]="_vis_engineData.categorizeBy.catTypeId"
            (ngModelChange)="_vis_engineDataChange(engineDataForm.valid)" [compareWith]="_compareFn">
            <option value="">
              Não Categorizar
            </option>
            <option *ngFor="let t of _axis_catTypes4sel" [ngValue]="t.id">
              {{t.name}}
            </option>
          </select>


          <label for="engineData_input_category_fieldIiseBy">
            Campo
          </label>
          <ng-select id="engineData_input_category_fieldIiseBy" name="engineData_input_category_fieldIiseBy"
            [disabled]="!edit||!_vis_engineData.categorizeBy || !_vis_engineData.categorizeBy.catTypeId"
            [(ngModel)]="_vis_engineData.categorizeBy.fieldId" (change)="_vis_engineDataChange(engineDataForm.valid)"
            [compareWith]="_compareFn" required>
            <ng-option *ngFor="let f of _data_for_selection.fields" [value]="f.id">
              {{f.name}}
            </ng-option>
          </ng-select>
        </div>
        <!-- categorizeBy -->
        <!-- ---------------------------------- -->

        <!-- ---------------------------------- -->
        <!-- categories -->
        <div class="categories" *ngIf="_engineData[ed].id=='categories'">
          <label for="engineData_input_categories_fieldId">
            Campo
          </label>
          <ng-select [disabled]="!edit" id="engineData_input_categories_fieldId"
            name="engineData_input_categories_fieldId" [(ngModel)]="_vis_engineData.categories.fieldId"
            (change)="_vis_engineDataChange(engineDataForm.valid)" [compareWith]="_compareFn" required>
            <ng-option *ngFor="let f of _data_for_selection.fields" [value]="f.id">
              {{f.name}}
            </ng-option>
          </ng-select>
        </div>
        <!-- categories -->

        <!-- values -->
        <div class="values" *ngIf="_engineData[ed].id=='values'">
          <label for="engineData_input_values_calcTypeId">
            Tipo de Cálculo
          </label>
          <select [disabled]="!edit" id="engineData_input_values_calcTypeId" name="engineData_input_values_calcTypeId"
            class="ic-form-control" [(ngModel)]="_vis_engineData.values.calcTypeId"
            (ngModelChange)="_vis_engineDataChange(engineDataForm.valid)" [compareWith]="_compareFn" required>
            <option *ngFor="let t of _axis_calcTypes" [ngValue]="t.id">
              {{t.name}}
            </option>
          </select>


          <label for="engineData_input_values_fieldId">
            Campo
          </label>
          <ng-select id="engineData_input_values_fieldId" name="engineData_input_values_fieldId"
            [disabled]="!edit||!_vis_engineData.values || !_vis_engineData.values.calcTypeId || _vis_engineData.values.calcTypeId == 'count'"
            [(ngModel)]="_vis_engineData.values.fieldId" (change)="_vis_engineDataChange(engineDataForm.valid)"
            [compareWith]="_compareFn" required>
            <ng-option *ngFor="let f of _data_for_selection.fields" [value]="f.id">
              {{f.name}}
            </ng-option>
          </ng-select>
        </div>
        <!-- values -->
        <!-- ---------------------------------- -->

      </div>
    </form>

  </div>
</div>