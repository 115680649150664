import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';

export interface TreeNode {
  data: TreeNodeData;
  children?: TreeNode[];
  expanded?: boolean;
}

export interface TreeNodeData {
  name: string;
  info: string;
  kind: string;
  isDir?: boolean;
  items?: number;
  url?: string;
}

@Component({
  selector: 'table-tree',
  styleUrls: ['./table-tree.component.scss'],
  templateUrl: './table-tree.component.html',
})
export class TableTreeComponent implements OnInit, OnDestroy {

  customColumn = 'name';
  defaultColumns = ['info', 'kind', 'items'];
  allColumns = [this.customColumn, ...this.defaultColumns];

  dataSource: NbTreeGridDataSource<TreeNodeData>;

  sortColumn: string;
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  @Input() nodes: TreeNode[];
  @Output() onSelection = new EventEmitter();


  constructor(
    private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>
  ) { }
  ngOnInit() {
    if (this.nodes)
      this.dataSource = this.dataSourceBuilder.create(this.nodes);
  }
  ngOnDestroy() { }

  selectNode(nodeData: TreeNodeData) {
    this.onSelection.emit({ nodeData: nodeData });
  }
  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }
  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }
  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }

}
