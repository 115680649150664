import firebase from "firebase/compat/app";

export class RM_Risk {

  id: string;
  description: string;

  // Avaliação Risco inerente (Média ER)
  probability: number;
  impact: number;
  criticality: number;

  // Avaliação Ambiente de Controle (Média Controles)
  controlsEnvironment: number;

  // Atributos
  riskOwnerId: string;
  areaId: string;
  processId: string;
  subprocessId: string;
  objetivoProcessoId: string;
  baseLegalId: string;

  // Classificação
  categoryId: string;
  grpSubcategoryId: string;
  subcategoryId: string;
  strategicPerspectiveIds: string[];
  strategicObjectiveIds: string[];
  strategicGoalIds: string[];
  rasIndicatorIds: string[];
  kriStrategicIds: string[];

  createdOn: firebase.firestore.Timestamp;
  updatedOn: firebase.firestore.Timestamp;
  createdBy: string;
  updatedBy: string;

  public constructor(init?: Partial<RM_Risk>) {

    this.id = '';
    this.description = '';

    this.probability = 0;
    this.impact = 0;
    this.criticality = 0;

    this.controlsEnvironment = 0;


    this.createdOn = firebase.firestore.Timestamp.now();
    this.updatedOn = null;
    this.createdBy = '';
    this.updatedBy = null;

    if (init)
      Object.assign(this, init);
  }
}
