import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/compat/performance';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

import { environment } from '../../environments/environment';
import { FireFunctionsService } from './fireFunctions.service';
import { FireMessagingService } from './fireMessaging.service';
import { FirePerformance } from './firePerformance.service';
import { FireStorageService } from './firestorage/firestorage.service';
import { FirestoreService } from './firestore/firestore.service';

@NgModule({
    imports: [
        CommonModule,

        // Angular
        AngularFireModule.initializeApp(environment.firebaseOptions),
        AngularFireFunctionsModule,
        AngularFirePerformanceModule,
        AngularFireStorageModule,
        AngularFirestoreModule,
        AngularFireAuthModule,
    ],
    providers: [
        // Services
        FirestoreService,
        FireFunctionsService,
        FireStorageService,
        FirePerformance,
        FireMessagingService,
        PerformanceMonitoringService,
    ],
})
export class FirebaseModule { }